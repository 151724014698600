<template>

   <div class="deq-reset account-users-page">
       <h1 class="title head">Users</h1>

       <LoadingMessage v-if="isLoading" class="account-users-loader" message="Retrieving users for this account"></LoadingMessage>

       <BeButton v-if="!isLoading" @click="onShowDialog" class="add-user-btn"><span><i class="icon-plus"></i>Add a user</span></BeButton>

       <div v-if="!isLoading" class="au-table">
           <div class="header name">Name</div>
           <div class="header email">Email</div>
           <div class="header permissions">Permissions</div>
           <div class="header private-mentions">View Private Mentions</div>
           <div class="header teams">Teams</div>

           <div  @click="onShowDialog(user)" class="table-data" v-for="user in users" :key="user.id">
               <div class="name au-row-cell">{{user.name}}</div>
               <div class="email au-row-cell">{{user.email}}</div>
               <div class="permissions au-row-cell">{{user.permissions.toLowerCase()}}</div>
               <div class="private-mentions au-row-cell">{{getPrivateMentions(user)}}</div>
               <div class="teams au-row-cell">{{findAccountTeamsByUserIdLocal(user.id)}}</div>
           </div>
       </div>

       <BeButton v-if="!isLoading" @click="onShowDialog" class="add-user-btn"><span><i class="icon-plus"></i>Add a user</span></BeButton>

   </div>

</template>

<script>
import BeButton from "@/components/buttons/BeButton.vue";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import UserDialog from "@/settings/users/UserDialog.vue";
import {mash} from "@/store/Services";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapGetters, mapState} from "vuex";
import {getUser} from "@/app/utils/User";
import LoadingMessage from "@/components/LoadingMessage.vue";
import {AccountUser} from "@/app/utils/types";
import {mentionTypes} from "@/settings/users/users-utils";

export default {
    name: "SetupAccountUsers",
    store: VuexStore,
    components: {LoadingMessage, BeButton},

    data(){
        return {
            isLoading: true,
        }
    },

    computed: {
        ...mapState(['account', 'accountTeams']),
        ...mapState('accountUsers', ['users']),
        ...mapGetters(['findAccountTeamById', 'findAccountTeamsByUserId']),
    },

    async mounted() {
        this.isLoading = true;

        await this.refreshAccountUsers();
        await this.refreshAccountTeams();

        this.isLoading = false;
    },

    methods: {
        ...mapActions('accountUsers', ['refreshAccountUsers']),
        ...mapActions(['refreshAccountTeams']),

        onShowDialog(user){
            // user will be pointer event if 'add a user' clicked
            if (user.id && user.email) {
                showDialogComponent(UserDialog, {user})
            } else {
                showDialogComponent(UserDialog)
            }
        },

        findAccountTeamsByUserIdLocal(userId) {
            return this.findAccountTeamsByUserId(userId)?.map(team => team.name).join(", ")
        },

        /**
         *
         * Returns a formatted mentionTypes string for display
         * @param user {AccountUser}
         *
         */
        getPrivateMentions(user) {

           if (!user.privateMentions || !user.privateMentions.length) {
                return 'None';
            }  if (user.privateMentions[0] === '*') {
                return 'All';
            } else  {
                return mentionTypes
                    .filter(type => user.privateMentions.includes(type.value))
                    .map(mentionType => mentionType.value.replace('_', ' ').toLowerCase())
                    .join(', ')
            }
        }

    }
}
</script>

<style scoped lang="sass">

.account-users-loader
    margin: auto
    --loading-message-delay: 50ms

.account-users-page
    height: 100%
    padding-bottom: 10px

.add-user-btn
    margin: 10px 0 10px 0

.au-table
    display: grid
    border: 1px solid black
    grid-template-columns: repeat(10, 1fr)
    margin-bottom: 10px

    .header
        padding: 10px
        border-bottom: 1px solid black
        background-color: var(--table-header-background-colour)

    .email, .permissions, .private-mentions, .teams
        border-left: 1px solid black

    .au-row-cell
        border-bottom: 1px solid black
        padding: 5px
        background-color: var(--table-background-colour)
        display: flex
        flex-direction: column
        justify-content: center

    .name, .teams, .private-mentions
        grid-column-end: span 2

    .email
        grid-column-end: span 3

    .permissions, .edit
        grid-column-end: span 1

    .table-data
        display: contents

        .permissions, .private-mentions
            text-transform: capitalize

        &:first-of-type
            .au-row-cell
                margin-top: 90px
                background-color: #00c9a0
                border-top: none

        &:last-of-type
            .au-row-cell
                border-bottom: none

        &:hover
            .au-row-cell
                cursor: pointer
                background-color: var(--table-hover-background)


</style>