import {mash} from "@/store/Services";
import {BrandPhrase} from "@/app/utils/types";
import {extractPhrases, setPhraseParent} from "@/helpers/BrandPhraseHelper";


let phrasePromise = null;
const PHRASE_PARENT = new BrandPhrase();

export const PhraseMutations = {

    /**
     * Sets the Phrases object in the VuexStore
     *
     * @param state
     * @param brands {Brand[]}
     */
    setPhrases: (state, brands) => {

        brands.forEach(brand => setPhraseParent(brand))

        let extractedPhrases = []
        brands.forEach(brand => {
            extractedPhrases = [...extractedPhrases, ...extractPhrases(brand)]
        })

        extractedPhrases.forEach(phrase => phrase.__proto__ = PHRASE_PARENT)

        state.phrases = extractedPhrases
    }
};

export const PhraseGetters = {
    idToPhrase: state => {
        const map = new Map();
        if (!state.phrases) return map;

        for (const phrase of state.phrases) {
            map.set(phrase.id, phrase);
        }
        return map
    },
};


export const PhraseActions = {

    /**
     * Refreshes the phrases state in VuexStore.
     * Dispatches 'refreshBrands' for a refined BrandPhrase object
     * @param dispatch
     * @param forceRefresh {boolean}
     * @returns {Promise<void>}
     */
    async refreshPhrases({dispatch}, forceRefresh) {
        await dispatch('refreshBrands', forceRefresh)
    },

    /**
     * Updates a phrase object
     *
     * @param state
     * @param dispatch
     * @param phrase {BrandPhrase}
     * @returns {Promise<void>}
     */
    async createPhrase({state, dispatch}, phrase) {
        try {
            await mash.post(`/rest/accounts/${state.account.code}/phrases`, phrase);
            dispatch('refreshPhrases', true)
        } catch (error) {
            console.warn(`An error occurred while saving phrase: ${phrase.q}, error: `, error);
            throw error;
        }
    },

    /**
     * Updates a phrase object
     *
     * @param state
     * @param dispatch
     * @param phrase {BrandPhrase}
     * @returns {Promise<void>}
     */
    async updatePhrase({state, dispatch}, phrase) {
        try {
            await mash.put(`/rest/accounts/${state.account.code}/phrases/${phrase.id}`, phrase);
            dispatch('refreshPhrases', true)
        } catch (error) {
            console.warn(`An error occurred while saving phrase: ${phrase.q}, error: `, error);
            throw error;
        }
    },


    /**
     * Moves a phrase object
     *
     * @param state
     * @param dispatch
     * @param phrase {BrandPhrase}
     * @returns {Promise<void>}
     */
    async movePhrase({state, dispatch}, phrase) {
        try {
            if (!phrase.id) throw new Error("Must be a valid phrase");

            await mash.put(`/rest/accounts/${state.account.code}/phrases/${phrase.id}`, phrase);
            dispatch('refreshPhrases', true)
        } catch (error) {
            console.warn(`An error occurred while saving phrase:, error: `, error);
            throw error;
        }
    },

    /**
     * Deletes a phrase object
     * @param phraseId {String}
     * @returns {Promise<void>}
     */
    async deletePhrase({state, dispatch}, phraseId) {
        try {
            if (!phraseId) throw new Error('PhraseId is required');

            await mash.delete(`/rest/accounts/${state.account.code}/phrases/${phraseId}`);
            dispatch('refreshPhrases', true)
        } catch (error) {
            console.warn(`An error occurred while deleting phrase: ${phraseId}, error: `, error);
            throw error;
        }
    },

    /**
     * Updates a phrase active state
     * @param phraseId {Number}
     * @param isActive {Boolean}
     * @param refreshPhrases {Boolean}
     * @returns {Promise<void>}
     */
    async togglePhraseActive({state, dispatch}, {phraseId, isActive, refreshPhrases = false}) {
        try {
            if (!phraseId) throw new Error('A valid Phrase is required');

            console.log('setting phrase active: ', isActive);

            await mash.put(`/rest/accounts/${state.account.code}/phrases/${phraseId}`, {active: isActive});

            // in handlebars the brand did not refresh, adding if stmt here for same effect
            if (refreshPhrases) {
                dispatch('refreshPhrases', true);
            }
        } catch (error) {
            console.warn(`An error occurred while updating phrase active state for phraseId: ${phraseId}, error: `, error);
            throw error;
        }
    }


};