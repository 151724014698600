<template>

    <div class="root-brand-description" :class="frameworkStyle">
        <TooltipComponent>
            <div class="heading">Descriptions for the Crowd</div>
            <template #tooltip>Brand descriptions which are visible to the Crowd</template>
        </TooltipComponent>

        <div class="descriptions dark-scrollbars dark-scrollbars--visible">
            <TooltipComponent>
                <div class="description" :class="{'can-edit': brand.canEdit}"
                     v-for="(description, index) in brand.description" :key="index"
                     @click="showEditDialog('update', description, index)">
                        <div class="language">{{renderLanguage(index)}}</div>
                        <div class="description-text">{{description}}</div>
                        <i v-if="brand.canEdit" class="icon-pencil"></i>
                </div>
                <template #tooltip>Edit the description</template>
            </TooltipComponent>
        </div>

        <div @click="showEditDialog('create')" class="add-description" v-if="brand.canEdit">
            <i class="icon-plus"></i>
            <span>
                Add a description
            </span>
        </div>

    </div>

</template>

<script>
import renderLanguage from "../../../helpers/renderLanguage";
import {isAccountAdmin} from "@/app/Permissions";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import EditBrandDescriptionDialog from "@/setup/brands/forms/EditBrandDescriptionDialog.vue";
import {features} from "@/app/Features";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";

export default {
    name: "BrandDescriptionSection",
    components: {TooltipComponent},
    props: ['brand'],
    data() {
        return {
            frameworkStyle: '',
        }
    },

    mounted() {
        this.frameworkStyle = features.rootBrandsVueDescription() ? 'for-handlebars' : 'for-vue';
    },

    methods: {
        isAccountAdmin,
        renderLanguage,
        showEditDialog(operation, description, languageCode) {
            showDialogComponent(EditBrandDescriptionDialog, {
                operation: operation,
                inputLanguage: languageCode ? languageCode : '',
                inputDescription: description ? description : '',
                brand: this.brand
            })
        }
    }
}
</script>

<style scoped lang="sass">

.can-edit
    pointer-events: auto
    &:hover
        background-color: rgba(255, 255, 255, 0.05)
        cursor: pointer


.root-brand-description
    margin-bottom: 20px
    position: relative
    border: 1px solid #575757
    border-radius: 3px
    height: fit-content
    max-height: 250px
    background: rgba(255, 255, 255, 0.05)


    .heading
        background-color: var(--dialog-title-background-colour)
        color: #eee
        text-align: center
        padding: 3px

    .descriptions
        display: flex
        flex-direction: column
        gap: 10px
        width: 100%
        max-height: 150px
        overflow-y: auto
        pointer-events: none

        .description
            display: flex
            flex-direction: row
            padding: 3px

            .language
                width: 20%
                margin: auto

            .description-text
                width: 73%

            .icon-pencil
                width: 7%
                margin: auto

    .add-description
        text-align: center
        padding: 3px
        border-top: 1px solid #575757

        &:hover
            background-color: rgba(255, 255, 255, 0.05)
            cursor: pointer


.for-handlebars
    width: 39%
    position: relative
    float: right

.for-vue
    width: 50%

</style>