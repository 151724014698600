<template>
    <div class="location-input">
        <div class="location-input__current-selected">
            <slotted-tag v-if="currentSelected" no-close/>
        </div>

        <popup-menu fixed :value="!!filteredLocationSuggestions.length"
                    ignore-activator-click :arrow-pointer="{show: true, left: true} ">
            <template #activator>
                <span class="location-input__search tag-input uneditable-input span12" :class="{'focus': hasFocus, 'disabled': disabled, 'error': requiredError}" @click="setSearchFocus">
                    <span v-if="!currentSelected.length && !hasFocus" class="placeholder">Start typing a location</span>
                    <slotted-tag class="location-input__selected-location" v-for="location in currentSelected" :key="location.id" @close="unselectLocation(location)">
                        {{ location.label }}
                    </slotted-tag>
                    <input type="text" v-model="searchTerm" class="search-input" ref="search" @focus="hasFocus = true" @blur="hasFocus = false"/>
                </span>
            </template>
            <div v-if="filteredLocationSuggestions.length"
                 class="location-input__suggestions dark-scrollbars dark-scrollbars--visible">
                <ul v-if="filteredGroups.length">
                    <li class="header">Geographic Regions</li>
                    <li v-for="suggestedLocation in filteredGroups" :key="suggestedLocation.id">
                        <a @click="addSuggestedLocation(suggestedLocation)">{{ suggestedLocation.label }}</a>
                    </li>
                </ul>
                <ul v-if="filteredCountries.length">
                    <li class="header">Countries</li>
                    <li v-for="suggestedLocation in filteredCountries" :key="suggestedLocation.id">
                        <a @click="addSuggestedLocation(suggestedLocation)">{{ suggestedLocation.label }}</a>
                    </li>
                </ul>
            </div>
        </popup-menu>
        <span v-if="requiredError" class="location-input__error-message">Required</span>
    </div>

</template>

<script>
import PopupMenu from "@/components/PopupMenu";
import {mapActions, mapState} from "vuex";
import VuexStore from "@/store/vuex/VuexStore";
import SlottedTag from "@/components/tags/SlottedTag";

export default {
    name: "LocationInput",
    components: {SlottedTag, PopupMenu},
    store: VuexStore,

    model: {
        prop: 'selected',
        event: 'selected-changed'
    },

    props: {
        selected: {
            type: Array,
            default: () => []
        },
        disabled: Boolean,
        onlyOne: Boolean,
        required: Boolean,
        includeGroups: {
            type: Boolean,
            default: true
        },
        includeCountries: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            searchTerm: "",
            hasFocus: false,
            currentSelected: []
        }
    },

    async created() {
        for (const loc of this.selected) {
            let id = loc.id || loc
            let location = await this.getLocation(id);

            if (!location.label.includes("unknown location")) this.currentSelected.push(location)
        }
    },

    computed: {
        ...mapState('locations', ['countryIdToLocation', 'groupIdToLocation']),

        filteredLocationSuggestions() {
            let list = [];

            if (this.includeCountries) list = this.filteredCountries;
            if (this.includeGroups) list = [...list, ...this.filteredGroups];

            return list;
        },

        filteredCountries() {
            let st = this.searchTerm.toLowerCase();

            return this.searchCountries(st);
        },

        filteredGroups() {
            let st = this.searchTerm.toLowerCase();

            return this.searchGroups(st);
        },

        requiredError() {
            return this.required && !this.currentSelected.length;
        }
    },

    methods: {
        ...mapActions('locations', ['getLocation', 'searchCountries']),

        searchGroups(searchValue) {
            let results = [];
            if (!searchValue) return [];

            Object.keys(this.groupIdToLocation).forEach(countryKey => {
                let location = this.groupIdToLocation[countryKey];

                if (location.group?.toLowerCase()?.includes(searchValue) || location.groupDisplay?.toLowerCase()?.includes(searchValue) || location.id.toLowerCase().includes(searchValue)
                    || location.value.toLowerCase().includes(searchValue)) {
                    results.push(this.standardise(location));
                }
            });

            return results;
        },

        searchCountries(searchValue) {
            let results = [];
            if (!searchValue) return [];

            Object.keys(this.countryIdToLocation).forEach(countryKey => {
                let location = this.countryIdToLocation[countryKey];

                if (location.country?.toLowerCase()?.includes(searchValue)
                    || location.countryDisplay?.toLowerCase()?.includes(searchValue)
                    || location.id.toLowerCase().includes(searchValue)
                    || location.value.toLowerCase().includes(searchValue)) {
                    results.push(this.standardise(location));
                }
            });

            return results;
        },

        setSearchFocus() {
            this.$refs.search.focus();
        },

        standardise(location) {
            return {
                id: location.id,
                label: location.value || location.countryDisplay || location.groupDisplay,
            }
        },

        addSuggestedLocation(location) {
            if (this.onlyOne) {
                this.currentSelected = [location];
            } else {
                if (!this.currentSelected.find(loc => loc.id === location.id)) {
                    this.currentSelected.push(location);
                }
            }

            this.$emit('selected-changed', this.currentSelected);

            this.searchTerm = "";
        },

        unselectLocation(location) {
            if (this.onlyOne){
                this.currentSelected = [];
            } else {
                this.currentSelected = this.currentSelected.filter(selectedLocation => location !== selectedLocation)
            }

            this.$emit('selected-changed', this.currentSelected);
        }
    }
}
</script>

<style scoped lang="sass">

.location-input

    &__current-selected
        display: flex
        align-items: center

    &__search

        &.error
            border-color: #FF6663

        .placeholder
            color: var(--bootstrap-placeholder)
            position: relative
            top: 1px
            font-size: 10pt

        .search-input
            width: 50px
            background: transparent
            border: none
            padding: 0
            margin: 0 !important

            &:focus
                outline: none
                box-shadow: none

    &__selected-location
        margin-right: 5px

    &__error-message
        color: #ff6663


    &__suggestions
        margin: 3px 0
        min-width: 125px
        max-height: 300px
        overflow-y: auto

        .header
            background: #222222
            padding: 3px 10px
            margin-bottom: 3px
            font-size: 12px
            text-align: center
            text-transform: uppercase
            color: var(--clr-sidebar-header) !important
            pointer-events: none


        ul
            list-style: none
            margin: 0

        li > a
            font-weight: 400
            color: #ffffff
            cursor: pointer
            display: block
            padding: 3px 10px
            clear: both
            white-space: nowrap

            &:hover
                color: #aee15d
                text-decoration: none
                background-color: #222222

.disabled
    background: #222
    pointer-events: none

</style>