<template>

   <div ref="phraseTermPicker" class="phrase-term-picker">
       <label v-if="label.length" class="phrase-term-picker-label">{{label}}</label>
       <div ref="selectedTerms" class="deq-input-div ptp-container" @click="focusPicker">
               <SlottedTag class="selected-terms" v-for="term in selectedTerms" :key="term" @close="onRemoveTerm(term)">
                   <span v-html="renderPhrase(term)"></span>
               </SlottedTag>
           <p class="placeholder" v-if="placeholder.length">{{placeholder}}</p>
           <div tabindex="0" class="term-input" ref="termInput"  @keydown="onKeyDown" contenteditable="true" @focusout="onBlur"></div>
       </div>
   </div>

</template>

<script>
import {encloseInQuotes} from "@/app/utils/StringUtils";
import {parsePhraseString} from "@/app/utils/Phrases";
import renderPhrase from "@/helpers/renderPhrase";
import SlottedTag from "@/components/tags/SlottedTag.vue";

/** 'terms-selected' */
const termsSelected = 'terms-selected';


/**
 * originalPhraseTerms: A string of phrases terms
 */
export default {
    name: "PhraseTermPickerComponent",
    components: {SlottedTag},
    model: {
        prop: 'originalPhraseTerms',
        event: termsSelected
    },
    props: {
        originalPhraseTerms: {
            type: String,
            required: false,
            default: ''
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        placeholderText: {
            type: String,
            required: false,
            default: 'Enter words or phrases to include here'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        }

    },

    data() {
        return {
            selectedTerms: this.originalPhraseTerms?.length > 0 ? parsePhraseString(this.originalPhraseTerms) : [],
            placeholder: this.originalPhraseTerms?.length === 0 ? this.placeholderText : ''
        }
    },

    methods: {
        renderPhrase,

        focusPicker() {
            if (!this.disabled) {
                this.$refs.termInput.focus();
                this.placeholder = '';
            }
        },

        getPlaceholder() {
            if (this.selectedTerms?.length === 0) {
                this.placeholder = this.placeholderText;
                return
            }
            this.placeholder = '';
        },

        onKeyDown(event) {
            if (!this.disabled) {
                if (event.key === "Enter") {
                    const enteredTerm = this.$refs.termInput.innerText.trim()
                    event.preventDefault();
                    event.stopPropagation();

                    this.selectedTerms.push(this.cleanPhraseTerm(enteredTerm));

                    this.$refs.termInput.innerText = '';
                    this.emitData()
                }
            }
        },

        onRemoveTerm(term) {
            if (!this.disabled) {
                this.selectedTerms = this.selectedTerms.filter(selectedTerm => selectedTerm !== term);
                this.getPlaceholder();
                this.emitData();
            }
        },

        cleanPhraseTerm(value) {
            if (!value) return null;
            value = value.replace(/['"*]+/g, '').trim();
            if (value.indexOf('+') === 0) value = value.substring(1);
            return value;
        },

        emitData() {
            const mappedTerms = this.selectedTerms.map(term =>  encloseInQuotes(term, '"'));
            const joined = mappedTerms.join(" ");
            this.$emit(termsSelected, joined);
        },

        onBlur() {
            this.getPlaceholder();
        }

    }
}
</script>

<style scoped lang="sass">
.term-input:empty
    display: block

.term-input
    outline: none
    margin-left: 5px
    display: inline-block

.ptp-container
    padding: 3px 3px 3px 3px
    display: flex
    flex-direction: row
    flex-wrap: wrap
    gap: 5px

    .placeholder
        margin: auto 0 auto 5px
        font-size: 10pt
        color: var(--bootstrap-placeholder)


    &:focus-within
        border-color: var(--be-input-focus-border-color)
        box-shadow: var(--be-input-focus-box-shadow)


.disabled
    pointer-events: none

::v-deep .tag
    margin-right: 0 !important
    
</style>