import { profileTypes } from "@/setup/profiles/ProfileUtils";
import { beef } from "@/store/Services";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let managedTiktokProfilesPromise = null;
let initializedTiktokProfiles = false;

export default {
    namespaced: true,

    state: {
        tiktokLoginStatus: {
            loggedIn: false, // is the user currently logged in through Tiktok?
            loginInvalidated: false // has the user's Tiktok login been invalidated?
        },
        tiktokLoggedInAs: null,

        managedTiktokProfiles: []
    },

    mutations: {
        setTiktokLoginStatus: (state, value) => state.tiktokLoginStatus = value,
        setTiktokLoggedInAs: (state, value) => state.tiktokLoggedInAs = value,
        setManagedTiktokProfiles: (state, value) => state.managedTiktokProfiles = value
    },

    actions: {
        async initializeManagedTiktokProfiles({commit, state}, forceRefresh) {
            forceRefresh ??= false;
            if (!forceRefresh && initializedTiktokProfiles) return;
            let response = null;
            if (managedTiktokProfilesPromise) response = await managedTiktokProfilesPromise;
            else {
                try{
                    managedTiktokProfilesPromise = beef.get("/api/online-profile/tiktok/list");
                    response = await managedTiktokProfilesPromise;
                }finally{
                    managedTiktokProfilesPromise= null;
                }
            }
            if (response.status === 200) {
                initializedTiktokProfiles = true;
                response.data?.forEach(tiktok => {
                    tiktok.type = profileTypes.tikTok;
                })
                commit('setManagedTiktokProfiles', response.data);
            }
        }
    }
};
