<template>
    <DialogBox modal :title="user ? `Edit the user details for ${user.name}` : 'Add a user'" class="account-user-dialog" width="550px" @close="emitClose()">

        <div class="deq-reset aud-form">

            <template  v-if="!isLoading && activePage === 'emailPage'">
                <TextInput v-if="!user" v-model="formData.email.value" label="Email" :rules="emailRule" width="100%" class="ud-input email" placeholder="Email" required/>
                <p v-if="formData.email.error" class="aud-error">{{formData.email.error}}</p>
                <p v-if="userLimitReached" class="aud-error">You have reached your account limit of {{account.maxUsers}} {{account.maxUsers > 1 ? 'users' : 'user'}}</p>
            </template>

            <template v-if="!isLoading && activePage === 'mainPage'">
                 <div class="name">
                     <TextInput v-model="formData.firstName.value" label="First Name" width="100%" class="ud-input fname"
                                :disabled='canEditName' placeholder="First name" required :is-invalid="formData.firstName.error"/>
                     <TextInput v-model="formData.lastName.value" label="Last Name" width="100%" class="ud-input lname"
                                :disabled='canEditName' placeholder="Last name" required/>
                 </div>

                <div class="permissions">
                    <PickerButtonGroup :options="permissionOptions" v-model="formData.selectedPermissions.value" label="Permission Level" :disabled="isPermEditable"/>
                    <div v-if="formData.selectedPermissions.error" class="form-error">Required</div>
                </div>

                <div class="aud-control">
                    <label class="mention-label">View Private Mentions</label>
                    <div class="mention-buttons">
                        <PickerButtonGroup :options="mentionToggleOptions" v-model="selectedMentionToggle" @selected="onToggleSelected" class="private-mentions-toggle"/>
                        <PickerButtonGroup :options="mentionTypes" v-model="formData.selectedMentionTypes.value" @selected="onMentionSelected" is-multi-select class="private-mentions"/>
                    </div>
                </div>
                <div v-if="formData.selectedMentionTypes.error" class="form-error">Required</div>

                <div>
                    <label class="teams-label">Teams</label>
                    <ul class="teams">
                            <!--  TODO: display team descriptions-->
                            <CheckboxList v-model="formData.teams.value" :options="accountTeams"/>
                    </ul>
                </div>
            </template>

            <LoadingMessage v-if="isLoading" message="Loading User form" class="user-dialog-loader"/>

        </div>

        <template #buttons>
            <div class="deq-dialog-buttons aud-buttons">

                <BeButton danger class="delete">Delete</BeButton>

                <spinner-component v-if="isProcessing" class="aud-spinner" :size="16"/>

              <div class="right-side">
                  <BeButton @click="emitClose" class="cancel">Cancel</BeButton>
                  <BeButton v-if="activePage === 'emailPage'" @click="onNext" :disabled="isProcessing || userLimitReached" primary class="ok">Next</BeButton>
                  <BeButton v-if="activePage === 'mainPage'" @click="onSave" :disabled="isProcessing" primary class="ok">Ok</BeButton>
              </div>

            </div>
        </template>
    </DialogBox>

</template>
<script>
import DialogBox from "@/components/DialogBox.vue";
import PickerButtonGroup from "@/components/inputs/PickerButtonGroup.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import BeButton from "@/components/buttons/BeButton.vue";
import {AccountUser} from "@/app/utils/types";
import {mapActions, mapGetters, mapState} from "vuex";
import VuexStore from "@/store/vuex/VuexStore";
import LoadingMessage from "@/components/LoadingMessage.vue";
import CheckboxList from "@/components/inputs/CheckboxList.vue";
import {isAccountAdmin, isMashAdmin} from "@/app/Permissions";
import {notifyUser} from "@/app/framework/notifications/Notifications";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import {mentionTypes} from "@/settings/users/users-utils";

export default {
    name: "UserDialog",
    store: VuexStore,
    props: {
        user: {
            type: AccountUser,
            required: false
        }
    },
    components: {
        SpinnerComponent,
        CheckboxList,
        LoadingMessage, BeButton, PickerButtonGroup, TextInput, DialogBox},

    data() {
        return {
            /** @type {'emailPage' | 'mainPage'}*/
            activePage: 'emailPage',

            permissionOptions: [
                { title: "Full", description: "User can edit everything except for users"},
                { title: "Read", description: "User can see everything but cannot make changes"},
                { title: "Reporting", description: "User can see everything but can only edit dashboards"},
                { title: "Admin", description: "User can edit everything including users"}
            ],

            mentionToggleOptions: [{title: 'All', description: 'Can view all private data'}, {title: 'None', description: 'Can only view public mentions'}],
            selectedMentionToggle: [],
            isLoading: true,
            isProcessing: false,

            formData: {
                firstName: {
                    value: this.user?.firstName || '',
                    error: false,
                },
                lastName: {
                    value: this.user?.lastName || '',
                    error: false
                },
                email: {
                    value: this.user?.email || '',
                    error: false,
                },
                selectedPermissions:  {
                    value: [],
                    error: false
                },
                selectedMentionTypes:  {
                    value: [],  // will contain 0 or 1 string items
                    error: false
                },
                teams:  {
                    value: [],
                    error: false
                },
            },

            emailRule: [value => {
                if (!value.includes('@') && !value.includes('.')) {
                    return 'Must be a valid email'
                }
            }]
        }
    },

    computed: {
        ...mapState(['accountTeams']),
        ...mapState(['account']),
        ...mapState({loggedInUser: state => state.user}),
        ...mapGetters(['findAccountTeamsByUserId']),

        isPermEditable() {
            return isAccountAdmin() && this.loggedInUser.id !== this.user?.id || isMashAdmin()
        },

        canEditName: function() {
            return this.loggedInUser.id === this.user?.id || isMashAdmin();
        },

        mentionTypes() {
            return mentionTypes
        },

        userLimitReached() {
            return this.account?.usage?.users === this.account.maxUsers;
        }
    },


    mounted() {
        this.isLoading = true;

        if (this.user) {
            this.activePage = 'mainPage';
        } else {
            this.activePage = 'emailPage';
        }

        this.setPermissionsControl();
        this.setMentionsControls();

        this.formData.teams.value = this.findAccountTeamsByUserId(this.user?.id)?.map(team => team.id);

        this.isLoading = false;
    },

    methods: {
        ...mapActions('accountUsers', ['refreshAccountUsers', 'updateUser', 'checkForEmail', 'createUser']),
        ...mapActions(['updateTeamUser']),


        emitClose() {
            this.$emit('close');
        },

        setPermissionsControl() {
            if (this.user) {
                this.formData.selectedPermissions.value = this.permissionOptions.filter(pOption => {
                    return pOption.title.toLowerCase() === this.user?.permissions.toLowerCase();
                }).map(option => option.title)
            }
        },

        setMentionsControls() {
            if (this.user?.privateMentions && this.user?.privateMentions.length) {
                if (this.user.privateMentions?.includes('*')) {
                    this.formData.selectedMentionTypes.value = mentionTypes.map(option => option.title);
                    this.selectedMentionToggle = ['All']
                } else {
                    this.formData.selectedMentionTypes.value = this.user.privateMentions
                        .filter(selected => mentionTypes.filter(option => option.value === selected))
                        .map(selectedOption => mentionTypes.find(option => option.value === selectedOption).title);
                }
            } else {
                this.selectedMentionToggle = ['None']
            }
        },

        /**
         *
         * @param val {String[]}
         */
        onToggleSelected(val) {
            if (val[0] === 'All') {
                if (this.selectedMentionToggle.includes('All')) {
                    this.formData.selectedMentionTypes.value = mentionTypes.map(option => option.title);
                }
            }

            if (val[0] === 'None') {
                if (this.selectedMentionToggle.includes('None')) {
                    this.formData.selectedMentionTypes.value = [];
                }
            }
        },

        /**
         *  Updates the selectedMentionToggle value based on the mentionOptions array
         * @param mentions {String[]}
         */
        onMentionSelected(mentions) {
            if (mentions.length === mentionTypes.length) {
                this.selectedMentionToggle = ['All'];
            } else if  (mentions.length === 0) {
                this.selectedMentionToggle = ['None'];
            } else {
                this.selectedMentionToggle = [];
            }
        },

        async onNext() {
            this.isProcessing = true;

            if (this.userLimitReached) {
                notifyUser(`You have reached your account limit of ${this.account.maxUsers} ${this.account.maxUsers > 1 ? 'users' : 'user'}`);
                this.isProcessing = false;
            } else if (this.isBadEmail()) {
                console.warn('The email is invalid: ')
                this.isProcessing = false;
            } else {

                try {
                    const checkedUser = await this.checkForEmail(this.formData.email.value.trim());

                    if (!checkedUser.email && !checkedUser.id) {
                        this.activePage = 'mainPage';
                    } else {
                        this.activePage = 'emailPage';
                        this.formData.email.error = 'User already exists';
                        console.warn('User already exists');
                    }
                } catch (e) {
                    console.warn('An error occurred while checking email: ', e)
                } finally {
                    this.isProcessing = false;
                }

            }
        },

        async onSave() {
            this.isProcessing = true;
            if (this.isBadEmail() || this.isBadFirstName() || this.isBadLastName() || this.isBadPermissions()
                    || this.isBadMentions()) {
                console.warn('The form is invalid: ', this.formData)
            } else {

                const request = {
                    id: this.user?.id || null,
                    firstName: this.formData.firstName.value,
                    lastName: this.formData.lastName.value,
                    name: `${this.formData.firstName.value.trim()} ${this.formData.lastName.value.trim()}`,
                    email: this.formData.email.value.trim(),
                    mainUser: this.user?.mainUser || false,
                    accessStatus: this.user?.accessStatus || true,
                    accountCode: this.user?.accountCode,
                    permissions: this.formData.selectedPermissions.value[0].toUpperCase()
                }

                if(this.selectedMentionToggle.length) {
                    if(this.selectedMentionToggle[0] === 'All') {
                        request.privateMentions = ['*']
                    }

                    if(this.selectedMentionToggle[0] === 'None') {
                        request.privateMentions = []
                    }
                } else if(this.formData.selectedMentionTypes.value?.length) {
                    request.privateMentions = this.formData.selectedMentionTypes.value.map(selectedType => mentionTypes.find(option => option.title === selectedType).value);
                } else {
                    request.privateMentions = []
                }


               try {

                   if (this.user) {

                       const originalTeams = this.findAccountTeamsByUserId(this.user.id)?.map(team => team.id);
                       const newlyAddedTeams = this.formData.teams.value.filter(selectedTeam => !originalTeams.includes(selectedTeam));
                       const removedTeams = originalTeams.filter(originalTeam => !this.formData.teams.value.includes(originalTeam));

                       const updatedUser = await this.updateUser(request);

                       for (const newTeam of newlyAddedTeams) {
                           await this.updateTeamUser({teamId: newTeam, userId: updatedUser.id})
                       }

                       for (const newTeam of removedTeams) {
                           await this.updateTeamUser({teamId: newTeam, userId: updatedUser.id, isDelete: true})
                       }

                       notifyUser(`Successfully updated user: ${request.name}`)
                       this.emitClose();

                   } else {

                       const newUser = await this.createUser(request);
                       for (const newTeam of this.formData.teams.value) {
                           await this.updateTeamUser({teamId: newTeam, userId: newUser.id})
                       }
                       notifyUser(`Successfully created user: ${newUser.name}`)
                       this.emitClose();
                   }

               } catch (e) {
                   console.warn("An error occurred while saving user: ", e)
               } finally {
                   this.isProcessing = false;
               }
            }
        },

        isBadEmail() {
            if (!this.formData.email.value.length) {
                this.formData.email.error = true;
                return true
            } else {
                this.formData.email.error = false;
                return false
            }
        },

        isBadFirstName() {
            if (!this.formData.firstName.value.length) {
                this.formData.firstName.error = true;
                return true
            } else {
                this.formData.firstName.error = false;
                return false
            }
        },

        isBadLastName() {
            if (!this.formData.lastName.value.length) {
                this.formData.lastName.error = true;
                return true
            } else {
                this.formData.lastName.error = false;
                return false
            }
        },

        isBadPermissions() {
            if (!this.formData.selectedPermissions.value.length) {
                this.formData.selectedPermissions.error = true;
                return true;
            } else {
                this.formData.selectedPermissions.error = false;
                return false;
            }
        },

        isBadMentions() {
             if (!this.selectedMentionToggle.length && !this.formData.selectedMentionTypes.value.length) {
                 this.formData.selectedMentionTypes.error = true;
                 return true;
            } else {
                 this.formData.selectedMentionTypes.error = false;
                 return false;
             }

        }

    }
}
</script>

<style scoped lang="sass">

.user-dialog-loader
    margin: auto
    --loading-message-delay: 50ms

.ud-input
    ::v-deep input
        padding: 15px

.mention-buttons
    grid-area: mentionButtons
    display: flex
    flex-direction: row
    gap: 10px

.form-error
    grid-area: formError
label
    margin-bottom: 0

.teams-label
    grid-area: teamsLabel

.teams
    grid-area: teams
    list-style: none
    margin: 0 0 0 0

    .team
        display: flex
        flex-direction: row
        flex-wrap: wrap
        p
            margin: 0

.aud-form
    display: flex
    flex-direction: column
    gap: 10px

    .aud-error
        color: #ff6663

    .name
        display: flex
        flex-direction: row
        gap: 10px

    .fname, .lname
        flex-grow: 1

    .aud-control
            gap: 0
            display: contents



.aud-buttons
    display: flex
    flex-direction: row
    justify-content: space-between

    .aud-spinner
        margin: auto

    .right-side
        display: flex
        flex-direction: row
        gap: 10px


.permissions
    ::v-deep label
        margin: 0

.form-error
    color: var(--ac-colour-suspended)

</style>