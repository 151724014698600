export class QueryForm {
    /**
     * @type {String}
     */
    query;
    /**
     * @type {String}
     */
    includeTerms;
    /**
     * @type {String}
     */
    excludeTerms;
    /**
     * @type {Boolean}
     */
    active;
    /**
     * @type {Boolean}
     */
    deleted;
    /**
     * @type {String}
     */
    feeds;
    /**
     * @type {String}
     */
    socialNetworks;
    /**
     * @type {String}
     */
    mentionFilter;
    /**
     * @type {String}
     */
    conversationLanguages;
    /**
     * @type {String}
     */
    authorBioLocation;
    /**
     * @type {Boolean}
     */
    webhose;
    /**
     * @type {Boolean}
     */
    twitter;
}