<template>
    <dialog-box overlay @close="close" width="865px" class="user-settings-dialog" title="Your user options">

        <div class="user-settings-dialog__container">
            <sidebar-component class="user-settings-dialog__sidebar">
                <sidebar-menu>
                    <sidebar-header>
                        <h4>User options</h4>
                    </sidebar-header>
                    <sidebar-menu-item
                        @click="makeActive('user_details')"
                        :active="userDetailsActive">
                        <i class="symbol-user"></i>
                        Personal Details
                    </sidebar-menu-item>
                    <sidebar-menu-item
                        @click="makeActive('tipsAndHelp')"
                        :active="tipsAndHelpActive">
                        <i class="symbol-tip"></i>
                        Help & Tips
                    </sidebar-menu-item>
                    <sidebar-menu-item
                        v-if="canEditProfiles"
                        @click="makeActive('platform_auth')"
                        :active="platformAuthActive">
                        <i class="symbol-online-profiles"></i>
                        Platform Authorisation
                    </sidebar-menu-item>
                    <sidebar-menu-item
                        v-if="showNotifications"
                        @click="makeActive('notifications')"
                        :active="notificationsActive">
                        <i class="symbol-notification"></i>
                        Your Alerts
                    </sidebar-menu-item>
                    <sidebar-menu-item
                        v-if="user.originalAdmin"
                        @click="makeActive('features')"
                        :active="featuresActive">
                        <i class="icon-star-1"></i>
                        Feature Flags
                    </sidebar-menu-item>
                </sidebar-menu>
            </sidebar-component>

            <div class="settings-content">
                <keep-alive>
                    <user-details-settings
                        :first-name="firstName"
                        :last-name="lastName"
                        :non-admin-mode="nonAdminMode"
                        :disabled="saving"
                        @update-user-details="updateUserDetails"
                        @non-admin-mode-updated="updateNonAdminMode"
                        v-if="userDetailsActive" ref="userDetails"></user-details-settings>

                    <tips-settings
                        :view-tips="viewTips"
                        @view-tips-updated="updateViewTips"
                        v-if="tipsAndHelpActive" ref="tipsAndHelp"></tips-settings>

                    <notifications-settings v-if="notificationsActive" @goto-notifications="gotoNotifications"/>

                    <platform-auth-settings
                        v-if="platformAuthActive"
                        :options="platformAuthOptions">
                    </platform-auth-settings>

                    <feature-flag-settings
                        :feature-flags="featureFlags"
                        :non-admin-mode="nonAdminMode"
                        @feature-flag-updated="updateFeatureFlags"
                        @non-admin-mode-updated="updateNonAdminMode"
                        v-if="featuresActive" ref="features"></feature-flag-settings>
                </keep-alive>
            </div>
        </div>

        <template #buttons>
            <div class="user-settings-dialog__button-bar">
                <be-button v-if="hasChangesToSave" link
                           class="bottom-button short-animated fadeIn"
                           @click="close"
                           :disabled="saving">Cancel</be-button>
                <be-button primary
                           class="bottom-button"
                           :disabled="saving"
                           @click="saveClicked">
                    {{saveButtonTitle}}
                    <spinner-component :size="11" class="save-spinner" v-if="saving"></spinner-component>
                </be-button>
            </div>
        </template>
    </dialog-box>
</template>

<script>
import { isDebugModeEnabled, loadLocalFlags, saveLocalFlags, setTurnOffAdmin, shouldTurnOffAdmin } from "@/app/Features";
import { editOnlineProfiles } from "@/app/Permissions";
import { showAskDialog, showErrorDialog } from "@/app/framework/dialogs/Dialog";
import NotificationsSettings from "@/app/framework/dialogs/user-settings/views/NotificationsSettings";
import { platforms } from "@/app/framework/dialogs/user-settings/views/platform-auth/PlatformAuthUtils";
import { notifyUser, notifyUserOfError, notifyWithText } from "@/app/framework/notifications/Notifications";
import DialogBox from "@/components/DialogBox";
import SpinnerComponent from "@/components/SpinnerComponent";
import BeButton from "@/components/buttons/BeButton";
import SidebarComponent from "@/components/sidebar/SidebarComponent";
import SidebarHeader from "@/components/sidebar/SidebarHeader";
import SidebarMenu from "@/components/sidebar/SidebarMenu";
import SidebarMenuItem from "@/components/sidebar/SidebarMenuItem";
import { gotoNotifications } from "@/setup/notifications/SetupNotificationUtilities";
import { mash } from "@/store/Services";
import { deprecatedUserObject as userStore } from "@/store/deprecated/Stores"; // Used to ensure that old user store values are also updated.
import VuexStore from "@/store/vuex/VuexStore";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import FeatureFlagSettings from "./views/FeatureFlagSettings";
import TipsSettings from "./views/TipsSettings";
import UserDetailsSettings from "./views/UserDetailsSettings";
import PlatformAuthSettings from "./views/platform-auth/PlatformAuthSettings";

export default {
    name: "UserSettings",
    store: VuexStore,
    components: {
        SpinnerComponent,
        SidebarHeader,
        NotificationsSettings,
        PlatformAuthSettings,
        BeButton,
        TipsSettings,
        UserDetailsSettings,
        FeatureFlagSettings,
        SidebarComponent,
        SidebarMenu,
        SidebarMenuItem,
        DialogBox
    },

    props: {
        openView: String,

        /**
         * Options for platform auth settings. the following fields are valid:
         *
         * @param {String} selectedPlatform: the platform that the settings should open on immediately, see {@link platforms} for possible values (required).
         * @param {Object} authorise: authorisation settings.
         * @param {Boolean} authorise.trigger: true if authorisation should be triggered immediately, false otherwise.
         * @param {Object} authorise.profile: the profile that should be authorised. Only required if authorise.trigger is true and selectedPlatform is {@link platforms.facebook}.
         * @param {String} authorise.profile.handle: the handle of the profile that should be authorised.
         * @param {String} authorise.profile.handleId: the handle ID of the profile that should be authorised.
         */
        platformAuthOptions: {
            type: Object,
            validator: options => {
                let valid = !!options.selectedPlatform;

                if (options.authorise) {
                    let auth = options.authorise;
                    if (auth.trigger) {
                        if (options.selectedPlatform && options.selectedPlatform !== platforms.facebook) {
                            valid = valid && auth.profile?.handle && auth.profile?.handleId;
                        }
                    }
                }

                return valid;
            }
        }
    },

    data() {
        return {
            saving: false,
            nonAdminMode: shouldTurnOffAdmin(),
            featureFlags: [
                {name: "debugMode", description: "🐛 See extra debugging information", value: false},
                {name: "reportSpamAuthors", description: "Test the ability to report authors as spammers", value: false},
                {name: "showCitations", description: "Turn on citations in the mention panel summary", value: false},

                {name: "dragAndDrop", description: "Test the new drag & drop functionality", value: false},
                {name: "newMentionConversationView", description: "Test updates to the mention conversation view"},
                {name: "otherCalculationFixes", description: "Test fixes for calculating Others in the Fantastic Charts", value: false},
                {name: "newMentionsPanel", description: "Improvements to the mentions panel", value: false},
                {name: "chartSegmentListPercentageChanges", description: "Test new percentage calculations for segment lists on the fantastic chart", value: false},
                {name: "googleManagedBusinessIntegration", description: "Test Managed Google Business integration features (adding managed business profiles)", value: false},
                {name: "newProfilePicker", description: "Test the new profile picker", value: false},
                {name: "commentaryPlusInWidget", description: "WYSIWYG Editor for comments made in widgets", value: false},
                {name: "upgradeDashBoardsToCommentPlus", description: "Test upgrading dashboards to the new commentary plus", value: false},
                {name: "feedsVueOverhaul", description: "Test feeds page as Vue.js component", value: false},
                {name: "importMentionsObjectBugFix", description: "Test Import Mentions page Object type upload", value: false},
                {name: "topicsVueMigration", description: "Test the new Vue.js Topics page", value: false},
                {name: "vueMigrationRootBrandsPage", description: "Root Brands Page with Vue.js", value: false},
                {name: "rootBrandsVueDescription", description: "Root Brands - Vue.js edit description dialog", value: false},
                {name: "vueMigrationCrowdVerification", description: "Use the new Vue.js Crowd verification page", value: false},
                {name: "vueMigrationAccountUsers", description: "Use the new Vue.js Users page", value: false},
                {name: "youtubePlatformAuth", description: "Platform auth for youtube", value: false},
                {name: "testingTripe", description: "Testing using tripe for trends and topics", value: false},
                {name: "engageIndirectReplies", description: "Indirect replies for discovery"},
                {name: "surveyReportingEnhancements", description: "Survey reporting enhancements", value: false},
                {name: "commentaryPlusImageToolEnhancement", description: "Optimizing images in the commentary plus by uploading them to biryani", value: false}
            ],
            firstName: null,
            lastName: null,
            viewTips: null,
            canEditProfiles: editOnlineProfiles(),
            old: {
                featureFlags: null,
                nonAdminMode: null,
                firstName: null,
                lastName: null,
                viewTips: null
            },
            userDetailsActive: true,
            tipsAndHelpActive: false,
            featuresActive: false,
            notificationsActive: false,
            platformAuthActive: false,
            showNotifications: false
        }
    },

    computed: {
        ...mapState(['user']),
        ...mapGetters('digests', ['alerts']),

        hasChangesToSave() {
            let userDetailsChanged = this.userDetailsChanged();
            let tipsAndHelpChanged = this.tipsAndHelpChanged();
            let featureFlagsChanged = this.featureFlagsChanged();
            return userDetailsChanged || tipsAndHelpChanged || featureFlagsChanged;
        },

        saveButtonTitle() {
            return this.hasChangesToSave ? "Ok" : "Close";
        }
    },

    async created() {
        const user = this.user;

        this.firstName = this.user.firstName;
        this.lastName =this.user.lastName;
        this.viewTips = this.user.settings?.viewTips ?? true;
        this.old = {
            featureFlags: {},
            nonAdminMode: null,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            viewTips: this.user.settings?.viewTips ?? true
        };

        if (this.openView) {
            this.makeActive(this.openView);
        }

        let features = loadLocalFlags(user.id);
        this.featureFlags.forEach(function (featureFlag) {
            featureFlag.value = !!features[featureFlag.name];
        });

        this.old.featureFlags = loadLocalFlags(user.id);
        this.old.nonAdminMode = shouldTurnOffAdmin(user.id);

        await this.refreshDigests();
        this.showNotifications = !!this.alerts?.length;
    },

    methods: {
        ...mapActions('digests', ['refreshDigests']),
        ...mapMutations(['setUserName', 'setUserViewTips']),

        async gotoNotifications() {
            if (await this.close()) { // Ask if we should close on unsaved changes.
                gotoNotifications();
            }
        },

        updateFeatureFlags(featureFlagName, value) {
            for (let i = 0; i < this.featureFlags.length; i++) {
                if (this.featureFlags[i].name === featureFlagName) {
                    this.featureFlags[i].value = value;
                    break;
                }
            }
        },

        updateNonAdminMode(value) {
            this.nonAdminMode = value;
        },

        updateUserDetails(valueUpdated, value) {
            switch (valueUpdated) {
                case "FIRST_NAME":
                    this.firstName = value;
                    break;
                case "LAST_NAME":
                    this.lastName = value;
                    break;
                default:
                    console.error("unrecognised user details value: ", valueUpdated);
            }
        },

        updateViewTips(value) {
            this.viewTips = value;
        },

        featureFlagsChanged() {
            if (this.nonAdminMode !== this.old.nonAdminMode) {
                return true;
            }

            for (let i = 0; i < this.featureFlags.length; i++) {
                let curFeatureFlag = this.featureFlags[i];
                let oldFeatureFlagValue = this.old.featureFlags[curFeatureFlag.name];

                // feature flag is not part of local storage yet
                if (oldFeatureFlagValue === undefined ) {
                    // user has changed value from default (default value for feature flags is false)
                    if (curFeatureFlag.value) {
                        return true;
                    }
                    continue;
                }

                if (curFeatureFlag.value !== oldFeatureFlagValue) {
                    return true;
                }
            }

            return false;
        },

        userDetailsChanged() {
            if (this.firstName.trim() !== this.old.firstName?.trim()) return true;
            if (this.lastName.trim() !== this.old.lastName?.trim()) return true;

            return false;
        },

        tipsAndHelpChanged() {
            return this.viewTips !== this.old.viewTips;
        },

        async saveSettings(changes) {
            const user = this.user;
            // save user details changes
            if (changes.userDetailsChanged) {
                let payload = {
                    firstName: this.firstName,
                    lastName: this.lastName
                }

                await mash.put("/rest/users/" + user.id, payload);
                this.setUserName({firstName: this.firstName, lastName: this.lastName});
            }

            // save tips and help changes
            if (changes.tipsAndHelpChanged) {
                let payload = {
                    viewTips: this.viewTips
                }

                await mash.put("/rest/users/" + user.id + "/settings", payload);

                // update user store settings
                if (!userStore.settings) userStore.settings = {};
                userStore.settings.viewTips = this.viewTips;
                this.setUserViewTips(this.viewTips);
            }

            // save features flags changes
            if (changes.featureFlagsChanged) {
                let features = loadLocalFlags(user.id);

                this.featureFlags.forEach(function (featureFlag) {
                    features[featureFlag.name] = !!featureFlag.value;
                });
                saveLocalFlags(features);

                setTurnOffAdmin(this.nonAdminMode);
                Vue.set(userStore, 'debugMode', isDebugModeEnabled());
                VuexStore.commit('setUserDebugMode', isDebugModeEnabled());
            }
        },

        async saveClicked() {
            let changes = {
                userDetailsChanged: this.userDetailsChanged(),
                tipsAndHelpChanged: this.tipsAndHelpChanged(),
                featureFlagsChanged: this.featureFlagsChanged()
            }
            let anyChanges = changes.featureFlagsChanged || changes.userDetailsChanged || changes.tipsAndHelpChanged;

            try {
                this.saving = true;

                let validateData = this.validateData();

                if (!validateData.result) {
                    showErrorDialog(validateData.message, "Unable to Save Changes");
                    return;
                }

                await this.saveSettings(changes);

                this.$emit('close');

                if (anyChanges) {
                    notifyUser({
                        message: "Your user settings have been updated.",
                        icon: "<i class='symbol-user'></i>",
                        undo: async () => {
                            try {
                                await this.undoChanges(changes);

                                notifyWithText("Your user setting changes have been undone.",
                                    null,
                                    "<i class='symbol-user'></i>");
                            } catch(e) {
                                console.error("Error undoing user settings", e);
                                notifyUserOfError("Unable to undo your user settings changes due to an error.");
                            }
                        },
                        action: {
                            name: "Reload",
                            tooltip: "Refresh this browser window so that your settings take effect",
                            method: function () {
                                location.reload()
                            }
                        }
                    });
                }
            } catch (e) {
                this.$emit('close');

                console.error("Error saving user settings", e);
                notifyUserOfError("Unable to save your user settings changes due to an error.");
            } finally {
                this.saving = false;
            }
        },

        async undoChanges(changes) {
            const user = this.user;
            // undo feature flag changes
            if (changes.featureFlagsChanged) {
                saveLocalFlags(this.old.featureFlags);
                setTurnOffAdmin(this.old.nonAdminMode, user.id);
                Vue.set(userStore, 'debugMode', this.old.featureFlags.debugMode);
                this.$store.commit('setUserDebugMode', userStore.debugMode);
            }

            // undo user details changes
            if (changes.userDetailsChanged) {
                let payload = {
                    firstName: this.old.firstName,
                    lastName: this.old.lastName
                }

                await mash.put("/rest/users/" + user.id, payload);

                // update user store
                user.firstName = this.old.firstName;
                user.lastName = this.old.lastName;
            }

            // undo tips & help changes
            if (changes.tipsAndHelpChanged) {
                let payload = {
                    viewTips: this.old.viewTips
                }

                await mash.put("/rest/users/" + user.id + "/settings", payload);

                // update user store
                if (user.settings === undefined) {
                    user.settings = {};
                }

                user.settings.viewTips = this.old.viewTips;
            }
        },

        validateData() {
            let validationResult = {};

            if (!this.firstName || this.firstName.trim().length === 0) {
                validationResult.message = "User details: First name cannot be empty.";
                validationResult.result = false;

                return validationResult;
            }

            if (!this.lastName || this.lastName.trim().length === 0) {
                validationResult.message = "User details: Last name cannot be empty.";
                validationResult.result = false;

                return validationResult;
            }

            validationResult.result = true;
            return validationResult;
        },

        /**
         * Returns true iff the dialog closed.
         */
        async close() {
            if (this.hasChangesToSave) {
                if (await showAskDialog("Cancel changes?", "You've made changes to your settings, are you sure you want to cancel them?", true)) {
                    this.$emit('close');
                    return true;
                }
            } else {
                this.$emit('close');
                return true;
            }

            return false;
        },

        makeActive(screen) {
            this.userDetailsActive = false;
            this.tipsAndHelpActive = false;
            this.featuresActive = false;
            this.notificationsActive = false;
            this.platformAuthActive = false;
            switch (screen) {
                case "user_details":
                    this.userDetailsActive = true; break;
                case "tipsAndHelp":
                    this.tipsAndHelpActive = true; break;
                case "features":
                    this.featuresActive = true; break;
                case "notifications":
                    this.notificationsActive = true; break;
                case "platform_auth":
                    this.platformAuthActive = true; break;
                default:
                    throw new Error(`Unknown option ${screen}`);
            }
        }
    }
}

</script>

<style scoped lang="sass">



.user-settings-dialog
    ::v-deep .dialog-body
        padding: 0

        header
            border-bottom: 1px solid #666666
            margin-bottom: 20px
        h1
            color: var(--heading-colour-text-dark)

    &__sidebar
        position: static
        height: auto

    &__container
        height: clamp(550px, 50vh, 600px)
        display: flex

        .settings-content
            padding: 0 0 0 30px
            width: calc(100% - var(--sidebar-size))

    ::v-deep .dialog-button-bar
        margin-top: 0

    &__button-bar
        padding: 20px
        border-top: 1px solid #272727
        background: #333333
        border-bottom-left-radius: 6px
        border-bottom-right-radius: 6px


        .bottom-button
            display: inline-block

            .save-spinner
                padding-left: 5px

    @media screen and (max-width: 1000px)
        .user-settings-dialog
            ::v-deep .modal-container
                width: 80vw !important


            &__container
                .sidebar
                    width: 70px
                    font-size: 30px

                    label
                        display: none

</style>
