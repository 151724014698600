<template>

    <DialogBox modal :title="'Edit Brand Tree as JSON'" class="edit-brand-dialog ebtj-dialog" width="600px" @close="emitClose()">
        <div class="deq-reset ebtj-json dark-scrollbars dark-scrollbars--visible">

            <textarea ref="jsonInput" v-if="activePage === 'jsonTree'" :value="jsonBrand" :rows="25" style="width: 100%"/>

            <template v-if="activePage === 'confirmChanges'">
                <LoadingMessage v-if="isLoading" :message="'Analysing your changes...'" class="edit-brand-loader ebjt-loader" />
                <template v-if="changesRes['error']">
                    <p class="ebtj-changes-header"><strong>An error was identified:</strong></p>
                    <p class="ebtj-changes-error">{{changesRes['error']}}</p>
                </template>
                <template v-if="hasChanges">
                    <template v-if="hasIdentifiedChanges">
                        <p class="ebtj-changes-header"><strong>Identified changes:</strong></p>
                        <ul class="ebtj-changes-list">
                            <li v-for="(change, index) in changesRes['changes']" :key="index">{{change}}</li>
                        </ul>
                    </template>

                    <template v-if="!hasIdentifiedChanges">
                        <p>No changes were identified, return to the previous page in order to update the brand tree.</p>
                    </template>
                </template>
            </template>
        </div>

        <template #buttons>
            <div class="deq-dialog-buttons edit-brand-buttons">

                <div class="left-side">
                </div>

                <div class="saving-spinner">
                    <SpinnerComponent v-if="isSaving" :size="16"/>
                </div>

                <div class="right-side">

                    <BeButton v-if="!isSaving" @click="emitClose" :disabled="isSaving" link>
                        <TooltipComponent>
                            <span>Cancel</span>
                            <template #tooltip>Discard changes (Esc)</template>
                        </TooltipComponent>
                    </BeButton>

                    <BeButton v-if="activePage === 'confirmChanges'"  @click="onPrevClick" :disabled="isSaving">Prev</BeButton>
                    <BeButton v-if="activePage === 'jsonTree'"  @click="onNextClick" :disabled="isSaving">Next</BeButton>
                    <BeButton v-if="hasIdentifiedChanges" @click="onSaveClick"
                              :disabled="isSaving || hasError">
                        Ok
                    </BeButton>
                </div>
            </div>
        </template>
    </DialogBox>

</template>

<script>
import DialogBox from "@/components/DialogBox.vue";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";
import BeButton from "@/components/buttons/BeButton.vue";
import {Brand} from "@/app/utils/types";
import {mash} from "@/store/Services";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapState} from "vuex";
import LoadingMessage from "@/components/LoadingMessage.vue";
import {notifyUser} from "@/app/framework/notifications/Notifications";

const UNEDITABLE_FIELDS = ['volume', 'created', 'rateSentiment', "crowdQuotaPercentage", "crowdQuota", "tier",
    "volumeLimit", "crowdSamplePercentage", "crowdTopicPercentage", "topicTrees", "segmentListIds",
    "activeSegmentListIds", "schema", "importedByAccounts", "tagItems", "cleanName", "sentiment",
    "relevancyModel"];

export default {
    name: "EditBrandTreeAsJsonDialog",
    components: {LoadingMessage, BeButton, TooltipComponent, SpinnerComponent, DialogBox},
    store: VuexStore,

    props: {
        brand: {
            type: Brand,
            required: true
        },
        toggles: {
            type: Object,
            required: false
        }
    },

    data() {
        return {
            isSaving: false,
            jsonBrand: '',
            /**
             * @type {'jsonTree' | 'confirmChanges'}
             */
            activePage: 'jsonTree',
            changesRes: {},
            isLoading: false,
        }
    },

    computed: {
        ...mapState(['account']),

        hasError() {

            return !!this.changesRes['error']?.length > 0
        },

        hasChanges() {
             return !!this.changesRes['changes'];
        },

        hasIdentifiedChanges() {
            return this.hasChanges && Object.keys(this.changesRes['changes']).length > 0
        }
    },

    mounted() {
        const cleanedBrand = this.cleanBrandTree(this.brand);

        if (this.toggles?.showBrandsAndPhrasesInTrash === false) {
            cleanedBrand.children = cleanedBrand.children?.filter(c => !c.deleted);
            cleanedBrand.children?.forEach(c => {
                if (c.children) {
                    c.children = c.children?.filter(gc => !gc.deleted);
                }
            });
        }

        this.jsonBrand = JSON.stringify(cleanedBrand, undefined, 5);
    },

    methods: {
        ...mapActions(['refreshBrands']),

        emitClose(){
            this.$emit('close');
        },

        /**
         * Removes fields that are not intended to be editable
         *
         * @param brand {Brand}
         */
        cleanBrandTree(brand) {
            const {parent, ...cleanBrand} = brand;
            UNEDITABLE_FIELDS.forEach(ueField => delete cleanBrand[ueField]);

            if (brand.phrases && brand.phrases.length) {
                cleanBrand.phrases = [];
                cleanBrand.phrases = [...brand.phrases
                    .filter(phrase => {
                        if (this.toggles?.inactivePhrases === false) {
                            return !phrase.inactive
                        } else {
                            return phrase
                        }
                    })
                    .map(phrase => {
                        return {
                            id: phrase.id,
                            q: phrase.q,
                            approved: phrase.approved,
                            deleted: phrase.deleted,
                            mentionFilter: phrase.mentionFilter,
                            lastUpdatedBy: {
                                admin: phrase.lastUpdatedBy.admin,
                                email: phrase.lastUpdatedBy.email,
                                firstName: phrase.lastUpdatedBy.firstName,
                                lastName: phrase.lastUpdatedBy.lastName,
                                id: phrase.lastUpdatedBy.id,
                            },
                        }}
                    )];
            }

            if (brand.children && brand.children.length > 0) {
                cleanBrand.children = []
                cleanBrand.children = brand.children?.map(child => {
                    return this.cleanBrandTree(child);
                })
            }

            return cleanBrand;
        },

        onPrevClick() {
            if (this.activePage !== 'confirmChanges') return;

            this.activePage = 'jsonTree';
            this.changesRes = {}
        },

        async onNextClick() {
            if (this.activePage === 'jsonTree') {
                this.activePage = 'confirmChanges';
                this.isLoading = true;
                this.jsonBrand = this.$refs.jsonInput.value;

                try {
                    this.possibleConfirmed = JSON.parse(this.jsonBrand);
                    const res = await mash.put(`/rest/accounts/${this.account.code}/brands?dry-run=true`, [this.possibleConfirmed])
                    this.changesRes = res.data;

                    if (this.hasError) {
                        console.warn(res.data['error']);
                    }
                } catch (error) {
                    console.warn('An error occurred while analysing brand changes: ', error)
                    if (error instanceof SyntaxError) {
                        this.changesRes['error'] = error.message
                    }
                } finally {
                    this.isLoading = false;
                }

            }
        },

        async onSaveClick() {
            if (this.activePage !== 'confirmChanges') return

            if (this.activePage === 'confirmChanges') {
                this.isSaving = true;

                try {
                    await mash.put(`/rest/accounts/${this.account.code}/brands?dry-run=false`, [this.possibleConfirmed]);
                    await this.refreshBrands(true);
                    notifyUser('Brand tree was successfully updated');
                } catch (error) {
                    console.warn('An error occurred while saving brand changes: ', error)
                } finally {
                    this.emitClose();
                    this.isSaving = false;
                }

            }
        },
    }
}
</script>

<style scoped lang="sass">
@use "../tree/edit-brand-dialog"

.ebjt-loader
    --loading-message-delay: 0ms
    margin: auto

.ebtj-input, .ebtj-changes-error
    margin: auto
    width: 100%

.ebtj-changes-header
    color: unset
.ebtj-changes-error
    margin-block-start: 0
    margin-block-end: 0
    margin-inline-start: 0
    margin-inline-end: 0

.ebtj-changes-list
    list-style-type: circle
    margin: 0 0 0 25px

.ebtj-json
    min-height: 50vh
    max-height: 80vh
    overflow-y: auto
    overflow-x: hidden


</style>