<template>
    <DialogBox modal :title="`Select a new parent Phrase for ${phrase.q}`" class="edit-brand-dialog move-brand-dialog" width="550px" @close="emitClose()">
        <div class="deq-reset move-brand-form dark-scrollbars dark-scrollbars--visible">
            <div v-if="!isLoading && tree">

                <div v-for="(brand) in tree" :key="brand.id">
                    <MoveBrandRow :current-parent-id="submitData.parentId" :branch="brand" @parent-updated="onParentUpdated"></MoveBrandRow>
                </div>

            </div>

            <LoadingMessage v-if="isLoading" :message="'Setting up your Brand Tree...'" class="edit-brand-loader" />
        </div>

        <template #buttons>
            <div class="deq-dialog-buttons edit-brand-buttons">

                <div class="left-side">
                </div>

                <div class="saving-spinner">
                    <SpinnerComponent v-if="isSaving" :size="16"/>
                </div>

                <div class="right-side">
                    <BeButton @click="emitClose" :disabled="isSaving" link>
                        <TooltipComponent>
                            <span>Cancel</span>
                            <template #tooltip>Discard changes (Esc)</template>
                        </TooltipComponent>
                    </BeButton>

                    <BeButton @click="onSave" :disabled="submitData.parentId === phrase.parent.id || isSaving">Ok</BeButton>
                </div>
            </div>
        </template>
    </DialogBox>
</template>


<script>
import DialogBox from "@/components/DialogBox.vue";
import {BrandPhrase} from "@/app/utils/types";
import {mapActions, mapGetters, mapState} from "vuex";
import MoveBrandRow from "@/setup/brands/forms/edit-brand/move-brand/MoveBrandRow.vue";
import VuexStore from "@/store/vuex/VuexStore";
import BeButton from "@/components/buttons/BeButton.vue";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import LoadingMessage from "@/components/LoadingMessage.vue";
import {warn} from "@vue/runtime-dom";
import {notifyUser} from "@/app/framework/notifications/Notifications";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";

export default {
    name: "MovePhraseDialog",
    store: VuexStore,
    props: {
        phrase: {
            type: BrandPhrase,
            required: true
        },
        searchToggles: {}
    },
    components: {TooltipComponent, LoadingMessage, SpinnerComponent, BeButton, MoveBrandRow, DialogBox},

    data() {
      return {
          isLoading: true,
          isSaving: false,
          tree: [],

          submitData: {
              parentId: this.phrase.parent.id
          },
      }
    },

    computed: {
        ...mapState(['rootBrands']),
        ...mapGetters(['idToBrand']),
    },

    mounted() {
        this.isLoading = true;
        this.tree = this.buildPickerTree([... this.rootBrands]);

        if (!this.searchToggles().showBrandsAndPhrasesInTrash) {
            this.tree?.forEach(c => {
                if (c.children) {
                    c.children = c.children?.filter(gc => !gc.deleted);
                }
            });
        }

        this.isLoading = false;

        setTimeout(() => {
            document.getElementById('move' + this.phrase.parent.id)
                .scrollIntoView({ behavior: "smooth", block: "center"});
        }, 300)
    },

    methods: {
        ...mapActions(['movePhrase']),

        emitClose() {
            this.$emit('close');
        },

        buildPickerTree(rootBrands) {
            const tree = [];
            for (let i = 0; i < rootBrands.length; i++) {
                tree.push(this.createPickerBrand(rootBrands[i], null));
            }
            return tree;
        },

        createPickerBrand(brand, parent) {
            const ans = {id: brand.id, name: brand.name, parent: parent, deleted: brand.deleted};
            if (brand.children) {
                ans.children = [];
                for (let i = 0; i < brand.children.length; i++) {
                    ans.children.push(this.createPickerBrand(brand.children[i], ans));
                }
            }
            return ans;
        },

        onParentUpdated(event) {
            this.submitData.parentId = event;
        },

        async onSave() {
            this.isSaving = true;

            if (this.submitData.parentId === this.phrase.parent.id) {
                this.isSaving = false;
                return;
            }

            try {
                const request = {
                    id: this.phrase.id,
                    query: this.phrase.q,
                    created: this.phrase.created,
                    active: !this.phrase.inactive,
                    approved: this.phrase.approved,
                    brandId: this.submitData.parentId
                };

                await this.movePhrase(request);
                const brand = this.idToBrand.get(this.phrase.parent.id);
                notifyUser(`Phrase successfully moved to brand: ${brand.shortName || brand.name}`);
                this.emitClose();
            } catch (error) {
                warn('An error occurred while moving phrase: ', error)
            } finally {
                this.isSaving = false;
            }
        },


    }
}
</script>

<style scoped lang="sass">
@use "../tree/edit-brand-dialog"

.move-brand-form
    max-height: 50vh
    overflow-y: scroll
</style>