<template>
    <div class="online-profile">
        <img class="online-profile__profile-image" alt="Profile image" :src="getImage()" @error="handleImageError">
        <div class="online-profile__profile-details">
            <div class="online-profile__text" :title="profile.name">
                <i :class="getProfileIcon(profile.type)"></i>{{nameDisplay}}
            </div>
            <div class="online-profile__text muted" :title="handleDisplay">
                 {{ handleDisplay }}
            </div>
        </div>
    </div>
</template>

<script>
import {getProfileIcon, profileTypes} from "@/setup/profiles/ProfileUtils";
import {Profile} from "@/app/utils/types";
import {mapState} from "vuex";

export default {
    name: "OnlineProfile",

    props: {
        profile: {
            type: Profile,
            required: true
        }
    },

    computed: {
        ...mapState(['user']),

        nameDisplay(){
            switch(this.profile.type){
                case profileTypes.whatsapp:{
                    let indexOfBracket = this.profile.name.lastIndexOf("(");
                    if(indexOfBracket==-1){
                        return this.profile.name;
                    }
                    let nameWithoutNumber = this.profile.name.substring(0,indexOfBracket)
                    return nameWithoutNumber;
                }
                default:
                    return this.profile.name
            }
        },

        handleDisplay() {
            if (this.profile.type === profileTypes.googleBusiness) {
                return this.profile.description;
            }
            if (this.profile.type === profileTypes.tikTok && this.profile.apiAppId === 50) return this.profile.handle;
            if (!this.profile.handle || this.profile.name === this.profile.handle) {
                return this.profile.handleId;
            } else {
                switch (this.profile.type) {
                    case profileTypes.twitter:
                    case profileTypes.instagram_business:
                    case profileTypes.whatsapp:{
                        let indexOfBracket = this.profile.name.lastIndexOf("(");
                        if(indexOfBracket==-1){
                            return this.profile.handle;
                        }
                        let phoneNumber = this.profile.name.substring(indexOfBracket+1,this.profile.name.length-1)
                        return phoneNumber;
                    }
                    case profileTypes.instagram_user:
                        return `@${this.profile.handle}`;
                    case profileTypes.tikTokAds && this.profile.apiAppId === 53 :
                        return this.profile.handleId;
                    default:
                        return this.profile.handle;
                }
            }
        }
    },

    methods: {
        getProfileIcon,

        getImage() {
            if (!this.profile.pictureLink) {
                switch (this.profile.type) {
                    case profileTypes.facebook:
                        return '/img/consumer_avatar.png';
                    case profileTypes.instagram_business:
                    case profileTypes.instagram_user:
                        return '/img/instagram-blank.jpg';
                    case profileTypes.twitter:
                        return '/img/twitter_avatar.png';
                    case profileTypes.googleBusiness:
                        return '/img/business_icon.svg';
                    default:
                        return '/img/consumer_avatar.png';
                }
            }
            return this.profile.pictureLink;
        },

        handleImageError(event) {
            switch (this.profile.type) {
                case profileTypes.facebook:
                    event.target.src = '/img/consumer_avatar.png';
                    break;
                case profileTypes.instagram_business:
                case profileTypes.instagram_user:
                    event.target.src = '/img/instagram-blank.jpg';
                    break;
                case profileTypes.twitter:
                    event.target.src = '/img/twitter_avatar.png';
                    break;
                case profileTypes.googleBusiness:
                    event.target.src = '/img/business_icon.svg';
                    break;
                default:
                    event.target.src = '/img/consumer_avatar.png';
            }
        }
    }
}
</script>

<style scoped lang="sass">

.online-profile
    display: flex
    align-items: center
    color: white

    &__profile-image
        height: 35px
        width: 35px
        margin-left: 10px
        border-radius: 4px

    &__profile-details
        margin-left: 10px
        width: calc(100% - 60px)

    &__text
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap

        .muted
            color: var(--be-colour-text-dark)

</style>