var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-fluid\">\n                <div class=\"span12\">\n                    <dt style=\"margin-top: 0\"\n                        title=\"We remove personal information from mentions after a certain amount of time has passed. This is the date and time at which that has done for this mention\">\n                        Personal information removed on</dt>\n                    <dd>"
    + container.escapeExpression(__default(require("../helpers/formatAccountDate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"scrubbed") : depth0),"YYYY/MM/DD HH:mm",{"name":"formatAccountDate","hash":{},"data":data,"loc":{"start":{"line":19,"column":24},"end":{"line":19,"column":73}}}))
    + "</dd>\n                </div>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "read-only";
},"5":function(container,depth0,helpers,partials,data) {
    return "class=\"read-only\"";
},"7":function(container,depth0,helpers,partials,data) {
    return "tag-input uneditable-input";
},"9":function(container,depth0,helpers,partials,data) {
    return "tf-read-only";
},"11":function(container,depth0,helpers,partials,data) {
    return "tabindex=\"0\"";
},"13":function(container,depth0,helpers,partials,data) {
    return "<input class=\"tag popup-menu\" type=\"text\"/>";
},"15":function(container,depth0,helpers,partials,data) {
    return " tf-read-only";
},"17":function(container,depth0,helpers,partials,data) {
    return " deleted";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"reshareCount") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":132,"column":44},"end":{"line":132,"column":73}}}));
},"21":function(container,depth0,helpers,partials,data) {
    return "0";
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"engagement") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":134,"column":42},"end":{"line":134,"column":69}}}));
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"OTS") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":136,"column":35},"end":{"line":136,"column":55}}}));
},"27":function(container,depth0,helpers,partials,data) {
    return "n/a";
},"29":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"replyCount") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":140,"column":42},"end":{"line":140,"column":69}}}));
},"31":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <td class=\"header\" title=\"Response time\">Resp. time</td>\n                        <td title=\"Time elapsed between the original post and the reply\">"
    + container.escapeExpression(__default(require("../helpers/formatDuration.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"responseTime") : depth0),{"name":"formatDuration","hash":{},"data":data,"loc":{"start":{"line":143,"column":89},"end":{"line":143,"column":120}}}))
    + "</td>\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "                        <td colspan=\"2\"></td>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <td class=\"header\">AVE</td>\n                        <td class=\"ave-value\">"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"AVE") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":149,"column":46},"end":{"line":149,"column":78}}})) != null ? stack1 : "")
    + "</td>\n";
},"36":function(container,depth0,helpers,partials,data) {
    return "...";
},"38":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " in "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"_accountCode") : depth0), depth0));
},"40":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <td class=\"header\">Conversation ID</td>\n                    <td title=\"ID of the conversation this mention is part of\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"conversationId") : depth0), depth0))
    + "</td>\n";
},"42":function(container,depth0,helpers,partials,data) {
    return "                    <td colspan=\"2\"></td>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <td class=\"header\">Interaction ID</td>\n                        <td title=\"ID of the interaction this mention is part of\" style=\"text-align: left\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"interactionId") : depth0), depth0))
    + "</td>\n";
},"46":function(container,depth0,helpers,partials,data) {
    return "                        <td class=\"header\">Interaction response time</td>\n                        <td style=\"text-align: left\"><i class=\"symbol-warning\"></i> Available within 24 hours</td>\n";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readableResponseTime") : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":183,"column":24},"end":{"line":186,"column":31}}})) != null ? stack1 : "");
},"49":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <td class=\"header\">Interaction response time</td>\n                            <td style=\"text-align: left\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"readableResponseTime") : depth0), depth0))
    + "</td>\n";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasBio") : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":196,"column":16},"end":{"line":204,"column":23}}})) != null ? stack1 : "");
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <dt>Author's Bio</dt>\n                    <dd>\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":199,"column":24},"end":{"line":199,"column":78}}})) != null ? stack1 : "")
    + "\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"location") : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":200,"column":24},"end":{"line":200,"column":103}}})) != null ? stack1 : "")
    + "\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"timezone") : depth0),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":201,"column":24},"end":{"line":201,"column":98}}})) != null ? stack1 : "")
    + "\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"twitterUserId") : depth0),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":202,"column":24},"end":{"line":202,"column":115}}})) != null ? stack1 : "")
    + "\n                    </dd>\n";
},"53":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "</span>";
},"55":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span><i class=\"icon-location-2\"></i>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"location") : depth0), depth0))
    + "</span>";
},"57":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span><i class=\"icon-clock\"></i>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"timezone") : depth0), depth0))
    + "</span>";
},"59":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span><i class=\"symbol-twitter\"></i>ID "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"twitterUserId") : depth0), depth0))
    + "</span>";
},"61":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row-fluid\">\n            <dt>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"relevant") : depth0),{"name":"if","hash":{},"fn":container.program(62, data, 0),"inverse":container.program(64, data, 0),"data":data,"loc":{"start":{"line":210,"column":16},"end":{"line":210,"column":109}}})) != null ? stack1 : "")
    + "</dt>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"brandPhrases") : depth0),{"name":"each","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":211,"column":12},"end":{"line":222,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n";
},"62":function(container,depth0,helpers,partials,data) {
    return "This is relevant to conversation about";
},"64":function(container,depth0,helpers,partials,data) {
    return "This matched phrases for";
},"66":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <dd class=\"collapsed\" data-brand=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"brandId") : depth0), depth0))
    + "\">\n                    <div class=\"brand\">\n                        <span class=\"path\" title=\"Click to show/hide phrases\">"
    + alias1(__default(require("../helpers/renderAnglePath.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"fullName") : depth0),{"name":"renderAnglePath","hash":{},"data":data,"loc":{"start":{"line":214,"column":78},"end":{"line":214,"column":106}}}))
    + " <i class=\"icon-down-dir\"></i></span>\n                    </div>\n                    <div class=\"phrases\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias2,(depth0 != null ? lookupProperty(depth0,"phrases") : depth0),{"name":"each","hash":{},"fn":container.program(67, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":217,"column":24},"end":{"line":219,"column":33}}})) != null ? stack1 : "")
    + "                    </div>\n                </dd>\n";
},"67":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div><i class=\"icon-dot\"></i> <span>"
    + container.escapeExpression(__default(require("../helpers/renderPhrase.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"q") : depth0),{"name":"renderPhrase","hash":{},"data":data,"loc":{"start":{"line":218,"column":64},"end":{"line":218,"column":82}}}))
    + "</span></div>\n";
},"69":function(container,depth0,helpers,partials,data) {
    return "        <label class=\"checkbox\" style=\"display: inline-block; margin-right: 32px\">\n            <input type=\"checkbox\" name=\"_updateSnoek\" class=\"update-snoek\">\n            Save category and location for all accounts\n        </label>\n";
},"71":function(container,depth0,helpers,partials,data) {
    return "<a class=\"ok btn btn-primary\">Ok</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-body dialog-body-notitle\">\n    <div class=\"right-col\">\n        <div class=\"row-fluid\">\n            <div class=\"span5\">\n                <dt style=\"margin-top: 0\" title=\"The date and time that this mention was posted\">Published on</dt>\n                <dd>"
    + alias2(__default(require("../helpers/formatAccountDate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"published") : depth0),"YYYY/MM/DD HH:mm",{"name":"formatAccountDate","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":70}}}))
    + "</dd>\n            </div>\n            <div class=\"span7\">\n                <dt style=\"margin-top: 0\">Status</dt>\n                <dd>"
    + alias2(__default(require("../helpers/renderMentionVerification.js")).call(alias1,{"name":"renderMentionVerification","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":49}}}))
    + "</dd>\n            </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"scrubbed") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":22,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"row-fluid\">\n            <div class=\"sentiments "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"canEditSentiment") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":35},"end":{"line":25,"column":83}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = container.invokePartial(require("./EditMentionSentiment.handlebars"),depth0,{"name":"./EditMentionSentiment","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div class=\"row-fluid\" style=\"margin-top: 3px\">\n            <div class=\"control-group\">\n                <label "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":23},"end":{"line":32,"column":76}}})) != null ? stack1 : "")
    + ">Location</label>\n                <div class=\"controls\">\n                    <span class=\"location span12 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":34,"column":49},"end":{"line":34,"column":123}}})) != null ? stack1 : "")
    + "\"\n                          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":26},"end":{"line":35,"column":66}}})) != null ? stack1 : "")
    + ">\n                        <span name=\"location\"></span>\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":37,"column":95}}})) != null ? stack1 : "")
    + "\n                    </span>\n                </div>\n            </div>\n        </div>\n        <div class=\"row-fluid\">\n            <div class=\"span6\">\n                <div class=\"control-group\">\n                    <label "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":27},"end":{"line":45,"column":80}}})) != null ? stack1 : "")
    + ">Language</label>\n                    <div class=\"controls\">\n                    <span class=\"_language span12 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":47,"column":50},"end":{"line":47,"column":124}}})) != null ? stack1 : "")
    + "\"\n                          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":26},"end":{"line":48,"column":66}}})) != null ? stack1 : "")
    + ">\n                        <span name=\"_language\"></span>\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":24},"end":{"line":50,"column":95}}})) != null ? stack1 : "")
    + "\n                    </span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"span6\">\n                <div class=\"control-group\">\n                    <label "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":27},"end":{"line":57,"column":80}}})) != null ? stack1 : "")
    + ">Gender</label>\n                    <div class=\"controls\">\n                    <span class=\"genderPicker span12 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":59,"column":53},"end":{"line":59,"column":127}}})) != null ? stack1 : "")
    + "\"\n                          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":26},"end":{"line":60,"column":66}}})) != null ? stack1 : "")
    + ">\n                        <span name=\"_gender\"></span>\n                    </span>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row-fluid\">\n            <div class=\"span6\">\n                <div class=\"control-group\">\n                    <label "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":27},"end":{"line":71,"column":80}}})) != null ? stack1 : "")
    + ">Category</label>\n                    <div class=\"controls\">\n                        <span class=\"mediaPicker span12 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":73,"column":56},"end":{"line":73,"column":130}}})) != null ? stack1 : "")
    + "\"\n                              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":30},"end":{"line":74,"column":70}}})) != null ? stack1 : "")
    + ">\n                            <span name=\"_category\"></span>\n                        </span>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row-fluid\">\n            <div class=\"control-group\">\n                <label "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditTopics") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":23},"end":{"line":84,"column":77}}})) != null ? stack1 : "")
    + ">Topics</label>\n                <div class=\"controls\">\n                    <span class=\"_topics span12 tag-input uneditable-input"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditTopics") : depth0),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":74},"end":{"line":86,"column":124}}})) != null ? stack1 : "")
    + "\"\n                          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditTopics") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":26},"end":{"line":87,"column":67}}})) != null ? stack1 : "")
    + ">\n                        <span name=\"_topics\"></span>\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditTopics") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":24},"end":{"line":89,"column":96}}})) != null ? stack1 : "")
    + "\n                    </span>\n                </div>\n            </div>\n            <div class=\"control-group\">\n                <label "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditTopics") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":23},"end":{"line":94,"column":77}}})) != null ? stack1 : "")
    + ">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"segmentLabel") : depth0), depth0))
    + "</label>\n                <div class=\"controls\">\n                    <span class=\"_segments span12 tag-input uneditable-input"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditTopics") : depth0),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":76},"end":{"line":96,"column":126}}})) != null ? stack1 : "")
    + "\"\n                          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditTopics") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":26},"end":{"line":97,"column":67}}})) != null ? stack1 : "")
    + ">\n                        <span name=\"_segments\"></span>\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditTopics") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":24},"end":{"line":99,"column":96}}})) != null ? stack1 : "")
    + "\n                    </span>\n                </div>\n            </div>\n            <div class=\"control-group\">\n                <label "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditTags") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":104,"column":23},"end":{"line":104,"column":75}}})) != null ? stack1 : "")
    + ">Tags</label>\n                <div class=\"controls\">\n                    <span class=\"_tags span12 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditTags") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":106,"column":46},"end":{"line":106,"column":119}}})) != null ? stack1 : "")
    + "\"\n                          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditTags") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":26},"end":{"line":107,"column":65}}})) != null ? stack1 : "")
    + ">\n                        <span name=\"_tags\"></span>\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditTags") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":24},"end":{"line":109,"column":94}}})) != null ? stack1 : "")
    + "\n                    </span>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"left-col\">\n\n        <div class=\"mention-item-container\">\n\n        </div>\n\n        <a href=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"uri") : depth0), depth0))
    + "\" target=\"_blank\"\n           title=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\"\n           class=\"mention-link"
    + ((stack1 = __default(require("../helpers/ifequal.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"relevancy") : depth0),"IRRELEVANT",{"name":"ifequal","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":30},"end":{"line":124,"column":85}}})) != null ? stack1 : "")
    + "\">\n            <i class=\"icon-link\"></i>"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\n        </a>\n\n        <div class=\"row-fluid\" style=\"margin-bottom: 4px;margin-top: 4px\">\n            <table class=\"label-data-table\">\n                <tr>\n                    <td class=\"header\">Reshares</td>\n                    <td>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"reshareCount") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":132,"column":24},"end":{"line":132,"column":89}}})) != null ? stack1 : "")
    + "</td>\n                    <td class=\"header\">Engagement</td>\n                    <td>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"engagement") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":134,"column":24},"end":{"line":134,"column":85}}})) != null ? stack1 : "")
    + "</td>\n                    <td class=\"header\">OTS</td>\n                    <td>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"OTS") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":136,"column":24},"end":{"line":136,"column":73}}})) != null ? stack1 : "")
    + "</td>\n                </tr>\n                <tr>\n                    <td class=\"header\">Replies</td>\n                    <td>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"replyCount") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":140,"column":24},"end":{"line":140,"column":85}}})) != null ? stack1 : "")
    + "</td>\n"
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"responseTime") : depth0),">=",0,{"name":"ifcond","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":141,"column":20},"end":{"line":146,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../helpers/ifShowAVE.js")).call(alias1,{"name":"ifShowAVE","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":147,"column":20},"end":{"line":152,"column":34}}})) != null ? stack1 : "")
    + "                </tr>\n            </table>\n            <table class=\"label-data-table\" style=\"margin-top: 8px\">\n                <tr>\n                    <td class=\"header\">ID</td>\n                    <td title=\"Unique ID for this mention"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_accountCode") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":57},"end":{"line":158,"column":104}}})) != null ? stack1 : "")
    + "\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"conversationId") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.program(42, data, 0),"data":data,"loc":{"start":{"line":159,"column":16},"end":{"line":164,"column":23}}})) != null ? stack1 : "")
    + "                </tr>\n            </table>\n            <table class=\"label-data-table\" style=\"margin: 8px 0\">\n                <tr>\n                <td class=\"ticket-info\"></td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"interactionId") : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":20},"end":{"line":174,"column":27}}})) != null ? stack1 : "")
    + "                </tr>\n            </table>\n            <table class=\"label-data-table\" style=\"margin: 8px 0\">\n                <tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"responseTimeStillCalculating") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.program(48, data, 0),"data":data,"loc":{"start":{"line":179,"column":20},"end":{"line":187,"column":27}}})) != null ? stack1 : "")
    + "                </tr>\n            </table>\n\n\n        </div>\n\n        <div class=\"row-fluid bio\">\n"
    + ((stack1 = __default(require("../helpers/withMentionAuthor.js")).call(alias1,{"name":"withMentionAuthor","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":195,"column":12},"end":{"line":205,"column":34}}})) != null ? stack1 : "")
    + "        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"brandPhrases") : depth0),{"name":"if","hash":{},"fn":container.program(61, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":208,"column":8},"end":{"line":224,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"dialog-button-bar\">\n        <a class=\"btn menu popup-menu pull-left\" title=\"Extra options and functionality related to this mention\">Options <span class=\"caret\"></span></a>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canUpdateSnoek") : depth0),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":230,"column":8},"end":{"line":235,"column":15}}})) != null ? stack1 : "")
    + "\n        <a class=\"cancel btn\" title=\"Discard changes (Esc)\">Cancel</a>\n        "
    + ((stack1 = __default(require("../helpers/ifallowed.js")).call(alias1,"editMentions",{"name":"ifallowed","hash":{},"fn":container.program(71, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":238,"column":8},"end":{"line":238,"column":87}}})) != null ? stack1 : "")
    + "\n    </div>\n</div>";
},"usePartial":true,"useData":true});