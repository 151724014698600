import {
    get as getSegment,
    getSegmentList,
    isCxSolution,
    isRiskSolution,
    getJourneySegmentList,
    getRiskSegmentList,
    getCxSegmentListsOnBrands,
    getRiskSegmentListsOnBrands,
    getMarketConductSegmentListsOnBrands,
    getChannelSegmentListsOnBrands
} from "../../../../app/utils/Segments";
import {encloseInDisplayQuotes, escapeExpression, splitAtSpaces, splitQuotedString} from "@/app/utils/StringUtils";

import { substituteTagParamaters } from "@/app/utils/Tags";
import {deprecatedFetchTags} from "@/data/DeprecatedBeefCache";
import {isMashAdmin} from "@/app/Permissions";
import {getTopicParents} from "@/app/utils/Topics";
import moment from "moment";
import {getMetatagsFromTags} from "@/app/utils/Metatags";
import {createTagConverter, toPlaceholderHTML} from "@/app/framework/pickers/picker-utils";


/**
 * Select one or more tags by typing the short code or name. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 */
Beef.module("TagPicker").addInitializer(function(startupOptions) {

    /**
     * A function for validating tags, checking things like tag length, tag content, and so on.
     */
    this.validate = function(tag) {
        if (tag.length > 50) return "The tag " + encloseInDisplayQuotes(tag) + " is too long";
        // We don't actually disallow the following, but it's almost certainly bad style
        if (/^[!"#$%&'()*+,\-./:;<=>?@ [\\\]^_`{|}~]+$/.test(tag)) return "The tag " + encloseInDisplayQuotes(tag) + " contains only punctuation";
        if (/^\s*$/.test(tag)) return "The tag contains only spaces";

        return null
    };

    this.items = function(q, view, callback) {
        deprecatedFetchTags(view, function(tags) {
            var map = {};
            var values = Object.values(tags);
            values?.forEach(function(tag) {
                map[tag.id] = tag;
            });

            try {
                // Let's make sure that we mark what the duplicates are.
                // NOTE: updating these tag values is not good design, and should be fixed in
                // code written with newer javascript.
                const getKey = tag => `${tag.name.toLowerCase().trim()}:${tag.namespace}`;
                const counts = new Map();
                for (const tag of values) {
                    const key = getKey(tag);
                    const count = counts.get(key) ?? 0;
                    counts.set(key, count + 1);
                }
                for (const tag of values) {
                    if (counts.get(getKey(tag)) > 1) tag._isDuplicate = true;
                }
            } catch(e) {
                console.warn("Unable to check for duplicates", e);
            }


            callback(map);
        });
    };

    this.View = Beef.AutoCompletePicker.View.extend({
        attributes: { class: "tag-picker auto-complete-picker" },
        items: this.items,
        acceptNew: true,
        showTopicTagDetail: true,  // Whether we should add (topic) to the tag name or not (useful for dedicated topic
                                   // fields)
        initialize: function(options) {
            Beef.AutoCompletePicker.View.prototype.initialize.call(this, options);
            this.showTopicTagDetail = options.options.showTopicTagDetail !== undefined ? !!options.options.showTopicTagDetail : this.showTopicTagDetail;
        },
        nameFormatter: function(d) {
            if (!d.namespace) {
                return escapeExpression(d.name);
            } else if (d.namespace === 'topic' || d.forCrowdTopicDiscovery) {
                let postfix = null;
                if (d.flag === "NONE_OF_THE_ABOVE" && d._parent) {
                    postfix = `(${d._parent.name})`;
                } else if (d.children) {
                    postfix = d._parent?.name ?? null;
                } else {
                    const treeName = d._parent?._parent?.name ?? "";
                    if (treeName) postfix = treeName + " » " + d._parent.name;
                    else postfix = d._parent.name;
                    postfix = postfix.trim();
                }
                if (!postfix && this.showTopicTagDetail) postfix = "(topic)";
                if (postfix) postfix = "&nbsp;<span class='tag-detail'>" + escapeExpression(postfix) + "</span>";
                return '<span>' + escapeExpression(d.name) + "</span>" + (postfix || '');
            } else if (d.forCrowdTopicList) {
                return '<span>' + escapeExpression(d.name) + '&nbsp;</span><span class="tag-detail">(classification)</span>';
            } else if (d.namespace !== 'tag') {
                var namespace = d.namespace.toLowerCase();
                if (namespace === "segment") {
                    var parentSegment = getSegmentList(d);
                    if (parentSegment) {
                        namespace = parentSegment.name;
                    }
                }

                let nameString = " " + d.name;
                if (d._isDuplicate && d.subtitle) nameString = " " + d.subtitle.trim() + nameString;

                nameString = " " + escapeExpression(nameString);
                if (namespace === 'brandseye') namespace = "BrandsEye";
                if (namespace === 'topic_tree') namespace = "Topic Tree";
                if (namespace === 'segment_list') namespace = !d.global ? "Solution" : "Tag set";

                var rpcs = getMetatagsFromTags(d);
                if (rpcs) {
                    // Empty out the name and namespace
                    // when displaying the rpcs tag in full
                    if (rpcs && d.id <= 4) {
                        rpcs = "<be-rpcs-icon full code='" + rpcs.code + "'></be-rpcs-icon>";
                        nameString = '';
                        namespace = '';
                    } else {
                        rpcs = "<be-rpcs-icon code='" + rpcs.code + "'></be-rpcs-icon>"
                    }

                } else rpcs = '';

                var namespaceString = '(' + escapeExpression(namespace.trim()) + ')';

                if (namespace === ''){
                    namespaceString = '';
                }

                if (d.namespace === 'survey-question') {
                    return '<span title="' + escapeExpression(d.description) + '" data-namespace="' + escapeExpression(d.namespace) + '"' +
                        (d.flag ? "data-flag='" + d.flag + "'" : '') + '>' +
                        rpcs + nameString +
                        '</span>&nbsp;<span class="tag-detail">' + namespaceString + '</span>';
                }

                return '<span data-namespace="' + escapeExpression(d.namespace) + '"' +
                        (d.flag ? "data-flag='" + d.flag + "'" : '') + '>' +
                    rpcs + nameString +
                    '</span>&nbsp;<span class="tag-detail">' + namespaceString + '</span>';
            } else {
                return escapeExpression(d.name);
            }
        },
        getName: function(d) { return d.name },
        getSearchText: (d) => {
            // We want people to be able to search by a range of things for tags.
            // The namespace should be searchable. It should be easy to search for our
            // 'solutions', and it should be easy to search for tags from a particular, named,
            // segment list.
            var text = d.name;
            if (d.namespace && d.namespace !== 'tag' && d.id > 4) text += ' ' + d.namespace;
            if (d.namespace === 'segment_list') {
                text += !d.global ? ' solution' : ' tag set';
                if (d._isDuplicate && d.subtitle) text = d.subtitle?.trim() + " " + text;
            }
            if (d.namespace === 'topic_tree') text += ' topic tree';
            if (d.namespace === 'engageusertags') text += ' engage user';
            if (d.namespace === 'segment') {
                var parent = getSegmentList(d);
                if (parent && parent.name) text += ' ' + parent.name;
                var metatag = getMetatagsFromTags(d);
                if (metatag) text += ' ' + metatag.code;
            }
            if (d.namespace === 'survey-question') text += ` ${d.description}`;
            return text;
        }
    });

    this.createConverterFactory = function(placeholder, options) {
        options = Object.assign({
            placeholderPersistant: true
        }, options || {});
        return function(view) {
            var conv;
            return function(direction, value) {
                if (value && value.length > 0) {
                    var binding = this;
                    if (conv) return conv.call(binding, direction, value);
                    const acceptNew = options.acceptNew === undefined ? true : options.acceptNew;
                    const showWarning = !!options.showWarning;
                    deprecatedFetchTags(view, function(items) {
                        conv = createTagConverter({
                            readOnly: !!options.readOnly,
                            items: items,
                            placeholder: placeholder,
                            placeholderPersistent: options.placeholderPersistant,
                            acceptNew: acceptNew,
                            getName: function(d) {
                                if (!d?.name) return '';
                                if (d._isDuplicate && d.subtitle) {
                                    return `${d.subtitle.trim()} ${d.name.trim()}`;
                                }

                                return d.name;
                            },
                            cls: function(d) {
                                if (!d) return showWarning ? "tag error" : "tag";
                                if (d.namespace === "topic" || d.forCrowdTopicDiscovery) {
                                    // Don't highlight topics, such as when showing tags in a dedicated topic field.
                                    if (options.noHighlightTopics) {
                                        if (!d._parent || d._parent.namespace !== "topic") return "tag parent";
                                        return "tag";
                                    }
                                    return "tag topic";
                                } else if ((d.namespace === "segment" && !d._parent?.global) || (d.namespace === "segment_list")) {
                                    if (options.noHighlightSegments) return "tag";
                                    return "tag topic";
                                } else if (d.namespace === "segment" && d._parent?.id === 137039 || d.namespace === 'engageusertags') {
                                    return "tag engage";
                                } else if (d.namespace === "BrandsEye") {
                                    return "tag brandseye";
                                } else if (d.namespace !== "tag" || d.forCrowdTopicList) {
                                    return "tag other";
                                }
                                return "tag";
                            },
                            nameFormatter: function(name, neg, tag) {
                                name = escapeExpression(name);

                                var prefix = neg ? '-' : '';
                                if (tag) {
                                    var rpcs = getMetatagsFromTags(tag);

                                    if (rpcs && tag.id <= 4) {
                                        return prefix + '<be-rpcs-icon full code="' + rpcs.code + '"></be-rpcs-icon>';
                                    }

                                    if (rpcs) {
                                        prefix += "<be-rpcs-icon code='" + rpcs.code + "'></be-rpcs-icon> ";
                                    }
                                } else if (showWarning) {
                                    prefix = '<i class="symbol-warning"></i>';
                                }

                                var postfix = null;
                                var postfixShort = null;
                                if (tag) {
                                    switch (tag.namespace) {
                                        case 'engageusertags':
                                            postfixShort = 'EU';
                                            postfix = 'Engage User';
                                            break;
                                        case 'crowd':
                                            postfixShort = 'C';
                                            postfix = 'Crowd';
                                            break;
                                        case 'segment_list':
                                            postfixShort = !tag.global ? "S" : "TS";
                                            postfix = !tag.global ? "Solution" : "Tag set";
                                            break;
                                        case 'topic_tree':
                                            postfixShort = "TT";
                                            postfix = "Topic Tree";
                                            break;
                                        case 'topic': {
                                            if (tag._parent) {
                                                const treeName = tag._parent?._parent?.name ?? "";
                                                if (treeName) postfix = treeName + " » " + tag._parent.name;
                                                else postfix = tag._parent.name;

                                                postfixShort = postfix.trim()
                                                    .split(' ')
                                                    .map(word => word.length ? word[0].toUpperCase() : '')
                                                    .filter(letter => letter !== '(' && letter !== ')')
                                                    .join('');
                                            }

                                            break;
                                        }
                                        case 'segment':
                                            var segment = getSegmentList(tag);

                                            if (segment) {
                                                postfix = segment.name.trim();
                                                postfixShort = segment.name.trim().split(' ')
                                                    .map(function(word) { return word.length ? word[0].toUpperCase() : ''; })
                                                    .join('');
                                            }
                                    }
                                } else {
                                    postfix = showWarning ? "unknown" : "new tag";
                                    postfixShort = showWarning ? "unknown" : "new"
                                }

                                // We don't want the text to be too long.
                                if (postfix) {
                                    if ((name + postfix).length > 35) {
                                        postfix = postfixShort;
                                    }

                                    postfix = " <span class='tag-detail'>(" + escapeExpression(postfix) + ")</span>";
                                } else postfix = '';

                                name = "<span class='tag-picker-name'>" + name + "</span>";
                                return prefix + name + postfix;
                            },
                            splitter: function(value) {
                                var ids = splitQuotedString(value);
                                if (!options.sort) return ids;

                                var tags = {};
                                var parent = {};
                                var order = {};

                                ids
                                    .map(function (id) { return parseInt(id) })
                                    .forEach(function (id, i) {
                                        order[id] = i;
                                        var t = items[id];
                                        if (t) {
                                            tags[id] = t;
                                            if (t.children) t.children.forEach(function (c) {
                                                parent[c] = t
                                            })
                                        }
                                    });

                                var conduct = getRiskSegmentList();
                                var journey = getJourneySegmentList();

                                return ids.sort(function (lhs, rhs) {
                                     if (lhs === rhs) return 0;

                                     try {

                                         lhs = parseInt(lhs);
                                         rhs = parseInt(rhs);

                                         var lTag = tags[lhs];
                                         var rTag = tags[rhs];

                                         if (!lTag || !rTag) return order[lhs] - order[rhs]; // Order in string.

                                         if (lTag.flag === "NONE_OF_THE_ABOVE" && rTag.flag !== lTag.flag) return 1;
                                         if (rTag.flag === "NONE_OF_THE_ABOVE" && rTag.flag !== lTag.flag) return -1;

                                         var lSegment = getSegmentList(lhs);
                                         var rSegment = getSegmentList(rhs);

                                         if (lSegment && rSegment && lSegment.id !== rSegment.id) {
                                             if (conduct) {
                                                 if (lSegment.id === conduct.id) return -1;
                                                 if (rSegment.id === conduct.id) return 1;
                                             }

                                             if (journey) {
                                                 if (lSegment.id === journey.id) return -1;
                                                 if (rSegment.id === journey.id) return 1;
                                             }

                                             return lSegment.name < rSegment.name;
                                         }

                                         if (lTag.namespace !== rTag.namespace) {
                                             return lTag.namespace < rTag.namespace;
                                         }

                                         // Sorting tags
                                         var haveOneParent = !parent[lhs] || !parent[rhs];
                                         var haveOneChild = parent[lhs] || parent[rhs];

                                         // Both are parent tags.
                                         if (!haveOneChild) return lTag.name.toLowerCase().localeCompare(rTag.name.toLowerCase());

                                         // Get parent tag IDs.
                                         lhs = parent[lhs] ? parent[lhs].id : lhs;
                                         rhs = parent[rhs] ? parent[rhs].id : rhs;

                                         // Comparing parent and child, so sort by parent IDs.
                                         if (haveOneParent) {
                                             if (lhs === rhs) {
                                                 if (parent[rTag.id]) return -1;
                                                 return 1;
                                             }

                                             return order[lhs] - order[rhs];
                                         }

                                         // Both are children
                                         return lTag.name.toLowerCase().localeCompare(rTag.name.toLowerCase());
                                     } catch (e) {
                                         console.warn(e);
                                         return -1;
                                     }
                                 });
                            },
                            preserveCase: true
                        });
                        conv.call(binding, direction, value);
                    });
                } else {
                    if (placeholder) $(this.boundEls[0]).html(toPlaceholderHTML(placeholder));
                    else $(this.boundEls[0]).text("");
                }
                return value;
            }
        }
    };

    this.converterFactory = this.createConverterFactory(null);

    /**
     * A function for showing tag related tooltips.
     */
    this.tooltip = function(view, item, node) {
        deprecatedFetchTags(view, function(items) {
            var tag = items[Math.abs(item)];
            var nothingToSay = !tag || (!tag.clientDescription && !tag.description && !tag.forCrowdTopicList && !tag.forCrowdTopicDiscovery);

            var description = tag && (tag.clientDescription || tag.description) || null;
            if (description && !description.match("\\.\\?!\"\'\s*$")) {
                description += '.';
            }

            var namespace = null;
            if (tag && tag.namespace && tag.namespace !== 'tag' && tag.namespace !== 'topic') {
                namespace = tag.namespace;
            }

            var isCx = isCxSolution(tag);
            var isRisk = isRiskSolution(tag);
            var isTopic = tag && tag.namespace === 'topic';

            var parent = null;
            if (tag && tag._parent) {
                parent = tag._parent.name;
            }

            // The tag tree can have the wrong parents, since tags can be the children of multiple trees.
            if (isCx || isRisk) {
                var segmentParent = getSegmentList(tag);
                parent = segmentParent ? segmentParent.name : parent;
            }

            if (isTopic) {
                if (parent && tag._parent.namespace !== 'topic') parent = null; // Don't show topic tree information.

                var topicParents = getTopicParents(tag, items);
                if (topicParents && topicParents.length) {
                    parent = topicParents[0].name;
                }
            }

            var children = null;
            var childrenOverflow = false,
                overflowSize = 5;
            if (tag && tag.children && tag.children.length) {
                children = tag.children
                    ?.filter(function (id) {
                        return !!items[id]
                    })
                    ?.slice(overflowSize - 1)
                    ?.map(function (id) {
                        return items[id].name
                    });

                if (tag.children.length > overflowSize) {
                    childrenOverflow = true;
                }
            }

            var template = require('@/dashboards/filter/pickers/tag/TagPickerTooltip.handlebars');
            if (isCx) template = require('@/dashboards/filter/pickers/tag/TagPickerCxTooltip.handlebars');
            if (isRisk) template = require('@/dashboards/filter/pickers/tag/TagPickerRiskTooltip.handlebars');
            if (tag && tag.id <= 4) {
                template = require('@/dashboards/filter/pickers/tag/TagPickerRpcsTooltip.handlebars');
            }

            if (tag && tag.flag === "NONE_OF_THE_ABOVE" && (isRisk || isCx)) {
                template = require('@/dashboards/filter/pickers/tag/TagPickerNoneAboveTooltip.handlebars');
            }

            let tooltipDisplayName = tag && tag.name && tag._parent && tag.flag === "NONE_OF_THE_ABOVE" && tag.namespace === "topic" ?
                `${tag.name} (${tag._parent.name})` : tag && tag.name || item

            Beef.Tooltip.show({
                excluded: item < 0,
                template: template,
                target: node,
                autoclose: true,
                model: new Backbone.Model({
                    tag:                    tag,
                    segment:                getMetatagsFromTags(tag),
                    newTag:                    isNaN(+(item)),
                    name:                   tooltipDisplayName,
                    namespace:              namespace,
                    description:            substituteTagParamaters(description),
                    forCrowdTopicList:      tag && tag.forCrowdTopicList,
                    forCrowdTopicDiscovery: tag && tag.forCrowdTopicDiscovery,
                    nothingToSay:           nothingToSay,
                    and:                    item == "&",
                    parent:                 parent,
                    isTopic:                tag && tag.namespace === 'topic',
                    children:               children,
                    childrenOverflow:       childrenOverflow,
                    created:                tag ? tag.created :null
                })
            });
        });
    };

    this.sortFun = function(lhs, rhs) {
        lhs = lhs.item || lhs;
        rhs = rhs.item || rhs;

        if (lhs.id && lhs.id === rhs.id) return 0;

        // Show our CX / risk / etc solutions first.
        if (lhs.namespace !== rhs.namespace) {
            if (lhs.namespace === 'segment_list') return -1;
            if (rhs.namespace === 'segment_list') return 1;
        }

        if (lhs.namespace === rhs.namespace) {
            // We want to ensure that topics are sorted by whether they're
            // parent topics or not.
            if (lhs.namespace === 'topic') {
                if (lhs.children && !rhs.children) return -1;
                if (rhs.children && !lhs.children) return 1;
            }
        }

        // Retrosent tags are less important than others. Sort at end.
        var isLhsRetrosent = lhs.name.startsWith("Retrosent ");
        var isRhsRetrosent = rhs.name.startsWith("Retrosent ");

        if (isLhsRetrosent && !isRhsRetrosent) return 1;
        if (isRhsRetrosent && !isLhsRetrosent) return -1;


        var lhsParent = getSegmentList(lhs) || lhs._parent;
        var rhsParent = getSegmentList(rhs) || rhs._parent;

        if (!lhsParent && rhsParent) return -1;
        if (rhsParent && !lhsParent) return 1;
        if (lhsParent && rhsParent && lhsParent.id !== rhsParent.id) {
            return lhsParent.namespace.toLowerCase().localeCompare(rhsParent.namespace.toLowerCase())
        }

        if (lhs.namespace !== rhs.namespace) {
            return lhs.namespace.localeCompare(rhs.namespace);
        }

        if (lhs.namespace === "segment") {
            if (lhs.flag !== rhs.flag) {
                // Show none of the above last.
                if (lhs.flag === 'NONE_OF_THE_ABOVE') return 1;
                if (rhs.flag === 'NONE_OF_THE_ABOVE') return -1;
            }

            // Keep segments in the order that they appear.
            var lhsSegment = getSegment(lhs.id);
            var rhsSegment = getSegment(rhs.id);
            return (lhsSegment && lhsSegment.index || lhs.id) - (rhsSegment && rhsSegment.index || rhs.id);
        }


        if (lhs.namespace === "BrandsEye") {
            return (lhs.ordinal || lhs.id) - (rhs.ordinal || rhs.id);
        }


        if (isLhsRetrosent && isRhsRetrosent) {
            var lhsDateSplit = splitAtSpaces(lhs.name);
            if (lhsDateSplit[2]) var lhsDate = moment(lhsDateSplit[1] + " " + lhsDateSplit[2]);
            var rhsDateSplit = splitAtSpaces(lhs.name);
            if (rhsDateSplit[2]) var rhsDate = moment(rhsDateSplit[1] + " " + rhsDateSplit[2]);
            if (lhsDate && rhsDate) {
                return lhsDate.isBefore(rhsDate) ? -1 : 1;
            }
        }
        return lhs.name.toLowerCase().localeCompare(rhs.name.toLowerCase());
    };


    this.tagChooser = function(d) {
        var namespace = d.item.namespace;
        switch (namespace) {
            case "topic":
                return false;
            case 'crowd':
            case 'system':
                return isMashAdmin();
            case 'segment':
            case 'segment_list':
                return false;
        }
        return d.item.id >= 1000;
    };

    this.topicChooser = function(d) {
        return d.item.namespace === 'topic';
    };

    this.segmentListChooser = function(d) {
        return d.item.namespace === 'segment_list';
    };

    this.segmentChooser = function(d) {
        if (d.item.namespace !== 'segment_list' && d.item.namespace !== 'segment') return false;

        // We don't want the journey and channel children to appear in the main list,
        // but having the parents appear there is nice and useful.
        // None of the above should also be here.

        if (d.item.flag === "NONE_OF_THE_ABOVE") return true;
        const parent = getSegmentList(d.item);

        const journey = getCxSegmentListsOnBrands() ?? [];
        const channel = getChannelSegmentListsOnBrands() ?? [];
        const risk = getRiskSegmentListsOnBrands() ?? [];
        const conduct = getMarketConductSegmentListsOnBrands() ?? [];

        const allListIds = new Set();
        [...journey, ...channel, ...risk, ...conduct].forEach(t => allListIds.add(t.id));

        if (allListIds.has(d.item.id)) return true;
        if (allListIds.has(parent && parent.id)) return false;

        return d.item.namespace === 'segment_list' || d.item.namespace === 'segment';
    };

    this.autoAssignChooser = function(d) {
        return d.item.autoAssign;
    };

    /**
     * Attach a tag picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        var tooltip = this.tooltip;
        options = Object.assign({
            splitter: function(value) { return splitQuotedString(value); },
            mouseover: function(item, node) { tooltip(view, item, node); },
            preserveCase: true,
            searchFilter: this.tagChooser,
            sortFun: Beef.TagPicker.sortFun
        }, options || {});
        Beef.Picker.attachInputPicker(view, selector, attribute, this.View, options);
    };

});
