<template>

    <DialogBox modal :title="brand ? 'Edit the brand' : 'Add a brand'" class="edit-brand-dialog" width="550px" @close="emitClose()">
        <div class="deq-reset edit-brand-form dark-scrollbars dark-scrollbars--visible">
            <template v-if="!isLoading">
                <TextInput ref="nameInput" :label="formGroup.name.label" required width="100%" name="name" v-model="formGroup.name.value"
                            :placeholder="formGroup.name.placeholder" class="edit-brand-row text-input" @valid="setValidity('name', $event)"/>

                <p class="consider">Consider including your brands, competitor brands or your full company name</p>

                <div class="edit-brand-row shortname-category-row">
                    <TextInput :label="formGroup.shortName.label" width="100%" name="short name" v-model="formGroup.shortName.value"
                                :placeholder="formGroup.shortName.placeholder" class="text-input"/>

                    <CategoryPicker :label="'Category'" :items="brandCategories" v-model="formGroup.category.value"/>
                </div>

                <ColorPicker v-model="formGroup.colour.value"></ColorPicker>


                <div class="edit-brand-row profile-picker-row">
                    <label>Online profiles used for customer support</label>
                    <div class="deq-input-div" @click="onShowSupportProfilePicker">
                        <span class="deq-placeholder" v-if="!formGroup.supportProfileIds.value.display.length">Support profiles</span>
                        <SlottedTag v-for="profile in formGroup.supportProfileIds.value.display" :key="profile" @close="unselectProfile(profile, 'support')">
                            <span v-html="profile"></span>
                        </SlottedTag>
                    </div>
                </div>

                <div class="edit-brand-row profile-picker-row">
                    <label>Other online profiles not used for customer support</label>
                    <div class="deq-input-div" @click="onShowOtherProfilePicker">
                        <span class="deq-placeholder" v-if="!formGroup.otherProfileIds.value.display.length">Non-support profiles</span>
                        <SlottedTag v-for="profile in formGroup.otherProfileIds.value.display" :key="profile" @close="unselectProfile(profile, 'other')">
                            <span v-html="profile"></span>
                        </SlottedTag>
                    </div>
                </div>

                <SocialNetworkPicker class="edit-brand-row" v-model="formGroup.socialNetworks.value"></SocialNetworkPicker>
                <FeedsPicker class="edit-brand-row" v-model="formGroup.feeds.value"></FeedsPicker>

                <MentionForm v-model="formGroup.mentionFilter"></MentionForm>

            </template>

            <LoadingMessage v-if="isLoading" :message="'Setting up your brand form...'" class="edit-brand-loader" />

        </div>


        <template #buttons>
            <div class="deq-dialog-buttons edit-brand-buttons">

                <div class="left-side">
                    <BeButton v-if="brand" link @click="onDelete" :disabled="!isFormValid || isSaving">
                        <TooltipComponent>
                            <span>Delete</span>
                            <template #tooltip>Delete this brand</template>
                        </TooltipComponent>
                    </BeButton>
                    <BeButton v-if="brand" link @click="onArchive" :disabled="!isFormValid || isSaving">
                        <TooltipComponent>
                            <span>Archive</span>
                            <template #tooltip>Archive this brand (stop collecting mentions)</template>
                        </TooltipComponent>
                    </BeButton>
                </div>

                <div class="saving-spinner">
                    <SpinnerComponent v-if="isSaving" :size="16"/>
                </div>

                <div class="right-side">
                    <BeButton @click="emitClose" :disabled="isSaving" link>
                        <TooltipComponent>
                            <span>Cancel</span>
                            <template #tooltip>Discard changes (Esc)</template>
                        </TooltipComponent>

                    </BeButton>
                    <BeButton @click="onSave" :disabled="!isFormValid || isSaving">Ok</BeButton>
                </div>
            </div>
        </template>

    </DialogBox>
</template>

<script>
import TextInput from "@/components/inputs/TextInput.vue";
import CategoryPicker from "@/setup/brands/inputs/CategoryPicker.vue";
import SocialNetworkPicker from "@/setup/brands/inputs/SocialNetworkPicker.vue";
import ProfilePickerDialog from "@/app/framework/dialogs/ProfilePickerDialog.vue";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import SlottedTag from "@/components/tags/SlottedTag.vue";
import {updateOtherProfiles, updateSupportProfiles} from "@/setup/brands/tree/rootBrandsHelpers";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapGetters, mapState} from "vuex";
import FeedsPicker from "@/setup/brands/inputs/FeedsPicker.vue";
import BeButton from "@/components/buttons/BeButton.vue";
import DialogBox from "@/components/DialogBox.vue";
import LoadingMessage from "@/components/LoadingMessage.vue";
import ColorPicker from "@/setup/brands/inputs/color-picker/ColorPicker.vue";
import {mash} from "@/store/Services";
import {notifyUser} from "@/app/framework/notifications/Notifications";
import ConfirmationDialog from "@/setup/brands/forms/ConfirmationDialog.vue";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import MentionForm from "@/setup/brands/inputs/MentionForm.vue";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";

export default {
    name: "EditRootBrand",
    props: ['brand'],
    store: VuexStore,
    components: {
        TooltipComponent,
        MentionForm, SpinnerComponent, LoadingMessage, BeButton, DialogBox, SlottedTag, TextInput, FeedsPicker,
        SocialNetworkPicker, CategoryPicker,  ColorPicker
    },

    data() {
        return {
            formGroup: {
                name: {
                    label: "I'd like to monitor the online sentiment towards",
                    title: '',
                    placeholder: 'Name',
                    required: true,
                    value: this.brand?.name ? this.brand?.name : '',
                    error: '',
                    isValid: true,
                },
                shortName: {
                    label: 'Client facing brand name',
                    title: 'This name will be used in charts and other places where we want to show a client facing name for the brand',
                    placeholder: 'Client facing brand name',
                    required: false,
                    value: this.brand?.shortName ? this.brand?.shortName : '',
                    error: '',
                    isValid: true,
                },
                category: {
                    label: '',
                    title: '',
                    placeholder: '',
                    required: false,
                    value: this.brand?.category ? this.brand?.category : '',
                    error: '',
                    isValid: false,
                },
                colour: {
                    label: '',
                    title: '',
                    placeholder: '',
                    required: false,
                    value: this.brand?.colour ? this.brand?.colour : null,
                    error: '',
                    isValid: false,
                },
                supportProfileIds: {
                    label: 'Online profiles used for customer support',
                    title: '',
                    placeholder: '',
                    required: false,
                    value: {data: this.brand?.supportProfileIds, display: []},
                    error: '',
                    isValid: false,
                },
                otherProfileIds: {
                    label: 'Other online profiles not used for customer support',
                    title: '',
                    placeholder: '',
                    required: false,
                    value: {data: this.brand?.otherProfileIds, display: []},
                    error: '',
                    isValid: false,
                },
                conversationLocations: {
                    label: 'Other online profiles not used for customer support',
                    title: '',
                    placeholder: '',
                    required: false,
                    value: '',
                    error: '',
                    isValid: false,
                },
                authorBioLocation: {
                    label: 'Other online profiles not used for customer support',
                    title: '',
                    placeholder: '',
                    required: false,
                    value: [],
                    error: '',
                    isValid: false,
                },
                sampleRate: {
                    label: 'Other online profiles not used for customer support',
                    title: '',
                    placeholder: '',
                    required: false,
                    value: 0,
                    error: '',
                    isValid: false,
                },
                dissallowReshares: {
                    label: 'Other online profiles not used for customer support',
                    title: '',
                    placeholder: '',
                    required: false,
                    value: false,
                    error: '',
                    isValid: false,
                },
                twitter: {
                    label: 'Other online profiles not used for customer support',
                    title: '',
                    placeholder: '',
                    required: false,
                    value: true,
                    error: '',
                    isValid: false,
                },
                webhose: {
                    label: 'Other online profiles not used for customer support',
                    title: '',
                    placeholder: '',
                    required: false,
                    value: true,
                    error: '',
                    isValid: false,
                },
                socialNetworks: {
                    label: 'Other online profiles not used for customer support',
                    title: '',
                    placeholder: '',
                    required: false,
                    value: this.brand?.socialNetworks ? this.brand.socialNetworks : '',
                    error: '',
                    isValid: false,
                },
                feeds: {
                    label: 'Other online profiles not used for customer support',
                    title: '',
                    placeholder: '',
                    required: false,
                    value: this.brand?.feeds ? this.brand.feeds : '',
                    error: '',
                    isValid: false,
                },
                conversationLanguages: {
                    label: 'Other online profiles not used for customer support',
                    title: '',
                    placeholder: '',
                    required: false,
                    value: '',
                    error: '',
                    isValid: false,
                },
                mentionFilter: this.brand?.mentionFilter ? this.brand.mentionFilter : ''
            },

            brandCategories: [
                {
                    name: 'OWN',
                    displayName: 'Own Brand',
                    description: 'One of your own brands'
                },
                {
                    name: 'COMPETITOR',
                    displayName: 'Competitor',
                    description: 'A competitor brand'
                }
            ],

            openAdvOps: false,
            isLoading: true,
            isSaving: false,
            isFormValid: true,
            operationType: '',
        }
    },

    computed: {
        ...mapGetters('profiles', ['getNonDeletedProfiles']),
        ...mapState(['account']),
    },

    activated() {
        this.isLoading = true;
    },

    watch: {
        "formGroup.name.isValid"(newValue) {
                this.isFormValid = newValue;
        }
    },

    async mounted() {
        this.isLoading = true;
        await this.refreshProfiles();
        const updateSupportProfiles1 = updateSupportProfiles(
            this.brand?.supportProfileIds ? this.brand?.supportProfileIds : [], this.getNonDeletedProfiles);
        this.formGroup.supportProfileIds.value = {display: updateSupportProfiles1.display, data: updateSupportProfiles1.data.map(data => data.id)}
        const updateOtherProfiles1 = updateOtherProfiles(
            this.brand?.otherProfileIds ? this.brand.otherProfileIds : [], this.getNonDeletedProfiles);
        this.formGroup.otherProfileIds.value = {display: updateOtherProfiles1.display, data: updateOtherProfiles1.data.map(data => data.id)};

        this.isLoading = false;

    },

    methods: {
        ...mapActions('profiles', ['refreshProfiles']),
        ...mapActions(['updateBrand', 'addBrand', 'deleteBrand']),

        onShowSupportProfilePicker() {
            // negation was set to false in handlebars EditBrand.js -> showSupportProfilePicker
            this.onShowProfilePicker(this.formGroup.supportProfileIds.value.data,false, this.supportProfileSetter);

        },
        supportProfileSetter(profileIds) {
            const formatted = updateSupportProfiles(profileIds, this.getNonDeletedProfiles);
            this.formGroup.supportProfileIds.value = {display: formatted.display, data: formatted.data.map(data => data.id)};
        },

        onShowOtherProfilePicker() {
            // negation was set to false in handlebars EditBrand.js -> showOtherProfilePicker
            this.onShowProfilePicker(this.formGroup.otherProfileIds.value.data, false, this.otherProfileSetter);
        },

        otherProfileSetter(profileIds) {
            const formatted = updateOtherProfiles(profileIds, this.getNonDeletedProfiles);
            this.formGroup.otherProfileIds.value = {display: formatted.display, data: formatted.data.map(data => data.id)};
        },


        onShowProfilePicker(previousSelected, allowNegation, setter) {
            showDialogComponent(ProfilePickerDialog, {
                previousSelected: previousSelected,
                allowNegation: allowNegation
            }).$on('selected', (profileIds) => setter(profileIds))
        },

        unselectProfile(profile, type) {

            if (type === 'support') {
                const indexOf = this.formGroup.supportProfileIds.value.display.indexOf(profile);
                this.formGroup.supportProfileIds.value.data.splice(indexOf, 1);
                this.formGroup.supportProfileIds.value.display.splice(indexOf, 1);
            }

            if (type === 'other') {
                const indexOf = this.formGroup.otherProfileIds.value.display.indexOf(profile);
                this.formGroup.otherProfileIds.value.data.splice(indexOf, 1);
                this.formGroup.otherProfileIds.value.display.splice(indexOf, 1);
            }


        },

        toggleAdvOps() {
            this.openAdvOps = !this.openAdvOps;
        },

        emitClose() {
            this.$emit('close');
        },

        async onSave() {
            this.isSaving = true;
            this.operationType = 'save';
            try {
                await this.submit()
            } catch (error) {
                console.warn("An error occurred while updating the brand. ", error)
            } finally {
                this.isSaving = false;
                this.operationType = '';
            }
        },

        async onArchive() {
            this.isSaving = true;
            this.operationType = 'archive';
            showDialogComponent(ConfirmationDialog, {
                message: `Are you sure you want to archive the brand, '${this.brand.shortName || this.brand.name}' ? It will not collect mentions while archived.`,
                onYesClick: this.submit,
                onNoClick: this.emitClose
            });
        },

        async onDelete() {
            this.isSaving = true;
            this.operationType = 'delete';
            showDialogComponent(ConfirmationDialog, {
                message: `Are you sure you want to delete the brand, '${this.brand.shortName || this.brand.name}' along with its phrases, all of its sub-brands and all of their phrases?`,
                onYesClick: this.submit,
                onNoClick: this.emitClose
            });
        },

        async submit() {
            this.isSaving = true;

            try {

                if (!this.isFormValid) {
                    throw new Error('Brand name is required.')
                }

                if (!this.formGroup.name.value.length) {
                    this.$refs.nameInput.validate();
                    throw new Error('Brand name is required.')
                }

                switch (this.operationType) {
                    case 'save': {
                        if (this.brand?.id) {
                            await this.updateBrand(this.extractFormData());
                        } else {
                            await this.addBrand(this.extractFormData());
                        }
                        notifyUser(`Successfully updated ${this.brand ? this.brand.name || this.brand.shortName : this.formGroup.name.value}.`);
                        this.emitClose();
                        break;
                    }
                    case 'archive': {
                        const formData = this.extractFormData();
                        formData.archived = true;
                        await this.updateBrand(formData)
                        notifyUser(`Successfully archived ${this.brand.name || this.brand.shortName}.`);
                        this.emitClose();
                        break;
                    }
                    case 'delete': {
                        await this.deleteBrand(this.brand.id);
                        notifyUser(`Successfully deleted ${this.brand.name || this.brand.shortName}.`);
                        this.emitClose();
                        break;
                    }
                }

            } catch (error) {
                console.warn(`An error occurred saving. `, error)
            } finally {
                this.isSaving = false;
                this.operationType = '';
            }
        },

        /**
         *
         * @returns {Brand}
         */
        extractFormData() {

            const submissionData = {
                id: this.brand?.id ? this.brand.id : null,
                name: "",
                shortName: "",
                category: "",
                colour: "",
                mentionFilter: "",
                subPhraseMatching: false, // always false on this brand level
                supportProfileIds: [],
                otherProfileIds: [],
                socialNetworks: "",
                feeds: ""
            };

            for (let formGroupKey in this.formGroup) {
                switch (formGroupKey) {
                    case 'supportProfileIds':
                        submissionData.supportProfileIds = this.formGroup['supportProfileIds'].value.data;
                        break;
                    case 'otherProfileIds':
                        submissionData.otherProfileIds = this.formGroup['otherProfileIds'].value.data;
                        break;
                    case 'mentionFilter':
                        submissionData.mentionFilter = this.formGroup['mentionFilter'];
                        break;
                    default:
                        submissionData[formGroupKey] = this.formGroup[formGroupKey].value;
                        break;
                }
            }

            return submissionData;
        },

        setValidity(formControl, event) {
            if (formControl === 'name') {
                this.formGroup.name.isValid = event;
            }
        }


    }
}
</script>

<style scoped lang="sass">

@use "../../tree/edit-brand-dialog"
$checkboxGap: 7px
$labelBottomMargin: 5px
$controlSpacing: 10px

.category-picker
    margin-top: 6px

.edit-brand-form
    height: 50vh
    overflow-y: scroll

.profile-picker-row

    .deq-input-div
        min-height: 28px
        display: flex
        flex-wrap: wrap
        cursor: pointer

        .deq-placeholder
            position: relative
            top: 2px
            margin-left: 5px

        .be-tag
            cursor: pointer
            margin-right: 5px

            &:hover
                color: var(--be-editable-data-colour)

.option
    display: flex
    flex-direction: row


.consider
    margin: 0 0 0 0

.shortname-category-row
    display: flex
    flex-direction: row
    align-items: baseline
    justify-content: space-between

    .text-input
        width: 50%
</style>