import { profileTypes } from "@/setup/profiles/ProfileUtils";
import { beef } from "@/store/Services";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let managedYoutubeProfilesPromise = null;
let initializedYoutubeProfiles = false;

export default {
    namespaced: true,

    state: {
        youtubeLoginStatus: {
            loggedIn: false, // is the user currently logged in through Youtube?
            loginInvalidated: false // has the user's Youtube login been invalidated?
        },
        youtubeLoggedInAs: null,

        managedYoutubeProfiles: []
    },

    mutations: {
        setYoutubeLoginStatus: (state, value) => state.youtubeLoginStatus = value,
        setYoutubeLoggedInAs: (state, value) => state.youtubeLoggedInAs = value,
        setManagedYoutubeProfiles: (state, value) => state.managedYoutubeProfiles = value
    },

    actions: {
        async initializeManagedYoutubeProfiles({commit, state}, forceRefresh) {
            forceRefresh ??= false;
            if (!forceRefresh && initializedYoutubeProfiles) return;
            let response = null;
            if (managedYoutubeProfilesPromise) response = await managedYoutubeProfilesPromise;
            else {
                try{
                    managedYoutubeProfilesPromise = beef.get("/api/online-profile/youtube/list");
                    response = await managedYoutubeProfilesPromise;
                }finally{
                    managedYoutubeProfilesPromise= null;
                }
            }
            if (response && response.status === 200) {
                initializedYoutubeProfiles = true;

                let profileData = response.data?.profileData ?? []
                let userInfo = response.data?.userInfo ? {name: response.data?.userInfo?.name, id: response.data?.userInfo?.id} : null
                profileData.forEach(youtubeProfile => {
                    youtubeProfile.type = profileTypes.youtube;
                })
                commit('setManagedYoutubeProfiles', profileData);
                commit('setYoutubeLoggedInAs', userInfo );
            }
        }
    }
};
