<template>

    <DialogBox modal :title="getTitle" class="edit-phrase-dialog" width="650px" @close="emitClose()">
        <div class="deq-reset edit-brand-form edit-phrase-form dark-scrollbars dark-scrollbars--visible">

            <progress-bar :total-stages="3" :cur-stage="pages[activePage].id"/>

            <!-- <Transition>-->
            <KeepAlive>
                <QueryPage v-if="!isLoading && activePage === 'queryForm'" :phrase="phrase" :query-form-data="queryFormData"
                           @query-form-updated="onQueryFormUpdated" @is-good-query-updated="setIsGoodQueryUpdated"
                           @button-update="updateButtons" @is-query-valid-updated="onIsQueryValidUpdated"
                           @is-goodness-check-required="onIsGoodnessCheckRequired"
                           @hook:mounted="handlePageMount()">
                </QueryPage>
            </KeepAlive>
            <!-- </Transition>-->

            <!-- <Transition>-->
            <PhraseGoodness v-if="!isLoading && activePage  === 'goodnessForm'" :root-brand-id="rootBrandId"
                            :query-form-data="queryFormData" @mentions-selected="onMentionsSelected"
                            @goodness-results-retrieved="onGoodnessResultsRetrieved" @button-update="updateButtons"
                            @hook:mounted="handlePageMount()">
            </PhraseGoodness>
            <!-- </Transition>-->

            <!-- <Transition>-->
            <PhraseTune v-if="!isLoading && activePage  === 'tuneForm'" @hook:mounted="handlePageMount()" @button-update="updateButtons"
                        :goodness-results="goodnessResults" :selected-mentions="selectedMentions">
            </PhraseTune>
            <!-- </Transition>-->

            <LoadingMessage v-if="isLoading" :message="'Setting up your brand form...'" class="edit-brand-loader" />

        </div>


        <template #buttons>
            <div class="deq-dialog-buttons edit-brand-buttons">

              <template v-if="!isLoading">
                  <div class="left-side">
                      <BeButton v-if="phrase.id && !phrase.deleted" link @click="onDelete" :disabled="!isFormValid || isSaving">
                          <TooltipComponent>
                              <span>Delete</span>
                              <template #tooltip>Delete this phrase</template>
                          </TooltipComponent>
                      </BeButton>
                      <BeButton v-if="phrase.id" link @click="onMove" :disabled="!isFormValid || isSaving">
                          <TooltipComponent>
                              <span>Move</span>
                              <template #tooltip>Move this phrase to a different brand</template>
                          </TooltipComponent>
                      </BeButton>
                  </div>

                  <div class="saving-spinner">
                      <SpinnerComponent v-if="isSaving" :size="16"/>
                  </div>

                  <div class="right-side">
                      <BeButton @click="emitClose" :disabled="isSaving" link>
                          <TooltipComponent>
                              <span>Cancel</span>
                              <template #tooltip>Discard changes (Esc)</template>
                          </TooltipComponent>
                      </BeButton>

                      <BeButton v-if="activePage === 'goodnessForm' || activePage === 'tuneForm'" @click="onBackClick"
                                :disabled="progressButtons.prev">
                          Previous
                      </BeButton>
                      <BeButton v-if="activePage === 'queryForm' || activePage === 'goodnessForm'" @click="onNextClick"
                                :disabled="progressButtons.next">
                          Next
                      </BeButton>
                      <BeButton v-if="!progressButtons.ok" @click="onSave" :disabled="progressButtons.ok">Ok</BeButton>
                  </div>
              </template>
            </div>
        </template>
    </DialogBox>

</template>

<script>
import DialogBox from "@/components/DialogBox.vue";
import BeButton from "@/components/buttons/BeButton.vue";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import LoadingMessage from "@/components/LoadingMessage.vue";
import PhraseGoodness from "@/setup/brands/forms/add-edit-phrase/PhraseGoodness.vue";
import PhraseTune from "@/setup/brands/forms/add-edit-phrase/PhraseTune.vue";
import {BrandPhrase} from "@/app/utils/types";
import QueryPage from "@/setup/brands/forms/add-edit-phrase/QueryPage.vue";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapGetters, mapState} from "vuex";
import {notifyUser, notifyUserOfError} from "@/app/framework/notifications/Notifications";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import MovePhraseDialog from "@/setup/brands/forms/MovePhraseDialog.vue";
import {getRootBrandFor} from "@/app/utils/Brands";
import {isPhraseANo} from "@/app/utils/Phrases";
import {addBusyPhrases} from "@/app/Permissions";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";
import ProgressBar from "@/components/ProgressBar.vue";

export default {
    name: "AddPhraseDialog",
    store: VuexStore,
    components: {
        ProgressBar,
        TooltipComponent,
        QueryPage,
        PhraseTune,
        PhraseGoodness, LoadingMessage,
        SpinnerComponent, BeButton, DialogBox
    },

    props: {
        phrase: {
            type: BrandPhrase,
            required: true
        },
        rootBrandId: {
            type: Number,
            required: true
        },
        fromHistoricalSearch: {
            type: Boolean,
            required: false,
            default: false
        },
        searchToggles: {}
    },

    data() {
        return {
            isLoading: true,
            isSaving: false,
            isFormValid: true,
            openAdvOps: false,
            operationType: '',

            /**
             * @type {'goodnessForm' | 'queryForm' | 'tuneForm'}
             */
            activePage: 'queryForm',
            pages: {
                queryForm: {
                    id: 1
                },
                goodnessForm: {
                    id: 2
                },
                tuneForm: {
                    id: 3
                },
            },
            hasMounted: false,
            progressButtons: {
                ok: false,
                next: false,
                prev: false,
            },

            /**
             * @type {QueryForm}
             */
            queryFormData: {},
            isGoodQuery: false,
            isQueryValid: false,


            showGoodness: false,
            isGoodnessCheckRequired: false,
            /** @type {Goodness} */
            goodnessResults: null,
            selectedMentions: [],

        }
    },

    computed: {
        ...mapState(['account']),
        ...mapGetters(['idToBrand']),
        getTitle() {
            return this.phrase.id ? 'Edit the search phrase' : 'Add a search phrase'
        }
    },

    watch: {
        activePage(newPage) {
            if (newPage === 'goodnessForm') {
                this.goodnessResults = undefined;
                this.selectedMentions = [];
            }
        }
    },

    mounted() {
        this.isLoading = true;

        this.activePage = 'queryForm';

        this.isLoading = false;
    },

    destroyed() {
    },

    methods: {
        ...mapActions(['createPhrase', 'updatePhrase', 'deletePhrase']),

        emitClose() {
            this.$emit('close');
        },

        onNextClick() {
            if (!this.isNextAllowed()) return
            this.hasMounted = false;
            switch (this.activePage) {
                case 'queryForm':
                    this.activePage = 'goodnessForm';
                    break;
                case 'goodnessForm':
                    this.activePage = 'tuneForm';
                    break;
            }
        },

        onBackClick() {
            if (!this.isPrevAllowed()) return
            this.hasMounted = false;

            switch (this.activePage) {
                case 'tuneForm':
                    this.activePage = 'goodnessForm';
                    break;
                case 'goodnessForm':
                    this.activePage = 'queryForm';
                    break;
            }
        },

        async onDelete() {
            this.isSaving = true;
            try {
                await this.deletePhrase(this.phrase.id)
                notifyUser('Successfully deleted phrase');
                this.emitClose();
            } catch (err) {
                console.warn(`An error occurred while deleting phrase: ${this.phrase.id}, error: `, err);
                notifyUserOfError('An error occurred while deleting phrase');
            } finally {
                this.isSaving = false;
            }
        },

        onMove() {
            showDialogComponent(MovePhraseDialog, {phrase: this.phrase, searchToggles: this.searchToggles});
            this.emitClose();
        },

        async onSave() {
            this.isSaving = true;

            const request = {
                "query": this.queryFormData.query,
                "active": this.queryFormData.active,
                "brandId": this.phrase.parent.id,
                "mentionFilter": this.queryFormData.mentionFilter,
                "feeds": this.queryFormData.feeds,
                "socialNetworks": this.queryFormData.socialNetworks,
                "deleted": false,
            };

            const brand = this.idToBrand.get(this.phrase.parent.id);

            try {
                if (this.phrase.id) {
                    request.id = this.phrase.id;
                    await this.updatePhrase(request);
                    notifyUser(`Successfully updated phrase for ${brand.shortName || brand.name}`)
                } else {
                    await this.createPhrase(request);
                    notifyUser(`Successfully created new phrase for ${brand.shortName || brand.name}`)
                }
                this.emitClose();
            } catch (error) {
                console.warn('An error occurred saving your phrase: ', error);
                notifyUserOfError('An error occurred saving your phrase');
            }
            this.isSaving = true;

        },


        setValidity(formControl, event) {
            if (formControl === 'name') {
                // this.formGroup.name.isValid = event;
            }
        },

        isLevelNo() {
            if (!this.goodnessResults) return true;
            // in handlebars state.brandPhrase.id is undefined for existing phrases which prevents existing phrases from going to the tune page
            // which means we only need the parentID for new phrases being added?
            try {
                const brand = getRootBrandFor(this.phrase.parent.id);
                return isPhraseANo(brand, {mentionFilter: this.queryFormData?.mentionFilter}, {
                    level: this.goodnessResults.level,
                    mentionsTotal: this.goodnessResults.mentionsTotal
                });
            } catch (err) {
                return true
            }
        },

        isNoMentions() {
            return this.goodnessResults?.mentions != null && this.goodnessResults?.mentions.length === 0
        },

        isOkAllowed() {

            return this.isOKForHistoricalSearch() || ((this.activePage !== "goodnessForm" || this.isNoMentions())
                && this.isQueryValid
                && !this.isLevelNo()
                && (this.goodnessResults && this.goodnessResults.query) && (this.isGoodQuery || addBusyPhrases()));
        },

        /**
         * Checks whether the phrase is OK for the historical search setup.
         */
        isOKForHistoricalSearch() {
            return (
                this.fromHistoricalSearch
                && (this.activePage === "goodnessForm")
                && (this.goodnessResults && this.goodnessResults.query)
                && this.isQueryValid
            );
        },

        isNextAllowed() {
            if (this.activePage === "queryForm") {
                return this.isQueryValid;
            }
            if (this.activePage === "goodnessForm") {
                return this.hasMounted && !this.isLevelNo() && (this.isGoodQuery || addBusyPhrases()) && !this.isNoMentions();
            }
            if (this.activePage === "tuneForm") return false;

            return false;
        },

        isPrevAllowed() {
            if (this.activePage === "queryForm") return false;
            if (this.activePage === "goodnessForm") return this.hasMounted;
            if (this.activePage === "tuneForm") return true;
        },

        onQueryFormUpdated(queryForm) {
            this.queryFormData = queryForm;
        },

        setIsGoodQueryUpdated(isGoodQuery) {
            this.isGoodQuery = isGoodQuery;
        },

        onIsQueryValidUpdated(isQueryValid) {
            this.isQueryValid = isQueryValid;
        },

        onGoodnessResultsRetrieved(goodnessResults) {
            this.goodnessResults = goodnessResults;
            this.isGoodnessCheckRequired = false;
        },

        onIsGoodnessCheckRequired(isGoodnessCheckRequired) {
            this.isGoodnessCheckRequired = isGoodnessCheckRequired;
        },

        onMentionsSelected(selectedMentions) {
            this.selectedMentions = selectedMentions;
            this.updateButtons();
        },

        updateButtons() {
            this.progressButtons.ok = !this.isOkAllowed();
            this.progressButtons.next = !this.isNextAllowed();
            this.progressButtons.prev = !this.isPrevAllowed();
        },

        handlePageMount() {
            this.hasMounted = true;
        }

    }
}
</script>

<style scoped lang="sass">
@use "../../tree/edit-brand-dialog"

.edit-phrase-form
    height: 65vh
    overflow-y: auto

    .progress-bar
        margin-top: 20px
        margin-bottom: 10px

.v-enter-active
    transition: all 0.3s ease-in
.v-leave-active
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1)

.v-enter
    opacity: 0
    transform: translateX(40px)

.v-leave-to
    opacity: 0
    transform: translateX(40px)

</style>