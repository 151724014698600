import { profileTypes } from "@/setup/profiles/ProfileUtils";
import { beef } from "@/store/Services";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let managedTiktokAdsProfilesPromise = null;
let initializedTiktokAdsProfiles = false;

export default {
    namespaced: true,

    state: {
        tiktokAdsLoginStatus: {
            loggedIn: false, // is the user currently logged in through Tiktok?
            loginInvalidated: false // has the user's Tiktok login been invalidated?
        },
        tiktokAdsLoggedInAs: null,

        managedTiktokAdsProfiles: []
    },

    mutations: {
        setTiktokAdsLoginStatus: (state, value) => state.tiktokAdsLoginStatus = value,
        setTiktokAdsLoggedInAs: (state, value) => state.tiktokAdsLoggedInAs = value,
        setManagedTiktokAdsProfiles: (state, value) => state.managedTiktokAdsProfiles = value
    },

    actions: {
        async initializeManagedTiktokAdsProfiles({commit, state}, forceRefresh) {
            forceRefresh ??= false;
            if (!forceRefresh && initializedTiktokAdsProfiles) return;
            let response = null;
            if (managedTiktokAdsProfilesPromise) response = await managedTiktokAdsProfilesPromise;
            else {
                try{
                    managedTiktokAdsProfilesPromise = beef.get("/api/online-profile/tiktok-ads/list");
                    response = await managedTiktokAdsProfilesPromise;
                }finally{
                    managedTiktokAdsProfilesPromise= null;
                }
            }
            if (response.status === 200) {
                initializedTiktokAdsProfiles = true;
                commit('setManagedTiktokAdsProfiles', response.data);
            }
        }
    }
};
