var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"mention-item__selection-button\" title=\"Click to select/un-select mention\" >\n        <span class=\"mention-item__selection-box\">\n        </span>\n<!--        <i class=\"selected-icon-bg icon-circle\"></i>-->\n<!--        <i class=\"selected-icon-off icon-circle-empty\"></i>-->\n<!--        <i class=\"selected-icon-on icon-ok-circled-1\"></i>-->\n    </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"sentiment") : depth0),">",0,{"name":"ifcond","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":17,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"sentiment") : depth0),"==",0,{"name":"ifcond","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":20,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"sentiment") : depth0),"<",0,{"name":"ifcond","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":23,"column":27}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"good\"><i class=\"icon-circle\"></i></div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"neutral\"><i class=\"icon-circle\"></i></div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"bad\" ><i class=\"icon-circle\"></i></div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./mclogo.handlebars"),depth0,{"name":"mclogo","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../helpers/reverseEach.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"_metatags") : depth0),{"name":"reverseEach","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":41,"column":28}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"last")),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":37,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":40,"column":27}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <be-rpcs-icon code=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\" full></be-rpcs-icon>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <be-rpcs-icon code=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\"></be-rpcs-icon>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"followOnTwitter") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":8},"end":{"line":52,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"facebook") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":8},"end":{"line":56,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"whatsapp") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":8},"end":{"line":60,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"googleplus") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":8},"end":{"line":64,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"instagram") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":8},"end":{"line":68,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hellopeter") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":8},"end":{"line":72,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"googleBusiness") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":8},"end":{"line":76,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"youtube") : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":8},"end":{"line":80,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"linkedin") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":8},"end":{"line":84,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tiktok") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":8},"end":{"line":88,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tumblr") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":8},"end":{"line":92,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"vk") : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":8},"end":{"line":96,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"telegram") : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":8},"end":{"line":100,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"trustpilot") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":8},"end":{"line":104,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"website") : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":8},"end":{"line":108,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.program(51, data, 0),"data":data,"loc":{"start":{"line":110,"column":8},"end":{"line":118,"column":15}}})) != null ? stack1 : "")
    + "\n        <a "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"link") : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":11},"end":{"line":120,"column":108}}})) != null ? stack1 : "")
    + ">\n            <div class=\"name\">"
    + alias2(__default(require("../helpers/render-emoji.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"render-emoji","hash":{},"data":data,"loc":{"start":{"line":121,"column":30},"end":{"line":121,"column":51}}}))
    + "</div>\n            <div title=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + "\" class=\"screen-name\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"screenName") : depth0), depth0))
    + "</div>\n        </a>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "            <a class=\"network-interactions greyscale\" title=\"Click for Twitter interaction options\">\n                <i class=\"symbol-twitter\"></i>\n            </a>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "            <a class=\"network-interactions greyscale follow-facebook\" title=\"Click for interaction options\"><i class=\"symbol-facebook-rect\"></i></a>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"network-interactions greyscale follow-whatsapp\" title=\"Click for interaction options\"><i class=\"symbol-whatsapp\"></i></span>\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "            <a class=\"network-interactions greyscale follow-googleplus\" title=\"Click for interaction options\"><i class=\"icon-gplus\"></i></a>\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "            <a class=\"network-interactions greyscale follow-instagram\" title=\"Click for interaction options\"><i class=\"symbol-instagram\"></i></a>\n";
},"29":function(container,depth0,helpers,partials,data) {
    return "            <a class=\"network-interactions greyscale follow-hellopeter\" title=\"Click for interaction options\"><i class=\"symbol-hellopeter\"></i></a>\n";
},"31":function(container,depth0,helpers,partials,data) {
    return "            <a class=\"network-interactions greyscale follow-google\" title=\"Click for interaction options\"><i class=\"symbol-google-full\"></i></a>\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "            <a class=\"network-interactions greyscale follow-youtube\" title=\"Click for interaction options\"><i class=\"symbol-youtube\"></i></a>\n";
},"35":function(container,depth0,helpers,partials,data) {
    return "            <a class=\"network-interactions greyscale follow-linkedin\" title=\"Click for interaction options\"><i class=\"symbol-linkedin-rect\"></i></a>\n";
},"37":function(container,depth0,helpers,partials,data) {
    return "            <a class=\"network-interactions greyscale follow-tiktok\" title=\"Click for interaction options\"><i class=\"symbol-tiktok\"></i></a>\n";
},"39":function(container,depth0,helpers,partials,data) {
    return "            <a class=\"network-interactions greyscale follow-tumblr\" title=\"Click for interaction options\"><i class=\"symbol-tumblr\"></i></a>\n";
},"41":function(container,depth0,helpers,partials,data) {
    return "            <a class=\"network-interactions greyscale follow-vk\" title=\"Click for interaction options\"><i class=\"symbol-vk-rect\"></i></a>\n";
},"43":function(container,depth0,helpers,partials,data) {
    return "            <a class=\"network-interactions greyscale follow-telegram\" title=\"Click for interaction options\"><i class=\"symbol-telegram\"></i></a>\n";
},"45":function(container,depth0,helpers,partials,data) {
    return "            <a class=\"network-interactions greyscale follow-trustpilot\" title=\"Click for interaction options\"><i class=\"symbol-trustpilot\"></i></a>\n";
},"47":function(container,depth0,helpers,partials,data) {
    return "            <a class=\"network-interactions greyscale follow-website\" title=\"Click for interaction options\"><i class=\"symbol-website\"></i></a>\n";
},"49":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <img id=\"m"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\"avatar edit-author\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "\" onerror=\"this.src='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"failAvatar") : depth0), depth0))
    + "'\">\n";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noView") : stack1),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.program(54, data, 0),"data":data,"loc":{"start":{"line":113,"column":12},"end":{"line":117,"column":19}}})) != null ? stack1 : "");
},"52":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <img class=\"avatar\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "\" onerror=\"this.src='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"failAvatar") : depth0), depth0))
    + "'\">\n";
},"54":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <img class=\"avatar edit-author\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "\" onerror=\"this.src='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"failAvatar") : depth0), depth0))
    + "'\">\n";
},"56":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\" target=\"_blank\" title=\"Open the author's profile in a new tab\"";
},"58":function(container,depth0,helpers,partials,data) {
    return "title=\"Click to see the mention in more detail\"";
},"60":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./MentionItemMedia.handlebars"),depth0,{"name":"./MentionItemMedia","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"62":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            This mention was deleted on its social media network on\n            "
    + alias2(__default(require("../helpers/formatAccountDate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"tombstoned") : depth0),"YYYY-MM-DD",{"name":"formatAccountDate","hash":{"plainText":true},"data":data,"loc":{"start":{"line":135,"column":12},"end":{"line":135,"column":72}}}))
    + "\n            at "
    + alias2(__default(require("../helpers/formatAccountDate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"tombstoned") : depth0),"HH:mm",{"name":"formatAccountDate","hash":{"plainText":true},"data":data,"loc":{"start":{"line":136,"column":15},"end":{"line":136,"column":70}}}))
    + ".\n";
},"64":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isAttachment") : depth0),{"name":"if","hash":{},"fn":container.program(65, data, 0),"inverse":container.program(67, data, 0),"data":data,"loc":{"start":{"line":138,"column":12},"end":{"line":156,"column":19}}})) != null ? stack1 : "");
},"65":function(container,depth0,helpers,partials,data) {
    return "                This mention contains no text and only has media attachments\n";
},"67":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"contentHtml") : depth0),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.program(71, data, 0),"data":data,"loc":{"start":{"line":141,"column":16},"end":{"line":155,"column":23}}})) != null ? stack1 : "");
},"68":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPrint") : depth0),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":142,"column":20},"end":{"line":142,"column":203}}})) != null ? stack1 : "")
    + "\n                    "
    + container.escapeExpression(__default(require("../helpers/render-emoji.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"contentHtml") : depth0),{"name":"render-emoji","hash":{},"data":data,"loc":{"start":{"line":143,"column":20},"end":{"line":143,"column":48}}}))
    + "\n";
},"69":function(container,depth0,helpers,partials,data) {
    return "<p style=\"font-style: italic; font-weight: bold\">This is an OCR transcript and may contain errors. Click the title above to view the original printed image.</p>";
},"71":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"combinedHtml") : depth0),{"name":"if","hash":{},"fn":container.program(72, data, 0),"inverse":container.program(77, data, 0),"data":data,"loc":{"start":{"line":145,"column":20},"end":{"line":154,"column":27}}})) != null ? stack1 : "");
},"72":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"usePublishFull") : stack1),{"name":"if","hash":{},"fn":container.program(73, data, 0),"inverse":container.program(75, data, 0),"data":data,"loc":{"start":{"line":146,"column":24},"end":{"line":151,"column":31}}})) != null ? stack1 : "");
},"73":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPrint") : depth0),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":28},"end":{"line":147,"column":211}}})) != null ? stack1 : "")
    + "\n                            "
    + container.escapeExpression(__default(require("../helpers/render-emoji.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"publishFull") : depth0),{"name":"render-emoji","hash":{},"data":data,"loc":{"start":{"line":148,"column":28},"end":{"line":148,"column":56}}}))
    + "\n";
},"75":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(__default(require("../helpers/render-emoji.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"combinedHtml") : depth0),{"name":"render-emoji","hash":{},"data":data,"loc":{"start":{"line":150,"column":28},"end":{"line":150,"column":57}}}))
    + "\n";
},"77":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(__default(require("../helpers/render-emoji.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"render-emoji","hash":{},"data":data,"loc":{"start":{"line":153,"column":24},"end":{"line":153,"column":46}}}))
    + "\n";
},"79":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"uri") : depth0), depth0))
    + "\"";
},"81":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a class=\"engagement show-graph "
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"engagementSize") : depth0), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noViewConversation") : stack1),{"name":"if","hash":{},"fn":container.program(82, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":172,"column":63},"end":{"line":172,"column":128}}})) != null ? stack1 : "")
    + "\" href=\"#\"\n               title=\"This mention is part of a conversation with an engagement of "
    + alias1(__default(require("../helpers/formatNumber.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"engagement") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":173,"column":83},"end":{"line":173,"column":110}}}))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noViewConversation") : stack1),{"name":"unless","hash":{},"fn":container.program(84, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":173,"column":110},"end":{"line":173,"column":219}}})) != null ? stack1 : "")
    + "\">\n                <i class=\"icon-chat\"></i>\n                <span class=\"engagement-value\">"
    + alias1(__default(require("../helpers/toSi.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"engagement") : depth0),{"name":"toSi","hash":{},"data":data,"loc":{"start":{"line":175,"column":47},"end":{"line":175,"column":66}}}))
    + "</span>\n            </a>\n";
},"82":function(container,depth0,helpers,partials,data) {
    return "no-conversation-pointer";
},"84":function(container,depth0,helpers,partials,data) {
    return ". Click to see the conversation this mention was a part of.";
},"86":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a class=\"engagement show-graph "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noViewConversation") : stack1),{"name":"if","hash":{},"fn":container.program(82, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":178,"column":44},"end":{"line":178,"column":109}}})) != null ? stack1 : "")
    + "\" href=\"#\"\n               title=\"This mention is part of a conversation"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noViewConversation") : stack1),{"name":"unless","hash":{},"fn":container.program(84, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":179,"column":60},"end":{"line":179,"column":169}}})) != null ? stack1 : "")
    + "\">\n                <i class=\"icon-chat adjust-position\"></i>\n            </a>\n";
},"88":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <i class=\"icon-email direct-message\"\n               title=\""
    + container.escapeExpression(__default(require("../helpers/visibilityDescription.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"visibility") : depth0),{"name":"visibilityDescription","hash":{},"data":data,"loc":{"start":{"line":186,"column":22},"end":{"line":186,"column":58}}}))
    + "\">\n            </i>\n";
},"90":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"usePhoto") : depth0),{"name":"unless","hash":{},"fn":container.program(91, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":192,"column":12},"end":{"line":214,"column":23}}})) != null ? stack1 : "");
},"91":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasVideo") : depth0),{"name":"if","hash":{},"fn":container.program(92, data, 0),"inverse":container.program(100, data, 0),"data":data,"loc":{"start":{"line":193,"column":16},"end":{"line":213,"column":23}}})) != null ? stack1 : "");
},"92":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"mediaLinks") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"isYoutubeVid") : stack1),{"name":"if","hash":{},"fn":container.program(93, data, 0),"inverse":container.program(95, data, 0),"data":data,"loc":{"start":{"line":194,"column":20},"end":{"line":201,"column":27}}})) != null ? stack1 : "");
},"93":function(container,depth0,helpers,partials,data) {
    return "                        <a class=\"hint-photo show-graph\"\n                            title=\"This is a YouTube mention, and has video. Click to view it.\">\n                            <i class=\"icon-videocam\"></i>\n                        </a>\n";
},"95":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a class=\"hint-photo show-graph\" title=\"This mention has video. Click to view it.\"><i class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasAudio") : depth0),{"name":"if","hash":{},"fn":container.program(96, data, 0),"inverse":container.program(98, data, 0),"data":data,"loc":{"start":{"line":200,"column":117},"end":{"line":200,"column":176}}})) != null ? stack1 : "")
    + "\"></i></a>\n";
},"96":function(container,depth0,helpers,partials,data) {
    return "icon-headphones";
},"98":function(container,depth0,helpers,partials,data) {
    return "icon-videocam";
},"100":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasPrint") : depth0),{"name":"if","hash":{},"fn":container.program(101, data, 0),"inverse":container.program(103, data, 0),"data":data,"loc":{"start":{"line":203,"column":20},"end":{"line":212,"column":27}}})) != null ? stack1 : "");
},"101":function(container,depth0,helpers,partials,data) {
    return "                        <a class=\"hint-photo show-graph\" title=\"This is print media.\">\n                            <i class=\"icon-book-open-1\"></i>\n                        </a>\n";
},"103":function(container,depth0,helpers,partials,data) {
    return "                        <a class=\"hint-photo show-graph\"\n                           title=\"This mention has images. Click to see more.\">\n                            <i class=\"icon-camera\"></i>\n                        </a>\n";
},"105":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class=\"actions\">\n        <span class=\"translation\">\n            <a class=\"mention-translation "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"translationDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(106, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":222,"column":42},"end":{"line":222,"column":84}}})) != null ? stack1 : "")
    + "\"\n                "
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"language") : depth0),{"name":"with","hash":{},"fn":container.program(108, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":223,"column":16},"end":{"line":223,"column":87}}})) != null ? stack1 : "")
    + "\n               title=\"Translate this mention into "
    + container.escapeExpression(__default(require("../helpers/defaultLanguageName.js")).call(alias1,{"name":"defaultLanguageName","hash":{},"data":data,"loc":{"start":{"line":224,"column":50},"end":{"line":224,"column":73}}}))
    + "\">\n                <i class=\"symbol-translate\"></i>\n            </a>\n            <a class=\"mention-translation-options\" title=\"Translation options\"><i class=\"icon-down-dir-1\"></i></a>\n        </span>\n\n        <a class=\"export-image\"\n           title=\"Download this mention as an image\">\n            <i class=\"symbol-download\"></i>\n        </a>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"engageIndirectRepliesAllowed") : depth0),{"name":"if","hash":{},"fn":container.program(111, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":235,"column":8},"end":{"line":240,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../helpers/ifallowed.js")).call(alias1,"editMentions",{"name":"ifallowed","hash":{},"fn":container.program(113, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":242,"column":8},"end":{"line":253,"column":22}}})) != null ? stack1 : "")
    + "    </span>\n";
},"106":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"108":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(109, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":223,"column":34},"end":{"line":223,"column":78}}})) != null ? stack1 : "");
},"109":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-last-language=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"";
},"111":function(container,depth0,helpers,partials,data) {
    return "             <a class=\"create-engage-ticket\"\n                title=\"Create engage ticket from mention\">\n                    <i class=\"icon-ticket\"></i>\n              </a>\n";
},"113":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"showShuffle") : stack1),{"name":"if","hash":{},"fn":container.program(114, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":243,"column":12},"end":{"line":245,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"if","hash":{},"fn":container.program(116, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":246,"column":12},"end":{"line":252,"column":19}}})) != null ? stack1 : "");
},"114":function(container,depth0,helpers,partials,data) {
    return "                <a class=\"shuffle-mention\" href=\"#\" title=\"Replace mention\"><i class=\"icon-shuffle\"></i></a>\n";
},"116":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../helpers/ifequal.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"relevancy") : depth0),"IRRELEVANT",{"name":"ifequal","hash":{},"fn":container.program(117, data, 0),"inverse":container.program(119, data, 0),"data":data,"loc":{"start":{"line":247,"column":16},"end":{"line":251,"column":28}}})) != null ? stack1 : "");
},"117":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"delete-link\" href=\"#\" title=\"Un-delete\"><i class=\"icon-ccw\"></i></a>\n";
},"119":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"delete-link\" href=\"#\" title=\"Delete\"><i class=\"icon-trash-1\"></i></a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noSelect") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "\n<section class=\"mention-item__value-bar\">\n    <section class=\"mention-item__lower_value\">\n        <section class=\"mention-item__sentiment\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSentiment") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "        </section>\n        <section class=\"mention-item__mclogo\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showMarketConduct") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":29,"column":19}}})) != null ? stack1 : "")
    + "        </section>\n    </section>\n    <section class=\"mention-item__rpcs be-rpcs-stack\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_metatags") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":42,"column":15}}})) != null ? stack1 : "")
    + "    </section>\n</section>\n\n<div class=\"author\">\n"
    + ((stack1 = __default(require("../helpers/withMentionAuthor.js")).call(alias1,{"name":"withMentionAuthor","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":4},"end":{"line":124,"column":26}}})) != null ? stack1 : "")
    + "</div>\n\n<div class=\"content\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noView") : stack1),{"name":"unless","hash":{},"fn":container.program(58, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":21},"end":{"line":127,"column":106}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"usePhoto") : depth0),{"name":"if","hash":{},"fn":container.program(60, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":128,"column":4},"end":{"line":130,"column":11}}})) != null ? stack1 : "")
    + "\n    <span class=\"mention-text\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tombstoned") : depth0),{"name":"if","hash":{},"fn":container.program(62, data, 0),"inverse":container.program(64, data, 0),"data":data,"loc":{"start":{"line":133,"column":8},"end":{"line":157,"column":15}}})) != null ? stack1 : "")
    + "    </span>\n</div>\n\n<div class=\"footer\">\n    <a class=\"date\"\n       title=\"This mention was published at "
    + alias2(__default(require("../helpers/formatAccountDate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"published") : depth0),"HH:mm",{"name":"formatAccountDate","hash":{"plainText":true},"data":data,"loc":{"start":{"line":163,"column":44},"end":{"line":163,"column":98}}}))
    + " on "
    + alias2(__default(require("../helpers/formatAccountDate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"published") : depth0),"MMMM Do, YYYY",{"name":"formatAccountDate","hash":{"plainText":true},"data":data,"loc":{"start":{"line":163,"column":102},"end":{"line":163,"column":164}}}))
    + "\"\n        "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"visibility") : depth0),{"name":"unless","hash":{},"fn":container.program(79, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":164,"column":8},"end":{"line":164,"column":55}}})) != null ? stack1 : "")
    + "\n        target=\"_blink\">\n        "
    + alias2(__default(require("../helpers/humanizeDate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"published") : depth0),true,{"name":"humanizeDate","hash":{"plainText":true},"data":data,"loc":{"start":{"line":166,"column":8},"end":{"line":166,"column":54}}}))
    + "\n    </a>\n\n    <span class=\"interactions\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"engagement") : depth0),{"name":"if","hash":{},"fn":container.program(81, data, 0),"inverse":container.program(86, data, 0),"data":data,"loc":{"start":{"line":171,"column":8},"end":{"line":182,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"visibility") : depth0),{"name":"if","hash":{},"fn":container.program(88, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":184,"column":8},"end":{"line":188,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hintPhoto") : depth0),{"name":"if","hash":{},"fn":container.program(90, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":191,"column":8},"end":{"line":215,"column":15}}})) != null ? stack1 : "")
    + "\n    </span>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noActions") : stack1),{"name":"unless","hash":{},"fn":container.program(105, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":219,"column":4},"end":{"line":255,"column":15}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});