import VuexStore from "@/store/vuex/VuexStore";
import {getMetatagsInAccount} from "@/app/utils/Metatags";
import {appendSegmentRestrictions, getAllCxSegmentLists, getAllRiskProductSegmentLists} from "@/app/utils/Segments";
import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
import {account} from "@/app/utils/Account";

function createNomenclature() {
    return {
        type: "Nomenclature",
        caption: "Social Customer service metrics",
        width: 6,
        height: 4,
        selectedList: "custom-scs"
    }
}

const socialCustomerService = {
    title: "Social customer service",
    description: "Measure the performance of your social customer service.",
    hide: true,
    expanded:
`_Social customer service_ is defined as the operational servicing of customers through social media 
platforms like Facebook, Twitter, and Instagram. Organisations make use of these platforms to reply 
to posts directed at or speaking about them by people using their products or services.
<br/>
<br/>
These metrics can be used to measure and report on the effectiveness of the teams’ service performance, 
and what impact this has had on both the brand’s reputation and the customer’s experience.
`,
    image: require("../assets/scs.png"),
    metrics: [
        {
            id: "scs/overall",
            title: "Overall comparison",
            description: "Provides an overall comparison of your social customer service performance",
            more: "This allows you to see where improvements can be made in terms of your response time and response rates.",
            hero: true,
            keywords: ["scs", "social", "service", "customer", "interaction", "response"],
            image: require("../assets/scs/comp-table-brand-interactions.png"),
            widgetDesc: async function () {
                return Object.assign({},
                    {
                        type: "CompTable",
                        caption: "Comparison Table: Interactions",
                        width: 5,
                        height: 3,
                        cols: [
                            {
                                id: 1,
                                type: "interactionCount",
                                showValue: true
                            },
                            {
                                id: 2,
                                type: "interactionHasResponseCount",
                                showValue: true
                            },
                            {
                                id: 3,
                                type: "interactionHasNoResponseCount",
                                showValue: true
                            },
                            {
                                id: 4,
                                type: "averageInteractionHasResponse",
                                showPercentage: true
                            },
                            {
                                id: 5,
                                type: "averageInteractionResponseTime",
                                showValue: true
                            },
                            {
                                id: 6,
                                type: "averageInteractionFirstResponseTime",
                                showValue: true
                            },
                            {
                                id: 7,
                                type: "averageInteractionFollowupResponseTime",
                                showValue: true
                            },
                            {
                                id: 8,
                                type: "averageInteractionWhResponseTime",
                                showValue: true
                            },
                            {
                                id: 9,
                                type: "averageInteractionWhFirstResponseTime",
                                showValue: true
                            },
                            {
                                id: 10,
                                type: "averageInteractionWhFollowupResponseTime",
                                showValue: true
                            }
                        ],
                        filter: getBaseFilter(),
                        swapRowsAndCols: true
                    });
            },
            reference: createNomenclature(),
            options: [
                {
                    key: "social-network",
                    title: "By social networks",
                    tooltip: "Compare performance between social networks",
                    action: async function () {
                        return {
                            id: "scs/overall/social-network",
                            widgetDesc: {
                                type: "CompTable",
                                caption: "Comparison Table: Interactions with social networks",
                                width: 6,
                                height: 3,
                                cols: [
                                    {
                                        id: 1,
                                        type: "mentionCount",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 2,
                                        type: "interactionCount",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 3,
                                        type: "interactionHasResponseCount",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 4,
                                        type: "averageInteractionHasResponse",
                                        showPercentage: true,
                                        showBar: true
                                    },
                                    {
                                        id: 5,
                                        type: "averageInteractionResponseTime",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 6,
                                        type: "averageInteractionFirstResponseTime",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 7,
                                        type: "averageInteractionFollowupResponseTime",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 8,
                                        type: "averageInteractionWhResponseTime",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 9,
                                        type: "averageInteractionWhFirstResponseTime",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 10,
                                        type: "averageInteractionWhFollowupResponseTime",
                                        showValue: true,
                                        showBar: true
                                    }
                                ],
                                rows: [
                                    {
                                        id: 1,
                                        groupBy: "socialNetwork",
                                        limit: 99,
                                        showOther: true,
                                        showUnknown: true
                                    }
                                ],
                                filter: getBaseFilter()
                            },
                        };
                    }
                },
                {
                    key: "rpcs",
                    title: "By priority categorisation",
                    tooltip: "Compare performance by priority categorisation",
                    action: async function () {
                        return {
                            id: "scs/overall/rpcs",
                            widgetDesc: {
                                type: "CompTable",
                                caption: "Comparison Table: Interactions with priority categorisation",
                                width: 5,
                                height: 3,
                                cols: [
                                    {
                                        id: 1,
                                        type: "mentionCount",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 2,
                                        type: "interactionCount",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 3,
                                        type: "interactionHasResponseCount",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 4,
                                        type: "averageInteractionHasResponse",
                                        showPercentage: true,
                                        showBar: true
                                    },
                                    {
                                        id: 5,
                                        type: "averageInteractionResponseTime",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 6,
                                        type: "averageInteractionFirstResponseTime",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 7,
                                        type: "averageInteractionFollowupResponseTime",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 8,
                                        type: "averageInteractionWhResponseTime",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 9,
                                        type: "averageInteractionWhFirstResponseTime",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 10,
                                        type: "averageInteractionWhFollowupResponseTime",
                                        showValue: true,
                                        showBar: true
                                    }
                                ],
                                rows: [
                                    {
                                        id: 1,
                                        groupBy: "rpcs",
                                        limit: 99,
                                        showOther: true,
                                        showUnknown: true
                                    }
                                ],
                                filter: getBaseFilter(),
                                swapRowsAndCols: true
                            },
                        };
                    }
                },
                {
                    key: "high-vs-low",
                    title: "By high priority vs low priority",
                    tooltip: "Compare performance between interactions of a high priority vs a low priority",
                    action: async function () {
                        return {
                            id: "scs/overall/high-vs-low",
                            widgetDesc: {
                                type: "CompTable",
                                caption: "Comparison Table: Interactions with high vs low priority",
                                width: 5,
                                height: 3,
                                cols: [
                                    {
                                        id: 1,
                                        type: "mentionCount",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 2,
                                        type: "interactionCount",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 3,
                                        type: "interactionHasResponseCount",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 4,
                                        type: "averageInteractionHasResponse",
                                        showPercentage: true,
                                        showBar: true
                                    },
                                    {
                                        id: 5,
                                        type: "averageInteractionResponseTime",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 6,
                                        type: "averageInteractionFirstResponseTime",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 7,
                                        type: "averageInteractionFollowupResponseTime",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 8,
                                        type: "averageInteractionWhResponseTime",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 9,
                                        type: "averageInteractionWhFirstResponseTime",
                                        showValue: true,
                                        showBar: true
                                    },
                                    {
                                        id: 10,
                                        type: "averageInteractionWhFollowupResponseTime",
                                        showValue: true,
                                        showBar: true
                                    }
                                ],
                                rows: [
                                    {
                                        id: 1,
                                        name: "High priority",
                                        filter: (await getHighVsLowPriorityFilters()).highPriority
                                    },
                                    {
                                        id: 2,
                                        name: "Low priority",
                                        filter: (await getHighVsLowPriorityFilters()).lowPriority
                                    }
                                ],
                                swapRowsAndCols: true
                            },
                        };
                    }
                }
            ]
        },
        {
            id: "scs/unique-interactions",
            title: "Unique interactions",
            description: "The amount of unique interactions between the brand and the consumer",
            keywords: ["scs", "social", "service", "customer", "interaction"],
            image: require("../assets/scs/unique-interactions.png"),
            widgetDesc: async function () {
                return Object.assign({},
                    {
                        type: "FantasticChart",
                        xAxis: "published",
                        yAxis: "interactionCount",
                        width: 7,
                        height: 4,
                        caption: "Unique interactions",
                        filter: getBaseFilter(),
                        show: [
                            {
                                opacity: 100,
                                yAxis: "interactionCount",
                                geometry: "columns",
                                lineWidth: 2,
                                hideLabels: false,
                                labelPlacement: "inside",
                                "colour-index": 10
                            }
                        ]
                    }
                );
            },
            options: [
                {
                    key: "interactions-replied-to",
                    title: "Include interactions replied to",
                    action: async function () {
                        return {
                            id: "scs/unique-interactions/interactions-replied-to",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "published",
                                width: 7,
                                height: 4,
                                caption: "Unique interactions",
                                filter: getBaseFilter(),
                                show: [
                                    {
                                        opacity: 100,
                                        yAxis: "interactionCount",
                                        geometry: "columns",
                                        lineWidth: 2,
                                        hideLabels: false,
                                        labelPlacement: "inside",
                                        "colour-index": 10
                                    },
                                    {
                                        yAxis: "interactionHasResponseCount",
                                        geometry: "lines",
                                        lineWidth: 2,
                                        lineCurve: "smooth",
                                        "colour-palette": "greens9",
                                        "colour-index": 1,
                                        labelPlacement: "inside"
                                    }
                                ],
                            },
                        };
                    }
                },
                {
                    key: "venn",
                    title: "As priority conversation venn diagram",
                    action: async function () {
                        return {
                            type: "Venn",
                            filter: getBaseFilter(),
                            caption: "Priority conversation venn diagram of unique interactions",
                            "selectedDataType": "interactions"
                        };
                    }
                },
                {
                    key: "rpcs",
                    title: "By priority conversation",
                    action: async function () {
                        return {
                            id: "scs/unique-interactions/rpcs",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "published",
                                yAxis: "interactionCount",
                                width: 8,
                                height: 4,
                                stacked: true,
                                compare: "rpcs",
                                commentFontSize: 14,
                                commentWidth: 4,
                                filter: getBaseFilter(),
                                comment: "# Unique interactions by priority category\n\n" +
                                    "# {{risk}}\n\n## {{total unique-interactions \"Tag IS 1\"}} interactions\n\n" +
                                    "# {{purchase}}\n\n## {{total unique-interactions \"Tag IS 2\"}} interactions\n\n" +
                                    "# {{cancel}}\n\n## {{total unique-interactions \"Tag IS 3\"}} interactions\n\n" +
                                    "# {{service}}\n\n## {{total unique-interactions \"Tag IS 4\"}} interactions"
                            },
                        };
                    }
                },
                {
                    key: "social-network",
                    title: "By social networks",
                    action: async function () {
                        return {
                            id: "scs/unique-interactions/social-network",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "published",
                                yAxis: "interactionCount",
                                width: 8,
                                height: 4,
                                stacked: true,
                                compare: "socialNetwork",
                                commentFontSize: 14,
                                commentWidth: 4,
                                filter: getBaseFilter(),
                                comment: "# Unique interactions by Social Network\n\n" +
                                    "# {{x}} X\n\n## {{total unique-interactions \"socialNetwork IS TWITTER\"}} interactions\n\n" +
                                    "# {{facebook}} Facebook\n\n## {{total unique-interactions \"socialNetwork IS FACEBOOK\"}} interactions\n\n" +
                                    "# {{instagram}} Instagram\n\n## {{total unique-interactions \"socialNetwork IS INSTAGRAM\"}} interactions\n\n" +
                                    "# {{hellopeter}} Hellopeter\n\n## {{total unique-interactions \"socialNetwork IS HELLOPETER\"}} interactions\n\n" +
                                    "# {{linkedin}} LinkedIn\n\n## {{total unique-interactions \"socialNetwork IS LINKEDIN\"}} interactions\n\n" +
                                    "# {{googlebusiness-g}} Google Business\n\n## {{total unique-interactions \"socialNetwork IS GOOGLEBUSINESS\"}} interactions\n\n"
                            },
                        };
                    }
                }
            ]
        },
        {
            id: "scs/interaction-response-rate",
            title: "Interaction response rate",
            description: "The average rate at which the brand responds to the consumer",
            keywords: ["scs", "social", "service", "customer", "interaction", "response"],
            image: require("../assets/scs/interaction-response-rate.png"),
            widgetDesc: async function () {
                return Object.assign({},
                    {
                        type: "FantasticChart",
                        xAxis: "published",
                        yAxis: "averageInteractionHasResponse",
                        width: 8,
                        height: 4,
                        caption: "Unique interactions",
                        filter: getBaseFilter(),
                        show: [
                            {
                                opacity: 100,
                                yAxis: "averageInteractionHasResponse",
                                geometry: "columns",
                                hideLabels: false,
                                labelPlacement: "inside",
                                "colour-index": 0
                            }
                        ]
                    }
                );
            },
            options: [
                {
                    key: "interactions-response-rate-vs-response-time",
                    title: "Include response time",
                    action: async function () {
                        return {
                            id: "scs/interaction-response-rate/interaction-response-time",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "published",
                                width: 8,
                                height: 4,
                                caption: "Interaction response rate vs average interaction response time",
                                xGridLines: true,
                                yGridLines: true,
                                filter: getBaseFilter(),
                                show: [
                                    {
                                        opacity: 100,
                                        yAxis: "averageInteractionHasResponse",
                                        geometry: "columns",
                                        labelPlacement: "inside",
                                        "colour-index": 0
                                    },
                                    {
                                        yAxis: "averageInteractionFirstResponseTime",
                                        geometry: "lines",
                                        lineWidth: 2,
                                        lineCurve: "smooth",
                                        labelPlacement: "inside",
                                        "colour-index": 1,
                                        "colour-palette": "blues9"
                                    },
                                    {
                                        yAxis: "averageInteractionResponseTime",
                                        geometry: "lines",
                                        lineWidth: 2,
                                        lineCurve: "smooth",
                                        labelPlacement: "inside",
                                        "colour-index": 2,
                                        "colour-palette": "be14-blue"
                                    }
                                ],
                            },
                        };
                    }
                },
                {
                    key: "rpcs-table",
                    title: "As priority conversation table",
                    action: async function () {
                        return {
                            id: "scs/interaction-response-time/rpcs-rate",
                            widgetDesc: {
                                type: "ResponseTable",
                                caption: "Priority interaction response time & response rate",
                                filter: getBaseFilter(),
                                width: 8,
                                newInteractionResponseTimes: true
                            }
                        };
                    }
                },
                {
                    key: "rpcs",
                    title: "By priority conversation",
                    action: async function () {
                        return {
                            id: "scs/interaction-response-rate/rpcs",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "published",
                                width: 8,
                                height: 4,
                                compare: "rpcs",
                                xGridLines: true,
                                yGridLines: true,
                                filter: getBaseFilter(),
                                show: [
                                    {
                                        opacity: 100,
                                        yAxis: "averageInteractionHasResponse",
                                        geometry: "lines",
                                        lineWidth: 2,
                                        lineCurve: "smooth",
                                        labelPlacement: "inside",
                                        "colour-index": 3
                                    }
                                ],
                                commentFontSize: 14,
                                commentWidth: 4,
                                comment: "# Interaction response rate by Priority Category\n\n" +
                                    "# {{risk}}\n\n## {{total interaction-response-rate \"Tag IS 1\"}}\n\n" +
                                    "# {{purchase}}\n\n## {{total interaction-response-rate \"Tag IS 2\"}}\n\n" +
                                    "# {{cancel}}\n\n## {{total interaction-response-rate \"Tag IS 3\"}}\n\n" +
                                    "# {{service}}\n\n## {{total interaction-response-rate \"Tag IS 4\"}}"
                            },
                        };
                    }
                },
                {
                    key: "social-network",
                    title: "By social network",
                    action: async function () {
                        return {
                            id: "scs/interaction-response-rate/social-network",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "socialNetwork",
                                width: 4,
                                height: 4,
                                stacked: true,
                                filter: getBaseFilter(),
                                show: [
                                    {
                                        opacity: 100,
                                        yAxis: "averageInteractionHasResponse",
                                        geometry: "rows",
                                        hideLabels: false,
                                        labelPlacement: "inside"
                                    }
                                ]
                            },
                        };
                    }
                }
            ]
        },
        {
            id: "scs/interaction-first-response-time-wh",
            title: "First response time within working hours",
            description: "The average amount of time it takes for the brand to first respond to the consumer within the brand's working hours",
            keywords: ["scs", "social", "service", "customer", "interaction", "response"],
            image: require("../assets/scs/interaction-wh-first-response-time.png"),
            widgetDesc: async function () {
                return Object.assign({},
                    {
                        type: "FantasticChart",
                        xAxis: "published",
                        yAxis: "averageInteractionWhFirstResponseTime",
                        width: 8,
                        height: 4,
                        filter: getBaseFilter(),
                        show: [
                            {
                                opacity: 100,
                                yAxis: "averageInteractionWhFirstResponseTime",
                                geometry: "columns",
                                hideLabels: false,
                                labelPlacement: "inside",
                                "colour-index": 1,
                                "colour-palette": "blues9"
                            }
                        ]
                    }
                );
            },
            options: [
                {
                    key: "interactions-first-response-time-wh-vs-response-rate",
                    title: "Include response rate",
                    action: async function () {
                        return {
                            id: "scs/interaction-first-response-time-wh/interaction-response-rate",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "published",
                                width: 8,
                                height: 4,
                                caption: "First response time (working hours) vs average interaction response rate",
                                xGridLines: true,
                                yGridLines: true,
                                filter: getBaseFilter(),
                                show: [
                                    {
                                        opacity: 100,
                                        yAxis: "averageInteractionWhFirstResponseTime",
                                        geometry: "columns",
                                        labelPlacement: "inside",
                                        "colour-index": 1,
                                        "colour-palette": "blues9"
                                    },
                                    {
                                        yAxis: "averageInteractionHasResponse",
                                        geometry: "lines",
                                        lineWidth: 2,
                                        lineCurve: "smooth",
                                        labelPlacement: "inside",
                                        "colour-index": 0
                                    }
                                ],
                            },
                        };
                    }
                },
                {
                    key: "rpcs",
                    title: "By priority conversation",
                    action: async function () {
                        return {
                            id: "scs/interaction-first-response-time-wh/rpcs",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "published",
                                width: 8,
                                height: 4,
                                compare: "rpcs",
                                xGridLines: true,
                                yGridLines: true,
                                filter: getBaseFilter(),
                                show: [
                                    {
                                        opacity: 100,
                                        yAxis: "averageInteractionWhFirstResponseTime",
                                        geometry: "lines",
                                        lineWidth: 2,
                                        lineCurve: "smooth",
                                        labelPlacement: "inside",
                                        "colour-index": 1,
                                        "colour-palette": "blues9"
                                    }
                                ],
                                commentFontSize: 14,
                                commentWidth: 4,
                                comment: "# Average first response time (within working hours) by Priority Category\n\n" +
                                    "# {{risk}}\n\n## {{total interaction-wh-response-time \"Tag IS 1\"}}\n\n" +
                                    "# {{purchase}}\n\n## {{total interaction-wh-response-time \"Tag IS 2\"}}\n\n" +
                                    "# {{cancel}}\n\n## {{total interaction-wh-response-time \"Tag IS 3\"}}\n\n" +
                                    "# {{service}}\n\n## {{total interaction-wh-response-time \"Tag IS 4\"}}"
                            },
                        };
                    }
                },
                {
                    key: "social-network",
                    title: "By social network",
                    action: async function () {
                        return {
                            id: "scs/interaction-first-response-time-wh/social-network",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "socialNetwork",
                                width: 4,
                                height: 4,
                                stacked: true,
                                filter: getBaseFilter(),
                                show: [
                                    {
                                        opacity: 100,
                                        yAxis: "averageInteractionWhFirstResponseTime",
                                        geometry: "rows",
                                        hideLabels: false,
                                        labelPlacement: "inside"
                                    }
                                ]
                            },
                        };
                    }
                }
            ]
        },
        {
            id: "scs/interaction-response-time-wh",
            title: "Interaction response time within working hours",
            description: "The average amount of time it takes for the brand to respond to the consumer within the brand's working hours",
            keywords: ["scs", "social", "service", "customer", "interaction", "response"],
            image: require("../assets/scs/interaction-wh-response-time.png"),
            widgetDesc: async function () {
                return Object.assign({},
                    {
                        type: "FantasticChart",
                        xAxis: "published",
                        yAxis: "averageInteractionWhResponseTime",
                        width: 8,
                        height: 4,
                        filter: getBaseFilter(),
                        show: [
                            {
                                opacity: 100,
                                yAxis: "averageInteractionWhResponseTime",
                                geometry: "columns",
                                hideLabels: false,
                                labelPlacement: "inside",
                                "colour-index": 2,
                                "colour-palette": "be14-blue"
                            }
                        ]
                    }
                );
            },
            options: [
                {
                    key: "interactions-response-time-wh-vs-response-rate",
                    title: "Include response rate",
                    action: async function () {
                        return {
                            id: "scs/interaction-response-time-wh/interaction-response-rate",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "published",
                                width: 8,
                                height: 4,
                                caption: "Interaction response time (working hours) vs average interaction response rate",
                                xGridLines: true,
                                yGridLines: true,
                                filter: getBaseFilter(),
                                show: [
                                    {
                                        opacity: 100,
                                        yAxis: "averageInteractionWhResponseTime",
                                        geometry: "columns",
                                        hideLabels: false,
                                        labelPlacement: "inside",
                                        "colour-index": 2,
                                        "colour-palette": "be14-blue"
                                    },
                                    {
                                        yAxis: "averageInteractionHasResponse",
                                        geometry: "lines",
                                        lineWidth: 2,
                                        lineCurve: "smooth",
                                        labelPlacement: "inside",
                                        "colour-index": 0
                                    }
                                ],
                            },
                        };
                    }
                },
                {
                    key: "rpcs-table",
                    title: "As priority conversation table",
                    action: async function () {
                        return {
                            id: "scs/interaction-response-time-wh/rpcs-table",
                            widgetDesc: {
                                type: "ResponseTable",
                                caption: "Priority interaction response time & response rate",
                                width: 8,
                                filter: getBaseFilter(),
                                newInteractionResponseTimes: true,
                                useInteractionWorkingHours: true
                            }
                        };
                    }
                },
                {
                    key: "rpcs",
                    title: "By priority conversation",
                    action: async function () {
                        return {
                            id: "scs/interaction-response-time-wh/rpcs",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "published",
                                width: 8,
                                height: 4,
                                compare: "rpcs",
                                xGridLines: true,
                                yGridLines: true,
                                filter: getBaseFilter(),
                                show: [
                                    {
                                        opacity: 100,
                                        yAxis: "averageInteractionWhResponseTime",
                                        geometry: "lines",
                                        lineWidth: 2,
                                        lineCurve: "smooth",
                                        labelPlacement: "inside",
                                        "colour-index": 3
                                    }
                                ],
                                commentFontSize: 14,
                                commentWidth: 4,
                                comment: "# Average interaction response time (within working hours) by Priority Category\n\n" +
                                    "# {{risk}}\n\n## {{total interaction-wh-response-time \"Tag IS 1\"}}\n\n" +
                                    "# {{purchase}}\n\n## {{total interaction-wh-response-time \"Tag IS 2\"}}\n\n" +
                                    "# {{cancel}}\n\n## {{total interaction-wh-response-time \"Tag IS 3\"}}\n\n" +
                                    "# {{service}}\n\n## {{total interaction-wh-response-time \"Tag IS 4\"}}"
                            },
                        };
                    }
                },
                {
                    key: "social-network",
                    title: "By social network",
                    action: async function () {
                        return {
                            id: "scs/interaction-response-time-wh/social-network",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "socialNetwork",
                                width: 4,
                                height: 4,
                                stacked: true,
                                filter: getBaseFilter(),
                                show: [
                                    {
                                        opacity: 100,
                                        yAxis: "averageInteractionWhResponseTime",
                                        geometry: "rows",
                                        hideLabels: false,
                                        labelPlacement: "inside"
                                    }
                                ]
                            },
                        };
                    }
                }
            ]
        },
        {
            id: "scs/interaction-first-response-time",
            title: "First response time",
            description: "The average amount of time it takes for the brand to first respond to the consumer",
            keywords: ["scs", "social", "service", "customer", "interaction", "response"],
            image: require("../assets/scs/interaction-first-response-time.png"),
            widgetDesc: async function () {
                return Object.assign({},
                    {
                        type: "FantasticChart",
                        xAxis: "published",
                        yAxis: "averageInteractionFirstResponseTime",
                        width: 8,
                        height: 4,
                        filter: getBaseFilter(),
                        show: [
                            {
                                opacity: 100,
                                yAxis: "averageInteractionFirstResponseTime",
                                geometry: "columns",
                                hideLabels: false,
                                labelPlacement: "inside",
                                "colour-index": 1,
                                "colour-palette": "blues9"
                            }
                        ]
                    }
                );
            },
            options: [
                {
                    key: "interactions-first-response-time-vs-response-rate",
                    title: "Include response rate",
                    action: async function () {
                        return {
                            id: "scs/interaction-first-response-time-wh/interaction-response-rate",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "published",
                                width: 8,
                                height: 4,
                                caption: "First response time vs average interaction response rate",
                                xGridLines: true,
                                yGridLines: true,
                                filter: getBaseFilter(),
                                show: [
                                    {
                                        opacity: 100,
                                        yAxis: "averageInteractionFirstResponseTime",
                                        geometry: "columns",
                                        labelPlacement: "inside",
                                        "colour-index": 1,
                                        "colour-palette": "blues9"
                                    },
                                    {
                                        yAxis: "averageInteractionHasResponse",
                                        geometry: "lines",
                                        lineWidth: 2,
                                        lineCurve: "smooth",
                                        labelPlacement: "inside",
                                        "colour-index": 0
                                    }
                                ],
                            },
                        };
                    }
                },
                {
                    key: "rpcs",
                    title: "By priority conversation",
                    action: async function () {
                        return {
                            id: "scs/interaction-first-response-time/rpcs",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "published",
                                width: 8,
                                height: 4,
                                compare: "rpcs",
                                xGridLines: true,
                                yGridLines: true,
                                filter: getBaseFilter(),
                                show: [
                                    {
                                        opacity: 100,
                                        yAxis: "averageInteractionFirstResponseTime",
                                        geometry: "lines",
                                        lineWidth: 2,
                                        lineCurve: "smooth",
                                        labelPlacement: "inside",
                                        "colour-index": 1,
                                        "colour-palette": "blues9"
                                    }
                                ],
                                commentFontSize: 14,
                                commentWidth: 4,
                                comment: "# Average first response time by Priority Category\n\n" +
                                    "# {{risk}}\n\n## {{total interaction-response-time \"Tag IS 1\"}}\n\n" +
                                    "# {{purchase}}\n\n## {{total interaction-response-time \"Tag IS 2\"}}\n\n" +
                                    "# {{cancel}}\n\n## {{total interaction-response-time \"Tag IS 3\"}}\n\n" +
                                    "# {{service}}\n\n## {{total interaction-response-time \"Tag IS 4\"}}"
                            },
                        };
                    }
                },
                {
                    key: "social-network",
                    title: "By social network",
                    action: async function () {
                        return {
                            id: "scs/interaction-first-response-time/social-network",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "socialNetwork",
                                width: 4,
                                height: 4,
                                stacked: true,
                                filter: getBaseFilter(),
                                show: [
                                    {
                                        opacity: 100,
                                        yAxis: "averageInteractionFirstResponseTime",
                                        geometry: "rows",
                                        hideLabels: false,
                                        labelPlacement: "inside"
                                    }
                                ]
                            },
                        };
                    }
                }
            ]
        },
        {
            id: "scs/interaction-response-time",
            title: "Interaction response time",
            description: "The average amount of time it takes for the brand to respond to the consumer",
            keywords: ["scs", "social", "service", "customer", "interaction", "response"],
            image: require("../assets/scs/interaction-response-time.png"),
            widgetDesc: async function () {
                return Object.assign({},
                    {
                        type: "FantasticChart",
                        xAxis: "published",
                        yAxis: "averageInteractionResponseTime",
                        width: 8,
                        height: 4,
                        filter: getBaseFilter(),
                        show: [
                            {
                                opacity: 100,
                                yAxis: "averageInteractionResponseTime",
                                geometry: "columns",
                                hideLabels: false,
                                labelPlacement: "inside",
                                "colour-index": 2,
                                "colour-palette": "be14-blue"
                            }
                        ]
                    }
                );
            },
            options: [
                {
                    key: "interactions-response-time-vs-response-rate",
                    title: "Include response rate",
                    action: async function () {
                        return {
                            id: "scs/interaction-response-rate/interaction-response-time",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "published",
                                width: 8,
                                height: 4,
                                caption: "Interaction response time vs average interaction response rate",
                                xGridLines: true,
                                yGridLines: true,
                                filter: getBaseFilter(),
                                show: [
                                    {
                                        opacity: 100,
                                        yAxis: "averageInteractionResponseTime",
                                        geometry: "columns",
                                        labelPlacement: "inside",
                                        "colour-index": 2,
                                        "colour-palette": "be14-blue"
                                    },
                                    {
                                        yAxis: "averageInteractionHasResponse",
                                        geometry: "lines",
                                        lineWidth: 2,
                                        lineCurve: "smooth",
                                        labelPlacement: "inside",
                                        "colour-index": 0
                                    }
                                ],
                            },
                        };
                    }
                },
                {
                    key: "rpcs-table",
                    title: "As priority conversation table",
                    action: async function () {
                        return {
                            id: "scs/interaction-response-time/rpcs-table",
                            widgetDesc: {
                                type: "ResponseTable",
                                caption: "Priority interaction response time & response rate",
                                width: 8,
                                filter: getBaseFilter(),
                                newInteractionResponseTimes: true
                            }
                        };
                    }
                },
                {
                    key: "rpcs",
                    title: "By priority conversation",
                    action: async function () {
                        return {
                            id: "scs/interaction-response-time/rpcs",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "published",
                                width: 8,
                                height: 4,
                                compare: "rpcs",
                                xGridLines: true,
                                yGridLines: true,
                                filter: getBaseFilter(),
                                show: [
                                    {
                                        opacity: 100,
                                        yAxis: "averageInteractionResponseTime",
                                        geometry: "lines",
                                        lineWidth: 2,
                                        lineCurve: "smooth",
                                        labelPlacement: "inside",
                                        "colour-index": 3
                                    }
                                ],
                                commentFontSize: 14,
                                commentWidth: 4,
                                comment: "# Average interaction response time by Priority Category\n\n" +
                                    "# {{risk}}\n\n## {{total interaction-response-time \"Tag IS 1\"}}\n\n" +
                                    "# {{purchase}}\n\n## {{total interaction-response-time \"Tag IS 2\"}}\n\n" +
                                    "# {{cancel}}\n\n## {{total interaction-response-time \"Tag IS 3\"}}\n\n" +
                                    "# {{service}}\n\n## {{total interaction-response-time \"Tag IS 4\"}}"
                            },
                        };
                    }
                },
                {
                    key: "social-network",
                    title: "By social network",
                    action: async function () {
                        return {
                            id: "scs/interaction-response-time/social-network",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "socialNetwork",
                                width: 4,
                                height: 4,
                                stacked: true,
                                filter: getBaseFilter(),
                                show: [
                                    {
                                        opacity: 100,
                                        yAxis: "averageInteractionResponseTime",
                                        geometry: "rows",
                                        hideLabels: false,
                                        labelPlacement: "inside"
                                    }
                                ]
                            },
                        };
                    }
                }
            ]
        },
        {
            id: "scs/interaction-response-time-wh-breakdown",
            title: "Interaction response time (within working hours) break down",
            description: "Splits your interaction response time (within working hours) to allow for more detailed analysis",
            keywords: ["scs", "social", "service", "customer", "interaction", "response"],
            image: require("../assets/scs/comp-table-interaction-resp-time-wh-split.png"),
            widgetDesc: async function () {
                return Object.assign({},
                    {
                        type: "CompTable",
                        caption: "Comparison Table: interaction response time (within working hours) break down",
                        width: 4,
                        height: 3,
                        rowColColours: {
                            "1-8": {
                                "colour-palette": "be14-blue",
                                "colour-index": 5
                            }
                        },
                        filter: getBaseFilter(),
                        rows: [
                            {
                                id: 1,
                                groupBy: "interactionResponseTimeWhBreakdown",
                                slaResponseTime: 2
                            }
                        ],
                        cols: [
                            {
                                id: 1,
                                type: "interactionHasResponseCount",
                                showPercentage: true,
                                showValue: true,
                                showBar: true
                            }
                        ]
                    });
            },
            options: [
                {
                    key: "interactions-first-response-time-wh-breakdown",
                    title: "As first response time",
                    action: async function () {
                        return {
                            id: "scs/interaction-response-time-wh-breakdown/first-response-time",
                            widgetDesc: {
                                type: "CompTable",
                                caption: "Comparison Table: first response time (within working hours) break down",
                                width: 4,
                                height: 3,
                                rowColColours: {
                                    "1-8": {
                                        "colour-palette": "be14-blue",
                                        "colour-index": 5
                                    }
                                },
                                filter: getBaseFilter(),
                                rows: [
                                    {
                                        id: 1,
                                        groupBy: "firstResponseTimeWhBreakdown",
                                        slaResponseTime: 2
                                    }
                                ],
                                cols: [
                                    {
                                        id: 1,
                                        type: "interactionHasResponseCount",
                                        showPercentage: true,
                                        showValue: true,
                                        showBar: true
                                    }
                                ]
                            },
                        };
                    }
                }
            ]
        },
        {
            id: "scs/interaction-response-time-breakdown",
            title: "Interaction response time break down",
            description: "Splits your interaction response time to allow for more detailed analysis",
            keywords: ["scs", "social", "service", "customer", "interaction", "response"],
            image: require("../assets/scs/comp-table-interaction-resp-time-split.png"),
            widgetDesc: async function () {
                return Object.assign({},
                    {
                        type: "CompTable",
                        caption: "Comparison Table: interaction response time break down",
                        width: 4,
                        height: 3,
                        filter: getBaseFilter(),
                        rowColColours: {
                            "1-8": {
                                "colour-palette": "be14-blue",
                                "colour-index": 5
                            }
                        },
                        rows: [
                            {
                                id: 1,
                                groupBy: "interactionResponseTimeBreakdown",
                                slaResponseTime: 2
                            }
                        ],
                        cols: [
                            {
                                id: 1,
                                type: "interactionHasResponseCount",
                                showPercentage: true,
                                showValue: true,
                                showBar: true
                            }
                        ]
                    });
            },
            options: [
                {
                    key: "interactions-first-response-time-breakdown",
                    title: "As first response time",
                    action: async function () {
                        return {
                            id: "scs/interaction-response-time-breakdown/first-response-time",
                            widgetDesc: {
                                type: "CompTable",
                                caption: "Comparison Table: first response time break down",
                                width: 4,
                                height: 3,
                                rowColColours: {
                                    "1-8": {
                                        "colour-palette": "be14-blue",
                                        "colour-index": 5
                                    }
                                },
                                filter: getBaseFilter(),
                                rows: [
                                    {
                                        id: 1,
                                        groupBy: "firstResponseTimeBreakdown",
                                        slaResponseTime: 2
                                    }
                                ],
                                cols: [
                                    {
                                        id: 1,
                                        type: "interactionHasResponseCount",
                                        showPercentage: true,
                                        showValue: true,
                                        showBar: true
                                    }
                                ]
                            },
                        };
                    }
                }
            ]
        }
    ]
};

export default socialCustomerService;

let initPromise = null;
let highVsLowPriorityFilter = null;


export async function init() {
    if (initPromise) return initPromise;
    return initPromise = initImpl();
}

function getBaseFilter() {
    return "(socialNetwork IS TWITTER OR socialNetwork IS FACEBOOK OR socialNetwork IS INSTAGRAM OR socialNetwork IS HELLOPETER OR socialNetwork IS GOOGLEBUSINESS OR socialNetwork IS LINKEDIN) AND Media IS CONSUMER AND (Tag IS 1 OR Tag IS 2 OR Tag IS 3 OR Tag IS 4)";
}

async function getHighVsLowPriorityFilters() {
    if (highVsLowPriorityFilter) return highVsLowPriorityFilter;

    const metatags = getMetatagsInAccount();
    const cx = await getAllCxSegmentLists();
    const conduct = await getAllRiskProductSegmentLists();

    const nonCxOrRisk = (cx || [])
        .concat(conduct || [])
        .flatMap(tag => tag.children ?? [])
        .map(id => VuexStore.getters.idToTag.get(id))
        .filter(tag => tag && tag.flag === "NONE_OF_THE_ABOVE");
    const nonCxOrRiskFilter = nonCxOrRisk.map(tag => `segment is ${tag.id}`).join(' or ');

    let highPriorityFilter = `${metatags.map(m => `tag is ${m.id}`).join(' or ')}`;
    highPriorityFilter = appendSegmentRestrictions(highPriorityFilter);
    let lowPriorityFilter = `(${metatags.map(m => `tag isnt ${m.id}`).join(' and ')}) and (${nonCxOrRiskFilter})`;
    lowPriorityFilter = appendSegmentRestrictions(lowPriorityFilter);

    highVsLowPriorityFilter = {
        highPriority: appendFiltersReadably(highPriorityFilter, "(socialNetwork IS TWITTER OR socialNetwork IS FACEBOOK OR socialNetwork IS INSTAGRAM OR socialNetwork IS HELLOPETER OR socialNetwork IS GOOGLEBUSINESS OR socialNetwork IS LINKEDIN)"),
        lowPriority: appendFiltersReadably(lowPriorityFilter, "(socialNetwork IS TWITTER OR socialNetwork IS FACEBOOK OR socialNetwork IS INSTAGRAM OR socialNetwork IS HELLOPETER OR socialNetwork IS GOOGLEBUSINESS OR socialNetwork IS LINKEDIN)")
    }

    return highVsLowPriorityFilter;
}

export async function initImpl() {
    const curAccount = account();
    if (!curAccount?.code) return;

    await VuexStore.dispatch('profiles/refreshProfiles');
    const profiles = VuexStore.state.profiles.profiles;

    // only show scs metrics if the account has at least one enterprise profile
    let hasEnterpriseProfile = false;

    for (const profile of profiles) {
        if (profile.media === "ENTERPRISE") {
            hasEnterpriseProfile = true;
            break;
        }
    }

    let showMetrics = hasEnterpriseProfile && curAccount?.interactionLabellingEnabled;

    if (showMetrics) {
        socialCustomerService.hide = false;
    }
}