<template>
    <div class="profile-row" :class="{'expanded': expanded, 'deleted': profile.deleted}" @click="expand()">

        <div class="profile-row__data profile-row__center-data space-between profile-row__hide-expanded">
            <online-profile :profile="profile"></online-profile>
            <popup-menu v-if="warnings" :arrow-pointer="{show: true, left: true}" :pos-offset="{top: 5, left: 0}">
                <template #activator>
                    <slotted-tag no-close :tooltip="`This profile has ${warnings.length} ${formatPlural(warnings.length, 'warning')}.`" style="cursor: pointer">
                        <i class="symbol-warning"></i> Has warnings
                    </slotted-tag>
                </template>
                <div class="profile-row__warning-list dark-scrollbars dark-scrollbars--visible short-animated fadeIn">
                    <div class="warning-item" v-for="warning in warnings" :key="warning.id">
                        {{ warning.message }}
                    </div>
                </div>
            </popup-menu>
            <slotted-tag v-if="linkedInProfileUsingNewApp" no-close>New app</slotted-tag>
        </div>
        <div class="profile-row__data profile-row__justify-data profile-row__hide-expanded">
            <span class="profile-row__muted-text" v-if="profile.type === profileTypes.youtube || profile.type === profileTypes.whatsapp">N/A</span>
            <div v-else-if="profile.authorized" style="display: flex; flex-direction: column; row-gap: 5px">
                <span v-if="!profile.authorizedBy || profile.authorizedBy.system">
                     Authorised
                </span>
                <span v-else>
                    Authorised by <span v-if="profile.userHandleId">influencer</span> <deq-user v-else :user="profile.authorizedBy"/>
                </span>
                <span style="text-align: center" v-if="profile.directMessagesEnabled">
                    DMs enabled
                </span>
            </div>
            <span v-else class="profile-row__muted-text">
                Not authorised
            </span>
        </div>
        <div class="profile-row__data profile-row__justify-data profile-row__hide-expanded">
            <div v-if="profile.authorized && tokenExpire">
                <span v-if="!isProfileExpired(profile)">{{ formatDate(tokenExpire, 'DD MMMM YYYY, HH:mm') }}</span>
                <span v-else class="profile-row__warning-text"><i class="symbol-warning"></i> Expired on {{ formatDate(tokenExpire, 'DD MMMM YYYY, HH:mm') }}</span>
            </div>
            <span class="profile-row__muted-text" v-else>
                N/A
            </span>
        </div>
        <span class="profile-row__data profile-row__justify-data profile-row__hide-expanded">
             <span v-if="profile.media">
                {{ profileCategory }}
            </span>
            <span v-else class="deq-text--muted">
                <i class="symbol-warning symbol-warning--muted"/>
                No category
            </span>
        </span>
        <span class="profile-row__data profile-row__justify-data profile-row__hide-expanded" v-if="relevantVisible">
           {{ profile.relevant ? 'Yes' : 'No' }}
        </span>
        <span class="profile-row__data profile-row__justify-data profile-row__hide-expanded" v-if="statsVisible">
            <span v-if="getProfileStats">
                <i :class="{'icon-users': getProfileStats.type === 'followers',
                'icon-thumbs-up': getProfileStats.type === 'likes', 'symbol-youtube': getProfileStats.type === 'subscribers'}"></i>
                {{ getProfileStats.amount }} {{ getProfileStats.type }}
            </span>
            <span v-else class="profile-row__muted-text">
                No statistics
            </span>
        </span>
        <span class="profile-row__data profile-row__justify-data profile-row__hide-expanded">
            <span v-if="profile.type === profileTypes.linkedin && !profile.authorized" class="profile-row__warning-text">
                <i class="symbol-warning"></i> LinkedIn profiles must be authorised in order to collect data
            </span>
            <span v-else-if="profile.brands">
                {{ profile.brands.length }} {{ profile.brands.length > 1 ? `brands are` : 'brand is' }} phrase matched to this profile
            </span>
            <span v-else style="padding-left: 5px;" class="profile-row__muted-text">
                0 brands are phrase matched to this profile
            </span>
        </span>
        <span class="profile-row__data profile-row__hide-expanded" v-if="tagsVisible">
              <slotted-tag style="margin-right: 10px; margin-bottom: 5px"
                           v-for="profileTag in profileTags"
                           no-close
                           :key="profileTag.id">
                            {{ profileTag.name }}
                </slotted-tag>
        </span>

        <div v-if="expanded" class="profile-row__expanded-container">
            <div id="header" class="profile-row__expanded-header" :class="{'deleted' : profile.deleted}">
                <div class="profile-row__expanded-profile-info profile-row__center-data">
                    <online-profile  :profile="profile"></online-profile>
                    <popup-menu v-if="warnings" fixed :arrow-pointer="{show: true, left: true}" :pos-offset="{top: 5, left: 0}">
                        <template #activator>
                            <slotted-tag no-close :tooltip="`This profile has ${warnings.length} ${formatPlural(warnings.length, 'warning')}.`" style="cursor: pointer">
                                <i class="symbol-warning"></i> Has warnings
                            </slotted-tag>
                        </template>
                        <div class="profile-row__warning-list dark-scrollbars dark-scrollbars--visible short-animated fadeIn">
                            <div class="warning-item" v-for="warning in warnings" :key="warning.id">
                                {{ warning.message }}
                            </div>
                        </div>
                    </popup-menu>
                </div>
                <div class="profile-row__expanded-profile-header-info">
                    <i :class="getProfileIcon(profile.type)"
                       :style="{color: getProfileColor(profile.type)}"></i>{{ prettyProfileType(profile.type) }}
                </div>
                <span class="profile-row__expanded-profile-header-info" v-if="profile.deleted">
                    <i class="symbol-warning"></i>
                    Uneditable - this profile has been deleted
                </span>
                <span class="profile-row__expanded-profile-header-info" v-if="canLinkProfile">
                    <a @click.stop="openProfileLink" tooltip="View this profile on it's platform"><i class="icon-forward-2"></i>View profile</a>
                </span>

                <div v-if="saving" class="profile-row__saving-message">
                    <spinner-component :size="16"/>
                    <span style="padding-left: 5px">Saving profile…</span>
                </div>

                <be-button link
                           style="margin-left: auto"
                           tooltip="Click to close (esc)"
                           @click.stop="expand(false)">
                    <i class="symbol-close"></i>
                </be-button>
            </div>

            <div class="profile-row__expanded-edit-container" @click.stop>
                <div class="profile-row__expanded-edit-left">
                    <h4>Handle ID</h4>
                    <div v-if="canEditHandleId">
                        <p v-if="!editingHandleId" class="profile-row__editable-text" @click="openHandleIdEdit">
                            {{ tempProfile.handleId }}<i class="symbol-edit" style="visibility: hidden"></i>
                        </p>
                        <inline-text-input style="width: 400px" v-else v-model="tempHandleId" @ok="updateHandleId" @cancel="editingHandleId = false" :disabled="saving"/>
                    </div>
                    <p v-else>
                        {{ tempProfile.handleId }}
                    </p>

                    <h4>Handle</h4>
                    <div v-if="canEditHandle">
                        <p v-if="!editingHandle" class="profile-row__editable-text" @click="openHandleEdit">
                            {{ tempProfile.handle }}<i class="symbol-edit" style="visibility: hidden"></i>
                        </p>
                        <inline-text-input style="width: 400px" v-else v-model="tempHandle" @ok="updateHandle" @cancel="editingHandle = false" :disabled="saving"/>
                    </div>
                    <p v-else>
                        {{ tempProfile.handle }}
                    </p>

                    <h4>Category</h4>
                    <popup-menu fixed
                                :ignoreActivatorClick="saving || profile.deleted || !canEditProfiles"
                                :arrow-pointer="{show: true, left: true} ">
                        <template #activator>
                            <p class="profile-row__editable-text row-container" :class="{'disabled': saving || profile.deleted, 'read-only': !canEditProfiles}">
                                {{ profileCategory ? profileCategory : 'Select a category' }}<i class="symbol-edit"
                                                                                                style="visibility: hidden"></i>
                                <training-marker v-if="warning && warning.id === warningIds.NO_CATEGORY" class="marker"></training-marker>
                            </p>
                        </template>
                        <div
                            class="profile-row__category-list">
                            Set category to
                            <ul>
                                <li v-for="category in categories" :key="category.value">
                                    <label class="checkbox" @click.prevent="updateCategory(category)">
                                        <input type="checkbox" :checked="tempProfile.media === category.value">
                                        {{ category.description }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </popup-menu>

                    <h4>Description</h4>
                    <textarea style="grid-row: 5; grid-column: 1/3; width: 95%; height: 80px; resize: none; border: var(--container-border)"
                              @blur="updateDescription"
                              :disabled="saving || profile.deleted"
                              :class="{'read-only': !canEditProfiles}"
                              class="dark-scrollbars dark-scrollbars--visible" v-model="tempProfile.description"/>


                    <label class="checkbox" style="grid-column: 1/3"
                           :class="{'disabled': saving || profile.deleted, 'read-only': !canEditProfiles}"
                           @click.prevent="updateRelevant"
                           title="Tick to skip sorting and mark mentions as relevant">
                        <input type="checkbox" v-model="tempProfile.relevant"> Always keep mentions from this profile
                        even
                        if they appear irrelevant</label>

                        <label v-if="shouldShowCollectMetricsCheckBox"
                               class="checkbox" style="grid-column: 1/3"
                               :class="{'disabled': saving}"
                               @click.prevent="updateCollectMetrics"
                               title="Tick to collect metrics for head posts for this profile">
                            <input type="checkbox" v-model="tempProfile.collectMetricsForHeadposts"> Collect metrics for head posts for this profile
                        </label>

                    <tooltip-component v-if="showDmOptions">
                        <label class="checkbox"
                               :class="{'disabled': saving || profile.deleted, 'read-only': !canEditProfiles, 'disabled-dms-editing': !canEditDms}"
                               style="grid-column: 1/3"
                               @click.prevent="updateDms">
                            <input type="checkbox" :disabled="!canEditDms" v-model="tempProfile.directMessagesEnabled">
                            Direct messages visible in DataEQ
                            <div class="help-block">
                                If enabled DMs will be shown to all users of your DataEQ account. DMs sent to the Crowd
                                have numbers and email addresses anonymised
                            </div>
                        </label>
                        <template #tooltip>
                            <div v-if="canEditDms">
                                {{ profile.directMessagesEnabled ? 'Click to prevent direct messages from being collected for this profile' : 'Click to make direct messages sent to this profile visible inside DataEQ'}}
                            </div>
                            <div v-else>
                                Only the user who has authorised this profile can enable/disable direct messages
                            </div>
                        </template>
                    </tooltip-component>


                    <label class="checkbox"
                           v-if="showFbSharedAssetOptions"
                           :class="{'disabled': saving || profile.deleted, 'read-only': !canEditProfiles}"
                           style="grid-column: 1/3"
                           @click.prevent="updateFbSharedAsset"
                           title="Tick to use a system user token to poll data from this profile">
                        <input type="checkbox" v-model="tempProfile.facebookSharedAsset">
                        Facebook Shared Asset
                        <div class="help-block">
                            Only check this item when the page has been shared with DataEQ via Facebook business manager and should be polled using a system user's page token.
                        </div>
                    </label>

                    <div v-if="profile.type === profileTypes.helloPeter"  style="grid-column: 1/3;">
                        <h4>API Key</h4>
                        <inline-text-input style="margin-top: 20px"
                                           v-model="apiKey"
                                           :disabled="saving"
                                           @ok="updateApiKey"
                                           :placeholder="profile.authorized ? 'Edit API Key...' : 'Add API Key...'"
                                           hide-controls>
                        </inline-text-input>
                    </div>
                </div>
                <div class="profile-row__expanded-edit-right">
                    <h4>Tags <i class="icon-info-circle" tooltip="These tags will be applied to every mention collected from this profile"></i></h4>
                    <div v-if="profileTags.length"
                         class="profile-row__expanded-tags-container dark-scrollbars dark-scrollbars--visible">
                        <slotted-tag style="margin-right: 10px; margin-bottom: 5px"
                                     v-for="profileTag in profileTags"
                                     :disabled="saving || profile.deleted"
                                     :no-close="!canEditProfiles"
                                     @close="removeTag(profileTag)"
                                     :class="isAddedTag(profileTag) ? 'profile-row__added-tag' : ''"
                                     :key="profileTag.id">
                            {{ profileTag.name }}
                        </slotted-tag>
                    </div>

                    <div v-if="canEditProfiles">
                        <p v-if="!editTags" @click="toggleEditTags" class="profile-row__editable-text"
                           :class="{'disabled': saving || profile.deleted}">Add a tag<i
                            class="symbol-edit"></i></p>
                        <popup-menu v-else fixed :value="!!addTagName && !!filteredTagSuggestions.length && !saving"
                                    ignore-activator-click :arrow-pointer="{show: true, left: true} ">
                            <template #activator>
                                <inline-text-input
                                    ref="profileTagInput"
                                    placeholder="Enter tag name..."
                                    ignore-blur
                                    signal-for-all
                                    :tooltip="newTagInvalid"
                                    :disable-done="!!newTagInvalid"
                                    v-model="addTagName"
                                    :disabled="saving || profile.deleted"
                                    @cancel="resetTag"
                                    @ok="addNewTag">
                                </inline-text-input>
                            </template>
                            <div v-if="!!addTagName && !!filteredTagSuggestions.length"
                                 class="profile-row__tag-suggestions dark-scrollbars dark-scrollbars--visible">
                                <ul>
                                    <li v-for="suggestedTag in filteredTagSuggestions" :key="suggestedTag.id">
                                        <a @click="addSuggestedTag(suggestedTag)">{{ suggestedTag.name }}</a>
                                    </li>
                                </ul>
                            </div><br/>
                        </popup-menu>
                    </div>


                    <!-- Tiktok ads profile selection -->
                    <div v-if="profile.type === profileTypes.tikTokAds">
                    <h4 style="margin-bottom:10px">Linked TikTok profile</h4>
                    <popup-menu fixed
                                :ignoreActivatorClick="saving || profile.deleted || !canEditProfiles"
                                :arrow-pointer="{show: true, left: true} ">
                        <template #activator>
                            <p  class="profile-row__editable-text row-container" :class="{'disabled': saving || profile.deleted, 'read-only': !canEditProfiles}">
                                <span>{{ getLinkedTikTokProfile() ? getLinkedTikTokProfile() : 'Select TikTok Profile' }}<i class="symbol-edit"></i></span>
                            </p>
                        </template>
                    
                        <div
                            class="profile-row__category-list">
                            Set linked TikTok profile
                            <ul>
                                <li v-for="ttProfile in getTikTokProfiles()" :key="ttProfile.id">
                                    <label class="checkbox" @click.prevent="updateLinkedPresence(ttProfile)">
                                        <input type="checkbox" :checked="tempProfile.linkedPresenceId === ttProfile.id">
                                        {{ ttProfile.name }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </popup-menu>
                    </div>

                    <div v-if="profile.type !== profileTypes.whatsapp">
                        <h4>Brands with phrases matching profile handle <i class="icon-info-circle" tooltip="Mentions from this profile will be collected for these brands"></i></h4>
                        <div v-if="!profile.brands">
                            <p class="profile-row__warning-text" style="margin-bottom: 10px">
                                No brands have phrases that match this profile.
                            </p>
                            <span v-if="canEditProfiles">
                                 Click <a :class="saving || profile.deleted ? 'disabled' : ''" style="cursor: pointer" @click="openBrandPicker">here</a> to select brands to phrase match this profile to
                            </span>
                        </div>
                        <div v-else>
                            <div class="profile-row__expanded-brands-list dark-scrollbars dark-scrollbars--visible">
                                <ul>
                                    <li v-for="(brand, index) in brandDisplayList" :key="index"
                                        :style="{paddingLeft: brand.indent + 'px'}">
                                    <span v-show="isBrandVisible(brand)" @click="toggleBrandCollapse(brand)"
                                          :class="brand.hasChildren ? 'expandable-brand' : ''">
                                       <i :style="{visibility: brand.hasChildren  ? 'visible' : 'hidden'}"
                                          :class="brand.childrenVisible ? 'icon-down-dir' : 'icon-right-dir'"></i>
                                        <span> {{formatBrandName(brand)}}</span>
                                    </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="profile-row__expanded-footer">
                <div v-if="warning" class="row-container">
                    <training-marker v-if="warning" class="marker"></training-marker>
                    <span>{{ warning.message }}</span>
                </div>

                <div class="btns">
                    <div class="row-container">
                        <slotted-tag :disabled="saving || profile.deleted" :no-close="!canEditProfiles" close-tooltip="Click to unauthorise" @close="showUnauthDialog = true" v-if="profile.authorized">Authorised</slotted-tag>
                        <be-button :disabled="saving || profile.deleted || authInProgress" v-if="canAuthoriseProfile" @click.stop="authoriseProfile"><spinner-component :size="11" v-if="authInProgress"></spinner-component> {{ authBtnText }}</be-button>
                        <be-button v-if="canEditProfiles" :danger="!profile.deleted" :primary="profile.deleted" :disabled="saving" @click.stop="showDeleteDialog = true">{{ profile.deleted ? 'Undelete' : 'Delete' }}</be-button>
                    </div>
                </div>
            </div>
        </div>

        <dialog-box modal v-if="showDeleteDialog" :title="profile.deleted ? 'Undelete profile' : 'Delete profile'" width="400px" @close="showDeleteDialog = false">
            <div class="ask-dialog">
                The following profile/s will be {{ profile.deleted ? 'undeleted' : 'deleted' }}:
                <div class="ask-dialog__profiles">
                    <online-profile :profile="profile"></online-profile>
                    <template v-if="linkedProfile">
                        <div class="ask-dialog__linked-profile-text">and linked profile</div>
                        <online-profile :profile="linkedProfile"></online-profile>
                    </template>
                </div>
            </div>

            <template #buttons>
                <div class="ask-dialog__btns">
                    <be-button link @click="showDeleteDialog = false">Cancel</be-button>
                    <be-button primary :disabled="saving" @click="deleteProfile">
                        <spinner-component v-if="saving" :size="11"/>{{ profile.deleted ? (saving ? 'Restoring...' : 'Undelete') : (saving ? 'Deleting...' : 'Delete')}}
                    </be-button>
                </div>
            </template>
        </dialog-box>

        <dialog-box modal v-if="showUnauthDialog" title="Unauthorise Profile" width="600px" @close="showUnauthDialog = false">
            <div class="ask-dialog">
                The following profile/s will be unauthorised:
                <div class="ask-dialog__profiles">
                    <online-profile :profile="profile"></online-profile>
                    <template v-if="linkedProfile">
                        <div class="ask-dialog__linked-profile-text">and linked profile</div>
                        <online-profile :profile="linkedProfile"></online-profile>
                    </template>
                </div>

                <label class="ask-dialog__invalidate-container checkbox" v-if="profile.type === profileTypes.twitter && (user.originalAdmin || user.id === profile.authorizedById)">
                    <input type="checkbox" v-model="invalidateToken"> Invalidate this token? This will completely revoke DataEQ's access from this profile.
                </label>

                <textarea v-if="user.originalAdmin" v-model="unauthReason" :disabled="saving" class="dark-scrollbars dark-scrollbars--visible" placeholder="Reason for unauthorising profile"></textarea>
            </div>

            <template #buttons>
                <div class="ask-dialog__btns">
                    <be-button link @click="showUnauthDialog = false">Cancel</be-button>
                    <be-button primary :disabled="saving || (user.originalAdmin ? !unauthReason : false)" @click="unauthoriseProfile">
                        <spinner-component v-if="saving" :size="11"/>{{ saving ? 'Unauthorising' : 'Unauthorise' }}
                    </be-button>
                </div>
            </template>
        </dialog-box>
    </div>
</template>

<script>
import OnlineProfile from "@/app/framework/dialogs/user-settings/views/platform-auth/OnlineProfile";
import InlineTextInput from "@/components/inputs/InlineTextInput";
import BeButton from "@/components/buttons/BeButton";
import SlottedTag from "@/components/tags/SlottedTag";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {beef, mash} from "@/store/Services";
import PopupMenu from "@/components/PopupMenu";
import {openWindowCenter} from "@/app/framework/dialogs/user-settings/views/platform-auth/PlatformAuthUtils";
import {showAskDialog, showErrorDialog} from "@/app/framework/dialogs/Dialog";
import {
    getProfileAppId,
    getProfileColor,
    getProfileIcon,
    prettyProfileType,
    profileCategories,
    profileTypes
} from "./ProfileUtils";
import {formatBrandName, formatDate, formatPlural} from "@/app/utils/Format";
import {isProfileExpired} from "@/app/popup/token-expire/TokenUtilities";
import Vue from "vue";
import {
    notifyUser,
    notifyUserOfError,
    notifyWithHtml,
    notifyWithText,
    showBusyNotification
} from "@/app/framework/notifications/Notifications";
import SpinnerComponent from "@/components/SpinnerComponent";
import BrandPickerDialog from "@/app/framework/dialogs/brand-picker/BrandPickerDialog";
import TrainingMarker from "@/components/inputs/TrainingMarker";
import {showDialogComponent as showDialog} from "@/app/framework/dialogs/DialogUtilities";
import {editOnlineProfiles, isMashAdmin} from "@/app/Permissions";
import DialogBox from "@/components/DialogBox";
import {Profile} from "@/app/utils/types";
import DeqUser from "@/components/formatters/DeqUser";
import {isOps} from "@/app/Permissions";
import TooltipComponent from "@/components/tooltip/TooltipComponent";
import {phraseNeedsQuotes} from "@/app/utils/Phrases";
import {encloseInDisplayQuotes} from "@/app/utils/StringUtils";
import {getUser} from "@/app/utils/User";
import {features} from "@/app/Features";

export default {
    name: "SetupProfileRow",
    store: VuexStore,
    components: {
        TooltipComponent,
        DeqUser,
        SpinnerComponent,
        DialogBox, TrainingMarker, PopupMenu, SlottedTag, BeButton, InlineTextInput, OnlineProfile},

    props: {
        profile: {
            type: Profile,
            required: true
        },
        warnings: {
            type: Array
        },
        relevantVisible: {
            type: Boolean,
            default: false
        },
        tagsVisible: {
            type: Boolean,
            default: false
        },
        statsVisible: {
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            profileTypes: profileTypes,
            linkedProfile: null,
            expanded: false,
            saving: false,
            canEditProfiles: editOnlineProfiles(),
            editTags: false,
            editingHandle: false,
            editingHandleId: false,
            tempHandle: "",
            tempHandleId: "",
            categories: profileCategories,
            addTagName: "",
            invalidTagTooltip: "",
            tempProfile: JSON.parse(JSON.stringify(this.profile)), // editable profile
            tikTokProfiles: [], // for selection of a linked tiktok profile
            curProfileTagIds: this.profile.tagIds ? JSON.parse(JSON.stringify(this.profile?.tagIds)) : [], // copy of profile's current tags -> used for tracking new tags
            brandDisplayList: [],
            warningIds: {
                NO_CATEGORY: "NO_CATEGORY",
                LINKEDIN_AUTH: "LINKEDIN_AUTH"
            },
            authInProgress: false,
            showUnauthDialog: false,
            showDeleteDialog: false,
            unauthReason: "",
            invalidateToken: false,
            apiKey: ""
        }
    },

    created: function () {
        if (this.profile.brands) {
            this.buildBrandDisplayList();
        }

        if (this.profile.linkedPresenceId) {
            this.linkedProfile = this.profiles.find(p => p.id === this.profile.linkedPresenceId);
        }
    },

    computed: {
        ...mapState(['tags', 'account', 'user']),
        ...mapState('profiles', ['profiles']),
        ...mapGetters('profiles', ['handleIdToProfileStats']),
        ...mapState('userPlatformAuth', [
            'managedTwitterProfiles', 'managedLinkedinProfiles'
        ]),

        tokenExpire() {
            return this.profile.refreshTokenExpire || this.profile.tokenExpire
        },

        warning() {
            if (this.profile.deleted) return null; // don't show warnings if profile is deleted
            if (!this.profileCategory) return { id: this.warningIds.NO_CATEGORY, message: "Set a category for this profile." }
            if (this.profile.type === profileTypes.linkedin && !this.profile.authorized) return { id: this.warningIds.LINKEDIN_AUTH, message: "LinkedIn profiles must be authorised in order to collect data." }

            return null;
        },

        authBtnText() {
            let text = this.profile.authorized ? 'Reauthorise' : 'Authorise';
            return this.authInProgress ? 'Authorising profile...' : text;
        },

        canLinkProfile() {
            return this.profile.link && this.profile.type  !== this.profileTypes.whatsapp;
        },

        showDmOptions() {
            return this.profile.authorized && (this.profile.type === this.profileTypes.facebook || this.profile.type === this.profileTypes.twitter || this.profile.type === this.profileTypes.helloPeter);
        },

        // Only the user who has authorised the profile can enable/disable DMs
        canEditDms() {
            return this.profile.authorizedById === this.user.id;
        },

        showFbSharedAssetOptions() {
            let correctType = this.profile.type === profileTypes.facebook || this.profile.type === profileTypes.instagram_business;
            let isSupportOrNonSupport = this.profile.supportProfileForOwnBrandIds?.length || this.profile.nonSupportProfileForOwnBrandIds?.length;

            return isOps() && correctType && isSupportOrNonSupport;
        },

        filteredTagSuggestions() {
            let text = this.addTagName.toLowerCase();

            return this.tags?.filter(tagSuggestion => tagSuggestion.name?.toLowerCase().includes(text) && !this.profile.tagIds?.includes(tagSuggestion.id) && tagSuggestion.namespace === 'tag');
        },

        profileTags() {
            if (this.profile.tagIds) {
                if (this.tags.length) return this.tags.filter(tag => !!this.profile.tagIds.find(profileTagId => profileTagId === tag.id));
            }

            return [];
        },

        newTagInvalid() {
            if (this.addTagName.length > 50) return "The tag " + encloseInDisplayQuotes(this.addTagName) + " is too long";
            // We don't actually disallow the following, but it's almost certainly bad style
            if (/^[!"#$%&'()*+,\-./:;<=>?@ [\\\]^_`{|}~]+$/.test(this.addTagName)) return "The tag " + encloseInDisplayQuotes(this.addTagName) + " contains only punctuation";
            if (/^\s*$/.test(this.addTagName)) return "Tag name cannot be empty";

            return null
        },

        profileCategory() {
            let cat = this.categories.find(category => this.tempProfile.media === category.value);

            if (cat) return cat.label;

            return '';
        },

        canAuthoriseProfile() {
            return this.canEditProfiles && (this.profile.type === this.profileTypes.twitter
                || this.profile.type === this.profileTypes.tikTok);
        },

        getProfileStats() {
            let statsValue = null;
            let stats = this.handleIdToProfileStats(this.profile.handleId);

            if (!stats) return null;

            // types of stats are mututally exclusive
            if (stats.followerCount !== undefined) {
                statsValue = {
                    amount: stats.followerCount,
                    type: "followers"
                }
            }
            if (stats.likes !== undefined) {
                statsValue = {
                    amount: stats.likes,
                    type: "likes"
                }
            }
            if (stats.subscribers !== undefined) {
                statsValue = {
                    amount: stats.subscribers,
                    type: "subscribers"
                }
            }

            return statsValue;
        },

        canEditHandle() {
            return isOps() && this.profile.type === profileTypes.helloPeter;
        },

        canEditHandleId() {
            return isOps() && !this.profile.authorized && this.profile.type === profileTypes.helloPeter;
        },

        // this can be removed once all users are using the new app
        linkedInProfileUsingNewApp() {
            return this.user.originalAdmin && this.profile.type === this.profileTypes.linkedin && this.profile.authorized && this.profile.tokenAppId === 52;
        },

        shouldShowCollectMetricsCheckBox() {
            const validProfileTypes = [profileTypes.twitter, profileTypes.facebook, profileTypes.instagram_business, profileTypes.linkedin, profileTypes.linkedin_user, profileTypes.tikTok];

            return features.enableProfilesForEngagementMetrics() && this.profile.authorized && this.profile.media === "ENTERPRISE" && (validProfileTypes.includes(this.profile.type));
        }
    },

    methods: {
        formatBrandName,
        ...mapActions('profiles', {
            storeRefreshProfiles: 'refreshProfiles',
            storeRefreshProfile: 'refreshProfile',
            storeUpdateProfile: 'updateProfile',
            storeUndeleteProfile: 'undeleteProfile',
            storeDeleteProfile: 'deleteProfile',
            storeGetProfile: 'getProfile',
            storeUnauthProfile: 'unauthoriseProfile'
        }),
        ...mapMutations('profiles', {
            storeAddProfile: 'addProfile'
        }),
        ...mapActions(['createTag', 'refreshBrands']),
        ...mapActions('userPlatformAuth', [
            'initializeManagedTwitterProfiles', 'initializeManagedLinkedinProfiles'
        ]),

        getProfileIcon,
        getProfileColor,
        prettyProfileType,
        formatDate,
        formatPlural,
        isProfileExpired,

        openHandleEdit() {
            this.editingHandle = true;
            this.tempHandle = this.profile.handle;
        },

        openHandleIdEdit() {
            this.editingHandleId = true;
            this.tempHandleId = this.profile.handleId;
        },

        async authoriseProfile() {
            try {
                this.authInProgress = true;

                let payload = {
                    handle: this.profile.handle,
                    handleId: this.profile.handleId,
                    type: this.profile.type,
                }

                // generate URL for the appropriate platform that will be opened in a popup window
                let response = await beef.post(`/api/online-profile/authorize-user-profile${this.account?.code ? `?accountCode=${this.account.code}` : ''}&platformAuth=true`, payload);

                let authFunctionName = `userPlatformAuth${this.user.id}`;

                // callback function for success/failure of the auth flow process (called by the popup window)
                window[authFunctionName] = async (paramsMap) => {
                    if (paramsMap["auth"] === "success") {
                        try {
                            // auth flow was successful and token was generated/refreshed -> authorise the profile on the account
                            this.saving = true;

                            let managedProfiles = [];

                            // fetch the users managed profiles, this list will have been updated with the profile that has just been authorised
                            if (this.profile.type === profileTypes.twitter) {
                                await this.initializeManagedTwitterProfiles(true);
                                managedProfiles = this.managedTwitterProfiles;
                            } else if (this.profile.type === profileTypes.linkedin) {
                                await this.initializeManagedLinkedinProfiles(true);
                                managedProfiles = this.managedLinkedinProfiles;
                            }

                            // get the required managed profile
                            let managedProfile = managedProfiles.find(p => this.profile.handleId === p.handleId && this.profile.handle === p.handle);
                            if (!managedProfile && this.profile.type === profileTypes.linkedin) {
                                // older Linkedin company profiles may have the same handle and handle ID
                                managedProfile = managedProfiles.find(p => this.profile.handle === p.handle && this.profile.handleId === p.handle);
                            }

                            if (managedProfile) {
                                // authorise the profile on the account using data found in the managed profile (token, tokenExpire, etc)
                                let payload = {
                                    pageLookups: [
                                        {
                                            apiAppId: managedProfile.apiAppId,
                                            handle: managedProfile.handle,
                                            handleId: managedProfile.handleId,
                                            name: managedProfile.name,
                                            pictureLink: managedProfile.pictureLink,
                                            token: managedProfile.token,
                                            tokenExpire: managedProfile.tokenExpire,
                                            type: managedProfile.type
                                        }
                                    ],
                                    accountCode: this.account.code,
                                    appId: managedProfile.apiAppId,
                                }

                                await beef.post("/api/online-profile/add-managed-profiles", payload);

                                await this.storeRefreshProfile(this.profile.id); // refresh profile in store

                                this.tempProfile = JSON.parse(JSON.stringify(this.profile)); // update the editable profile

                                notifyWithText(`${this.prettyProfileType(this.profile.type)} ${this.profile.name} successfully authorised.`,
                                    null,
                                    "<i class='symbol-online-profiles'></i>");
                            } else {
                                console.error(`Error occurred while trying to add ${this.profile.type} profile with ID ${this.profile.id} to
                                account ${this.account.code} after authorisation: profile not in list of user ${this.user.id} managed profiles`);

                                notifyUserOfError(`An error occurred while trying to authorise profile ${this.profile.name}. Please refresh the page and try again or contact support.`);
                            }
                        } catch(e) {
                            console.error(`Error occurred while trying to add ${this.profile.type} profile with ID ${this.profile.id} to account ${this.account.code} after authorisation: `, e);

                            notifyUserOfError(`An error occurred while trying to authorise profile ${this.profile.name}. Please refresh the page and try again or contact support.`);
                        } finally {
                            this.saving = false;
                            this.authInProgress = false;
                        }
                    } else {
                        console.error(`Error occurred while trying to authorise ${this.profile.type} profile with ID ${this.profile.id}: failureUser has been rendered`);

                        this.authInProgress = false;
                        this.saving = false;

                        let errorMessage = `An error occurred while trying to authorise profile ${this.profile.name}.`;

                        // specific linkedin message for trying to authorise a company profile that you are not an admin of
                        if (this.profile.type === this.profileTypes.linkedin) {
                            if (paramsMap["errorCode"] && paramsMap["errorCode"] === 403) {
                                errorMessage += ` You are not an admin of the company profile that you are trying to authorise.`
                            } else if (paramsMap["message"]) {
                                errorMessage += ` ${paramsMap["message"]}`
                            }
                        } else if (paramsMap["message"]) {
                            errorMessage += ` ${paramsMap["message"]}`
                        }

                        notifyUserOfError(errorMessage);
                    }
                }

                let win = openWindowCenter(response.data.url, "", 600, 1000);
                if (!win) { // popups blocked
                    this.authInProgress = false
                    notifyUserOfError('Unable to open popup window. Please allow popups in your browser and try again.')
                } else if (this.profile.type === 'TIKTOK') {
                    // for simple flow like tiktok we just have to reload the profiles .. also using window.opener
                    // to call a js function on the parent window doesn't seem to work on safari
                    let iv = setInterval(() => {
                        if (win.closed) {
                            clearInterval(iv)
                            this.authInProgress = false
                            this.$emit('refreshprofiles')
                        }
                    }, 500)
                }

            } catch (error) {
                console.error("Error occurred while trying to authorise profile " + this.profile.handle + ": ", error);

                this.authInProgress = false;

                notifyUserOfError(`An error occurred while trying to authorise profile ${this.profile.name}. Please refresh the page and try again or contact support.`);
            }
        },

        openProfileLink() {
            if (this.canLinkProfile) {
                window.open(this.profile.link, '_blank').focus();
            }
        },

        expand(value) {
            if (this.showUnauthDialog) return;
            value ??= !this.expanded;
            this.expanded = value;

            if (this.expanded) {
                this.$nextTick(() => {
                    this.$el.scrollIntoView({behavior: 'smooth', block: 'end'});
                });
            }
        },

        toggleEditTags() {
            this.editTags = true;

            this.$nextTick(() => this.$refs.profileTagInput?.focus());
        },

        resetTag() {
            this.addTagName = "";
            this.editTags = false;
        },

        // has the tag just been added to the profile?
        isAddedTag(tag) {
            if (this.curProfileTagIds.length) {
                let tagExists = !!this.curProfileTagIds.find(profileTag => profileTag === tag.id);

                return !tagExists;
            }

            return true;
        },

        async removeTag(tag) {
            this.tempProfile.tagIds = this.tempProfile.tagIds.filter(profileTagId => profileTagId !== tag.id);

            await this.updateProfile(`Tag ${tag.name} has been removed from profile`, "tagIds");
        },

        async addSuggestedTag(tag) {
            if (!this.tempProfile.tagIds) Vue.set(this.tempProfile, 'tagIds', []);

            // don't add the tag if the profile already has it
            let tagExists = !!this.tempProfile.tagIds.find(profileTagId => profileTagId === tag.id);
            if (!tagExists) {
                this.tempProfile.tagIds.push(tag.id);
            }

            await this.updateProfile(`Tag ${tag.name} has been added to profile`, "tagIds");

            this.resetTag();
        },

        // create new tag and add it to profile
        async addNewTag() {
            try {
                let tag = {
                    name: this.addTagName
                }

                this.saving = true;

                let newTag = await this.createTag(tag);

                if (newTag) {
                    if (!this.tempProfile.tagIds) Vue.set(this.tempProfile, 'tagIds', []);

                    this.tempProfile.tagIds.push(newTag.id);
                }

                await this.updateProfile(`New tag ${tag.name} has been added to profile`, "tagIds");
            } catch (e) {
                console.error("Unable to create new tag: ", e);
                await showErrorDialog("We were unable to save this profile's changes");
            } finally {
                this.saving = false;
                this.resetTag();
            }
        },

        async unauthoriseProfile() {
            try {
                this.saving = true;

                // invalidate token for Twitter profiles
                if (this.profile.type === profileTypes.twitter && this.invalidateToken) {
                    await mash.delete(`/rest/api-tokens/user/${this.profile.authorizedById}/profile/${this.profile.handleId}/unauth?accountCode=${this.account.code}${this.unauthReason ? `&unauthReason=${encodeURIComponent(this.unauthReason)}` : ""}`);
                    await this.storeRefreshProfiles(true);
                } else {
                    await this.storeUnauthProfile({
                        profileId: this.profile.id,
                        linkedProfileId: this.profile.linkedPresenceId,
                        reason: this.unauthReason
                    });
                }

                this.tempProfile = JSON.parse(JSON.stringify(this.profile)); // update the editable profile

                notifyWithHtml(
                    `Profile has been unauthorised successfully.`,
                    null,
                    "<i class='symbol-profiles'></i>");
            } catch (e) {
                console.error("Error trying to unauthorise profile: ", e);
                await showErrorDialog("We were unable to unauthorise this profile. Please try again or contact DataEQ support.");
            } finally {
                this.saving = false;
                this.unauthReason = "";
                this.showUnauthDialog = false;
                this.invalidateToken = false;
            }
        },

        toggleBrandCollapse(parentBrand) {
            // if we are collapsing/expanding the root brand, then we need also need to adjust childrenVisible for the root brand's children's children
            if (parentBrand.isRootBrand) {
                this.brandDisplayList.forEach(brand => {
                    if (brand.parent === parentBrand.id) brand.childrenVisible = !parentBrand.childrenVisible;
                });
            }

            parentBrand.childrenVisible = !parentBrand.childrenVisible;
        },

        rootBrandHasChildren(rootBrand) {
            for (let i = 0; i < this.profile.brands?.length; i++) {
                let curBrands = this.profile.brands[i];
                if (curBrands?.length > 1 && curBrands.find(brand => brand.name === rootBrand.name)) return true;
            }

            return false;
        },

        buildBrandDisplayList() {
            let nonDuplicateBrands = new Set();
            let indentValue = 0;

            // Sort brands on profile so we can display them in alphabetical order.
            // "brands" field on profile object is an array of arrays, i.e.
            // [{[a,b,c]}, {[a,b,d]}], where each list in the "brands" list represents the parent brands and children brands.
            // E.g. in {a, b, c}, a is b's parent and b is c's parent.
            let profileBrands = JSON.parse(JSON.stringify(this.profile.brands));
            profileBrands?.sort((a, b) => {
                let aTotal = 0;
                a.forEach(a => aTotal += a.name.toLowerCase());

                let bTotal = 0;
                b.forEach(b => bTotal += b.name.toLowerCase());

                return aTotal > bTotal ? 1 : -1;
            })

            /**
             * build a single list of all brands that should be displayed. The list should:
             * - contain no duplicates
             * - be sorted in the order that the brands should be displayed
             */
            profileBrands?.forEach(brands => {
                for (let i = 0; i < brands?.length; i++) {
                    let curBrand = brands[i];

                    if (!nonDuplicateBrands.has(curBrand.id)) {
                        nonDuplicateBrands.add(curBrand.id);

                        indentValue = 15 * i; // represents the amount of indentation (in px) that an item will get

                        let parent = null;
                        // the first brand in a brand list will always be the root brand
                        if (i > 0) {
                            parent = brands[i - 1].id;
                        }

                        let displayBrand = {
                            id: curBrand.id,
                            name: curBrand.name,
                            indent: indentValue,
                            parent: parent,
                            isRootBrand: parent === null,
                            childrenVisible: true,
                            hasChildren: parent === null ? this.rootBrandHasChildren(curBrand) : (i > 0 && i < brands.length - 1)
                        }

                        this.brandDisplayList.push(displayBrand);
                    }
                }
            });
        },

        isBrandVisible(brand) {
            if (brand.isRootBrand) return true;

            // get the parent brand
            let parentBrand = this.brandDisplayList.find(displayBrand => displayBrand.id === brand.parent);

            return parentBrand.childrenVisible;
        },

        async updateCategory(category) {
            if (!this.tempProfile.media) {
                Vue.set(this.tempProfile, "media", category.value);
            } else {
                if (this.tempProfile.media === category.value) {
                    this.tempProfile.media = null;
                } else {
                    this.tempProfile.media = category.value;
                }
            }

            await this.updateProfile(`Profile's category has been updated`, "media");
        },

        async updateLinkedPresence(ttProfile){
            if (!this.tempProfile.linkedPresenceId) {
                Vue.set(this.tempProfile, "linkedPresenceId", ttProfile.id);
            } else {
                if (this.tempProfile.linkedPresenceId === ttProfile.id) {
                    this.tempProfile.linkedPresenceId = 0;
                } else {
                    this.tempProfile.linkedPresenceId = ttProfile.id;
                }
            }

            await this.updateProfile(`Profile's linked presence has been updated`, "linkedPresenceId");
        },

        async updateDescription() {
            // has description changed?
            if (this.profile.description === this.tempProfile.description) return;

            await this.updateProfile(`Profile's description has been updated`, "description");
        },

        async updateHandle() {
            // has handle changed?
            if (this.profile.handle === this.tempHandle) return;

            this.tempProfile.handle = this.tempHandle;
            await this.updateProfile(`Profile's handle has been updated`, "handle");

            this.editingHandle = false;
        },

        async updateHandleId() {
            // has handle ID changed?
            if (this.profile.type === profileTypes.helloPeter && !this.profile.authorized) {
                if (this.profile.handleId === this.tempHandleId) return;

                this.tempProfile.handleId = this.tempHandleId;
                await this.updateProfile(`Profile's handle ID has been updated`, "handleId");

                this.editingHandleId = false;
            }
        },

        async updateApiKey() {
            try {
                this.saving = true;

                if (this.apiKey === "") return;

                let profileUpdateData = JSON.parse(JSON.stringify(this.profile))

                switch (this.profile.type) {
                    case profileTypes.helloPeter:
                        profileUpdateData.token = this.profile.handleId;
                        profileUpdateData.tokenSecret = this.apiKey;
                        profileUpdateData.apiAppId = getProfileAppId(profileTypes.helloPeter);
                        break;
                    default:
                        console.warn(`unable to update API key for profiles of type ${this.profile.type}`);
                }

                await this.storeUpdateProfile(profileUpdateData);

                notifyWithHtml(
                    "Profile API key has been updated",
                    null,
                    "<i class='symbol-profiles'></i>")
            } catch (e) {
                console.error("Unable to update profile api key: ", e);
                await showErrorDialog("We were unable to save this profile's changes");
            } finally {
                this.saving = false;

                this.apiKey = "";
            }

        },

        async updateRelevant() {
            if (this.tempProfile.relevant === undefined) {
                Vue.set(this.tempProfile, 'relevant', false);
            }

            this.tempProfile.relevant = !this.tempProfile.relevant;

            await this.updateProfile(`Mentions from this profile will be ${!this.tempProfile.relevant ? `not be kept` : `kept even`} if irrelevant`, "relevant");
        },

        async updateCollectMetrics() {
            if (this.tempProfile.collectMetricsForHeadposts === undefined) {
                Vue.set(this.tempProfile, 'collectMetricsForHeadposts', false);
            }

            this.tempProfile.collectMetricsForHeadposts = !this.tempProfile.collectMetricsForHeadposts;

            await this.updateProfile(`Metrics shall be collected for mentions for this profile`, "collectMetricsForHeadposts");
        },

        async updateFbSharedAsset() {
            if (this.tempProfile.facebookSharedAsset === undefined) {
                Vue.set(this.tempProfile, 'facebookSharedAsset', false);
            }

            this.tempProfile.facebookSharedAsset = !this.tempProfile.facebookSharedAsset;

            await this.updateProfile(`Facebook shared asset has been ${this.tempProfile.facebookSharedAsset ? `enabled` : `disabled`}`, "facebookSharedAsset");
        },

        async updateDms() {
            if (!this.canEditDms) return;

            if (this.tempProfile.directMessagesEnabled === undefined) {
                Vue.set(this.tempProfile, 'directMessagesEnabled', false);
            }

            this.tempProfile.directMessagesEnabled = !this.tempProfile.directMessagesEnabled;

            await this.updateProfile(`Profile's DM's have been ${this.tempProfile.directMessagesEnabled ? `enabled` : `disabled`}`, "directMessagesEnabled");
        },

        async updateProfile(message, field) {
            try {
                this.saving = true;

                // some fields need to be handled differently depending on their data type
                let oldValue = null;
                if (field) {
                    switch (field) {
                        // booleans
                        case "relevant":
                        case "directMessagesEnabled":
                        case "deleted":
                        case "facebookSharedAsset":
                        case "collectMetricsForHeadposts":
                            oldValue = !!this.profile[field];
                            break;
                        // arrays
                        case "tagIds":
                            oldValue = this.profile[field] ?? [];
                            break;
                        // strings
                        case "description":
                        case "handle":
                        case "handleId":
                            oldValue = this.profile[field] ?? "";
                            break;
                    }
                }

                // don't include these auth fields in the payload to prevent
                // auth flow logic occurring in the update
                let payload = {...this.tempProfile};
                delete payload.token;
                delete payload.tokenSecret;
                delete payload.scope;

                await this.storeUpdateProfile(payload);
                this.$emit('refresh-profile-warnings');

                message ??= 'Profile has been updated';

                notifyWithHtml(
                    message,
                    oldValue !== null ? (async () => {
                        try {
                            this.saving = true;

                            this.tempProfile[field] = oldValue;

                            // don't include these auth fields in the payload to prevent
                            // auth flow logic occurring in the update
                            let payload = {...this.tempProfile};
                            delete payload.token;
                            delete payload.tokenSecret;
                            delete payload.scope;

                            await this.storeUpdateProfile(payload);
                            this.$emit('refresh-profile-warnings');

                            notifyWithHtml(
                                "Profile changes have been undone",
                                null,
                                "<i class='symbol-profiles'></i>")
                        } catch (e) {
                            console.error("Unable to undo profile changes: ", e);
                            await showErrorDialog("We were unable to undoing profile changes");
                        } finally {
                            this.saving = false;
                        }
                    }) : null,
                    "<i class='symbol-profiles'></i>")
            } catch (e) {
                console.error("Unable to update profile: ", e);
                await showErrorDialog("We were unable to save this profile's changes");
            } finally {
                this.saving = false;
            }
        },

        /**
         * Deletes the profile if the profile has not been deleted, undeletes the profile otherwise
         */
        async deleteProfile() {
            try {
                this.saving = true;

                let shouldDelete = !this.profile.deleted;

                if (shouldDelete) {
                    await this.storeDeleteProfile({profileId: this.profile.id, linkedProfileId: this.profile.linkedPresenceId});
                    this.expanded = false;
                } else {
                    await this.storeUndeleteProfile({profileId: this.profile.id, linkedProfileId: this.profile.linkedPresenceId});
                }

                notifyUser({
                    message: `Profile ${shouldDelete ? 'deletion' : 'restoration'} successful.`,
                    isEscapedHtml: true,
                    icon: "<i class='symbol-profiles'></i>",
                    longDelay: true,
                    undo: async () => {
                        let busy = showBusyNotification(`${shouldDelete ? 'Restoring' : 'Deleting'} profile...`, null, null, true);
                        try {
                            if (shouldDelete) {
                                await this.storeUndeleteProfile({profileId: this.tempProfile.id, linkedProfileId: this.tempProfile.linkedPresenceId});
                            } else {
                                await this.storeDeleteProfile({profileId: this.tempProfile.id, linkedProfileId: this.tempProfile.linkedPresenceId});
                                this.expanded = false;
                            }

                            notifyWithHtml(
                                `Profile has been ${this.profile.deleted ? `deleted` : `restored`}.`,
                                null,
                                "<i class='symbol-profiles'></i>")
                        } catch (e) {
                            console.error("Unable to undo profile delete/undelete: ", e);
                            await showErrorDialog(`We were unable to ${shouldDelete ? `undelete` : `delete`} this profile`);
                        } finally {
                            busy.close();
                        }
                    }
                })
            } catch (e) {
                console.error("Unable to delete/restore profile: ", e);
                await showErrorDialog(`We were unable to ${this.profile.deleted ? 'restore' : 'delete'} this profile`);
            } finally {
                this.saving = false;
                this.showDeleteDialog = false;
            }
        },

        openBrandPicker() {
            let brandPicker = showDialog(BrandPickerDialog, {onlyOne: false, mustHaveOne: true});

            brandPicker.$on('selected', selectedBrands => {
                this.saveProfilePhrases(selectedBrands);
            });
        },

        async saveProfilePhrases(selectedBrands) {
            try {
                this.saving = true;
                let phrases = [];

                // prepare phrases
                selectedBrands.forEach(brand => {
                    // only allow adding phrases to brands that aren't imported
                    if (!brand.importedFromAccount) {
                        let quoteHandle = phraseNeedsQuotes(this.profile.handle);
                        let handle = this.profile.handle;

                        // Modifying handle phrase based on platform
                        // We exclude enterprise mentions from root brand filters but only if their handle is tracked in the @name format
                        if (this.profile.type === profileTypes.instagram_user || this.profile.type === profileTypes.twitter) handle = `@${handle}`;

                        if (this.profile.type !== profileTypes.trustpilot) {
                            let handlePhrase = {
                                query: quoteHandle === true ? `"${handle}"` : handle,
                                active: true,
                                brandId: brand.id,
                                deleted: false
                            };
                            if (this.profile.type === profileTypes.helloPeter){
                                handlePhrase.socialNetworks = 'HELLOPETER';
                            }
                            if (this.profile.type === profileTypes.googleBusiness){
                                handlePhrase.socialNetworks = 'GOOGLEBUSINESS';
                            }

                            phrases.push(handlePhrase);
                        }

                        // Youtube, and sometimes Facebook, handle and handleId are the same.
                        if (this.profile.handle !== this.profile.handleId) {
                            let quoteHandleId = phraseNeedsQuotes(this.profile.handleId);
                            let handleIdPhrase = {
                                query: quoteHandleId === true ? `"${this.profile.handleId}"` : this.profile.handleId,
                                active: true, brandId: brand.id, deleted: false
                            };
                            if (this.profile.type === profileTypes.helloPeter){
                                handleIdPhrase.socialNetworks = 'HELLOPETER';
                            }
                            if (this.profile.type === profileTypes.googleBusiness){
                                handleIdPhrase.socialNetworks = 'GOOGLEBUSINESS';
                            }
                            phrases.push(handleIdPhrase);
                        }
                    }
                });

                // save phrases
                await mash.post(`/rest/accounts/${this.account.code}/phrases`, phrases);

                await this.refreshBrands(true);
                await this.storeRefreshProfiles(true);

                if (this.profile.brands) {
                    this.buildBrandDisplayList();
                }
            } catch (e) {
                console.error("Unable to add profile handle and handleId as phrases to brands: ", e);
                await showErrorDialog("We were unable to save this profile's handle and handle ID to the selected brands. Please refresh and try again or contact support.");
            } finally {
                this.saving = false;
            }
        },

        getTikTokProfiles(){
            return this.profiles.filter(p=>p.tokenAppId == 50);
        },

        getLinkedTikTokProfile(){
            if(this.profile.linkedPresenceId){
                return this.profiles.find(el=>el.id==this.profile.linkedPresenceId)
            }else{
                return null
            }
        }
    }
}
</script>

<style scoped lang="sass">

.profile-row
    --container-border: 1px solid rgb(136 136 136 / 0.5)

    display: grid
    box-sizing: border-box
    transition-property: background, margin
    transition-duration: 100ms
    grid-template-columns: var(--grid-columns)
    background: #333
    border: var(--border)

    &::v-deep .online-profile
        width: 230px

    &.deleted
        background: #93413e

    & + .profile-row
        border-top: none

    &.expanded
        display: flex
        transition-property: margin
        /* Make sure background doesnt transition here, or the flicker is awful. */
        background: #444
        margin-top: 20px
        margin-bottom: 20px
        border-top: var(--border)
        border-left: 0
        border-right: 0
        box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.4)

    &:not(.expanded):hover
        background: var(--background-menu-hover)
        cursor: pointer

    &__data
        padding: 10px
        border-right: var(--border)

    &__justify-data
        display: inline-flex
        align-items: center
        justify-content: center
        text-align: center

    &__center-data
        display: inline-flex
        align-items: center

        &.space-between
            justify-content: space-between

        &:last-child
            border-right: none

    &__saving-message
        color: var(--be-colour-muted-text-dark)
        font-size: 1rem
        margin-left: 30px

        & ::v-deep .css-spinner
            top: 3px
            margin: 0 5px

    &__editable-text
        word-break: break-all
        align-self: flex-start
        margin-bottom: 5px !important
        cursor: pointer
        font-style: italic
        transition: color var(--transition-duration)
        color: var(--be-colour-muted-text-dark)

        &:hover
            color: var(--be-colour-text-dark__hover)

            i
                visibility: visible !important

    &__muted-text
        color: var(--be-colour-muted-text-dark)

    &__warning-text
        color: var(--be-colour-warning)

    &__expanded-container
        border-left: var(--border)
        border-right: var(--border)
        width: 100%

        p
            margin: 0
            color: var(--be-colour-text-dark)

        h4
            margin: 0

    &__expanded-header
        display: flex
        align-items: center
        border-bottom: 1px solid #272727
        background: #333
        padding: 10px
        cursor: pointer

        &.deleted
            background: #93413e

    &__expanded-profile-info
        font-size: 14px
        display: flex
        column-gap: 30px

        ::v-deep img
            width: 60px
            height: 60px
            margin: 0

    &__warning-list
        max-height: 400px
        overflow-y: auto

        .warning-item
            padding: 5px
            line-height: 20px

            & + .warning-item
                border-top: 1px solid #1a1a1a

    &__expanded-profile-header-info
        margin-left: 40px
        font-size: 20px
        padding: 20px 10px

    &__expanded-edit-container
        display: flex
        padding: 10px 0

    &__expanded-edit-left
        display: grid
        grid-template-columns: 200px 1fr
        grid-auto-rows: auto
        row-gap: 20px
        width: 50%
        padding: 20px
        border-right: var(--container-border)

    &__expanded-edit-right
        display: flex
        flex-direction: column
        row-gap: 15px
        width: 50%
        padding: 20px


    &__expanded-tags-container
        border: var(--container-border)
        padding: 5px
        border-radius: 4px
        max-height: 80px
        overflow-y: auto

    &__expanded-brands-list
        border: var(--container-border)
        border-radius: 4px
        max-height: 150px
        overflow-y: auto
        margin-top: 15px

        .expandable-brand
            cursor: pointer

            &:hover
                color: var(--be-colour-text-dark__hover)

        ul
            list-style: none
            margin: 0

        li
            padding: 0 5px
            line-height: 25px

            &:nth-child(odd)
                background: rgb(102 102 102 / 0.5)

            &:nth-child(even)
                background: rgb(80 80 80 / 0.5)

            &:last-of-type
                margin-bottom: 0

    &__expanded-footer
        display: flex
        background: #333333
        border-top: 1px solid #272727
        padding: 10px
        align-items: center

        & > .btns
            margin-left: auto
            display: flex

    &__category-list
        margin: 3px 0
        padding: 5px 5px 5px 10px

        ul
            list-style: none
            margin: 10px 0 0 0

        li
            font-weight: 400
            color: #ffffff
            cursor: pointer
            display: block
            padding: 3px 10px
            clear: both
            white-space: nowrap

            &:hover
                color: #aee15d
                text-decoration: none

    &__tag-suggestions
        margin: 3px 0
        min-width: 125px
        max-height: 200px
        overflow-y: auto

        ul
            list-style: none
            margin: 0

        li > a
            font-weight: 400
            color: #ffffff
            cursor: pointer
            display: block
            padding: 3px 10px
            clear: both
            white-space: nowrap

            &:hover
                color: #aee15d
                text-decoration: none
                background-color: #222222

    &__added-tag
        animation: new-tag-transition 1s linear
        animation-iteration-count: 1


.profile-row.expanded + .profile-row:not(.expanded)
    border-top: var(--border)

.expanded .profile-row__hide-expanded
    display: none

.disabled
    color: #666 !important
    pointer-events: none !important

.disabled-dms-editing
    color: #666 !important
    cursor: default

.read-only
    pointer-events: none

.row-container
    display: flex
    align-items: center
    column-gap: 10px

.ask-dialog

    &__profiles
        margin: 20px 0
        display: flex
        flex-direction: column
        row-gap: 10px

    &__linked-profile-text
        margin-left: 10px
        font-style: italic
        color: var(--be-colour-muted-text-dark)

    &__invalidate-container
        margin: 20px 0

    textarea
        width: 95%
        height: 70px
        resize: none

    &__btns
        display: flex
        justify-content: flex-end

@keyframes new-tag-transition
    0%
        background: #333

    50%
        background: #aee15d

    100%
        background: #333

</style>