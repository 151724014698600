<template>
    <div class="row-fluid" :style="{marginTop: index === 0 ? null : 0}">
        <div class="span12 control-group control-row">
            <div class="control-group" @click="editFilter">
                <label>Row filter</label>
                <div class="controls">
                    <div class="tag-input uneditable-input filter">
                        <english-filter v-if="row.filter" :filter="row.filter" partial/>
                    </div>
                </div>
            </div>

            <drop-down-input v-model="groupBy" :options="groupByOptions" label="Split row into" optional
                             style="width: 200px; flex-grow: 0"/>
            <template v-if="groupBy">
                <text-input v-if="showLimitInput" v-model="limit" numeric label="Max values" :rules="rules.maxItems"
                            style="width: 70px; flex-grow: 0"/>

                <drop-down-input v-if="showSlaResponseTimeInput"  v-model="slaResponseTime" :options="slaResponseTimeOptions" label="SLA response time"
                                 style="width: 120px; flex-grow: 0"/>

                <div style="margin-left: 10px" :style="{marginTop: (canSortByFirstCol || canSortByName ? 0 : 13) + 'px'}">
                    <label class="checkbox">
                        <input v-model="showUnknown" type="checkbox"> Show unknown
                    </label>
                    <label class="checkbox">
                        <input v-model="showOther" type="checkbox"> Show others
                    </label>
                    <label class="checkbox" v-if="canSortByFirstCol">
                        <input v-model="sortByFirstCol" type="checkbox"> Sort by 1st col
                    </label>
                    <label class="checkbox" v-if="canSortByName">
                        <input v-model="sortByName" type="checkbox"> Sort by name
                    </label>
                </div>
            </template>
            <text-input v-else v-model="name" label="Row name" style="width: 191px; flex-grow: 0"/>

            <up-down-button v-if="canRemove" :index="index" :array="attrs.rows" style="margin-left: 4px; margin-top: 22px"/>

            <a v-if="canRemove" class="btn btn-link" @click="remove" title="Remove this row" style="margin-top: 25px">
                <i class="icon-trash-1" />
            </a>
        </div>

        <dialog-box v-if="showEditFilter" title="Edit Row Filter" @close="showEditFilter = false" width="740px">
            <filter-editor v-model="filter" no-published no-trash :brand="brand"/>
            <template #buttons>
                <a class="cancel btn" @click="showEditFilter = false">Cancel</a>
                <a class="btn btn-primary" @click="editFilterOk">Ok</a>
            </template>
        </dialog-box>
    </div>
</template>

<script>

import DropDownInput from "@/components/inputs/DropDownInput";
import TextInput from "@/components/inputs/TextInput";
import {RULES} from "@/dashboards/widgets/WidgetSettingsDialog";
import DialogBox from "@/components/DialogBox";
import EnglishFilter from "@/components/formatters/EnglishFilter";
import FilterEditor from "@/components/inputs/FilterEditor";
import UpDownButton from "@/components/UpDownButton";
import {convertFilterToAttrs} from "@/dashboards/filter/BasicFilter";
import {
    setColOrRowVisibility,
    GROUPBY_BY_ID,
    getAvailableGroupByOptions,
    GROUPBY, SLA_RESPONSE_TIME_OPTIONS
} from "@/dashboards/widgets/comptable/CompTableUtils";
import {isMashAdmin} from "@/app/Permissions";
import {features} from "@/app/Features";

export default {
    name: "CTRowSettings",

    components: { DropDownInput, TextInput, DialogBox, EnglishFilter, FilterEditor, UpDownButton },

    props: {
        row: Object,
        index: Number,
        attrs: Object,
        model: Object
    },

    data() {
        return {
            rules: RULES,
            showEditFilter: false,
            filter: String,
            slaResponseTimeOptions: SLA_RESPONSE_TIME_OPTIONS,

            groupBy: this.row.groupBy,
            limit: this.row.limit,
            slaResponseTime: this.row.slaResponseTime,
            showUnknown: this.row.showUnknown,
            showOther: this.row.showOther,
            sortByFirstCol: this.row.sortByFirstCol,
            sortByName: this.row.sortByName,
            name: this.row.name,
        }
    },

    created() {
        if (!this.slaResponseTime) this.slaResponseTime = 2;

        setColOrRowVisibility([
            "engageusertag",
        ], this.groupByOptions, isMashAdmin());

        setColOrRowVisibility([
            "fiveStarRating", "zeroToTenStarRating", "fiveOptionAgreementScale", "zeroToOneScoringScale", "surveyQuestions", "npsBreakDown", "verbatimQuestions"
        ], this.groupByOptions, features.surveyReportingEnhancements());
    },

    watch: {
        groupBy() {
            this.$set(this.row, "groupBy", this.groupBy);
        },

        'row.groupBy'() {
            this.groupBy = this.row.groupBy;
        },

        limit() {
            this.$set(this.row, "limit", this.limit);
        },

        'row.limit'() {
            this.limit = this.row.limit;
        },

        slaResponseTime() {
            this.$set(this.row, "slaResponseTime", this.slaResponseTime);
        },
        'row.slaResponseTime'() {
            this.slaResponseTime = this.row.slaResponseTime;
        },

        showUnknown() {
            this.$set(this.row, "showUnknown", this.showUnknown);
        },

        'row.showUnknown'() {
            this.showUnknown = this.row.showUnknown;
        },

        showOther() {
            this.$set(this.row, "showOther", this.showOther);
        },

        'row.showOther'() {
            this.showOther = this.row.showOther;
        },

        sortByFirstCol() {
            this.$set(this.row, "sortByFirstCol", this.sortByFirstCol);
        },

        'row.sortByFirstCol'() {
            this.sortByFirstCol = this.row.sortByFirstCol;
        },

        sortByName() {
            this.$set(this.row, "sortByName", this.sortByName);
        },

        'row.sortByName'() {
            this.sortByName = this.row.sortByName;
        },

        name() {
            this.$set(this.row, "name", this.name);
        },

        'row.name'() {
            this.name = this.row.name;
        },
    },

    computed: {
        canRemove() { return this.attrs.rows.length > 1 },

        brand() {
            let brand = this.model.getInteractiveFilterModel().get('brand');
            if (!brand) brand = convertFilterToAttrs(this.sectionFilter).brand
            return brand
        },

        groupByOptions() {
            let used = []
            this.attrs.cols.forEach(c => { if (c.groupBy) used.push(c.groupBy) })
            return getAvailableGroupByOptions(used)
        },

        rowGroupByObject() {
            let key = this.row.groupBy
            if (!key) return null
            let i = key.indexOf("-")
            if (i > 0) key = key.substring(0, i)    // strip of segment ids etc.
            return GROUPBY_BY_ID[key]
        },

        canSortByFirstCol() {
            let gb = this.rowGroupByObject
            return gb && gb.canSortByFirstCol && !this.attrs.cols.find(c => !!c.groupBy)
        },

        canSortByName() {
            let gb = this.rowGroupByObject
            return gb && gb.canSortByName && !this.attrs.cols.find(c => !!c.groupBy)
        },

        showSlaResponseTimeInput() {
            return this.row.groupBy === "interactionResponseTimeBreakdown" || this.row.groupBy === "interactionResponseTimeWhBreakdown" || this.row.groupBy === "firstResponseTimeBreakdown" || this.row.groupBy === "firstResponseTimeWhBreakdown";
        },

        showLimitInput() {
            for (const groupByOption of GROUPBY) {
                if (groupByOption.id === this.row.groupBy) {
                    return !groupByOption.noMax;
                }
            }

            return true;
        }
    },

    methods: {
        remove() {
            const result = Array.from(this.attrs.rows);
            result.splice(this.index, 1);
            this.$set(this.attrs, "rows", result);
        },

        editFilter() {
            this.filter = this.row.filter
            this.showEditFilter = true
        },

        editFilterOk() {
            this.showEditFilter = false;
            this.$set(this.row, "filter", this.filter);
        }
    }
}

</script>

<style scoped>

</style>