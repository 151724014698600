<template>
    <div>
        <transition name="slide-content">
            <div>
                <div v-if="youtubeProfiles.length">
                    <p>
                        These are the Youtube Channels that you have granted DataEQ access to. Click on a Channel's row
                        to view the accounts you can add it to.
                    </p>

                    <search-input v-if="youtubeProfiles.length > 5" class="tiktok-auth__search-bar" autofocus
                                  placeholder="Search for a channel" v-model="searchTerm">
                    </search-input>
                    <div class="youtube-auth__profiles dark-scrollbars dark-scrollbars--visible"
                         :style="youtubeProfiles.length > 5 ? 'height: clamp(230px, 22vh, 600px)' : 'height: clamp(265px, 26vh, 600px)'">

                        <online-profile-row v-for="profile in filteredProfiles"
                                            :profile="profile"
                                            :linked-profile="profile.linkedProfile"
                                            :key="profile.handleId"
                                            :expand-row="showAccounts"
                                            :profile-just-reauthorised="profileJustReauthorized"
                                            @collapse-row="showAccounts = false"/>
                        <div style="margin-top: 10px" v-if="!filteredProfiles.length">
                            No Channel's match your search
                        </div>
                    </div>
                </div>
                <div v-else>
                    You can authorise a Youtube channel by clicking the "Authorise Youtube Channel" button below.
                </div>

                <div class="youtube-auth__btn-bar">
                    <be-button class="youtube-auth__add-profile-button" @click="loginThroughYoutube">
                        {{loginBtnText}}
                    </be-button>

                    <div class="login-status" v-if="youtubeLoggedInAs">
                        <div>Logged in through <i class="symbol-youtube"></i>Youtube as {{ youtubeLoggedInAs.name }}</div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>


<script>
import BeButton from "@/components/buttons/BeButton.vue";
import {beef} from "@/store/Services";
import {openWindowCenter} from "@/app/framework/dialogs/user-settings/views/platform-auth/PlatformAuthUtils";
import {notifyUserOfError, notifyWithText} from "@/app/framework/notifications/Notifications";
import {mapActions, mapState} from "vuex";
import {profileTypes} from "@/setup/profiles/ProfileUtils";
import OnlineProfileRow from "@/app/framework/dialogs/user-settings/views/platform-auth/OnlineProfileRow.vue";
import SearchInput from "@/components/inputs/SearchInput";

export default {
    name: "YoutubeAuth",
    components: {OnlineProfileRow, BeButton, SearchInput},

    props: {
        youtubeProfiles: {
            type: Array,
            required: true
        },
        expandAccounts: {
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            searchProfile: "",
            searching: false,
            foundProfile: null,
            searchError: "",
            showAddProfile: false,
            authMessage: {
                message: "",
                type: "INFO"
            },
            profileTypes: profileTypes,
            searchTerm: "",
            showAccounts: false,
            reauthorizedProfiles: new Set(),
            profileJustReauthorized: ""
        }
    },

    created() {
        this.showAccounts = this.expandAccounts;
    },

    computed: {
        ...mapState(['user', 'account']),

        ...mapState('userPlatformAuth/youtubeAuthModule', ['youtubeLoggedInAs']),

        filteredProfiles() {
            let search = this.searchTerm.toLowerCase();

            let profiles = this.youtubeProfiles.filter(profile => profile.name.toLowerCase().includes(search) ||
                profile.handle.toLowerCase().includes(search) || profile.handleId.toLowerCase().includes(search));

            return profiles.sort((a, b) => {
                return a.name > b.name ? 1 : -1
            });
        },

        loginBtnText() {
            return this.filteredProfiles && this.filteredProfiles.length > 0 ? "Refresh Youtube login" : "Authorise Youtube Channel";
        },

        disableAddButton() {
            return !this.canAuthoriseProfile();
        }
    },

    methods: {
        ...mapActions('userPlatformAuth', [
            'initializeManagedYoutubeProfiles'
        ]),

        async loginThroughYoutube() {
            try {
                this.setLoading("Communicating with Youtube...", true);

                let response = await beef.post('/api/online-profile/generate-oauth-url',
                    { type: 'YOUTUBE_CHANNEL', accountCode: this.account.code });

                let win = openWindowCenter(response.data.url, "", 700, 900);
                if (!win) { // popups blocked
                    this.busy = false
                    this.error = 'Unable to open popup window. Please allow popups in your browser and try again.'
                } else {
                    let iv = setInterval(() => {
                        if (win.closed) {
                            clearInterval(iv)
                            if (window.location.href.indexOf("setup/profiles") !== -1) {
                                // this.refreshProfiles(true);
                            }

                            notifyWithText("Youtube login complete.",
                                null,
                                "<i class='symbol-youtube'></i>");

                            this.setLoading("Youtube login complete! Fetching your managed business profiles...", true);

                            this.triggerFetchProfiles();

                            this.showAccounts = true;
                        }
                    }, 500)
                }
            } catch (e) {
                console.error("Error occurred while trying to log user in with Youtube", e);
                this.loginMessage = "Login with Youtube failed. Please reload the page and try again or contact DataEQ support.";
                this.TiktokBusinessLoginFailed = true;

                this.setLoading("", false);

                notifyUserOfError("An error occurred while trying to log you into Youtube. Please close the dialog and try again or contact DataEQ support.");
            }
        },

        setLoading(message, value) {
            this.$emit('loading-updated', message, value);
        },

        triggerFetchProfiles() {
            this.$emit('fetch-youtube-profiles', true);
        },

        triggerUnauthProfile(args) {
            this.$emit('unauth-profile', args);
        },

    }
}


</script>


<style scoped lang="sass">

.youtube-auth

    &__search-bar
        margin-bottom: 10px
        width: 90%

    &__profiles
        padding-right: 10px
        overflow-y: scroll

    &__headers
        display: grid
        cursor: pointer
        overflow-x: auto
        grid-template-columns: 350px repeat(auto-fit, minmax(100px, 1fr))
        position: sticky
        z-index: 10
        border: none
        color: var(--be-colour-text-dark)
        text-align: center

        span
            background: #222
            box-sizing: border-box
            border-top: 1px solid #1a1a1a
            border-right: 1px solid #1a1a1a
            padding: 5px 10px

            &:first-child
                border-top-left-radius: 3px
                border-left: 1px solid #1a1a1a

            &:last-child
                border-top-right-radius: 3px

    &__add-profile-container
        padding-right: 20px

    &__add-profile
        display: flex
        background: #444
        height: 90px
        border: 1px solid #272727
        padding: 10px
        margin-top: 20px
        margin-bottom: 30px
        border-radius: 6px

    &__enter-profile-details
        width: 50%

    &__found-profile
        width: 50%
        padding-left: 10px

    &__add-profile-divider
        margin: -10px 0
        border-right: 1px dashed #272727

    &__add-profile-loader
        margin-top: 0

    &__add-profile-button
        margin-top: 15px

        ::v-deep .btn
            background: var(--colour-youtube)

        &:hover
            ::v-deep .btn
                filter: brightness(1.2)

    &__add-profile-button-bar
        display: flex
        margin-top: 50px
        column-gap: 10px

    &__btn-bar
        display: flex
        align-items: center
        margin-top: 10px
        column-gap: 10px

        .login-status
            color: var(--be-colour-muted-text-dark)
            font-style: italic
            font-size: 0.9em
            padding-right: 10px
            margin-left: auto
            max-width: 295px

.slide-content-enter-active,
.slide-add-profile-enter-active
    transition: all .5s ease

.slide-content-leave-active,
.slide-add-profile-leave-active
    transition: none
    opacity: 0

//.slide-content-enter
//    transform: translatetiktok(-5px)
//    opacity: 0
//
//.slide-add-profile-enter
//    transform: translatetiktok(5px)
//    opacity: 0

</style>