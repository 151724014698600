import Vue from 'vue';
import benchmarkingMetrics, {init as initBenchmarking} from "./metrics/overviewMetrics";
import cxMetrics, {init as initCx} from "./metrics/cxMetrics";
import riskMetrics, {init as initRisk} from "./metrics/riskMetrics";
import sentimentMetrics, {init as initSentiment} from "./metrics/sentimentMetrics";
import themeMetrics, {init as initThemes} from './metrics/themeMetrics';
import authorMetrics from './metrics/authorMetrics';
import socialMetrics from './metrics/socialMetrics';
import geographicMetrics, {init as initGeographic} from './metrics/geographicMetrics';
import competitorMetrics, {init as initCompetitor} from "./metrics/competitorMetrics";
import scsMetrics, {init as initSocialCustomerService} from "./metrics/scsMetrics";
import utilityMetrics, {init as initUtilityMetrics} from './metrics/utilityMetrics';
import priorityMetrics, {init as initPriority} from './metrics/priorityMetrics';
import conductMetrics, {init as initConduct} from './metrics/marketConductMetrics';
import VuexStore from "@/store/vuex/VuexStore";
import surveyMetrics, {init as initSurveyMetrics} from "@/app/framework/dialogs/metric-picker/metrics/SurveyMetrics";


export class MetricTypeError extends Error {
    constructor(message) {
        super(message);
    }
}

/**
 * Some of the available metrics have various 'options' that can configure them
 * differently — for example, to make them use a particular sub-filter or display option.
 *
 * This function applies the options on the base metric, to produce a metric that can
 * be added to a section.
 */
export async function applyOptionAction(metric, options) {
    if (options && !options.action) {
        console.error(`Unable to determine the action for option [${options.key}] on metric [${metric.id || metric.title}]`);
        return null;
    }

    if (options && options.action) {
        metric = await options.action(metric);
    }

    return metric;
}

/**
 * Duplicates a particular metric.
 */
export function duplicate(metric, duplicateOverrides) {
    duplicateOverrides ??= {}
    let duplicate = getMetricFromId(metric);
    if (!duplicate) throw new MetricTypeError(`Unable to add metric [${metric}]`);
    return Object.assign({}, duplicate, duplicateOverrides, {duplicate: true});
}


/**
 * These are descriptions and functions for creating classes of metrics
 * for display in the metric picker. This is observable: adding new descriptions
 * or classes or metrics will have them appear on a metric picker.
 */
export const availableMetrics =  Vue.observable({
    benchmarking: benchmarkingMetrics,
    priority: priorityMetrics,
    sentiment: sentimentMetrics,
    cx: cxMetrics,
    risk: riskMetrics,
    conduct: conductMetrics,
    themes: themeMetrics,
    socialCustomerService: scsMetrics,
    competitors: competitorMetrics,
    authors: authorMetrics,
    social: socialMetrics,
    geography: geographicMetrics,
    utility: utilityMetrics,
    survey: surveyMetrics
});

let idToMetric = null;

export function getMetricFromId(metricId) {
    if (!idToMetric) {
        idToMetric = new Map();
    }

    if (!idToMetric.get(metricId)) {
        Object.values(availableMetrics)
              .flatMap(k => k.metrics || [])
              .forEach(m => {
                  if (m.id) idToMetric.set(m.id, m);
              })
    }

    return idToMetric.get(metricId);
}

let initialisingPromise = null;
/**
 * Initialise the various metrics and metric classes available to a user.
 * @returns {Promise<void>}
 */
export async function init() {
    if (initialisingPromise) return initialisingPromise;
    initialisingPromise = async() => {
        await initCx();
        await initRisk();
        await initBenchmarking();
        await initThemes();
        await initPriority();
        await initGeographic();
        await initCompetitor();
        await initSocialCustomerService();
        await initConduct();
        await initSentiment();
        initUtilityMetrics();
        await initSurveyMetrics();

        const account = VuexStore.state.account;
        const ave = getMetricFromId("ave/social/columns");
        const tradAve = getMetricFromId("ave/trad/columns");
        ave.hide = !account.showAVE;
        tradAve.hide = !account.showTraditionalAVE;

        // Here we make sure to copy duplicates in.
        const keys = Object.keys(availableMetrics);
        for (const key of keys) {
            const klass = availableMetrics[key];
            if (!klass.metrics || !klass.metrics.length) continue;
            for (let i = 0; i < klass.metrics.length; i++) {
                const m = klass.metrics[i];
                if (m.duplicate && getMetricFromId(m.duplicate)) {
                    klass.metrics[i] = duplicate(m.duplicate, m)
                }
            }
        }
    };

    return initialisingPromise();
}


/**
 * Returns the descriptor for the risk volume metric, assuming that one exists.
 * @returns {null|{id: String, title: String, description: String, image: String, widgetDesc: {type: String}}}
 */
export function getRiskVolumeMetricDesc() {
    return getMetricFromId("riskVolumes");
}