<template>
    <div class="search-component">
        <div v-for="result in results" :key="'resItem' + result.id">
            <div tabindex="0" @click="onClick(result)" class="brand-name" :style="{'padding-left': result.padding || result.phrasePadding}">
                <div>{{result.shortName || result.name}}</div>
                <div v-for="phrase in filterPhrases(result.phrases)" :key="phrase.id">
                    <PhraseRendererComponent :phrase="phrase" class="phrase-tags"/>
                </div>

            </div>
            <div v-if="result.children">
                <PhraseSearchResult :results="result.children" :search-text="searchText" @selected="emit"/>
            </div>
        </div>
    </div>
</template>

<script>

import PhraseRendererComponent from "@/setup/brands/tree/PhraseRendererComponent.vue";

export default {
    name: "PhraseSearchResult" ,
    components: {PhraseRendererComponent},
    props: ['results', 'searchText'],

    methods: {
        onClick(brand) {

            if (brand.level === 0) {
                brand.children[0].parent.showChildren = true
                brand.children[0].parent.showPhrases = true
            } else {
                if (brand.level === 1) {
                    const find = brand.parent?.children?.find(child => child.id === brand.id);
                    find.showChildren = true
                    find.showPhrases = true
                }

                if (brand.level > 1) {
                    brand.showChildren = true
                    brand.showPhrases = true
                }

                this.showParentChildren(brand)
            }

            this.scrollToRow(brand.id);
            this.emit()
        },

        emit() {
            this.$emit('selected')
        },

        showParentChildren(brand) {
            if (brand.parent) {
                brand.parent.showChildren = true
                this.showParentChildren(brand.parent)
            }
        },

        /**
         *
         * @param brandId the rows ID
         */
        scrollToRow(brandId) {
            setTimeout(() => {
                document.getElementById(brandId).querySelector('.name-cell-component')
                    .scrollIntoView({ behavior: "smooth", block: "center"});
            }, 300)
        },

        /**
         *
         * @param phrases {BrandPhrase[]}
         */
        filterPhrases(phrases) {
            return phrases?.filter(phrase => {
                return phrase.q.toLowerCase().includes(this.searchText.toLowerCase())
            })
        }
    }
}
</script>


<style scoped lang="sass">

.brand-name
    padding: 5px
    background-color: #333
    border: 1px solid #222
    cursor: pointer
    display: flex
    flex-direction: column
    gap: 5px

    &:hover
        color: var(--table-row-hover-text-colour)
        background-color: var(--table-row-hover-bg-colour)

.phrase-tags
    ::v-deep .tag
        &:hover
            color: var(--strong-colour-dark)
    ::v-deep .tag-neg
        &:hover
            color: var(--be-colour-negated)
</style>