<template>

    <div  class="ppb-table-component">
        <template v-if="!isLoading">
            <p>
                This account is using the pay-per-brand model. Each brand has its own volume limit and a crowd sampling
                percentage. If topics have been purchased then topic assignment is done on a a percentage of the
                verified emotive mentions.
            </p>


            <div class="ppb-table" :style="{'grid-template-columns': isMashAdmin ? 'repeat(7, 1fr)' : 'repeat(4, 1fr)'}">
                <div class="header brand">Brand</div>
                <div class="header tier">Product Tier</div>
                <div class="header solutions" title="Whether this brand shows Customer Experience or Risk and Reputation">Solutions</div>
                <template v-if="isMashAdmin()">
                    <div class="header volume-limit">Volume Limit</div>
                    <div class="header sampling" title="What percentage of mentions should go to the crowd?">Sampling %</div>
                    <div class="header topic" title="Assign topics to this percentage of emotive mentions">Topic %</div>
                </template>


                <div class="data-row-container" v-for="accBrand in mashRes.brands" :key="accBrand.id" @click="onShowEditTier(accBrand)">

                    <div class="ppb-row brand">
                        {{accBrand.shortName || accBrand.name}}

                        <template v-if="accBrand.importedFromAccount">
                            <SlottedTag no-close>Imported Brand</SlottedTag>
                        </template>
                    </div>


                    <div class="ppb-row tier">
                        <TooltipComponent>
                            {{getTiers[accBrand.tier].label}}
                            <template #tooltip>{{getTiers[accBrand.tier].description}}</template>
                        </TooltipComponent>
                    </div>

                    <div class="ppb-row solutions">
                        <template v-if="getSegmentType(accBrand) === 'hasConduct'">
                            Risk and Reputation
                        </template>
                        <template v-else-if="getSegmentType(accBrand) === 'hasCx'">
                          Customer Experience
                        </template>
                    </div>

                    <template v-if="isMashAdmin()">
                        <div class="ppb-row volume-limit">
                            <template v-if="accBrand.tempVolumeLimit">
                                <TooltipComponent>
                                  <span class="temp-value">{{formatNumber(accBrand.tempVolumeLimit)}} <span class="limit">({{formatNumber(accBrand.volumeLimit)}})</span></span>
                                    <template #tooltip>
                                        {{`${formatNumber(accBrand.tempVolumeLimit)} until end of month then reverts to ${formatNumber(accBrand.volumeLimit)}`}}
                                    </template>
                                </TooltipComponent>
                            </template>
                            <template v-else>
                                {{formatNumber(accBrand.volumeLimit)}}
                            </template>
                        </div>

                        <div class="ppb-row sampling">
                            <template v-if="accBrand.tempCrowdSamplePercentage">
                                <TooltipComponent>
                                    <span class="temp-value">
                                      <DeqPercentage :percent="accBrand.tempCrowdSamplePercentage" :precision="1"/>
                                      <span class="limit"><DeqPercentage :percent="accBrand.crowdSamplePercentage" :precision="1"/></span>
                                    </span>
                                    <template #tooltip>
                                        {{`${formatPercentage(accBrand.tempCrowdSamplePercentage, 1)} until end of month then reverts to ${formatPercentage(accBrand.crowdSamplePercentage, 1)}`}}
                                    </template>
                                </TooltipComponent>
                            </template>
                            <template v-else>
                                <DeqPercentage :percent="accBrand.crowdSamplePercentage" :precision="1" />
                            </template>
                        </div>

                        <div class="ppb-row topic">
                            <DeqPercentage :percent="accBrand.crowdTopicPercentage" :precision="1"/>
                            <template v-if="accBrand.neutralTopics">
                                <TooltipComponent>
                                    <span>(inc neutral)</span>
                                    <template #tooltip>Neutral mentions sent for topics</template>
                                </TooltipComponent>
                            </template>

                        </div>
                    </template>


                </div>
            </div>

            <div class="totals" v-if="isMashAdmin">
              <div class="header">Total volume limit</div>
              <div class="header">Estimated mentions sent to crowd</div>
              <div class="total-data mentions">{{formatNumber(mashRes.mentionsPerMonth)}} per month</div>
              <div class="total-data">{{formatNumber(mashRes.crowdVerifiedMentionsPerMonth)}} per month</div>
            </div>
        </template>

        <template v-else>
            <LoadingMessage :message="'Retrieving your brands'" class="crowd-verification-loader"></LoadingMessage>
        </template>
    </div>

</template>

<script>
import {isMashAdmin} from "@/app/Permissions";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";
import {formatNumber, formatPercentage} from "@/app/utils/Format";
import EditBrandTierDialog from "@/setup/crowd/crowd-settings/edit-brand-tier/EditBrandTierDialog.vue";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import {TIERS} from "@/setup/crowd/crowd-settings/edit-brand-tier/shared";
import {getSegmentList} from "@/app/utils/Segments";
import {mapGetters, mapState} from "vuex";
import LoadingMessage from "@/components/LoadingMessage.vue";
import SlottedTag from "@/components/tags/SlottedTag.vue";
import DeqPercentage from "@/components/formatters/DeqPercentage.vue";
import {mash} from "@/store/Services";

export default {
    name: "CrowdSettingsPayPerBrandTable",
    components: {DeqPercentage, SlottedTag, LoadingMessage, TooltipComponent},

    data() {
        return {
            isLoading: true,
            theRootBrand: undefined,
            mashRes: undefined,
            brands: [],
        }
    },

    computed: {
        ...mapState(['account', 'rootBrands']),
        ...mapGetters(['idToBrand']),
        getTiers() {
            return TIERS
        },
    },

    async mounted() {
        this.isLoading = true;
        await this.initialiseCrowdSettings();
        this.isLoading = false;
    },

    methods: {
      formatPercentage, formatNumber, isMashAdmin,

        async initialiseCrowdSettings() {
            const mashRes = await mash.get(`rest/accounts/${this.account.code}?include=brands&rootBrandsOnly=true`)
            this.mashRes = mashRes.data;
        },

        getSegmentType(brand) {
            if (brand.activeSegmentListIds) {
                return brand.activeSegmentListIds.map(id => {
                    return  getSegmentList(id);
                }).reduce((acc, current) => {
                    if (current.segmentType?.id === "CX_LIST") {
                        return 'hasCx'
                    }
                    if (current.segmentType?.id === "CONDUCT_LIST") {
                        return 'hasConduct'
                    }
                    return 'none'
                })
            } else {
                return 'none';
            }
        },

      onShowEditTier(clickedBrand) {
          if (clickedBrand.importedFromAccount) return
          showDialogComponent(EditBrandTierDialog, {brand: clickedBrand, refresh: this.initialiseCrowdSettings})
      }
    }
}
</script>

<style scoped lang="sass">

.crowd-verification-loader
    margin: auto
    --loading-message-delay: 50ms

.ppb-table
    display: grid
    border: 1px solid black

    .brand
        grid-column-end: span 2
        align-content: center

    .tier, .solutions, .volume-limit, .sampling, .topic
        grid-column-end: span 1
        border-left: 1px solid black
        text-align: center
        align-content: center

    .header, .ppb-row
        padding: 5px

    .header
        background-color: var(--table-header-background-colour)

    .ppb-row
        background-color: var(--table-background-colour)
        border-bottom: 1px solid black

        .temp-value
            color: var(--ac-colour-suspended)

            .limit
                color: var(--be-colour-text-dark)

    .data-row-container
        display: contents

        &:hover
          .ppb-row
            cursor: pointer
            background-color: var(--table-hover-background)

            .ppb-row > .tier, .solutions, .volume-limit, .sampling, .topic
              background-color: var(--table-hover-background)

        &:last-of-type
            .ppb-row
                border-bottom: none

.totals
  margin-top: 10px
  width: 55%
  display: grid
  grid-template-columns: 1fr 1fr
  background-color: var(--table-background-colour)
  border: 1px solid black

  .header
    background-color: var(--table-header-background-colour)
    border-bottom: 1px solid black

  .header, .total-data
    padding: 5px

  .header
    &:first-of-type
      border-right: 1px solid black

  .mentions
      border-right: 1px solid black


</style>