<template>
    <dialog-box class="machine-sentiment-dialog"
                :title="`Edit ${brand.shortName || brand.name} settings`"
                width="500px"
                modal
                @close="emitClose">
        <div class="deq-reset edit-brand-tier-dialog">

            <div class="product-tier">
                <label>Product Tier:</label>
                <button v-for="tier in productTiers" :key="tier.label" @click="onTierSelect(tier)" :class="{'selected-tier': formData.selectedTier === tier.label}">{{tier.label}}</button>
            </div>

            <div class="limits">
                <TextInput v-model="formData.volumeLimit" :label="'Volume Limit:'" :width="'50%'" :rules="validIntegerRule" class="ebtd-input"></TextInput>
                <TextInput v-model="formData.samplingPercentage" :label="'Sampling %:'" :width="'50%'" :rules="validPercentageRule" class="ebtd-input"></TextInput>

                <p class="temp-msg">These apply until the end of the month:</p>
                <TextInput v-model="formData.tempVolumeLimit" :label="'Temp Volume Limit:'" :width="'50%'" :rules="validIntegerRule"  class="ebtd-input"></TextInput>
                <TextInput v-model="formData.tempSamplingPercentage" :label="'Temp Sampling %:'" :width="'50%'" :rules="validPercentageRule" class="ebtd-input"></TextInput>
            </div>

            <div v-if="formData.selectedTier === productTiers.TOPICS.label" class="topics">
                <label>Topic %</label>
                <TextInput v-model="formData.topicPercentage" :width="'50%'" :rules="validPercentageRule" class="ebtd-input"></TextInput>
                <p class="topic-msg">This percentage of emotive mentions are assigned topics</p>

                <div class="neutral-topics">
                    <input v-model="formData.neutralTopics" type="checkbox">
                    <label>Send neutral mentions for topics</label>
                </div>
            </div>

           <div>
               <TextInput v-model="formData.changeReason" :label="'What is the reason for any sampling or limit changes?'"
                          :placeholder="'Reason for sampling or limit change'" :rows="2" :width="'100%'" :class="{error : reasonError.length > 0}">
               </TextInput>
               <p class="reason-error">{{reasonError}}</p>
           </div>

        </div>

        <template #buttons>
            <div class="deq-dialog-buttons">
                <BeButton @click="emitClose" :disabled="isSaving">Cancel</BeButton>
                <BeButton @click="onSaveClick" :disabled="isSaving">
                    Ok
                </BeButton>
            </div>
        </template>
    </dialog-box>

</template>

<script>
import DialogBox from "@/components/DialogBox.vue";
import BeButton from "@/components/buttons/BeButton.vue";
import {TIERS} from "@/setup/crowd/crowd-settings/edit-brand-tier/shared";
import TextInput from "@/components/inputs/TextInput.vue";
import {mapActions, mapState} from "vuex";
import VuexStore from "@/store/vuex/VuexStore";
import {notifyUser} from "@/app/framework/notifications/Notifications";

export default {
    name: "EditBrandTierDialog",
    store: VuexStore,
    props: {
        brand: {},
        refresh: {
            type: Function,
            required: true,
        }
    },
    components: {TextInput, BeButton, DialogBox},

    data() {
        return {
            isSaving: false,

            formData: {
                selectedTier: TIERS[this.brand.tier].label,
                volumeLimit: this.brand.volumeLimit,
                tempVolumeLimit: this.brand.tempVolumeLimit,
                samplingPercentage: this.brand.crowdSamplePercentage,
                tempSamplingPercentage: this.brand.tempSamplingPercentage,
                topicPercentage: this.brand.crowdTopicPercentage,
                neutralTopics: this.brand.neutralTopics,
                changeReason: ''
            },

            isNumberRule: (value) => {
                if (!Number.isFinite(+value)) {
                    return 'Must be valid number'
                }
            },

            /**
             * Assumes value is a valid number
             * @param value {Number}
             * @returns {string}
             */
            percentageRangeRule: (value) => {
                if (+value < 0 || +value > 100) {
                    return 'Value must be between 0 and 100'
                }
            },

            isIntegerRule: (value) => {
                if (!Number.isFinite(+value) || !Number.isInteger(+value) || value.includes('.') || value.includes('.')) {
                    return 'Must be valid number'
                }
            },

            isPositiveValueRule: (value) => {
                if (+value < 0 ) {
                    return 'Must be positive number'
                }
            },

            validPercentageRule: [value => this.isNumberRule(value), value => this.percentageRangeRule(value)],
            validIntegerRule: [value => this.isIntegerRule(value), value => this.isPositiveValueRule(value)],

            reasonError: '',
            isMounted: false,

        }
    },

    computed: {
        ...mapState(['account']),
        productTiers() {
            return TIERS
        },
    },

    watch: {
        formData: {
            handler(){
                const originalData = {
                    selectedTier: TIERS[this.brand.tier].label,
                    volumeLimit: this.brand.volumeLimit,
                    tempVolumeLimit: this.brand.tempVolumeLimit,
                    samplingPercentage: this.brand.crowdSamplePercentage,
                    tempSamplingPercentage: this.brand.tempSamplingPercentage,
                    topicPercentage: this.brand.crowdTopicPercentage,
                    neutralTopics: this.brand.neutralTopics,
                    changeReason: ''
                }

                // requires coercion, original values are numbers, inputs are text
                const changedField = Object.keys(this.formData).find(key => originalData[key] != this.formData[key]);
                if (changedField && !this.formData.changeReason.length) {
                    this.reasonError = 'Must supply a reason for changes'
                } else {
                    this.reasonError = ''
                }
            },
            deep: true
        }
    },

    methods: {
        ...mapActions(['refreshBrands', 'updateBrand']),

        emitClose() {
            this.$emit('close')
        },

        onTierSelect(selectedTier) {
            this.formData.selectedTier = selectedTier.label
        },

        async onSaveClick() {
            if (!this.formData.changeReason.length) return
            try {
                const request = {
                    id: this.brand.id,
                    tier: this.formData.selectedTier.toUpperCase(),
                    volumeLimit: this.formData.volumeLimit,
                    tempVolumeLimit: this.formData.tempVolumeLimit,
                    crowdSamplePercentage: this.formData.samplingPercentage,
                    tempSamplingPercentage: this.formData.tempSamplingPercentage,
                    crowdTopicPercentage: this.formData.topicPercentage,
                    neutralTopics: this.formData.neutralTopics,
                };

                this.isSaving = true;
                await this.updateBrand(request);
                await this.refresh()
                this.emitClose();
                notifyUser(`Successfully updated ${this.brand.shortName || this.brand.name}`)
            } catch (e) {
                console.warn('An error occurred while updating crowd tier settings: ', e);
            } finally {
                this.isSaving = false;
            }

        }
    }
}
</script>

<style scoped lang="sass">

.edit-brand-tier-dialog
    display: flex
    flex-direction: column
    gap: 15px

.product-tier
    label
        margin-bottom: 0
    .selected-tier
        color: #aee15d
        background-color: #606060

    button
        border: none
        outline: none
        padding: 5px
        color: #ffffff
        background-color: #747474

        &:first-of-type
            border-top-left-radius: 3px
            border-bottom-left-radius: 3px

        &:last-of-type
            border-top-right-radius: 3px
            border-bottom-right-radius: 3px

        &:nth-of-type(2)
            border-left: 1px solid #8e8e8e
            border-right: 1px solid #8e8e8e

.limits
    display: grid
    grid-template-columns: 1fr 1fr

    .temp-msg
        margin: 10px 0 5px 0
        grid-column-end: span 2

.topics
    .topic-msg
        margin: 0 0 5px 0

    .neutral-topics
        display: flex
        flex-direction: row
        align-items: center
        gap: 5px

        label
             margin: 0 0 0 0
        input[type="checkbox"]
            margin: 0 0 0 0


.ebtd-input
    ::v-deep input
        padding: 15px

.deq-dialog-buttons
    .be-button
        margin-right: 10px


.reason-error
    color: #ff6663

</style>