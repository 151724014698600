<template>

    <div class="phrase-renderer-component">
        <span v-for="term in terms" :key="term" class="tag" :class="{'tag-neg': term.charAt(0) === '-'}"><span>{{term}}</span></span>
    </div>

</template>

<script>
import {parsePhraseString} from "@/app/utils/Phrases";
import {BrandPhrase} from "@/app/utils/types";

export default {
    name: "PhraseRendererComponent",
    props: {
        phrase: {
            type: BrandPhrase,
            required: true
        }
    },

    data() {
        return {
            terms: []
        }
    },

    mounted() {
        const a = parsePhraseString(this.phrase.q);
        for (let i = 0; i < a.length; i++) {
            this.terms.push(a[i]);
        }
    }
}
</script>

<style scoped lang="sass">

</style>