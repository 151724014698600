import moment from "moment";
import * as b3js from 'brandseyejs';
import {
    get as getSegment,
    getAllCxSegmentLists,
    getAllRiskProductSegmentLists,
    getAllRiskSegments,
    getAllServiceSegments,
} from "@/app/utils/Segments";
import {
    calculateOnlineAve, fantasticDateFormat, fantasticDateImportant,
    filterGetterFactory,
    filterGetterWithIdFactory,
    formatCurrency,
    formatDecimal,
    formatNumber,
    formatPercentage,
    formatPercentageTooltip,
    formatRelevancy,
    getCompareFilterGetter,
    getCompareGetter,
    initialiseRow,
    setterWithIdFactory,
    simpleGetterFactory,
    simpleSetterFactory,
    sumRows,
    tagAndTopicDescriptionGetterFactory,
    tagAndTopicExtender,
    tagAndTopicFilterGetterFactory,
    tagAndTopicPreProcess,
    tagAndTopicRawDataGetterFactory,
    tagAndTopicRawDataSetterFactory,
    tagAndTopicSetterFactory
} from "../FantasticUtilities";

import {countPopulation, createGroupExcluding, createSelectExcluding} from "../FantasticDataHelpers";
import sentimentFields from "./SentimentFields";
import DataSetComparisonFields from "./DataSetComparisonFields";

import {codeToColour, codeToId, getMetatagsInAccount, idToCode, isRisk, isService, Metatag} from "@/app/utils/Metatags";
import {
    appendFiltersReadably,
    getSegmentsInFilter,
} from "@/dashboards/filter/FilterParser";
import responseTimeFields from "@/dashboards/widgets/fantasticchart/fields/responseTimeFields";
import TopicFields from "@/dashboards/widgets/fantasticchart/fields/TopicFields";
import {appendSegmentRestrictions} from "@/app/utils/Segments";
import VuexStore from "@/store/vuex/VuexStore";
import {capitalise, escapeExpression} from "@/app/utils/StringUtils";
import InteractionFields from "@/dashboards/widgets/fantasticchart/fields/InteractionFields";
import SurveyFields from "@/dashboards/widgets/fantasticchart/fields/SurveyFields";
import PublishingMetricFields from "@/dashboards/widgets/fantasticchart/fields/PublishingMetricFields";

/**
 * {
 *     name: "string"           // for display in things like tooltips, menus.
 *     chartName: "string"      // what to call a chart returning this data
 *     grouseAlias: "Array<string>"    // what field to select in grouse
 * }
 *
 */
export const FANTASTIC_FIELDS = {
    ...sentimentFields,
    ...DataSetComparisonFields,
    ...responseTimeFields,
    ...TopicFields,
    ...InteractionFields,
    ...SurveyFields,
    ...PublishingMetricFields,
    "mostRecentMention": {
        extendData: function (d) {
            return {exampleMention: d && d.id || null}
        }
    },
    "mentionCount": {
        tooltipArticle: "a",
        name: "Volume",
        chartName: "Volume",
        yLabel: {long: "Total Mentions", short: "# Mentions"},
        defaultSortOptions: {
            label: "Volume",
            field: "mentionCount",
            order: "descending"
        },
        formatY: formatNumber,
        formatLabel: formatNumber,
        getter: simpleGetterFactory("mentionCount"),
        setter: simpleSetterFactory("mentionCount")
    },
    "mentionPercent": {
        tooltipArticle: "a",
        name: "Percentage Volume",
        chartName: "Percentage of total volume",
        grouseAlias: ["mentionCount"],
        isPercent: true,
        yLabel: {long: "Percentage of Total Mentions", short: "% of Mentions"},
        defaultSortOptions: {
            label: "Volume",
            field: "mentionCount",
            order: "descending"
        },
        formatY: d => formatPercentage(d, 0),
        formatLabel: d => formatPercentage(d, 1),
        formatTooltipY: formatPercentageTooltip,
        getter: simpleGetterFactory("mentionPercent"),
        setter: simpleSetterFactory("mentionPercent")
    },
    "totalEngagement": {
        tooltipArticle: "an",
        name: "Engagement",
        chartName: "Engagement",
        yLabel: "Engagement",
        defaultSortOptions: {
            label: "Engagement",
            field: "totalEngagement",
            order: "descending"
        },
        formatY: formatNumber,
        setter: simpleSetterFactory("totalEngagement"),
        getter: simpleGetterFactory("totalEngagement")
    },
    "totalReshareCount": {
        name: "Reshare count",
        chartName: "Reshares",
        yLabel: {long: "Number of reshares received", short: "Reshares"},
        defaultSortOptions: {
            label: "Reshares",
            field: "totalReshareCount",
            order: "descending"
        },
        formatY: formatNumber,
        setter: simpleSetterFactory("totalReshareCount"),
        getter: simpleGetterFactory("totalReshareCount"),
        extraFilterWhenY: () => "reshareCount > 0"
    },
    "totalNegative": {
        isSentiment: true,
        tooltipArticle: "a",
        name: "Negative Sentiment",
        chartName: "Volume of negative sentiment",
        yLabel: {long: "Total Mentions with Negative Sentiment", short: "# Negative Sentiment"},
        defaultSortOptions: {
            label: "Negative sentiment volume",
            field: "totalNegative",
            order: "descending"
        },
        formatY: formatNumber,
        setter: simpleSetterFactory("totalNegative"),
        getter: simpleGetterFactory("totalNegative"),
        extraFilterWhenY: () => "sentiment <= -1"
    },
    "totalPositive": {
        isSentiment: true,
        tooltipArticle: "a",
        name: "Positive Sentiment",
        chartName: "Volume of positive sentiment",
        yLabel: {long: "Total Mentions with Positive Sentiment", short: "# Positive Sentiment"},
        defaultSortOptions: {
            label: "Positive sentiment volume",
            field: "totalPositive",
            order: "descending"
        },
        formatY: formatNumber,
        setter: simpleSetterFactory("totalPositive"),
        getter: simpleGetterFactory("totalPositive"),
        extraFilterWhenY: () => "Sentiment >= 2"
    },
    "totalNeutral": {
        isSentiment: true,
        tooltipArticle: "a",
        name: "Neutral Sentiment",
        chartName: "Volume of neutral sentiment",
        yLabel: {long: "Total Mentions with Neutral Sentiment", short: "# Neutral Sentiment"},
        defaultSortOptions: {
            label: "Neutral sentiment volume",
            field: "totalNeutral",
            order: "descending"
        },
        formatY: formatNumber,
        setter: simpleSetterFactory("totalNeutral"),
        getter: simpleGetterFactory("totalNeutral"),
        extraFilterWhenY: () => "Sentiment = 1"
    },
    "totalOTS": {
        tooltipArticle: "an",
        name: "OTS",
        chartName: "Opportunities-to-see",
        yLabel: {long: "Opportunities-to-See", short: "OTS"},
        defaultSortOptions: {
            label: "OTS",
            field: "totalOTS",
            order: "descending"
        },
        formatY: formatNumber,
        setter: simpleSetterFactory("totalOTS"),
        getter: simpleGetterFactory("totalOTS"),
        extraFilterWhenY: function (d) {
            return "ots > 0";
        },
    },
    "totalAVE": {
        tooltipArticle: "an",
        tooltipTemplate: require("@/dashboards/widgets/fantasticchart/FantasticCurrencyTip.handlebars"),
        name: "AVE",
        chartName: "Traditional Advert Value Equivalent",
        yLabel: {long: "Traditional Advert Value Equivalent", short: "AVE"},
        defaultSortOptions: {
            label: "AVE",
            field: "totalAVE",
            order: "descending"
        },
        formatY: formatCurrency,
        setter: simpleSetterFactory("totalAVE"),
        getter: simpleGetterFactory("totalAVE"),
        extraFilterWhenY: function (d) {
            return "ave > 0";
        },
    },
    "totalOnlineAVE": {
        tooltipArticle: "an",
        tooltipTemplate: require("@/dashboards/widgets/fantasticchart/FantasticCurrencyTip.handlebars"),
        grouseAlias: ["totalOTS"],
        name: "Online AVE",
        chartName: "Online Advert Value Equivalent",
        yLabel: {long: "Online Advert Value Equivalent", short: "Online AVE"},
        defaultSortOptions: {
            label: "Online AVE",
            field: "totalOnlineAVE",
            order: "descending"
        },
        formatY: formatCurrency,
        setter: simpleSetterFactory("totalOnlineAVE"),
        getter: simpleGetterFactory("totalOnlineAVE"),
        preProcess: function (data, model, footnotes) {
            for (var i = 0; i < data.length; i++) {
                data[i].totalOnlineAVE = calculateOnlineAve(data[i].totalOTS);
            }

            return data;
        },
        extraFilterWhenY: function (d) {
            return "ots > 0";
        },
    },

    "conversationIdCount": {
        tooltipTemplate: require("@/dashboards/widgets/fantasticchart/FantasticCountTip.handlebars"),
        name: "Unique Conversations",
        chartName: "Unique conversations",
        yLabel: {long: "Unique conversations", short: "# conversations"},
        defaultSortOptions: {
            label: "Unique conversations",
            field: "conversationCount",
            order: "descending"
        },
        formatY: formatNumber,
        formatLabel: formatNumber,
        extendData: function (d) {
            return {conversationCount: d}
        },
        getter: simpleGetterFactory("conversationCount"),
        setter: simpleSetterFactory("conversationCount")
    },
    "averageConversationLength": {
        name: "Average conversation length",
        chartName: "Average conversation length",
        yLabel: {long: "Average conversation length", short: "Avg convo length"},
        defaultSortOptions: {
            label: "Average conversation length",
            field: "averageConversationLength",
            order: "descending"
        },
        tooltipArticle: "an",
        grouseAlias: ["conversationIdCount", "mentionCount"],
        formatY: formatNumber,
        formatLabel: formatDecimal,
        tooltipDecimal: 1,
        preProcess: function (data, model, footnotes) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].conversationIdCount === 0) data[i].averageConversationLength = 0;
                else data[i].averageConversationLength = data[i].mentionCount / data[i].conversationIdCount;
            }

            return data;
        },

    },
    "siteCount": {
        grouseAlias: ["site"],
        tooltipTemplate: require("@/dashboards/widgets/fantasticchart/FantasticCountTip.handlebars"),
        name: "Unique Sites",
        chartName: "Unique sites",
        yLabel: {long: "Unique Sites", short: "# Sites"},
        defaultSortOptions: {
            label: "Unique sites",
            field: "siteCount",
            order: "descending"
        },
        formatY: formatNumber,
        formatLabel: formatNumber,
        getter: simpleGetterFactory("siteCount"),
        setter: simpleSetterFactory("siteCount")
    },
    "languageCount": {
        grouseAlias: ["language"],
        tooltipTemplate: require("@/dashboards/widgets/fantasticchart/FantasticCountTip.handlebars"),
        name: "Unique Languages",
        chartName: "Unique languages",
        yLabel: {long: "Unique Languages", short: "# Languages"},
        defaultSortOptions: {
            label: "Unique languages",
            field: "languageCount",
            order: "descending"
        },
        formatY: formatNumber,
        formatLabel: formatNumber,
        getter: simpleGetterFactory("languageCount"),
        setter: simpleSetterFactory("languageCount")
    },
    "countryCount": {
        grouseAlias: ["country"],
        tooltipTemplate: require("@/dashboards/widgets/fantasticchart/FantasticCountTip.handlebars"),
        name: "Unique Countries",
        chartName: "Unique countries",
        yLabel: {long: "Unique Countries", short: "# Countries"},
        defaultSortOptions: {
            label: "Unique countries",
            field: "countryCount",
            order: "descending"
        },
        formatY: formatNumber,
        formatLabel: formatNumber,
        getter: simpleGetterFactory("countryCount"),
        setter: simpleSetterFactory("countryCount")
    },
    "authorIdCount": {
        grouseAlias: ["authorId"],
        tooltipTemplate: require("@/dashboards/widgets/fantasticchart/FantasticCountTip.handlebars"),
        name: "Unique Authors",
        chartName: "Number of unique authors",
        yLabel: {long: "Unique Authors", short: "# Authors"},
        defaultSortOptions: {
            label: "Unique authors",
            field: "authorIdCount",
            order: "descending"
        },
        formatY: formatNumber,
        formatLabel: formatNumber,
        getter: simpleGetterFactory("authorIdCount"),
        setter: simpleSetterFactory("authorIdCount")
    },
    "brand": {
        scaleX: b3js.scaleDiscrete,
        noLimit: true,
        noSelect: true,
        hideUnknown: true,
        getter: function (d) {
            return d._brand && d._brand.shortName || d["brand.name"] || "Unknown";
        },
        rawDataGetter: function (d) {
            return d._brand;
        },
        rawDataSetter: function (d, data) {
            return d._brand = data;
        },
        setter: setterWithIdFactory("brand"),
        filterGetter: filterGetterWithIdFactory("brand"),
        extendData: function (d) {
            if (!d) return {"brand.id": "NA", "brand.name": "Unknown"};
            return {"brand.id": d.id, "brand.name": d.fullName || d.name, "_brand": d}
        },
        defaultSortOptions: {
            label: "Brand order in filter",
            field: "_brand.index",
            order: "ascending"
        },
        sorter: function (lhs, rhs, order) {
            order ??= "ascending";

            if (lhs._brand && rhs._brand) {
                return order === "ascending" ? lhs._brand.index - rhs._brand.index : rhs._brand.index - lhs._brand.index;
            }

            var lhsX = (lhs["brand.name"] || "").toLowerCase();
            var rhsX = (rhs["brand.name"] || "").toLowerCase();

            if (order === "ascending") {
                if (lhsX < rhsX) return -1;
                if (rhsX < lhsX) return 1;
            } else {
                if (lhsX > rhsX) return -1;
                if (rhsX > lhsX) return 1;
            }

            return 0;
        },
        colourFromX: function (d, defaultColour) {
            if (d._brand && d._brand.colour) return d._brand.colour;
            return defaultColour || b3js.colours.eighteen.sentiment.neutral
        }
    },
    "language": {
        noLimit: true,
        getter: function (d) {
            return d["language.name"];
        },
        setter: setterWithIdFactory("language"),
        filterGetter: filterGetterWithIdFactory("language"),
        extendData: function (d) {
            if (!d) return {"language.id": 'NA', "language.name": "Unknown"};
            return {"language.id": d.id, "language.name": d.name}
        }
    },
    "country": {
        tooltipPreposition: "from",
        noLimit: true,
        getter: function (d) {
            return d["country.name"];
        },
        setter: setterWithIdFactory("country"),
        filterGetter: function (d) {
            return d["country.id"];
        },
        extendData: function (d) {
            if (!d) return {"country.id": 'UN', "country.name": "Unknown"};
            return {"country.id": d.id, "country.name": d.name}
        }
    },
    "ticketId": {
        name: "Engage ticket",
        scaleX: b3js.scaleDiscrete,
        noLimit: true,
        getter: function (d) {
            return d["ticketId"];
        },
        setter: simpleSetterFactory("ticketId"),
        filterGetter: function (d) {
            return d["ticketId"];
        },
        formatX: function (d) {
            return d || "No ticket"
        },
        formatTooltipX: function (ticket) {
            if (!ticket) return "no ticket";
            return "ticket " + ticket;
        },
        menuOptions: function (ticket) {
            return [
                {
                    text: "Open in Engage",
                    tooltip: "Open this ticket directly in Engage, in another tab",
                    method: function (ticket) {
                        window.open("https://engage.dataeq.com/private/v2/account/" + VuexStore.state.account.code + "/ticket/" + ticket, true);
                    },
                    disabled: !ticket
                }
            ]
        }
    },
    "ticketIdCount": {
        name: "Active Engage Tickets",
        chartName: "Active Engage Tickets",
        tooltipTemplate: require("@/dashboards/widgets/fantasticchart/FantasticCountTip.handlebars"),
        yLabel: {long: "Active Engage Tickets", short: "# active tickets"},
        formatY: formatNumber,
        formatLabel: formatNumber,
        extendData: function (d) {
            return {ticketIdCount: d}
        },
        defaultSortOptions: {
            label: "Active Engage tickets",
            field: "ticketIdCount",
            order: "descending"
        },
        getter: simpleGetterFactory("ticketIdCount"),
        setter: simpleSetterFactory("ticketIdCount"),
        extraFilterWhenY: function () {
            return "ticketId isnt unknown";
        }
    },
    "region": {
        tooltipPreposition: "from",
        noLimit: true,
        getter: function (d) {
            return d["region.name"];
        },
        setter: setterWithIdFactory("region"),
        filterGetter: function (d) {
            if (d['region.id'] === "NA") return "UN";
            var country = d['region.id'].split('.')[0];
            var region = d['region.name'];
            return country + "," + region;
        },
        extendData: function (d) {
            if (!d) return {"region.id": 'NA', "region.name": "Unknown"};
            return {"region.id": d.id, "region.name": d.name}
        }
    },
    "city": {
        tooltipPreposition: "from",
        getter: function (d) {
            return d["city.name"];
        },
        setter: setterWithIdFactory("city"),
        filterGetter: function (d) {
            if (d['city.id'] === "NA") return "UN";
            var country = d['region.id']?.split('.')[0];
            var region = d['region.name'];
            var city = d['city.name'];
            return country + "," + region + "," + city;
        },
        extendData: function (d) {
            if (!d) return {
                "city.id": 'NA', "city.name": "Unknown",
                "region.id": 'NA', "region.name": "Unknown"
            };

            var region = d.region || {"id": 'NA', "name": "Unknown"};
            return {
                "city.id": d.id, "city.name": d.name,
                "region.id": region.id, "region.name": region.name
            }
        }
    },
    "socialNetwork": {
        name: "Social Network",
        noLimit: true,
        getter: function (d) {
            return d["socialnetwork.name"];
        },
        setter: setterWithIdFactory("socialnetwork"),
        filterGetter: filterGetterWithIdFactory("socialnetwork"),
        tooltipPreposition: "from",
        extendData: function (d) {
            if (!d) return {"socialnetwork.id": 'NA', "socialnetwork.name": "Websites"};
            return {"socialnetwork.id": d.id, "socialnetwork.name": d.label}
        },
        colourFromX: function (d, defaultColour) {
            if (!d['socialnetwork.id']) return "#c0c0c0";
            const code = d['socialnetwork.id'];

            return getComputedStyle(document.documentElement)
                .getPropertyValue('--colour-' + code.toLowerCase())
                .trim();
        }
    },
    "tag": {
        tooltipPrepositionPercent: 'tagged with',
        tooltipComparePreposition: 'tagged with',
        tooltipMentionsWithVerb: 'with tag',
        isMultiple: true,
        hideUnknown: true,
        noLimit: true,
        isTag: true,
        getter: function (d, compare) {
            var field = !compare ? "tag.name" : "tag2.name";
            return d[field];
        },
        setter: tagAndTopicSetterFactory("tag"),
        filterGetter: tagAndTopicFilterGetterFactory("tag"),
        rawDataGetter: tagAndTopicRawDataGetterFactory("tag"),
        rawDataSetter: tagAndTopicRawDataSetterFactory("tag"),
        extendData: tagAndTopicExtender,
        preProcess: tagAndTopicPreProcess,
        descriptionGetter: tagAndTopicDescriptionGetterFactory("tag"),
        scaleX: b3js.scaleDiscrete
    },
    "engageusertag": {
        tooltipPrepositionPercent: 'tagged with',
        tooltipComparePreposition: 'tagged with',
        tooltipMentionsWithVerb: 'with tag',
        isMultiple: true,
        hideUnknown: true,
        noLimit: true,
        isTag: true,
        tagNamespace: "engageusertags",
        grouseAlias: ["tag"],
        getter: simpleGetterFactory("engageusertag.name"),
        setter: tagAndTopicSetterFactory("engageusertag"),
        filterGetter: tagAndTopicFilterGetterFactory("engageusertag"),
        filterField: "tag",
        rawDataGetter: tagAndTopicRawDataGetterFactory("engageusertag"),
        rawDataSetter: tagAndTopicRawDataSetterFactory("engageusertag"),
        extendData: function (d) {
            if (d.namespace === "engageusertags") {
                return {
                    "engageusertag.id": d.id,
                    "engageusertag.name": d.name,
                    "engageusertag.namespace": d.namespace
                }
            }

            return d;
        },
        preProcess: function (data, model) {
            let compare = model.get("compare");

            // temporary solution:
            // when grouping by this field and comparing it with another isTag field,'
            // we need to swap tag and tag2 - tag2 should be the compare field, but for some reason it ends up being the tag field
            if (compare && compare !== "engageusertag") {
                data.forEach(d => {
                    if (d.tag && d.tag2) {
                        let clone = JSON.parse(JSON.stringify(d));

                        d.tag = clone.tag2;
                        d.tag2 = clone.tag;
                    }
                });
                return data;
            }

            return data;
        },
        descriptionGetter: tagAndTopicDescriptionGetterFactory("engageusertag"),
        scaleX: b3js.scaleDiscrete,
        async getData(model, query, groupBy, ignore, ignoreY, getData) {
            await VuexStore.dispatch('refreshTags');
            let tags = VuexStore.state.tags;

            let engageUserTags = [];
            let engageUserTagPartitions = [];
            const PARTITION_SIZE = 50;

            for (const tag of tags) {
                if (tag.namespace === "engageusertags") {
                    engageUserTags.push(tag);
                }
            }

            for (let i = 0; i < engageUserTags.length; i += PARTITION_SIZE) {
                const curPartition = engageUserTags.slice(i, i + PARTITION_SIZE);
                engageUserTagPartitions.push(curPartition);
            }

            let data = [];
            let requests = [];

            for (const filterPartition of engageUserTagPartitions) {
                let engageTagsFilter = `Tag IS ${filterPartition.map(t => t.id).join(" OR TAG IS ")}`;
                let filter = `(${query.filter}) and (${engageTagsFilter})`;
                let q = Object.assign({}, query, {
                    filter: filter,
                    groupBy: groupBy.join(","),
                    select: query.select
                });

                requests.push(getData(q, groupBy, ignore, ignoreY));
            }

            let results = await Promise.all(requests);
            results.forEach(result => {
                data = [...data, ...result];
            });

            return data;
        }
    },
    "engageagenttag": {
        tooltipPrepositionPercent: 'tagged with',
        tooltipComparePreposition: 'tagged with',
        tooltipMentionsWithVerb: 'with tag',
        isMultiple: true,
        hideUnknown: true,
        noLimit: true,
        isTag: true,
        tagNamespace: "engageagenttag",
        grouseAlias: ["tag"],
        getter: function (d){
            let val = simpleGetterFactory("engageagenttag.name")(d);
            return val.replace("Engage user: ","").replace(/\s\(\d+\)/g,"");
        },
        setter: tagAndTopicSetterFactory("engageagenttag"),
        filterGetter: tagAndTopicFilterGetterFactory("engageagenttag"),
        filterField: "tag",
        rawDataGetter: tagAndTopicRawDataGetterFactory("engageagenttag"),
        rawDataSetter: tagAndTopicRawDataSetterFactory("engageagenttag"),
        extendData: function (d) {
            if (d.namespace === "engageagenttag") {
                return {
                    "engageagenttag.id": d.id,
                    "engageagenttag.name": d.name,
                    "engageagenttag.namespace": d.namespace
                }
            }

            return d;
        },
        preProcess: function (data, model) {
            let compare = model.get("compare");

            // temporary solution:
            // when grouping by this field and comparing it with another isTag field,'
            // we need to swap tag and tag2 - tag2 should be the compare field, but for some reason it ends up being the tag field
            if (compare && compare !== "engageagenttag") {
                data.forEach(d => {
                    if (d.tag && d.tag2) {
                        let clone = JSON.parse(JSON.stringify(d));

                        d.tag = clone.tag2;
                        d.tag2 = clone.tag;
                    }
                });
                return data;
            }

            return data;
        },
        descriptionGetter: tagAndTopicDescriptionGetterFactory("engageagenttag"),
        scaleX: b3js.scaleDiscrete,
        async getData(model, query, groupBy, ignore, ignoreY, getData) {
            let q = Object.assign({}, query, {
                filter: appendFiltersReadably(query.filter, "Tag IS 201"),
                groupBy: groupBy.join(","),
                select: query.select
            });
            return await getData(q, groupBy, ignore, ignoreY);
        }
    },
    "tag2": { // This field occurs when grouping tag with itself, as in asking grouse for groupBy=tag,tag
        extendData: tagAndTopicExtender
    },
    "risk": createRpcsField("risk", isRisk, getAllRiskSegments),
    "service": createRpcsField("service", isService, getAllServiceSegments),
    "priority": {
        name: "High vs low priority",
        tooltipPreposition: " ", // remove the 'for'
        descriptionGetter: tagAndTopicDescriptionGetterFactory("priority"),
        scaleX: b3js.scaleDiscrete,
        isMultiple: true,
        noMaxItems: true,
        hideUnknown: true,
        hideOthers: true,
        tagNamespace: "segment,BrandsEye",
        grouseAlias: ["tag"],
        calculateMoe: true,
        extendData: function (d) {
            const label = `${capitalise(d.id)} priority`;
            const metatags = getMetatagsInAccount();
            let description = "";
            switch(d.id) {
                case 'high':
                    description =  metatags.length === 4
                        ? "High priority mentions are those that relate to your risk factors, customer acquisition and retention, and service queries"
                        : "High priority mentions are those that relate to customer acquisition and retention, and to service queries";
                    break;
                case 'low':
                    description = metatags.length === 4
                        ? "Low priority mentions are unrelated to any risk factors, or to customer acquisition and retention or service queries"
                        : "Low priority mentions are unrelated to either customer acquisition and retention and to service queries";
                    break;
            }
            return {
                "priority.id": d.id,
                "priority.name": label,
                "priority.description": description,
                "_priority": d
            };
        },
        postProcess: function (data, model, footnotes) {
            if (data.extra.cxPresent && data.extra.conductPresent) {
                footnotes?.push("Calculated from mentioned verified for Risk and Reputation, as well as Customer Experience")
            } else if (data.extra.cxPresent) {
                footnotes?.push("Calculated from mentions verified for Customer Experience");
            } else if (data.extra.conductPresent) {
                footnotes?.push("Calculated from mentions verified for Risk and Reputation");
            }
            return data;
        },
        getter: simpleGetterFactory("priority.id"),
        rawDataGetter(d) {return d._priority;},
        rawDataSetter(d, data) {return d._priority = data;},
        formatX(d) {return `${capitalise(d || 'Unknown')} priority`},
        defaultSortOptions: {
            label: "Priority",
            field: "priority.id",
            order: "ascending"
        },
        sorter: function (lhs, rhs, order) {
            order ??= "ascending";
            return order === "ascending" ? (lhs['priority.id'] || '1').localeCompare((rhs['priority.id'] || '1')) : (rhs['priority.id'] || '1').localeCompare((lhs['priority.id'] || '1'));
        },
        filterGetter() { return null },
        extraFilter(d) {
            if (d._priority && d._priority.filter) return d._priority.filter;
            throw new Error("Unable to find filter for high/low priority metric")
        },
        async getData(model, query, groupBy, ignore, ignoreY, getData) {
            const fields = FANTASTIC_FIELDS;
            const xAxis = model.get("xAxis") || "published";
            const xField = fields[xAxis] || {};
            const compare = model.get("compare") || "published";
            const compareField = compare && fields[compare] || {};

            const groupTags = xField.isTag || compareField.isTag;
            const g = createGroupExcluding(groupBy, ignore, "tag");
            const s = createSelectExcluding(query, ignore, !groupTags ? "tag" : null).join(',');
            if (groupTags) g.push("tag");

            const metatags = getMetatagsInAccount();
            const cx = await getAllCxSegmentLists();
            const conduct = await getAllRiskProductSegmentLists();

            const nonCxOrRisk = (cx || [])
                .concat(conduct || [])
                .flatMap(tag => tag.children ?? [])
                .map(id => VuexStore.getters.idToTag.get(id))
                .filter(tag => tag && tag.flag === "NONE_OF_THE_ABOVE");
            const nonCxOrRiskFilter = nonCxOrRisk.map(tag => `segment is ${tag.id}`).join(' or ');

            let highPriorityFilter = `${metatags.map(m => `tag is ${m.id}`).join(' or ')}`;
            highPriorityFilter = appendSegmentRestrictions(highPriorityFilter);
            let lowPriorityFilter = `(${metatags.map(m => `tag isnt ${m.id}`).join(' and ')}) and (${nonCxOrRiskFilter})`;
            lowPriorityFilter = appendSegmentRestrictions(lowPriorityFilter);

            const [population, high, low] = await Promise
                .all([
                    countPopulation(model, query),
                    ...([{id: 'high', filter: highPriorityFilter}, {id: 'low', filter: lowPriorityFilter}]
                        .map(filterInfo => {
                            const q = Object.assign({}, query, {
                                filter: `(${query.filter}) and (${filterInfo.filter} )`,
                                groupBy: g.join(','),
                                select: s
                            });

                            if (groupTags) q.tagNamespace = compareField.isTag ? compareField.tagNamespace : xField.tagNamespace;
                            if (!g.length) delete q.groupBy;

                            return getData(q, g, ignore, ignoreY)
                                .then(d => Array.isArray(d) ? d : [d])
                                .then(d => d.map(r => Object.assign({priority: filterInfo}, r)))
                                .then(d => d.length ? d : [initialiseRow(model, {priority: filterInfo})])
                        }))

                ]);

            const data = [...high, ...low].flat();
            data.extra = Object.assign({
                populationSize: population.mentionCount,
                cxPresent: !!(cx && cx.length),
                conductPresent: !!(conduct && conduct.length)
            });

            return data;
        }
    },
    "rpcs": {
        name: "Priority categorisation",
        scaleX: b3js.scaleDiscrete,
        tooltipPreposition: "tagged with",
        formatTooltipX: function (rpcsId) {
            return new Handlebars.SafeString("<be-rpcs-icon code='" + idToCode(rpcsId) + "' full></be-rpcs-icon>");
        },
        isMultiple: true,
        calculateMoe: true,
        noMaxItems: true,
        hideUnknown: true,
        hideOthers: true,
        noLimit: true,
        tagNamespace: "segment,BrandsEye",
        grouseAlias: ["tag"],
        setter: function (d, id, value) {
            var rpcs = new Metatag(idToCode(id));
            d["rpcs.id"] = id;
            d["rpcs.name"] = rpcs.label;
            d["rpcs.description"] = rpcs.opportunity;
            d["_rpcs"] = rpcs;
            return d;
        },
        getter: simpleGetterFactory("rpcs.id"),
        filterGetter: filterGetterWithIdFactory("rpcs"),
        filterField: "tag",
        rawDataGetter: function (d) {
            return d._rpcs;
        },
        rawDataSetter: function (d, data) {
            return d._rpcs = data;
        },
        descriptionGetter: tagAndTopicDescriptionGetterFactory("rpcs"),
        formatX: function (d) {
            switch (d) {
                case 1:
                    return "Risk";
                case 2:
                    return "Purchase";
                case 3:
                    return "Cancel";
                case 4:
                    return "Service";
                default:
                    return "Unknown " + d;
            }
        },
        colourFromX: function (d, defaultColour) {
            var id = d['rpcs.id'];
            if (id) return codeToColour(idToCode(id)) || defaultColour;
            return defaultColour;
        },
        legendColours: {
            "Risk": codeToColour("RISK"),
            "Purchase": codeToColour("PURCHASE"),
            "Cancel": codeToColour("CANCEL"),
            "Service": codeToColour("SERVICE")
        },
        extendData: function (d) {
            if (d >= 1 && d <= 4) {
                const tag = new Metatag(idToCode(d));
                if (tag) {
                    return {
                        "rpcs.id": tag.id,
                        "rpcs.name": tag.label,
                        "rpcs.description": tag.opportunity || "TODO",
                        "_rpcs": tag
                    }
                }
            }
            return {
                "rpcs.id": d.id,
                "rpcs.name": d.label,
                "rpcs.description": d.opportunity || "TODO",
                "_rpcs": d
            };
        },
        defaultSortOptions: {
            label: "RPCS priority",
            field: "rpcs.id",
            order: "ascending"
        },
        sorter: function (lhs, rhs, order) {
            order ??= "ascending";
            return order === "ascending" ? (lhs['rpcs.id'] || 0) - (rhs['rpcs.id'] || 0) : (rhs['rpcs.id'] || 0) - (lhs['rpcs.id'] || 0);
        },
        async getData(model, query, groupBy, ignore, ignoreY, getData) {
            const fields = FANTASTIC_FIELDS;
            const xAxis = model.get("xAxis") || "published";
            const xField = fields[xAxis] || {};
            const compare = model.get("compare") || "published";
            const compareField = compare && fields[compare] || {};

            // RPCS tags have overlap, so to avoid duplicate counts
            // we need to make multiple calls to the API, one for each RPCS tag.
            const groupTags = xField.isTag || compareField.isTag;
            const g = createGroupExcluding(groupBy, ignore, "tag");
            const s = createSelectExcluding(query, ignore, !groupTags ? "tag" : null).join(',');
            if (groupTags) g.push("tag");

            return Promise
                .all([
                    countPopulation(model, query),
                    ...(getMetatagsInAccount()
                        .map(metatag => {
                            let filter = "(" + query.filter + ") and tag is " + metatag.id;
                            filter = appendSegmentRestrictions(filter);

                            const q = Object.assign({}, query, {
                                filter: filter,
                                groupBy: g.join(','),
                                select: s
                            });

                            if (groupTags) q.tagNamespace = compareField.isTag ? compareField.tagNamespace : xField.tagNamespace;
                            if (!g.length) delete q.groupBy;

                            return getData(q, g, ignore, ignoreY)
                                .then(d => Array.isArray(d) ? d : [d])
                                .then(d => d.map(r => Object.assign({rpcs: metatag}, r)))
                                .then(d => d.length ? d : [initialiseRow(model, {rpcs: metatag})])
                        }))

                ])
                .then(results => {
                    const data = results.slice(1).flat();
                    data.extra = { populationSize: results[0].mentionCount};
                    return data;
                });
        }

    },
    "category": {
        noLimit: true,
        getter: function (d) {
            return d["category.name"];
        },
        setter: setterWithIdFactory("category"),
        filterGetter: filterGetterWithIdFactory("category"),
        extendData: function (d) {
            if (!d) return {"category.id": 'NA', "category.name": "Unknown"};
            return {"category.id": d.id, "category.name": d.label}
        }
    },
    "feed": {
        noLimit: true,
        getter: function (d) {
            return d["feed.name"];
        },
        setter: setterWithIdFactory("feed"),
        filterGetter: filterGetterWithIdFactory("feed"),
        extendData: function (d) {
            if (!d) return {"feed.id": 'NA', "feed.name": "Unknown"};
            return {"feed.id": d.id, "feed.name": d.description}
        }
    },
    "gender": {
        noLimit: true,
        getter: function (d) {
            return d["gender.name"];
        },
        setter: setterWithIdFactory("gender"),
        filterGetter: filterGetterWithIdFactory("gender"),
        extendData: function (d) {
            if (!d) return {"gender.id": 'NA', "gender.name": "Unknown"};
            return {"gender.id": d.id, "gender.name": d.label}
        }
    },
    "phrase": {
        isMultiple: true,
        getter: function (d) {
            return d["phrase"];
        },
        setter: function (d, id, value) {
            d["phrase.id"] = id;
            d["phrase"] = value;
        },
        filterGetter: filterGetterWithIdFactory("phrase"),
        extendData: function (d) {
            if (!d) return {"phrase.id": 'NA', "phrase": "NA"};
            return {"phrase.id": d.id, "phrase": d.q}
        }
    },
    "race": {
        noLimit: true,
        getter: function (d) {
            return d["race.name"];
        },
        setter: setterWithIdFactory("race"),
        filterGetter: filterGetterWithIdFactory("race"),
        extendData: function (d) {
            if (!d) return {"race.id": 'NA', "race.name": "Unknown"};
            return {"race.id": d.id, "race.name": d.label}
        }
    },
    "fullAuthor": {
        name: "Author",
        scaleX: b3js.scaleDiscrete,
        filterGetter: function (d) {
            const id = d.authorId;
            if (!id) return null;

            let name = d.authorName;
            let handle = d.authorHandle;
            let site = d.site;

            if (name && name.toLowerCase() === "unknown") name = null;
            if (handle && handle.toLowerCase() === "unknown") handle = null;

            if (name && handle) return handle + " (" + name + ") " + id;
            if (name || handle) return (name || handle) + " " + id;
            if (site) {
                switch (site) {
                    case "www.facebook.com":
                        return "Anonymised FB authors " + id;
                    case "instagram.com":
                        return "Anonymised IG authors " + id;
                    case "twitter.com":
                        return "Anonymised twitter authors " + id;
                    default:
                        return site + " " + id;
                }
            }

            return id;
        },
        extraFilter: function (d) {
            const value = FANTASTIC_FIELDS.fullAuthor.filterGetter(d);
            return value === null && d.site ? "site is '" + d.site + "'" : null;
        },
        getter: function (d) {
            if (!d.authorId && d.site) {
                switch (d.site) {
                    case "facebook.com":
                    case "www.facebook.com":
                        return "Anonymised FB authors";
                    case "instagram.com":
                    case "www.instagram.com":
                        return "Anonymised IG authors";
                    case "twitter.com":
                        return "Anonymised twitter authors";
                }
            }

            let name = d.authorName;
            let handle = d.authorHandle;
            let site = d.site;

            if (handle && handle.toLowerCase() !== "unknown") {
                if (site === "twitter.com" || site === "instagram.com") handle = "@" + handle;
            }

            if (d.duplicate && name && handle && name.toLowerCase() !== "unknown" && handle.toLowerCase() !== "unknown") {
                return name + " (" + d.authorId + ")";
            }
            if (name && name.toLowerCase() !== "unknown") return name;
            if (handle && handle.toLowerCase() !== "unknown") return handle;
            if (site && site.toLowerCase() !== "unknown") return site;
            return d.authorId;
        },
        tooltipGetter: function (d) {
            let name = d.authorName;
            let handle = d.authorHandle;
            let site = d.site;
            let authorId = d.authorId;

            if (!authorId && site) {
                switch (d.site) {
                    case "www.facebook.com":
                    case "facebook.com":
                    case "instagram.com":
                    case "www.instagram.com":
                    case "twitter.com":
                        return FANTASTIC_FIELDS.fullAuthor.getter(d);
                }

                return site;
            }

            let icon = "";
            let network = "";
            switch (site) {
                case "facebook.com":
                case "www.facebook.com":
                    network = "Facebook";
                    icon = "<i class='symbol-facebook-rect'></i>";
                    break;
                case "instagram.com":
                case "www.instagram.com":
                    network = "Instagram";
                    icon = "<i class='symbol-instagram'></i>";
                    break;
                case "twitter.com":
                    network = "Twitter";
                    icon = "<i class='symbol-twitter'></i>";
                    break;
            }

            let hasHandle = handle && handle.toLowerCase() !== "unknown";
            let hasName = name && name.toLowerCase() !== "unknown";

            if (hasHandle) {
                if (site === "twitter.com" || site === "instagram.com" || site === "www.instagram.com") handle = "@" + handle;
                handle = escapeExpression(handle);
            }

            if (hasName) {
                name = escapeExpression(name);
            }

            if (network) {
                if (hasHandle && hasName) {
                    return new Handlebars.SafeString(name + " (" + handle + " on" + icon + network + ")");
                }

                if (icon && hasName) {
                    return new Handlebars.SafeString(name + " (on " + icon + network + ")");
                }

                if (icon && hasHandle) {
                    return new Handlebars.SafeString(handle + " on " + icon + network);
                }
            }

            return FANTASTIC_FIELDS.fullAuthor.getter(d);
        },
        grouseAlias: ["authorId", "authorName", "authorHandle", "site"],
        setter: function (d, id, value) {
            d.authorId = value
        },
        preProcess: function (data, model, footnotes) {
            // Authors may have changed their name or handle, but their author id may remain the same.
            // We want to make sure that we group all of these up into the same author.
            const results = [];
            const idToIndex = new Map();
            const nameToIndex = new Map();
            const compare = model.get("compare") || "comp";
            data.forEach(function (row, index) {
                var idKey = (row[compare] && (row[compare].id || row[compare].label || row[compare])) + ":" + row.authorId;
                if (!idToIndex.has(idKey)) {
                    results.push(row);
                    idToIndex.set(idKey, results.length - 1);
                } else {
                    sumRows(model, results[idToIndex.get(idKey)], row);
                }

                var nameKey = (row[compare] && (row[compare].id || row[compare].label || row[compare])) + ":" +
                    (row.authorName && row.authorName.toLowerCase() || row.authorName);
                if (nameToIndex.has(nameKey)) {
                    data[nameToIndex.get(nameKey)].duplicate = true;
                    row.duplicate = true;
                } else {
                    nameToIndex.set(nameKey, index);
                }

            });
            return results;
        },
        extendData: function (d) {
            if (!d) return {"authorId": "Unknown"};
            return {"authorId": d};
        }
    },
    "authorName": {
        name: "Author name",
        scaleX: b3js.scaleDiscrete,
        filterGetter: filterGetterFactory("authorName"),
        getter: simpleGetterFactory("authorName"),
        setter: function (d, id, value) {
            d.authorName = value
        },
        extendData: function (d) {
            if (!d) return {"authorName": "Unknown"};
            return {"authorName": d};
        }
    },
    "authorHandle": {
        name: "Author handle",
        scaleX: b3js.scaleDiscrete,
        filterGetter: filterGetterFactory("authorHandle"),
        getter: simpleGetterFactory("authorHandle"),
        setter: function (d, id, value) {
            d.authorHandle = value
        },
        extendData: function (d) {
            if (!d) return {"authorHandle": "Unknown"};
            return {"authorHandle": d};
        }

    },
    "site": {
        name: "Site",
        scaleX: b3js.scaleDiscrete,
        filterGetter: filterGetterFactory("site"),
        getter: simpleGetterFactory("site"),
        setter: function (d, id, value) {
            d.site = value
        }
    },

    "published": {
        isDate: true,
        noMaxItems: true,
        noRemoveData: true,
        noLimit: true,
        hideOthers: true,
        hideUnknown: true,
        getter: simpleGetterFactory("published"),
        filterGetter: filterGetterFactory("published"),
        setter: simpleSetterFactory("published"),
        formatX: fantasticDateFormat,
        importance: fantasticDateImportant,
        filterData: function (data, model, extraFootnotes) {
            if (!model.get('hideFuture')) return data;

            var tomorrow = moment().add(1, 'day').startOf('day');
            return data.filter(function (d) {
                var date = moment(d.published);
                return date.isBefore(tomorrow);
            });
        },
        defaultSortOptions: {
            label: "Published date",
            field: "published",
            order: "ascending"
        },
        sorter: function (lhs, rhs, order) {
            order ??= "ascending";

            var lhsX = moment(lhs.published);
            var rhsX = moment(rhs.published);

            if (order === "ascending") {
                if (lhsX.isBefore(rhsX)) return -1;
                if (rhsX.isBefore(lhsX)) return 1;
            } else {
                if (rhsX.isBefore(lhsX)) return -1;
                if (lhsX.isBefore(rhsX)) return 1;
            }

            return 0;
        }
    },
    "pickedUp": {
        isDate: true,
        noSelect: true,
        noMaxItems: true,
        noRemoveData: true,
        noLimit: true,
        hideOthers: true,
        hideUnknown: true,
        getter: simpleGetterFactory("pickedUp"),
        filterGetter: filterGetterFactory("pickedUp"),
        setter: simpleSetterFactory("pickedUp"),
        formatX: fantasticDateFormat,
        importance: fantasticDateImportant,
        filterData: function (data, model, extraFootnotes) {
            if (!model.get('hideFuture')) return data;

            var tomorrow = moment().add(1, 'day').startOf('day');
            return data.filter(function (d) {
                var date = moment(d.pickedUp);
                return date.isBefore(tomorrow);
            });
        },
        defaultSortOptions: {
            label: "Picked up date",
            field: "pickedUp",
            order: "ascending"
        },
        sorter: function (lhs, rhs, order) {
            order ??= "ascending";

            var lhsX = moment(lhs.pickedUp);
            var rhsX = moment(rhs.pickedUp);

            if (order === "ascending") {
                if (lhsX.isBefore(rhsX)) return -1;
                if (rhsX.isBefore(lhsX)) return 1;
            } else {
                if (rhsX.isBefore(lhsX)) return -1;
                if (lhsX.isBefore(rhsX)) return 1;
            }

            return 0;
        }
    },
    "timeOfDay": {
        name: "Time of day",
        noRemoveData: true,
        noMaxItems: true,
        grouseAlias: ["published[HOUR_OF_DAY]"],
        noSelect: true,
        noLimit: true,
        hideOthers: true,
        hideUnknown: true,
        getter: simpleGetterFactory("timeOfDay"),
        filterGetter: filterGetterFactory("timeOfDay"),
        setter: simpleSetterFactory("timeOfDay"),
        scaleX: b3js.scaleDiscrete,
        tooltipPreposition: "at",
        filterField: "publishedTime",
        formatX: function (d) {
            if (d) d += ":00";
            return d;
        },
        preProcess: function (data, model) {
            data.forEach(function (row) {
                row.timeOfDay = row.published;
                delete row.published;
            });

            return data;
        },
        defaultSortOptions: {
            label: "Time of day",
            field: "timeOfDay",
            order: "ascending"
        },
        sorter: function (lhs, rhs, order) {
            order ??= "ascending";

            var lhsX = parseInt(lhs.timeOfDay);
            var rhsX = parseInt(rhs.timeOfDay);

            return order === "ascending" ? lhsX - rhsX : rhsX - lhsX;
        }
    },
    "dayOfWeek": {
        name: "Day of week",
        noRemoveData: true,
        noMaxItems: true,
        grouseAlias: ["published[DAY_OF_WEEK]"],
        noSelect: true,
        noLimit: true,
        hideOthers: true,
        hideUnknown: true,
        getter: simpleGetterFactory("dayOfWeek"),
        filterGetter: function (d) {
            var day = parseInt(d.dayOfWeek);

            if (day >= 1 && day <= 7) {
                return [
                    "MON",
                    "TUE",
                    "WED",
                    "THU",
                    "FRI",
                    "SAT",
                    "SUN"
                ][day - 1]
            }

            console.warn("Unexpected day of week: " + d);
            return "Unknown";
        },
        setter: simpleSetterFactory("dayOfWeek"),
        scaleX: b3js.scaleDiscrete,
        tooltipPreposition: "on",
        filterField: "publishedTime",
        formatX: function (d) {
            if (d >= 1 && d <= 7) {
                return [
                    "Mondays",
                    "Tuesdays",
                    "Wednesdays",
                    "Thursdays",
                    "Fridays",
                    "Saturdays",
                    "Sundays"
                ][d - 1]
            }

            console.warn("Unexpected day of week: " + d);
            return "Unknown";
        },
        preProcess: function (data, model) {
            data.forEach(function (row) {
                row.dayOfWeek = row.published;
                delete row.published;
            });

            return data;
        },
        defaultSortOptions: {
            label: "Day of week",
            field: "dayOfWeek",
            order: "ascending"
        },
        sorter: function (lhs, rhs, order) {
            order ??= "ascending";

            var lhsX = parseInt(lhs.dayOfWeek);
            var rhsX = parseInt(rhs.dayOfWeek);

            return order === "ascending" ? lhsX - rhsX : rhsX - lhsX;
        }
    },
    "relevancy": {
        noLimit: true,
        getter: simpleGetterFactory("relevancy"),
        filterGetter: filterGetterFactory("relevancy"),
        setter: simpleSetterFactory("relevancy"),
        formatX: formatRelevancy,
        formatLabel: formatRelevancy,
    },
    "extractWord": {
        name: "Words",
        isMultiple: true,
        filterGetter: filterGetterFactory("extractWord"),
        getter: simpleGetterFactory("extractWord"),
        setter: function (d, id, value) {
            d.extractWord = value
        },
        scaleX: b3js.scaleDiscrete
    },
    "authorBioWord": {
        name: "Words in the authors' bios",
        chartName: "Words in the authors' bios",
        isMultiple: true,
        filterGetter: filterGetterFactory("authorBioWord"),
        getter: simpleGetterFactory("authorBioWord"),
        setter: function (d, id, value) {
            d.authorBioWord = value
        },
        scaleX: b3js.scaleDiscrete
    }
};



function createRpcsField(fieldName, rpcsTester, getAllSegments) {
    fieldName = fieldName.toLowerCase();

    let colourScale = null;

    switch(fieldName) {
        case "risk": {
            const risk = getAllRiskSegments();
            const ids = risk.map(r => r.id);
            const riskColour = codeToColour("RISK");
            const riskColourLight = d3.hcl(riskColour).brighter(2);
            colourScale = d3.scaleOrdinal()
                .domain(ids)
                .range(d3.quantize(d3.interpolateHcl(riskColourLight, riskColour), ids.length));
            break;
        }
        case "service": {
            const service = getAllServiceSegments();
            const ids = service.map(r => r.id);
            const serviceColour = codeToColour("SERVICE");
            const serviceColourLight = d3.hcl(serviceColour).brighter(2);
            colourScale = d3.scaleOrdinal()
                .domain(ids)
                .range(d3.quantize(d3.interpolateHcl(serviceColourLight, serviceColour), ids.length));
            break;
        }

    }


    return {
        tooltipPrepositionPercent: 'tagged with',
        tooltipComparePreposition: 'tagged with',
        tooltipMentionsWithVerb: 'with tag',
        name: capitalise(fieldName),
        isMultiple: true,
        isTag: true,
        calculateMoe: true,
        noMaxItems: true,
        hideUnknown: true,
        hideOthers: true,
        showCooccurrence: true,
        noLimit: true,
        grouseAlias: ["tag"],
        tagNamespace: "segment",
        appendForFetch: function (model) {
            let filter;

            var id = codeToId(fieldName.toUpperCase());
            var showCooccurrence = !!model.get("showCooccurrence");
            if (showCooccurrence) {
                filter = "tag is " + id;
                filter = appendSegmentRestrictions(filter);
                return filter;
            }

            var segments = getSegmentsInFilter(model.get("_effectiveFilter")).include;
            segments = segments.filter(function (s) {
                return rpcsTester(getSegment(s))
            });
            if (!segments.length) {
                filter = "tag is " + id;
                filter = appendSegmentRestrictions(filter);
                return filter;
            }

            filter = "(" + segments.map(function (s) {
                return "tag is " + s
            }).join(' or ') + ")";
            filter = appendSegmentRestrictions(filter);

            return filter;
        },
        getter: function (d, compare) {
            var field = !compare ? fieldName + ".name" : fieldName + "2.name";
            return d[field];
        },
        setter: tagAndTopicSetterFactory(fieldName),
        filterField: "segment",
        filterGetter: tagAndTopicFilterGetterFactory(fieldName),
        extendData: tagAndTopicExtender,
        filterData: function (data, model, extraFootnotes) {
            return data
                .filter(function (row) {
                    if (row[fieldName + '.id']) {
                        var rpcs = getSegment(row[fieldName + '.id']);
                        if (!rpcs && !rpcsTester(rpcs)) return false;
                    }

                    if (row[fieldName + '2.id']) {
                        rpcs = getSegment(row[fieldName + '2.id']);
                        if (!rpcs && !rpcsTester(rpcs)) return false;
                    }

                    return true;
                });
        },
        postProcess: function (data, model, footnotes) {
            // Here we want to make sure that we are not missing any of the segments.
            // If we are, we add it as a zero entry. The complication
            // is when we are comparing with other data.

            var xAxis = model.get("xAxis");
            let show = model.get('show');
            let first = show[0];
            var yAxis = first.yAxis;
            var compare = model.get("compare");
            var isCompare = compare === fieldName;
            var prefix = !isCompare ? fieldName : fieldName + "2";

            if (isCompare) {
                compare = xAxis;
                xAxis = fieldName;
            }

            var fields = FANTASTIC_FIELDS;
            var yField = fields[yAxis] || {};
            var compareField = fields[compare] || {};
            var compareGetter = null;
            var compareFilterGetter = null;
            if (compare) {
                compareGetter = !isCompare ? getCompareGetter(compare) : compareField.getter || function (d) {
                    return d[compare]
                };
                compareFilterGetter = !isCompare ? getCompareFilterGetter(compare) : compareField.filterGetter || function (d) {
                    return d[compare]
                };
            }

            var presentWithComparison = new Set();
            var comparisons = new Set();
            var idToName = {};
            var maxItems = model.get('maxItems') || 8;
            var addMissing = data.length > maxItems;

            var allSegments = getAllSegments();

            var id;
            for (var i = 0; i < data.length; i++) {
                if (compare) {
                    id = compareFilterGetter(data[i]);
                    comparisons.add(id);
                    idToName[id] = compareGetter(data[i]);
                }
                presentWithComparison.add("" + (data[i][prefix + '.id'] + ":" + (compare ? id : '')));
            }

            var segmentsPresent = new Set();
            data.forEach(function (d) {
                segmentsPresent.add("" + d[prefix + '.id'])
            });

            addMissing = segmentsPresent.size < maxItems;

            const excludedInFilter = new Set(getSegmentsInFilter(model.get('_effectiveFilter')).exclude);

            var c;
            for (i = 0; i < allSegments.length; i++) {
                c = allSegments[i];
                if (!compare) comparisons.add("");
                comparisons.forEach(function (comp) {
                    if (!presentWithComparison.has(c.id + ":" + comp) && addMissing && !excludedInFilter.has(c.id)) {
                        var result = {};
                        result[prefix + '.id'] = c.id;
                        result[prefix + '.name'] = c.name;
                        result[prefix + '.description'] = c.description;
                        result[prefix + '.namespace'] = "segment";
                        result['_' + fieldName] = c;

                        yField.setter(result, 0);
                        if (compare) compareField.setter(result, comp, idToName[comp]);
                        data.push(result);
                    }
                })
            }
            return data;
        },
        descriptionGetter: tagAndTopicDescriptionGetterFactory(fieldName),
        colourFromX: function (d, defaultColour, model) {
            if (!d) return "#c0c0c0";

            const compare = model?.get("compare");
            const isCompare = compare === fieldName;
            const prefix = !isCompare ? fieldName : fieldName + "2";
            const data = d[`_${prefix}`];
            if (isCompare) {
                const id = data?.id;
                if (!id || !colourScale) return defaultColour;
                if (id && colourScale(id)) return colourScale(id);
                return "#c0c0c0";
            }


            if (!data || !data.flag) return "#c0c0c0";
            return codeToColour(data.flag) || "#c0c0c0";
        },
    }
};
