<template>

    <div :class="{'brand-summary-container': showBottomMargin}">
        <div v-if="!isLoading" class="root-brand-options">
            <template v-if="brand.archived">
                <p class="summary-item archived">
                    <span>Archived on {{ formatDate(brand.archived) }}, not collecting new mentions. </span>
                    <span class="unarchive-btn">
                        <span v-if="brand.canArchive" @click="onUnarchive" class="unarchive" :data-brand="brand.id">unarchive</span>
                        <SpinnerComponent v-if="archiveSaving" :size="16"/>
                    </span>
                </p>
            </template>

            <template v-if="brand.mentionFilter">
                <TooltipComponent>
                    <p class="summary-item" :class="{'can-edit': brand.canEdit}" @click="showEditBrandDialog"><span
                        class="title">{{ formatConversationFeedFilter(brand.mentionFilter) }}: </span><span>{{ formatConversationFilter(brand.mentionFilter) }}.</span>
                    </p>
                    <template #tooltip>{{ brand.canEdit ? 'Click to edit' : '' }}</template>
                </TooltipComponent>

            </template>

            <template v-if="brand.socialNetworks">
                <TooltipComponent>
                    <p class="summary-item" :class="{'can-edit': brand.canEdit}" @click="showEditBrandDialog">
                        <span class="title">Social networks: </span>
                        <span>Only search {{ formatSocialNetworksList(brand.socialNetworks) }}</span>.
                    </p>
                    <template #tooltip>{{ brand.canEdit ? 'Click to edit' : '' }}</template>
                </TooltipComponent>
            </template>

            <template v-if="brand.feeds">
                <TooltipComponent>
                    <p class="summary-item" :class="{'can-edit': brand.canEdit}" @click="showEditBrandDialog">
                        <span class="title">Feeds: </span>
                        <span>Only search {{ formatFeedsList(brand.feeds) }}.</span>
                    </p>
                    <template #tooltip>{{ brand.canEdit ? 'Click to edit' : '' }}</template>
                </TooltipComponent>
            </template>


            <TooltipComponent>
                <p class="summary-item" :class="{'can-edit': brand.canEditTopicTree && brand.canEdit}" @click="showEditTopicTreeDialog">
                    <span class="title">Topic trees: </span>
                    <span v-if="!brand.topicTrees">(none)</span>
                    <span v-for="(tt, index) in brand.topicTrees" :key="tt.id">
                        <TooltipComponent>
                            <span :class="{'inactive': tt.active}">
                                {{ tt.label }}<template v-if="index < brand.topicTrees.length - 1">, </template></span>
                            <template #tooltip>{{tt.description}}</template>
                        </TooltipComponent>
                    </span>
                </p>
                <template #tooltip>{{ brand.canEditTopicTree ? 'Click to edit' : '' }}</template>
            </TooltipComponent>

                <span class="summary-item" :class="{'can-edit': brand.canEditTopicTree && brand.canEdit}" @click="onShowEditBrandSegmentsDialog">
                    <TooltipComponent>
                        <span class="title">Segments: </span>
                        <span v-if="!brand.segmentLists">(none)</span>
                        <template #tooltip>{{ brand.canEditTopicTree ? 'Click to edit' : '' }}</template>
                    </TooltipComponent>

                    <span v-for="(value, key, mapIndex) of segmentMap" :key="key">
                        <template v-if="ifMashAdmin">

                            <BrandSegmentComponent v-if="value.active.length" :segment-name="key"
                                                   :segment-data="value.active"
                                                   :is-last="mapIndex < Object.keys(segmentMap).length - 1">
                            </BrandSegmentComponent>

                            <BrandSegmentComponent v-if="value.inactive.length" :segment-name="key"
                                                   :segment-data="value.inactive" :active="false"
                                                   :is-last="mapIndex < Object.keys(segmentMap).length - 1">
                            </BrandSegmentComponent>

                        </template>

                        <template v-if="!ifMashAdmin">
                            <span v-if="value.active.length"><i class="icon-check-1"></i> {{ key }} (active)</span>
                            <span v-if="value.inactive.length"> <i class="icon-cancel-circle"></i> {{ key }} (inactive)</span>
                        </template>
                    </span>
                </span>

            <TooltipComponent>
                <p class="summary-item" :class="{'can-edit': brand.canEdit}" @click="showEditBrandDialog">
                    <span class="title">Support profiles: </span>
                    <span class="profiles">
                    <slotted-tag v-for="(supProfile, index) in supportProfiles.display" :key="index" no-close>
                        <span v-html="supProfile"></span>
                    </slotted-tag>
                    <span v-if="!supportProfiles.display.length">(none)</span>
                 </span>
                </p>
                <template #tooltip>{{ brand.canEdit ? 'Click to edit' : '' }}</template>
            </TooltipComponent>

            <TooltipComponent>
                <p class="summary-item" :class="{'can-edit': brand.canEdit && isAccountAdmin}" @click="onShowEditWorkingHoursDialog">
                    <span class="title">Working hours: </span>
                    <span class="working-hours">{{ workingHours }}</span>
                </p>
                <template #tooltip>{{ brand.canEdit && isAccountAdmin ? 'Click to edit' : '' }}</template>
            </TooltipComponent>

            <TooltipComponent>
                <p class="summary-item" :class="{'can-edit': brand.canEdit}" @click="showEditBrandDialog">
                    <span class="title">Other profiles: </span>
                    <span class="profiles">
                    <slotted-tag v-for="(otherProfile, index) in otherProfiles.display" :key="index" no-close>
                        <span v-html="otherProfile"></span>
                    </slotted-tag>
                    <span v-if="!otherProfiles.display.length">(none)</span>
                </span>
                </p>
                <template #tooltip>{{ brand.canEdit ? 'Click to edit' : '' }}</template>
            </TooltipComponent>

            <TooltipComponent>
                <div :class="{'can-edit': brand.canEdit}" class="summary-item edit-colour" style="position: relative">
                    <p @click="showEditBrandDialog">Colour:</p>
                    <div @click="showEditBrandDialog" class="colour-preview"
                         :style="{'background-color': brand.colour}"></div>
                    <span class="colour-name">{{ brand.colour ? '' : 'None' }}</span>
                </div>
                <template #tooltip>{{
                        brand.canEdit ? 'Click to edit the colour that the brand appears as in metrics and reports' : 'The colour the brand appears as in metrics and reports'
                    }}
                </template>
            </TooltipComponent>


            <template v-if="!isLoading && brand['importedByAccounts']">
              <span>
                Brand is imported by
                <span v-for="(account, index) in brand['importedByAccounts']" :key="account.code">
                    <a :href="'/' + account.code + '/setup/brands/brand.id'">{{ account.code }}</a>
                        {{ account.name }}
                        <span v-if="index < brand['importedByAccounts'].length - 1">, </span>
                </span>
              </span>
            </template>
        </div>

        <BrandDescriptionSection v-if="!isLoading" :brand="brand"></BrandDescriptionSection>


        <LoadingMessage v-if="isLoading" :message="`Loading your summary for ${brand.shortName || brand.name}...`"
                        class="brand-summary-loader"/>

    </div>

</template>

<script>
import moment from "moment";
import formatConversationFeedFilter from "../../../helpers/formatConversationFeedFilter";
import formatConversationFilter from "../../../helpers/formatConversationFilter";
import formatSocialNetworksList from "../../../helpers/formatSocialNetworksList";
import formatFeedsList from "../../../helpers/formatFeedsList";
import {isAccountAdmin} from "@/app/Permissions";
import renderLanguage from "../../../helpers/renderLanguage";
import ifMashAdmin from "@/helpers/ifMashAdmin";
import BrandDescriptionSection from "@/setup/brands/tree/BrandDescriptionSection.vue";
import {getProfileIcon} from "@/app/utils/Profiles";
import {getProfileDisplayName} from "@/setup/profiles/ProfileUtils";
import VuexStore from "@/store/vuex/VuexStore";
import {features} from "@/app/Features";
import {mash} from "@/store/Services";
import {mapActions, mapGetters, mapState} from "vuex";
import {showDialogComponent as showDialog, showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import {
    updateSupportProfiles,
    updateWorkingHours,
    updateOtherProfiles,
    initBrandSegments
} from "@/setup/brands/tree/rootBrandsHelpers";
import EditRootBrand from "@/setup/brands/forms/edit-brand/EditRootBrand.vue";
import LoadingMessage from "@/components/LoadingMessage.vue";
import {notifyUser} from "@/app/framework/notifications/Notifications";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import SlottedTag from "@/components/tags/SlottedTag.vue";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";
import TopicTreePickerDialog from "@/app/framework/dialogs/topic-tree-picker/TopicTreePickerDialog.vue";
import SegmentPickerDialog from "@/app/framework/dialogs/segment-picker/SegmentPickerDialog.vue";
import EditWorkingHours from "@/setup/brands/EditWorkingHours.vue";
import BrandSegmentComponent from "@/setup/brands/tree/brand-summary-section/BrandSegmentComponent.vue";

export default {
  name: "BrandSummarySection",
    components: {
        BrandSegmentComponent, SlottedTag, SpinnerComponent, TooltipComponent,
        LoadingMessage, BrandDescriptionSection
    },
  props: ['brand', 'showMarginBottom'],

    data() {
      return {
          supportProfiles: {data: [], display: []},
          workingHours: '',
          otherProfiles: {data: [], display: []},
          segmentMap: {},
          hasFeedsOrFilter: false,
          showBottomMargin: false,
          isLoading: true,
          archiveSaving: false,
      }
    },

  computed: {
      ...mapState(['account']),
      ...mapGetters('workingHours', ['brandIdToWorkingHours']),
      ...mapGetters('profiles', ['getNonDeletedProfiles']),
  },

    watch: {
      async brand() {
          await this.initialiseSummary();
      }
    },

    async mounted() {
        await this.initialiseSummary();
    },

    methods: {
        ...mapActions(['updateBrand']),
        ...mapActions('profiles', ['refreshProfiles']),
        ...mapActions(['refreshBrands']),
        ifMashAdmin,
        renderLanguage, isAccountAdmin, formatFeedsList, formatSocialNetworksList, formatConversationFilter,
        formatConversationFeedFilter,

        async initialiseSummary() {
            this.isLoading = true;

            this.hasFeedsOrFilter = (this.brand.feeds || this.brand.socialNetworks || this.brand.filter || this.brand.mentionFilter) ? true : false;
            this.showBottomMargin = features.brandDescription() || this.hasFeedsOrFilter;


            try {
                await this.refreshProfiles();

                this.supportProfiles = updateSupportProfiles(this.brand.supportProfileIds, this.getNonDeletedProfiles);
                this.otherProfiles = updateOtherProfiles(this.brand.otherProfileIds, this.getNonDeletedProfiles);
                this.workingHours = updateWorkingHours(this.brandIdToWorkingHours.get(this.brand.id));

                this.segmentMap = initBrandSegments(this.brand)

            } catch (error) {
                console.warn('An error occurred fetching profiles: ', error)
                this.loadingError = true;
            } finally {
                this.isLoading = false;
            }
        },

        formatDate(archivedDate) {
          return moment(archivedDate).format("MMMM Do YYYY");
        },

        async onUnarchive() {
            this.archiveSaving = true;
            try {
                await this.updateBrand({id: this.brand.id, archived: false});
                notifyUser(`Successfully unarchived ${this.brand.name || this.brand.shortName}.`);
            } catch (error) {
                console.warn("An error occurred while archiving the brand. ", error)
            } finally {
                this.archiveSaving = false;
            }
        },

    showEditBrandDialog() {
        showDialogComponent(EditRootBrand, {brand: this.brand})

    },

    showEditTopicTreeDialog() {
        if (!this.brand.canEdit || !this.brand.canEditTopicTree) return;
        if (this.brand.parent) return;

        const dialog = showDialog(TopicTreePickerDialog, {
            activeBrandId: this.brand.id
        });
        dialog.$on('update-brand-topic-trees', async (data) => {
            await this.refresh();
        });
    },


    onShowEditBrandSegmentsDialog() {
        if (this.brand.parent || !this.brand.canEdit || !this.brand.canEditTopicTree) return;

        const dialog = showDialog(SegmentPickerDialog, {
            activeBrandId: this.brand.id
        });
        dialog.$on('update-brand-segments', async (brandSegments) => {
            await this.refresh();
        });
    },

    onShowEditWorkingHoursDialog() {
        if (!this.brand.canEdit) return;

        let dialog = showDialog(EditWorkingHours, {brand: this.brand});
        dialog.$on('update-working-hours', (brandId) => {
            if (brandId === this.brand.id) {
                this.workingHours = updateWorkingHours(this.brandIdToWorkingHours.get(this.brand.id));
            }
        });

    },

    async refresh() {
        this.isLoading = true
        await this.refreshBrands(true);
        this.isLoading = false
    }

  },
}
</script>

<style scoped lang="sass">

.brand-summary-loader
    --loading-message-delay: 0ms
    margin: 20px auto 20px auto

.root-brand-options
    width: 50%

.brand-summary-container
  margin-bottom: 20px
  border-bottom: 1px solid #666
  display: flex
  flex-direction: row
  justify-content: space-between
  gap: 10px

.root-brand-setup .root-brand-options .filter,
.root-brand-setup .root-brand-options .feeds
  color: #aaa
  display: block
  padding-left: 20px
  padding-right: 20px
  padding-bottom: 10px
  line-height: 26px

.root-brand-setup
  .support-profiles
    .tag
      padding-right: 3px
      margin-right: 4px
    button
      display: none

.profiles
    .be-tag
        margin: 2px 3px 2px 3px

.edit-colour
  display: flex
  flex-direction: row
  gap: 5px

  .colour-preview
      height: 20px
      width: 30px
      border-radius: 3px

.archived
    display: flex
    flex-direction: row
    align-items: center
    gap: 9px
    pointer-events: unset !important

    .unarchive-btn
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center
        gap: 5px

        .unarchive
            cursor: pointer
            color: var(--deq-color-text-strong)
            &:hover
                color: #aee15d

.edit-brand
    cursor: pointer

.title
    color: var(--deq-color-text-label)

.summary-item
    pointer-events: none

.can-edit
    pointer-events: unset
    cursor: pointer
    &:hover
        color: var(--be-editable-data-colour)
        .title
            color: var(--be-editable-data-colour)
        .be-tag
            color: var(--be-editable-data-colour)
            cursor: pointer


</style>