import { profileTypes } from "@/setup/profiles/ProfileUtils";
import { beef } from "@/store/Services";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let managedWhatsAppProfilesPromise = null;
let initializedWhatsAppProfiles = false;

export default {
    namespaced: true,

    state: {
        whatsAppLoginStatus: {
            loggedIn: false, // is the user currently logged in through WhatsApp?
            loginInvalidated: false // has the user's WhatsApp login been invalidated?
        },
        whatsAppLoggedInAs: null,

        managedWhatsAppProfiles: []
    },

    mutations: {
        setWhatsAppLoginStatus: (state, value) => state.whatsAppLoginStatus = value,
        setWhatsAppLoggedInAs: (state, value) => state.whatsAppLoggedInAs = value,
        setManagedWhatsAppProfiles: (state, value) => state.managedWhatsAppProfiles = value
    },

    actions: {
        async initializeManagedWhatsAppProfiles({commit, state}, forceRefresh) {
            forceRefresh ??= false;
            if (!forceRefresh && initializedWhatsAppProfiles) return;
            let response = null;
            if (managedWhatsAppProfilesPromise) response = await managedWhatsAppProfilesPromise;
            else {
                try{
                    managedWhatsAppProfilesPromise = beef.get("/api/online-profile/whatsapp/list");
                    response = await managedWhatsAppProfilesPromise;
                }finally{
                    managedWhatsAppProfilesPromise= null;
                }
            }
            if (response.status === 200) {
                initializedWhatsAppProfiles = true;
                response.data?.forEach(whatsApp => {
                    whatsApp.type = profileTypes.whatsapp;
                })
                commit('setManagedWhatsAppProfiles', response.data);
            }
        }
    }
};
