import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
import {count, count as grouseCount} from "@/data/Grouse";


const FIVE_STAR_SEGMENT_ID = 167447;
const TEN_STAR_SEGMENT_ID = 194038;
const FIVE_OPTION_AGREEMENT_SCALE = 196490;
const YES_NO_SCALE = 194051;


/**
 * The segment ID for the net promoter score.
 * @type {number}
 */
export const NET_PROMOTER_SCORE_SEGMENT = 194025;

/**
 * The segment ID for the Customer Effort Survey type using five points.
 * @type {number}
 */
export const CES_SURVEY_5POINT_SEGMENT = 194027;

/**
 * The segment ID for the Customer Satisfaction Survey type.
 * @type {number}
 */
export const CSAT_SURVEY_SEGMENT = 194024;

/**
 * The segment ID for the First Contact Resolution survey type.
 * @type {number}
 */
export const FCR_SURVEY_SEGMENT = 194028;

/**
 * The segment ID for the Verbatim survey Question Type
 * @type {number}
 */
export const VERBATIM_SURVEY_SEGMENT = 194068;

export function getNetPromoterScoreFilter() {
    return `segment is ${NET_PROMOTER_SCORE_SEGMENT} and segment is ${TEN_STAR_SEGMENT_ID}`;
}

export function getCes5PointScoreFilter() {
    return `segment is ${CES_SURVEY_5POINT_SEGMENT} and segment is ${FIVE_OPTION_AGREEMENT_SCALE}`;
}

export function getCsatScoreFilter() {
    return `segment is ${CSAT_SURVEY_SEGMENT} and segment is ${FIVE_STAR_SEGMENT_ID}`;
}

export function getFcrScoreFilter() {
    return `segment is ${FCR_SURVEY_SEGMENT} and segment is ${YES_NO_SCALE}`;
}

/**
 * Returns the total number of mentions that have been tagged with a
 * net promoter score segment.
 * @param filter
 * @return {Promise<number>}
 */
export async function getTotalNpsMentions(filter) {
    filter = appendFiltersReadably(filter, getNetPromoterScoreFilter());
    const { mentionCount } = await grouseCount(filter);
    return mentionCount;
}

/**
 * Returns the total number of mentions that have been tagged with a
 * CSat score segment.
 * @param filter
 * @return {Promise<number>}
 */
export async function getTotalCsatMentions(filter) {
    filter = appendFiltersReadably(filter, getCsatScoreFilter());
    const { mentionCount } = await grouseCount(filter);
    return mentionCount;
}

/**
 * Returns the total number of mentions that have been tagged with a
 * CES 5 point score segment.
 * @param filter
 * @return {Promise<number>}
 */
export async function getTotalCes5PointMentions(filter) {
    filter = appendFiltersReadably(filter, getCes5PointScoreFilter());
    const { mentionCount } = await grouseCount(filter);
    return mentionCount;
}

/**
 * Returns the total number of mentions that have been tagged with a
 * FCR segment.
 * @param filter
 * @return {Promise<number>}
 */
export async function getTotalFcrMentions(filter) {
    filter = appendFiltersReadably(filter, getFcrScoreFilter());
    const { mentionCount } = await grouseCount(filter);
    return mentionCount;
}



/**
 * Calculates the net promoter score (as a value between -1 and 1)
 * given the supplied values.
 * @return {number}
 */
export function calculateNpsScoreFrom(totalMentions, numDetractors, numPromoters) {
    if (!Number.isFinite(totalMentions)) throw new Error("totalMentions must be an integer");
    if (!Number.isFinite(numDetractors)) throw new Error("numDetractors must be an integer");
    if (!Number.isFinite(numPromoters)) throw new Error("numPromoters must be an integer");
    if (numDetractors < 0) throw new Error("numDetractors should not be negative");
    if (numPromoters < 0) throw new Error("numPromoters should not be negative");
    if (totalMentions <= 0) return totalMentions;

    const promoterP = numPromoters / totalMentions;
    const detractorP = numDetractors / totalMentions;

    return promoterP - detractorP;
}

/**
 * Given a filter, this pulls data from the account and then calculates
 * the nps score as a value between -1 and 1.
 * @param filter
 * @return {Promise<number>}
 */
export async function calculateNpsScore(filter) {
    const totalFilter = appendFiltersReadably(filter, getNetPromoterScoreFilter());
    const detractorFilter = appendFiltersReadably(filter, getNpsDetractorsFilter());
    const promoterFilter = appendFiltersReadably(filter, getNpsPromotersFilter());

    const [total,
        detractors,
        promoters] = await Promise.all([count(totalFilter), count(detractorFilter), count(promoterFilter)]);

    return calculateNpsScoreFrom(total.mentionCount, detractors.mentionCount, promoters.mentionCount);
}

/**
 * Calculates the CSAT score.
 * @param totalMentions
 * @param sumOfFoursAndFives
 * @return {number}
 */
export function calculateCsatScoreFrom(totalMentions, sumOfFoursAndFives) {
    if (!Number.isFinite(totalMentions)) throw new Error("totalMentions must be an integer");
    if (!Number.isFinite(sumOfFoursAndFives)) throw new Error("sumOfFoursAndFives must be an integer");
    if (sumOfFoursAndFives < 0) throw new Error("sumOfFoursAndFives should not be negative");
    if (totalMentions <= 0) return totalMentions;

    return sumOfFoursAndFives / totalMentions;
}

/**
 * Given a filter, this pulls data from the account and then calculates
 * the CSat score as a value between 0 and 1.
 * @param filter
 * @return {Promise<number>}
 */
export async function calculateCsatScore(filter) {
    const totalFilter = appendFiltersReadably(filter, getCsatScoreFilter());
    const fourAndFiveFilter = appendFiltersReadably(totalFilter, "segment is 167452 or segment is 167453");

    const [total,
        fourAndFive] = await Promise.all([count(totalFilter), count(fourAndFiveFilter)]);

    return calculateCsatScoreFrom(total.mentionCount, fourAndFive.mentionCount);
}

/**
 * Calculates the CES 5 point score using the given inputs.
 * @param {number} totalMentions
 * @param {number} totalAgree
 * @param {number} totalStronglyAgree
 * @return {number}
 */
export function calculateCes5PointScoreFrom(totalMentions, totalAgree, totalStronglyAgree) {
    if (!Number.isFinite(totalMentions)) throw new Error("totalMentions must be an integer");
    if (!Number.isFinite(totalAgree)) throw new Error("totalAgree must be an integer");
    if (!Number.isFinite(totalStronglyAgree)) throw new Error("totalStronglyAgree must be an integer");
    if (totalAgree < 0) throw new Error("totalAgree should not be negative");
    if (totalStronglyAgree < 0) throw new Error("totalStronglyAgree should not be negative");
    if (totalMentions <= 0) return totalMentions;

    return (totalAgree + totalStronglyAgree) / totalMentions;
}

/**
 * Calculates the CES score by querying the API using the given filter.
 * @param filter
 * @return {Promise<number>}
 */
export async function calculateCes5PointScore(filter) {
    const totalFilter = appendFiltersReadably(filter, getCes5PointScoreFilter());
    const stronglyFilter = appendFiltersReadably(totalFilter, "segment is 196492");
    const agreeFilter = appendFiltersReadably(totalFilter, "segment is 196493");

    const [total,
        strongly,
        agree] = await Promise.all([count(totalFilter), count(stronglyFilter), count(agreeFilter)]);

    return calculateCes5PointScoreFrom(total.mentionCount, strongly.mentionCount, agree.mentionCount);
}

/**
 * Calculates the First Contact Resolution score (FCR score) using the given inputs.
 * @param totalMentions
 * @param totalYes
 * @return {number}
 */
export function calculateFcrScoreFrom(totalMentions, totalYes) {
    if (!Number.isFinite(totalMentions)) throw new Error("totalMentions must be an integer");
    if (!Number.isFinite(totalYes)) throw new Error("totalYes must be an integer");
    if (totalYes < 0) throw new Error("totalYes should not be negative");
    if (totalMentions <= 0) return totalMentions;

    return totalYes / totalMentions;
}

/**
 * Calculates the FCR score after querying the API for the specified data.
 * @param filter
 * @return {Promise<number>}
 */
export async function calculateFcrScore(filter) {
    const totalFilter = appendFiltersReadably(filter, getFcrScoreFilter());
    const yesFilter = appendFiltersReadably(totalFilter, "segment is 194053");

    const [total,
        yes] = await Promise.all([count(totalFilter), count(yesFilter)]);

    return calculateCsatScoreFrom(total.mentionCount, yes.mentionCount);
}



/**
 * These segments define the tags for what NPS considers
 * "detractors" (people who scored a service low).
 * @type {number[]}
 */
export const NPS_IDS_DETRACTORS = Object.freeze([
    194040,
    194041,
    194042,
    194043,
    194044,
    194045,
    194046
]);

/**
 * These segments define the tags for what NPS considers
 * "passive" (people who scored a service mediumly).
 * @type {number[]}
 */
export const NPS_IDS_PASSIVES = Object.freeze([
    194047,
    194048
]);

/**
 * These segments define the tags for what NPS considers
 * "promoters" (people who scored a service high).
 * @type {number[]}
 */
export const NPS_IDS_PROMOTERS = Object.freeze([
    194050,
    194049
]);

/**
 * Returns a filter for determining NPS detractors
 * @return {string}
 */
export function getNpsDetractorsFilter() {
    return NPS_IDS_DETRACTORS.map(val => `segment is ${val}`).join(' or ');
}

export const NPS_PROMOTER_DESCRIPTION = "Respondents who gave a score of 9 or 10. They are considered to be satisfied, enthusiastic customers who will promote the brand.";
export const NPS_PASSIVE_DESCRIPTION = "Respondents who gave a score of 7 or 8. They are considered to be satisfied, but not enthusiastic, customers. They may be vulnerable to competitors.";
export const NPS_DETRACTOR_DESCRIPTION = "Respondents who gave a score of 6 or lower. They may be unhappy customers, who can damage the brand and impede growth.";

/**
 * Returns a filter for determining NPS passives
 * @return {string}
 */
export function getNpsPassivesFilter() {
    return NPS_IDS_PASSIVES.map(val => `segment is ${val}`).join(' or ');
}

/**
 * Returns a filter for determining NPS promoters
 * @return {string}
 */
export function getNpsPromotersFilter() {
    return NPS_IDS_PROMOTERS.map(val => `segment is ${val}`).join(' or ');
}
