<template>

    <div class="deq-reset deq-terms-list-component">
        <div v-if="!isLoading" ref="queryInputList" class="tl-query-input-list dark-scrollbars dark-scrollbars--visible">
            <div v-for="(term, index) in termsList" :key="term" class="tl-input-container">
                <i @click="onRemove(term)" class="icon-trash-1 tl-remove"></i>
                <PhraseTermPickerComponent v-model="termsList[index]"></PhraseTermPickerComponent>
            </div>
        </div>
        <LoadingMessage v-if="isLoading" :message="'Setting up your phrase terms...'" class="tl-loader" />

        <div class="tl-cockpit">
            <BeButton @click="onAddInputField" class="tl-add-btn">
                <i class="icon-plus"></i>
                Add Phrases
            </BeButton>

            <ul>
                <li>Press enter after typing a word or group of words to add them to the phrase</li>
                <li>Use - to exclude words (e.g -no spam)</li>
            </ul>
        </div>

    </div>

</template>

<script>

import BeButton from "@/components/buttons/BeButton.vue";
import LoadingMessage from "@/components/LoadingMessage.vue";
import PhraseTermPickerComponent from "@/setup/brands/inputs/PhraseTermPickerComponent.vue";

/**
 * event name: 'terms-updated'
 * @type {string}
 */
const TERMS_UPDATED = 'terms-updated';


export default {
    name: "TermsList",
    components: {PhraseTermPickerComponent, LoadingMessage, BeButton},

    props: {
        initialTermsList: {
            type: Array,
            required: true
        }
    },

    model: {
        prop: 'initialTermsList',
        event: TERMS_UPDATED
    },


    data() {
        return {
            isLoading: true,
            termsList: this.initialTermsList,
        }
    },

    mounted() {
        this.isLoading = true;
        this.termsList = this.initialTermsList
    },

    // to handle keep-alive components
    activated() {
        this.termsList = this.initialTermsList;
        this.isLoading = false;
    },

    deactivated() {
        this.isLoading = true;
    },

    destroyed() {
        this.isLoading = true;
    },

    watch: {
        termsList() {
            this.emitData();
        }
    },

    methods: {
        onAddInputField() {
            const emptyPhrases = this.termsList.filter(phrase => !phrase.length);

            if (emptyPhrases.length) {
                return
            }

            this.termsList.push("");
        },

        onRemove(removeTerm) {
            this.termsList = this.termsList.filter(term => term !== removeTerm);
            this.emitData();
        },

        emitData() {
            this.$emit(TERMS_UPDATED, this.termsList.filter(term => term.length))
        }
    }
}
</script>

<style scoped lang="sass">

.tl-loader
    --loading-message-delay: 0ms
    margin: auto

.tl-query-input-list
    max-height: 300px
    overflow-y: auto
    margin-bottom: 10px

    .tl-input-container
        display: flex
        flex-direction: row
        align-items: center
        gap: 10px
        margin-bottom: 10px
        padding: 0 10px 0 10px

        .tl-remove
            &:hover
                cursor: pointer
                color: var(--be-colour-text-dark__hover)

        .phrase-term-picker
            width: 100%


.tl-cockpit
    display: flex
    flex-direction: row
    align-items: flex-start
    gap: 10px

    .tl-add-btn
        margin-top: 6px
    ul
        list-style: circle

</style>