<template>
    <div class="deq-reset">
        <header class="deq-panel-header">
            <h1>Glossary</h1>
        </header>

        <main>
            <overview-description class="explore-glossary__description">
                <p>
                    These are many of the terms that are used in <strong><i class="symbol-explore"></i>Explore</strong> and
                    that make up the very basics of Analyse. If you want to find out more, you can press
                    <span class="be-tooltip--keyword">shift</span> + <span class="be-tooltip--keyword">?</span> to
                    open the <strong><i class="symbol-help"></i>Help & Tips</strong> dialog.
                </p>
            </overview-description>
            <glossary section="EXPLORE"/>
        </main>
    </div>
</template>

<script>
import {getGlossaryEntriesFor} from "@/app/help/tips/tip-utils";
import Glossary from "@/app/help/tips/GlossaryView";
import OverviewDescription from "@/app/toplevel/explore/overview/components/OverviewDescription";

export default {
    components: {OverviewDescription, Glossary},
    data() {
        return {
            entries: []
        }
    },

    async created() {
        this.entries = await getGlossaryEntriesFor("EXPLORE");
    }
}
</script>


<style scoped lang="sass">

.be-tooltip--keyword
    display: inline-flex
    box-sizing: border-box
    margin: 0
    color: white

.explore-glossary__description
    margin-bottom: 40px

</style>