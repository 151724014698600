<template>

    <main class="deq-reset">
        <template v-if="!loadingError">
            <TooltipComponent>
            <div v-if="!loadingError" class="head title">
                <div class="brand-name">
                    <h1>
                        <span @click="showEditBrandDialog" class="name" :class="{ 'deq-can-edit': brand.canEditRoot }">
                            <template v-if="brand.shortName">{{ brand.shortName }}</template>
                            <template v-else>
                                {{ brand.name }}
                            </template>
                        </span>
                    </h1>
                    <div>
                        <span v-if="brand.category" class="category" :class="{ 'deq-can-edit': brand.canEditRoot }"
                              @click="showEditBrandDialog">
                            {{ brandCategory(brand.category) }}
                        </span>
                        <template v-if="!brand.canEdit">
                           <span style="padding-left: 24px">
                                Brand is
                               <template v-if="brand.archived">archived and </template>
                               read-only
                               <template v-if="isMashAdmin && brand['importedFromAccount']">
                                   , imported from
                                   <a :href="`/${brand.importedFromAccount}/setup/brands/${brand.id}`">
                                       {{ brand.importedFromAccount }}
                                   </a>
                               </template>
                           </span>
                        </template>
                    </div>
                </div>
            </div>
                <template #tooltip>{{brand.canEditRoot ? 'Click to edit' : ''}}</template>
            </TooltipComponent>

<!--            end of page heading-->

            <BrandSummarySection :brand="brand"></BrandSummarySection>

            <BrandTree :brand="brand" :setSearchToggles="searchToggles" @deleted="onSearchToggle" @inactive="onSearchToggle"/>

            <div v-if="isMashAdmin && brand['importedFromAccount']" class="imported-brand-controls">
                <span>
                    Brand is imported from
                    <a :href="`/${account.code}/setup/brands/${brand.id}`">{{brand['importedFromAccount']}}</a>
                </span>

                <TooltipComponent>
                    <BeButton @click="onRemoveFromAccount">Remove from this account</BeButton>
                    <template #tooltip>Remove imported brand from this account</template>
                </TooltipComponent>
            </div>


            <template v-if="brand.canEdit && canEditBrandAsJson">
                <BeButton class="edit-json-btn" @click="onEditAsJsonClick">Edit JSON</BeButton>
            </template>
            <br>
            <br>
            <br>

        </template>



        <LoadingMessage v-if="isLoading && !loadingError" :message="'Fetching your brand'" class="root-brands-loader"/>
        <ErrorMessage v-if="loadingError"/>



    </main>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import VuexStore from "@/store/vuex/VuexStore";
import {editBrandsAndPhrases, isAccountAdmin, isMashAdmin, isOps} from "@/app/Permissions";
import {setTitle} from "@/app/Beef";
import brandCategory from "../../../helpers/brandCategory";
import ErrorMessage from "@/components/ErrorMessage.vue";
import LoadingMessage from "@/components/LoadingMessage.vue";
import renderLanguage from "../../../helpers/renderLanguage";
import moment from "moment";
import formatConversationFeedFilter from "../../../helpers/formatConversationFeedFilter";
import formatConversationFilter from "../../../helpers/formatConversationFilter";
import formatSocialNetworksList from "../../../helpers/formatSocialNetworksList";
import formatFeedsList from "../../../helpers/formatFeedsList";
import ifMashAdmin from "@/helpers/ifMashAdmin";
import BrandTree from "@/setup/brands/tree/BrandTree.vue";

import BrandSummarySection from "@/setup/brands/tree/BrandSummarySection.vue";
import {removeInactivePhrases} from "@/helpers/BrandPhraseHelper";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import EditRootBrand from "@/setup/brands/forms/edit-brand/EditRootBrand.vue";
import BeButton from "@/components/buttons/BeButton.vue";
import EditBrandTreeAsJsonDialog from "@/setup/brands/forms/EditBrandTreeAsJsonDialog.vue";
import {features} from "@/app/Features";
import ConfirmationDialog from "@/setup/brands/forms/ConfirmationDialog.vue";
import {getDefaultBrand} from "@/app/toplevel/explore/overview/ExploreUtilities";
import {notifyUser} from "@/app/framework/notifications/Notifications";


export default {
    name: "SetupRootBrands",
    components: {BeButton, TooltipComponent, BrandSummarySection, BrandTree, LoadingMessage, ErrorMessage},
    store: VuexStore,

    props: ['selectedBrandId'],
    provide() {
        return {
            rootBrandId: () => { return {id: this.selectedBrandId} },
          searchToggles: () => this.searchToggles
        }
    },

    data() {
        return {
            brand: {},
            mashBrand: {},
            brandsWithStats: [],
            brandMap: {},
            phraseMap: {},
            maxLevel: 2,
            isLoading: false,
            loadingError: false,

            searchToggles: {
                inactivePhrases: false,
                showBrandsAndPhrasesInTrash: false,
            },

            showEditDescription: false,
        }
    },

    computed: {
        ...mapState(['account']),
        ...mapState(['rootBrands']),
        ...mapState(['deletedBrands']),
        ...mapGetters(['idToBrand']),

        canEditBrandAsJson() {
            return features.editBrandJson();
        }
    },

    methods: {
        ifMashAdmin, formatFeedsList, formatSocialNetworksList, formatConversationFilter, formatConversationFeedFilter,
        moment, renderLanguage, isAccountAdmin, isMashAdmin, brandCategory,
        ...mapActions(['deleteBrand']),

        initBrandSegments: function (brand) {
            this.segmentMap = {};
            try {
                if (brand.segmentLists) {
                    let brandSegments = JSON.parse(JSON.stringify(brand.segmentLists));
                    brandSegments.sort((a, b) => a.label + a.subtitle > b.label + b.subtitle ? 1 : -1);

                    if (brandSegments) {
                        for (let i = 0; i < brandSegments.length; i++) {
                            let curSegment = brandSegments[i];
                            let segmentType = curSegment.label;

                            if (!this.segmentMap[segmentType]) {
                                this.segmentMap[segmentType] = {
                                    active: [],
                                    inactive: []
                                }
                            }

                            let curList = curSegment.active ? this.segmentMap[segmentType].active : this.segmentMap[segmentType].inactive;

                            if (!curList.includes(curSegment.subtitle)) {
                                let seg = {
                                    subtitle: curSegment.subtitle,
                                    description: curSegment.description
                                }
                                curList.push(seg);
                            }
                        }
                    }
                }
            } catch (error) {
                console.error("Unable to initialize brand segments", error);
            }
        },

        updateTitle: function() {
            setTitle(this.brand ? ((this.brand.shortName || this.brand.name) + ' Setup') : "Brand");
        },

        async init(includeInactivePhrases, showBrandsAndPhrasesInTrash) {
            this.searchToggles = {
                inactivePhrases: includeInactivePhrases,
                showBrandsAndPhrasesInTrash: showBrandsAndPhrasesInTrash
            };

            this.brandsWithStats = [...this.rootBrands, ...this.deletedBrands];

            if (this.idToBrand.has(this.selectedBrandId)) {
                this.mashBrand = {...this.brandsWithStats.find(b => b.id === this.selectedBrandId)};
            } else {
                const defaultBrand = await getDefaultBrand();
                this.mashBrand = {...this.brandsWithStats.find(b => b.id === defaultBrand.id)};
            }

            const newBrand = this.copyBrand(this.mashBrand)

            if (includeInactivePhrases === false) {
                removeInactivePhrases(newBrand);
            }

            if (showBrandsAndPhrasesInTrash === false) {
                newBrand.children = newBrand.children?.filter(c => !c.deleted);
                newBrand.children?.forEach(c => {
                    if (c.children) {
                        c.children = c.children?.filter(gc => !gc.deleted);
                    }
                });
            }

            this.brand = newBrand;

            // // // TODO: for testing header
            // // this.brand.canEditRoot = false;
            // // this.brand.canEdit = false;
            // // this.brand.archived = true;
            // // this.brand.importedFromAccount = 'ChadTesting';
        },

        /**
         *
         * Returns a deep copy of the brand object for fields that are used by the SetupRootBrands component
         * and children.
         */
        copyBrand(brand) {

            const copiedBrand = {...brand};

            if (copiedBrand.parent) {
                copiedBrand.level = copiedBrand.parent.level + 1;
                if (copiedBrand.level > this.maxLevel) this.maxLevel = copiedBrand.level;
                copiedBrand.canEdit = copiedBrand.parent.canEdit;
                copiedBrand.padding = `${(copiedBrand.level + 1) * 15}px`;
                copiedBrand.showChildren = true;
                copiedBrand.showPhrases = false;
            } else {
                copiedBrand.level = 0;
                copiedBrand.ancestors = "";
                copiedBrand.padding = `${(copiedBrand.level + 1) * 15}px`;
                copiedBrand.showChildren = true;
                copiedBrand.showPhrases = false;
                copiedBrand.canArchive = !copiedBrand.importedFromAccount && editBrandsAndPhrases();
                copiedBrand.canEdit = !copiedBrand.archived && !copiedBrand.importedFromAccount && editBrandsAndPhrases();
                copiedBrand.canEditTopicTree = copiedBrand.canEdit && isOps() || (!copiedBrand.importedFromAccount && editBrandsAndPhrases() && isOps() && copiedBrand.archived);
                copiedBrand.canEditRoot = copiedBrand.canEdit || copiedBrand.importedFromAccount && editBrandsAndPhrases();
            }

            copiedBrand.treeDoesMentionFilter = brand.filter && true;
            copiedBrand.treeDoesFeedFilter = (brand.feeds || brand.socialNetworks) && true;

            if (brand.children && brand.children.length) {
                copiedBrand.children = [];
                copiedBrand.children = [...brand.children.map((childBrand) => {
                    let newChild = {...childBrand};
                    newChild.grandParent = copiedBrand.parent && copiedBrand.parent.name ? copiedBrand.parent.name : null;
                    newChild.parent = copiedBrand
                    newChild = this.copyBrand({...newChild});
                    copiedBrand.treeDoesMentionFilter = brand.treeDoesMentionFilter || newChild.treeDoesMentionFilter;
                    copiedBrand.treeDoesFeedFilter = brand.treeDoesFeedFilter || newChild.treeDoesFeedFilter;
                    return newChild
                })]
            }

            copiedBrand.subBrandsAllowed = copiedBrand.level < this.maxLevel;

            copiedBrand.phrasePadding = `${(copiedBrand.level + 1) * 30}px`;

            if (brand.phrases && brand.phrases.length) {
                copiedBrand.phrases = [];
                copiedBrand.showPhrases = false;
                copiedBrand.phrases = [...brand.phrases.map(phrase => {
                    if (brand.treeDoesMentionFilter || (phrase && phrase.mentionFilter)) {
                        copiedBrand.treeDoesMentionFilter = brand.treeDoesMentionFilter || (phrase && phrase.mentionFilter);
                    }
                    if (brand.treeDoesFeedFilter || (phrase && (phrase.feeds || phrase.socialNetworks))) {
                        copiedBrand.treeDoesFeedFilter = brand.treeDoesFeedFilter || (phrase && (phrase.feeds || phrase.socialNetworks));
                    }
                    return {
                        ...phrase,
                        lastUpdatedBy: {
                            admin: phrase.lastUpdatedBy.admin,
                            email: phrase.lastUpdatedBy.email,
                            firstName: phrase.lastUpdatedBy.firstName,
                            lastName: phrase.lastUpdatedBy.lastName,
                            id: phrase.lastUpdatedBy.id,
                        },
                        volume: {...phrase.volume},
                        inactive: phrase.inactive ? phrase.inactive : false,
                        parent: copiedBrand
                    }
                })]
            }

            return copiedBrand;

        },

        async onSearchToggle(event) {
            await this.init(event.inactivePhrases, event.showBrandsAndPhrasesInTrash)
        },

        showEditBrandDialog() {
            showDialogComponent(EditRootBrand, {brand: this.brand})
        },

        onEditAsJsonClick() {
            showDialogComponent(EditBrandTreeAsJsonDialog, {brand: this.mashBrand, toggles: this.searchToggles})
        },

        async onRemoveFromAccount() {
            showDialogComponent(ConfirmationDialog, {
                message: `Are you sure you want to remove ${this.brand.shortName || this.brand.name} from this account: ${this.account.code}`,
                onYesClick: this.executeDelete,
            })
        },

        async executeDelete() {
            try {
                const idForDelete = this.brand.id
                const nameForDelete = this.brand.shortName || this.brand.name;
                await this.deleteBrand(idForDelete);
                // at this point we've already "watched" rootBrands change, we no longer have the deleted brand,
                // ie. this.brand !== the brand that was deleted, so we need defaultBrand
                const defaultBrand = await getDefaultBrand();
                Beef.router.navigate(`/${this.account.code}/setup/${defaultBrand.id}`, {trigger: false, replace: false});
                notifyUser(`Successfully deleted ${nameForDelete}`)
            }  catch (error) {
                console.warn('An error occurred while deleting brand: ', error)
            }

        }

    },

    watch: {
        async rootBrands() {
            if (this.selectedBrandId) {
                await this.init(this.searchToggles.inactivePhrases, this.searchToggles.showBrandsAndPhrasesInTrash);
            }
        }
    },

    async mounted() {
        this.isLoading = true;
        await this.init(false, false);
        this.isLoading = false;
    },

}
</script>

<style scoped lang="sass">

@use "edit-brand-dialog"

.root-brands-loader
    --loading-message-delay: 100ms



.read-only
    pointer-events: none

.brand-name
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center

    .name, .category
        &:hover
            color: var(--be-editable-data-colour)

.imported-brand-controls
    display: flex
    flex-direction: row
    gap: 10px
    align-items: center
    margin-top: 10px

.edit-json-btn
    margin-top: 10px
</style>