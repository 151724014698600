import Vue from "vue";
import {features} from "@/app/Features";


const survey = Vue.observable({
    title: "Surveys",
    expanded: `Transform survey responses into actionable insights with real-time results.
     These metrics provide a clear view of the team’s service performance by measuring survey scores, resolution effectiveness,
      and customer satisfaction trends, helping to identify strengths and areas for improvement.`,
    hide: true,
    image: require("../assets/priority.png"),
    description: "Transform survey responses into actionable insights with real-time results.",
    metrics: [
        {
            id: "surveys/response",
            title: "Volume: Survey Response Breakdown",
            description: `Provides an overview of Surveys responses`,
            more: `Total surveys sent: the volume of surveys sent by the brand.
                    Completed Surveys: the volume of surveys that were filled to completion.
                    Incomplete Surveys: the volume of surveys that were not filled to completion.`,
            image: require("../assets/surveys/surveyResponseBreakDown.png"),
            keywords: ["surveys", "response", "breakdown"],
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "dataSet",
                yAxis: "mentionCount",
                caption: "Volume: Survey response breakdown",
                width: 6,
                height: 4,
                geometry: "pie",
                notification: "Added a <strong>Survey Response Breakdown</strong> metric",
                isDonut: true,
                useOutsideLabels: false,
                comment: "> # Total surveys sent\n> {{total mentions 'Segment IS 190614'}}\n\n> # Completed Surveys\n> {{total mentions 'Segment IS 190614 AND Segment IS 207241'}}\n\n> # Incomplete Surveys\n> {{total mentions 'Segment IS 190614 AND Segment ISNT 207241'}}\n",
                commentFontSize: 10,
                commentWidth: 3,
                dataSets: [
                    {
                        id: 1,
                        label: "Completed Surveys",
                        filter: "Segment IS 190614 AND Segment IS 207241"
                    },
                    {
                        id: 2,
                        label: "Incomplete Surveys",
                        filter: "Segment IS 190614 AND Segment ISNT 207241"
                    }
                ],
                show: [
                    {
                        opacity: 100,
                        yAxis: "mentionCount",
                        geometry: "pie",
                        barOutline: 0,
                        barPadding: 20,
                        hideLabels: false,
                        isDonut: true,
                        lineWidth: 2,
                        lineCurve: "smooth",
                        onlySupportHandles: true,
                        labelPlacement: "outside",
                        "colour-palette": "custom",
                        "colour-index": 0,
                        "colour-palette-custom": [
                            "#333333",
                            "#333333",
                            "#7a7878",
                            "#9856B0",
                            "#ff58a3",
                            "#D22D6F",
                            "#fc6a4b",
                            "#E53E39",
                            "#FFD658",
                            "#E8AF44",
                            "#43C278",
                            "#318F58",
                            "#8a2be2",
                            "#a52a2a",
                            "#deb887",
                            "#5f9ea0",
                            "#7fff00",
                            "#d2691e"
                        ]
                    }
                ],
            }
        },
        {
            id: "surveys/nps",
            title: "Net Promoter Score",
            description: "This metric measures the level of customer advocacy.",
            keywords: ["nps", "promoter", "survey"],
            image: require("../assets/surveys/nps.png"),
            widgetDesc: {
                type: "FantasticChart",
                yAxis: "npsScore",
                xAxis: "published",
                width: 8,
                height: 3,
                showAxisLabel: true,
                showAxis2Label: true,
                filter: "Segment IS 190611",
                show: [
                    {
                        geometry: "columns",
                        yAxis: "npsScore",
                        "colour-palette": "be14-gray",
                        "colour-index": 4
                    }
                ],
            },
            options: [
                {
                    key: "socialNetwork",
                    description: "NPS Score by social network",
                    title: "NPS Score by social network",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/npsSocialNetwork",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    yAxis: "npsScore",
                                    xAxis: "socialNetwork",
                                    caption: "Volume: Survey response breakdown",
                                    width: 4,
                                    height: 3,
                                    showAxisLabel: true,
                                    showAxis2Label: true,
                                    filter: "Segment IS 190611",
                                    show: [
                                        {
                                            geometry: "rows",
                                            yAxis: "npsScore",
                                        }
                                    ],
                                }
                            });
                    }

                },
                {
                    key: "engageAgent",
                    description: "NPS Score by engage agent",
                    title: "NPS Score by engage agent",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/npsEngageAgent",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    yAxis: "npsScore",
                                    xAxis: "engageagenttag",
                                    caption: "Volume: Survey response breakdown",
                                    width: 3,
                                    height: 3,
                                    showAxisLabel: true,
                                    showAxis2Label: true,
                                    filter: "Segment IS 190611",
                                    show: [
                                        {
                                            geometry: "rows",
                                            yAxis: "npsScore",
                                            "colour-palette": "be14-gray",
                                            "colour-index": 4
                                        }
                                    ],
                                }
                            });
                    }

                }
            ]
        },
        {
            id: "surveys/ces",
            title: "Customer Effort Score (CES)",
            description: "This metric measures the effort required to get an issue resolved.",
            keywords: ["customer", "effort", "survey"],
            image: require("../assets/surveys/ces.png"),
            widgetDesc: {
                type: "FantasticChart",
                yAxis: "cesScore",
                xAxis: "published",
                caption: "Volume: Survey response breakdown",
                width: 8,
                height: 3,
                showAxisLabel: true,
                showAxis2Label: true,
                filter: "Segment IS 190611",
                show: [
                    {
                        geometry: "columns",
                        yAxis: "cesScore",
                        "colour-palette": "be14-gray",
                        "colour-index": 4
                    }
                ],
            },
            options: [
                {
                    key: "socialNetwork",
                    description: "CES Score by social network",
                    title: "CES Score by social network",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/cesSocialNetwork",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    yAxis: "cesScore",
                                    xAxis: "socialNetwork",
                                    caption: "Volume: Survey response breakdown",
                                    width: 4,
                                    height: 3,
                                    showAxisLabel: true,
                                    showAxis2Label: true,
                                    filter: "Segment IS 190611",
                                    show: [
                                        {
                                            geometry: "rows",
                                            yAxis: "cesScore",
                                        }
                                    ],
                                }
                            });
                    }

                },
                {
                    key: "engageAgent",
                    description: "CES Score by engage agent",
                    title: "CES Score by engage agent",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/cesEngageAgent",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    yAxis: "cesScore",
                                    xAxis: "engageagenttag",
                                    caption: "Volume: Survey response breakdown",
                                    width: 3,
                                    height: 3,
                                    showAxisLabel: true,
                                    showAxis2Label: true,
                                    filter: "Segment IS 190611",
                                    hideOther: true,
                                    show: [
                                        {
                                            geometry: "rows",
                                            yAxis: "cesScore",
                                            "colour-palette": "be14-gray",
                                            "colour-index": 4
                                        }
                                    ],
                                }
                            });
                    }

                }
            ]
        },
        {
            id: "surveys/csat",
            title: "Customer Satisfaction Score (CSAT)",
            description: "This metric measures the customer satisfaction with a product, service, or interaction.",
            keywords: ["customer", "satisfaction", "survey", "csat"],
            image: require("../assets/surveys/csat.png"),
            widgetDesc: {
                type: "FantasticChart",
                yAxis: "csatScore",
                xAxis: "published",
                caption: "Volume: Survey response breakdown",
                width: 8,
                height: 3,
                showAxisLabel: true,
                showAxis2Label: true,
                filter: "Segment IS 190611",
                show: [
                    {
                        geometry: "columns",
                        yAxis: "csatScore",
                        "colour-palette": "be14-gray",
                        "colour-index": 4
                    }
                ],
            },
            options: [
                {
                    key: "socialNetwork",
                    description: "CSAT Score by social network",
                    title: "CSAT Score by social network",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/csatSocialNetwork",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    yAxis: "csatScore",
                                    xAxis: "socialNetwork",
                                    caption: "Volume: Survey response breakdown",
                                    width: 4,
                                    height: 3,
                                    showAxisLabel: true,
                                    showAxis2Label: true,
                                    filter: "Segment IS 190611",
                                    show: [
                                        {
                                            geometry: "rows",
                                            yAxis: "csatScore",
                                        }
                                    ],
                                }
                            });
                    }

                },
                {
                    key: "engageAgent",
                    description: "CSAT Score by engage agent",
                    title: "CSAT Score by engage agent",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/cesEngageAgent",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    yAxis: "csatScore",
                                    xAxis: "engageagenttag",
                                    caption: "Volume: Survey response breakdown",
                                    width: 3,
                                    height: 3,
                                    showAxisLabel: true,
                                    showAxis2Label: true,
                                    filter: "Segment IS 190611",
                                    hideOther: true,
                                    show: [
                                        {
                                            geometry: "rows",
                                            yAxis: "csatScore",
                                            "colour-palette": "be14-gray",
                                            "colour-index": 4
                                        }
                                    ],
                                }
                            });
                    }
                }
            ]
        },
        {
            id: "surveys/fcr",
            title: "First Contact Resolution Score (FCR)",
            description: "This metric measures the percentage of customer inquiries or issues that have been resolved.",
            keywords: ["first", "contact", "resolution", "fcr", "survey"],
            image: require("../assets/surveys/ces.png"),
            widgetDesc: {
                type: "FantasticChart",
                yAxis: "fcrScore",
                xAxis: "published",
                caption: "Volume: Survey response breakdown",
                width: 8,
                height: 3,
                showAxisLabel: true,
                showAxis2Label: true,
                filter: "Segment IS 190611",
                show: [
                    {
                        geometry: "columns",
                        yAxis: "fcrScore",
                        "colour-palette": "be14-gray",
                        "colour-index": 4
                    }
                ],
            },
            options: [
                {
                    key: "socialNetwork",
                    description: "FCR Score by social network",
                    title: "FCR Score by social network",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/fcrSocialNetwork",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    yAxis: "fcrScore",
                                    xAxis: "socialNetwork",
                                    caption: "Volume: Survey response breakdown",
                                    width: 4,
                                    height: 3,
                                    showAxisLabel: true,
                                    showAxis2Label: true,
                                    filter: "Segment IS 190611",
                                    show: [
                                        {
                                            geometry: "rows",
                                            yAxis: "fcrScore",
                                        }
                                    ],
                                }
                            });
                    }

                },
                {
                    key: "engageAgent",
                    description: "FCR Score by engage agent",
                    title: "FCR Score by engage agent",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/fcrEngageAgent",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    yAxis: "fcrScore",
                                    xAxis: "engageagenttag",
                                    caption: "Volume: Survey response breakdown",
                                    width: 3,
                                    height: 3,
                                    showAxisLabel: true,
                                    showAxis2Label: true,
                                    filter: "Segment IS 190611",
                                    hideOther: true,
                                    show: [
                                        {
                                            geometry: "rows",
                                            yAxis: "fcrScore",
                                            "colour-palette": "be14-gray",
                                            "colour-index": 4
                                        }
                                    ],
                                }
                            });
                    }
                }
            ]
        },
        {
            id: "surveys/npsRating",
            title: "Volume: Net Promoter Score (NPS)",
            description: "This metric measures the level of customer advocacy.",
            keywords: ["nps", "promoter", "survey", "rating"],
            image: require("../assets/surveys/npsVolume.png"),
            widgetDesc: {
                type: "FantasticChart",
                yAxis: "mentionCount",
                xAxis: "194038",
                caption: "Volume by NPS Rating",
                width: 4,
                height: 3,
                showAxisLabel: true,
                showAxis2Label: true,
                filter: "Segment IS 190611",
                xSortOrder: "descending",
                show: [
                    {
                        yAxis: "mentionCount",
                        geometry: "rows",
                        'colour-palette': "custom",  // Note: hyphenated property names need quotes
                        'colour-palette-custom': [
                            "#8b0000",
                            "#b22222",
                            "#d32f2f",
                            "#e65100",
                            "#ef6c00",
                            "#f57c00",
                            "#ff9800",
                            "#ffc107",
                            "#ffeb3b",
                            "#228b22",
                            "#006400",
                            "#318F58",
                            "#8a2be2",
                            "#a52a2a",
                            "#deb887",
                            "#ffd700",
                            "#7fff00",
                            "#d2691e"
                        ],
                        'colour-index': 0,
                        pointSettings: {
                            194040: {
                                'colour-index': 0,
                                'colour-palette': "custom"
                            },
                            194041: {
                                'colour-index': 1,
                                'colour-palette': "custom"
                            },
                            194042: {
                                'colour-index': 2,
                                'colour-palette': "custom"
                            },
                            194043: {
                                'colour-index': 3,
                                'colour-palette': "custom"
                            },
                            194044: {
                                'colour-index': 4,
                                'colour-palette': "custom"
                            },
                            194045: {
                                'colour-index': 5,
                                'colour-palette': "custom"
                            },
                            194046: {
                                'colour-index': 6,
                                'colour-palette': "custom"
                            },
                            194047: {
                                'colour-index': 7,
                                'colour-palette': "custom"
                            },
                            194048: {
                                'colour-index': 8,
                                'colour-palette': "custom"
                            },
                            194049: {
                                'colour-index': 9,
                                'colour-palette': "custom"
                            },
                            194050: {
                                'colour-index': 10,
                                'colour-palette': "custom"
                            }
                        }
                    }
                ]
            },
            options: [
                {
                    key: "time",
                    description: "Volume: Net Promoter Score (NPS) over time",
                    title: "Volume by NPS ratings over time",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/npsSocialNetwork",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    width: 8,
                                    height: 3,
                                    caption: "Volume by NPS ratings over time",
                                    xAxis: "published",
                                    show: [
                                        {
                                            yAxis: "mentionCount",
                                            geometry: "columns",
                                            'colour-palette': "custom",
                                            'colour-palette-custom': [
                                                "#8b0000",
                                                "#b22222",
                                                "#d32f2f",
                                                "#e65100",
                                                "#ef6c00",
                                                "#f57c00",
                                                "#ff9800",
                                                "#ffc107",
                                                "#ffeb3b",
                                                "#228b22",
                                                "#006400",
                                                "#318F58",
                                                "#8a2be2",
                                                "#a52a2a",
                                                "#deb887",
                                                "#ffd700",
                                                "#7fff00",
                                                "#d2691e"
                                            ],
                                            'colour-index': 0,
                                            pointSettings: {
                                                194040: {
                                                    'colour-index': 0,
                                                    'colour-palette': "custom"
                                                },
                                                194041: {
                                                    'colour-index': 1,
                                                    'colour-palette': "custom"
                                                },
                                                194042: {
                                                    'colour-index': 2,
                                                    'colour-palette': "custom"
                                                },
                                                194043: {
                                                    'colour-index': 3,
                                                    'colour-palette': "custom"
                                                },
                                                194044: {
                                                    'colour-index': 4,
                                                    'colour-palette': "custom"
                                                },
                                                194045: {
                                                    'colour-index': 5,
                                                    'colour-palette': "custom"
                                                },
                                                194046: {
                                                    'colour-index': 6,
                                                    'colour-palette': "custom"
                                                },
                                                194047: {
                                                    'colour-index': 7,
                                                    'colour-palette': "custom"
                                                },
                                                194048: {
                                                    'colour-index': 8,
                                                    'colour-palette': "custom"
                                                },
                                                194049: {
                                                    'colour-index': 9,
                                                    'colour-palette': "custom"
                                                },
                                                194050: {
                                                    'colour-index': 10,
                                                    'colour-palette': "custom"
                                                }
                                            }
                                        }
                                    ],
                                    stacked: true,
                                    xSortOrder: "ascending",
                                    compareBucketSortOrder: "ascending",
                                    geometry: "columns",
                                    yAxis: "mentionCount",
                                    'manual-caption': true,
                                    compare: "194038",
                                    filter: "Segment IS 190611",
                                }
                            });
                    }

                },
            ]
        },
        {
            id: "surveys/cesAgreement",
            title: " Volume by CES agreement scale",
            description: "This metric measures the effort required to get an issue resolved.",
            keywords: ["ces", "customer", "effort", "scale", "agreement", "survey"],
            image: require("../assets/surveys/cesVolume.png"),
            widgetDesc: {
                type: "FantasticChart",
                yAxis: "mentionCount",
                xAxis: "196490",
                caption: " Volume by CES agreement scale",
                width: 4,
                height: 3,
                showAxisLabel: true,
                showAxis2Label: true,
                filter: "Segment IS 190611",
                xSortOrder: "ascending",
                show: [
                    {
                        yAxis: "mentionCount",
                        geometry: "rows",
                        'colour-palette': "custom",  // Note: hyphenated property names need quotes
                        "colour-palette-custom": [
                            "#006400",
                            "#318f58",
                            "#e1c16e",
                            "#e65100",
                            "#880808",
                            "#D22D6F",
                            "#fc6a4b",
                            "#E53E39",
                            "#FFD658",
                            "#E8AF44",
                            "#43C278",
                            "#318F58",
                            "#8a2be2",
                            "#a52a2a",
                            "#deb887",
                            "#5f9ea0",
                            "#7fff00",
                            "#d2691e"
                        ],
                        'colour-index': 0,
                        pointSettings: {
                            196492: {
                                'colour-index': 0,
                                'colour-palette': "custom"
                            },
                            196493: {
                                'colour-index': 1,
                                'colour-palette': "custom"
                            },
                            196494: {
                                'colour-index': 2,
                                'colour-palette': "custom"
                            },
                            196495: {
                                'colour-index': 3,
                                'colour-palette': "custom"
                            },
                            196496: {
                                'colour-index': 4,
                                'colour-palette': "custom"
                            }
                        }
                    }
                ]
            },
            options: [
                {
                    key: "time",
                    description: " Volume by CES agreement scale over time",
                    title: " Volume by CES agreement scale over time",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/cesRating",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    width: 8,
                                    height: 3,
                                    caption: " Volume by CES agreement scale over time",
                                    xAxis: "published",
                                    show: [
                                        {
                                            yAxis: "mentionCount",
                                            geometry: "columns",
                                            'colour-palette': "custom",
                                            "colour-palette-custom": [
                                                "#006400",
                                                "#318f58",
                                                "#e1c16e",
                                                "#e65100",
                                                "#880808",
                                                "#D22D6F",
                                                "#fc6a4b",
                                                "#E53E39",
                                                "#FFD658",
                                                "#E8AF44",
                                                "#43C278",
                                                "#318F58",
                                                "#8a2be2",
                                                "#a52a2a",
                                                "#deb887",
                                                "#5f9ea0",
                                                "#7fff00",
                                                "#d2691e"
                                            ],
                                            'colour-index': 0,
                                            pointSettings: {
                                                196492: {
                                                    'colour-index': 0,
                                                    'colour-palette': "custom"
                                                },
                                                196493: {
                                                    'colour-index': 1,
                                                    'colour-palette': "custom"
                                                },
                                                196494: {
                                                    'colour-index': 2,
                                                    'colour-palette': "custom"
                                                },
                                                196495: {
                                                    'colour-index': 3,
                                                    'colour-palette': "custom"
                                                },
                                                196496: {
                                                    'colour-index': 4,
                                                    'colour-palette': "custom"
                                                }
                                            }
                                        }
                                    ],
                                    stacked: true,
                                    xSortOrder: "ascending",
                                    xSortByField: "default",
                                    compareBucketSortByField: "default",
                                    geometry: "columns",
                                    yAxis: "mentionCount",
                                    'manual-caption': true,
                                    compare: "196490",
                                    filter: "Segment IS 190611",
                                }
                            });
                    }

                },
            ]
        },
        {
            id: "surveys/csatRating",
            title: "Volume: Customer Satisfaction (CSAT) Score",
            description: "This metric measures the customer satisfaction with a product, service, or interaction.",
            keywords: ["csat", "customer", "satisfaction", "score", "rating", "survey"],
            image: require("../assets/surveys/csatVolume2.png"),
            widgetDesc: {
                type: "FantasticChart",
                yAxis: "mentionCount",
                xAxis: "167447",
                caption: "Volume by CSAT ratings",
                width: 4,
                height: 3,
                showAxisLabel: true,
                showAxis2Label: true,
                filter: "Segment IS 190611",
                xSortOrder: "descending",
                compareBucketSortOrder: "descending",
                show: [
                    {
                        yAxis: "mentionCount",
                        geometry: "rows",
                        'colour-palette': "custom",  // Note: hyphenated property names need quotes
                        'colour-palette-custom': [
                            "#006400",
                            "#318f58",
                            "#e1c16e",
                            "#e65100",
                            "#880808",
                            "#D22D6F",
                            "#fc6a4b",
                            "#E53E39",
                            "#FFD658",
                            "#E8AF44",
                            "#43C278",
                            "#318F58",
                            "#8a2be2",
                            "#a52a2a",
                            "#deb887",
                            "#5f9ea0",
                            "#7fff00",
                            "#d2691e"
                        ],
                        pointSettings: {
                            167449: {
                                'colour-index': 4,
                                'colour-palette': "custom"
                            },
                            167450: {
                                'colour-index': 3,
                                'colour-palette': "custom"
                            },
                            167451: {
                                'colour-index': 2,
                                'colour-palette': "custom"
                            },
                            167452: {
                                'colour-index': 1,
                                'colour-palette': "custom"
                            },
                            167453: {
                                'colour-index': 0,
                                'colour-palette': "custom"
                            },
                            196492: {
                                'colour-index': 0,
                                'colour-palette': "custom"
                            },
                            196493: {
                                'colour-index': 1,
                                'colour-palette': "custom"
                            },
                            196494: {
                                'colour-index': 2,
                                'colour-palette': "custom"
                            },
                            196495: {
                                'colour-index': 3,
                                'colour-palette': "custom"
                            },
                            196496: {
                                'colour-index': 4,
                                'colour-palette': "custom"
                            }
                        }
                    }
                ]
            },
            options: [
                {
                    key: "time",
                    description: "Volume: Customer Satisfaction (CSAT) Score over time",
                    title: "Volume by CSAT ratings over time",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/csatRating",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    width: 8,
                                    height: 3,
                                    caption: "Volume by CSAT ratings over time",
                                    xAxis: "published",
                                    show: [
                                        {
                                            yAxis: "mentionCount",
                                            geometry: "columns",
                                            'colour-palette': "custom",
                                            "colour-palette-custom": [
                                                "#880808",
                                                "#e65100",
                                                "#e1c16e",
                                                "#318f58",
                                                "#006400",
                                                "#D22D6F",
                                                "#fc6a4b",
                                                "#E53E39",
                                                "#FFD658",
                                                "#E8AF44",
                                                "#43C278",
                                                "#318F58",
                                                "#8a2be2",
                                                "#a52a2a",
                                                "#deb887",
                                                "#5f9ea0",
                                                "#7fff00",
                                                "#d2691e"
                                            ],
                                            "colour-index": 0
                                        }
                                    ],
                                    stacked: true,
                                    xSortOrder: "ascending",
                                    xSortByField: "default",
                                    compareBucketSortByField: "default",
                                    geometry: "columns",
                                    yAxis: "mentionCount",
                                    'manual-caption': true,
                                    compare: "167447",
                                    filter: "Segment IS 190611",
                                }
                            });
                    }

                },
            ]
        },
        {
            id: "surveys/fcrScale",
            title: "Volume by FCR Scale",
            description: "This metric measures the percentage of customer inquiries or issues that have been resolved.",
            keywords: ["first", "contact", "resolution", "fcr", "survey"],
            image: require("../assets/surveys/fcrVolume.png"),
            widgetDesc: {
                type: "FantasticChart",
                yAxis: "mentionCount",
                xAxis: "194051",
                caption: "Volume by FCR Scale",
                width: 4,
                height: 3,
                showAxisLabel: true,
                showAxis2Label: true,
                filter: "Segment IS 190611",
                xSortOrder: "ascending",
                compareBucketSortOrder: "descending",
                show: [
                    {
                        yAxis: "mentionCount",
                        geometry: "rows",
                        'colour-palette': "custom",  // Note: hyphenated property names need quotes
                        "colour-palette-custom": [
                            "#006400",
                            "#bc4333",
                            "#DF71FC",
                            "#9856B0",
                            "#ff58a3",
                            "#D22D6F",
                            "#fc6a4b",
                            "#E53E39",
                            "#FFD658",
                            "#E8AF44",
                            "#43C278",
                            "#318F58",
                            "#8a2be2",
                            "#a52a2a",
                            "#deb887",
                            "#5f9ea0",
                            "#7fff00",
                            "#d2691e"
                        ],
                        pointSettings: {
                            194053: {
                                "colour-index": 0,
                                "colour-palette": "custom"
                            },
                            194054: {
                                "colour-index": 1,
                                "colour-palette": "custom"
                            }
                        }
                    }
                ]
            },
            options: [
                {
                    key: "time",
                    description: "Volume by FCR Scale over time",
                    title: "Volume by FCR Scale over time",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/fcrScale",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    width: 8,
                                    height: 3,
                                    caption: "Volume by FCR Scale over time",
                                    xAxis: "published",
                                    show: [
                                        {
                                            yAxis: "mentionCount",
                                            geometry: "columns",
                                            'colour-palette': "custom",
                                            "colour-palette-custom": [
                                                "#006400",
                                                "#bc4333",
                                                "#DF71FC",
                                                "#9856B0",
                                                "#ff58a3",
                                                "#D22D6F",
                                                "#fc6a4b",
                                                "#E53E39",
                                                "#FFD658",
                                                "#E8AF44",
                                                "#43C278",
                                                "#318F58",
                                                "#8a2be2",
                                                "#a52a2a",
                                                "#deb887",
                                                "#5f9ea0",
                                                "#7fff00",
                                                "#d2691e"
                                            ],
                                            pointSettings: {
                                                194053: {
                                                    "colour-index": 0,
                                                    "colour-palette": "custom"
                                                },
                                                194054: {
                                                    "colour-index": 1,
                                                    "colour-palette": "custom"
                                                }
                                            }
                                        }
                                    ],
                                    stacked: true,
                                    xSortOrder: "ascending",
                                    xSortByField: "default",
                                    compareBucketSortByField: "default",
                                    geometry: "columns",
                                    yAxis: "mentionCount",
                                    'manual-caption': true,
                                    compare: "194051",
                                    filter: "Segment IS 190611",
                                }
                            });
                    }
                },
            ]
        },
        {
            id: "surveys/npsGroupings",
            title: " Volume: Net Promoter Score Breakdown",
            description: "The count of responses segmented into NPS categories—Detractors (0-6), Passives (7-8), and Promoters (9-10).",
            keywords: ["nps", "net", "promoter", "score", "survey"],
            image: require("../assets/surveys/npsPie.png"),
            widgetDesc: {
                type: "FantasticChart",
                width: 4,
                height: 3,
                caption: "Volume by NPS breakdown as pie chart",
                xAxis: "npsBreakdown",
                stacked: true,
                xSortByField: "default",
                compareBucketSortByField: "default",
                durationFormat: "default",
                durationHoursThreshold: 3,
                show: [
                    {
                        opacity: 100,
                        yAxis: "mentionCount",
                        geometry: "pie",
                        isDonut: true,
                        labelPlacement: "outside",
                        'colour-palette': "custom",
                        'colour-index': 0,
                        'colour-palette-custom': [
                            "#acacac",
                            "#8b0000",
                            "#e1c16e",
                            "#006400",
                            "#ff58a3",
                            "#D22D6F",
                            "#fc6a4b",
                            "#E53E39",
                            "#FFD658",
                            "#E8AF44",
                            "#43C278",
                            "#318F58",
                            "#8a2be2",
                            "#a52a2a",
                            "#deb887",
                            "#5f9ea0",
                            "#7fff00",
                            "#d2691e"
                        ]
                    }
                ],
                xSortOrder: "descending",
                compareBucketSortOrder: "descending",
                geometry: "columns",
                yAxis: "mentionCount",
                useOutsideLabels: false,
                isDonut: false,
                'manual-caption': true,
                filter: "Segment IS 190611",
            },
            options: [
                {
                    key: "row",
                    description: "Volume by NPS breakdown as row chart",
                    title: "Volume by NPS breakdown as row chart",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/npsGroupingsRowChart",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    width: 4,
                                    height: 2,
                                    caption: "Volume by NPS breakdown as row chart",
                                    xAxis: "brand",
                                    stacked: true,
                                    xSortByField: "default",
                                    compareBucketSortByField: "default",
                                    durationFormat: "default",
                                    durationHoursThreshold: 3,
                                    show: [
                                        {
                                            yAxis: "mentionCount",
                                            geometry: "rows",
                                            'colour-palette': "custom",
                                            'colour-index': 1,
                                            'colour-palette-custom': [
                                                "#acacac",
                                                "#8b0000",
                                                "#e1c16e",
                                                "#006400",
                                                "#ff58a3",
                                                "#D22D6F",
                                                "#fc6a4b",
                                                "#E53E39",
                                                "#FFD658",
                                                "#E8AF44",
                                                "#43C278",
                                                "#318F58",
                                                "#8a2be2",
                                                "#a52a2a",
                                                "#deb887",
                                                "#5f9ea0",
                                                "#7fff00",
                                                "#d2691e"
                                            ]
                                        }
                                    ],
                                    xSortOrder: "ascending",
                                    compareBucketSortOrder: "descending",
                                    geometry: "columns",
                                    yAxis: "mentionCount",
                                    compare: "npsBreakdown",
                                    filter: "Segment IS 190611",
                                    id: null
                                }
                            });
                    }
                },
                {
                    key: "time",
                    description: "Volume by NPS breakdown over time",
                    title: "Volume by NPS breakdown over time",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/npsGroupingsOverTime",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    width: 8,
                                    height: 4,
                                    caption: "NPS breakdown comparison of volume",
                                    xAxis: "published",
                                    stacked: true,
                                    xSortByField: "default",
                                    compareBucketSortByField: "default",
                                    durationFormat: "default",
                                    durationHoursThreshold: 3,
                                    show: [
                                        {
                                            yAxis: "mentionCount",
                                            geometry: "columns",
                                            isDonut: true,
                                            labelPlacement: "outside",
                                            'colour-palette': "custom",
                                            'colour-index': 1,
                                            'colour-palette-custom': [
                                                "#acacac",
                                                "#8b0000",
                                                "#e1c16e",
                                                "#006400",
                                                "#ff58a3",
                                                "#D22D6F",
                                                "#fc6a4b",
                                                "#E53E39",
                                                "#FFD658",
                                                "#E8AF44",
                                                "#43C278",
                                                "#318F58",
                                                "#8a2be2",
                                                "#a52a2a",
                                                "#deb887",
                                                "#5f9ea0",
                                                "#7fff00",
                                                "#d2691e"
                                            ]
                                        }
                                    ],
                                    xSortOrder: "ascending",
                                    compareBucketSortOrder: "descending",
                                    geometry: "columns",
                                    yAxis: "mentionCount",
                                    compare: "npsBreakdown",
                                    'manual-caption': true,
                                    filter: "Segment IS 190611",
                                    id: null
                                }
                            });
                    }
                },
            ]
        },
        {
            id: "surveys/verbatim",
            title: " Volume: Verbatim survey questions",
            description: "Open-ended qualitative feedback provided by customers in survey responses, offering insights into their experiences and sentiments",
            keywords: ["verbatim", "survey"],
            image: require("../assets/surveys/verbatim.png"),
            widgetDesc: {
                type: "FantasticChart",
                width: 8,
                height: 4,
                caption: "Volume",
                xAxis: "published",
                xSortByField: "default",
                compareBucketSortByField: "default",
                durationHoursThreshold: 3,
                show: [
                    {
                        yAxis: "mentionCount",
                        geometry: "columns",
                        labelPlacement: "inside",
                        "colour-index": 0
                    }
                ],
                xSortOrder: "ascending",
                compareBucketSortOrder: "descending",
                filter: "Segment IS 194068 AND Segment IS 190611",
                geometry: "columns",
                yAxis: "mentionCount",
                lineWidth: 2,
                lineCurve: "smooth",
            },
            options: [
                {
                    key: "sentiment",
                    description: "Volume of verbatim by sentiment",
                    title: "Volume of verbatim by sentiment",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/npsGroupingsRowChart",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    width: 4,
                                    height: 4,
                                    caption: "Volume of verbatim by sentiment",
                                    filter: "Segment IS 194068 AND Segment IS 190611",
                                    xAxis: "sentiment",
                                    xSortByField: "default",
                                    compareBucketSortByField: "default",
                                    durationFormat: "default",
                                    durationHoursThreshold: 3,
                                    show: [
                                        {
                                            yAxis: "mentionPercent",
                                            geometry: "columns",
                                            onlySupportHandles: true,
                                            labelPlacement: "inside",
                                            "colour-index": 1
                                        }
                                    ],
                                    xSortOrder: "ascending",
                                    compareBucketSortOrder: "descending",
                                    "manual-caption": true,
                                    geometry: "columns",
                                    yAxis: "mentionCount",
                                }
                            });
                    }
                },
                {
                    key: "socialNetwork",
                    description: "Verbatim by social network",
                    title: "Verbatim by social network",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/npsGroupingsOverTime",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    width: 4,
                                    height: 4,
                                    caption: "Percentage of verbatim volume by social network",
                                    filter: "Segment IS 194068 AND Segment IS 190611",
                                    xAxis: "socialNetwork",
                                    xSortByField: "default",
                                    compareBucketSortByField: "default",
                                    durationFormat: "default",
                                    durationHoursThreshold: 3,
                                    show: [
                                        {
                                            yAxis: "mentionPercent",
                                            geometry: "columns",
                                            labelPlacement: "inside",
                                            "colour-index": 1
                                        }
                                    ],
                                    xSortOrder: "descending",
                                    compareBucketSortOrder: "descending",
                                    "manual-caption": true,
                                    geometry: "columns",
                                    yAxis: "mentionCount",
                                }
                            });
                    }
                },
                {
                    key: "verbatim/negative",
                    description: "Example Verbatim: Negative",
                    title: "Example Verbatim: Negative",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/negative",
                                widgetDesc: {
                                    type: "Conversation",
                                    width: 4,
                                    height: 4,
                                    caption: "Example Verbatim: Negative",
                                    filter: "Process IS VERIFIED AND Sentiment <= -1 AND Segment IS 194068 AND Segment IS 190611",
                                    "colour-index": 3,
                                    show: "top",
                                    "num-mentions": "10",
                                    orderby: "engagement desc",
                                    seed: 1,
                                    "hidden-title": false,
                                }
                            });
                    }
                },
                {
                    key: "verbatim/neutral",
                    description: "Example Verbatim: Neutral",
                    title: "Example Verbatim: Neutral",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/neutral",
                                widgetDesc: {
                                    type: "Conversation",
                                    width: 4,
                                    height: 4,
                                    caption: "Example Verbatim: Neutral",
                                    filter: "Process IS VERIFIED AND Sentiment = 1 AND Segment IS 194068 AND Segment IS 190611",
                                    "colour-index": 3,
                                    show: "top",
                                    "num-mentions": "10",
                                    orderby: "engagement desc",
                                    seed: 1,
                                    "hidden-title": false,
                                }
                            });
                    }
                },
                {
                    key: "verbatim/positive",
                    description: "Example Verbatim: Positive",
                    title: "Example Verbatim: Positive",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "surveys/positive",
                                widgetDesc: {
                                    type: "Conversation",
                                    width: 4,
                                    height: 4,
                                    caption: "Example Verbatim: Positive",
                                    filter: "Process IS VERIFIED AND Sentiment >= 2 AND Segment IS 194068 AND Segment IS 190611",
                                    "colour-index": 3,
                                    show: "top",
                                    "num-mentions": "10",
                                    orderby: "engagement desc",
                                    seed: 1,
                                    "hidden-title": false,
                                }
                            });
                    }
                },
            ]
        }


    ]
});

export default survey;

export async function init() {
    if (features.surveyReportingEnhancements()) {
        survey.hide = false;
    }
}