<template>
    <div class="deq-reset deq-goodness-results-component">
        <template v-if="!isLoading">
            <p class="hint">
                Please review the volume estimates carefully. Note that the estimated volumes do not
                take into account exclusions, phrase or root brand filters and will over estimate phrases with multiple
                positive terms. Use Rewind if you need more accurate estimates.
            </p>

            <div class="gr-table">
                <div class="gr-header gr-phrase gr-row">Phrase</div>
                <div class="gr-header gr-volume gr-row">Volume</div>

                <template v-if="goodnessResults">
                    <div v-for="(phraseResult) in goodnessResults" :key="phraseResult.query" class="gr-phrase-results">
                        <div class="gr-phrase gr-query-result gr-row">
                            <span v-for="queryTerms in getParsedPhrase(phraseResult.query)" :key="queryTerms" v-html="renderPhrase(queryTerms)"></span>
                        </div>
                        <div class="gr-volume gr-volume-result gr-row">
                            {{formattedNumber(phraseResult.mentionsTotal)}}
                        </div>
                    </div>
                </template>

                <div class="gr-total gr-phrase gr-row">Total</div>
                <div class="gr-total gr-volume gr-row">{{formattedNumber(getVolumeTotal)}}</div>
            </div>

            <CheckboxInput v-model="userConfirmed" label="I have reviewed the volume estimates and want to add the phrases"
                           class="gr-user-confirmation"></CheckboxInput>
        </template>

        <p v-if="isLoading">
            <LoadingMessage :message="'Loading phrase goodness results'"  class="gr-loader"></LoadingMessage>
        </p>

    </div>

</template>

<script>

import {getGoodness} from "@/data/goodness";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import LoadingMessage from "@/components/LoadingMessage.vue";
import {formatNumber} from "@/app/utils/Format";
import {isPhraseANo, parsePhraseString, phraseNeedsQuotes} from "@/app/utils/Phrases";
import {getRootBrandFor} from "@/app/utils/Brands";
import {notifyUserOfError} from "@/app/framework/notifications/Notifications";
import {escapeHtml, removeQuotes} from "@/app/utils/StringUtils";
import renderPhrase from "@/helpers/renderPhrase";

const EVENT_NAME = 'confirmation-changed';
export default {
    name: "GoodnessResultsComponent",
    components: {LoadingMessage, CheckboxInput},
    props: {
        termsList: {
            type: Array,
            required: true,
        },

        parentId: {
            type: Number,
            required: true,
        },

        mentionFilter: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            /** @type {Goodness[]}*/
            goodnessResults: undefined,
            /** @type {Goodness[]}*/
            badQueries: [],
            hasLevelNoQueries: false,
            userConfirmed: false,
            isLoading: true,
            constructedQueries: []
        }
    },

    watch: {
        userConfirmed() {
            this.goodnessResults.filter(queryResult => queryResult.level === 'NO')

            this.$emit(EVENT_NAME, {goodToSave: this.userConfirmed && !this.badQueries.length && !this.hasLevelNoQueries, queryList: this.constructedQueries});
        }
    },

    computed: {
        formattedNumber() {
            return formatNumber;
        },

        formattedQuery() {
            return removeQuotes;
        },

        getVolumeTotal() {
          return this.goodnessResults.reduce((acc, queryResult) => acc += queryResult.mentionsTotal, 0)
        },

        getParsedPhrase() {
          return parsePhraseString
        }
    },

    async mounted() {
        this.isLoading = true;

        this.constructedQueries = this.constructQueries();
        this.goodnessResults = await this.getGoodness(this.constructedQueries);
        this.isLoading = false;
    },

    methods: {
        renderPhrase,

        constructQueries() {
            const localQueries = this.termsList.map(phrase => parsePhraseString(phrase))

            const quotedQueryList = localQueries.map(phrase => {
                if (phraseNeedsQuotes(phrase)) {
                    return phrase.map(t => {
                        if (t.charAt(0) === '-') t = '"-' + t.substring(1) + '"';
                        else t = '"' + t + '"';
                        return t;
                    })
                }
            })
            return quotedQueryList.map(query => query.join(' '))
        },

        async getGoodness(queryList) {
            try {
                const goodnessRes = await getGoodness(this.parentId, queryList);

                this.badQueries = goodnessRes.filter(query => {
                    const b = isPhraseANo(getRootBrandFor(this.parentId), {mentionFilter: this.mentionFilter},
                        {level: query.level, mentionsTotal: query.mentionsTotal});
                    if (b) {
                        return {query: query.query, mentionsTotal: query.mentionsTotal}
                    }
                });

                this.hasLevelNoQueries = goodnessRes
                    .filter(queryRes => queryRes.level === 'NO')
                    .map(query => {return true})
                    .reduce((acc, bool) => bool || acc, false);

                this.badQueries.forEach(badQuery => {
                    const msg = `The phrase <strong>${badQuery.query}</strong> cannot be added to this account. It is
                                    estimated to bring in <strong>${formatNumber(badQuery.mentionsTotal)}</strong> mentions
                                    in a month, exceeding this brand's volume limit. Please add restrictions to this brand
                                    and/or phrase.`
                    notifyUserOfError(escapeHtml(msg),true);
                });


                if (!this.badQueries.length && this.hasLevelNoQueries) {
                    const msg = `Carefully consider the volumes and account setup for these phrases. Some of them are
                    expected to exceed this brand's volume limit.`;
                    notifyUserOfError(escapeHtml(msg),true);
                }

                return goodnessRes;
            } catch (error) {
                console.warn('An error occurred while retrieving goodness results: ', error)
            }

        }
    }
}
</script>



<style scoped lang="sass">
.gr-loader
    --loading-message-delay: 50ms
    margin-top: 0

.hint
    color: #999999
    margin-bottom: 10px

.gr-row
    border-bottom: 1px solid black

.gr-table
    display: grid
    grid-template-columns: 1fr 1fr
    border-radius: 3px
    border: 1px solid black
    border-bottom: none

    .gr-phrase
        border-right: 1px solid black

    .gr-phrase, .gr-volume
        padding: 5px

    .gr-header
        background-color: #222

    .gr-phrase-results
        display: contents

        .gr-query-result, .gr-volume-result
            background-color: #333
            display: flex
            flex-direction: row
            gap: 5px

    .gr-total
        background-color: #333

.gr-user-confirmation
    margin-top: 10px


</style>