<template>
    <div class="control-group" :class="{error: error}" @paste.stop>
        <label v-if="label">{{label}}</label>
        <div class="controls">
            <textarea v-if="rows" v-model="text" :placeholder="placeholder" :disabled="disabled" ref="input" :style="width ? `width: ${width}` : ``" class="span12" :rows="rows"/>
            <input v-else :type="this.type" v-model="text" :placeholder="placeholder" ref="input" :style="width ? `width: ${width}` : ``" class="span12" @blur="onblur"
                   @focus="$emit('focus')">
            <span v-if="error" class="help-block error-message">{{error}}</span>
        </div>
    </div>
</template>

<script>
    /**
     * Text input box with validation, space for error message etc.. Specify rows to get a textarea.
     */
    export default {
        name: "TextInput",

        props: {
            value: [String, Number],
            type: { type: String, default: "text" },
            placeholder: String,
            width: { type: String, default: "" },
            label: String,
            rules: Array, // of functions, passed the input text and return true if ok or string error message
            required: Boolean,
            numeric: Boolean,
            disabled: { type: Boolean, default: false, },
            rows: Number,
            isInvalid: {type: Boolean}
        },

        data() {
            return {
                text: toText(this.value),
                error: null
            }
        },

        watch: {
            value(v) {
                this.text = toText(v)
            },

            text(v) {
                if (this.validate()) {
                    if (this.numeric) v = v ? parseFloat(v) : null
                }
                this.$emit('input', v)
            },

            error(v) {
                this.$emit('valid', !v)
            },

            isInvalid() {
                if (this.isInvalid) {
                    this.error = this.getError();
                } else {
                    this.error = '';
                }
            }
        },

        mounted() {
        },

        methods: {
            isValid() {
                return !this.error
            },

            validate() {
                let err = null
                if (this.required && (!this.text || !this.text.trim())) {
                    err = this.getError();
                } else {
                    if (this.rules) this.rules.find(r => {
                        let v = r(this.text)
                        return typeof v === "string" ? err = v : false
                    })
                }
                this.error = err
                return this.isValid()
            },

            getError() {
                return this.placeholder ? this.placeholder + " is required" : "Required"
            },

            focus() {
                this.$refs.input.focus()
            },

            onblur() {
                this.validate()
                this.$emit('blur')
            },
        }
    }

    function toText(v) {
        return v === undefined || v === null ? "" : ("" + v)
    }
</script>

<style scoped>

label {
    cursor: text;
}

</style>