<template>

    <div class="deq-checkbox-input" :style="{' flex-direction': getFlexDirection }">
        <input type="checkbox" v-model="internalData" :class="internalData ? 'icon-check' : ''" :disabled="disable">
        <label v-if="label.length">{{label}}</label>
        <slot></slot>
    </div>

</template>


<script>
export default {
    name: "CheckboxInput",

    model: {
        prop: 'originalValue',
        event: 'change'
    },

    props: {
        originalValue: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: '',
        },
        labelPosition: {
            type: String,
            required: false,
            default: 'left',
        },
        disable: {
            type: Boolean,
            required: false,
            default: false,
        }
    },

    data() {
        return {
            internalData: this.originalValue,
        }
    },

    watch: {
        originalValue(newValue) {
            this.internalData = this.originalValue;
            this.$emit('change', this.internalData);
        },

        internalData() {
            this.$emit('change', this.internalData);
        }
    },

    computed: {
        getFlexDirection() {
            if (this.labelPosition === 'top') {
                return 'column';
            } else if (this.labelPosition === 'left') {
                return 'row';
            } else {
                return 'row';
            }
        }
    }
}
</script>



<style scoped lang="sass">

input[type=checkbox]
    margin: 0
    height: 14px
    width: 14px

.deq-checkbox-input
    display: flex
    flex-direction: row
    align-items: center
    width: fit-content
    gap: 5px
    label
        margin-bottom: 0

    input
        appearance: none
        border: 1px solid var(--be-input-blur-border-color)
        box-shadow: var(--be-picker-ul-focus-box-shadow)
        border-radius: 3px
        height: 14px
        width: 14px
        display: grid
        place-content: center
        background-color: var(--be-colour-dark-grey)
        cursor: pointer

        &:focus
            appearance: none
            outline: none
            border-color: var(--be-input-focus-border-color)
            box-shadow: var(--be-input-focus-box-shadow)

        &:checked
            background-color: var(--be-filter-tag-hover-colour)

.icon-check
    &:before
        color: var(--colour-placeholder)
        margin-top: 2px

</style>