import {getProfileIcon} from "@/app/utils/Profiles";
import {getProfileDisplayName} from "@/setup/profiles/ProfileUtils";

export function updateSupportProfiles(brandSupportProfileIds, onlineProfiles) {
    const supportProfiles = {data: [], display: []};
    brandSupportProfileIds?.forEach(supProfileId => {
        const found = onlineProfiles.find((profile) => profile.id === supProfileId);
        if (found) {
            supportProfiles.data.push(found)
            // TODO: find out about this isNeg thing
            // const prefix = (isNeg ? "-" : "") + getProfileIcon(found.type);
            const prefix = getProfileIcon(found.type);
            const name = getProfileDisplayName(found.handle, found.handleId, found.name, found.type);
            supportProfiles.display.push(prefix + name)
        }
    })
    return supportProfiles;
}

export function updateOtherProfiles(brandOtherProfileIds, onlineProfiles) {
    const otherProfiles = {data: [], display: []};
    brandOtherProfileIds?.forEach(otherProfileId => {
        const found = onlineProfiles.find((profile) => profile.id === otherProfileId);
        if (found) {
            otherProfiles.data.push(found)

            // TODO: find out about this isNeg thing
            // const prefix = (isNeg ? "-" : "") + getProfileIcon(found.type);
            const prefix = getProfileIcon(found.type);
            const name = getProfileDisplayName(found.handle, found.handleId, found.name, found.type);
            otherProfiles.display.push(prefix + name)
        }
    })
    return otherProfiles
}


export function updateWorkingHours(brandWorkingHours) {
    let workingHours = '';

    if (brandWorkingHours) {
        let range = []

        let days = [
            {key: "monday", label: "Monday", order: 1}, {key: "tuesday", label: "Tuesday", order: 2},
            {key: "wednesday", label: "Wednesday", order: 3}, {key: "thursday", label: "Thursday", order: 4},
            {key: "friday", label: "Friday", order: 5}, {key: "saturday", label: "Saturday", order: 6},
            {key: "sunday", label: "Sunday", order: 7}
        ];

        days.forEach(day => {
            let curDayWorkingHours = brandWorkingHours.weekWorkTimes[day.key];
            if (curDayWorkingHours) {
                let newRange = true;
                let lastRange = range.at(-1);

                if (lastRange) {
                    if (lastRange.startTime === curDayWorkingHours.startTime && lastRange.endTime === curDayWorkingHours.endTime) {
                        if (day.order - lastRange.days.at(-1)?.order === 1) {
                            lastRange.days.push(day);
                            newRange = false;
                        }
                    }
                }

                if (newRange) {
                    range.push({
                        startTime: curDayWorkingHours.startTime,
                        endTime: curDayWorkingHours.endTime,
                        days: [day]
                    })
                }
            }
        });

        let description = [];
        // build range string
        if (range.length) {
            range.forEach(curRange => {
                let is24Hours = curRange.startTime === "00:00:00" && curRange.endTime === "24:00:00"
                let startTime = curRange.startTime.substring(0, curRange.startTime.length - 3);
                let endTime = curRange.endTime.substring(0, curRange.endTime.length - 3);

                if (curRange.days.length === 1) {
                    let day = curRange.days.at(0);
                    description.push(`${day.label} ${is24Hours ? '24 hours' : `${startTime} till ${endTime}`}`);
                } else {
                    let firstDay = curRange.days.at(0);
                    let lastDay = curRange.days.at(-1);

                    description.push(`${firstDay.label} - ${lastDay.label} ${is24Hours ? '24 hours' : `${startTime} till ${endTime}`}`);
                }
            });
        }

        if (brandWorkingHours.holidayCalendar) {
            description.push(`using holiday calendar: ${brandWorkingHours.holidayCalendar.title}`)
        }

        if (range.length) {
            workingHours = description.join(" | ")
        } else {
            workingHours = "(none)";
        }

    } else {
        workingHours = "(none)";


    }
    return workingHours;
}

export function initBrandSegments(brand) {
    let segmentMap = {};
    try {
        if (brand.segmentLists) {
            let brandSegments = JSON.parse(JSON.stringify(brand.segmentLists));
            brandSegments.sort((a, b) => a.label + a.subtitle > b.label + b.subtitle ? 1 : -1);

            if (brandSegments) {
                for (let i = 0; i < brandSegments.length; i++) {
                    let curSegment = brandSegments[i];
                    let segmentType = curSegment.label;

                    if (!segmentMap[segmentType]) {
                        segmentMap[segmentType] = {active: [], inactive: []}
                    }

                    let curList = curSegment.active ? segmentMap[segmentType].active : segmentMap[segmentType].inactive;

                    if (!curList.includes(curSegment.subtitle)) {
                        let seg = {
                            subtitle: curSegment.subtitle,
                            description: curSegment.description
                        }
                        curList.push(seg);
                    }
                }
            }
        }
        return segmentMap;
    } catch (error) {
        console.error("Unable to initialize brand segments", error);
    }
}