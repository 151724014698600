<template>
    <dialog-box class="machine-sentiment-dialog"
                :title="'Setting up machine sentiment for brand ' + encloseInDisplayQuotes(formatBrandName(brand)) "
                width="500px"
                stop-mousedown
                @close="close"
                modal>

        <div class="deq-reset">
            <h4>Machine learning for {{ formatBrandName(brand) }}</h4>

            <!-- region Table of setup controls -->
            <div class="msd__table">
                <dl>
                    <dt>Human Crowd Percentage</dt>
                    <dd class="row__percent">
                        <div v-if="humanCrowdPercentage !== 10 && humanCrowdPercentage !== 0">
                            <input type="number"
                                   :disabled="loading"
                                   v-model.number="humanCrowdPercentage"
                                   min="0" max="100"
                                   placeholder="A percentage"
                                   required>
                        </div>
                        <BeButtonGroup v-else>
                            <BeButton :active="humanCrowdPercentage === 10"
                                      tooltip="Ensure that 10% of the Crowd data is done by a person — this gives training data for us to use for AI"
                                      :disabled="loading"
                                      @click="humanCrowdPercentage = 10">10%</BeButton>
                            <BeButton :active="humanCrowdPercentage === 0"
                                      tooltip="Do not let humans in the Crowd work on this data"
                                      :disabled="loading"
                                      @click="humanCrowdPercentage = 0">None</BeButton>
                        </BeButtonGroup>
                    </dd>
                </dl>


                <dl>
                    <dt>Sentiment policy</dt>
                    <dd>
                        <BeButtonGroup>
                            <BeButton :active="sentimentPolicy === 'DEFAULT'"
                                      :disabled="loading"
                                      @click="sentimentPolicy='DEFAULT'">Default</BeButton>
                            <BeButton :active="sentimentPolicy === 'MANUAL'"
                                      :disabled="loading"
                                      @click="sentimentPolicy='MANUAL'">Manual</BeButton>
                        </BeButtonGroup>
                    </dd>
                </dl>

                <dl>
                    <dt>Sentiment</dt>
                    <dd v-if="sentimentPolicy === 'DEFAULT'">
                        Currently set to <SlottedTag no-close>{{formatPolicy(brand.sentiment) }}</SlottedTag>
                    </dd>
                    <dd v-if="sentimentPolicy === 'MANUAL'">
                        <BeButtonGroup>
                            <BeButton :active="sentiment === 'MACHINE'"
                                      :disabled="loading"
                                      @click="sentiment='MACHINE'">AI</BeButton>
                            <BeButton :disabled="loading"
                                      :active="sentiment === 'HUMAN'"
                                      @click="sentiment='HUMAN'">Human</BeButton>
                        </BeButtonGroup>
                    </dd>
                </dl>

                <dl>
                    <dt>Segments</dt>
                    <dd>
                        <BeButtonGroup>
                            <BeButton :active="segments === 'DEFAULT'"
                                      :disabled="loading"
                                      @click="segments='DEFAULT'">Default</BeButton>
                            <BeButton :active="segments === 'MACHINE'"
                                      :disabled="loading"
                                      @click="segments='MACHINE'">AI</BeButton>
                            <BeButton v-if="segments === 'HUMAN'"
                                      :disabled="loading"
                                      :active="segments === 'HUMAN'"
                                      @click="segments='HUMAN'">Human</BeButton>
                        </BeButtonGroup>
                    </dd>
                </dl>

                <dl>
                    <dt>Topics</dt>
                    <dd>
                        <BeButtonGroup>
                            <BeButton :active="topics === 'DEFAULT'"
                                      :disabled="loading"
                                      @click="topics='DEFAULT'">Default</BeButton>
                            <BeButton :active="topics === 'MACHINE'"
                                      :disabled="loading"
                                      @click="topics='MACHINE'">AI</BeButton>
                            <BeButton v-if="segments === 'HUMAN'"
                                      :disabled="loading"
                                      :active="topics === 'HUMAN'"
                                      @click="topics='HUMAN'">Human</BeButton>
                        </BeButtonGroup>
                    </dd>
                </dl>
            </div>
            <!-- endregion -->

            <!-- region Message about state of setup -->
            <div class="machine-sentiment-dialog__status">
                <div class="deq-callout">
                    <div v-if="isAiOnly" class="deq-align-center">
                        <i class="symbol-info"></i> This brand has been set to be an AI only brand.
                    </div>
                    <div v-else>
                        <i class="symbol-info"></i> This brand is NOT AI only.
                        <div class="deq-callout--muted">
                            If you would like to make it AI only, set
                            <ul>
                                <li><em>human crowd percentage</em> to <em>None</em>,</li>
                                <li><em>sentiment policy</em> to <em>Manual</em>,</li>
                                <li><em>sentiment</em> to <em>AI</em>,</li>
                                <li>and both <em>Segments</em> and <em>Topics</em> to <em>AI</em></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- endregion -->
        </div>

        <!-- region Button bar -->
        <template #buttons>
            <div class="deq-dialog-buttons">
                <BeOkCancelCloseButton
                    :disabled="loading"
                    :is-changed="isChanged"
                    @close="close" @cancel="close" @accept="accept"/>
            </div>
        </template>
        <!-- endregion -->

    </dialog-box>
</template>



<script>

import DialogBox from "@/components/DialogBox.vue";
import {formatBrandName, formatPolicy} from "@/app/utils/Format";
import {Brand} from "@/app/utils/types";
import BeButton from "@/components/buttons/BeButton.vue";
import BeButtonGroup from "@/components/buttons/BeButtonGroup.vue";
import {encloseInDisplayQuotes, escapeHtml} from "@/app/utils/StringUtils";
import BeOkCancelCloseButton from "@/components/buttons/BeOkCancelCloseButton.vue";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions} from "vuex";
import {notifyUserOfError, notifyWithHtml, showBusyNotification} from "@/app/framework/notifications/Notifications";
import SlottedTag from "@/components/tags/SlottedTag.vue";

export default {
    store: VuexStore,
    components: {SlottedTag, BeButtonGroup, BeButton, DialogBox, BeOkCancelCloseButton},

    props: {
        brand: {
            type: Brand,
            required: true
        }
    },

    data() {
        return {
            loading: false,

            humanCrowdPercentage: this.brand.humanCrowdPercentage,
            sentimentPolicy: this.brand.sentimentPolicy,
            sentiment: this.brand.sentiment,
            segments: this.brand.segments,
            topics: this.brand.topics,
        }
    },

    watch: {
        brand() {
            this.humanCrowdPercentage = this.brand.humanCrowdPercentage;
            this.sentimentPolicy = this.brand.sentimentPolicy;
            this.sentiment = this.brand.sentiment;
            this.segments = this.brand.segments;
            this.topics = this.brand.topics;
        }
    },

    computed: {
        isAiOnly() {
            return this.humanCrowdPercentage === 0
                && this.sentimentPolicy === 'MANUAL'
                && this.sentiment === 'MACHINE'
                && this.segments === 'MACHINE'
                && this.topics === 'MACHINE';
        },

        isChanged() {
            return this.humanCrowdPercentage !== this.brand.humanCrowdPercentage
                || this.sentimentPolicy !== this.brand.sentimentPolicy
                || this.sentiment !== this.brand.sentiment
                || this.segments !== this.brand.segments
                || this.topics !== this.brand.topics;
        }
    },

    methods: {
        formatPolicy,
        ...mapActions(['updateBrand']),

        encloseInDisplayQuotes,
        formatBrandName,

        close() {
            if (this.loading) return;
            this.$emit('close');
        },

        async accept() {
            if (this.loading) return;
            const name = formatBrandName(this.brand);

            //region Undo setup
            const oldSetup = Object.freeze({
                id: this.brand.id,
                humanCrowdPercentage: this.brand.humanCrowdPercentage,
                sentimentPolicy: this.brand.sentimentPolicy,
                sentiment: this.brand.sentiment,
                segments: this.brand.segments,
                topics: this.brand.topics
            });

            const undo = async () => {
                const notification = showBusyNotification(escapeHtml`Undoing AI setting changes for <strong>${name}</strong>`, null, null, true);
                try {
                    await this.updateBrand(oldSetup);
                } catch (e) {
                    console.error(e);
                    notifyUserOfError("There was a problem updating the brand. Please see the javascript console");
                } finally {
                    notification?.close();
                }
            };
            //endregion

            let notification = null;
            try {
                this.loading = true;
                notification = showBusyNotification(escapeHtml`Updating AI settings for <strong>${formatBrandName(this.brand)}</strong>`, null, null, true);

                await this.updateBrand({
                    id: this.brand.id,
                    humanCrowdPercentage: this.humanCrowdPercentage,
                    sentiment: this.sentiment,
                    sentimentPolicy: this.sentimentPolicy,
                    segments: this.segments,
                    topics: this.topics,
                });
                
                this.$emit('close');
                notifyWithHtml(escapeHtml`AI settings for <strong>${name}</strong> have been updated`, undo, "<i class='symbol-setup'></i>")
            } catch (error) {
                console.error(error);
                notifyUserOfError("There was a problem updating the brand. Please see the javascript console");
            } finally {
                this.loading = false;
                notification?.close();
            }
        }
    }
}
</script>


<style lang="sass" scoped>

.msd__table
    display: grid
    grid-template-columns: max-content max-content
    margin-right: auto

    column-gap: 1rem
    row-gap: 0.5rem

    dl
        display: grid
        grid-column: 1 / -1
        grid-template-columns: subgrid
        grid-template-rows: subgrid

        margin: 0
        padding: 0

        dt
            text-align: right

.machine-sentiment-dialog__status
    padding-top: 1rem

input[type="number"]
    padding-right: 0


</style>