import {formatBrandName, formatTagName, formatTagNamespace} from "@/app/utils/Format";
import VuexStore from "@/store/vuex/VuexStore";
import {getProfileIcon} from "@/setup/profiles/ProfileUtils";
import {getRuleIcon, getRulePrettyAction, getRuleType} from "@/setup/rules/RuleUtils";

export class DataEQType {
    id;

    /**
     * A formatted representation of this object that could be displayed to a user.
     * @return {String}
     */
    toString() {
        return "" + this.id;
    }

    /**
     * A link that can be used to navigate to an appropriate screen for this object.
     * @return {String|null}
     */
    get analyseLink() {
        return null;
    }

    /**
     * A potential CSS class that can be used as an icon for this object.
     * @return {String|null}
     */
    get analyseIcon() {
        return null;
    }
}

/**
 * @property {string} code;
 * @property {string} name;
 */
export class Account extends DataEQType {
    /** @type {string} */
    code;
    /** @type {string} */
    name;
    inactive;
    /** @type {string} */
    font;
    currency;
    country;
    vertical;
    verticalId;
    dateCreated;
    lastUpdated;
    timezone;

    /** @type {boolean|null} */
    hasFeedTikTok;

    showPredictedSentiment;
    showNotifications;

    clientCode;
    clientName;

    /**
     * True iff only DataEQ staff can log in to the account.
     * Usually this means that client account access has been suspended.
     *
     * @type {?boolean}
     */
    onlyAdminLogin;

    /** @type {{id, name}} */
    defaultBrand;

    status;
    findNewMentions;

    hasReporting;
    hasEngage;
    hasTopics;
    hasFeedBroadcast;
    hasFeedPrint;
    payPerBrand;
    maxPhrases;

    /** @type {boolean|undefined|null} */
    dev;

    /** @type {Boolean|null} */
    hasFacebookDataAuthorisation;

    /**
     * @deprecated This is not returned by mash.
     * @type {[]}
     */
    segmentLists;
    /**
     * @deprecated This is not returned by mash.
     * @type {[]}
     */
    topicTrees;
    /**
     * @deprecated This is not returned by mash.
     * @type {[]}
     */
    topicViews;

    get analyseLink() {
        return `/${this.code}`;
    }
}

export class Brand extends DataEQType {
    /** @type {number} */
    id;

    /** @type {?number} */
    crowdSamplePercentage;

    /** @type {?number} */
    humanCrowdPercentage;

    /** @type {?boolean} */
    deleted;
    /** @type {?boolean} */
    archived;

    /** @type {?[Brand]} */
    children;

    /** @type {?number} */
    topicTreeId;

    /** @type {?boolean} */
    rateSentiment;

    parent;

    /** @type {?string} */
    colour;

    /** @type {'DEFAULT' | 'MANUAL'} */
    sentimentPolicy;

    /** @type {'DEFAULT' | 'MACHINE' | 'HUMAN'} */
    sentiment;

    /** @type {'DEFAULT' | 'MACHINE' | 'HUMAN'} */
    segments;

    /** @type {'DEFAULT' | 'MACHINE' | 'HUMAN'} */
    topics;


    /** @type {?string} */
    mentionFilter;
    /** @type {?string} */
    socialNetworks;
    /** @type {?string} */
    feeds;
    /** @type {?[BrandPhrase]}*/
    phrases;
    /** @type {?MentionVolume}*/
    volume;
    description;
    importedByAccounts;


    /**
     * The brand's formatted name.
     * @return {String}
     */
    toString() {
        return formatBrandName(this);
    }

    /**
     * A link to the brand's setup page
     * @return {String}
     */
    get analyseLink() {
        const account = VuexStore.state.account;
        return `/${account.code}/setup/brands/${this.id}`;
    }

    get analyseIcon() {
        return "symbol-setup";
    }
}

export class Profile extends DataEQType {
    id;
    /** @type {string} */
    name;
    /** @type {'FACEBOOK_PAGE' | 'INSTAGRAM_BUSINESS' | 'INSTAGRAM_USER' | 'TWITTER_SCREEN_NAME' | 'LINKEDIN_COMPANY' | 'TELEGRAM_CHANNEL' | 'YOUTUBE_CHANNEL' | 'WHATSAPP' | 'HELLOPETER' | 'GOOGLEBUSINESS' } */
    type;
    /** @type {string} */
    handle;
    /** @type {string} */
    handleId;
    /** @type {string} */
    pictureLink;

    /**
     * The profile's name.
     * @return {String}
     */
    toString() {
        return this.name;
    }

    /**
     * A link to the profile's panel. Called "panelLink" becase profiles already have a field called "link" that links to the profile's page on the respective platform.
     * @return {String}
     */
    get analyseLink() {
        const account = VuexStore.state.account;
        return `/${account.code}/setup/profiles`;
    }

    get analyseIcon() {
        return getProfileIcon(this.type);
    }
}


export class Dashboard extends DataEQType {
    id;
    name;

    toString() {
        return this.name;
    }

    /**
     * A link to the dashboard.
     * @return {String}
     */
    get analyseLink() {
        const account = VuexStore.state.account;
        return `/${account.code}/dashboards/${this.id}`;
    }

    get analyseIcon() {
        return "symbol-reports";
    }
}

/**
 * @class
 * @extends DataEQType
 */
export class Notification extends DataEQType {
    id;
    name;
    externalId;
    /** @type {?string} */
    filter;
    bucket;
    minVolume;

    toString() {
        return this.name;
    }

    /**
     * A link to the notification.
     * @return {String}
     */
    get analyseLink() {
        const account = VuexStore.state.account;
        return `/${account.code}/setup/notifications/${this.id}`;
    }

    get analyseIcon() {
        return "symbol-notification";
    }
}

/**
 * @class
 * @extends DataEQType
 */
export class Tag extends DataEQType {
    id;

    /** @type {string} */
    name;

    /** @type {string|null} */
    namespace;

    /**
     * For segment_lists, this is a way to different their name.
     * @type {string|null}
     * */
    subtitle;

    /** @type {string|null} */
    description = null;

    constructor(id, name, namespace) {
        super();
        this.id = id ?? null;
        this.name = name ?? "";
        this.namespace = namespace ?? "tag";
    }

    toString() {
        const formattedNamespace = formatTagNamespace(this);
        if (!formattedNamespace) return formatTagName(this);
        return `${formatTagName(this)} (${formattedNamespace})`;
    }

    get analyseLink() {
        if (!this.id) return null; // This is a new tag with nowhere to link to
        return `/${VuexStore.state.account.code}/setup/tags/${this.id}`;
    }

    get analyseIcon() {
        return "symbol-tags";
    }
}

export class Phrase extends DataEQType {
    id;
    /** @type {string} */
    query;
    /** @type {boolean} */
    autoRelevant;
    /** @type {boolean|null} */
    active;
    /** @type {string} */
    lastUpdated;
    /** @type {string|null} */
    approved;
    /** @type {number|null} */
    brandId;
}

export class BrandPhrase extends DataEQType {
    id;
    /** @type {string} */
    q;
    /** @type {string|null} */
    created;
    /** @type {boolean} */
    inactive;
    /** @type {?boolean} */
    deleted;
    /** @type {?string} */
    feeds;
    /** @type {?string} */
    socialNetworks;
    /** @type {string} */
    mentionFilter;
    /** @type {string} */
    lastUpdatedBy;
    /** @type {string} */
    approved;
    /** @type {?string} */
    approvedBy;
    /** @type {Brand} */
    parent;
}

export class Rule extends DataEQType {
    id;

    /** @type {string} */
    name;

    /** @type {string} */
    action;

    /** @type {string} */
    filter;

    /** @type {string} */
    attributes;

    /** @type {object|null} */
    macro;

    /** @type {Number} */
    priority;

    /** @type {boolean|null} */
    active;

    toString() {
        return this.name;
    }

    /**
     * A link to the rules panel.
     * @return {String}
     */
    get analyseLink() {
        const account = VuexStore.state.account;
        return `/${account.code}/setup/rules`;
    }

    get analyseIcon() {
        let prettyAction = getRulePrettyAction(this);
        let ruleType = getRuleType(prettyAction);

        return getRuleIcon(ruleType);
    }
}

export class MentionVolume extends DataEQType {

    /** @type {Number}*/
    total;
    /** @type {Number}*/
    unsorted;
    /** @type {?Number}*/
    volumeDelta;
    /** @type {?boolean}*/
    volumeUp;
    /** @type {?boolean}*/
    volumeDown;
    /** @type {Number}*/
    trash;
    /** @type {?boolean}*/
    highTrash;
    /** @type {?[Message]}*/
    messages
}

export class Message extends DataEQType {
    /** @type {Code}*/
    code;
    /** @type {string}*/
    text;
}


/**
 * Used for managing and reporting on errors relating to filters.
 */
export class FilterError extends Error {
    /** @type {string} */
    #filter;

    /**
     * @param {string} message
     * @param {string?} filter
     */
    constructor(message, filter) {
        super(message);
        this.#filter = filter;
        this.name = "FilterError";
    }

    get filter() {
        return this.#filter;
    }
}

export class User {
    /** @type {number} */
    id;
}

export class BasicUser {
    /** @type {Number} */
    id;
    /** @type {String} */
    firstName;
    /** @type {String} */
    lastName;
    /** @type {String} */
    email;
}

export class AccountUser extends BasicUser{
    /** @type {Boolean} */
    accessStatus
    /** @type {String} */
    accountCode
    /** @type {Boolean} */
    mainUser
    /** @type {String} */
    name
    /** @type {String} */
    permissions
    /** @type {String[]}*/
    privateMentions;
}


export class AccountTeam {
    /** @type {String} */
    created;
    /** @type {String} */
    description;
    /** @type {Number} */
    id
    /** @type {String} */
    lastUpdated;
    /** @type {BasicUser} */
    lastUpdatedBy;
    /** @type {String} */
    name;
    /** @type {Number[]} */
    userIds;
}