<template>

    <div class="category-picker">
        <PopupMenu ref="popupMenu" fixed :value="isOpen"
                   ignore-activator-click :arrow-pointer="{show: true, left: true}" :never-hide="isOpen">
            <template #activator>
                <p class="category-picker-label">{{label}}</p>
                <div ref="selector" class="deq-input-div category-search-wrapper" @click="onFocusInput">
                        <SlottedTag ref="slotRef" v-if="selectedCategory.length" class="selected-category" @close="unselectCategory">
                            {{selectedCategory}}
                        </SlottedTag>
                    <p class="deq-placeholder" v-if="!selectedCategory.length && isOpen === false">Optional category</p>
                </div>
            </template>

        <ul class="deq-picker-ul category-options-list dark-scrollbars dark-scrollbars--visible">
            <li v-for="(category, i) in this.items" :key="i" @click="onCategorySelect(category)" class="category-option"
                :style="{'pointer-events': category.name === 'no_results' ? 'none' : 'unset'}">
                {{ category.displayName }}
            </li>
        </ul>
        </PopupMenu>
    </div>

</template>


<script>
import SlottedTag from "@/components/tags/SlottedTag.vue";
import PopupMenu from "@/components/PopupMenu.vue";

export default {
name: "CategoryPicker",
    components: {PopupMenu, SlottedTag},
    model: {
        prop: 'originalCategory',
        event: 'category-selected'
    },
    props: {
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        originalCategory: {
            type: String,
            required: false,
            default: () => '',
        },
        label: {
            type: String,
            required: false,
            default: () => '',
        }
    },
    data() {
        return {
            searchInput: '',
            categoryOptions: this.items,
            isOpen: false,
            width: '97%',
            selectedCategory: this.originalCategory ? this.items.find(item => item.name === this.originalCategory).displayName : '',
            placeholder: this.originalCategory ? '' : 'Optional Brand category',
        };
    },

    mounted() {
        document.querySelector('.dialog').addEventListener('click', this.categoriesBlur);
        document.addEventListener('click', this.categoriesBlur);
    },
    destroyed() {
        document.querySelector('.dialog').removeEventListener('click', this.categoriesBlur);
        document.removeEventListener('click', this.categoriesBlur);
    },

    methods: {
        onFocusInput() {
            this.isOpen = true;
        },

        onCategorySelect(result) {
            this.placeholder = '';
            this.selectedCategory = result.displayName;
            this.emitData();
            this.categoryOptions = [];
            this.isOpen = false;
        },

        unselectCategory(){
            this.selectedCategory = '';
            this.placeholder = 'Optional Brand category';
            this.emitData();
        },

        categoriesBlur(event) {
            if (event.target.classList.contains('deq-placeholder')) return
            if (event.target.classList.contains('category-picker-label') || !this.$refs.popupMenu.$el.contains(event.target)) {
                this.isOpen = false;
            }
        },

        emitData() {
            if (this.selectedCategory.length) {
                this.$emit('category-selected',  this.items.find(item => item.displayName === this.selectedCategory).name);
            } else {
                this.$emit('category-selected',  '');
            }
        }
    },
}
</script>

<style scoped lang="sass">

.category-picker
    .deq-placeholder
        position: relative
        top: 1px
        margin-left: 5px

    p
        margin: 0 0 5px 0

    .category-search-wrapper
        display: flex
        flex-direction: row
        flex-wrap: wrap
        align-items: center
        gap: 5px
        height: 30px
        width: 200px

        &:focus-within
            border-color: var(--be-input-focus-border-color)
            box-shadow: var(--be-input-focus-box-shadow)
            .category-options-list
                visibility: unset

        .selected-category
            &:hover
                color: #AEE15D

    .category-options-list
        padding: 0
        height: fit-content
        max-height: 6em
        width: 200px
        visibility: hidden

::v-deep .controls input
    height: 30px
</style>