import { render, staticRenderFns } from "./EditSubBrandDialog.vue?vue&type=template&id=28ce24b6&scoped=true"
import script from "./EditSubBrandDialog.vue?vue&type=script&lang=js"
export * from "./EditSubBrandDialog.vue?vue&type=script&lang=js"
import style0 from "./EditSubBrandDialog.vue?vue&type=style&index=0&id=28ce24b6&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ce24b6",
  null
  
)

export default component.exports