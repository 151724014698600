import VuexStore from "@/store/vuex/VuexStore";
import {beef} from "@/store/Services";
import axios from "axios";

export const platforms = {
    facebook: "facebook",
    twitter: "twitter",
    linkedin: "linkedin",
    googleBusiness: "googleBusiness",
    tiktok: "tiktok",
    whatsapp: "whatsapp",
    youtube: "youtube",
    tiktokAds: "tiktokAds"
}

/**
 * Navigates user to current account's Online Profiles section in the setup panel
 */
export function gotoOnlineProfilesSetup() {
    const account = VuexStore.state.account;
    Beef?.router?.navigate(`/${account.code}/setup/profiles`, {trigger: true});
}

/**
 * Opens a window in the center of the focused screen
 *
 * @param url - URL that the window should open
 * @param title - title of the window
 * @param w - width of the window
 * @param h - height of the window
 */
export function openWindowCenter(url, title, w, h) {
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title, `scrollbars=yes,width=${w / systemZoom},height=${h / systemZoom},top=${top},left=${left}`)

    if (newWindow?.focus) newWindow.focus();
    return newWindow
}

/**
 * Handles the login flow for authorising a user's managed Facebook pages
 *
 * @param callback function that handles the success/failure of the Facebook login callback
 */
export async function handleFacebookPageAuthLogin(callback) {
    let response;

    let accountCode = VuexStore.state?.account?.code;
    let userId = VuexStore.state?.user?.id;

    response = await beef.get(`/api/online-profile/authFacebookUser?scope=MANAGED&createPageTokens=true${accountCode ? `&accountCode=${accountCode}` : ''}&performExpectedPagesCheck=true`);

    // handle success of callback
    let addedFunctionName = `userPlatformAuth${userId}`;

    window[addedFunctionName] = async (paramsMap) => {
        if (paramsMap["auth"] === "success") {
            // updated user token
            let updatedUserToken = paramsMap['token'];

            VuexStore.commit("setUserFacebookAuthorised", [updatedUserToken]);
            VuexStore.commit("userPlatformAuth/setFacebookUserToken", updatedUserToken.token);

            if (window.location.href.indexOf("setup/profiles") !== -1) {
                VuexStore.dispatch("profiles/refreshProfiles", true)
            }
        }

        callback(paramsMap);
    }

    openWindowCenter(response.data.url, "", 700, 900);
}

export async function handleProfileAuth(handleId, handle, profileType, callback) {
    let response;

    let accountCode = VuexStore.state?.account?.code;
    let userId = VuexStore.state?.user?.id;

    let payload = {
        handle: handle,
        handleId: handleId,
        type: profileType
    }

    response = await beef.post(`/api/online-profile/authorize-user-profile${accountCode ? `?accountCode=${accountCode}` : ''}`, payload);

    // handle success of callback
    let addedFunctionName = `userPlatformAuth${userId}`;

    window[addedFunctionName] = async (paramsMap) => {
        callback(paramsMap);
    }

    openWindowCenter(response.data.url, "", 700, 900);
}