<template>
    <dialog-box @close="$emit('input', null)" :width="isPreviewing ? previewWidth : '680px'" class="fc-settings"
                modal no-transition
                :title="title">
        <be-form ref="form" v-model="valid" class="form dark-scrollbars dark-scrollbars--visible settings-body" v-if="!isPreviewing">
            <div class="row-fluid">
                <div class="span12 control-row">
                    <div style="position: relative">
                        <text-input v-model="attrs.caption" label="Title" placeholder="Title" required ref="caption"
                                    @valid="validate" style="width: 353px"/>
                        <div class="control-group" style="position: absolute; right: 0; top: 0">
                            <label class="checkbox">
                                <input v-model="attrs['hidden-title']" type="checkbox" :false-value="true" :true-value="false"> Show title
                            </label>
                        </div>
                    </div>
                    <div class="control-group" @click="editComment">
                        <label>Comments</label>
                        <div class="controls">
                            <div v-if="commentaryPlusInMetricFeatureFlag">
                                   <span class="tag-input uneditable-input span12 comments" tabindex="0"
                                         style="width: 230px; white-space: nowrap; text-overflow: ellipsis" @click="editComment">
                                    <span style="color: #a0a0a0">
                                        <span v-if="attrs.commentPlus">{{getCommentPlusPreview(attrs.commentPlus)}}</span>
                                        <span v-else>Add comments ...</span>
                                    </span>
                                </span>
                            </div>
                            <div v-else>
                                    <span class="tag-input uneditable-input span12 comments" tabindex="0"
                                          style="width: 230px; white-space: nowrap; text-overflow: ellipsis" @click="editComment">
                                        <span style="color: #a0a0a0">
                                            <span v-if="attrs.comment">{{attrs.comment}}</span>
                                            <span v-else>Add comments ...</span>
                                        </span>
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row-fluid">
                <div class="span12 control-row">
                    <drop-down-input v-model="attrs.height" :options="options.height" label="Height" style="width: 40px"/>
                    <drop-down-input v-model="attrs.width" :options="options.width" label="Width" style="width: 40px"/>
                    <drop-down-input v-model="attrs.fontSize" label="Font" :options="options.fontSize" style="width: 40px"/>
                    <drop-down-input v-model="attrs.numberFormat" label="Numbers" :options="options.numberFormat" style="width: 70px"/>
                    <div>
                        <div class="control-row">
                            <div style="position: relative">
                                <text-input v-model="attrs.axisLabel" :label="(hasY2Axis ? 'Left axis': 'Axis') + ' label'"
                                            placeholder="Auto" style="width: 200px"/>
                                <div class="control-group" style="position: absolute; right: 0; top: 0">
                                    <label class="checkbox">
                                        <input v-model="attrs.showAxisLabel" type="checkbox"> Show label
                                    </label>
                                </div>
                            </div>
                            <text-input v-model="attrs.axisMaxValue" label="Max value" numeric placeholder="Auto"
                                        style="width: 70px" :rules="options.rules.axisMaxValue"/>
                            <div style="position: relative">
                                <text-input v-model="attrs.axisMinValue" label="Min" numeric placeholder="Auto"
                                            style="width: 70px" :rules="options.rules.axisMaxValue"/>
                                <div class="control-group" style="position: absolute; right: 0; top: 0">
                                    <label class="checkbox">
                                        <input v-model="attrs.hideAxisMin" type="checkbox" style="margin-left: -14px">Hide
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-if="hasY2Axis" class="control-row">
                            <div style="position: relative">
                                <text-input v-model="attrs.axis2Label" label="Right axis label" placeholder="Auto" style="width: 200px"/>
                                <div class="control-group" style="position: absolute; right: 0; top: 0">
                                    <label class="checkbox">
                                        <input v-model="attrs.showAxis2Label" type="checkbox"> Show label
                                    </label>
                                </div>
                            </div>
                            <text-input v-model="attrs.axis2MaxValue" label="Max value" numeric placeholder="Auto"
                                        style="width: 70px" :rules="options.rules.axisMaxValue"/>
                            <div style="position: relative">
                                <text-input v-model="attrs.axis2MinValue" label="Min" numeric placeholder="Auto"
                                            style="width: 70px" :rules="options.rules.axisMaxValue"/>
                                <div class="control-group" style="position: absolute; right: 0; top: 0">
                                    <label class="checkbox">
                                        <input v-model="attrs.hideAxis2Min" type="checkbox" style="margin-left: -14px">Hide
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <f-c-y-axis-settings v-for="(g,i) in attrs.show" :key="i" :show="attrs.show" :index="i" :options="options"
                    @remove="removeSeries(i)" :dashboard-model="dashboardModel" style="margin-top: 10px"
                    @valid="validate" @yAxisChange="validateLater"/>

            <div v-if="canAddSeries">
                <a class="btn btn-link" @click="addSeries"><i class='icon-plus'/> Add another series</a>
            </div>

            <div v-if="showDurationFormats" class="row-fluid">
                <div class="span12 control-row">
                    <drop-down-input v-model="attrs.durationFormat" show-sub-label-in-selected-item label="Response time duration format"
                                     :options="options.durationFormats" style="width: 200px"/>

                    <drop-down-input v-if="durationFormatIsDefault" v-model="attrs.durationHoursThreshold" show-sub-label-in-selected-item label="Hours threshold"
                                     :options="options.durationHoursThresholds" style="width: 100px"/>
                </div>
            </div>

            <div class="row-fluid">
                <div class="span12 control-row">
                    <div>
                        <drop-down-input v-model="attrs.xAxis" label="How do you want to group the data?"
                                         :options="options.xAxis" style="width: 220px"/>
                        <div v-if="xAxis.isDate" style="margin-left: 8px" class="control-group">
                            <label class="checkbox" title="Show/hide results for dates in the future">
                                <input v-model="attrs.hideFuture" type="checkbox" :false-value="true" :true-value="false"> Show future dates
                            </label>
                        </div>
                    </div>
                    <div>
                        <div class="control-row">
                            <text-input v-if="!xAxis.noMaxItems" v-model="attrs.maxItems" label="Max bars"
                                        :rules="options.rules.maxItems" numeric style="width: 70px"/>
                            <template v-if="xAxis.isDate">
                                <drop-down-input  v-model="attrs.coarseness" label="Grouping"
                                                    :options="options.dateGroup" style="width: 70px"/>
                                <drop-down-input v-model="attrs.dateFormat" label="Format" :options="options.dateFormat"
                                                 style="width: 80px"/>
                                <template v-if="firstGeometry !== 'rows'">
                                    <drop-down-input v-model="attrs.dateTicks" label="Ticks" :options="options.dateTicks"
                                                     style="width: 80px"/>
                                    <drop-down-input v-model="attrs.labelAngle" label="Angle" :options="options.labelAngle"
                                                     style="width: 60px"/>
                                </template>
                            </template>
                            <template v-else>
                                <drop-down-input  v-model="attrs.labelAngle" label="Label angle" :options="options.labelAngle"
                                                  style="width: 80px"/>
                                <div v-if="firstGeometry === 'lines'" class="control-group" style="margin-top: 24px">
                                    <label class="checkbox">
                                        <input v-model="attrs.xGridLines" type="checkbox"> Show vertical grid
                                    </label>
                                </div>
                                <div v-if="firstGeometry === 'columns'" class="control-group" style="margin-top: 24px">
                                    <label class="checkbox">
                                        <input v-model="attrs.yGridLines" type="checkbox"> Show horizontal grid
                                    </label>
                                </div>
                            </template>
                        </div>
                        <div v-if="firstGeometry === 'lines' && xAxis.isDate" style="margin-left: 8px" class="control-group">
                            <label class="checkbox">
                                <input v-model="attrs.xGridLines" type="checkbox"> Show vertical grid
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row-fluid" style="margin-top: 10px">
                <div class="span12 control-row">
                    <drop-down-input v-model="attrs.xSortByField" show-sub-label-in-selected-item label="Sort data by" :options="filteredXSortOptions"
                                     style="width: 220px"/>
                    <drop-down-input v-model="attrs.xSortOrder" show-sub-label-in-selected-item label="Sort order" :options="filteredXSortOrderOptions"
                                     style="width: 165px"/>
                </div>
            </div>

            <div class="row-fluid" v-if="firstGeometry === 'points'">
                <div class="span4">
                    <drop-down-input v-model="attrs.size" label="Bubble size" :options="options.bubbleSize" style="width: 220px"/>
                </div>
            </div>

            <div class="row-fluid">
                <div class="span12 control-row">
                    <drop-down-input v-if="showCompare" v-model="attrs.compare" label="What do you want to compare?" :options="compareOptions"
                            optional style="width: 220px"/>
                    <text-input v-if="!compare.noMaxItems" v-model="attrs.maxComparisons" label="Max bars"
                                :rules="options.rules.maxItems" numeric style="width: 70px"/>
                    <div class="control-group" :style="{marginLeft: showCompare ? null : '8px'}" style="margin-top: 24px">
                        <label v-if="!(xAxis.hideOthers && compare.hideOthers) && !compare.otherNotSupported"
                               class="checkbox" title="Show/hide all of the results that are grouped under 'other'">
                            <input v-model="attrs.hideOther" type="checkbox" :false-value="true" :true-value="false"> Show other values
                        </label>
                        <label v-if="!(xAxis.hideUnknown && compare.hideUnknown)" class="checkbox" title="Show/hide all of the results with unknown values">
                            <input v-model="attrs.hideUnknown" type="checkbox" :false-value="true" :true-value="false"> Show unknown values
                        </label>
                        <label v-if="attrs.compare || attrs.show.length > 1" class="checkbox"
                               title="Show/hide information about the comparison data sets">
                            <input v-model="attrs.hideLegend" type="checkbox" :false-value="true" :true-value="false"> Show legend
                        </label>
                        <label v-if="canStack && attrs.compare" class="checkbox">
                            <input v-model="attrs.stacked" type="checkbox"> Stack bars
                        </label>
                    </div>
                </div>
            </div>

            <div class="row-fluid" style="margin-top: 10px" v-if="attrs.compare">
                <div class="row-fluid">
                    <div class="span12 control-row">
                        <drop-down-input v-model="attrs.compareBucketSortByField" show-sub-label-in-selected-item label="Sort comparisons by" :options="filteredCompareSortOptions"
                                         style="width: 220px"/>
                        <drop-down-input v-model="attrs.compareBucketSortOrder" show-sub-label-in-selected-item label="Sort order" :options="filteredCompareSortOrderOptions"
                                         style="width: 165px"/>
                    </div>
                </div>
            </div>

            <div class="row-fluid control-group" v-if="showDataSets" >
                <label v-if="attrs.xAxis === 'dataSet'">Data sets</label>
                <div class="span12 fc-settings__datasets dark-scrollbars dark-scrollbars--visible" v-if="attrs.dataSets" style="margin-left: 0">
                    <f-c-data-set-settings v-for="(ds,i) in attrs.dataSets" :key="ds.id" :data-set="ds" :index="i"
                                           :count="attrs.dataSets.length"
                                           :brand="brand" :previous-dates="previousDates" @remove="removeDataSet(i)"/>
                </div>
                <div>
                    <a class="btn btn-link" @click="addDataSet"><i class='icon-plus'/> Add another data set</a>
                    <a class="btn btn-link" @click="last4Weeks" v-if="dateInterval.doWeeksMakeSense"><i class='icon-clock'/>4 weeks</a>
                    <template v-if="dateInterval.doMonthsMakeSense">
                        <a class="btn btn-link" @click="last3Months"><i class='icon-clock'/>3 months</a>
                        <a class="btn btn-link" @click="last4Months"><i class='icon-clock'/>4 months</a>
                    </template>
                </div>
            </div>

            <div class="row-fluid" v-if="showTopicControls">
                <div class="span12 control-row">
                    <topic-view-input v-model="attrs.topicview" label="Topic view" optional style="width: 220px"/>
                    <language-input v-model="attrs.language" label="Display language" optional style="width: 160px"/>
                    <div class="control-group" style="margin-top: 13px">
                        <label class="checkbox" title="Show/hide the parent topics">
                            <input v-model="attrs.hideParents" type="checkbox" :false-value="true" :true-value="false"> Show parent topics
                        </label>
                        <label class="checkbox" title="Show/hide the child topics">
                            <input v-model="attrs.hideChildren" type="checkbox" :false-value="true" :true-value="false"> Show child topics
                        </label>
                    </div>
                </div>
            </div>

            <div class="row-fluid" v-if="showCooccurrence || showHideMissing">
                <div class="span12 control-row">
                    <div class="control-group">
                        <label v-if="showCooccurrence" class="checkbox" title="Show/hide co-occuring topics and segments">
                            <input v-model="attrs.showCooccurrence" type="checkbox"> Show co-occurrence
                        </label>
                    </div>
                    <div class="control-group">
                        <label v-if="showHideMissing" class="checkbox" title="Show/hide missing segment tags">
                            <input v-model="attrs.hideMissing" type="checkbox"> Hide missing segments
                        </label>
                    </div>
                </div>
            </div>

            <div class="row-fluid" v-if="attrs.hidden && attrs.hidden.length">
                <div class="span12 control-group">
                    <label>
                        Hidden items
                    </label>
                    <section class="fc-settings__hidden">
                        <slotted-tag v-for="hidden in attrs.hidden" :key="hidden.id"
                                     tooltip="This item has been hidden, and will not display in the metric"
                                     close-tooltip="Click to show this item in the metric"
                                     @close="removeHidden(hidden.id)">
                            {{hidden.name}}
                        </slotted-tag>
                    </section>
                </div>
            </div>

            <div class="row-fluid">
                <div class="span12 control-row">
                    <div class="control-group" @click="editFilter">
                        <label>Additional filter</label>
                        <div class="controls">
                            <div class="tag-input uneditable-input filter" >
                                <english-filter v-if="attrs.filter" :filter="attrs.filter" partial/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </be-form>
        <div v-else>
            <div class="preview-widget dark-scrollbars dark-scrollbars--visible">
                <div class="preview-widget-container widget-grid resizable-grid widget-grid-col12" ref="metricPreview"/>
            </div>
        </div>

        <dialog-box v-if="showEditFilter" title="Edit Additional Filter" @close="showEditFilter = false" width="850px">
            <filter-editor v-model="filter" no-published no-trash :brand="brand"/>
            <template #buttons>
                <a class="cancel btn" @click="showEditFilter = false">Cancel</a>
                <a class="btn btn-primary" @click="editFilterOk">Ok</a>
            </template>
        </dialog-box>

        <div v-if="commentaryPlusInMetricFeatureFlag">
            <dialog-box modal v-if="showEditComment" title="Edit Comments" @close="showEditComment = false" width="850px">
                <section class="commentary-box">
                    <CommentaryPlusEditor
                        ref="commentaryPlusEditor"
                        :blocks="this.attrs.commentPlus"
                        :isReadOnly="false"
                        :showActionBtns="true"
                        :filter="this.model.attributes._effectiveFilter"
                        @onEditorChange="editCommentPlus"></CommentaryPlusEditor>
                </section>
                <div class="row-fluid">
                    <div class="control-row span12" style="height: 56px">
                        <drop-down-input v-model="attrs.commentFontSize" label="Font size" :options="options.fontSize" style="width: 60px"/>
                        <text-input v-model="attrs.commentWidth" label="Width" style="width: 80px" numeric :rules="options.rules.commentWidth"/>
                        <span class="text-units">blocks</span>
                    </div>
                </div>
                <template #buttons>
                    <a class="cancel btn" @click="showEditComment = false">Cancel</a>
                    <a class="btn btn-primary" @click="editCommentOk">Ok</a>
                </template>
            </dialog-box>
        </div>

        <div v-else>
            <dialog-box modal v-if="showEditComment" title="Edit Comments" @close="showEditComment = false" width="650px">
                <markdown-editor v-model="comment" class="comment-editor"/>
                <div class="row-fluid">
                    <div class="control-row span12" style="height: 56px">
                        <drop-down-input v-model="attrs.commentFontSize" label="Font size" :options="options.fontSize" style="width: 60px"/>
                        <text-input v-model="attrs.commentWidth" label="Width" style="width: 80px" numeric :rules="options.rules.commentWidth"/>
                        <span class="text-units">blocks</span>
                    </div>
                </div>
                <template #buttons>
                    <a class="cancel btn" @click="showEditComment = false">Cancel</a>
                    <a class="btn btn-primary" @click="editCommentOk">Ok</a>
                </template>
            </dialog-box>
        </div>

        <template #buttons>
            <div class="btn-container">
                <be-button v-if="!isPreviewing" link @click="cancel">Cancel</be-button>
                <be-button :link="isPreviewing" @click="preview" :class="{disabled: !valid}">{{ isPreviewing ? '« Back to edit' : 'Preview' }}</be-button>
                <be-button primary v-if="!isPreviewing" @click="ok" :class="{disabled: !valid}">Ok</be-button>
            </div>
        </template>
    </dialog-box>
</template>

<script>
    import DialogBox from "../../../components/DialogBox"
    import BeForm from "../../../components/inputs/BeForm"
    import TextInput from "../../../components/inputs/TextInput"
    import DropDownInput from "../../../components/inputs/DropDownInput"
    import EnglishFilter from "../../../components/formatters/EnglishFilter"
    import FilterEditor from "../../../components/inputs/FilterEditor"
    import MarkdownEditor from "../../../components/inputs/MarkdownEditor"
    import LanguageInput from "../../../components/inputs/LanguageInput"
    import TopicViewInput from "../../../components/inputs/TopicViewInput"
    import FantasticChartSettingsOptions from "./FantasticChartSettingsOptions"
    import FCYAxisSettings from "./FCYAxisSettings"
    import FCDataSetSettings from "./FCDataSetSettings";

    import {cloneDeep} from 'lodash'
    import DateInterval from "./DateInterval";
    import {notifyUser, notifyWithText} from "@/app/framework/notifications/Notifications";
    import SlottedTag from "@/components/tags/SlottedTag";
    import {convertFilterToAttrs} from "@/dashboards/filter/BasicFilter";
    import {showTip} from "@/app/help/tips/tips";
    import {escapeHtml} from "@/app/utils/StringUtils";
    import BeButton from "@/components/buttons/BeButton";
    import {FANTASTIC_FIELDS} from "@/dashboards/widgets/fantasticchart/fields/Fields";
    import {getDefaultCompareSortField, getDefaultXSortField} from "@/dashboards/widgets/fantasticchart/FantasticUtilities";
    import CommentaryPlusEditor from "@/dashboards/widgets/commentaryplus/CommentaryPlusEditor.vue";
    import {features} from "@/app/Features";
    import {getCommentPlusPreview} from "../commentaryplus/blockTools/Utils";

    export default {
        name: "FantasticChartSettingsDialog",

        components: {
            CommentaryPlusEditor,
            BeButton,
            SlottedTag, DialogBox, TextInput, BeForm, DropDownInput, EnglishFilter,
            FilterEditor, MarkdownEditor, LanguageInput, TopicViewInput, FCYAxisSettings, FCDataSetSettings },

        props: {
            model: Object
        },

        data() {
            return {
                attrs: this.cloneModel(),
                original: this.cloneModel(),
                valid: true,
                showEditFilter: false,
                filter: String,
                showEditComment: false,
                comment: String,
                isPreviewing: false,
                previewWidth: "",
                sortOptions: {
                    'brand.name': { name: "Brand name", supportedAxis: ["brand"] },
                    mentionCount: { name: "Volume", supportedAxis: ["mentionCount", "mentionPercent", "averageConversationLength", "sentimentSamplePercent"] },
                    totalPositivePercent: { name: "Percentage positive", supportedAxis: ["positivePercent", "totalSentimentPercent", "totalSentiment", "negativePercent", "posAndNegPercent"] },
                    totalNegativePercent: { name: "Percentage negative", supportedAxis: ["negativePercent", "totalSentimentPercent", "totalSentiment", "positivePercent", "posAndNegPercent"] },
                    totalSentimentPercent: { name: "Percentage net sentiment", supportedAxis: ["totalSentimentPercent", "posAndNegPercent", "totalSentiment"] },
                    sentimentVerifiedPercent: { name: "Sentiment verified %", supportedAxis: ["sentimentSamplePercent"] },
                    totalEngagement: { name: "Engagement", supportedAxis: ["totalEngagement"] },
                    totalReshareCount: { name: "Reshares", supportedAxis: ["totalReshareCount"] },
                    conversationCount: { name: "Unique conversations", supportedAxis: ["averageConversationLength", "conversationIdCount"] },
                    averageConversationLength: { name: "Average conversation length", supportedAxis: ["averageConversationLength"] },
                    totalOTS: { name: "OTS", supportedAxis: ["totalOTS"] },
                    authorIdCount: { name: "Unique authors", supportedAxis: ["authorIdCount"] },
                    interactionCount: { name: "Unique interactions", supportedAxis: ["interactionCount", "interactionPercent"] },
                    siteCount: { name: "Unique sites", supportedAxis: ["siteCount"] },
                    languageCount: { name: "Unique languages", supportedAxis: ["languageCount"] },
                    countryCount: { name: "Unique countries", supportedAxis: ["countryCount"] },
                    ticketIdCount: { name: "Active engage tickets", supportedAxis: ["ticketIdCount"] },
                    averageFirstReplyTime: { name: "Average reply time", supportedAxis: ["averageFirstReplyTime"] },
                    averageHasReply: { name: "Response rate %", supportedAxis: ["averageHasReply"] },
                    totalHasReply: { name: "Total mentions replied to", supportedAxis: ["totalHasReply"] },
                    averageInteractionHasResponse: { name: "Interaction response rate", supportedAxis: ["averageInteractionHasResponse"] },
                    averageInteractionResponseTime: { name: "Interaction response time", supportedAxis: ["averageInteractionResponseTime"] },
                    averageInteractionFirstResponseTime: { name: "First response time", supportedAxis: ["averageInteractionFirstResponseTime"] },
                    averageInteractionFollowupResponseTime: { name: "Follow-up response time", supportedAxis: ["averageInteractionFollowupResponseTime"] },
                    averageInteractionWhResponseTime: { name: "Interaction response time (working hours)", supportedAxis: ["averageInteractionWhResponseTime"] },
                    averageInteractionWhFirstResponseTime: { name: "First response time (working hours)", supportedAxis: ["averageInteractionWhFirstResponseTime"] },
                    averageInteractionWhFollowupResponseTime: { name: "Follow-up response time (working hours)", supportedAxis: ["averageInteractionWhFollowupResponseTime"] },
                    interactionHasResponseCount: { name: "Interactions replied to", supportedAxis: ["interactionHasResponseCount"] }
                },
                interactionFields: [
                    "averageInteractionResponseTime",
                    "averageInteractionFirstResponseTime",
                    "averageInteractionFollowupResponseTime",
                    "averageInteractionWhResponseTime",
                    "averageInteractionWhFirstResponseTime",
                    "averageInteractionWhFollowupResponseTime",
                    "averageInteractionHasResponse",
                    "interactionHasResponseCount"
                ],
                commentaryPlusInMetricFeatureFlag: features.commentaryPlusInWidget()
            }
        },

        created() {
            this.options = new FantasticChartSettingsOptions();

            if (!this.attrs.xAxis) {
                console.warn(`xAxis is undefined for metric ${this.attrs.caption} [id:${this.attrs.id}]`);
            } else if (!this.xAxis) {
                console.warn(`xAxis [${this.attrs.xAxis}] has no known field for metric ${this.attrs.caption} [id:${this.attrs.id}]`);
            }

            if (this.showCompare) {
                showTip("EDIT_METRIC_COMPARE_BRANDS");
            }

            showTip("EDIT_METRIC_CHANGE_GEOMETRY", "METRIC_PREVIEW");
        },

        computed: {
            fields() { return FANTASTIC_FIELDS },
            xAxis() { return this.fields[this.attrs.xAxis] ?? {} },
            firstGeometry() { return this.attrs.show[0].geometry },
            compare() { return this.fields[this.attrs.compare] || {hideOthers: true, hideUnknown: true} },
            dashboardModel() { return this.model.getDashboardModel() },

            showTopicControls() { return this.attrs.xAxis === "topic" || this.attrs.compare === "topic" },
            showCooccurrence() { return this.xAxis.showCooccurrence || !!this.compare.showCooccurrence },
            showHideMissing() { return !!this.xAxis.hideMissing || !!this.compare.hideMissing },
            showCompare() { return this.firstGeometry !== 'pie' && this.attrs.show.length <= 1 },

            showDataSets() {
                return this.showCompare && this.attrs.compare === 'dataSet' || this.attrs.xAxis === "dataSet"
            },

            sectionFilter() {
                return this.model.getSectionModel().get('filter')
            },

            brand() {
                let brand = this.model.getInteractiveFilterModel().get('brand');
                if (!brand) brand = convertFilterToAttrs(this.sectionFilter).brand
                return brand
            },

            canAddSeries() {
                return (this.firstGeometry === 'columns' || this.firstGeometry === 'lines')
                    && !this.attrs.compare && this.attrs.xAxis !== 'sentiment'
            },

            hasY2Axis() {
                let show = this.attrs.show
                let scaleType = (this.options.yAxis[show[0].yAxis] || {}).scaleType
                for (let i = 1; i < show.length; i++) {
                    if (scaleType !== (this.options.yAxis[show[i].yAxis] || {}).scaleType) return true
                }
                return false
            },

            dateInterval() {
                let filter = this.sectionFilter
                // todo this should probably keep only the date info from the metric filter if it has some
                if (this.attrs.filter) filter = "(" + filter + ") and (" + this.attrs.filter + ")"
                return new DateInterval(filter)
            },

            previousDates() {
                return this.dateInterval.getPreviousDates()
            },

            canCompareDataSets() {
                let xo = this.options.xAxis[this.attrs.xAxis]
                return !xo || !xo.noCompareDataSets
            },

            compareOptions() {
                if (this.canCompareDataSets) return this.options.compare
                return Object.entries(this.options.compare).map(row => {
                    let o = {...row[1]}
                    o.id = row[0]
                    if ("dataSet" === o.id) o.disabled = true
                    return o
                })
            },

            canStack() {
                return this.firstGeometry === "columns" || this.firstGeometry === "rows"
            },

            title() {
                return this.isPreviewing ? "Metric Preview" : "Edit the metric's settings";
            },

            defaultXSortField() {
                let yAxis = this.attrs.show[0].yAxis;
                let xAxis = this.attrs.xAxis;

                return getDefaultXSortField(xAxis, yAxis);
            },

            filteredXSortOptions() {
                return this.getSortFieldOptions(this.defaultXSortField, false);
            },

            filteredXSortOrderOptions() {
                return this.getSortOrderOptions(this.attrs.xSortByField, this.defaultXSortField);
            },

            defaultCompareSortField() {
                let yAxis = this.attrs.show[0].yAxis;
                let compare = this.attrs.compare;

                return getDefaultCompareSortField(yAxis, compare);
            },

            filteredCompareSortOptions() {
                return this.getSortFieldOptions(this.defaultCompareSortField, true);
            },

            filteredCompareSortOrderOptions() {
                return this.getSortOrderOptions(this.attrs.compareBucketSortByField, this.defaultCompareSortField);
            },

            showDurationFormats() {
                for (const show of this.attrs.show) {
                    let yField = this.fields[show.yAxis];

                    if (yField?.isDuration) return true;
                }

                return false;
            },

            durationFormatIsDefault() {
                return this.attrs.durationFormat === 'default';
            }
        },

        watch: {
            model(v) {
                this.attrs = this.cloneModel()
            },

            'attrs.numberFormat'(v) {
                this.attrs.noSi = v !== "si"
            },

            'attrs.xAxis'(v) {
                if (v === 'sentiment' && this.attrs.show.length > 1) this.attrs.show = [this.attrs.show[0]]
                if (v === 'dataSet') this.ensureDataSets()
                if (this.attrs.compare === "dataSet" && !this.canCompareDataSets) this.attrs.compare = null
            },

            'attrs.compare'(v) {
                if (v === 'dataSet') this.ensureDataSets()
            },

            filteredXSortOptions() {
                // if the currently selected sort option is no longer part of the current filtered sort options,
                // set the selected sort option to default
                if (!this.filteredXSortOptions[this.attrs.xSortByField]) {
                    this.attrs.xSortByField = "default";
                }
            },

            defaultXSortField() {
                // if the default sort field changes, ensure that we select the default sort field's default sort order
                if (this.attrs.xSortByField === "default") {
                    this.attrs.xSortOrder = this.defaultXSortField?.defaultSortOptions?.order ?? "descending";
                }
            },

            filteredCompareSortOptions() {
                // if the currently selected sort option is no longer part of the current filtered sort options,
                // set the selected sort option to default
                if (!this.filteredCompareSortOptions[this.attrs.compareBucketSortByField]) {
                    this.attrs.compareBucketSortByField = "default";
                }
            },

            defaultCompareSortField() {
                // if the default sort field changes, ensure that we select the default sort field's default sort order
                if (this.attrs.compareBucketSortByField === "default") {
                    this.attrs.compareBucketSortOrder = this.defaultCompareSortField?.defaultSortOptions?.order ?? "descending";
                }
            }
        },

        mounted() {
            this.$nextTick(() => this.$refs.caption.focus())
        },

        methods: {
            getCommentPlusPreview,
            getSortFieldOptions(defaultSortField, isCompare) {
                let defaultSortLabel = defaultSortField?.defaultSortOptions?.label;
                let filteredSortOptions = {
                    default: {
                        name: defaultSortLabel,
                        subLabel: "Default"
                    }
                };

                let compareAxis = this.attrs.compare;
                let xAxis = this.attrs.xAxis;

                // check all yAxis values
                this.attrs.show.forEach(show => {
                    let yAxis = show.yAxis;

                    // filter sort options ensuring:
                    // 1. the default sort option isn't part of this.sortOptions
                    // 2. the y axis is supported by the sort field
                    let validSortOptionKeys = Object.keys(this.sortOptions).filter(sortOptionsKey => {
                        let curSortOption = this.sortOptions[sortOptionsKey];

                        let isDefaultSortOption = sortOptionsKey === defaultSortField?.defaultSortOptions?.field;
                        let supportedAxis = curSortOption.supportedAxis?.includes(yAxis) || curSortOption.supportedAxis?.includes(isCompare ? compareAxis : xAxis);

                        return sortOptionsKey === "default" || (!isDefaultSortOption && supportedAxis);
                    });

                    validSortOptionKeys.forEach(key => {
                        filteredSortOptions[key] = this.sortOptions[key];
                    });
                });

                return filteredSortOptions;
            },

            getSortOrderOptions(sortByField, defaultSortField) {
                let defaultSortOrder = defaultSortField?.defaultSortOptions?.order;

                return {
                    ascending: { name: "Ascending", subLabel: sortByField === "default" && defaultSortOrder === "ascending" ? "Default" : "" },
                    descending: { name:  "Descending", subLabel: sortByField === "default" && defaultSortOrder === "descending" ? "Default" : "" }
                }
            },

            ensureDataSets() {
                if (!this.attrs.dataSets || !this.attrs.dataSets.length) {
                    this.attrs.dataSets = [
                        { id: 1, label: "Set 1", filter: ""},
                        { id: 2, label: "Set 2", filter: ""}
                    ]
                }
            },

            cloneModel() {
                let attrs = {...this.model.attributes}
                Object.keys(attrs).forEach(k => {
                    if (k.charAt(0) == '_') {
                        delete attrs[k]
                    } else {
                        let v = attrs[k]
                        if (typeof v === 'object') attrs[k] = cloneDeep(v)
                    }
                })
                return attrs
            },

            validate() {
                this.$refs.form.validate()
                return this.valid
            },

            validateLater() {
                this.$nextTick(() => this.validate())
            },

            ok() {
                if (!this.validate()) return
                this.applyChanges(this.attrs)
                this.model.save(null, {
                    error: (model, xhr, options) => {
                        if (xhr.responseText) console.error(xhr.responseText, xhr)
                        else (console.error(xhr))
                        window.alert("Error saving changes .. please retry")
                    }
                })
                this.$emit('input', null)

                notifyUser({
                    message: escapeHtml`<strong>${this.original.caption}</strong> settings updated.`,
                    isEscapedHtml: true,
                    icon: '<i class="icon-signal-1"></i>',
                    undo: () => {
                        this.model.clear({silent: true})
                        this.model.set({'_preserveSettingsOnUpdate': true}, {silent: true}) // Need this to stop coarseness being updated.
                        this.model.set(this.original)
                        this.model.save();
                        notifyWithText("Metric setting changes have been undone.", null, '<i class="icon-signal-1"></i>')
                    }
                })
            },

            applyChanges(attrs) {
                if (this.firstGeometry === 'pie') {
                    attrs.compare = null
                }
                this.model.set(attrs)
            },

            preview() {
                this.isPreviewing = !this.isPreviewing;
                if (!this.isPreviewing) {
                    this.dialogWidth = this.width;
                    return;
                }

                if (!this.validate()) return;

                let previewModel = $.extend(true, {}, this.model);
                previewModel.set(this.attrs);

                let previewView = new Beef.Widget.View({model: previewModel});
                previewView.updateFilter();

                this.$nextTick(() => {
                    previewView.render();
                    this.$refs.metricPreview.append(previewView.el);

                    let style = getComputedStyle(previewView.el)
                    let widgetWidth = style.getPropertyValue('--widget-width');

                    // set width of dialog to be the width of the widget as it is in the dashboard
                    if (widgetWidth) this.previewWidth = `calc(${widgetWidth} + 40px)`;
                });
            },

            cancel() {
                this.$emit('input', null)
            },

            editFilter() {
                this.filter = this.attrs.filter
                this.showEditFilter = true
            },

            editFilterOk() {
                this.showEditFilter = false
                this.attrs.filter = this.filter
            },

            editComment() {
                this.comment = this.attrs.comment
                this.showEditComment = true
            },

            editCommentOk() {
                this.showEditComment = false
                this.attrs.comment = this.comment
            },

            removeHidden(id) {
                if (this.attrs.hidden?.length) {
                    this.attrs.hidden = this.attrs.hidden.filter(d => d.id !== id);
                }
            },

            addSeries() {
                if (!this.canAddSeries) return
                let yAxis = ['mentionCount', 'totalEngagement', 'totalNegative', 'totalPositive'].find(v => {
                    return !this.attrs.show.find(s => s.yAxis === v)
                }) || this.attrs.show[0].yAxis
                this.attrs.show.push({
                    yAxis: yAxis,
                    geometry: 'lines',
                    barOutline: 0,
                    barPadding: 10,
                    hideLabels: true,
                    lineWidth: 2,
                    lineCurve: 'smooth',
                    onlySupportHandles: true
                })
            },

            removeSeries(i) {
                if (i > 0) this.attrs.show.splice(i, 1)
            },

            addDataSet() {
                let id = 0
                this.attrs.dataSets.forEach(ds => id = Math.max(ds.id, id))
                ++id
                this.attrs.dataSets.push({
                    id: id,
                    label: "",
                    date: null,
                    filter: ""
                })
            },

            removeDataSet(i) {
                if (this.attrs.dataSets.length > 1) this.attrs.dataSets.splice(i, 1)
            },

            last4Weeks() {
                this.attrs.dataSets = [
                    { id: 1, label: "", date: "week-3", filter: ""},
                    { id: 2, label: "", date: "week-2", filter: ""},
                    { id: 3, label: "", date: "week-1", filter: ""},
                    { id: 4, label: "", date: "current", filter: ""},
                ]
            },

            last3Months() {
                this.attrs.dataSets = [
                    { id: 1, label: "", date: "month-2", filter: ""},
                    { id: 2, label: "", date: "month-1", filter: ""},
                    { id: 3, label: "", date: "current", filter: ""},
                ]
            },

            last4Months() {
                this.attrs.dataSets = [
                    { id: 1, label: "", date: "month-3", filter: ""},
                    { id: 2, label: "", date: "month-2", filter: ""},
                    { id: 3, label: "", date: "month-1", filter: ""},
                    { id: 4, label: "", date: "current", filter: ""},
                ]
            },

            async editCommentPlus(output){
                try {
                    this.attrs.commentPlus = JSON.stringify(output);
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }
</script>

<style scoped>

.settings-body {
    min-height: 480px;
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.btn-container {
    display: flex;
    column-gap: 5px;
}

.btn-container > :first-child {
    margin-left: auto;
}

.control-row {
    display: flex;
}

.control-row > div:not(:first-child) {
    margin-left: 15px;
}

.form > .row-fluid:not(:first-child) {
    margin-top: 10px;
}

.row-fluid [class*="span"] {
    min-height: 20px;
}

.comments:hover,
.filter:hover {
    border-color: var(--be-filter-tag-hover-colour);
}

.filter {
    width: auto;
    min-width: 206px;
}

.fc-settings ::v-deep .dialog-body {
    max-height: calc(90vh - 64px);
}

.text-units {
    margin-top: 27px;
    margin-left: 6px;
    color: #bbb;
}

.comment-editor /deep/ textarea {
    min-height: 300px;
}

.fc-settings__hidden {
    display: flex;
    flex-wrap: wrap;
}

.fc-settings__hidden .be-tag {
    margin-right: 5px;
    margin-top: 5px;
}

.fc-settings__datasets {
    max-height: 200px;
    overflow-y: auto;
}
</style>

<style scoped lang="sass">

.preview-widget
    max-height: calc(90vh - 125px)
    overflow: auto

.preview-widget-container
    grid-template-columns: repeat(auto-fit, var(--widget-width))
    animation: fadeIn linear 250ms
    width: auto

    ::v-deep .widget-height-inner
        & >:first-child
            pointer-events: none

    ::v-deep .markdown-display
        overflow: visible !important

@keyframes fadeIn
    0%
        opacity: 0
    100%
        opacity: 1

</style>

<style scoped>
.commentary-box {
    max-height: 500px;
    //height: 50%;
    overflow: auto;
    background-color: white;
}
</style>