<template>

    <div class="deq-feed-picker">
        <PopupMenu ref="popupMenu" fixed :value="showList"
                    ignore-activator-click :arrow-pointer="{show: true, left: true}" :never-hide="showList">
            <template #activator>
                <label for="" class="feed-picker-label" @click="onBlur($event)">Only read these feeds (leave blank for all)</label>
                <div tabindex="0" ref="selectedFeedsContainer" class="deq-input-div selected-feeds" @click="focusSearch" @focusout="onBlur">
                    <SlottedTag ref="slotRef" v-for="feed in selectedFeeds" :key="feed" @close="unselectFeed(feed)" class="feed-tag">
                        {{feedOptions.find(feedOpt => feedOpt.name === feed).displayName}}
                    </SlottedTag>
                    <input ref="textInput" class="search-input" v-model="searchInput"
                           :placeholder="selectedFeeds.length ? '' : 'Search for feed name here'">
                </div>
            </template>

            <ul ref="feedsList" class="feeds-list dark-scrollbars dark-scrollbars--visible">
                <li class="feed" v-for="feed in filteredFeeds" :key="feed.name" @click="onFeedSelect(feed.name)"
                    :style="{'pointer-events': feed.name === 'no_results' ? 'none' : 'unset'}">
                    {{feed.displayName}}
                </li>
            </ul>
        </PopupMenu>
    </div>

</template>

<script>
import SlottedTag from "@/components/tags/SlottedTag.vue";
import PopupMenu from "@/components/PopupMenu.vue";

export default {
    name: "FeedsPicker",
    components: {PopupMenu, SlottedTag},
    model: {
        prop: 'originalFeeds',
        event: 'feeds-selected'
    },
    props: {
        originalFeeds: {
            type: String,
            required: false,
            default: ''
        }
    },

    mounted() {
        // no other way of closing the list: PopupMenu requires a boolean to show/hide
        document.querySelector('.dialog').addEventListener('click', this.onBlur);
        this.emitData();
    },

    destroyed() {
        document.querySelector('.dialog').removeEventListener('click', this.onBlur);
    },

    computed: {
        filteredFeeds() {
            const temp = this.feedOptions.filter(feed => {
                return feed.name.toLowerCase().includes(this.searchInput.toLowerCase())
                    || feed.displayName.toLowerCase().includes(this.searchInput.toLowerCase())
            });

            if (temp.length) {
                return temp;
            } else {
                return [{name: "no_results", displayName: 'No results found'}];
            }
        },
    },

    data() {
        return {
            showList: false,
            searchInput: '',
            selectedFeeds: this.originalFeeds ? this.originalFeeds.split(' ') : [],

            feedOptions: [
                {name: "TWITTER", displayName: 'X'},
                {name: "TWITTER_SAMPLE", displayName: 'X 1% stream'},
                {name: "TWITTER_DM", displayName: 'X DMs'},
                {name: "TWITTER_ARCHIVE", displayName: 'X Explore searches'},
                {name: "MOREOVER", displayName: 'Moreover'},
                {name: "GOOGLENEWS", displayName: 'Google News'},
                {name: "GOOGLEMAPSREVIEWS", displayName: 'Google Business & Maps'},
                {name: "GOOGLE_QNA", displayName: 'Google Business Questions and Answers'},
                {name: "TWINGLY", displayName: 'Twingly'},
                {name: "FACEBOOKPOSTS", displayName: 'Facebook Posts'},
                {name: "FACEBOOKPAGES", displayName: 'Facebook Pages'},
                {name: "INSTAGRAMTAGS", displayName: 'Instagram Tags'},
                {name: "INSTAGRAMUSERS", displayName: 'Instagram Profiles'},
                {name: "INSTAGRAM_BUSINESS", displayName: 'Instagram Business graph API'},
                {name: "GOOGLEPLUSPOSTS", displayName: 'Google+ Posts'},
                {name: "SALTY", displayName: 'Old, Salty, mention uploader (defunct)'},
                {name: "FONDUE", displayName: 'Mention Uploader'},
                {name: "VKNEWS", displayName: 'VK News'},
                {name: "BOARDREADER", displayName: 'Boardreader'},
                {name: "CUSTOM", displayName: 'Custom feeds'},
                {name: "TUMBLR", displayName: 'Tumblr'},
                {name: "PRESSREADER", displayName: 'PressReader.com'},
                {name: "EMEDIAMONITOR", displayName: 'EMediaMonitor.net'},
                {name: "NOVUS_PRESS", displayName: 'Novus News Print Media'},
                {name: "NOVUS_BROADCAST", displayName: 'Novus News Broadcast Media'},
                {name: "WEBHOSE", displayName: 'Webhose'},
                {name: "YOUTUBE", displayName: 'Youtube'},
                {name: "YOUTUBE_PROFILES", displayName: 'Youtube Profiles'},
                {name: "LINKEDIN", displayName: 'LinkedIn Company Posts'},
                {name: "TELEGRAM", displayName: 'Telegram Channels'},
                {name: "WHATSAPP", displayName: 'WhatsApp Messages'}
            ],

        }
    },

    methods: {
        onFeedSelect(selectedFeed) {
            if (!this.selectedFeeds.find(feedName => feedName === selectedFeed)) {
                this.selectedFeeds.push(selectedFeed);
                this.emitData()
            }
            this.showList = true;
            this.searchInput = '';
        },

        unselectFeed(feedName) {
            this.selectedFeeds = this.selectedFeeds.filter(feed => feed !== feedName);
            this.emitData();
        },

        emitData() {
            this.$emit('feeds-selected', this.selectedFeeds.join(' '));
        },

        focusSearch() {
            this.$refs.textInput.focus();
            this.showList = true;
        },

        onBlur(event) {
            if (event.target.classList.contains('feed-picker-label') || !this.$refs.popupMenu.$el.contains(event.target)) {
                this.showList = false;
                this.emitData();
            }
        }

    }

}
</script>

<style scoped lang="sass">
.deq-feed-picker
    position: relative

    .feed-picker-label
        cursor: text

.feeds-list
    max-height: 200px
    overflow-y: auto
    width: 300px

    .feed
        &:hover
            background-color: #222222
            cursor: pointer

.selected-feeds
    display: flex
    flex-wrap: wrap
    gap: 7px
    position: relative

    .feed-tag
        &:hover
            color: #AEE15D

.search-input
    margin-top: 3px
    background-color: var(--be-picker-ul-blur-box-shadow)
    border: none
    caret-color: white
    color: white

    &:focus
        border: none
        outline: none

    &::placeholder
        font-size: 10pt
        color: var(--bootstrap-placeholder)

</style>