/**
 * Recursively set the phrase.parent property on the Brand object and its children
 * @param brand
 */
export function setPhraseParent (brand) {

    if (brand.phrases) {
        for (const phrase of brand.phrases) {
            phrase.parent = brand;
        }
    }

    if(brand.children) {
        for (const child of brand.children) {
            setPhraseParent(child);
        }
    }
}

/**
 * Recursively extracts phrases from the Brand object and its children
 *
 * @param brand {Brand}
 * @returns {[BrandPhrase]}
 */
export function extractPhrases (brand) {

    let extracted = [];

    if (brand.phrases) {
        extracted = [...extracted, ...brand.phrases]
    }

    if(brand.children) {
        for (const child of brand.children) {
            extracted = [...extracted, ...extractPhrases(child)];
        }
    }

    return extracted
}

/**
 * Recursively removes inactive phrases from the Brand object and its children
 * @param brand {Brand}
 */
export function removeInactivePhrases (brand) {

    if (brand.phrases) {
        brand.phrases = brand.phrases.filter(phrase => {return !phrase.inactive})
    }

    if(brand.children) {
        for (const child of brand.children) {
            removeInactivePhrases(child);
        }
    }
}