<template>
    <dialog-box class="add-mention-to-engagement-cache-dialog" @close="$emit('close')" title="Add mention to engagement cache" width="700px" overlay>
        <div>
            Add this mention to the cache for engagement metric collection.

            <div class="add-mention-to-engagement-cache-dialog__checkboxes">
                <label class="checkbox">
                    <input type="checkbox" v-model="isCampaignMention">
                    This mention was published via the Engage publishing tool
                </label>

                <label class="checkbox">
                    <input type="checkbox" v-model="isPollMention">
                    This mention is a poll
                </label>
            </div>
        </div>

        <template #buttons>
            <div class="add-mention-to-engagement-cache-dialog__button-bar">
                <be-button @click="cancel()" :disabled="saving" style="margin-left: auto">
                    Cancel
                </be-button>
                <be-button primary @click="addToCache()" :disabled="saving">
                    Add to cache
                    <spinner-component :size="11" class="save-spinner" v-if="saving"></spinner-component>
                </be-button>
            </div>
        </template>

    </dialog-box>
</template>

<script>

import BeButton from "@/components/buttons/BeButton.vue";
import DialogBox from "@/components/DialogBox.vue";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import {currentAccountCode} from "@/app/utils/Account";
import {lasagna} from "@/store/Services";
import { notifyUserOfError, notifyUser } from "@/app/framework/notifications/Notifications";

export default {
    name: "AddMentionToEngagementCacheDialog",
    components: {SpinnerComponent, DialogBox, BeButton},

    props: {
        mentionId: {
            type: String,
            required: true
        }
    },

    data: function() {
        return {
            saving: false,
            isCampaignMention: false,
            isPollMention: false
        }
    },

    methods: {
        async addToCache() {
            this.saving = true;

            try {
                let payload = {
                    id: this.mentionId,
                    campaignMention: this.isCampaignMention,
                    poll: this.isPollMention
                }
                await lasagna.post(`/rest/mention-cache/${currentAccountCode()}/add-mention`, payload);

                notifyUser("Mention has been added to engagement cache.");
                this.$emit('close');
            } catch (e) {
                console.error("Failed to add mention to engagement cache: ", e);
                notifyUserOfError("Failed to add mention to engagement cache.");
            } finally {
                this.saving = false;
            }
        },

        cancel() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped lang="sass">

.add-mention-to-engagement-cache-dialog

    &__checkboxes
        margin-top: 20px

    &__button-bar
        display: flex
        column-gap: 5px


</style>