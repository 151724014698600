<template>

    <div class="trash-cell">
        <template v-if="child && child.volume && child.volume.trash">
            {{child.volume.trash}}%
        </template>
    </div>

</template>

<script>
export default {
    name: "PhraseVolumeTrashCell",
    props: ['child']
}
</script>

<style scoped lang="sass">

</style>