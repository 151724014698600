<template>
    <dialog-box overlay @close="close" width="800px" class="all-tips-dialog" :data-expanded="expanded">
        <template #title>
            <div class="all-tips-dialog__title">
                <h5><i class="symbol-tip"></i> Analyse help & tips</h5>
                <be-button link class="all-tips-dialog__expand" @click="expanded = !expanded"
                           tooltip="Click to make this dialog bigger or smaller">
                    <span v-if="expanded">Shrink</span>
                    <span v-else>Expand</span>
                </be-button>
            </div>
        </template>
        <div class="all-tips-dialog__container">
            <sidebar-component class="all-tips-dialog__sidebar dark-scrollbars dark-scrollbars--visible">
                <sidebar-header>
                    <h4>Help & Tips</h4>
                </sidebar-header>
                <sidebar-menu-item :active="active === 'welcome'"
                                   @click="active = 'welcome'">
                    About
                </sidebar-menu-item>
                <sidebar-menu-item :active="active === 'glossary'" @click="active = 'glossary'">
                    Glossary
                </sidebar-menu-item>

                <sidebar-menu v-if="menus.explore.views.length"
                              collapsable
                              :start-collapsed="!menus.explore.open">
                    <template #header="{collapsed}">
                        <sidebar-header>
                            <h4>
                                <collapsable-caret :collapsed="collapsed"/>
                                <i class="symbol-explore"></i>
                                {{ menus.explore.name }}
                            </h4>
                        </sidebar-header>
                    </template>

                    <sidebar-menu-item
                        v-for="view in menus.explore.views"
                        :key="view.theme"
                        :active="isActiveView(menus.explore, view)"
                        @click="setActiveView(menus.explore, view)"
                        indented>
                        {{ view.theme }}
                    </sidebar-menu-item>
                </sidebar-menu>

                <sidebar-menu v-if="menus.reports.views.length"
                              collapsable
                              :start-collapsed="!menus.reports.open">
                    <template #header="{collapsed}">
                        <sidebar-header>
                            <h4>
                                <collapsable-caret :collapsed="collapsed"/>
                                <i class="symbol-reports"></i>
                                {{ menus.reports.name }}
                            </h4>
                        </sidebar-header>
                    </template>
                    <template #default>
                        <sidebar-menu-item
                            v-for="view in menus.reports.views"
                            :key="view.theme"
                            :active="isActiveView(menus.reports, view)"
                            @click="setActiveView(menus.reports, view)"
                            indented>
                            {{ view.theme }}
                        </sidebar-menu-item>
                    </template>
                </sidebar-menu>


                <sidebar-menu v-if="menus.mentions.views.length"
                              collapsable
                              :start-collapsed="!menus.mentions.open">
                    <template #header="{collapsed}">
                        <sidebar-header>
                            <h4>
                                <collapsable-caret :collapsed="collapsed"/>
                                <i class="symbol-mentions"></i>
                                {{ menus.mentions.name }}
                            </h4>
                        </sidebar-header>
                    </template>
                    <template #default>
                        <sidebar-menu-item
                            v-for="view in menus.mentions.views"
                            :key="view.theme"
                            :active="isActiveView(menus.mentions, view)"
                            @click="setActiveView(menus.mentions, view)"
                            indented>
                            {{ view.theme }}
                        </sidebar-menu-item>
                    </template>
                </sidebar-menu>

                <sidebar-menu v-if="menus.authors.views.length"
                              collapsable
                              :start-collapsed="!menus.authors.open">
                    <template #header="{collapsed}">
                        <sidebar-header>
                            <h4>
                                <collapsable-caret :collapsed="collapsed"/>
                                <i class="symbol-authors"></i>
                                {{ menus.authors.name }}
                            </h4>
                        </sidebar-header>
                    </template>

                    <template #default>
                        <sidebar-menu-item
                            v-for="view in menus.authors.views"
                            :key="view.theme"
                            :active="isActiveView(menus.authors, view)"
                            @click="setActiveView(menus.authors, view)"
                            indented>
                            {{ view.theme }}
                        </sidebar-menu-item>
                    </template>
                </sidebar-menu>

                <sidebar-menu v-if="menus.setup.views.length"
                              collapsable
                              :start-collapsed="!menus.setup.open">
                    <template #header="{collapsed}">
                        <sidebar-header>
                            <h4>
                                <collapsable-caret :collapsed="collapsed"/>
                                <i class="symbol-setup"></i>
                                {{ menus.setup.name }}
                            </h4>
                        </sidebar-header>
                    </template>
                    <template #default>
                        <sidebar-menu-item
                            v-for="view in menus.setup.views"
                            :key="view.theme"
                            :active="isActiveView(menus.setup, view)"
                            @click="setActiveView(menus.setup, view)"
                            indented>
                            {{ view.theme }}
                        </sidebar-menu-item>
                    </template>
                </sidebar-menu>
                <sidebar-menu-item :active="active === 'shortcuts'" @click="active = 'shortcuts'">
                    Keyboard shortcuts
                </sidebar-menu-item>
            </sidebar-component>

            <section v-if="active === 'welcome'"
                 class="all-tips-dialog__welcome">
                <header>
                    <h1>Help & Tips</h1>
                </header>

                <section class="tips-view__welcome-message dark-scrollbars dark-scrollbars--visible">
                    <p>
                        Tips will occasionally be shown to you while you work with Analyse. You can stop tips
                        from being shown by turning off the <em>Tips enabled</em> option below. You can also <em>Reset tips</em>
                        if you wish to be reshown all tips that you have previously seen.
                    </p>
                    <p>
                        All available tips are organised to the left, if you would like to
                        browse through them.
                    </p>
                    <h2>
                        Other help resources
                    </h2>
                    <div class="all-tips-dialog__extra">
                        <dark-card>
                            We have a short
                            <a href="https://storage.googleapis.com/brandseye-public-files/dataeq-analyse-quickstart-guide-1.1.pdf"
                               target="_blank">
                                Quickstart Guide
                            </a> to help you get up and running quickly.
                        </dark-card>

                        <dark-card>
                            If you want all of the details, you can look through our
                            <a href="https://dataeq.com/knowledge/analyse"
                               target="_blank">
                                knowledge base</a>.
                        </dark-card>
                        <dark-card>
                            If you are a developer wanting to integrate with our data and APIs,
                            have a look at <a href="https://dev.dataeq.com" target="_blank">our docs</a>.
                        </dark-card>
                        <dark-card>
                            You can email questions to our <a href="mailto:support@dataeq.com">helpful support team</a>.
                        </dark-card>
                    </div>
                </section>


            </section>
            <div v-else-if="active === 'shortcuts'"
                 class="all-tips-dialog__welcome">
                <header>
                    <h1>Keyboard shortcuts</h1>
                </header>

                <p>
                    There are keyboard shortcuts that can make Analyse easier to use. An important
                    shortcut to remember is
                    <keyboard-key>Shift</keyboard-key>
                    +
                    <keyboard-key>?</keyboard-key>,
                    which opens this help dialog.
                </p>

                <div class="tips-view__shortcuts dark-scrollbars dark-scrollbars--visible">
                    <h2>Navigation</h2>
                    <p>These keyboard shortcuts lets you navigate between different portions of Analyse.</p>
                    <div class="tips-view__navigation-grid">
                        <template v-for="shortcut in navigationShortcuts">
                            <span :key="shortcut.key + ':key'">
                                <keyboard-key>G</keyboard-key>&nbsp;<keyboard-key>{{shortcut.key}}</keyboard-key>
                            </span>
                                <span :key="shortcut.key + ':description'">
                                {{shortcut.description}}
                            </span>
                        </template>
                    </div>

                    <div v-for="page in menus" :key="page.name">
                        <div v-if="getTipsFor(page.name).length > 0">
                            <h2>{{page.name}} Panel Shortcuts</h2>
                            <div class="tips-view__tip-container" v-for="tip in getTipsFor(page.name)" :key="tip.id">
                                <span class="tips-view__tip-keyboard be-tooltip__keyword-styling">{{tip.keyboard}}</span>
                                <div class="tips-view__tip be-notification-tip">
                                    <span v-html="tip.description"></span>
                                    <span v-if="user.debugMode">
                                <br>
                                <slotted-tag no-close>🐛</slotted-tag>
                                ID: [{{tip.id}}]
                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="active === 'glossary'" class="all-tips-dialog__welcome">
                <header>
                    <h1>Glossary</h1>
                </header>
                <glossary/>
            </div>

            <div v-else
                 class="tips-view">
                <header class="tips-view__header">
                    <h1>{{ currentTipsView.menu }} » {{ currentTipsView.theme }}</h1>
                    <a v-if="account && account.code" @click="navigate(currentTipsView.url)"><i class="icon-link-ext"></i> Go to
                        {{ currentTipsView.menu }} Panel</a>
                </header>

                <p class="tips-view__description">{{ currentTipsView.description }}</p>

                <div class="tips-view__tips-list dark-scrollbars dark-scrollbars--visible">
                    <div class="tips-view__tip-container" v-for="tip in filteredTips" :key="tip.id">
                        <i class="symbol-tip tips-view__tip-icon"></i>
                        <div class="tips-view__tip be-notification-tip">
                            <span v-html="tip.description"></span>
                            <span v-if="user.debugMode">
                                <br>
                                <slotted-tag no-close>🐛</slotted-tag>
                                ID: [{{tip.id}}]
                            </span>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <template #buttons>
            <div class="all-tips-dialog__button-container">
                <div class="all-tips-dialog__tips-action-controls">
                    <be-button v-if="!tipsEnabled"
                               :disabled="saving"
                               primary
                               tooltip="Click to enable tips. You will see tips as you use Analyse."
                               @click="toggleTipsSetting(true)">
                        Enable tips
                    </be-button>
                    <slotted-tag v-else
                                 :disabled="saving"
                                 @close="toggleTipsSetting(false)"
                                 close-tooltip="Click to disable tips. You will stop seeing tips as you use Analyse.">
                        <animated-check :animated="tipsJustEnabled"/> Tips enabled
                    </slotted-tag>
                    <be-button link
                               class="all-tips-dialog__rest-tips-button"
                               tooltip="All tips you have previously seen will be shown again."
                               :disabled="!resetTipsEnabled || saving"
                               @click="resetTips">
                        {{ resetTipsStatus }}
                        <spinner-component :size="11" class="save-spinner" v-if="resettingTips"></spinner-component>
                    </be-button>

                </div>

                <be-button class="all-tips-dialog__close-button" @click="close" tooltip="Click to close this dialog" keyword="esc">Close</be-button>
            </div>
        </template>
    </dialog-box>
</template>

<script>
import DialogBox from "@/components/DialogBox";
import SidebarComponent from "@/components/sidebar/SidebarComponent";
import SidebarMenu from "@/components/sidebar/SidebarMenu";
import SidebarMenuItem from "@/components/sidebar/SidebarMenuItem";
import BeButton from "@/components/buttons/BeButton";
import {default as tips} from "./tip-text";
import {mash} from "@/store/Services";
import SpinnerComponent from "@/components/SpinnerComponent";
import {clearTipsCache, resetTips} from "@/app/help/tips/tips";
import {showInfoDialog, showWhenDialog} from "@/app/framework/dialogs/Dialog";
import {notifyUser, notifyWithText} from "@/app/framework/notifications/Notifications";
import {pages, themes} from "@/app/help/tips/tip-utils";
import SlottedTag from "@/components/tags/SlottedTag";
import AnimatedCheck from "@/components/animated-icons/AnimatedCheck";
import SidebarHeader from "@/components/sidebar/SidebarHeader";
import CollapsableCaret from "@/components/CollapsableCaret";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapMutations, mapState} from "vuex";
import DarkCard from "@/components/cards/DarkCard";
import KeyboardKey from "@/components/formatters/KeyboardKey";
import {NAVIGATION_SHORTCUTS} from "@/app/help/Shortcuts";
import Glossary from "@/app/help/tips/GlossaryView";

export default {
    name: "AllTipsDialog",
    store: VuexStore,
    components: {
        SpinnerComponent,
        Glossary,
        KeyboardKey,
        DarkCard,
        CollapsableCaret,
        SidebarHeader,
        AnimatedCheck,
        SlottedTag,
        BeButton,
        SidebarMenuItem,
        SidebarMenu,
        SidebarComponent,
        DialogBox
    },

    data() {
        const account = VuexStore.state.account;

        const menus = {
            explore: {
                name: pages.explore,
                open: false,
                url: `/${account.code}/explore`,
                views: []
            },
            reports: {
                name: pages.reports,
                open: false,
                url: `/${account.code}/dashboards`,
                views: []
            },
            mentions: {
                name: pages.mentions,
                open: false,
                url: `/${account.code}/mentions`,
                views: []
            },
            authors: {
                name: pages.authors,
                open: false,
                url: `/${account.code}/authors`,
                views: []
            },
            setup: {
                name: pages.setup,
                open: false,
                url: `/${account.code}/setup`,
                views: []
            }
        };

        return {
            menus,
            currentTipsView: {
                menu: String,
                theme: String,
                url: String,
                description: String
            },
            tips: [],
            saving: false,
            resettingTips: false,
            resetTipsEnabled: true,
            resetTipsStatus: "Reset tips",
            tipsEnabled: false,
            tipsJustEnabled: false,

            active: "welcome",
            lastAddress: null,

            navigationShortcuts: [],
            expanded: location.pathname === "/help"
        }
    },

    computed: {
        ...mapState(['user', 'account']),
        filteredTips() {
            return this.tips.filter(tip => tip.theme.name === this.currentTipsView.theme && tip.page === this.currentTipsView.menu);
        },

        shortcutTips() {
            return this.tips.filter(tip => tip.keyboard);
        },
    },

    async created() {
        const promise = this.refreshUser();

        for (let i = 0; i < Object.keys(tips).length; i++) {
            let tipId = Object.keys(tips)[i];
            let tip = tips[tipId];

            // only tips with both a theme and page variable set will be shown in the dialog
            if (tip.theme && tip.page) {
                this.tips.push(tip);

                // dynamically build menu views based on tips currently in the system
                let menu = this.getMenuByName(tips[tipId].page);

                if (menu !== null) {
                    if (!menu.views.some(view => view.theme === tip.theme.name)) {
                        menu.views.push({ theme: tip.theme.name, description: tip.theme.description})
                    }
                }
            }
        }

        for (const shortcut of NAVIGATION_SHORTCUTS) {
            if (await shortcut.isValid()) this.navigationShortcuts.push(shortcut);
        }

        await promise;
        this.tipsEnabled = this.user?.settings?.viewTips ?? true;

        // try to load last view from session storage. Defaults to welcome page otherwise.
        const lastViewJson = sessionStorage.getItem("beef:user:" + this.user.id + ":all-tips-last-view");
        const lastView = lastViewJson ? JSON.parse(lastViewJson) : null;

        if (location.hash?.startsWith("#glossary:")) {
            this.active = 'glossary';
        } else if (lastView?.menu) {
            // find the menu key of the last view
            let menu = Object.keys(this.menus).find(key => this.menus[key].name === lastView.menu);
            let view = this.menus[menu].views.find(view => view.theme === lastView.theme);
            this.setActiveView(this.menus[menu], view);
        } else {
            this.active = lastView ?? "welcome";
        }
    },

    async mounted() {
        const address = `/help`;
        this.lastAddress = location.pathname + location.search;
        if (this.lastAddress === address) this.lastAddress = null;
        Beef.router.navigate(address, {trigger: false});

        if (location.hash?.startsWith("#glossary:")) {
            setTimeout(() => {
                const element = document.getElementById(location.hash.slice(1));
                if (element) element.scrollIntoView({behavior: "smooth"});
            })
        }

        document.addEventListener('keydown', this.escapeHandler);
    },

    async beforeDestroy() {
        if (this.lastAddress) {
            Beef.router.navigate(this.lastAddress, {trigger: false});
        } else if (!Beef.content.currentView) { // Someone opened this by directly visiting the /help uri
            window.location = "/";
        }

        document.removeEventListener('keydown', this.escapeHandler);
    },

    methods: {
        ...mapActions(['refreshUser']),
        ...mapMutations(['setUserViewTips']),

        getTipsFor(page) {
            let items = this.shortcutTips?.filter(t => t.page === page) ?? [];
            return items.sort((lhs, rhs) => {
                if (lhs.keyboard.length === rhs.keyboard.length) {
                    return lhs.keyboard.toLocaleLowerCase().localeCompare(rhs.keyboard.toLocaleLowerCase());
                } else {
                    return rhs.keyboard.length - lhs.keyboard.length;
                }
            });
        },

        escapeHandler(event) {
            if (event?.key === 'Escape' && event?.target?.tagName !== 'INPUT') {
                event.stopPropagation(); // If this dialog is the only thing loaded, ensure the escape doesn't stop the page load that happens in beforeDestroy
                event.preventDefault();
                this.$emit('close');
            }
        },

        getMenuByName(name) {
            let key = Object.keys(this.menus).find(key => this.menus[key].name === name);

            if (key) return this.menus[key];

            return null;
        },

        isActiveView(menu, view) {
            return !this.active && this.currentTipsView.menu === menu.name && this.currentTipsView.theme === view.theme;
        },

        setActiveView(menu, view) {
            this.active = null;
            menu.open = true;

            this.currentTipsView.menu = menu.name;
            this.currentTipsView.theme = view.theme;
            this.currentTipsView.description = view.description;
            this.currentTipsView.url = menu.url;
        },

        navigate(url) {
            if (!this.account.code) {
                showInfoDialog("You must select an account before you can navigate to this page.", "Unable to navigate to page");

                return;
            }

            this.lastAddress = null;

            switch (this.currentTipsView.theme) {
                case themes.notifications.name:
                    Beef.router.navigate(`${url}/notifications`, {trigger: true});
                    break;
                case themes.onlineProfiles.name:
                    Beef.router.navigate(`${url}/online`, {trigger: true});
                    break;
                default:
                    Beef.router.navigate(url, {trigger: true});
                    break;
            }

            this.close();
        },

        async updateTipsSetting(value) {
            await mash.put("/rest/users/" + this.user.id + "/settings", {viewTips: !!value});
            await this.setUserViewTips(!!value);
        },

        async toggleTipsSetting(value) {
            try {
                this.saving = true;
                this.tipsEnabled = value;
                this.tipsJustEnabled = value;

                await this.updateTipsSetting(value);
            } catch (e) {
                console.error("Error enabling/disabling user tips", e);
                this.tipsEnabled = !value;
                this.tipsJustEnabled = !value;

                notifyWithText("Unable to enable/disable tips: an error occurred");
            } finally {
                this.saving = false;
                this.tipsEnabled = value;
                this.tipsJustEnabled = value;

                notifyUser({
                    message: value ? "Tips have been enabled. You will see tips as you use Analyse." : "Tips have been disabled. You will no longer see tips as you use Analyse.",
                    undo: async () => {
                        try {
                            await this.updateTipsSetting(!value);

                            let undoMessage = "Your changes have been undone: " + (!value ? "Tips have been re-enabled." : "Tips have been disabled again.");

                            this.tipsEnabled = !value;
                            this.tipsJustEnabled = !value;

                            notifyWithText(undoMessage);
                        } catch(e) {
                            console.error("Error enabling/disabling user tips", e);
                            notifyWithText("Unable to undo changes: an error occurred.");
                        }
                    }
                });
            }
        },

        async resetTips() {
            if (this.resetTipsEnabled) {
                showWhenDialog("Are you sure you want to reset your tips? All tips you have previously seen will be shown again.", "Reset tips?")
                    .then(async function () {
                        try {
                            this.resettingTips = true;
                            // clear tips in cache
                            clearTipsCache();

                            // reset tips in api
                            await resetTips();

                            this.resetTipsStatus = "Tips Successfully Reset";
                        } catch (e) {
                            console.error("Error while trying to reset tips", e);

                            this.resetTipsStatus = "Error Resetting Tips"
                        } finally {
                            this.resetTipsEnabled = false;
                            this.resettingTips = false;
                        }
                    }.bind(this));
            }
        },

        close() {
            this.$emit('close');

            // remember last view
            let lastView = {
                menu: this.currentTipsView.menu,
                theme: this.currentTipsView.theme
            };

            if (this.active) lastView = this.active;

            sessionStorage.setItem("beef:user:" + this.user.id + ":all-tips-last-view", JSON.stringify(lastView));
        }
    }
}

</script>

<style lang="sass">

.all-tips-dialog
    strong
        color: white // Needs to be unscoped because we must style dom elements injected with v-html

</style>

<style scoped lang="sass">

.all-tips-dialog
    --dialog-height: clamp(400px, 50vh, 500px)
    --dialog-width: clamp( 500px, 800px, calc(100vw - 300px))

    header
        border-bottom: 1px solid #666666
        margin-bottom: 20px
        h1
            color: var(--heading-colour-text-dark)
    h2
        color: var(--heading-colour-text-dark)

    &__sidebar
        position: static
        height: auto
        white-space: nowrap
        overflow-y: auto

    ::v-deep .dialog-body
        padding: 0

    ::v-deep .modal-container
        width: var(--dialog-width) !important
        //width: clamp(800px, 100ch + var(--sidebar-size), 100vw - 100px) !important

    &__container
        display: flex
        height: var(--dialog-height)
        transition: height var(--transition-duration)
        color: var(--be-colour-text-dark)

        .tips-view
            padding: 15px 30px 0 30px
            width: calc(100% - var(--sidebar-size))
            height: 100%
            box-sizing: border-box
            display: flex           // Needed to contain the scrolling of its child elements to this element's height.
            flex-direction: column

            &__header
                display: flex
                align-items: center
                padding-right: 20px

                a
                    margin-left: auto

                a:hover
                    cursor: pointer


            &__description
                margin-bottom: 20px

            &__tips-list
                height: 100%
                overflow-y: auto

            &__tip-container
                display: flex
                margin-bottom: 10px


            &__tip-keyboard,
            &__tip-icon
                border-top: 1px solid #1a1a1a
                border-left: 1px solid #1a1a1a
                border-bottom: 1px solid #1a1a1a
                height: 22px
                border-top-left-radius: 5px
                border-bottom-left-radius: 5px

            &__tip-icon
                background: #333333

            &__tip-keyboard
                box-sizing: border-box
                min-width: 80px
                height: 30px
                display: flex
                justify-content: center
                align-items: center
                color: white

            &__tip
                background: #333333
                padding: 6px
                border: 1px solid #1a1a1a
                border-bottom-left-radius: 5px
                border-top-right-radius: 5px
                line-height: 25px
                width: 93%

    .all-tips-dialog__welcome
        display: flex
        flex-direction: column
        padding: 15px 30px 0 30px
        box-sizing: border-box
        width: calc(100% - var(--sidebar-size))

    ::v-deep .dialog-button-bar
        margin-top: 0

    &__button-container
        display: flex
        padding: 20px
        border-top: var(--border-separation)
        background: var(--background-dialog-buttoncontainer)
        border-bottom-left-radius: 6px
        border-bottom-right-radius: 6px

        .be-tag
            margin-top: auto
            margin-bottom: auto



    &__tips-action-controls
        display: flex

    &__close-button
        margin-left: auto
        width: 80px

        .save-spinner
            padding-left: 5px


.all-tips-dialog__extra
    display: grid
    grid-template-columns: 1fr 1fr
    grid-template-rows: 1fr 1fr
    grid-gap: 10px


.tips-view__navigation-grid
    display: grid
    margin-inline: auto
    grid-template-columns: auto 1fr auto 1fr
    column-gap: 10px
    row-gap: 5px
    margin-block: 20px


.tips-view__shortcuts, .tips-view__welcome-message
    overflow-y: auto
    box-sizing: border-box
    padding-right: 10px

.all-tips-dialog__title
    display: flex
    align-items: center

.all-tips-dialog__expand
    font-variant: small-caps
    font-size: var(--default-text-size-half)
    margin-left: auto
    margin-right: 4ch

.all-tips-dialog[data-expanded="true"]
    --dialog-height: calc(100vh - 150px)
    --dialog-width:  clamp(800px, 100ch + var(--sidebar-size), 100vw - 100px)

@media screen and (max-width: 1000px)
    .all-tips-dialog
        ::v-deep .modal-container
            width: 80vw !important
    .tips-view__navigation-grid
        grid-template-columns: auto 1fr

</style>
