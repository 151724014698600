<template>

   <div class="phrase-search-component">

       <TextInput type="text" v-model="searchText" placeholder="Search for a phrase or phrase term" width="35%" class="search-input" @focus="onFocus"/>
       <PhraseSearchResult v-if="showSearch" :results="results" :search-text="searchText"
                           class="search-component dark-scrollbars dark-scrollbars--visible" @selected="onSelected"/>

   </div>

</template>

<script>
import PhraseSearchResult from "@/setup/brands/tree/PhraseSearchResult.vue";
import TextInput from "@/components/inputs/TextInput.vue";

export default {
    name: "PhraseSearchComponent",
    components: {TextInput, PhraseSearchResult},
    props: ['brandTree'],

    data() {
        return {
            searchText: '',
            results: undefined,
            showSearch: true,
        }
    },

    watch: {
        searchText() {
            const searchResults = this.searchForPhrase({... this.brandTree}, this.searchText);

            const resultsTree = this.createResultsTree(this.brandTree, searchResults);

            if (!resultsTree.id) {
                this.results = [];
            } else {
                this.results = [resultsTree]
            }

            if (!this.searchText.length) {
                this.$emit('closed')
            }
        }
    },

    mounted() {
        document.addEventListener("keydown", this.onKeyDown)
    },

    destroyed() {
        document.removeEventListener("keydown", this.onKeyDown)
    },

    methods: {
        /**
         * Searches a brand tree for phrases that match a given string
         * @param brand a brand tree object
         * @param searchText text to search for
         * @param acc DO NOT SUPPLY THIS VALUE - used by the method to collect brands with phrases that contain the
         * search text
         * @returns {*|*[]} an array of brands with phrases that contain the search text
         */
        searchForPhrase(brand, searchText, acc) {

            let found = acc ? acc : [];

            if (searchText.length) {
                if (brand.phrases?.filter(phrase => phrase.q.toLowerCase().includes(searchText.toLowerCase())).length) {
                    found.push(brand)
                }

                if (brand.children) {
                    brand.children?.forEach(child => {
                        this.searchForPhrase(child, searchText, found)
                    })
                }

                found.sort((a, b) => a.level - b.level)
            }
            return found
        },

        /**
         *
         * @param brand a brand tree object
         * @param brandsToKeep an array of brands with phrases that contain the search text
         * @returns {(*&{children: *[]})|{}} a filtered brand tree
         */
        createResultsTree(brand, brandsToKeep) {

            if (!brandsToKeep.length) {
                return {};
            }

            let copiedBrand;

            if (brand.children) {
                const children = [];

                brand.children.forEach(child => {
                    if (brandsToKeep.find(keptBrand => keptBrand.id === child.id || keptBrand?.parent?.id === child.id)) {
                        children.push(this.createResultsTree(child, brandsToKeep))
                    }
                })

                // Creates a deep copy of brand and shallow copy of children, which is why we can expand/collapse
                // children but not the parent. Hack to toggle parent is copiedBrand.isParent = true and toggle child's
                // parent.showPhrases in PhraseSearchResult
                copiedBrand = {...brand, children: children}
                copiedBrand.isParent = true;
            } else {
                copiedBrand = brand
            }

            return copiedBrand
        },

        onKeyDown(ev) {
            if (ev.keyCode === 27) {
                this.results = undefined
                this.searchText = '';
            }
        },

        onFocus() {
            this.showSearch = true;
        },

        onSelected() {
            this.showSearch = false
        }
    }
}
</script>

<style scoped lang="sass">

.phrase-search-component
    &:focus-within
        .search-component
            display: unset

.search-input
    ::v-deep .controls input
        padding: 13px
        margin-bottom: 0

.search-component
    width: 400px
    border-radius: 6px
    background-color: #333
    position: absolute
    z-index: 1031
    max-height: 350px
    box-shadow: var(--be-picker-ul-blur-box-shadow)
    display: none
    overflow: auto

    &:before
        position: absolute
        top: -6px
        left: 10px
        display: inline-block
        border-right: 6px solid transparent
        border-top: 6px solid #333
        border-left: 6px solid transparent
        content: ''
        transform: rotate(180deg)

</style>