import {deprecatedTagsStore, deprecatedUserObject} from "@/store/deprecated/Stores"
import {getDefaultTopicViewId} from "@/app/utils/Util";
import {appendFiltersReadably, getTopicsInFilter} from "@/dashboards/filter/FilterParser";
import {getPalette} from "@/app/utils/Colours";
import {appendSegmentRestrictions, isCxSolution, isRiskSolution, isTcfSolution} from "@/app/utils/Segments";
import {currentAccountCode} from "@/app/utils/Account";
import {createEqualityStatement} from "@/dashboards/filter/Generator";
import {percent, COLS_BY_ID, GROUPBY_BY_ID, DEFAULT_COLOUR} from "@/dashboards/widgets/comptable/CompTableUtils";
import {grouseGet} from "@/data/Grouse";

/**
 * Manages fetching and processing the data for a CompTable. Expands each input row into rows and columns.
 */
export default class CompTableData {

    constructor(options) {
        this.filter = options.filter
        this.dashboardModel = options.dashboardModel    // for colours
        this.sectionModel = options.sectionModel
        this.keepAllCols = options.keepAllCols
        this.topicViewId = options.topicViewId  // the topic tree we are using
        this.language = options.language
        this.hiddenRowCols = options.hiddenRowCols

        // these are the segment list id's when grouping by a segment list on row/col .. they are extracted from
        // the groupBy (e.g. segment-123) and added to the filter and used to filter rows coming back to only the
        // segment list we want
        this.colTagId = null
        this.inRows = options.rows.map(r => {
            let tagId = null
            let ans = this.extractTagIdFromGroupBy(r, id => tagId = id)
            if (tagId) ans.tagId = tagId
            return ans
        })
        this.inCols = options.cols.map(c => this.extractTagIdFromGroupBy(c, tagId => this.colTagId = tagId))

        this.rowSets = []
        this.colMap = { }
        this.cols = []

        this.doc = {
            rows: [],
            cols: [],
            min: null,
            max: null
        }
    }

    extractTagIdFromGroupBy(rc, cb) {
        let i
        if (rc.groupBy && ((i = rc.groupBy.indexOf('-')) > 0)) {
            rc = {...rc}
            cb(parseInt(rc.groupBy.substring(i + 1)))
            rc.groupBy = rc.groupBy.substring(0, i)
        }
        return rc
    }

    /**
     * Fetch the data. Rows and cols are available incrementally as calls come back in the doc object which is
     * also passed to the optional progressCallback.
     *
     * includeRowIds - a list of row IDs to include in the "non other" rows. Useful for ensuring that data in the "other" row is consistent for previous and current data (if comparing against previous time periods and limiting the amount of rows to show)
     */
    async refresh(progressCallback, includeRowIds) {
        this.progressCallback = progressCallback

        await deprecatedTagsStore.refresh(true)  // we need tags to filter segments etc

        let selectList = []
        // here we have an array of columns that have custom getData functions
        let columnsToGetData = this.inCols.map(col => COLS_BY_ID[col.type]).filter(col => col.getData).map(col => col.getData);

        // if there is a single column then it can have groupBy and is expanded into multiple columns based on the data
        this.colGroupBy = null
        if (this.inCols.length === 1 && this.inCols[0].groupBy) {
            let groupBy = this.inCols[0].groupBy
            this.colGroupBy = GROUPBY_BY_ID[groupBy]
            if (!this.colGroupBy) throw "Invalid groupBy [" + groupBy + "]"
            if (this.colGroupBy.select) this.colGroupBy.select.forEach(s => pushIfNotPresent(selectList, s))
            if (this.colGroupBy.requiresTopicView) {
                // parent topic from filter overrides the selected tree/view
                this.topicViewId = this.getParentTopicId(this.filter) || this.topicViewId || this.getTopicViewId(this.filter)
            }
        }

        // build up select list from the cols
        this.inCols.forEach(col => {
            let c = COLS_BY_ID[col.type]
            if (!c) throw "Invalid col [" + col.type + "]"
            c.select.forEach(s => { if (selectList.indexOf(s) < 0) selectList.push(s)})
        })

        // possible if only column is note and query isn't valid without select
        if (!selectList.length) selectList.push("mentionCount")

        let calls = []
        this.inRows.forEach((row,i) => {
            let filter = appendFiltersReadably(this.filter, row.filter)
            let groupByList = [], tagNamespaceList = [], limit, select = [...selectList], wordsLike

            let rowGroupBy
            if (row.groupBy) {
                rowGroupBy = GROUPBY_BY_ID[row.groupBy]
                if (!rowGroupBy) throw "Invalid row groupBy [" + row.groupBy + "]"
                rowGroupBy.groupBy.forEach(g => pushIfNotPresent(groupByList, g))
                if (rowGroupBy.tagNamespace) {
                    if (Array.isArray(rowGroupBy.tagNamespace)) {
                        rowGroupBy.tagNamespace.forEach(tagNamespace => pushIfNotPresent(tagNamespaceList, tagNamespace));
                    } else {
                        pushIfNotPresent(tagNamespaceList, rowGroupBy.tagNamespace);
                    }
                }
                if (rowGroupBy.limit) {
                    // if we are not showing other values then the limit can be just the number of options we are showing
                    if (row.limit && !row.showOther) limit = row.limit
                    else limit = rowGroupBy.limit
                }
                if (rowGroupBy.select) rowGroupBy.select.forEach(s => pushIfNotPresent(select, s))
                if (rowGroupBy.wordsLike) wordsLike = rowGroupBy.wordsLike
                if (rowGroupBy.requiresTopicView) {
                    // parent topic from filter overrides the selected tree/view
                    this.topicViewId = this.getParentTopicId(this.filter) || this.topicViewId || this.getTopicViewId(this.filter)
                }
            }

            if (this.colGroupBy) {
                this.colGroupBy.groupBy.forEach(g => pushIfNotPresent(groupByList, g));
                if (this.colGroupBy.tagNamespace) {
                    if (Array.isArray(this.colGroupBy.tagNamespace)) {
                        this.colGroupBy.tagNamespace.forEach(tagNamespace => pushIfNotPresent(tagNamespaceList, tagNamespace));
                    } else {
                        pushIfNotPresent(tagNamespaceList, this.colGroupBy.tagNamespace)
                    }
                }
                let colLimit = this.colGroupBy.limit
                if (colLimit) {
                    let ic = this.inCols[0]
                    // if we are not showing other values then the limit can be just the number of options we are showing
                    if (ic.limit && !ic.showOther && !row.groupBy) colLimit = Math.min(colLimit, ic.limit)
                    limit = Math.max(limit || 0, colLimit)
                }
                if (this.colGroupBy.wordsLike) wordsLike = this.colGroupBy.wordsLike
            }

            if (this.topicViewId) filter = appendFiltersReadably(filter, "Tag IS " + this.topicViewId)
            if (row.tagId) {
                filter = appendFiltersReadably(filter, "Tag IS " + row.tagId);
                if (isCxSolution(row.tagId) || isRiskSolution(row.tagId) || isTcfSolution(row.tagId)) {
                    row.filter = appendSegmentRestrictions(row.filter); // append restrictions to row.filter for clickthrough
                    filter = appendSegmentRestrictions(filter);
                }
            }

            if (this.colTagId) {
                filter = appendFiltersReadably(filter, "Tag IS " + this.colTagId);
                if (isCxSolution(this.colTagId) || isRiskSolution(this.colTagId) || isTcfSolution(this.colTagId)) {
                    filter = appendSegmentRestrictions(filter);
                }
            }

            let params = { filter }
            params.select = select.join(",")
            if (groupByList.length) params.groupBy = groupByList.join(",")
            if (tagNamespaceList.length) params.tagNamespace = tagNamespaceList.join(",")
            if (limit) params.limit = limit
            if (wordsLike) params.wordsLike = wordsLike
            if (deprecatedUserObject.debugMode) params.debug = true

            // totals for topics need to be fetched with a separate call as some mentions may not be tagged with
            // the topic tree or view in used (topics can be in multiple trees)
            let needsTotals = this.colGroupBy && this.colGroupBy.requiresTopicView || rowGroupBy && rowGroupBy.requiresTopicView
            let rowData, totalRowData

            let process = () => {
                this.doc.busy--
                if (rowData && totalRowData || !needsTotals) {
                    if (needsTotals) rowData = rowData.concat(totalRowData)
                    this.rowSets[i] = this.processRowData(row, filter, rowData, includeRowIds)
                    this.updateDoc()
                }
            }

            let getExtraColumnData = async (rowData, params) => {
                const extraColumnDataCalls = [];
                for (const colGetData of columnsToGetData) {
                    let sortCol = this.inCols.at(0);
                    let call = colGetData(params, fromGrouse, sortCol, row).then(res => {
                        if (Array.isArray(rowData)) {
                            rowData = rowData.map((obj, index) => {
                                return {...obj, ...res[index]};
                            });
                        } else {
                            rowData = {...rowData, ...res}
                        }
                    })
                    extraColumnDataCalls.push(call);
                }
                await Promise.all(extraColumnDataCalls);
                return rowData;
            }


            let getData = rowGroupBy?.getData ?? this.colGroupBy?.getData;

            const fromGrouse = this.sectionModel
                ? this.sectionModel.view.getJsonFromGrouse.bind(this.sectionModel.view)
                : grouseGet;

            // check if our row group by or column group by have their own way of getting data
            if (getData) {
                // create clone of params so we don't overwrite it's data in getData function
                let paramsClone = {...params};

                let sortCol = this.inCols.at(0);
                let call = getData(paramsClone, fromGrouse, sortCol, row, this.inCols.at(0)).then(response => {
                    rowData = response;
                    process();
                });
                calls.push(call);

                if (needsTotals) {
                    params = {...params}
                    groupByList.splice(groupByList.indexOf("tag"), 1)
                    if (groupByList.length) params.groupBy = groupByList.join(",")
                    else delete params.groupBy

                    let call = getData(params).then(response => {
                        totalRowData = response;
                        totalRowData.forEach(t => t.tag = { id: this.topicViewId });
                        process();
                    });

                    calls.push(call);
                }
            } else {
                const paramsClone = {...params}
                let call = fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", params).then(async res => {
                    res = await getExtraColumnData(res, paramsClone);
                    rowData = res;
                    // process()
                }).then(_ => process());
                calls.push(call);

                if (needsTotals) {
                    params = {...params}
                    groupByList.splice(groupByList.indexOf("tag"), 1)
                    if (groupByList.length) params.groupBy = groupByList.join(",")
                    else delete params.groupBy

                    let call = fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", params).then(res => {
                        totalRowData = res.length ? res : [res]
                        totalRowData.forEach(t => t.tag = { id: this.topicViewId })
                        process()
                    });
                    calls.push(call);
                }
            }
        })
        this.doc.busy = calls.length
        await Promise.all(calls)
        return this.doc
    }

    processRowData(row, filter, data, includeRowIds) {
        if (!data.length) data = [data]

        let rowGroupBy = row.groupBy ? GROUPBY_BY_ID[row.groupBy] : null

        // filter out data we don't want e.g. parent topics when showing only children
        if (rowGroupBy && rowGroupBy.valuePredicate !== true) {
            data = data.filter(r => rowGroupBy.valuePredicate(rowGroupBy.get(r), row.tagId || this.topicViewId))
        }
        if (this.colGroupBy && this.colGroupBy.valuePredicate !== true) {
            data = data.filter(r => this.colGroupBy.valuePredicate(this.colGroupBy.get(r), this.colTagId || this.topicViewId))
        }

        let rows
        if (this.colGroupBy) {
            // make sure we have a column for each colGroupBy value and flatten all the 'column' rows into one row
            // for each row groupBy with a value for each column
            let inCol = this.inCols[0]
            let col = COLS_BY_ID[inCol.type]
            rows = []
            let rowMap = { }
            data.forEach(r => {
                let rgv = rowGroupBy ? rowGroupBy.get(r) : 1
                let key = rgv.id || rgv
                let current = rowMap[key]
                if (!current) {
                    if (rowGroupBy) {
                        current = this.createLabel(rowGroupBy, rgv, filter)
                        current.id = row.id + "-" + current.id
                    } else {
                        current = {...row}
                        current.filter = filter
                    }
                    current.colValues = { }
                    rows.push(current)
                    rowMap[key] = current
                }
                let cv = this.colGroupBy.get(r)
                let cvId = cv.id || cv
                if ((inCol.showUnknown || !isUnknown(cv)) && !this.colMap[cvId]) {
                    let c = Object.assign({}, inCol, col, this.createLabel(this.colGroupBy, cv))
                    this.colMap[c.id] = c
                    this.cols.push(c)
                }
                current.colValues[cvId] = col.get(r)
            })
        } else {
            this.inCols.forEach(col => this.ensureCol(col))
            let otherRows = [];
            rows = [];
            data.forEach(r => {
                let ans
                if (rowGroupBy) {
                    ans = this.createLabel(rowGroupBy, rowGroupBy.get(r), filter)
                    ans.id = row.id + "-" + ans.id
                } else {
                    ans = {...row}
                }
                ans.colValues = { }
                this.cols.forEach(c => ans.colValues[c.id] = c.get(r))

                if (includeRowIds) {
                    if (includeRowIds.includes(ans.id)) {
                        rows.push(ans);
                    } else {
                        otherRows.push(ans);
                    }
                } else {
                    rows.push(ans);
                }
            })

            rows = [...rows, ...otherRows];
        }

        // find and remove the total row for topics / tags if needed .. percentages need to be relative to all the
        // mentions with the tree / segment list and not relative to the other topics
        let totalRow = null
        if (this.topicViewId || row.tagId) {
            let tid = this.topicViewId || row.tagId
            rows = rows.filter(r => {
                if (r.tagId !== tid) return true
                totalRow = r
                return false
            })
        }

        // sort rows by first column or name if needed
        if (!this.colGroupBy && rowGroupBy) {
            if (rowGroupBy.canSortByFirstCol && row.sortByFirstCol) {
                let col = this.cols[0]
                rows.sort((a,b) => {
                    let va = a.colValues[col.id]
                    let vb = b.colValues[col.id]
                    return vb.number - va.number
                })
            } else if (rowGroupBy.canSortByName && row.sortByName) {
                rows.sort((a,b) => a.name.localeCompare(b.name))
            }
        }

        // cut off rows if over limit and optionally create "other" row
        if (rowGroupBy && row.limit && rows.length > row.limit) {
            if (row.showOther) {
                let colValues = { }    // maps col id to value
                let other = { id: row.id + "-other", name: this.getOtherName(), colValues: colValues }

                this.cols.forEach(c => {
                    let tot = 0
                    let mentionCounts = 0;

                    for (let i = row.limit - 1; i < rows.length; i++) {
                        let v = rows[i].colValues[c.id]

                        if (v) {
                            // if the column is a percentage column, we can't only sum the "number" values.
                            // We have to some the mention counts and calculate the average percentage of the "other" values
                            if (c.isPercentage && typeof v.count === "number" && typeof v.total === "number") {
                                mentionCounts += v.count;
                                tot += v.total;
                            } else if (typeof v.number === "number") {
                                tot += v.number
                            }
                        }
                    }

                    if (c.isPercentage) {
                        let averagePercent = percent(mentionCounts, tot);
                        colValues[c.id] = { number: averagePercent }

                        // for sentiment/negative sentiment columns, we need to append "pos" and "neg" values for bar width calculations
                        if (c.type === "sentiment" || c.type === "netSentiment") {
                            colValues[c.id].pos = averagePercent >= 0 ? averagePercent : 0;
                            colValues[c.id].neg = averagePercent < 0 ? averagePercent : 0;
                        }
                    } else {
                        colValues[c.id] = { number: tot }
                    }
                })

                other.filter = this.buildOtherFilter(row, rows);

                rows = rows.slice(0, row.limit - 1)
                rows.push(other)
            } else {
                rows = rows.slice(0, row.limit)
            }
        }

        rows.totalRow = totalRow
        return rows
    }

    createLabel(groupBy, v, filter) {
        let o = groupBy.createLabel(v, filter)
        if (this.language && o.labels) {
            let s = o.labels[this.language]
            if (s) o.name = s
        }
        return o
    }

    /**
     * Builds filter for "other" row - used for clickthrough, not for fetching data
     *
     * @param inRow - the "split into row" data
     * @param rowsData - list of row data
     * @returns {string|FilterString}
     */
    buildOtherFilter(inRow, rowsData) {
        let filter = "";
        filter = appendFiltersReadably(filter, inRow.filter);

        // click through for 'other' region and cities is broken at the moment
        if (inRow.groupBy === "region" || inRow.groupBy === "cities") return "";
        let nonOtherRowData = [];

        let parentTopicId = null;

        for (let i = 0; i < inRow.limit - 1; i++) {
            // rows[i].id = "rowId-dataId", we want the dataId here for the 'points' array (nonOtherRowData in our case)
            let rowId = rowsData[i].id;
            let dataPoint = rowId.substring(rowId.indexOf('-') + 1, rowId.length);

            dataPoint = dataPoint === "un" ? "UNKNOWN" : dataPoint;

            // if we are splitting the row by parent topics, we need to include the parent topic in the filter
            if ((inRow.groupBy === "parentTopic" || inRow.groupBy === "topic") && this.topicViewId) {
                filter = appendFiltersReadably(filter, `Tag IS ${this.topicViewId}`);
            }

            nonOtherRowData.push(dataPoint);
        }

        if (inRow.tagId) {
            filter = appendFiltersReadably(filter, `Tag IS ${inRow.tagId}`);
        }

        // negation list for filter, i.e. [topic isnt x, topic isnt y]
        let filterNegations = nonOtherRowData.map(nonOtherRowDataPoint => {
            return createEqualityStatement(inRow.groupBy, nonOtherRowDataPoint,
                "Others", {
                    negate: true
                })
        });

        filterNegations = filterNegations.join(" and ");

        return appendFiltersReadably(filterNegations, filter);
    }

    /**
     * Collect all the rows and cols we have so far into our doc in correct order.
     */
    updateDoc() {
        let rows = [], totalRow
        this.rowSets.forEach(rs => {
            rows = rows.concat(rs)
            totalRow = totalRow || rs.totalRow
        })

        // calc row and col min, max and totals
        this.cols.forEach(c => {
            c.min = 0
            c.max = 0
            c.maxSort = 0
            c.total = 0
        })
        rows.forEach(row => {
            row.min = 0
            row.max = 0
            row.maxSort = 0
            row.total = 0
            let colValues = row.colValues
            this.cols.forEach(c => {
                let cv = colValues[c.id]
                if (cv) {
                    let v = cv.number
                    if (typeof v === "number") {
                        // when we calculate the % value for a particular column,
                        // we accumulate the totals of all values for that column in every row.
                        // in some instances, we don't want a particular row's column value to be considered in this % calculation.
                        // This logic applies for instances where we split rows and do not compare.
                        if (!row.ignoreForTotal) {
                            c.min = Math.min(c.min, cv.neg || v)
                            c.max = Math.max(c.max, cv.pos || v)
                            c.maxSort = Math.max(c.maxSort, cv.sortNumber)
                            c.total += v;
                        }

                        // This follows the same logic as above, but for when we compare.
                        if (!c.ignoreForTotal) {
                            row.min = Math.min(row.min, cv.neg || v)
                            row.max = Math.max(row.max, cv.pos || v)
                            row.maxSort = Math.max(row.maxSort, cv.sortNumber)
                            row.total += v;
                        }
                    }
                }
            })
        })

        // if we have a total row then use that for the col totals (e.g. for topics as % of mentions with the tree)
        if (totalRow) {
            let colValues = totalRow.colValues
            this.cols.forEach(c => {
                let cv = colValues[c.id]
                if (cv && cv.number) c.total = cv.number
            })
        }

        // if we have a total column then use that for the row totals (e.g. for topics as % of mentions with the tree)
        // and remove it from the cols
        let cols = this.cols
        if (this.topicViewId && this.colGroupBy || this.colTagId) {
            let tid = this.colTagId || this.topicViewId
            let i = cols.findIndex(c => c.tagId === tid)
            if (i >= 0) {
                cols = [...cols]
                let totalCol = cols[i]
                cols.splice(i, 1)
                rows.forEach(r => {
                    let cv = r.colValues[totalCol.id]
                    if (cv && cv.number) r.total = cv.number
                })
            }
        }

        let gbc = this.inCols[0]
        if (this.colGroupBy && gbc.limit && this.cols.length > gbc.limit) {
            cols = [...cols]
            cols.sort((a,b) => b.maxSort - a.maxSort)
            if (gbc.showOther && !cols[0].noPercentageOption) {
                let other = Object.assign({}, gbc, COLS_BY_ID[gbc.type], { id: "other", name: this.getOtherName() })
                rows.forEach(row => {
                    let colValues = row.colValues
                    let tot = 0
                    for (let i = gbc.limit - 1; i < cols.length; i++) {
                        let v = colValues[cols[i].id]
                        if (v && typeof v.number === "number") tot += v.number
                    }
                    colValues[other.id] = { number: tot }
                    if (tot > row.max) row.max = tot
                })
                if (!this.keepAllCols) cols = cols.slice(0, gbc.limit - 1)
                cols.push(other)
            } else {
                if (!this.keepAllCols) cols = cols.slice(0, gbc.limit)
            }
        }

        cols.forEach(c => {
            c.hasPrev = false
            if (c.isPercentage) c.showPercentage = true
            else if (c.noPercentageOption) c.showPercentage = false
        })

        // calculate percentages for all cell values
        rows.forEach(row => {
            let colValues = row.colValues
            cols.forEach(col => {
                let cv = colValues[col.id]
                if (!cv) return
                if (col.isPercentage) {
                    cv.percentage = cv.number
                    cv.posPercentage = cv.pos
                    if (typeof cv.neg === "number") cv.negPercentage = Math.abs(cv.neg)
                } else {
                    let tot = col.groupBy ? row.total : col.total
                    if (tot) {
                        cv.percentage = cv.number * 100 / tot
                        cv.posPercentage = cv.pos * 100 / tot
                        if (typeof cv.neg === "number") cv.negPercentage = Math.abs(cv.neg) * 100 / tot
                    }
                }
            })
        })

        this.doc.cols = cols
        this.doc.rows = rows

        // calc max percentage for each column for sizing bars within that column
        // hidden rows and cols are excluded so the bars on the chart are sized relative to the largest visible %
        let doc = this.doc
        this.doc.calcMaxPercentages = function(hiddenRowCols) {
            let hidden = { }
            if (hiddenRowCols) hiddenRowCols.forEach(k => hidden[k] = true)
            let docMaxPercentage = 0
            doc.cols.forEach(col => {
                let maxPercentage = 0, maxNegPercentage = 0, maxPosPercentage = 0
                if (!hidden[col.id]) {
                    doc.rows.forEach(row => {
                        if (hidden[row.id]) return
                        let cv = row.colValues[col.id]
                        if (cv) {
                            if (cv.posPercentage) {
                                maxPercentage = Math.max(maxPercentage, cv.posPercentage)
                                maxPosPercentage = Math.max(maxPosPercentage, cv.posPercentage)
                            }
                            if (cv.negPercentage) {
                                maxPercentage = Math.max(maxPercentage, cv.negPercentage)
                                maxNegPercentage = Math.max(maxNegPercentage, cv.negPercentage)
                            }
                            if (cv.percentage) maxPercentage = Math.max(maxPercentage, cv.percentage)
                        }
                    })
                }
                col.maxPercentage = maxPercentage
                col.maxNegPercentage = maxNegPercentage // used to see if any row in the col has a neg percentage
                col.maxPosPercentage = maxPosPercentage // used to see if any row in the col has a pos percentage
                docMaxPercentage = Math.max(docMaxPercentage, maxPercentage)
            })
            doc.maxPercentage = docMaxPercentage
        }
        doc.calcMaxPercentages(this.hiddenRowCols)

        // set fallback colour on each col
        cols.forEach(c => c.defaultColour = getPalette(c['colour-palette'] ? c : DEFAULT_COLOUR, this.dashboardModel.attributes)[0])
        if (this.progressCallback) this.progressCallback(this.doc)
    }

    /**
     * Make sure we have a column in colMap and return it.
     */
    ensureCol(col) {
        let c = this.colMap[col.id]
        if (!c) {
            c = COLS_BY_ID[col.type]
            if (!c) throw "Invalid col [" + col.type + "]"
            c = Object.assign({}, c)
            Object.keys(col).forEach(key => {
                let v = col[key]
                if (v) c[key] = v
            })
            this.colMap[c.id] = c
            this.cols.push(c)
        }
        return c
    }

    getTopicViewId(filter) {
        if (this.topicViewId) return this.topicViewId
        let id = getDefaultTopicViewId(filter)
        return id ? parseInt(id) : null
    }

    /**
     * If the filter includes a single parent topic then return its id. We use this as the topic tree id so
     * volumes etc. for the child topics are a percentage of the parent topic from the filter.
     */
    getParentTopicId(filter) {
        let parentTopicId = null
        getTopicsInFilter(filter).include.forEach(id => {
            let t = deprecatedTagsStore.byId[id]
            if (t && t.children && t.children.length) {
                if (parentTopicId) return null  // more than one parent
                parentTopicId = t.id
            }
        })
        return parentTopicId
    }

    getOtherName() {
        if (this.language) {
            switch (this.language) {
                case 'ar':  return 'أخرى'
                case 'es':  return 'Otros'
                case 'de':  return 'Andere'
                case 'af':  return 'Ander'
                case 'fr':  return 'Autres'
                case 'pl':  return 'Inni'
                case 'pt':  return 'Outros'
            }
        }
        return 'Others'
    }
}

function isUnknown(cv) {
    return cv.id === "un" || cv.flag === "NONE_OF_THE_ABOVE"
}

function pushIfNotPresent(a, v) {
    if (!a) return [v]
    if (a.indexOf(v) < 0) a.push(v)
    return a
}

/**
 * Include values from the previous time period and/or filter into the document for the current time period so
 * deltas can be visualized
 */
export function includePreviousValues(current, prev) {
    let prevRowsById = { }
    prev.rows.forEach(r => prevRowsById[r.id] = r )
    current.rows.forEach(row => {
        let prevRow = prevRowsById[row.id]
        current.cols.forEach(c => {
            if (!c.showDelta) return
            let v = row.colValues[c.id]
            if (!v) return
            let pv = prevRow ? prevRow.colValues[c.id] : null
            if (!pv) pv = { number: 0, percentage: 0 }
            v.prev = pv
            c.hasPrev = true
            // todo what about cols that are no longer present?
        })
    })
}
