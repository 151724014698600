<template>
    <dialog-box class="create-ticket-dialog"
                @close="closeNoEscape($event)"
                title="Create an engage ticket from this mention"
                stop-mousedown
                width="400px"
                modal>
        <div class="create-ticket-body">
            <div>
                Select a queue
            </div>
            <drop-down-input class="create-ticket-select-tag" v-model="val"
                             :optional="optional"
                             :options="this.getTicketTagDropDownOptions"
                             :stop-propagation="true"
                             placeholder="Start typing the name of the queue"/>

            <div v-if="isCreatingTicket">
                Creating ticket from mention
                <span>
                    <spinner-component class="create-ticket-spinner" :size="14"></spinner-component>
                </span>
            </div>
            <div v-else-if="hasCreatedTicket">
                Successfully created ticket from mention
                <span>
                    <animated-check class="create-ticket-spinner" :size-px="14" :animated="true"></animated-check>
                </span>
            </div>
            <div v-else-if="error">
                <span class="text-warning">
                    <i class="symbol-warning"></i>
                    There was an error creating a ticket from this mention
                </span>
            </div>

        </div>
        <template #buttons>
            <section class="create-ticket-dialog__buttons">
                <be-button :disabled="isCreatingTicket" link @click="close">Cancel</be-button>
                <be-button :disabled="isCreatingTicket || hasCreatedTicket" primary @click="createTicket()">Create</be-button>
            </section>
        </template>
    </dialog-box>

</template>
<script>
import DialogBox from "@/components/DialogBox.vue";
import {mapState} from "vuex";
import VuexStore from "@/store/vuex/VuexStore";
import DropDownInput from "@/components/inputs/DropDownInput.vue";
import BeButton from "@/components/buttons/BeButton.vue";
import {beef} from "@/store/Services";
import {notifyUser, notifyUserOfError} from "@/app/framework/notifications/Notifications";
import {currentAccountCode} from "@/app/utils/Account";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import AnimatedCheck from "@/components/animated-icons/AnimatedCheck.vue";

export default {
    name: "TicketTagPickerDialog",
    components: {AnimatedCheck, SpinnerComponent, BeButton, DropDownInput, DialogBox},
    store: VuexStore,
    props: {
        mentionId: String
    },
    computed: {
        ...mapState(['engageTicketTags']),
        getTicketTagDropDownOptions() {
            return this.engageTicketTags
                .filter(it => it.isMutuallyExclusive && it.active)
        }
    },
    data: function () {
        return {
            val: null,
            optional: true,
            isCreatingTicket: false,
            hasCreatedTicket: false,
            error: null
        }
    },
    methods: {
        close(){
            this.$emit('close');
        },

        closeNoEscape(event) {
            if (event.key === 'Escape') return;
            this.$emit('close');
        },

        async createTicket() {
            this.isCreatingTicket = true
            notifyUser("Creating engage ticket from mention")
            try {
                this.isCreatingTicket = true;
                let response = await beef.post(`/api/engage/accounts/${currentAccountCode()}/mentions/${this.mentionId}?${this.val != null ? "ticketTagId=" + this.val : "" }`, { params: {} });
                if (response.status === 200 || response.status === 201){
                    notifyUser("Successfully created engage ticket from mention");
                    this.$emit("ticket-created", response.data.ticketId + "")
                    this.hasCreatedTicket = true;
                    setTimeout(() => {
                        this.hasCreatedTicket = false;
                        this.close();
                    }, 3000);
                }
            } catch (err) {
                notifyUserOfError("Error in creating engage ticket from mention: " + err);
                this.error = err;
            } finally {
                this.isCreatingTicket = false;
            }
        }
    }
}
</script>

<style scoped lang="sass">
.create-ticket-dialog__buttons
    display: flex
    flex-direction: row
    justify-content: flex-end
    padding: 5px
    background-color: var(--background-dialog-buttoncontainer)

.create-ticket-dialog
    ::v-deep .dialog-body
        padding: 0
        margin: 0
        background: var(--body-background-colour)

.create-ticket-body
    padding: 5px

.create-ticket-select-tag
    width: 90%

.create-ticket-spinner
    vertical-align: middle

</style>