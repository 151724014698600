<template>
    <section>
        <sidebar-component class="setup-panel__sidebar dark-scrollbars">

            <sidebar-brands :brands="visibleBrands" :disabled="brandsDisabled" @select="loadBrand($event)" :active-brand-id="activeBrandId"/>
            <section v-if="loading && !visibleBrands.length">
                <sidebar-header><h4>Own Brands</h4></sidebar-header>
                <sidebar-menu-item class="setup-panel__loading-brands animated fadeIn delay-400">
                    <spinner-component :size="16" class="setup-panel__spinner"/> Fetching brands
                </sidebar-menu-item>
            </section>

            <setup-sidebar-no-brands v-if="!loading && !rootBrands.length" @click="addBrand" class="animated fadeIn"/>


            <sidebar-spacer/>
            <sidebar-action v-if="canAddBrand" @click="addBrand" :disabled="creatingBrand">
                <i class="icon-plus"></i> Add a brand
            </sidebar-action>
            <sidebar-action v-if="canAddBrand && !showDeleted" @click="showDeleted = true" :disabled="creatingBrand">
                <i class="icon-plus"></i> Show deleted brands
            </sidebar-action>
            <sidebar-action v-if="canAddBrand && showDeleted" @click="showDeleted = false" :disabled="creatingBrand">
                <i class="icon-plus"></i> Hide deleted brands
            </sidebar-action>
            <sidebar-action v-if="canImportBrand" @click="importBrand" :disabled="creatingBrand">
                <i class="icon-plus"></i> Import a brand
            </sidebar-action>
            <sidebar-action v-if="canEditJson" @click="editJson" :disabled="creatingBrand">
                <i class="symbol-edit"></i> Edit brand JSON
            </sidebar-action>
            <sidebar-spacer/>

            <sidebar-menu>
                <sidebar-header>
                    <h4>Settings</h4>
                </sidebar-header>
                <sidebar-menu-item ref="health"
                                   data-heading="Account Health"
                                   data-symbol="symbol-account-health"
                                   data-title="See what is changing your data accuracy, who is using your account, and other metrics that make up your account's health"
                                   :active="activePage === 'health'"
                                   @mouseenter="showTooltip($event)"
                                   @click="activePage = 'health'">
                    <a :href="`/${account.code}/setup/health`" @click.prevent><i class="symbol-account-health"></i> Account Health</a>
                </sidebar-menu-item>
                <sidebar-menu-item ref="crowd"
                                   data-heading="Crowd Verification"
                                   data-symbol="symbol-crowd"
                                   data-title="See how the DataEQ Crowd has been handling mentions from your account"
                                   :active="activePage === 'crowd'"
                                   @mouseenter="showTooltip($event)"
                                   @click="activePage = 'crowd'">
                    <a :href="`${account.code}/setup/crowd`" @click.prevent><i class="symbol-crowd"></i> Crowd Verification</a>
                </sidebar-menu-item>

                <sidebar-menu-item ref="notifications"
                                   data-heading="Emails & Notifications"
                                   data-symbol="symbol-notification"
                                   data-title="Setup emails to notify you of things that are happening in your account"
                                   :active="activePage === 'notifications'"
                                   @mouseenter="showTooltip($event)"
                                   @click="activePage = 'notifications'">
                    <a :href="`/${account.code}/setup/notifications`" @click.prevent>
                        <i class="symbol-notification"></i> Emails & Notifications
                    </a>
                </sidebar-menu-item>
                <sidebar-menu-item ref="profiles"
                                   data-heading="Profiles"
                                   data-symbol="symbol-profiles"
                                   data-title="Edit the profiles (such as Twitter and Facebook accounts) being tracked by your account"
                                   :active="activePage === 'profiles'"
                                   @mouseenter="showTooltip($event)"
                                   @click="activePage = 'profiles'">
                    <a :href="`/${account.code}/setup/profiles`" @click.prevent>
                        <i class="symbol-profiles"></i> Profiles
                    </a>
                </sidebar-menu-item>
                <sidebar-menu-item ref="rules"
                                   data-heading="Rules"
                                   data-symbol="symbol-rules"
                                   data-title="Set up rules for automatically editing and deleting new mentions picked up by your account"
                                   :active="activePage === 'rules'"
                                   @mouseenter="showTooltip($event)"
                                   @click="activePage = 'rules'">
                    <a :href="`/${account.code}/setup/rules`" @click.prevent>
                        <i class="symbol-rules"></i> Rules
                    </a>
                </sidebar-menu-item>
                <sidebar-menu-item ref="tags"
                                   data-heading="Tags & Custom Tags"
                                   data-symbol="symbol-tags"
                                   data-title="See, edit and delete the tags used in your account"
                                   :active="activePage === 'tags'"
                                   @mouseenter="showTooltip($event)"
                                   @click="activePage = 'tags'">
                    <a :href="`/${account.code}/setup/tags`" @click.prevent>
                        <i class="symbol-tags"></i> Tags
                    </a>
                </sidebar-menu-item>
                <sidebar-menu-item ref="topics"
                                   data-heading="Topics"
                                   data-symbol="symbol-topics"
                                   data-title="See the topics in your account"
                                   :active="activePage === 'topics'"
                                   @mouseenter="showTooltip($event)"
                                   @click="activePage = 'topics'">
                    <a :href="`/${account.code}/setup/topics`" @click.prevent>
                        <i class="symbol-topics"></i> Topics
                    </a>
                </sidebar-menu-item>
                <sidebar-menu-item ref="feeds"
                                   data-heading="Custom Data Sources"
                                   data-symbol="symbol-custom-data-source"
                                   data-title="Edit a list of RSS feeds for your account to receive mentions from"
                                   :active="activePage === 'feeds'"
                                   @mouseenter="showTooltip($event)"
                                   @click="activePage = 'feeds'">
                    <a :href="`/${account.code}/setup/feeds`" @click.prevent>
                        <i class="symbol-custom-data-source"></i> Custom Data Sources
                    </a>
                </sidebar-menu-item>

                <show-to-ops>
                    <sidebar-menu-item ref="import"
                                       data-heading="Import Mentions"
                                       data-symbol="symbol-import"
                                       data-ops="true"
                                       data-title="Import mentions into your account, using CSV spreadsheets"
                                       :active="activePage === 'import'"
                                       @mouseenter="showTooltip($event)"
                                       @click="activePage = 'import'">
                        <a :href="`/${account.code}/setup/import`" @click.prevent>
                            <i class="symbol-import"></i> Import Mentions
                        </a>
                    </sidebar-menu-item>
                </show-to-ops>

                <sidebar-menu-item ref="usage"
                                   data-heading="Volume Usage"
                                   data-icon="symbol-volumes"
                                   data-title="See your usage for the current month"
                                   :active="activePage === 'usage'"
                                   @mouseenter="showTooltip($event)"
                                   @click="activePage = 'usage'">
                    <a :href="`/${account.code}/setup/usage`" @click.prevent>
                        <i class="symbol-volumes"></i> Volume Usage
                    </a>
                </sidebar-menu-item>

                <show-to-mash-admin>
                    <sidebar-menu-item ref="sorting"
                                       data-heading="Sorting"
                                       data-symbol="symbol-sorter"
                                       data-staff="true"
                                       data-title="See how well DataEQ is doing at automatically figuring out if mentions are relevant to your account or should be deleted"
                                       :active="activePage === 'sorting'"
                                       @mouseenter="showTooltip($event)"
                                       @click="activePage = 'sorting'">
                        <a :href="`/${account.code}/setup/sorting`" @click.prevent>
                            <i class="symbol-sorter"></i> Sorting
                        </a>
                    </sidebar-menu-item>
                </show-to-mash-admin>
            </sidebar-menu>

            <sidebar-menu>
                <sidebar-header tooltip="Add or remove users to this DataEQ account, as well as manage teams and permissions">
                    <h4>Account Management</h4>
                </sidebar-header>


                <sidebar-menu-item ref="account"
                                   data-heading="Account Profile"
                                   data-title="See your overall account settings"
                                   :active="activePage === 'account'"
                                   @mouseenter="showTooltip($event)"
                                   @click="activePage = 'account'">
                    <a :href="`/${account.code}/setup/account`" @click.prevent>
                        Account Profile
                    </a>
                </sidebar-menu-item>
                <sidebar-menu-item ref="users"
                                   data-heading="Users"
                                   data-title="Add and remove users from this account, and control what they can see and do"
                                   :active="activePage === 'users'"
                                   @mouseenter="showTooltip($event)"
                                   @click="activePage = 'users'">
                    <a :href="`/${account.code}/setup/users`" @click.prevent>
                        Users
                    </a>
                </sidebar-menu-item>
                <sidebar-menu-item ref="teams"
                                   data-heading="Teams"
                                   data-title="Create teams of users and control who can access what particular dashboards"
                                   :active="activePage === 'teams'"
                                   @mouseenter="showTooltip($event)"
                                   @click="activePage = 'teams'">
                    <a :href="`/${account.code}/setup/teams`" @click.prevent>
                        Teams
                    </a>
                </sidebar-menu-item>
            </sidebar-menu>
        </sidebar-component>

        <section class="setup-panel__content">
            <section class="setup-panel__view"
                     ref="view">
            </section>
            <loading-message v-if="showBrandLoading" message="Fetching your brand"/>

            <setup-no-brand-content v-if="!loading && !rootBrands.length && !activePage" @click="addBrand"/>

            <keep-alive>
                <setup-health-panel v-if="activePage === 'health'"/>
            </keep-alive>
            <IfFeature feature="vueMigrationCrowdVerification">
                <KeepAlive>
                    <SetupCrowd v-if="activePage === 'crowd'"></SetupCrowd>
                </KeepAlive>
            </IfFeature>
            <keep-alive>
                <setup-profiles-panel v-if="activePage === 'profiles'"/>
            </keep-alive>
            <keep-alive>
                <setup-rules-panel v-if="activePage === 'rules'"/>
            </keep-alive>
            <keep-alive>
                <setup-notifications v-if="activePage === 'notifications'"
                                     :show-mine="id === 'mine'"
                                     :show-notification-id="id && id !== 'mine' ? parseInt(id) : null"/>
            </keep-alive>
            <keep-alive>
                <setup-tag-panel v-if="activePage === 'tags'" :show-tag-id="id ? parseInt(id) : null"/>
            </keep-alive>
            <keep-alive>
                <setup-account-profile v-if="activePage === 'account'"></setup-account-profile>
            </keep-alive>
            <keep-alive>
                <setup-teams v-if="activePage === 'teams'"/>
            </keep-alive>

            <keep-alive>
                <setup-sorter v-if="activePage === 'sorting'"/>
            </keep-alive>
            <keep-alive>
                <setup-usage v-if="activePage === 'usage'" />
            </keep-alive>
            <keep-alive>
                <setup-import-mentions v-if="activePage === 'import'" />
            </keep-alive>

            <if-feature feature="feedsVueOverhaul">
                <keep-alive>
                    <setup-feeds v-if="activePage === 'feeds'"></setup-feeds>
                </keep-alive>
            </if-feature>

            <if-feature feature="topicsVueMigration">
                <keep-alive>
                    <setup-topics v-if="activePage === 'topics'"></setup-topics>
                </keep-alive>
            </if-feature>

            <if-feature feature="vueMigrationAccountUsers">
                <keep-alive>
                    <SetupAccountUsers v-if="activePage === 'users'"/>
                </keep-alive>
            </if-feature>

        </section>
    </section>
</template>


<script>

import SidebarComponent from "@/components/sidebar/SidebarComponent";
import SidebarHeader from "@/components/sidebar/SidebarHeader";
import SidebarMenu from "@/components/sidebar/SidebarMenu";
import SidebarMenuItem from "@/components/sidebar/SidebarMenuItem";
import SidebarBrands from "@/components/sidebar/SidebarBrands";
import ShowToOps from "@/components/cards/ShowToOps";
import ShowToMashAdmin from "@/components/cards/ShowToMashAdmin";
import {getDefaultBrand} from "@/app/toplevel/explore/overview/ExploreUtilities";
import SidebarAction from "@/components/sidebar/SidebarAction";
import {showTip} from "@/app/help/tips/tips";
import {notifyWithHtml, showBusyNotification, symbolClass} from "@/app/framework/notifications/Notifications";
import {addBrandWithPayPerBrandCheck, importBrand, isAccountAdmin} from "@/app/Permissions";
import {features} from "@/app/Features";
import {mash} from "@/store/Services";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import SetupTooltip from "@/app/toplevel/setup/SetupTooltip";
import SpinnerComponent from "@/components/SpinnerComponent";
import SetupSidebarNoBrands from "@/app/toplevel/setup/SetupSidebarNoBrands";
import {showErrorDialog} from "@/app/framework/dialogs/Dialog";
import SetupNoBrandContent from "@/app/toplevel/setup/SetupNoBrandContent";
import SidebarSpacer from "@/components/sidebar/SidebarSpacer";
import LoadingMessage from "@/components/LoadingMessage";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapGetters, mapState} from "vuex";
import SetupProfilesPanel from "@/setup/profiles/SetupProfilesPanel";
import {escapeExpression} from "@/app/utils/StringUtils";
import {resetContentHeight} from "@/app/Beef";
import {logPageUsed} from "@/app/utils/UserAccessLog";
import SetupRulesPanel from "@/setup/rules/SetupRulesPanel";
import SetupSorter from "@/setup/sorter/SetupSorter";
import SetupAccountProfile from "@/settings/account-profile/SetupAccountProfile";
import IfFeature from "@/components/IfFeature.vue";
import SetupUsage from "@/setup/usage/SetupUsage.vue";
import SetupImportMentions from "@/setup/importmentions/SetupImportMentions.vue";
import SetupFeeds from "@/setup/feeds/SetupFeeds.vue";
import SetupTopics from "@/setup/topics/SetupTopics.vue";
import SetupRootBrands from "@/setup/brands/tree/SetupRootBrands.vue";
import EditRootBrand from "@/setup/brands/forms/edit-brand/EditRootBrand.vue";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import EditBrandTreeAsJsonDialog from "@/setup/brands/forms/EditBrandTreeAsJsonDialog.vue";
import ImportBrandDialog from "@/setup/brands/forms/ImportBrandDialog.vue";
import SetupCrowd from "@/setup/crowd/SetupCrowd.vue";
import SetupAccountUsers from "@/settings/users/SetupAccountUsers.vue";

let cachedBrandsWithStats = null;
let lastBrandActive = null;
let lastPageActive = null;

export default {
    store: VuexStore,

    components: {
        SetupAccountUsers,
        SetupCrowd,
        SetupTopics,
        SetupFeeds,
        IfFeature,
        SetupImportMentions,
        SetupUsage,
        SetupAccountProfile,
        SpinnerComponent,
        SetupRulesPanel,
        SetupProfilesPanel,
        SetupSorter,
        LoadingMessage,
        SidebarSpacer,
        SetupNoBrandContent,
        SetupSidebarNoBrands,
        SetupTeams: () => import("@/settings/teams/SetupTeams"),
        SetupTagPanel: () => import("@/setup/tags/SetupTagPanel"),
        SetupNotifications: () => import("@/setup/notifications/SetupNotifications"),
        SidebarAction,
        ShowToMashAdmin, ShowToOps, SidebarBrands, SidebarMenuItem, SidebarMenu, SidebarHeader, SidebarComponent,
        SetupHealthPanel: () => import("@/setup/changes/SetupHealthPanel")
    },

    props: {
        resource: String,
        id: String
    },

    data() {
        let activeBrandId = lastBrandActive;
        if (this.resource === 'brands' && this.id) activeBrandId = lastBrandActive = Number.parseInt(this.id);

        // We want to ensure that if no resource is given, we return to the last appropriate
        // page, but if a resource is given, we do not.
        let lastPage = lastPageActive;
        if (this.resource && this.resource !== 'brands') {
            lastPage = lastPageActive = this.resource;
        } else if (this.resource === 'brands') {
            lastPage = lastPageActive = null;
        }

        return {
            loading: false,
            destroyed: false,
            loadingActiveBrand: false,
            showDeleted: false,
            brandsWithStats: cachedBrandsWithStats,
            activeBrandId: activeBrandId,

            firstUrlUpdate: true,
            features: features,

            currentBrandView: null,
            activePage: lastPage,

            creatingBrand: false,
            canImportBrand: importBrand(),
            canAddBrand: addBrandWithPayPerBrandCheck(),
            canEditJson: features.editBrandJson()
        }
    },

    computed: {
        ...mapState(['account', 'user', 'rootBrands', 'deletedBrands']),
        ...mapGetters(['idToBrand']),

        brandsDisabled() {
            if (!features.vueMigrationRootBrandsPage()) {
                return !this.rootBrands.length || this.brandsWithStats === null || this.creatingBrand;
            } else {
                return !this.rootBrands.length || this.creatingBrand;
            }
        },

        showBrandLoading() {
            return this.loadingActiveBrand
        },

        visibleBrands() {
            if (!this.showDeleted) return this.rootBrands;
            return [...this.rootBrands, ...this.deletedBrands];
        }
    },

    watch: {
        activeBrandId(val) {
            lastBrandActive = val;
            if (val) this.activePage = null;
        },
        activePage(val) {
            const shouldSetup = lastPageActive !== val;
            lastPageActive = val;
            if (shouldSetup) this.setupPage();
        },

        async visibleBrands(val) {
            setTimeout(() => {
                const brand = this.idToBrand.get(this.activeBrandId);
                if (brand?.deleted) this.showDeleted = true;
            });
            this.$refs[this.activePage]?.$el?.scrollIntoView({behavior: 'smooth', block: "nearest"});
        }
    },

    created() {
        resetContentHeight();
    },

    beforeDestroy() {
        this.destroyed = true;
    },

    async mounted() {
        logPageUsed("setup-panel");

        // See if there is a current, non-brand page to display immediately.
        this.setupPage(true);
        this.loading = true;

        try {
            if (!this.activePage) this.loadingActiveBrand = true;
            // Don't refresh brands if we have them. A full refresh will come later.
            // This allows the interface to load quickly when switching between panels.
            if (!this.rootBrands.length && !this.deletedBrands.length) {
                await this.refreshBrands();
            }

            await this.refreshWorkingHours();
            await this.refreshHolidayCalendars();

            const loadInitial = async () => {
                if (this.activePage) {
                    return;
                }
                if (this.activeBrandId && this.idToBrand.has(this.activeBrandId)) {
                    await this.loadBrand(this.idToBrand.get(this.activeBrandId));
                    return;
                }
                const defaultBrand = await getDefaultBrand();
                if (defaultBrand) await this.loadBrand(defaultBrand);
            };

            if(!features.vueMigrationRootBrandsPage()) {
                // If we have previously cached data, use it before refreshing the cache.
                if (cachedBrandsWithStats) await loadInitial();

                // Refresh the cache: get old brand data for old edit brand UI. This also has stats included.
                // Once the old backbone code has been replaced, we will hopefully not need this.
                const mashResponse = await mash.get(`/rest/accounts/${this.account.code}/brands?includeStats=true&includeDeleted=true`);
                this.brandsWithStats = cachedBrandsWithStats = mashResponse?.data ?? [];
                await loadInitial();

                // Hard refresh brand data in case we are out of sync.
                this.loading = false;
                await this.refreshBrands(true);
            }

            if(features.vueMigrationRootBrandsPage()) {
                this.loading = false;
                await loadInitial();
            }

            // noinspection ES6MissingAwait
            showTip("NOTIFICATIONS");
        } finally {
            this.loading = false;
        }

    },

    methods: {
        ...mapActions(['refreshBrands']),

        ...mapActions('workingHours', ['refreshWorkingHours', 'refreshHolidayCalendars']),

        async loadBrand(brand) {
        if (!features.vueMigrationRootBrandsPage()) {
            this.loadingActiveBrand = true;
            await this.refreshBrands(); // Ensure that all brands have been loaded at this point. Can be soft refresh.
            try {
                if (this.activeBrandId === brand.id && this.$refs?.view?.innerText) return;
                if (!this.idToBrand.has(brand.id)) {
                    // Here the brand does not exist.
                    this.activeBrandId = null;
                    const b = await getDefaultBrand();
                    if (b && b.id !== brand.id) await this.loadBrand(b);
                    return;
                }

                if (brand.deleted) {
                    this.showDeleted = true;
                }

                if (this.currentBrandView) {
                    this.currentBrandView.close();
                    this.currentBrandView = null;
                }

                this.activeBrandId = brand.id;
                if (this.$refs?.view) {
                    this.$refs.view.innerHTML = '';
                }
                if (this.brandsWithStats === null) return;

                let mashBrand = this.brandsWithStats.find(b => b.id === brand.id);
                // hide sub-brands by default
                mashBrand.children = mashBrand.children?.filter(c => !c.deleted);
                mashBrand.children?.forEach(c => {
                    if (c.children) {
                        c.children = c.children?.filter(gc => !gc.deleted);
                    }
                });

                const model = new Backbone.Model();
                model.set({
                    brands: this.brandsWithStats,
                    brand: mashBrand
                });

                this.updateUrl(`brands/${brand.id}`);
                const view = this.currentBrandView = new Beef.RootBrandSetup.View({
                    model: model,
                    accountCode: this.account.code
                });
                view.render();
                this.$refs?.view?.append(view.el);

            } catch (e) {
                console.error(`Unexpected error when loading brand ${brand.id}:${brand.name}`, e)
            } finally {
                this.loadingActiveBrand = false;
            }
        }
            if (features.vueMigrationRootBrandsPage()) {
                this.loadingActiveBrand = true;
                this.updateUrl(`brands/${brand.id}`);
                this.activeBrandId = brand.id
                this.currentBrandView?.close();
                const view = this.currentBrandView = new Beef.VuejsView.View({component: SetupRootBrands, props: {selectedBrandId: brand.id}});
                view.render()
                this.$refs?.view?.replaceChildren(view.el)
                this.loadingActiveBrand = false;
            }
        },

        addBrand() {
            if (!this.canAddBrand) {
                showErrorDialog("You do not have enough permissions to add a brand to this account. Please contact support.");
                return
            }

            if (!features.vueMigrationRootBrandsPage()) {
                let busy = null;
                const model = new Beef.EditBrand.Model({ _level:0 });
                model.url = Beef.Sync.toMashUrl("accounts/" + this.account.code + "/brands");

                model.on("saveError", () => {
                    if (busy) busy.close();
                    this.creatingBrand = false;
                });

                const popup = new Beef.Popup.View({ closeOnHide:true, positions:["center"], alwaysMove:true });
                popup.setTarget(this.$el);
                const view = new Beef.EditBrand.View({model: model, cache: {},
                    onClose: ok => {
                        if (!ok) return;
                        model.set({_cid: model.cid}, {silent: true});
                        busy = showBusyNotification("Setting up your new brand");
                        this.creatingBrand = true;
                    }
                });
                view.on("close", function(){ popup.hide(); });
                popup.show(view);

                model.on("sync", async () => {
                    await this.refreshBrands(true);

                    try {
                        const mashResponse = await mash.get(`/rest/accounts/${this.account.code}/brands?includeStats=true&includeDeleted=true`);
                        const data = mashResponse.data;

                        this.brandsWithStats = data ?? [];
                        cachedBrandsWithStats = this.brandsWithStats;
                        const brand = this.idToBrand.get(model.get('id'));
                        await this.loadBrand(brand);
                        notifyWithHtml(
                            `Brand <strong>${escapeExpression(brand.shortName || brand.name)}</strong> created`,
                            null,
                            symbolClass('symbol-setup')
                        );
                        if (!brand.shortName && brand.name.indexOf('(') >= 0 && this.user.admin) {
                            setTimeout(() => {
                                notifyWithHtml(
                                    `Please give <strong>${escapeExpression(brand.shortName || brand.name)}</strong> a client facing name`,
                                    null,
                                    '<i class="symbol-setup"></i>'
                                );
                            }, 1000)
                        }
                    } finally {
                        if (busy) busy.close();
                        this.creatingBrand = false;
                    }
                });
            }
            if (features.vueMigrationRootBrandsPage()) {
                showDialogComponent(EditRootBrand, {brand: undefined});
            }
        },

        importBrand() {
            if (!features.vueMigrationRootBrandsPage()) {
                const popup = new Beef.Popup.View({ closeOnHide:true, positions:["center"], alwaysMove:true });
                popup.setTarget(this.$el);
                const view = Beef.ImportBrand.createView(this.account.code, async newBrandId => {
                    if (!newBrandId) return;

                    try {
                        this.creatingBrand = true;
                        await this.refreshBrands(true);

                        const mashResponse = await mash.get(`/rest/accounts/${this.account.code}/brands?includeStats=true`);
                        const data = mashResponse.data;
                        this.brandsWithStats = cachedBrandsWithStats = data ?? [];
                        const brand = this.idToBrand.get(newBrandId);
                        if (brand) {
                            await this.loadBrand(brand);
                            notifyWithHtml(
                                `Brand <strong>${escapeExpression(brand.shortName || brand.name)}</strong> has been imported into this account`,
                                null,
                                '<i class="symbol-setup"></i>'
                            );
                        }
                    } finally {
                        this.creatingBrand = false;
                    }
                });
                popup.show(view);
            }

            if (features.vueMigrationRootBrandsPage()) {
                showDialogComponent(ImportBrandDialog).$on('imported', (brandId) => {
                    this.loadBrand({id: brandId})
                })
            }
        },

        editJson() {
            if (!features.vueMigrationRootBrandsPage()) {
                const popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"] });
                popup.setTarget(this.$el);

                const view = Beef.EditBrandTreeJson.createView(this.account.code, this.brandsWithStats,
                    async () => {
                        try {
                            this.creatingBrand = true;
                            const mashResponse = await mash.get(`/rest/accounts/${this.account.code}/brands?includeStats=true`);
                            this.brandsWithStats = cachedBrandsWithStats = mashResponse.data;
                        } finally {
                            this.creatingBrand = false;
                        }
                    });
                view.on("close", () => popup.hide());
                popup.show(view);
            }

            if (features.vueMigrationRootBrandsPage()) {
                const brandsWithStats = [...this.rootBrands, ...this.deletedBrands];
                const rootBrand = {...brandsWithStats.find(b => b.id === this.activeBrandId)};

                showDialogComponent(EditBrandTreeAsJsonDialog, {brand: rootBrand})
            }
        },

        updateUrl(fragment) {
            let addToBackHistory = !this.firstUrlUpdate;
            this.$nextTick(() => {
                if (this.destroyed) return;
                // On the first visit to the setup page, we might insert multiple entries.
                // We do not want that.
                Beef.router.navigate(`/${this.account.code}/setup/${fragment}`, {trigger: false, replace: !addToBackHistory});
                this.firstUrlUpdate = false
            });
        },

        setupPage(scroll) {
            if (this.activePage) {
                this.activeBrandId = null;
                this.loadingActiveBrand = false;
                if (this.currentBrandView) {
                    this.currentBrandView.close();
                    this.$refs.view.innerText = '';
                }

                const code = this.account.code;
                let view = null;
                switch (this.activePage) {
                    case 'health':
                        this.updateUrl(`health`); break;
                    case 'crowd':
                        if (features.vueMigrationCrowdVerification()) {
                            this.activePage = 'crowd';
                            this.updateUrl(`crowd`);
                            break;
                        }
                        view = new Beef.CrowdSetup.View({model: new Backbone.Model({accountCode: code})});
                        this.updateUrl(`crowd`);
                        break;
                    case 'notifications': {
                        let url = 'notifications';
                        if (this.id) url += '/' + this.id;
                        this.updateUrl(url); break;
                    }
                    case 'online':
                         this.activePage = 'profiles';
                         this.updateUrl(`profiles`);
                        break;
                    case 'rules':
                        this.updateUrl(`rules`);
                        break;
                    case 'tags':
                        this.updateUrl(`tags`); break;
                    case 'topics':
                        if (!features.topicsVueMigration()) {
                            view = new Beef.TopicsSetup.View({model: new Backbone.Model({accountCode: code})});
                            this.updateUrl(`topics`);
                            break;
                        }
                        this.activePage = 'topics';
                        this.updateUrl(`topics`);
                        break;
                    case 'feeds':
                        if (!features.feedsVueOverhaul()) {
                            view = new Beef.FeedsSetup.View({model: new Backbone.Model({accountCode: code})});
                            this.updateUrl(`feeds`);
                            break;
                        }
                        this.activePage = 'feeds';
                        this.updateUrl(`feeds`);
                        break;
                    case 'import':
                        this.activePage = 'import';
                        this.updateUrl(`import`);
                        break;
                    case 'usage':
                        this.activePage = 'usage';
                        this.updateUrl(`usage`);
                        break;
                    case 'sorting':
                        this.activePage = 'sorting';
                        this.updateUrl(`sorting`);
                        break;
                    case 'account':
                        this.updateUrl(`account`);
                        break;
                    case 'users':
                        if (!features.vueMigrationAccountUsers()) {
                            view = new Beef.AccountUsers.View({model: new Backbone.Model({accountCode: code})});
                            this.updateUrl(`users`);
                            break;
                        }
                        this.activePage = 'users';
                        this.updateUrl(`users`);
                        break;
                    case 'teams':
                        this.updateUrl(`teams`); break;
                    case 'profiles':
                        this.updateUrl(`profiles`);
                        break;
                    default:
                        view = Beef.view404;
                }

                if (view) {
                    this.currentBrandView = view;
                    view.render();
                    this.$refs.view.append(view.el);
                }

                if (scroll) {
                    setTimeout(() => {
                        this.$refs[this.activePage]?.$el?.scrollIntoView({behavior: 'smooth', block: "nearest"});
                    }, 0)
                }
            }

        },

        showTooltip(event) {
            const target = event?.currentTarget;
            if (target) {
                const text = target.dataset.title;
                const heading = target.dataset.heading;
                const symbol = target.dataset.symbol;
                if (!text) return;

                showTooltipComponent(
                    target,
                    SetupTooltip,
                    {
                        text: text,
                        heading: heading,
                        symbol: symbol,
                        ops: !!target.dataset.ops,
                        staff: !!target.dataset.staff
                    },
                    {positions: ['right', 'top-right']}
                )
            }
        }
    }
}

</script>



<style scoped lang="sass">

.setup-panel__sidebar
    overflow-y: auto
    contain: strict
    overscroll-behavior-y: contain

.setup-panel__content
    margin-left: var(--sidebar-size)

.setup-panel__loading-brands
    display: flex
    align-items: center
    justify-content: center
    color: var(--be-colour-muted-text-dark)

    .setup-panel__spinner
        margin-right: 5px


</style>
