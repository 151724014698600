<template>
    <div class="profiles">
        <div class="row-fluid head title">
            <h1>Profiles</h1>
        </div>
        <loading-message v-if="loading" message="Fetching account's profiles…" />
        <div v-else class="profiles__container">
            <div class="profiles__options-container">
                <p>
                    Profiles are used to collect data for your account. Add your own profiles, and those of competitors,
                    to ensure they are scanned by Analyse. You will still need to add appropriate search phrases to
                    assign mentions to the correct brand(s).
                </p>

                <div class="profiles__options">
                    <search-input style="width: 40%" v-model="searchTerm" placeholder="Search for a profile...">
                    </search-input>

                    <div v-if="loadingStats" class="profiles__options-message">
                        <spinner-component :size="16" />
                        <span style="padding-left: 5px">Fetching statistics…</span>
                    </div>

                    <popup-menu
                        ref="addProfile"
                        fixed
                        left
                        style="margin-left: auto"
                        :arrow-pointer="{ show: true, left: false }"
                    >
                        <template #activator>
                            <be-button primary v-if="canEditProfiles">
                                <i class="icon-plus-circle"></i> Add Profile
                            </be-button>
                        </template>
                        <div style="width: 190px" class="short-animated fadeIn">
                            <div class="profiles__add-profile-btn" @click="addProfile(profileTypes.facebook)">
                                <div class="profiles__add-profile-logo facebook">
                                    <i class="symbol-facebook-rect"></i>
                                    <i class="symbol-instagram"></i>
                                </div>
                                Facebook Page
                            </div>
                            <div class="profiles__add-profile-btn" @click="addProfile(profileTypes.twitter)">
                                <div class="profiles__add-profile-logo twitter">
                                    <i class="symbol-twitter"></i>
                                </div>
                                X Profile
                            </div>
                            <div class="profiles__add-profile-btn" @click="addProfile(profileTypes.youtube)">
                                <div class="profiles__add-profile-logo youtube">
                                    <i class="symbol-youtube"></i>
                                </div>
                                Youtube Channel
                            </div>
                            <div class="profiles__add-profile-btn" @click="addProfile(profileTypes.linkedin)">
                                <div class="profiles__add-profile-logo linkedin">
                                    <i class="symbol-linkedin-rect"></i>
                                </div>
                                LinkedIn Company
                            </div>
                            <div class="profiles__add-profile-btn" @click="addProfile(profileTypes.tikTok)"
                                    v-if="features.tikTokProfiles()">
                                <div class="profiles__add-profile-logo tiktok">
                                    <i class="symbol-tiktok"></i>
                                </div>
                                TikTok Profile
                            </div>
                            <div class="profiles__add-profile-btn" @click="addProfile(profileTypes.tikTokAds)"
                                    v-if="features.tikTokProfiles()">
                                <div class="profiles__add-profile-logo tiktok-ads">
                                    <i class="symbol-tiktok"></i> ads
                                </div>
                                TikTok Ad Profile
                            </div>
                            <div class="profiles__add-profile-btn" @click="addProfile(profileTypes.whatsapp)">
                                <div class="profiles__add-profile-logo whatsapp">
                                    <i class="symbol-whatsapp"></i>
                                </div>
                                WhatsApp Number
                            </div>
                            <div class="profiles__add-profile-btn" @click="addProfile(profileTypes.helloPeter)">
                                <div class="profiles__add-profile-logo hellopeter">
                                    <i class="symbol-hellopeter"></i>
                                </div>
                                Hellopeter Profile
                            </div>
                            <div class="profiles__add-profile-btn" @click="addProfile(profileTypes.googleBusiness)">
                                <div class="profiles__add-profile-logo google">
                                    <i class="symbol-google-full"></i>
                                </div>
                                Google Business
                            </div>
                            <div
                                class="profiles__add-profile-btn"
                                @click="addProfile(profileTypes.trustpilot)"
                            >
                                <div class="profiles__add-profile-logo trustpilot">
                                    <i class="symbol-trustpilot"></i>
                                </div>
                                Trustpilot Site
                            </div>
                            <div
                                class="profiles__add-profile-btn"
                                v-if="user.admin"
                                @click="addProfile(profileTypes.csv)"
                            >
                                <div class="profiles__add-profile-logo csv">
                                    <i class="icon-doc-5"></i>
                                </div>
                                Upload CSV
                            </div>
                        </div>
                    </popup-menu>

                    <popup-menu
                        ref="profileOptions"
                        fixed
                        left
                        ignore-doc-click
                        :arrow-pointer="{ show: true, left: false }"
                    >
                        <template #activator>
                            <be-button @click="showOptions = true">
                                Options <i class="caret" style="margin-left: 5px;" />
                            </be-button>
                        </template>
                        <div v-if="showOptions">
                            <div class="profiles__show-options short-animated fadeIn">
                                <ul>
                                    <li class="profiles__options-header">Show Profile Types</li>
                                    <li v-for="show in showProfileTypeOptions" :key="show.label">
                                        <label class="profiles__options-label checkbox" v-if="!show.notVisible">
                                            <input type="checkbox" v-model="show.value" />
                                            {{ show.label }}
                                        </label>
                                    </li>
                                </ul>
                                <ul>
                                    <li class="profiles__options-header">Show Columns</li>
                                    <li v-for="showColumn in showColumnOptions" :key="showColumn.label">
                                        <label class="profiles__options-label checkbox">
                                            <input type="checkbox" v-model="showColumn.visible" />
                                            {{ showColumn.label }}
                                        </label>
                                    </li>
                                </ul>
                                <ul>
                                    <li class="profiles__options-header">Actions</li>
                                    <li class="profiles__options-btn" @click="downloadProfilesCsv">
                                        Download CSV
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </popup-menu>
                </div>
            </div>

            <popup-menu
                ref="profileWarningList"
                v-if="totalProfilesWithWarnings"
                fixed
                :arrow-pointer="{ show: true, left: true }"
            >
                <template #activator>
                    <label class="profile-warning-text">
                        <i class="symbol-warning"></i> {{ totalProfilesWithWarnings }}
                        {{ formatPlural(totalProfilesWithWarnings, "profile has warnings", "profiles have warnings") }}
                    </label>
                </template>
                <div
                    class="profiles__profile-warning-list dark-scrollbars dark-scrollbars--visible short-animated fadeIn"
                >
                    <div v-for="profile in filteredProfiles" :key="profile.id">
                        <div class="warning-item" v-if="warningsMap[profile.id]" @click="scrollToProfile(profile)">
                            Profile <strong>{{ profile.name }}</strong> has {{ warningsMap[profile.id].length }}
                            {{ formatPlural(warningsMap[profile.id].length, "warning") }}
                        </div>
                    </div>
                </div>
            </popup-menu>

            <div v-if="filteredProfiles && filteredProfiles.length">
                <div class="profiles__row-header">
                    <span
                        v-for="column in visibleColumns"
                        :key="column.label"
                        @click="sortProfiles(filteredProfiles, column)"
                    >
                        {{ column.label }}
                        <i
                            v-show="activeSortBy.column.label === column.label"
                            :class="activeSortBy.order === 'ASCENDING' ? 'icon-up' : 'icon-down'"
                        ></i>
                    </span>
                </div>
                <div
                    class="profiles__profiles-list dark-scrollbars dark-scrollbars--visible"
                    ref="profiles-list-container"
                >
                    <setup-profile-row
                        v-for="profile in filteredProfiles"
                        :key="profile.id"
                        :ref="'profile-row-' + profile.id"
                        :warnings="warningsMap[profile.id]"
                        :profile="profile"
                        :tags-visible="tagsVisible"
                        :relevant-visible="alwaysRelevantVisible"
                        :stats-visible="statsVisible"
                        @refresh-profile-warnings="setWarningsMap()"
                        @refreshprofiles="refreshProfiles(true)"
                    >
                    </setup-profile-row>
                </div>
            </div>
            <no-topics-message class="profiles__no-profiles-message" v-else>
                {{ noProfilesToDisplayMessage }}
            </no-topics-message>
        </div>
    </div>
</template>

<script>
import { features } from "@/app/Features";
import { editOnlineProfiles } from "@/app/Permissions";
import { showDialogComponent as showDialog } from "@/app/framework/dialogs/DialogUtilities";
import NoTopicsMessage from "@/app/toplevel/explore/overview/components/NoTopicsMessage";
import { formatPlural } from "@/app/utils/Format";
import LoadingMessage from "@/components/LoadingMessage";
import PopupMenu from "@/components/PopupMenu";
import SpinnerComponent from "@/components/SpinnerComponent";
import BeButton from "@/components/buttons/BeButton";
import SearchInput from "@/components/inputs/SearchInput";
import { profileTypes } from "@/setup/profiles/ProfileUtils";
import SetupProfileRow from "@/setup/profiles/SetupProfileRow";
import ProfileCsvUploadDialog from "@/setup/profiles/add-profile/csv/ProfileCsvUploadDialog";
import FbAddProfileDialog from "@/setup/profiles/add-profile/facebook/FbAddProfileDialog";
import GoogleAddBusinessDialog from "@/setup/profiles/add-profile/googlebusiness/GoogleAddBusinessDialog";
import HelloPeterAddProfileDialog from "@/setup/profiles/add-profile/hellopeter/HelloPeterAddProfileDialog";
import LinkedinAddProfileDialog from "@/setup/profiles/add-profile/linkedin/LinkedinAddProfileDialog";
import TrustPilotAddProfileDialog from "@/setup/profiles/add-profile/trustpilot/TrustPilotAddProfileDialog";
import TwitterAddProfileDialog from "@/setup/profiles/add-profile/twitter/TwitterAddProfileDialog";
import TikTokAddProfileDialog from "@/setup/profiles/add-profile/tiktok/TikTokAddProfileDialog";
import TikTokAdsAddProfileDialog from "@/setup/profiles/add-profile/tiktok/TikTokAdsAddProfileDialog";
import WhatsAppAddProfileDialog from "@/setup/profiles/add-profile/whatsapp/WhatsAppAddProfileDialog";
import YoutubeAddProfileDialog from "@/setup/profiles/add-profile/youtube/YoutubeAddProfileDialog";
import VuexStore from "@/store/vuex/VuexStore";
import moment from "moment";
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    name: "SetupProfilesPanel",
    components: {
        SpinnerComponent,
        NoTopicsMessage,
        PopupMenu,
        BeButton,
        SetupProfileRow,
        LoadingMessage,
        SearchInput
    },
    store: VuexStore,

    data() {
        const showProfileTypeOptions = [
            ...[
                {
                    value: true,
                    label: "X",
                    type: profileTypes.twitter
                },
                {
                    value: true,
                    label: "Facebook",
                    type: profileTypes.facebook
                },
                {
                    value: true,
                    label: "Instagram Business",
                    type: profileTypes.instagram_business
                },
                {
                    value: true,
                    label: "Instagram User",
                    type: profileTypes.instagram_user
                },
                {
                    value: true,
                    label: "LinkedIn",
                    type: profileTypes.linkedin
                },
                {
                    value: true,
                    label: "LinkedIn User",
                    type: profileTypes.linkedin_user
                },
                {
                    value: true,
                    label: "Youtube",
                    type: profileTypes.youtube
                },
                {
                    value: true,
                    label: "Telegram",
                    type: profileTypes.telegram
                },
                {
                    value: features.tikTokProfiles(),
                    label: "TikTok",
                    type: profileTypes.tikTok
                },
                {
                    value: features.tikTokProfiles(),
                    label: "TikTok Ads",
                    type: profileTypes.tikTokAds
                },
                {
                    value: true,
                    label: "Whatsapp",
                    type: profileTypes.whatsapp
                },
                {
                    value: true,
                    label: "Trustpilot",
                    type: profileTypes.trustpilot
                }
            ],
            ...[
                {
                    value: true,
                    label: "Hellopeter",
                    type: profileTypes.helloPeter
                }
            ],
            ...[
                {
                    value: true,
                    label: "Google Business",
                    type: profileTypes.googleBusiness
                }
            ],
            ...[
                {
                    value: true,
                    label: "Authorised profiles",
                    type: "AUTH"
                },
                {
                    value: true,
                    label: "Unauthorised profiles",
                    type: "UNAUTH"
                },
                {
                    value: false,
                    label: "Deleted Profiles",
                    type: "DELETED"
                }
            ]
        ];

        return {
            features,
            loading: false,
            searchTerm: "",
            loadingStats: false,
            profileStats: [],
            showOptions: false,
            canEditProfiles: editOnlineProfiles(),
            showProfileTypeOptions,
            columns: [
                {
                    label: "Profile",
                    sortField: "name",
                    visible: true,
                    sorter(lhs, rhs, reverse) {
                        const val = lhs.name.localeCompare(rhs.name);
                        return reverse ? -val : val;
                    }
                },
                {
                    label: "Auth Status",
                    sortField: "authorized",
                    dataType: "BOOLEAN",
                    visible: true
                },
                {
                    label: "Auth Expire Date",
                    sortField: "tokenExpire",
                    dataType: "DATE",
                    visible: true,
                    sorter(lhs, rhs, reverse) {
                        reverse = reverse ? 1 : -1;
                        if (lhs.tokenExpire && rhs.tokenExpire) {
                            const lhsMoment = moment(lhs.tokenExpire),
                                rhsMoment = moment(rhs.tokenExpire);
                            if (lhsMoment.isBefore(rhsMoment)) return -1 * reverse;
                            if (rhsMoment.isBefore(lhsMoment)) return 1 * reverse;
                        }

                        const lhsIsUnauthedLinked = lhs.type === "LINKEDIN_COMPANY" && !lhs.authorized;
                        const rhsIsUnauthedLinked = rhs.type === "LINKEDIN_COMPANY" && !rhs.authorized;

                        // Always have things with expire dates at the top.
                        if (lhs.tokenExpire) return -1;
                        if (rhs.tokenExpire) return 1;

                        if (lhsIsUnauthedLinked && !rhsIsUnauthedLinked) return -1;
                        if (rhsIsUnauthedLinked && !lhsIsUnauthedLinked) return 1;

                        // ---------------------------------
                        // Here the two things have the same expire date (probably none)

                        if (lhs.authorized && !rhs.authorized) return -1;
                        if (!lhs.authorized && rhs.authorized) return 1;

                        if (lhs.type < rhs.type) return -1;
                        if (rhs.type < lhs.type) return 1;

                        return lhs.name.localeCompare(rhs.name);
                    }
                },
                {
                    label: "Category",
                    sortField: "media",
                    visible: true
                },
                {
                    label: "Always Relevant",
                    sortField: "relevant",
                    dataType: "BOOLEAN",
                    visible: false,
                    canToggleVisibility: true
                },
                {
                    label: "Profile Statistics",
                    sortField: "stats",
                    visible: false,
                    canToggleVisibility: true
                },
                {
                    label: "Data Collection",
                    sortField: "brands",
                    dataType: "ARRAY",
                    visible: true
                },
                {
                    label: "Tags",
                    sortField: "tagIds",
                    dataType: "ARRAY",
                    visible: false,
                    canToggleVisibility: true
                }
            ],
            activeSortBy: {
                column: {},
                order: "ASCENDING"
            },
            warningsMap: {},
            profileTypes: profileTypes
        };
    },

    async activated() {
        try {
            this.loading = true;

            this.activeSortBy = {
                column: this.columns.find(d => d.sortField === "tokenExpire"),
                order: "ASCENDING"
            };

            await this.refreshProfiles();
            await this.refreshTags();
            await this.refreshBrands();
        } finally {
            this.loading = false;
        }
    },

    async created() {
        try {
            await this.fetchProfileStats();
            await this.setWarningsMap();
        } catch (e) {
            console.warn("Unable to fetch statistics for profiles: ", e);
        }
    },

    computed: {
        ...mapState(["user", "account"]),
        ...mapState("profiles", ["profiles", "setupWarnings"]),
        ...mapGetters("profiles", ["handleIdToProfileStats"]),

        visibleColumns() {
            return this.columns.filter(column => column.visible);
        },

        showColumnOptions() {
            return this.columns.filter(column => column.canToggleVisibility);
        },

        alwaysRelevantVisible() {
            return this.columns.find(column => column.sortField === "relevant")?.visible;
        },

        statsVisible() {
            return this.columns.find(column => column.sortField === "stats")?.visible;
        },

        tagsVisible() {
            return this.columns.find(column => column.sortField === "tagIds")?.visible;
        },

        totalProfilesWithWarnings() {
            return Object.keys(this.warningsMap).length;
        },

        filteredProfiles() {
            let st = this.searchTerm.toLowerCase();

            let profiles = this.profiles?.filter(
                profile =>
                    (profile.name.toLowerCase().includes(st) ||
                        profile.handle.toLowerCase().includes(st) ||
                        profile.handleId.toLowerCase().includes(st)) &&
                    this.isVisible(profile)
            );

            if (profiles) {
                this.sortProfiles(profiles, this.activeSortBy.column, true);
            }

            return profiles;
        },

        noProfilesToDisplayMessage() {
            if (!this.profiles?.length) {
                return `There are no profiles on this account`;
            } else {
                return `No profiles found in search results`;
            }
        }
    },

    methods: {
        ...mapActions("profiles", ["refreshProfiles", "refreshProfileStats", "checkWarnings"]),
        ...mapActions(["refreshTags", "refreshBrands"]),

        formatPlural,

        addProfile(profileType) {
            let dlg = null
            switch (profileType) {
                case profileTypes.facebook:
                    showDialog(FbAddProfileDialog, null);
                    break;
                case profileTypes.twitter:
                    showDialog(TwitterAddProfileDialog, null);
                    break;
                case profileTypes.tikTok:
                    dlg = showDialog(TikTokAddProfileDialog, null);
                    break;
                case profileTypes.tikTokAds:
                    dlg = showDialog(TikTokAdsAddProfileDialog, null);
                    break;
                case profileTypes.youtube:
                    showDialog(YoutubeAddProfileDialog, null);
                    break;
                case profileTypes.linkedin:
                    showDialog(LinkedinAddProfileDialog, null);
                    break;
                case profileTypes.whatsapp:
                    showDialog(WhatsAppAddProfileDialog, null);
                    break;
                case profileTypes.helloPeter:
                    showDialog(HelloPeterAddProfileDialog, null);
                    break;
                case profileTypes.googleBusiness:
                    showDialog(GoogleAddBusinessDialog, null);
                    break;
                case profileTypes.csv:
                    showDialog(ProfileCsvUploadDialog, null);
                    break;
                case profileTypes.trustpilot:
                    showDialog(TrustPilotAddProfileDialog, null);
                    break;
            }
            if (dlg) dlg.$on('refreshprofiles', () => this.refreshProfiles(true))
        },

        async setWarningsMap() {
            await this.checkWarnings();

            this.warningsMap = {};

            this.setupWarnings.forEach(warning => {
                let profileId = warning.object.id;
                let warningMessage = this.getWarningMessage(warning.id);

                if (warningMessage) {
                    let profileWarningObj = {
                        id: warning.id,
                        message: this.getWarningMessage(warning.id)
                    };

                    if (this.warningsMap[profileId]) {
                        this.warningsMap[profileId].push(profileWarningObj);
                    } else {
                        Vue.set(this.warningsMap, profileId, [profileWarningObj]);
                    }
                }
            });
        },

        scrollToProfile(profile) {
            if (profile.id) this.$refs["profile-row-" + profile.id][0]?.expand(true);
        },

        getWarningMessage(warningId) {
            switch (warningId) {
                case "EXPIRED_PROFILES":
                    return "Profile authorisation is expired";
                case "UNSUPPORTED_PROFILE_TYPES":
                    return "Profile type is no longer supported";
                // case "NO_CATEGORY_PROFILES": // don't allow this warning to be shown in the panel, it's not super necessary to fix
                //     return "Profile does not have a category set";
                case "NO_DM_AUTH_TWITTER_PROFILES":
                    return "Authorised X profile does not have DM's enabled";
                case "UNAUTH_LINKEDIN_PROFILES":
                    return "LinkedIn profile is not authorised - no data can be collected from it";
                case "ENTERPRISE_PROFILES_NO_PHRASES":
                    return "Enterprise profile is not phrase matched to any brands";
                case "NO_LINKED_PRESENCE_TIKTOK_PROFILES":
                    return "TikTok Ads profile does not have a linked TikTok profile"
                default:
                    return "";
            }
        },

        async fetchProfileStats() {
            try {
                this.loadingStats = true;
                await this.refreshProfileStats(true);
            } finally {
                this.loadingStats = false;
            }
        },

        isVisible(profile) {
            let showProfile = false;

            // check if profile should be shown based on profile type
            let show = this.showProfileTypeOptions.find(show => show.type === profile.type);

            if (show) {
                showProfile = show.value;
            }

            // check if profile should be shown based on auth state
            let showAuth = this.showProfileTypeOptions.find(show => show.type === "AUTH")?.value;
            let showUnauth = this.showProfileTypeOptions.find(show => show.type === "UNAUTH")?.value;

            let showDeleted = this.showProfileTypeOptions.find(show => show.type === "DELETED")?.value;

            if (!showDeleted) showProfile = show?.value && !profile.deleted;
            if (showAuth && showUnauth) return showProfile;
            if (showAuth && !showUnauth) return showProfile && profile.authorized;
            if (!showAuth && showUnauth) return showProfile && !profile.authorized;

            return false;
        },

        downloadProfilesCsv() {
            this.showOptions = false;

            // Add the UTF-8 BOM to the header so that Excel + Windows correctly interprets and opens this file.
            let csv = "\ufeffhandle,handleId,category,type,onlineProfileId,accountCode\n";
            this.filteredProfiles?.forEach(profile => {
                let profileHandle = profile.type === profileTypes.facebook ? profile.name : profile.handle;
                csv += `${profileHandle.replace(/"/g, '""')},${profile.handleId},${profile.media || ""},${
                    profile.type},${profile.id},${this.account.code}\n`;
            });

            let link = document.createElement("a");
            link.style["display"] = "none";
            link.href = window.URL.createObjectURL(new Blob([csv], { type: "text/csv" }));
            link.download = `online-profiles-${this.account.code}-${moment().format("YYYY-MM-DD-HH[h]mm")}.csv`;
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        },

        sort(a, b, order) {
            a ??= "";
            b ??= "";

            if (typeof a === "string") a = a.toLowerCase();
            if (typeof b === "string") b = b.toLowerCase();

            if (order === "ASCENDING") {
                return a > b ? 1 : -1;
            } else {
                return a < b ? 1 : -1;
            }
        },

        sortProfiles(list, column, keepOrder) {
            let order = "";
            keepOrder ??= false;
            keepOrder
                ? (order = this.activeSortBy.order)
                : (order = this.activeSortBy.order === "ASCENDING" ? "DESCENDING" : "ASCENDING");

            this.activeSortBy = {
                column: column,
                order: order
            };

            if (column.sorter) {
                return list.sort((lhs, rhs) => column.sorter(lhs, rhs, order === "ASCENDING"));
            }

            switch (column.dataType) {
                case "ARRAY":
                    list.sort((a, b) => {
                        let aValue = a[column.sortField]?.length;
                        let bValue = b[column.sortField]?.length;

                        return this.sort(aValue, bValue, order);
                    });
                    break;
                case "BOOLEAN":
                    list.sort((a, b) => {
                        let aValue = !!a[column.sortField];
                        let bValue = !!b[column.sortField];

                        return this.sort(aValue, bValue, order);
                    });
                    break;
                case "DATE":
                    list.sort((a, b) => {
                        let aValue = a[column.sortField];
                        let bValue = b[column.sortField];

                        return this.sort(bValue, aValue, order);
                    });
                    break;
                default:
                    list.sort((a, b) => {
                        // special case for "stats" because the field does not exist on the profile object
                        let aValue;
                        let bValue;
                        if (column.sortField === "stats") {
                            aValue = 0;
                            bValue = 0;
                            let aStats = this.handleIdToProfileStats(a.handleId);
                            let bStats = this.handleIdToProfileStats(b.handleId);

                            if (aStats) {
                                aValue += aStats.followerCount !== undefined ? aStats.followerCount : 0;
                                aValue += aStats.likes !== undefined ? aStats.likes : 0;
                                aValue += aStats.subscribers !== undefined ? aStats.subscribers : 0;
                            } else {
                                aValue = -1;
                            }

                            if (bStats) {
                                bValue += bStats.followerCount !== undefined ? bStats.followerCount : 0;
                                bValue += bStats.likes !== undefined ? bStats.likes : 0;
                                bValue += bStats.subscribers !== undefined ? bStats.subscribers : 0;
                            } else {
                                bValue = -1;
                            }
                        } else {
                            aValue = a[column.sortField];
                            bValue = b[column.sortField];
                        }

                        return this.sort(aValue, bValue, order);
                    });
                    break;
            }
        }
    }
};
</script>

<style scoped lang="sass">

.profiles
    --border: 1px solid #1a1a1a
    --grid-columns: minmax(10px, 2fr) minmax(10px, 1.5fr) minmax(10px, 1.5fr) minmax(10px, 200px) repeat(auto-fit, minmax(100px, 1fr))

    @media screen and (max-width: 1300px)
        --grid-columns: minmax(10px, 2.5fr) repeat(auto-fit, minmax(100px, 1fr))

    p
        color: var(--be-colour-text-dark)

    &__container
        max-width: 1300px

    &__profiles-list
        height: calc(100vh - 280px)
        position: relative
        overflow-y: auto
        overflow-x: hidden

    &__no-profiles-message
        margin-top: 100px

    &__options-container
        margin: 10px 0
        padding-bottom: 15px

    &__options
        display: flex
        margin-top: 30px
        column-gap: 20px

    &__options-message
        color: var(--be-colour-muted-text-dark)
        font-size: 1rem
        margin-left: 100px

        & ::v-deep .css-spinner
            top: 3px
            margin: 0 5px

    &__add-profile-btn
        display: flex
        align-items: center
        padding: 5px
        column-gap: 15px

        &:not(:last-of-type)
            border-bottom: 1px solid #666

        &:hover
            background: var(--background-menu-hover)
            cursor: pointer

            &:first-of-type
                border-top-left-radius: 4px
                border-top-right-radius: 4px

            &:last-of-type
                border-bottom-left-radius: 4px
                border-bottom-right-radius: 4px

    &__add-profile-logo
        display: flex
        align-items: center
        justify-content: center
        height: 32px
        width: 40px
        border-radius: 3px

        &.whatsapp
            background: var(--colour-whatsapp)

        &.google
            background: var(--colour-googlebusiness)

        &.hellopeter
            background: white
            color: #47a8e2

        &.youtube
            background: var(--colour-youtube)

        &.facebook
            background: linear-gradient(to right, #4267b2, #5963b3, #8357ad, #a6489d, #b53e92, #c13584)

        &.twitter
            background: var(--colour-twitter)

        &.linkedin
            background: var(--colour-linkedin)

        &.tiktok
            background: #000000

        &.tiktok-ads
            background: #000000
            font-size: 11px

        &.trustpilot
            background: var(--colour-trustpilot)

        &.csv
            background: var(--be-colour-dark-grey)


    &__show-options
        display: flex
        border: var(--border)

        @media screen and (max-height: 770px)
            flex-direction: row

        ul
            list-style: none
            margin: 0

            border-right: 1px solid #1a1a1a

            &:last-of-type
                border: none

        li
            line-height: 20px

        li > a
            font-weight: 400
            color: #ffffff
            cursor: pointer
            display: block
            padding: 3px 10px
            clear: both
            white-space: nowrap

            &:hover
                color: #aee15d
                text-decoration: none
                background-color: #222222

    &__options-label
        padding: 3px 10px
        margin-left: 20px

    &__options-header
        background: #222222
        padding: 3px 10px
        margin-bottom: 3px
        font-size: 12px
        text-transform: uppercase
        color: var(--clr-sidebar-header) !important
        pointer-events: none

    &__options-btn
        cursor: pointer
        padding: 5px 10px
        margin-bottom: 5px

        &:hover
            color: #aee15d
            background: #222222

    &__filter-options-btn-bar
        display: flex
        border-top: 1px solid #888
        padding: 5px 10px


    &__row-header
        display: grid
        cursor: pointer
        overflow-x: auto
        grid-template-columns: var(--grid-columns)
        position: sticky
        top: 40px
        z-index: 10
        border: none
        color: var(--be-colour-text-dark)
        text-align: center

        span
            background: #222
            box-sizing: border-box
            border-top: var(--border)
            border-right: var(--border)
            padding: 5px 10px

            &:first-child
                border-top-left-radius: 3px
                border-left: var(--border)

            &:last-child
                border-top-right-radius: 3px

    &__profile-warning-list
        max-height: 400px
        overflow-y: auto

        & div > .warning-item
            padding: 5px
            color: var(--be-colour-text-dark)
            line-height: 20px
            border-bottom: 1px solid #1a1a1a

            strong
                font-weight: bold
                font-size: calc(1em + 1px)
                color: white

            &:hover
                cursor: pointer
                background: var(--background-menu-hover)

    .profile-warning-text
        display: inline-block
        color: red
</style>
