import {
    formatNumber, simpleGetterFactory,
    simpleSetterFactory
} from "@/dashboards/widgets/fantasticchart/FantasticUtilities";

export default {
    "totalPlatformImpressions": {
        tooltipArticle: "a total",
        name: "Platform impressions",
        chartName: "Platform impressions",
        yLabel: {long: "Total Platform Impressions", short: "# Platform Impressions"},
        defaultSortOptions: {
            label: "Platform impressions",
            field: "totalPlatformImpressions",
            order: "descending"
        },
        formatY: formatNumber,
        formatLabel: formatNumber,
        getter: simpleGetterFactory("totalPlatformImpressions"),
        setter: simpleSetterFactory("totalPlatformImpressions")
    },
    "totalPlatformEngagement": {
        tooltipArticle: "a total",
        name: "Platform engagement",
        chartName: "Platform engagement",
        yLabel: {long: "Total Platform Engagement", short: "# Platform Engagement"},
        defaultSortOptions: {
            label: "Platform Engagement",
            field: "totalPlatformEngagement",
            order: "descending"
        },
        formatY: formatNumber,
        formatLabel: formatNumber,
        getter: simpleGetterFactory("totalPlatformEngagement"),
        setter: simpleSetterFactory("totalPlatformEngagement")
    },
    "totalPlatformReactions": {
        tooltipArticle: "a total",
        name: "Platform reactions",
        chartName: "Platform reactions",
        yLabel: {long: "Total Platform Reactions", short: "# Platform Reactions"},
        defaultSortOptions: {
            label: "Platform reactions",
            field: "totalPlatformReactions",
            order: "descending"
        },
        formatY: formatNumber,
        formatLabel: formatNumber,
        getter: simpleGetterFactory("totalPlatformReactions"),
        setter: simpleSetterFactory("totalPlatformReactions")
    },
    "totalPlatformReshares": {
        tooltipArticle: "a total",
        name: "Platform reshares",
        chartName: "Platform reshares",
        yLabel: {long: "Total Platform Reshares", short: "# Platform Reshares"},
        defaultSortOptions: {
            label: "Platform reshares",
            field: "totalPlatformReshares",
            order: "descending"
        },
        formatY: formatNumber,
        formatLabel: formatNumber,
        getter: simpleGetterFactory("totalPlatformReshares"),
        setter: simpleSetterFactory("totalPlatformReshares")
    },
    "totalPlatformComments": {
        tooltipArticle: "a total",
        name: "Platform comments",
        chartName: "Platform comments",
        yLabel: {long: "Total Platform Comments", short: "# Platform Comments"},
        defaultSortOptions: {
            label: "Platform comments",
            field: "totalPlatformComments",
            order: "descending"
        },
        formatY: formatNumber,
        formatLabel: formatNumber,
        getter: simpleGetterFactory("totalPlatformComments"),
        setter: simpleSetterFactory("totalPlatformComments")
    }
}