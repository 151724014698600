<template>

     <div class="picker-button-component">
         <label v-if="label">{{label}}</label>

         <div class="picker-button-group">
             <TooltipComponent v-for="option in options" :key="option.title" @click="onOptionSelect(option.title)">
                 <button @click="onOptionSelect(option.title)" :class="{'selected': isSelected(option)}">{{option.title}}</button>
                 <template #tooltip>
                     {{option.description}}
                 </template>
             </TooltipComponent>
         </div>
     </div>

</template>

<script>

import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";

export default {
    name: "PickerButtonGroup",
    components: {TooltipComponent},
    model: {
        prop: 'initialSelection',
        event: 'selected'
    },
    props: {
        options: {
            type: Array, // [{title: 'item1', description: 'this is the first title', value?: 'ITEM_1'}],
            required: true
        },
        isMultiSelect: {
            type: Boolean,
        },
        initialSelection: {
            type: Array,
        },
        label: {
            type: String,
        }
    },

    data() {
        return {
            /** @type {[String]}*/
            internalSelected: []
        }
    },

    watch: {
        initialSelection() {
            this.internalSelected = this.initialSelection;
        },
    },

    mounted() {
        this.internalSelected = this.initialSelection;
    },

    methods: {
        /**
         *
         * @param option {String}
         */
        onOptionSelect(option) {
            if (!this.options.find(realOption => realOption.title === option)) {
                console.warn('Invalid option selected');
                return;
            }
            if (this.isMultiSelect) {
                if (this.internalSelected.includes(option)) {
                    this.internalSelected = this.internalSelected.filter(selectedItem => selectedItem !== option);
                } else {
                    this.internalSelected.push(option);
                }
                this.emitData();
            } else {
                this.internalSelected = [];
                this.internalSelected.push(option);
                this.emitData();
            }
        },

        isSelected(option) {
            return this.internalSelected.includes(option.title);
        },

        emitData() {
            this.$emit('selected', this.internalSelected);
        }

    }
}
</script>

<style scoped lang="sass">

.picker-button-component
    width: fit-content
    height: fit-content

.picker-button-group
    .component-tip

        button
            min-width: 50px

        &:first-of-type
            button
                border-top-left-radius: 3px
                border-bottom-left-radius: 3px

        &:nth-of-type(even)
            button
                border-left: 1px solid #8e8e8e
                border-right: 1px solid #8e8e8e

        &:last-of-type
            button
                border-top-right-radius: 3px
                border-bottom-right-radius: 3px
                border-right: none
        button
            border: none
            outline: none
            padding: 5px
            color: #ffffff
            background-color: #747474

            &:hover
                color: #aee15d

        .selected
            color: #aee15d
            background-color: #606060

</style>