var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " class=\"row-fluid head title edit-brand\" title=\"Click to edit\"";
},"3":function(container,depth0,helpers,partials,data) {
    return " class=\"row-fluid head title\"";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class=\"read-only\" style=\"padding-left: 24px\">\n        Brand is "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"archived") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":17},"end":{"line":4,"column":59}}})) != null ? stack1 : "")
    + "read-only"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"isMashAdmin",{"name":"ifallowed","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":68},"end":{"line":4,"column":266}}})) != null ? stack1 : "")
    + "\n    </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "archived and ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"importedFromAccount") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":96},"end":{"line":4,"column":252}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ", imported from <a href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"importedFromAccount") : stack1), depth0))
    + "/setup/brands/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"importedFromAccount") : stack1), depth0))
    + "</a>";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"category\">"
    + container.escapeExpression(__default(require("../../helpers/brandCategory.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"category") : stack1),"span12",{"name":"brandCategory","hash":{},"data":data,"loc":{"start":{"line":8,"column":31},"end":{"line":8,"column":72}}}))
    + "</span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"shortName") : stack1), depth0));
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0));
},"17":function(container,depth0,helpers,partials,data) {
    return "class=\"root-brand-options-container\"";
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " class=\"root-brand-description root-brand-description-float-right\">\n        <table class=\"table\">\n            <thead>\n                <tr title=\"Brand descriptions which are visible to the Crowd\">\n                    <th></th>\n                    <th>Descriptions for the Crowd</th>\n                    <th></th>\n                </tr>\n            </thead>\n\n            <tbody class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":26},"end":{"line":24,"column":127}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = __default(require("../../helpers/eachMap.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"description") : stack1),{"name":"eachMap","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":43,"column":28}}})) != null ? stack1 : "")
    + "                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":16},"end":{"line":50,"column":37}}})) != null ? stack1 : "")
    + "\n            </tbody>\n        </table>\n    </div ";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"isAccountAdmin",{"name":"ifallowed","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":48},"end":{"line":24,"column":120}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    return "edit-root-brand-description";
},"23":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <tr class=\"brand-description-row\" data-language-code=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\" title=\"Edit the description\">\n                        <td>"
    + alias2(__default(require("../../helpers/renderLanguage.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"renderLanguage","hash":{},"data":data,"loc":{"start":{"line":27,"column":28},"end":{"line":27,"column":55}}}))
    + "</td>\n                        <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</td>\n                        <td>\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"brand") : depths[1])) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":24},"end":{"line":40,"column":45}}})) != null ? stack1 : "")
    + "\n                        </td>\n                    </tr>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"isAccountAdmin",{"name":"ifallowed","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":49},"end":{"line":40,"column":38}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = __default(require("../../helpers/iffeature.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"rootBrandsVueDescription",{"name":"iffeature","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":31,"column":28},"end":{"line":39,"column":42}}})) != null ? stack1 : "")
    + "                        ";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span title=\"Remove this description\" class=\"remove-brand-description\" data-language-code=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\">\n                                    <i class=\"icon-pencil\"></i>\n                                </span>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span title=\"Remove this description\" class=\"remove-brand-description\" data-language-code=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\">\n                                    <i class=\"icon-trash-1\"></i>\n                                </span>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"isAccountAdmin",{"name":"ifallowed","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":38},"end":{"line":50,"column":30}}})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    return "\n                    <tr data-language-code=\"\" title=\"Add a new brand description\">\n                        <td><i class=\"icon-plus\"></i></td>\n                        <td>Add a description</td>\n                        <td></td>\n                    </tr>\n                ";
},"33":function(container,depth0,helpers,partials,data) {
    return " class=\"vue-root-brand-description\"></div ";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"archived\">Archived on "
    + container.escapeExpression(__default(require("../../helpers/moment.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"archived") : stack1),"MMMM Do YYYY",{"name":"moment","hash":{},"data":data,"loc":{"start":{"line":59,"column":47},"end":{"line":59,"column":87}}}))
    + ", not collecting new mentions\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canArchive") : stack1),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":16},"end":{"line":60,"column":108}}})) != null ? stack1 : "")
    + "\n            </span>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"unarchive\" data-brand=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">unarchive</a>";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.program(41, data, 0),"data":data,"loc":{"start":{"line":64,"column":18},"end":{"line":64,"column":117}}})) != null ? stack1 : "")
    + ">\n                <span class=\"description\">"
    + alias2(__default(require("../../helpers/formatConversationFeedFilter.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"mentionFilter") : stack1),{"name":"formatConversationFeedFilter","hash":{},"data":data,"loc":{"start":{"line":65,"column":42},"end":{"line":65,"column":94}}}))
    + ": </span>\n                "
    + alias2(__default(require("../../helpers/formatConversationFilter.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"mentionFilter") : stack1),{"name":"formatConversationFilter","hash":{},"data":data,"loc":{"start":{"line":66,"column":16},"end":{"line":66,"column":64}}}))
    + ".\n            </span>\n";
},"39":function(container,depth0,helpers,partials,data) {
    return "class=\"filter edit-brand\" title=\"Click to edit\"";
},"41":function(container,depth0,helpers,partials,data) {
    return " class=\"filter\"";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.program(46, data, 0),"data":data,"loc":{"start":{"line":70,"column":18},"end":{"line":70,"column":115}}})) != null ? stack1 : "")
    + ">\n                <span class=\"description\">Social networks: </span>\n                Only search "
    + container.escapeExpression(__default(require("../../helpers/formatSocialNetworksList.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"socialNetworks") : stack1),{"name":"formatSocialNetworksList","hash":{},"data":data,"loc":{"start":{"line":72,"column":28},"end":{"line":72,"column":77}}}))
    + ".\n            </span>\n";
},"44":function(container,depth0,helpers,partials,data) {
    return "class=\"feeds edit-brand\" title=\"Click to edit\"";
},"46":function(container,depth0,helpers,partials,data) {
    return " class=\"feeds\"";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.program(46, data, 0),"data":data,"loc":{"start":{"line":76,"column":18},"end":{"line":76,"column":115}}})) != null ? stack1 : "")
    + ">\n                <span class=\"description\">Feeds: </span>\n                Only search "
    + container.escapeExpression(__default(require("../../helpers/formatFeedsList.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"feeds") : stack1),{"name":"formatFeedsList","hash":{},"data":data,"loc":{"start":{"line":78,"column":28},"end":{"line":78,"column":59}}}))
    + ".\n            </span>\n";
},"50":function(container,depth0,helpers,partials,data) {
    return "edit";
},"52":function(container,depth0,helpers,partials,data) {
    return "view";
},"54":function(container,depth0,helpers,partials,data) {
    return "(none)";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"index")),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":16},"end":{"line":86,"column":39}}})) != null ? stack1 : "")
    + "\n                <span title=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"unless","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":46},"end":{"line":87,"column":91}}})) != null ? stack1 : "")
    + ">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</span>\n";
},"57":function(container,depth0,helpers,partials,data) {
    return ", ";
},"59":function(container,depth0,helpers,partials,data) {
    return "class=\"inactive\"";
},"61":function(container,depth0,helpers,partials,data) {
    return "class=\"feeds edit-segments editable\" title=\"Click to edit\"";
},"63":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span title=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "\">\n"
    + ((stack1 = __default(require("../../helpers/ifMashAdmin.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ifMashAdmin","hash":{},"fn":container.program(64, data, 0),"inverse":container.program(72, data, 0),"data":data,"loc":{"start":{"line":96,"column":20},"end":{"line":120,"column":36}}})) != null ? stack1 : "")
    + "                </span>\n";
},"64":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"index")),{"name":"if","hash":{},"fn":container.program(65, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":24},"end":{"line":97,"column":48}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"if","hash":{},"fn":container.program(67, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":24},"end":{"line":106,"column":31}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inactive") : depth0),{"name":"if","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":24},"end":{"line":116,"column":31}}})) != null ? stack1 : "");
},"65":function(container,depth0,helpers,partials,data) {
    return " | ";
},"67":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span><i title=\"Active "
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + " segments\" class=\"icon-check-1\"></i> "
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + " (active) » </span>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"each","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":28},"end":{"line":105,"column":37}}})) != null ? stack1 : "");
},"68":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"index")),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":32},"end":{"line":101,"column":55}}})) != null ? stack1 : "")
    + "\n                                <span title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "\">\n                                    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"subtitle") : depth0), depth0))
    + "\n                                </span>\n";
},"70":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span><i title=\"Inactive "
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + " segments\" class=\"icon-cancel-circle\"></i> "
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + " (inactive) » </span>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"inactive") : depth0),{"name":"each","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":28},"end":{"line":115,"column":37}}})) != null ? stack1 : "");
},"72":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"if","hash":{},"fn":container.program(73, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":118,"column":24},"end":{"line":118,"column":107}}})) != null ? stack1 : "")
    + "\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inactive") : depth0),{"name":"if","hash":{},"fn":container.program(75, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":119,"column":24},"end":{"line":119,"column":117}}})) != null ? stack1 : "")
    + "\n";
},"73":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <span><i class=\"icon-check-1\"></i> "
    + container.escapeExpression(container.lambda((data && lookupProperty(data,"key")), depth0))
    + " (active)</span> ";
},"75":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <span> <i class=\"icon-cancel-circle\"></i> "
    + container.escapeExpression(container.lambda((data && lookupProperty(data,"key")), depth0))
    + " (inactive)</span> ";
},"77":function(container,depth0,helpers,partials,data) {
    return "class=\"feeds edit-profiles editable edit-brand\" title=\"Click to edit\"";
},"79":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"isAccountAdmin",{"name":"ifallowed","hash":{},"fn":container.program(80, data, 0),"inverse":container.program(82, data, 0),"data":data,"loc":{"start":{"line":130,"column":35},"end":{"line":130,"column":167}}})) != null ? stack1 : "")
    + " ";
},"80":function(container,depth0,helpers,partials,data) {
    return "class=\"feeds edit-working-hours editable\" title=\"Click to edit\" ";
},"82":function(container,depth0,helpers,partials,data) {
    return " class=\"feeds\" ";
},"84":function(container,depth0,helpers,partials,data) {
    return "\n            class=\"feeds edit-colour editable\" title=\"Click to edit the colour that the brand appears as in metrics and reports\"\n";
},"86":function(container,depth0,helpers,partials,data) {
    return "            class=\"feeds\" title=\"The colour the brand appears as in metrics and reports\"\n        ";
},"88":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"importedByAccounts") : stack1),{"name":"if","hash":{},"fn":container.program(89, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":151,"column":35},"end":{"line":161,"column":11}}})) != null ? stack1 : "");
},"89":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <div class=\"row-fluid\">\n            <div class=\"span-12\">\n                Brand is imported by\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"importedByAccounts") : stack1),{"name":"each","hash":{},"fn":container.program(90, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":155,"column":16},"end":{"line":158,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    ";
},"90":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"index")),{"name":"if","hash":{},"fn":container.program(57, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":156,"column":20},"end":{"line":156,"column":43}}})) != null ? stack1 : "")
    + "\n                    <span><a href=\"/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "/setup/brands/"
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"brand") : depths[1])) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "</a> "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n";
},"92":function(container,depth0,helpers,partials,data) {
    return "<span class=\"pull-right\">Add a sub-brand</span>";
},"94":function(container,depth0,helpers,partials,data) {
    return "                <td class=\"edit-brand\">Edit</td>\n";
},"96":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./BrandTree.handlebars"),depth0,{"name":"./BrandTree","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"98":function(container,depth0,helpers,partials,data) {
    return "4";
},"100":function(container,depth0,helpers,partials,data) {
    return "3";
},"102":function(container,depth0,helpers,partials,data) {
    return "checked=\"true\"";
},"104":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../helpers/iffeature.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"editBrandJson",{"name":"iffeature","hash":{},"fn":container.program(105, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":200,"column":30},"end":{"line":202,"column":22}}})) != null ? stack1 : "");
},"105":function(container,depth0,helpers,partials,data) {
    return "\n            <a class=\"btn edit-json\">Edit JSON</a>\n        ";
},"107":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"importedFromAccount") : stack1),{"name":"if","hash":{},"fn":container.program(108, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":205,"column":12},"end":{"line":210,"column":19}}})) != null ? stack1 : "");
},"108":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span style=\"margin-right: 16px\">Brand is imported from <a href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"importedFromAccount") : stack1), depth0))
    + "/setup/brands/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"importedFromAccount") : stack1), depth0))
    + "</a></span>\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"importBrand",{"name":"ifallowed","hash":{},"fn":container.program(109, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":207,"column":16},"end":{"line":209,"column":30}}})) != null ? stack1 : "");
},"109":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"btn remove-imported-brand\" title=\"Remove imported brand from this account\">Remove from this account</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEditRoot") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":1,"column":5},"end":{"line":1,"column":137}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"unless","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"category") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "    <h1>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"shortName") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":79}}})) != null ? stack1 : "")
    + "</h1>\n</div>\n\n<div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBottomMargin") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":5},"end":{"line":13,"column":72}}})) != null ? stack1 : "")
    + ">\n    <div "
    + ((stack1 = __default(require("../../helpers/ifnotfeature.js")).call(alias1,"rootBrandsVueDescription",{"name":"ifnotfeature","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":9},"end":{"line":53,"column":27}}})) != null ? stack1 : "")
    + ">\n\n    <div "
    + ((stack1 = __default(require("../../helpers/iffeature.js")).call(alias1,"rootBrandsVueDescription",{"name":"iffeature","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":9},"end":{"line":55,"column":106}}})) != null ? stack1 : "")
    + ">\n\n    <div class=\"root-brand-options\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"archived") : stack1),{"name":"if","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":8},"end":{"line":62,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"mentionFilter") : stack1),{"name":"if","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":8},"end":{"line":68,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"socialNetworks") : stack1),{"name":"if","hash":{},"fn":container.program(43, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":8},"end":{"line":74,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"feeds") : stack1),{"name":"if","hash":{},"fn":container.program(48, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":8},"end":{"line":80,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"feeds edit-topic-tree editable\" title=\"Click to "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditTopicTree") : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0, blockParams, depths),"inverse":container.program(52, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":82,"column":68},"end":{"line":82,"column":116}}})) != null ? stack1 : "")
    + "\">\n            <span class=\"description\">Topic trees: </span>\n            "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"topicTrees") : stack1),{"name":"unless","hash":{},"fn":container.program(54, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":12},"end":{"line":84,"column":57}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"topicTrees") : stack1),{"name":"each","hash":{},"fn":container.program(56, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":12},"end":{"line":88,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n\n        <div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEditTopicTree") : stack1),{"name":"if","hash":{},"fn":container.program(61, data, 0, blockParams, depths),"inverse":container.program(46, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":91,"column":13},"end":{"line":91,"column":131}}})) != null ? stack1 : "")
    + ">\n            <span class=\"description\">Segments: </span>\n            "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"segmentLists") : stack1),{"name":"unless","hash":{},"fn":container.program(54, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":12},"end":{"line":93,"column":59}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"segmentMap") : depth0),{"name":"each","hash":{},"fn":container.program(63, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":12},"end":{"line":122,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n\n        <div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(77, data, 0, blockParams, depths),"inverse":container.program(46, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":125,"column":13},"end":{"line":125,"column":133}}})) != null ? stack1 : "")
    + ">\n            <span class=\"description\">Support profiles: </span>\n            <span class=\"support-profiles\"></span>\n        </div>\n\n        <div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(79, data, 0, blockParams, depths),"inverse":container.program(82, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":130,"column":13},"end":{"line":130,"column":198}}})) != null ? stack1 : "")
    + " >\n            <span class=\"description\">Working hours: </span>\n            <span class=\"working-hours\"></span>\n        </div>\n\n        <div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(77, data, 0, blockParams, depths),"inverse":container.program(46, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":135,"column":13},"end":{"line":135,"column":133}}})) != null ? stack1 : "")
    + ">\n            <span class=\"description\">Other profiles: </span>\n            <span class=\"other-profiles\"></span>\n        </div>\n\n        <div style=\"position: relative\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(84, data, 0, blockParams, depths),"inverse":container.program(86, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":140,"column":40},"end":{"line":144,"column":15}}})) != null ? stack1 : "")
    + ">\n            <span class=\"description\">Colour: </span>\n            <input type=\"text\" class=\"brand-colour\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"colour") : stack1), depth0))
    + "\">\n            <span class=\"colour-name\"></span>\n        </div>\n    </div>\n\n    "
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"isAccountAdmin",{"name":"ifallowed","hash":{},"fn":container.program(88, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":151,"column":4},"end":{"line":161,"column":25}}})) != null ? stack1 : "")
    + "\n</div>\n\n<div class=\"main\">\n    <table class=\"table table-condensed table-bordered table-hover\">\n        <thead>\n        <tr>\n            <td class=\"brand\">Brand\n                <a class=\"expand-all\" title=\"Expand/collapse tree\">All<i class=\"plus icon-down-open\"></i><i class=\"minus icon-up-open\"></i></a>\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(92, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":170,"column":16},"end":{"line":170,"column":92}}})) != null ? stack1 : "")
    + "\n            </td>\n            <td class=\"phrases\">Phrases</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(94, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":173,"column":12},"end":{"line":175,"column":19}}})) != null ? stack1 : "")
    + "            <td class=\"trash\" title=\"% of mentions sent to trash\">Trash</td>\n        </tr>\n        </thead>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"brand") : depth0),{"name":"with","hash":{},"fn":container.program(96, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":179,"column":8},"end":{"line":181,"column":17}}})) != null ? stack1 : "")
    + "        <tfoot>\n        <tr>\n            <td colspan=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(98, data, 0, blockParams, depths),"inverse":container.program(100, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":184,"column":25},"end":{"line":184,"column":64}}})) != null ? stack1 : "")
    + "\">\n                <div class=\"form-inline\">\n                    <label class=\"checkbox\">\n                        <input type=\"checkbox\" class=\"showInactive\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_showInactive") : depth0),{"name":"if","hash":{},"fn":container.program(102, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":187,"column":68},"end":{"line":187,"column":110}}})) != null ? stack1 : "")
    + "> Show inactive search phrases\n                    </label>\n                    <label class=\"checkbox\">\n                        <input type=\"checkbox\" class=\"showDeleted\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_showDeleted") : depth0),{"name":"if","hash":{},"fn":container.program(102, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":190,"column":67},"end":{"line":190,"column":108}}})) != null ? stack1 : "")
    + "> Show brands and phrases in Trash\n                    </label>\n                </div>\n            </td>\n        </tr>\n        </tfoot>\n    </table>\n\n\n    <div class=\"extras\">\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(104, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":200,"column":8},"end":{"line":202,"column":29}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"isMashAdmin",{"name":"ifallowed","hash":{},"fn":container.program(107, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":204,"column":8},"end":{"line":211,"column":22}}})) != null ? stack1 : "")
    + "    </div>\n\n</div>\n\n<div class=\"overlay\" style=\"display: none\">\n    <span class=\"spinner\"></span>\n</div>";
},"usePartial":true,"useData":true,"useDepths":true});