<template>
    <span class="name-cell-component" @click="onToggleChildren">
        <span v-if="brandChild && brandChild.subBrandsAllowed">
            <i v-if="isShowing" class="minus icon-down-dir"></i><i v-if="!isShowing" class="plus icon-right-dir"></i>
        </span>
        <template v-else>
            <i class="icon-dot"></i>
        </template>
        <span class="name">
            <template v-if="brandChild && brandChild.shortName">
                {{nameFormat(brandChild.shortName,40)}}
            </template>
             <template v-if="brandChild && !brandChild.shortName && brandChild.name">
                {{nameFormat(brandChild.name,40)}}
            </template>
             <template v-if="brandChild && brandChild.subPhraseMatching">
                 <span class="sub-phrase-matching">[partial phrase matching]</span>
            </template>
            <!-- TODO -->
            <!--                            {{#ifdebug}}-->
            <!--                                <span class="general-debug-message">({{#ifcond _level '==' 0}}🐛{{/ifcond}} id: {{id}})</span>-->
            <!--                            {{/ifdebug}}-->
        </span>

        <template v-if="brandChild && brandChild.treeDoesMentionFilter">
            <TooltipComponent>
                <span class="icon-filter"></span>
                <template #tooltip>This brand and/or its child brands and phrases have mention filters configured.</template>
            </TooltipComponent>
        </template>

        <template v-if="brandChild && brandChild.treeDoesFeedFilter">
            <TooltipComponent>
                <span class="icon-comment-empty"></span>
                <template #tooltip>This brand and/or its child brands and phrases have feed or social network filters configured.</template>
            </TooltipComponent>
        </template>

        <template v-if="brandChild && brandChild.canEdit && brandChild.subBrandsAllowed">
            <span class="add-brand" :class="{'deq-can-edit': brandChild.canEdit}" @click="showEditBrandDialog">
                <span class="icon-plus"></span>
            </span>
        </template>
    </span>
</template>

<script>
import nameFormat from "../../../../helpers/nameFormat";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import EditSubBrandDialog from "@/setup/brands/forms/edit-brand/EditSubBrandDialog.vue";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";

export default {
    name: "NameCellComponent",
    components: {TooltipComponent},
    props: ['brandChild', 'isShowing', 'searchToggles'],


    methods: {
        nameFormat,

        showEditBrandDialog(event) {
            event.preventDefault();
            event.stopPropagation();
            showDialogComponent(EditSubBrandDialog, {brand: this.brandChild, isNew: true, searchToggles: this.searchToggles})
        },

        onToggleChildren(){
            const brand = this.brandChild;
            brand.showChildren = !this.brandChild.showChildren;

            if (this.brandChild.showChildren === false) {
                brand.showPhrases = false;
            }
        },

    },
}
</script>



<style scoped lang="sass">

//.name-cell-component
//    display: contents

.add-brand
    float: right
    margin-right: 8px

</style>