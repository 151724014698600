<template>

   <div v-if="internalBrand" class="brand-tree-row-component">
       <div class="brand-tree-row" :id="internalBrand.id" :class="{'deleted': internalBrand.deleted}">
           <NameCellComponent class="brand-name-cell btr-cell" :style="{'padding-left': internalBrand.padding}"
                              :brand-child="brand" :is-showing="internalBrand.showChildren">
           </NameCellComponent>

           <PhraseCountCell :brand="internalBrand" :show-phrases="internalBrand.showPhrases" @toggle-phrases="onTogglePhrases" class="btr-cell"/>

           <EditBrandCell v-if="internalBrand.canEdit" :brand="internalBrand" class="btr-cell"/>

           <PhraseVolumeTrashCell :child="internalBrand" class="btr-cell"/>
       </div>

       <PhrasesRow v-if="internalBrand.showPhrases" :brand="internalBrand" class="btr-cell btr-phrases"/>

       <BrandTreeRow v-for="(child) in internalBrand.children" :key="child.id" v-show="brand.showChildren" :brand="child"
                     style="padding-left: 10px" :show-all="child.showChildren" class="btr-child sub-brand-container-placeholder">
       </BrandTreeRow>
   </div>

</template>

<script>
import NameCellComponent from "@/setup/brands/tree/cells/NameCellComponent.vue";
import EditBrandCell from "@/setup/brands/tree/cells/EditBrandCell.vue";
import PhraseVolumeTrashCell from "@/setup/brands/tree/cells/PhraseVolumeTrashCell.vue";
import PhrasesRow from "@/setup/brands/tree/rows/PhrasesRow.vue";
import PhraseCountCell from "@/setup/brands/tree/cells/PhraseCountCell.vue";

export default {
    name: "BrandTreeRow",
    components: {PhraseCountCell, PhrasesRow, PhraseVolumeTrashCell, EditBrandCell, NameCellComponent},
    props: ['brand', 'showAll'],

    data() {
        return {
            showPhrases: false,
            showChildren: true,
            cssClassName: `${this.brand.id}`,
            isParentHovered: false,
            isChildHovered: false,
            internalBrand: {},
        }
    },

    mounted() {
        this.cssClassName = `${this.brand.id}`;
        this.internalBrand = this.brand
    },

    watch: {

        brand() {
            this.internalBrand = {
                ...this.brand,
                showChildren: this.internalBrand.showChildren || true,
                showPhrases: this.internalBrand.showPhrases || false
            }
        }

    },

    methods: {
        onTogglePhrases(value){
            this.internalBrand.showPhrases = value
        },
    }
}
</script>

<style scoped lang="sass">

@use "../edit-brand-dialog"

.brand-tree-row-component
    display: contents

.brand-tree-row
    display: contents

    &:hover
        .btr-cell
            background-color: var(--table-row-hover-bg-colour)
            color: var(--table-row-hover-text-colour)
            cursor: pointer
        .btr-child
            .btr-cell
                background-color: unset
                color: unset
                cursor: unset

.btr-child
    .brand-tree-row
        &:hover
            .btr-cell
                background-color: var(--table-row-hover-bg-colour)
                color: var(--table-row-hover-text-colour)
                cursor: pointer
            .phrases-container-placeholder
                background-color: unset
                color: unset
                cursor: unset

.children-container
    display: contents

.btr-cell
    background-color: unset
</style>