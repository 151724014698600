<template>
    <DialogBox modal :title="`Select a new parent Brand for ${brand.shortName || brand.name}`" class="edit-brand-dialog move-brand-dialog" width="550px" @close="emitClose()">
        <div class="deq-reset move-brand-form dark-scrollbars dark-scrollbars--visible">
            <div v-if="!isLoading && tree">
                <div v-for="(activeBrand) in activeBrands" :key="activeBrand.id">
                    <MoveBrandRow :current-parent-id="submitData.parentId" :branch="activeBrand" @parent-updated="onParentUpdated"></MoveBrandRow>
                </div>
                <template v-if="!isLoading && archivedBrands.length">
                    <h6>Archived Brands</h6>
                    <div v-for="(archivedBrand) in archivedBrands" :key="archivedBrand.id">
                        <MoveBrandRow :current-parent-id="submitData.parentId" :branch="archivedBrand" @parent-updated="onParentUpdated"></MoveBrandRow>
                    </div>
                </template>
            </div>

            <LoadingMessage v-if="isLoading" :message="'Setting up your Brand Tree...'" class="edit-brand-loader" />
        </div>

        <template #buttons>
            <div class="deq-dialog-buttons edit-brand-buttons">

                <div class="left-side">
                </div>

                <div class="saving-spinner">
                    <SpinnerComponent v-if="isSaving" :size="16"/>
                </div>

                <div class="right-side">
                    <BeButton @click="emitClose" :disabled="isSaving" link>
                        <TooltipComponent>
                            <span>Cancel</span>
                            <template #tooltip>Discard changes (Esc)</template>
                        </TooltipComponent>
                    </BeButton>
                    <BeButton @click="onSave" :disabled="!isFormValid || isSaving">Ok</BeButton>
                </div>
            </div>
        </template>
    </DialogBox>
</template>

<script>
import DialogBox from "@/components/DialogBox.vue";
import {mapActions, mapState} from "vuex";
import VuexStore from "@/store/vuex/VuexStore";
import MoveBrandRow from "@/setup/brands/forms/edit-brand/move-brand/MoveBrandRow.vue";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import BeButton from "@/components/buttons/BeButton.vue";
import {mash} from "@/store/Services";
import {features} from "@/app/Features";
import LoadingMessage from "@/components/LoadingMessage.vue";
import {notifyUser} from "@/app/framework/notifications/Notifications";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";

/**
 * Allows you to select a new parent for a brand
 */
export default {
    name: "MoveBrandDialog",
    props: ['brand', 'searchToggles'],
    store: VuexStore,
    components: {TooltipComponent, LoadingMessage, BeButton, SpinnerComponent, MoveBrandRow, DialogBox},

    data() {
        return {
            tree: [],
            activeBrands: [],
            archivedBrands: [],

            submitData: {
                id: this.brand.id,
                name: this.brand.name,
                subPhraseMatching: this.brand.subPhraseMatching,
                supportProfileIds: this.brand.supportProfileIds,
                otherProfileIds: this.brand.otherProfileIds,
                parentId: this.brand.parent.id
            },
            isSaving: false,
            isFormValid: true,
            isLoading: true,
            brandTree: []

        }
    },

    computed: {
        ...mapState(['account']),
        ...mapState(['rootBrands', 'deletedBrands']),
    },

    async mounted() {
        this.isLoading = true;
        const brandsWithStats = [... this.rootBrands, ... this.deletedBrands];
        this.tree = this.buildPickerTree(brandsWithStats, this.brand.id);

        const res = await mash.get(`rest/accounts/${this.account.code}/brands/tree`);
        this.brandTree = this.processBrands(res.data, this.account.code);

        if (!this.searchToggles().showBrandsAndPhrasesInTrash) {
            this.tree?.forEach(c => {
                if (c.children) {
                    c.children = c.children?.filter(gc => !gc.deleted);
                }
            });
        }

        if (this.tree) {
            for (let i = 0; i < this.tree.length; i++) {
                const b = this.tree[i];
                (b.archived ? this.archivedBrands : this.activeBrands).push(b);
            }
        }

        this.isLoading = false;

        setTimeout(() => {
            document.getElementById('move' + this.brand.parent.id)
                .scrollIntoView({ behavior: "smooth", block: "center"});
        }, 300)
    },

    methods: {
        ...mapActions(['refreshBrands', 'updateBrand', 'addBrand']),

        emitClose() {
            this.$emit('close');
        },

        /**
         * (Logic from handlebars)
         * Builds the structure that will be used for displaying the new possible parent brands (brands and their children.)
         * Excludes the brand you want to move from the displayed brands, so that you a brand cannot be its own parent.
         * @param rootBrands
         * @param brandIdToMove
         * @returns {*[]}
         */
        // buildPickerTree (rootBrands, excludeBrandId) {
        buildPickerTree (rootBrands, brandIdToMove) {
            const tree = [];
            for (let i = 0; i < rootBrands.length; i++) {
                const b = rootBrands[i];
                if (b.id !== brandIdToMove) tree.push(this.createPickerBrand(b, brandIdToMove, null, 1));
            }
            return tree;
        },

        // createPickerBrand (brand, excludeBrandId, parent, level) {
        createPickerBrand (brand, brandIdToMove, parent, level) {
            const ans = {id: brand.id, name: brand.name, parent: parent, deleted: brand.deleted};
            if (level === 1 && brand.children) {
                ans.children = [];
                for (let i = 0; i < brand.children.length; i++) {
                    const b = brand.children[i];
                    if (b.id !== brandIdToMove) ans.children.push(this.createPickerBrand(b, brandIdToMove, ans, level + 1));
                }
            }
            return ans;
        },

        onParentUpdated(event) {
            this.submitData.parentId = event;
        },

        async onSave() {
            this.isSaving = true;
            const undeletedCount = this.rootBrands ? this.rootBrands.filter((b) => !b.deleted).length : 0;

            if (undeletedCount >= this.account.maxRootBrands) {
                this.isSaving = false;
                console.warn('Limit reached')
                Beef.LimitReached.show({target: document.querySelector('.move-brand-dialog')}, this.account.maxRootBrands, "root brand", "root brands");
                return
            }

            try {
                const depth = this.brandDepth(this.submitData.id, this.brandTree) + this.brandHeight(this.submitData.parentId, this.brandTree);

                // hardcoded depth of 4 (from handlebars code)
                if (depth >= 4 && !features.ignoreBrandDepth()) {
                    this.isSaving = false;
                    console.warn('Brand depth reached')
                    Beef.LimitBrandDepth.show({target: document.querySelector('.move-brand-dialog')});
                    return
                }

                await this.updateBrand(this.submitData);
                notifyUser(`Successfully moved ${this.submitData.name}.`);
                this.emitClose();

            } catch (error) {
                console.warn("An error occurred while saving...", error)
            } finally {
                this.isSaving = false;
            }
        },

        /**
         * (Logic from handlebars - deprecatedBeefCache)
         * @param tree
         * @param accountCode
         * @returns {{maxDepth: number, tree, count: number, map: {}}}
         */
        processBrands (tree, accountCode) {
            const map = {};
            let maxDepth = 1;
            let count = 0;
            const addToMap = function(b, parent, depth) {
                count++;
                if (depth > maxDepth) maxDepth = depth;
                map[b.id] = b;
                b.parent = parent;
                b.depth = depth;
                b.accountCode = accountCode;
                if (b.children) for (var i = 0; i < b.children.length; i++) addToMap(b.children[i], b, depth + 1);
            };
            for (let i = 0; i < tree.length; i++) addToMap(tree[i], null, 1);

            return {tree: tree, map: map, maxDepth: maxDepth, count: count};
        },


        /**
         * (Logic from handlebars)
         * Returns the depth of this particular brand node.
         * @param id
         * @param brands
         * @returns {number|*}
         */
        brandDepth(id, brands) {
            const brand = brands.map[id];
            if (brand.children) {
                return 1 + brand.children
                    .map((b) => this.brandDepth(b.id, brands))
                    .reduce((prevVal, currVal) => prevVal > currVal ? prevVal : currVal)
            }

            return 1;
        },

        /**
         * (Logic from handlebars)
         * Returns how many jumps there are until you reach the root brand.
         * @param id
         * @param brands
         * @returns {number}
         */
        brandHeight(id, brands) {
            let height = 0;
            while (id) {
                id = brands.map[id].parent;
                if (id) id = id.id;
                height++;
            }

            return height;
        },
    },
}
</script>


<style scoped lang="sass">
@use "../../../tree/edit-brand-dialog"

.move-brand-form
    max-height: 50vh
    overflow-y: scroll



</style>