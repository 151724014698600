/**
 * These are fields used by the fantastic chart for displaying survey data.
 */
import {
    initialiseRow,
    simpleGetterFactory,
    tagAndTopicDescriptionGetterFactory,
    tagAndTopicFilterGetterFactory,
    tagAndTopicRawDataGetterFactory,
    tagAndTopicRawDataSetterFactory,
    tagAndTopicSetterFactory
} from "@/dashboards/widgets/fantasticchart/FantasticUtilities";
import * as b3js from "brandseyejs";
import VuexStore from "@/store/vuex/VuexStore";
import {createGroupExcluding, createSelectExcluding} from "@/dashboards/widgets/fantasticchart/FantasticDataHelpers";
import {
    calculateCes5PointScoreFrom,
    calculateCsatScoreFrom,
    calculateFcrScoreFrom,
    calculateNpsScoreFrom,
    getCes5PointScoreFilter,
    getCsatScoreFilter,
    getFcrScoreFilter,
    getNetPromoterScoreFilter,
    getNpsDetractorsFilter,
    getNpsPassivesFilter,
    getNpsPromotersFilter,
    NPS_DETRACTOR_DESCRIPTION,
    NPS_PASSIVE_DESCRIPTION,
    NPS_PROMOTER_DESCRIPTION
} from "@/app/utils/Surveys";
import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
import {capitalise} from "@/app/utils/StringUtils";


export default {
    surveytemplatetag: {
        tooltipPrepositionPercent: 'tagged with',
        tooltipComparePreposition: 'tagged with',
        tooltipMentionsWithVerb: 'with tag',
        isMultiple: true,
        hideUnknown: true,
        noLimit: true,
        isTag: true,
        tagNamespace: "survey-template",
        grouseAlias: ["tag"],
        getter: function (d){
            let val = simpleGetterFactory("surveytemplatetag.name")(d);
            return val.replace("Survey template: engage_manual_","").replace(/\s\(\d+\)/g,"");
        },
        setter: tagAndTopicSetterFactory("surveytemplatetag"),
        filterGetter: tagAndTopicFilterGetterFactory("surveytemplatetag"),
        filterField: "tag",
        rawDataGetter: tagAndTopicRawDataGetterFactory("surveytemplatetag"),
        rawDataSetter: tagAndTopicRawDataSetterFactory("surveytemplatetag"),
        extendData: function (d) {
            if (d.namespace === "survey-template") {
                return {
                    "surveytemplatetag.id": d.id,
                    "surveytemplatetag.name": d.name,
                    "surveytemplatetag.namespace": d.namespace
                }
            }

            return d;
        },
        preProcess: function (data, model) {
            let compare = model.get("compare");

            // temporary solution:
            // when grouping by this field and comparing it with another isTag field,'
            // we need to swap tag and tag2 - tag2 should be the compare field, but for some reason it ends up being the tag field
            if (compare && compare !== "surveytemplatetag") {
                data.forEach(d => {
                    if (d.tag && d.tag2) {
                        let clone = JSON.parse(JSON.stringify(d));

                        d.tag = clone.tag2;
                        d.tag2 = clone.tag;
                    }
                });
                return data;
            }

            return data;
        },
        descriptionGetter: tagAndTopicDescriptionGetterFactory("surveytemplatetag"),
        scaleX: b3js.scaleDiscrete,
        async getData(model, query, groupBy, ignore, ignoreY, getData) {
            await VuexStore.dispatch('refreshTags');
            let tags = VuexStore.state.tags;

            let engageUserTags = [];
            let engageUserTagPartitions = [];
            const PARTITION_SIZE = 50;

            for (const tag of tags) {
                if (tag.namespace === "survey-template") {
                    engageUserTags.push(tag);
                }
            }

            for (let i = 0; i < engageUserTags.length; i += PARTITION_SIZE) {
                const curPartition = engageUserTags.slice(i, i + PARTITION_SIZE);
                engageUserTagPartitions.push(curPartition);
            }

            let data = [];
            let requests = [];

            for (const filterPartition of engageUserTagPartitions) {
                let engageTagsFilter = `Tag IS ${filterPartition.map(t => t.id).join(" OR TAG IS ")}`;
                let filter = `(${query.filter}) and (${engageTagsFilter})`;
                let q = Object.assign({}, query, {
                    filter: filter,
                    groupBy: groupBy.join(","),
                    select: query.select
                });

                requests.push(getData(q, groupBy, ignore, ignoreY));
            }

            let results = await Promise.all(requests);
            results.forEach(result => {
                data = [...data, ...result];
            });

            return data;
        }
    },

    npsBreakdown: {
        name: "NPS Breakdown",
        isMultiple: true,
        isConstructed: true,
        noLimit: true,
        noMaxItems: true,
        hideOthers: true,
        hideUnknown: true,
        setter: function (d, id, value) {
            d['npsBreakdown.id'] = id;
            d['npsBreakdown.name'] = value;
        },
        getter: simpleGetterFactory("npsBreakdown.id"),
        rawDataGetter(d) {return d._npdBreakdown;},
        rawDataSetter(d, data) {return d._npdBreakdown = data;},
        scaleX: b3js.scaleDiscrete,
        tooltipPreposition: 'are from',
        tooltipPrepositionPercent: 'are from',
        tooltipComparePreposition: 'related to',
        tooltipMentionsWithVerb: 'related to',
        descriptionGetter: tagAndTopicDescriptionGetterFactory("npsBreakdown"),
        formatTooltipX(d) {
            return d;
        },
        filterGetter() { return null },
        extraFilter(d) {
            if (d._npsBreakdown && d._npsBreakdown.filter) return d._npsBreakdown.filter;
            throw new Error("Unable to find filter for nps breakdown metric")
        },
        extendData(d) {
            return {
                "npsBreakdown.id": d.id,
                "npsBreakdown.name": d.label,
                "npsBreakdown.description": d.description,
                "_npsBreakdown": d
            };
        },
        formatX(d) {
            return capitalise(d);
        },
        defaultSortOptions: {
            label: "NPS Breakdown",
            field: "_npsBreakdown.ordinal",
            order: "descending"
        },
        sorter: function (lhs, rhs, order) {
            order ??= "descending";

            const lhsScore = lhs._npsBreakdown && lhs._npsBreakdown.ordinal || 0;
            const rhsScore = rhs._npsBreakdown && rhs._npsBreakdown.ordinal || 0;

            return order === "descending" ? rhsScore - lhsScore : lhsScore - rhsScore;
        },
        async getData(model, query, groupBy, ignore, ignoreY, getData) {
            const g = createGroupExcluding(groupBy, ignore, "npsBreakdown");
            const s = createSelectExcluding(query, ignore, "npsBreakdown").join(',');


            const npsDetractorsFilter = getNpsDetractorsFilter();
            const npsPassivesFilter = getNpsPassivesFilter();
            const npsPromotersFilter = getNpsPromotersFilter();

            const filter = appendFiltersReadably(query.filter, getNetPromoterScoreFilter());

            // noinspection DuplicatedCode
            const [npsDetractors, npsPassives, npsPromoters] = await Promise
                .all([
                    ...([
                        {id: 'detractors', filter: npsDetractorsFilter, ordinal: 3, label: "Detractors", description: NPS_DETRACTOR_DESCRIPTION},
                        {id: 'passives', filter: npsPassivesFilter, ordinal: 2, label: "Passives", description: NPS_PASSIVE_DESCRIPTION},
                        {id: 'promoters', filter: npsPromotersFilter, ordinal: 1, label: "Promoters", description: NPS_PROMOTER_DESCRIPTION},
                    ]
                        .map(filterInfo => {
                            const q = Object.assign({}, query, {
                                filter: filterInfo.filter ? `(${filter}) and (${filterInfo.filter} )` : filter,
                                groupBy: g.join(','),
                                select: s
                            });

                            // noinspection DuplicatedCode
                            if (!g.length) delete q.groupBy;

                            return getData(q, g, ignore, ignoreY)
                                .then(d => Array.isArray(d) ? d : [d])
                                .then(d => d.map(r => Object.assign({npsBreakdown: filterInfo}, r)))
                                .then(d => d.length ? d : [initialiseRow(model, {npsBreakdown: filterInfo})])
                        }))
                ]);

            return [...npsDetractors, ...npsPassives, ...npsPromoters].flat();
        }
    },
    surveyQuestion: {
        name: "Survey Question",
        tooltipPrepositionPercent: 'tagged with',
        tooltipComparePreposition: 'tagged with',
        tooltipMentionsWithVerb: 'with question',
        tooltipPreposition: 'are from',
        isMultiple: true,
        hideUnknown: true,
        noLimit: true,
        isTag: true,
        getter: function (d){
            let val = d._surveyQuestion?.description;
            return val.replace("Survey template: engage_manual_","").replace(/\s\(\d+\)/g,"");
        },
        setter: function (d, id, value) {
            d['surveyQuestion.id'] = id;
            d['surveyQuestion.description'] = d.description;
        },
        filterGetter: tagAndTopicFilterGetterFactory("surveyQuestion"),
        rawDataGetter(d) {
            return d._surveyQuestion;
        },
        rawDataSetter(d, data) {
            return d._surveyQuestion = data;
        },
        scaleX: b3js.scaleDiscrete,
        filterField: "tag",
        tagNamespace: "survey-question",
        grouseAlias: ["tag"],
        descriptionGetter: tagAndTopicDescriptionGetterFactory("surveyQuestion"),
        formatTooltipX(d) {
            return d;
        },
        extraFilter(d) {
            if (d._surveyQuestion && d._surveyQuestion.filter) return d._surveyQuestion.filter;
            throw new Error("Unable to find filter for survey question metric")
        },
        extendData(d) {
            return {
                "surveyQuestion.id": d.id,
                "surveyQuestion.name": d.label,
                "surveyQuestion.description": d.description,
                "_surveyQuestion": d
            };
        },
        defaultSortOptions: {
            label: "Survey Question",
            field: "_surveyQuestion.ordinal",
            order: "descending"
        },
        sorter: function (lhs, rhs, order) {
            order ??= "descending";

            const lhsScore = lhs._surveyQuestion && lhs._surveyQuestion.ordinal || 0;
            const rhsScore = rhs._surveyQuestion && rhs._surveyQuestion.ordinal || 0;

            return order === "descending" ? rhsScore - lhsScore : lhsScore - rhsScore;
        },
        async getData(model, query, groupBy, ignore, ignoreY, getData) {
            await VuexStore.dispatch('refreshTags');
            let tags = VuexStore.state.tags;

            let surveyQuestionTags = [];
            let surveyQuestionTagPartitions = [];
            const PARTITION_SIZE = 50;

            for (const tag of tags) {

                if (tag.namespace === "survey-question") {
                    surveyQuestionTags.push(tag);
                }
            }
            let surveyQuestionTagsMqp =  Object.fromEntries(
                surveyQuestionTags.map(obj => [obj.id, obj])
            );

            for (let i = 0; i < surveyQuestionTags.length; i += PARTITION_SIZE) {
                const curPartition = surveyQuestionTags.slice(i, i + PARTITION_SIZE);
                surveyQuestionTagPartitions.push(curPartition);
            }

            let data = [];
            let requests = [];

            for (const filterPartition of surveyQuestionTagPartitions) {
                let surveyQuestionsFilter = `Tag IS ${filterPartition.map(t => t.id).join(" OR TAG IS ")}`;
                let filter = `(${query.filter}) and (${surveyQuestionsFilter})`;
                let q = Object.assign({}, query, {
                    filter: filter,
                    groupBy: groupBy.join(","),
                    select: query.select
                });

                requests.push(getData(q, groupBy, ignore, ignoreY));
            }

            let results = await Promise.all(requests);
            results.forEach(result => {
                data = [...data, ...result];
            });
            data = data.map(it => ({
                ...it,
                tag: surveyQuestionTagsMqp[it.tag.id],
                filter: `${query.filter} AND TAG IS ${it.tag.id}`
            }))

            return data;
        }
    },
    "npsScore": {
        tooltipArticle: "a",
        name: "NPS Score",
        chartName: "NPS Score",
        grouseAlias: ["mentionCount"],
        yLabel: {long: "Net Promoter Score", short: "NPS"},
        defaultSortOptions: {
            label: "NPS Score",
            field: "npsScore",
            order: "descending"
        },
        formatY: (v) => v + '%',
        formatLabel: (v) => v + '%',
        getter: function(d) {
            return d["npsScore"];
        },
        extraFilterWhenY: (d) => `${getNetPromoterScoreFilter()} OR ${getNpsPromotersFilter()} OR ${getNpsDetractorsFilter()}`,
        async getData(model, query, groupBy, ignore, ignoreY, getData){

            const [netPromoterScoreData, npsPromoterData, npsDetractorData] = await Promise.all(
                [
                    getData({...query, filter: appendFiltersReadably(query.filter, getNetPromoterScoreFilter())}, [...groupBy], [...ignore], ignoreY),
                    getData({...query, filter: appendFiltersReadably(query.filter, getNpsPromotersFilter())}, [...groupBy], [...ignore], ignoreY),
                    getData({...query, filter: appendFiltersReadably(query.filter, getNpsDetractorsFilter())}, [...groupBy], [...ignore], ignoreY),
                ]
            )

            const result = [];
            const groupKey = (groupBy[0].startsWith("published")) ? "published" : groupBy[0];
            for (const entry of netPromoterScoreData) {
                const npsPromoterDataEntry = npsPromoterData.find(it => getGroupKey(it[groupKey]) === getGroupKey(entry[groupKey]));
                const npsDetractorDataEntry = npsDetractorData.find(it => getGroupKey(it[groupKey]) === getGroupKey(entry[groupKey]));
                const npsScore = Math.round(calculateNpsScoreFrom(entry.mentionCount, npsDetractorDataEntry?.mentionCount ?? 0, npsPromoterDataEntry?.mentionCount ?? 0) * 100)
                result.push({...entry, npsScore: npsScore})
            }

            return result;
        }

    },
    "cesScore": {
        tooltipArticle: "a",
        name: "CES Score",
        chartName: "CES Score",
        grouseAlias: ["mentionCount"],
        yLabel: {long: "Customer Effort Score", short: "CES"},
        defaultSortOptions: {
            label: "CES Score",
            field: "cesScore",
            order: "descending"
        },
        formatY: (v) => v + '%',
        formatLabel: (v) => v + '%',
        getter: function(d) {
            return d["cesScore"];
        },
        extraFilterWhenY: (d) => `${getCes5PointScoreFilter()} OR segment is 196492 OR segment is 196493`,
        async getData(model, query, groupBy, ignore, ignoreY, getData){

            const [totalCesData, stronglyAgreeData, agreeData] = await Promise.all(
                [
                    getData({...query, filter: appendFiltersReadably(query.filter, getCes5PointScoreFilter())}, [...groupBy], [...ignore], ignoreY),
                    getData({...query, filter: appendFiltersReadably(query.filter, appendFiltersReadably(getCes5PointScoreFilter(), "segment is 196492"))}, [...groupBy], [...ignore], ignoreY),
                    getData({...query, filter: appendFiltersReadably(query.filter, appendFiltersReadably(getCes5PointScoreFilter(), "segment is 196493"))}, [...groupBy], [...ignore], ignoreY),
                ]
            )

            const result = [];
            const groupKey = (groupBy[0].startsWith("published")) ? "published" : groupBy[0];
            for (const entry of totalCesData) {
                const stronglyAgreeDataEntry = stronglyAgreeData.find(it => getGroupKey(it[groupKey]) === getGroupKey(entry[groupKey]));
                const agreeDataEntry = agreeData.find(it => getGroupKey(it[groupKey]) === getGroupKey(entry[groupKey]));
                const cesScore = Math.round(calculateCes5PointScoreFrom(entry.mentionCount, agreeDataEntry?.mentionCount ?? 0, stronglyAgreeDataEntry?.mentionCount ?? 0) * 100)
                result.push({...entry, cesScore: cesScore})
            }

            return result;
        }

    },
    "fcrScore": {
        tooltipArticle: "a",
        name: "FCR Score",
        chartName: "FCR Score",
        grouseAlias: ["mentionCount"],
        yLabel: {long: "First Call Resolution Score", short: "FCR"},
        defaultSortOptions: {
            label: "FCR Score",
            field: "fcrScore",
            order: "descending"
        },
        formatY: (v) => v + '%',
        formatLabel: (v) => v + '%',
        getter: function(d) {
            return d["fcrScore"];
        },
        extraFilterWhenY: (d) => `${getCes5PointScoreFilter()} OR segment is 194053`,
        async getData(model, query, groupBy, ignore, ignoreY, getData){

            const [totalFcrData, totalYesData] = await Promise.all(
                [
                    getData({...query, filter: appendFiltersReadably(query.filter, getFcrScoreFilter())}, [...groupBy], [...ignore], ignoreY),
                    getData({...query, filter: appendFiltersReadably(query.filter, appendFiltersReadably("segment is 194053", getFcrScoreFilter()))}, [...groupBy], [...ignore], ignoreY),
                ]
            )

            const result = [];
            const groupKey = (groupBy[0].startsWith("published")) ? "published" : groupBy[0];
            for (const entry of totalFcrData) {
                const totalYesDataEntry = totalYesData.find(it => getGroupKey(it[groupKey]) === getGroupKey(entry[groupKey]));
                const fcrScore = Math.round(calculateFcrScoreFrom(entry.mentionCount, totalYesDataEntry?.mentionCount ?? 0) * 100)
                result.push({...entry, fcrScore: fcrScore})
            }
            return result;
        }
    },
    "csatScore": {
        tooltipArticle: "a",
        name: "CSAT Score",
        chartName: "CSAT Score",
        grouseAlias: ["mentionCount"],
        yLabel: {long: "Customer Satisfaction Score", short: "CSAT"},
        defaultSortOptions: {
            label: "CSAT Score",
            field: "csatScore",
            order: "descending"
        },
        formatY: (v) => v + '%',
        formatLabel: (v) => v + '%',
        getter: function(d) {
            return d["csatScore"];
        },
        extraFilterWhenY: (d) => `${getCsatScoreFilter()} OR segment is 167452 or segment is 167453`,
        async getData(model, query, groupBy, ignore, ignoreY, getData){

            const [totalCsatData, totalFourAndFiveData] = await Promise.all(
                [
                    getData({...query, filter: appendFiltersReadably(query.filter, getCsatScoreFilter())}, [...groupBy], [...ignore], ignoreY),
                    getData({...query, filter: appendFiltersReadably(query.filter, appendFiltersReadably("segment is 167452 or segment is 167453", getCsatScoreFilter()))}, [...groupBy], [...ignore], ignoreY),
                ]
            )

            const result = [];
            const groupKey = (groupBy[0].startsWith("published")) ? "published" : groupBy[0];
            for (const entry of totalCsatData) {
                const totalFourAndFiveDataEntry = totalFourAndFiveData.find(it => getGroupKey(it[groupKey]) === getGroupKey(entry[groupKey]));
                const csatScore = Math.round(calculateCsatScoreFrom(entry.mentionCount, totalFourAndFiveDataEntry?.mentionCount ?? 0) * 100)
                result.push({...entry, csatScore: csatScore})
            }
            return result;
        }
    },


}

function getGroupKey(value) {
    if (!value) throw new Error();
    if (typeof value === 'object') {
        if ('id' in value) {
            return value.id;
        }
        return JSON.stringify(value);
    }
    return value;
}
