<template>

    <!-- START OF HEADING -->
   <div>

       <PhraseSearchComponent :brand-tree="internalBrand" />

       <div class="root-brand-brand-tree" :style="{'grid-template-columns': getColumns}">
           <div class="heading brand-name-cell bt-cell">Brand
               <TooltipComponent>
                   <BeButton link @click="toggleAll" class="expand-all">
                       <span>All</span>
                       <i class="icon-down-open" v-if="!showAll"></i>
                       <i class="icon-up-open" v-if="showAll"></i>
                   </BeButton>
                   <template #tooltip>Expand/collapse tree</template>
               </TooltipComponent>
               <span v-if="internalBrand.canEdit" class="add-sub-brand">Add a sub-brand</span>
           </div>
           <div class="heading phrase-count-cell">Phrases</div>
           <div v-if="internalBrand.canEdit" class="heading edit-brand-cell">
            <span class="edit-brand">
                Edit
            </span>
           </div>
           <div class="heading trash-cell">
               <TooltipComponent>
                   <span>Trash</span>
                   <template #tooltip>% of mentions sent to trash</template>
               </TooltipComponent>
           </div>
           <!-- END OF HEADING -->


           <div class="brand-tree-container">
               <BrandTreeRow :brand="internalBrand" :show-all="internalBrand.showChildren"></BrandTreeRow>
           </div>


           <div class="footer form-inline">
               <label class="checkbox">
                   <input type="checkbox" v-model="searchToggles.inactivePhrases"> Show inactive search phrases
               </label>
               <label class="checkbox">
                   <input type="checkbox" class="showDeleted" v-model="searchToggles.showBrandsAndPhrasesInTrash"> Show brands and phrases in Trash
               </label>
           </div>
       </div>
   </div>


</template>

<script>
import nameFormat from "@/helpers/nameFormat";
import BrandTreeRow from "@/setup/brands/tree/rows/BrandTreeRow.vue";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";
import BeButton from "@/components/buttons/BeButton.vue";
import PhraseSearchComponent from "@/setup/brands/tree/PhraseSearchComponent.vue";

export default {
    name: "BrandTree",
    components: {PhraseSearchComponent, BeButton, TooltipComponent, BrandTreeRow},
    props: ['brand', 'setSearchToggles'],

    data() {
        return {
            searchToggles: {
                inactivePhrases: this.setSearchToggles.inactivePhrases,
                showBrandsAndPhrasesInTrash: this.setSearchToggles.showBrandsAndPhrasesInTrash,
            },

            showAll: true,
            internalBrand: {}
        }
    },


    watch: {
        'searchToggles.inactivePhrases'(newVal) {
            this.$emit('inactive', {...this.searchToggles, inactivePhrases: newVal})
        },

        'searchToggles.showBrandsAndPhrasesInTrash'(newVal) {
            this.$emit('deleted', {...this.searchToggles, showBrandsAndPhrasesInTrash: newVal})
        },

        brand() {
            this.internalBrand = {
                ...this.brand,
                showChildren: this.internalBrand.showChildren || true,
                showPhrases: this.internalBrand.showPhrases || false
            }
        }
    },

    mounted() {
        this.internalBrand = this.brand
    },

    computed: {
        getColumns() {
            return this.brand.canEdit ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)';
        },
    },

    methods: {
        nameFormat,


        toggleAll() {
            this.showAll = !this.showAll;
            this.toggleBrandShow(this.internalBrand)
        },

        toggleBrandShow(internalBrand) {

            if (this.showAll === true) {
                internalBrand.showChildren = true
            }

            if (this.showAll === false && internalBrand.level > 0) {
                internalBrand.showChildren = false;
            }

            if (this.showAll === false) {
                internalBrand.showPhrases = false;
            }

            internalBrand.children?.forEach(child => {
                this.toggleBrandShow(child)
            })


        }

    },
}
</script>

<style scoped lang="sass">

@use "edit-brand-dialog"

@mixin headerPadding()
    padding: 7px 3px 3px 3px

.root-brand-brand-tree
    display: grid
    grid-template-rows: auto
    border-left: 1px solid black
    border-right: 1px solid black
    background-color:  var(--table-background-colour)
    margin-top: 15px

.heading
    background-color: var(--table-header-background-colour)

.heading.brand
    border: 1px solid black
    border-left: none

.bt-cell
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    @include headerPadding()

.expand-all
    cursor: pointer

.add-sub-brand
    justify-self: flex-end

.heading.phrase-count-cell, .heading.edit-brand-cell, .heading.trash-cell
    @include headerPadding()

.brand-tree-container
    display: contents

.footer
    grid-column: span 12
    display: flex
    gap: 20px
    border-bottom: 1px solid black


</style>