<template>
    
    <div class="deq-reset deq-phrase-generator-component">
        <div class="pg-row pg-control pg-colum-1">
            <p>Column 1</p>
            <TextInput v-model="generatorData.col1" :rows="8" :placeholder="'One word or phrase per line'" :width="'100%'" class="pg-input"></TextInput>
        </div>
        <div class="pg-row pg-control pg-colum-2">
            <p>Column 2</p>
            <TextInput v-model="generatorData.col2" :rows="8" :placeholder="'One word or phrase per line'" :width="'100%'" class="pg-input"></TextInput>
        </div>
        <p class="pg-hint">One phrase will be generated for each possible combination of the words or phrases in column 1 and 2</p>
        <CheckboxInput v-model="generatorData.joinWords" label="Join words together" class="pg-join"></CheckboxInput>
    </div>

</template>

<script>
import TextInput from "@/components/inputs/TextInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import {mergePhraseLists} from "@/app/utils/Phrases";

/**
 * $emit event name: 'generated'
 * @type {string}
 */
const EVENT_NAME = 'generated';
export default {
    name: "PhraseGeneratorComponent",
    components: {
        TextInput, CheckboxInput
    },

    watch: {
        onSendData() {
            this.$emit(EVENT_NAME, this.generateTerms());
        },


        generatorData: {
            handler() {
                this.$emit('generated', this.generateTerms());

            },
            deep: true
        }

    },

    data() {
        return {
            generatorData: {
                col1: "",
                col2: "",
                joinWords: false,
            }
        }
    },

    methods: {
        toWords(s) {
            const words = [];
            if (!s) return words;
            const wordArray = s.split("\n");
            for (let i = 0; i < wordArray.length; i++) {
                const word = wordArray[i];
                if (word && word.trim().length > 0 && words.indexOf(word) < 0){
                    // not sure if this should be trimmed, wasn't trimmed in handlebars
                    words.push(word);
                }
            }
            return words;
        },

        generateTerms() {
            const col1 = this.toWords(this.generatorData.col1);
            const col2 = this.toWords(this.generatorData.col2);
            const joinWords = this.generatorData.joinWords;

            return mergePhraseLists(col1, col2, joinWords);
        }
    }
}
</script>


<style scoped lang="sass">

.deq-phrase-generator-component
    display: grid
    grid-template-areas: "col1 col2" "hint hint" "join join"
    gap: 10px
    padding: 10px

    .pg-colum-1
        grid-area: col1
    .pg-colum-2
        grid-area: col2
    .pg-hint
        grid-area: hint
        margin-bottom: 0
    .pg-join
        grid-area: join

    .pg-colum-1, .pg-colum-2
        p
            margin-bottom: 3px



</style>