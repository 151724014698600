<template>
    <dialog-box title="Edit widget comments" @close="$emit('input', null)" width="850px" class="edit-widget-comments-dialog" modal no-transition>
        <section class="commentary-box">
            <CommentaryPlusEditor
                ref="commentaryPlusEditor"
                :blocks="blocks"
                :isReadOnly="false"
                :showActionBtns="true"
                :filter="filter"
                @onEditorChange="editCommentPlus"></CommentaryPlusEditor>
        </section>
        <div class="edit-widget-comments-dialog__comment-settings">
            <drop-down-input v-model="commentFontSize" label="Font size" :options="options.fontSize"/>
            <div class="width-input">
                <text-input v-model="commentWidth" label="Width" numeric :rules="rules.commentWidth"/>
            </div>

        </div>
        <template #buttons>
            <div class="edit-widget-comments-dialog__btns">
                <be-button link @click="$emit('close')">Cancel</be-button>
                <be-button primary @click="Ok">Ok</be-button>
            </div>
        </template>
    </dialog-box>
</template>

<script>
import MarkdownEditor from "@/components/inputs/MarkdownEditor";
import DialogBox from "@/components/DialogBox";
import BeButton from "@/components/buttons/BeButton";
import DropDownInput from "@/components/inputs/DropDownInput";
import TextInput from "@/components/inputs/TextInput";
import CommentaryPlusEditor from "@/dashboards/widgets/commentaryplus/CommentaryPlusEditor.vue";

export default {
    name: "EditWidgetCommentsDialog",

    components: {CommentaryPlusEditor, TextInput, DropDownInput, BeButton, DialogBox },

    props: {
        model: Object,
        filter: String,
        blocks: String
    },

    data() {
        return {
            rules: {
                commentWidth: [v => !v || v >= 0.5 && v <= 20 || "Invalid"]
            },
            options: {
                fontSize: [{id:10}, {id:12}, {id:14}, {id:16}, {id:18}]
            },
            comment: String,
            commentWidth: null,
            commentFontSize: null,
            blocksData: this.blocks
        }
    },

    mounted() {
    },

    created() {
        this.comment = this.model.attributes.comment ?? "";
        this.commentWidth = this.model.attributes.commentWidth ?? null;
        this.commentFontSize = this.model.attributes.commentFontSize ?? null;
    },

    methods: {
        Ok() {
            this.$emit('comment-saved', {comment: this.blocksData, commentWidth: this.commentWidth ?? 4, commentFontSize: this.commentFontSize});
            this.$emit('close');
        },

        async editCommentPlus(output){
            try {
                this.blocksData = JSON.stringify(output);
            } catch (e) {
                console.error(e);
            }
        }
    },


}
</script>

<style scoped lang="sass">

.edit-widget-comments-dialog

    &__comment-settings
        display: flex
        column-gap: 40px

        .width-input
            display: flex
            column-gap: 5px

            span
                color: #bbb
                margin-top: 25px

        ::v-deep input
            width: 80px

    &__btns
        display: flex
        justify-content: flex-end

.comment-editor
    ::v-deep textarea
        min-height: 300px

</style>

<style scoped>

.commentary-box {
    max-height: 500px;
    height: 50%;
    overflow: auto;
    background-color: white;
}

</style>