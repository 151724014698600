<template>
    <div class="component-tip" ref="slot">
        <slot/>
        <div ref="tip" class="glossary-top__tip">
            <slot name="tooltip">
            </slot>
        </div>
    </div>
</template>


<script>
import {closeCurrentTooltip, showTooltipDomElement} from "@/components/tooltip/TooltipUtilities";

export default {
    props: {
        positions: {
            type: Array,
            default: () => ['top-left', 'top-right', 'bottom-left', 'bottom-right']
        }
    },

    data() {
        return {
            tooltipNode: null,
            targets: null,
            tip: null,

            isDestroyed: false
        }
    },

    beforeDestroy() {
        try {
            this.isDestroyed = true;
            if (this.targets?.length) {
                this.targets.forEach(t => t.removeEventListener('mouseenter', this.mouseHandler, true));
            }
            this.targets = null;
        } finally {
            closeCurrentTooltip();
        }
    },

    mounted() {
        this.tooltipNode = this.$refs.tip.parentNode.removeChild(this.$refs.tip);
        const targets = this.targets = this.$refs.slot.childNodes;

        if (!targets) {
            console.error("Unable to find tooltip target");
            console.debug("tip is", this.$refs.slot);
            return;
        }

        targets.forEach(t => {
            // If we have tooltips inside of tooltips, do not add a listener to the child tooltip.
            if (!t?.classList?.contains('component-tip')) {
                t.addEventListener('mouseenter', this.mouseHandler, {capture: true, passive: true});
            }
        })
    },

    methods: {
        mouseHandler(event) {
            if (this.isDestroyed) return;
            if (!this.$refs.tip.innerHTML) return;
            showTooltipDomElement(event.target, this.tooltipNode, {positions: this.positions})
        }
    }
}
</script>

<style scoped lang="sass">

.component-tip
    display: contents

</style>