import {
    getDefaultTargetLanguage,
    getLanguageName,
    saveDefaultTargetLanguage,
    translate
} from "@/app/utils/Language";

import { features } from "@/app/Features";
import { editImportedMentions, editMentions, isMashAdmin } from "@/app/Permissions";
import { showErrorDialog, showInfoDialog } from "@/app/framework/dialogs/Dialog";
import {notifyUser, notifyUserOfError, showBusyNotification} from "@/app/framework/notifications/Notifications";
import { account, currentAccountCode } from "@/app/utils/Account";
import { getBrand } from "@/app/utils/Brands";
import { accountDate, humanizeDate, toEmojiHtml } from "@/app/utils/Format";
import { getMetatagsFromTags } from "@/app/utils/Metatags";
import { getSegmentList } from "@/app/utils/Segments";
import {isString, removeQuotes, removeSingleQuotes, splitQuotedString} from "@/app/utils/StringUtils";
import { logMentionUpdate } from "@/data/Chervil";
import VuexStore from "@/store/vuex/VuexStore";
import moment from "moment";
import _ from 'underscore';
import * as Stores from '../store/deprecated/Stores';
import {
    CES_SURVEY_5POINT_SEGMENT,
    CSAT_SURVEY_SEGMENT,
    FCR_SURVEY_SEGMENT,
    NET_PROMOTER_SCORE_SEGMENT, VERBATIM_SURVEY_SEGMENT
} from "@/app/utils/Surveys";


/**
 * Displays an individual mention, optionally with/without various controls and other other chrome. The View must
 * be used with an instance of the Model in this file and not a generic Backbone.Model.
 */
Beef.module("MentionItem").addInitializer(function(_startupOptions) {

    Handlebars.registerHelper('reverseEach', function(inputArray, options){
        var reversedArray = inputArray.slice().reverse();
        if (options.data) {
            var data = Handlebars.createFrame(options.data);
        }
        var html = reversedArray.map(function(item, index, arr){
            if (data && index === arr.length - 1) {
                data.last = true;
            }
            return options.fn(item, {data: data});
        }).join('');
        return new Handlebars.SafeString(html);
    });

    // these are the networks that snoek stores author information for
    var SNOEK_NETWORKS = ["TWITTER", "FACEBOOK", "INSTAGRAM", "LINKEDIN", "VK", "YOUTUBE"]

    this.Model = Backbone.Model.extend({

        validation: {
            _tags: function(field) {
                if (field) {
                    var split = splitQuotedString(field);
                    var tag = null;
                    for (var i = 0; i < split.length; i++) {
                        tag = removeQuotes(split[i], "'");
                        var error = Beef.TagPicker.validate(tag);
                        if (error) return error;
                    }
                }
            }
        },

        /**
         * Some options include
         * - usePublishFull Set this to true if the mention should display more than the extract.
         * - includePhoto Set this to true if the mention should show any associated photos. Hides the image button.
         * - includeExportButton Set this to true to show the export button. Ensure that there is enough space for this button!
         *                       Setting includePhoto to true will hide the image button, which will do this.
         */
        initialize: async function(attrs, options) { // Not ideal that this is async.
            if (options && options.urlRoot) this.urlRoot = options.urlRoot;
            try {
                await VuexStore.dispatch("profiles/refreshProfiles"); // Ensure profiles are loaded so we can map to facebook page names
                await VuexStore.dispatch("refreshTags");
            } catch (e) {
                // Nothing major will break if this fails, and we do not want it to kill mention rendering.
                console.warn(e);
            }


            var _tags = null;
            var _topics = null;
            var _segments = null;
            var _metatags = null;
            var _originalTags = null;
            var _engageTicketed = false;
            if (attrs.tags) {
                _tags = _(attrs.tags).chain()
                    .filter(function(d) {
                        // Tags we want to hide from people
                        if (isMashAdmin()) return d.namespace !== 'topic' && d.namespace !== 'segment';
                        else return d.namespace !== 'topic' && d.namespace !== 'segment' &&
                            d.namespace !== 'crowd' && d.namespace !== 'system';
                    })
                    .pluck('id')
                    .uniq()
                    .value()
                    .join(' ');
                _topics = _(attrs.tags).chain()
                    .filter(function(d) { return d.namespace === 'topic'; })
                    .pluck('id')
                    .uniq()
                    .value()
                    .join(' ');
                _segments = _(attrs.tags).chain()
                    .filter(d => {
                        if (d.namespace !== 'segment') return false;
                        if (isMashAdmin()) return true;

                        // We want to hide global segments from users, since most of this is internal
                        // stuff, and also reveals things like savings in the Crowd, etc.
                        let parent = getSegmentList(d);
                        if (parent?.id) {
                            parent = VuexStore.getters.idToTag.get(parent.id);
                            return !parent?.global
                        }
                        return true;
                    })
                    .pluck('id')
                    .uniq()
                    .value()
                    .join(' ');

                _metatags = getMetatagsFromTags(attrs.tags);

                _originalTags = attrs._tags;
                if (_topics) {
                    _originalTags = (_originalTags ? _originalTags + ' ' : '') + _topics;
                }
                if (_segments) {
                    _originalTags = (_originalTags ? _originalTags + ' ' : '') + _segments;
                }

                if (attrs.tags.find(t => t.id === 137042)) { // This is the Engage Ticketed segment ID.
                    _engageTicketed = true;
                }
            }

            var _canEditAttrs = editMentions()
                && (!attrs.brand.importedFromAccount || editImportedMentions());
            var _canEditTags = editMentions();
            var _canEditTopics = editMentions() && features.editTopicsOnMentions();
                // This feature is to prevent most people from editing these, including staff,
                // although sometimes we need this to help cleanup data after a bug.

            var _canUpdateSnoek = _canEditAttrs && isMashAdmin();
            if (_canUpdateSnoek && attrs.socialNetwork) {
                _canUpdateSnoek = attrs.authorHandleId && SNOEK_NETWORKS.indexOf(attrs.socialNetwork.id) >= 0;
            }

            this.set({
                "_canEditAttrs": _canEditAttrs,
                "_canEditTags": _canEditTags,
                "_canEditTopics": _canEditTopics,
                "_canUpdateSnoek": _canUpdateSnoek,
                "_language": attrs.language ? attrs.language.id : null,
                "_gender": attrs.gender ? attrs.gender.id : null,
                "_category": attrs.category ? attrs.category.id : null,
                "_tags": _tags,
                "_segments": _segments,
                "_topics": _topics,
                "_originalTags": _originalTags,
                "_isDeletedOnNetwork": this.isDeletedOnNetwork(),
                "_metatags": _metatags,
                "_engageTicketed": _engageTicketed
            });

            var location = this.get('location');
            if (location === undefined) {
                var country = this.get('country');
                if (country) {
                    location = country.id;
                    var region = this.get('region'), city = this.get('city');
                    if (region) location += ", " + region.asciiName;
                    if (city) {
                        if (!region) location += ", "; // This is required for locations without regions, such as 'HK, , hong kong'
                        location += ", " + city.asciiName;
                    }
                }
                if (location) {
                    this.set('location', "'" + location + "'");
                    this.on("change:location", this.locationChanged);
                }
            }

            this._dontUpdateAuthor = false;

            if (features.engageIndirectReplies()) {
                await VuexStore.dispatch("refreshEngageTicketTags")
            }
        },

        locationChanged: function() {
            var location = this.get('location');
            if (!location) return;

            location = removeSingleQuotes(location);

            var locations = location.split(','),
                country = locations[0],
                region = locations.length > 1 ? locations[1].trim() : null,
                city = locations.length > 2 ? locations[2].trim() : null;

            this.set({
                country: country.trim().toUpperCase(),
                region: region,
                city: city
            });
        },

        /**
         * Indicates if this mention was deleted on a social network.
         */
        isDeletedOnNetwork: function() {
            return !!this.get("tombstoned");
        },

        isAttachment() {
            return Beef.Conversation.isFacebook(this) && this.get('extract') === "(Attachments)"
        },

        sync: async function(method, model, options) {
            var _tags = this.get('_tags');
            var _topics = this.get('_topics');
            var _segments = this.get('_segments');
            var _originalTags = this.get('_originalTags');

            if (isString(_topics)) _tags = (isString(_tags) ? _tags + ' ' : '') + _topics;
            if (isString(_segments)) _tags = (isString(_tags) ? _tags + ' ' : '') + _segments;

            var addTags = [], removeTags = [];
            if (isString(_tags)) {
                let createTags = []
                var o = _originalTags ? ' ' + _originalTags + ' ' : '';
                splitQuotedString(_tags).forEach(function(t) {
                    if (t.charAt(0) == "'") {   // new tag e.g. 'foo'
                        let name = t.substring(1, t.length - 1)
                        createTags.push(name)
                        addTags.push({ name: name, verified: true });
                    } else if (o.indexOf(' ' + t + ' ') < 0) {  // existing tag e.g. 12345
                        addTags.push({ id: parseInt(t), verified: true });
                    }
                });
                if (_originalTags) {
                    o = _tags ? ' ' + _tags + ' ' : '';
                    splitQuotedString(_originalTags).forEach(function(t) {
                        if (o.indexOf(' ' + t + ' ') < 0) {
                            var id = parseInt(t);
                            if (id > 0) removeTags.push({ id: id });
                            else removeTags.push({ name: t.substring(1, t.length - 1) });
                        }
                    });
                }
                if (createTags.length) {
                    await Stores.deprecatedTagsStore.ensureTagsExist(createTags)
                    addTags.forEach(t => {
                        if (!t.id) t.id = Stores.deprecatedTagsStore.getByName(t.name, "tag").id
                    })
                }
            }

            var gender = this.get('_gender');
            if (gender == "UNKNOWN") gender = "";

            var category = this.get('_category');
            if (category == "UNKNOWN") category = "";

            var language = this.get('_language');
            if (language == "unknown") language = "";

            var location = this.get('location');
            if (location) {
                location = location.replace(/'/g, "");
                if ('UN' == location) location = '';
            }

            var data = {
                relevancy: this.get('relevancy'),
                relevancyVerified: this.get('relevancyVerified'),
                sentiment: this.get('sentiment'),
                sentimentVerified: this.get('sentimentVerified'),
                location: location,
                gender: gender,
                category: category,
                language: language,
                addTags: addTags,
                removeTags: removeTags,
                updateAuthor: !this._dontUpdateAuthor
            };

            model.attributes.gender = data.gender ? { id: data.gender } : null;
            model.attributes.category = data.category ? { id: data.category } : null;
            model.attributes.language = data.language ? { id: data.language } : null;

            options.data = JSON.stringify(data);

            const busy = showBusyNotification("Updating your mention...");
            options.success = function() {
                // Log this in chervil
                var code = model.collection && model.collection.accountCode || currentAccountCode();
                logMentionUpdate(code, model.get('id'), model.get('uri'), data);

                // Need to make sure we update the original tags data structures,
                // since when we create a new model from this one, that's what's used to
                // populate the _tags etc.
                model.attributes._originalTags = _tags;
                if (addTags) {
                    if (!model.attributes.tags) model.attributes.tags = []
                    addTags.forEach(function(t) { model.attributes.tags.push(t) });
                }
                if (removeTags) {
                    removeTags.forEach(
                        function(t) {
                            if (t.id) {
                                model.attributes.tags = model.attributes.tags.filter(function(existing) {
                                    return existing.id !== t.id;
                                })
                            }
                        })
                }
            };

            try {
                return Backbone
                    .sync(method, model, options)
                    .always(() => busy?.close())
            } catch(e) {
                busy?.close();
                notifyUserOfError("There was an error saving your mention. Please try again.");
                throw e;
            }
        }

    });

    this.View = Backbone.Marionette.ItemView.extend({

        getTemplate: function() {
            if (this.options.ball) return require("@/mentions/MentionItemBall.handlebars");
            if (this.options.authorOnly) return require("@/mentions/MentionItemAuthorOnly.handlebars");
            if (this.options.timeline) return require("@/mentions/MentionItem.handlebars");
            return require("@/mentions/MentionItemRipcase.handlebars");
        },

        attributes: function() {
            var cls = "mention-item";
            if (this.options.ball) cls = "mention-item-ball";
            if (this.options.authorOnly) cls = "mention-item-author-only";
            if (this.options.timeline) cls = "mention-item-timeline";
            if (this.options.small) cls = cls + " " + cls + "-small";
            if (this.options.youtubeHeadPost) cls = cls + " youtube-mention";
            if (this.options.facebookHeadPost) cls = cls + " facebook-video-mention";
            if (this.options.telegramHeadPost) cls = cls + " telegram-video-mention";
            if (this.model.isDeletedOnNetwork()) cls = cls + " mention-item__deleted";
            if (this.model.isAttachment()) cls = cls + " mention-item__attachment";
            if (this.model.attributes.visibility) cls += " " + this.model.attributes.visibility.id;

            cls += " rpcs";
            return { class: cls }
        },

        events: {
            "click .content": "contentClick",
            "click .show-graph": "showGraph",
            "click a.edit-link": "edit",
            "click a.delete-link": "toggleDeleted",
            "click a.mark-relevant": "markRelevant",
            "click .export-image": "exportImage",
            "click .network-interactions": "networkInteractionsClicked",
            "click .avatar": "avatarClicked",
            "click .mention-translation": "translateClicked",
            "click .mention-translation-options": "translateOptionClicked",
            "click .mention-item__rpcs": "ripcaseClicked",
            "click .create-engage-ticket   ": "createEngageTicketFromMention",
            "mouseover .mention-item__sentiment": "showSentimentTooltip",
            "mouseover .mention-item__mclogo svg": "showMarketConductTooltip"
        },

        modelEvents: {
            "change": "render",
            "change:_anonymise": "anonymise"
        },

        initialize: function(options) {
            if (!this.intervalHandler && !this.isClosed) {
                if (this.isClosed && this.intervalHandler) {
                    clearInterval(this.intervalHandler);
                    this.intervalHandler = null;
                    return;
                }

                this.intervalHandler = setInterval(function() {
                    try {
                        var value = null;
                        if (!options.timeline || !options.notime) value = humanizeDate(this.model.get('published'), true);
                        else value = accountDate(this.model.get('published'), "MMM Do");

                        if (value && value.string) value = value.string;
                        if (value) {
                            this.$('.date').html(value);
                        }

                    } catch (e) {
                        if (this.intervalHandler) clearInterval(this.intervalHandler);
                        this.intervalHandler = null;
                    }

                }.bind(this), 60000);
            }
        },

        isShowSentiment: function() {
            if (!this.model.get('sentimentVerified')) return false;
            if (!account().payPerBrand) return true;
            var brand = this.model.get('brand');
            var acBrand = getBrand(brand.id);
            return !acBrand || acBrand.rateSentiment;
        },

        isMarketConduct() {
            // this code is relying on the tagStore having been initialised, which happens
            // in the initialize method.
            const conducts = this.model.get('tags')
                ?.filter(t => t.namespace === "segment" && t.flag !== "NONE_OF_THE_ABOVE")
                ?.map(t => Stores.deprecatedTagsStore.get(t.id))
                ?.filter(t => t?._parent?.segmentType?.id === "TCF_LIST");

            return conducts?.length > 0
        },

        templateHelpers: function() {
            var authorized = Beef.user.getNonExpiredAuthorizedProfiles();
            var twitterAuthorized = false,
                facebookAuthorized = false;
            var engagement = this.model.get('engagement');

            _(authorized).each(function(profile) {
                twitterAuthorized = twitterAuthorized || Beef.Conversation.isTwitter(profile);
                facebookAuthorized = facebookAuthorized || Beef.Conversation.isFacebook(profile);
            });

            twitterAuthorized = twitterAuthorized && Beef.Conversation.isTwitter(this.model);
            facebookAuthorized = facebookAuthorized && Beef.Conversation.isFacebook(this.model);

            var isFacebook = Beef.Conversation.isFacebook(this.model);
            var isFacebookVid = Beef.Conversation.isFacebookVideo(this.model);
            var isTelegramVid = Beef.Conversation.isTelegramVideo(this.model);
            var isInstagramVid = Beef.Conversation.isInstagramVideo(this.model);

            var engagementSize = 'small-engagement';
            if (engagement >= 100) engagementSize = "medium-engagement";
            if (engagement >= 99950) engagementSize = "large-engagement";

            var usePhoto = !!this.options.includePhoto;
            var mediaLinks = this.model.get('mediaLinks');
            var useCarousel = this.options.includePhoto && mediaLinks && mediaLinks.length > 1;
            if (mediaLinks && mediaLinks.length) {
                mediaLinks[0].first = true;
                var sortedMediaLinks = [];
                for(var mediaLinkIndex = 0; mediaLinkIndex < mediaLinks.length; mediaLinkIndex++) {
                    var mediaLink = mediaLinks[mediaLinkIndex];
                    mediaLink.carouselActive = false;
                    if((mediaLink.url.toUpperCase().indexOf('EMEDIAMONITOR') != -1
                        || mediaLink.url.toLowerCase().indexOf('video-cache.brandseye.com') != -1
                        || mediaLink.url.toLowerCase().indexOf('media-player.brandseye.com') != -1) && mediaLink.mimeType == 'text/html') {
                        mediaLink.isEmbeddableHtml = true;
                        sortedMediaLinks.unshift(mediaLink);
                        mediaLink.url = mediaLink.url.replace('http://', 'https://')
                    }
                    else if(mediaLink.url.toUpperCase().indexOf("YOUTUBE") != -1) {
                        mediaLink.isEmbeddableHtml = true;
                        mediaLink.isYoutubeVid = true;
                        mediaLink.url = mediaLink.url + "?html5=1";
                        sortedMediaLinks.push(mediaLink);
                    }
                    else if(isFacebook && mediaLink.mimeType.toUpperCase().indexOf('VIDEO/MP4') != -1) {
                        mediaLink.isEmbeddableHtml = true;
                        mediaLink.isFacebookVid = true;
                        mediaLink.url = 'https://media-player.brandseye.com/player/?template=mpegvideo&mediaurl=' + encodeURIComponent(mediaLink.url);
                        sortedMediaLinks.push(mediaLink);
                    }
                    else if(isFacebook && mediaLink.mimeType.toUpperCase().indexOf('TEXT/HTML') != -1 && mediaLink.url.toUpperCase().indexOf('/VIDEO') != -1) {
                        mediaLink.isEmbeddableHtml = true;
                        mediaLink.isFacebookVid = true;
                        sortedMediaLinks.push(mediaLink);
                    }
                    else if (isTelegramVid) {
                        mediaLink.isEmbeddableHtml = true;
                        mediaLink.isTelegramVid = true;
                        mediaLink.url = 'https://media-player.brandseye.com/player/?template=mpegvideo&mediaurl=' + encodeURIComponent(mediaLink.url);
                        sortedMediaLinks.push(mediaLink);
                    }
                    else if(isInstagramVid) {
                        mediaLink.isEmbeddableHtml = false;
                        mediaLink.isInstagramVid = true;
                        mediaLink.mentionLink = this.model.get('uri');
                        sortedMediaLinks.push(mediaLink)
                    }
                    else {
                        mediaLink.isEmbeddableHtml = false;
                        sortedMediaLinks.push(mediaLink)
                    }
                }
                sortedMediaLinks[0].carouselActive = true
            }
            var mentionUri = this.model.get('uri');
            var print_media = mentionUri && mentionUri.toLowerCase().indexOf('pressdisplay-redirect.brandseye.com') != -1;
            var novus_print_media = mentionUri && mentionUri.toLowerCase().indexOf('http://media-cache.brandseye.com') == 0;
            if(print_media) {
                usePhoto = false;
            }
            var hasVideo = mentionUri && (mentionUri.indexOf('media-player.brandseye.com') != -1 || mentionUri.indexOf('video-cache.brandseye.com') != -1
                || (mentionUri.indexOf('youtube.com') != -1 && !this.model.get('replyToUri')) || isFacebookVid);
            var hasAudio = mentionUri && (mentionUri.indexOf('mpegaudio') != -1);
            var hasPrint = print_media || novus_print_media;
            var published = moment(this.model.get('published'));
            var partOfConversation = this.model.get('visibility') || this.model.get('reshareOfId') || this.model.get('replyToId');

            var ans = {
                publishedAt: "published " + published.format("Do MMMM YYYY") +
                    ", at " + published.format("HH:mm"),
                twitterAuthorized: twitterAuthorized,
                facebookAuthorized: facebookAuthorized,
                engagementSize: engagementSize,
                usePhoto: usePhoto,
                hintPhoto: print_media || novus_print_media || hasVideo || (this.model.get('mediaLinks') && this.model.get('mediaLinks').length),
                useCarousel: useCarousel,
                stats: this.options.stats || {},
                hasVideo: hasVideo,
                hasPrint: hasPrint,
                hasAudio: hasAudio,
                partOfConversation: partOfConversation,
                showSentiment: this.isShowSentiment(),
                showMarketConduct: this.isMarketConduct(),
                isAttachment: this.model.isAttachment(),
                translationDisabled: this.model.get("language") && this.model.get("language").id === getDefaultTargetLanguage()
            };

            var orderby = this.options.orderby;
            if (orderby) {
                var i = orderby.indexOf(' ');
                ans.stats[i < 0 ? orderby : orderby.substring(0, i)] = true;
            }

            return  ans
        },

        onRender: function() {
            Beef.MentionItem.onRender(this.$el, this.model, this.isSelected());
            this.fixContentLinks();
            if (features.surveyReportingEnhancements() && this.isSurveyMention()) this.setSurveyText();
        },

        fixContentLinks: function() {
            let $mentionTextLinks = this.$('.mention-text a');
            $mentionTextLinks.prop('target', '_blank');
            $mentionTextLinks.prop('title', 'Open this link in a new tab');
            $mentionTextLinks.prop('rel', 'noreferrer'); // don't report where links are coming from to twitter / facebook / etc.
        },

        isSelected: function() {
            var parentModel = this.model.getAncestorProperty('parentModel');

            if (this.options.noSelect) return false;
            if (!parentModel) return false;

            return ((parentModel.get('allSelected') &&
                    parentModel.get('unselected')[this.model.get('id')] == null) ||
                    parentModel.get('selected')[this.model.get('id')] != null);
        },

        serializeData: function() {
            var data = Backbone.Marionette.ItemView.prototype.serializeData.call(this, arguments);
            data._options = this.options;
            return data;
        },

        makeLinkInactive: function ($t) {
            $t.css({ cursor: 'default' });
            $t.removeClass('hover-link');
        },

        contentClick: function(ev) {
            if (!$(ev.target).is("a")) this.edit(ev);
        },

        edit: function(ev) {
            ev.preventDefault();

            var popup = new Beef.Popup.View({
                closeOnHide: true,
                positions:["center"],
                alwaysMove: true
            });

            var view = new Beef.EditMention.View({
                model: this.model,
                accountCode: currentAccountCode(),
                cache: this.cache,
                noViewConversation: this.options.noViewConversation
            });
            var that = this;
            view.on("close", function() {
                popup.hide();
                // that.render();
            });
            popup.setTarget($(ev.target));
            popup.show(view);
        },

        showGraph: function(ev) {
            // Event handled elsewhere
            if (this.options.ignoreEditEvents) return;

            ev.preventDefault();
            if (this.options.noViewConversation) return;
            Beef.MentionConversation.show(this.model, this.model.collection && this.model.collection.accountCode || currentAccountCode());
        },

        exportImage: function(ev) {
            Beef.MentionItem.saveImage($(ev.target), this.$el, this.model.attributes);
        },

        toggleDeleted: function(ev) {
            if (this.options.ignoreEditEvents) return;

            ev.preventDefault();
            var oldRelevancy = this.model.get('relevancy'), relevancy = oldRelevancy;
            if (relevancy == "RELEVANT" || relevancy == "UNSORTED") relevancy = 'IRRELEVANT';
            else if (relevancy == "IRRELEVANT") relevancy = 'RELEVANT';
            else return;
            this.model.set({relevancy: relevancy, relevancyVerified: true});
            try {
                this.model._dontUpdateAuthor = true;
                var xhr = this.model.save();
            } finally {
                this.model._dontUpdateAuthor = false;
            }
            if (xhr) {
                var that = this;
                xhr.done(function() { that.model.trigger("relevancyUpdated", that.model, oldRelevancy) });
            }
        },

        markRelevant: function(ev) {
            if (this.options.ignoreEditEvents) return;

            ev.preventDefault();
            this.model.set({relevancy: 'RELEVANT', relevancyVerified: true});
            try {
                this.model._dontUpdateAuthor = true;
                this.model.save();
            } finally {
                this.model._dontUpdateAuthor = false;
            }
        },

        useInteractDialog: function() {
            var authorized = Beef.user.getNonExpiredAuthorizedProfiles();
            var showInteract = false;
            if (authorized.length) {
                if (Beef.Conversation.isTwitter(this.model) && _(authorized).find(function(p) { return Beef.Conversation.isTwitter(p); })) {
                    showInteract = true;
                }
                if (Beef.Conversation.isFacebook(this.model) && _(authorized).find(function(p) { return Beef.Conversation.isFacebook(p); })) {
                    showInteract = true;
                }
            }
            return showInteract;
        },

        networkInteractionsClicked: function(ev){
            var menu = [];

            var visibility = this.model.get("visibility");
            var site = this.model.get('site');
            var uri = this.model.get('uri');
            var socialNetwork = this.model.get('socialNetwork');
            var authorHandle = this.model.get('authorHandle');
            var authorHandleId = this.model.get('authorHandleId');

            var linkIcon = '<i class="icon-link"></i>';
            var linkTooltip = "See this mention " + (socialNetwork ? "on " + socialNetwork.label : "online");
            var isReshare = !!this.model.get('reshareOfUri');
            var isReply = !!this.model.get('replyToUri');
            var type = 'mention';
            if (socialNetwork) {
                switch (socialNetwork.id) {
                    case 'TWITTER':
                        linkIcon = '<i class="symbol-twitter"></i>';
                        type = "tweet";
                        if (isReshare) type = "retweet";
                        linkTooltip = "Open this " + type + " on Twitter";
                        break;
                    case 'FACEBOOK':
                        linkIcon = '<i class="symbol-facebook-rect"></i>';
                        type = "post";
                        if (isReply) type = "comment";
                        linkTooltip = "Open the " + type + " on Facebook";
                        break;
                    case 'INSTAGRAM':
                        linkIcon = '<i class="symbol-instagram"></i>';
                        type = "post";
                        if (isReply) type = "comment";
                        linkTooltip = "Open the " + type + " on Instagram";
                        break;
                    case 'YOUTUBE':
                        linkIcon = '<i class="symbol-youtube"></i>';
                        type = "video";
                        if (isReply) type = "comment";
                        linkTooltip = "Open the " + type + " on YouTube";
                        break;
                    case 'VK':
                        linkIcon = '<i class="symbol-vk-rect"></i>';
                        type = "post";
                        if (isReply) type = "comment";
                        linkTooltip = "Open the " + type + " on VK";
                        break;
                    case 'TELEGRAM':
                        linkIcon = '<i class="symbol-telegram"></i>';
                        type = "post";
                        linkTooltip = "Open the " + type + " on Telegram";
                        break;
                    case 'TRUSTPILOT':
                        linkIcon = '<i class="symbol-trustpilot"></i>';
                        type = !isReply ? "review" : "reply";
                        linkTooltip = "Open the " + type + " on Trustpilot";
                        break;
                    case 'LINKEDIN':
                        linkIcon = '<i class="symbol-linkedin-rect"></i>';
                        type = "post";
                        if (isReply) type = "comment";
                        linkTooltip = "Open the " + type + " on LinkedIn";
                        break;
                    case 'TIKTOK':
                        linkIcon = '<i class="symbol-tiktok"></i>';
                        type = "video";
                        if (isReply) type = "comment";
                        linkTooltip = "Open the " + type + " on TikTok";
                        break;
                    case 'HELLOPETER':
                        linkIcon = '<i class="symbol-hellopeter"></i>';
                        type = "post";
                        if (isReply) type = "comment";
                        linkTooltip = "Open the " + type + " on Hellopeter";
                        break;
                    case "GOOGLEBUSINESS":
                        linkIcon = '<i class="symbol-google-full"></i>';
                        type = "post";
                        if (isReply) type = "comment";
                        linkTooltip = "Open the " + type + " on Google Business";
                        break;
                }
            } else if (site === 'www.hellopeter.com') {
                linkIcon = '<i class="symbol-hellopeter"></i>'
                linkTooltip = "Open on Hellopeter";
            } else {
                linkTooltip = "Open this web page in another tab";
            }

            menu.push({
                text: socialNetwork ? "Open on " + socialNetwork.label : "Open online",
                tooltip: !visibility
                    ? linkTooltip
                    : "This is not a public post, and cannot be linked to",
                href: uri,
                hrefTarget: "_blank",
                icon: linkIcon,
                disabled: !!visibility
            });

            switch (site) {
                case 'www.google.com':
                    if(uri.startsWith("https://www.google.com/search")){
                        var indexOf = uri.indexOf("&googleId=")
                        menu[0].href = uri.substring(0,indexOf);
                    }
                    break;
                case 'twitter.com':
                    if (!visibility) {

                        var i = uri.lastIndexOf('/');
                        var twitterId = i ? uri.substring(i + 1) : null;

                        menu.push({
                            text: "Follow @" + authorHandle,
                            tooltip: "Follow @" + authorHandle + " using your personal Twitter account",
                            href: "https://twitter.com/intent/follow?user_id=" + authorHandleId,
                            hrefTarget: "_blank",
                            icon: '<i class="icon-user-add"></i>'
                        });

                        if (twitterId) {
                            menu.push({
                                text: "Like",
                                tooltip: "Like this tweet using your personal Twitter account",
                                href: "https://twitter.com/intent/favorite?tweet_id=" + twitterId,
                                hrefTarget: "_blank",
                                icon: '<i class="icon-heart"></i>'
                            });
                            menu.push({
                                text: "Retweet",
                                tooltip: "Retweet this tweet using your personal Twitter account",
                                href: "https://twitter.com/intent/retweet?tweet_id=" + twitterId,
                                hrefTarget: "_blank",
                                icon: '<i class="icon-retweet-1"></i>'
                            });
                        }
                    }

            }

            if (!menu.length) return;

            Beef.MiniMenu.showIcons(menu, ev.currentTarget)
                .then(function(popup) {
                    this._interactionPopup = popup;
                }.bind(this));

        },

        avatarClicked: function(ev) {
            ev.preventDefault();
            this.showAuthorPopup(ev);
        },

        translateClicked: function(ev) {
            ev.preventDefault();
            var $button = this.$('.mention-translation');
            if ($button.length && $button.hasClass('disabled')) return;
            this.translate(getDefaultTargetLanguage(), ev.target);
        },

        translateOptionClicked: function(ev) {
            ev.preventDefault();

            var defaultLanguageCode = getDefaultTargetLanguage();
            var name = getLanguageName(defaultLanguageCode);
            var currentLanguage = this.currentLanguage || this.model.get("language") && this.model.get("language").id;

            var menu = [
                {
                    text: "To " + name,
                    tooltip: "Translate this to " + name,
                    method: "defaultLang",
                    disabled: currentLanguage === defaultLanguageCode
                },

                {
                    text: "To original",
                    tooltip: "See the mention's original text",
                    method: "originalLang",
                    disabled: !this.currentLanguage
                },

                {
                    text: "Other language…",
                    tooltip: "Choose another language to translate to",
                    method: "otherLanguage"
                },

                Beef.MiniMenu.divider,

                {
                    text: "Explore translations…",
                    tooltip: "Explore different translations in a popup dialog",
                    method: "dialog"
                }
            ];

            var model = this.model;
            var translate = _.partial(this.translate.bind(this), _, ev.target);

            this.showFullChrome();
            this._currentMenu = Beef.MiniMenu.show({
                object: {
                    defaultLang: function() {
                        translate(defaultLanguageCode);
                    },
                    originalLang: this.showOriginalLang.bind(this),
                    otherLanguage: function() {
                        this.showFullChrome();
                        this._currentMenu = Beef.MiniMenu.show({
                            object: {
                                afrikaans: function() { translate('af') },
                                arabic: function() { translate('ar') },
                                chinese: function() { translate('zh-CN') },
                                english: function() { translate('en') },
                                french: function() { translate('fr') },
                                russian: function() { translate('ru') },
                                sesotho: function() { translate('st') },
                                spanish: function() { translate('es') },
                                xhosa: function() { translate('xh') },
                                zulu: function() { translate('zu') },
                            },
                            target: ev.target,
                            dropdown: true,
                            offsets: { top: -1, right: -6 },
                            onClose: function() { this._currentMenu = null; this.hideFullChrome()}.bind(this),
                            menu: [
                                { text: "Afrikaans", method: "afrikaans", disabled: currentLanguage === 'af'},
                                { text: "Arabic", method: "arabic", disabled: currentLanguage === 'ar'},
                                { text: "Chinese", method: "chinese", disabled: currentLanguage === 'zh' || currentLanguage === 'zh-CN'},
                                { text: "English", method: "english", disabled: currentLanguage === 'en'},
                                { text: "French", method: "french", disabled: currentLanguage === 'fr'},
                                { text: "Russian", method: "russian", disabled: currentLanguage === 'ru'},
                                { text: "Sesotho", method: "sesotho", disabled: currentLanguage === 'st'},
                                { text: "Spanish", method: "spanish", disabled: currentLanguage === 'es'},
                                { text: "Xhosa", method: "xhosa", disabled: currentLanguage === 'xh'},
                                { text: "Zulu", method: "zulu", disabled: currentLanguage === 'zu'}
                            ]
                        })
                    }.bind(this),
                    dialog: function() {
                        Beef.Dialogs.translate(model, defaultLanguageCode);
                    }
                },
                target: ev.target,
                dropdown: true,
                offsets: { top: -1, right: -6 },
                menu: menu,
                onClose: function() { this._currentMenu = null; this.hideFullChrome(); }.bind(this)
            })
        },

        showFullChrome: function() {
            this.$el.addClass("mention-full-chrome");
        },

        hideFullChrome: function() {
            this.$el.removeClass("mention-full-chrome");
        },

        showAuthorPopup: function(ev) {
            if (this._authorInfoPopup) {
                this.hideAuthorPopup();
            }

            var popup = new Beef.Popup.View({
                closeOnHide: true,
                positions:["right"],
                offsets: { top: -2, left: 3 },
                alwaysMove: true
            });

            var view = new Beef.AuthorItemV4.View({model: this.model});
            popup.setTarget($(ev.target));
            popup.show(view);
            this._authorInfoPopup = popup;
            return popup;
        },

        hideAuthorPopup: function() {
            if (this._authorInfoPopup) {
                this._authorInfoPopup.hide();
                this._authorInfoPopup = null;
            }
        },

        showSentimentTooltip: function(event) {
            var tooltipSettings = {
                template: require("@/mentions/MentionItemSentimentTip.handlebars"),
                target: event.currentTarget,
                positions: ['top-right', 'top-left', 'bottom-left', 'bottom-right'],
                model: this.model,
                autoclose: true
            };

            Beef.Tooltip.show(tooltipSettings);
        },

        showMarketConductTooltip: function(event) {
            var tooltipSettings = {
                template: require("@/mentions/MentionItemMarketConductTip.handlebars"),
                target: event.currentTarget,
                positions: ['top-right', 'top-left', 'bottom-left', 'bottom-right'],
                model: this.model,
                autoclose: true
            };

            Beef.Tooltip.show(tooltipSettings);
        },

        ripcaseClicked: function(event) {
            this.$el.toggleClass('be-rpcs-stack--expanded', true);
            Beef.RipcasePopup.show(this.model, event.currentTarget)
                .then(function() {
                    this.$el.toggleClass('be-rpcs-stack--expanded', false);
                }.bind(this));

            this._ripcasePopup = Beef.RipcasePopup.getCurrentPopup();
        },

        isSurveyMention: function () {
            const surveyTags = [190610, 190609];
            for (const tag of this.model.get("tags") ?? []) {
                if (surveyTags.includes(tag.id)) return true;
            }
            return false;
        },

        setSurveyText: function () {

            let verbatimQuestionTags = VuexStore.state.tags.filter(it => it.namespace === "survey-question" && it.name.toLowerCase().includes("verbatim"));

            let verbatimQuestionTagsMap =  Object.fromEntries(
                verbatimQuestionTags.map(obj => [obj.id, obj])
            );

            const surveyQuestionTypeSegments = [
                NET_PROMOTER_SCORE_SEGMENT,
                CES_SURVEY_5POINT_SEGMENT,
                CSAT_SURVEY_SEGMENT,
                FCR_SURVEY_SEGMENT,
                VERBATIM_SURVEY_SEGMENT,
                261707 // custom survey type
            ];
            const surveyResponseTypeSegments = [
                167449, 167450, 167451, 167452, 167453, 194040,
                194041, 194042, 194043, 194044, 194045, 194046,
                194047, 194048, 194049, 194050, 196492, 196493,
                196494, 196495, 196496, 194054, 194053, 259365,
                259366, 259367, 259368, 259370, 259371, 259372,
                259373, 259374, 259375];
            const surveyQuestionTag = this.model.get("tags")?.find(it => surveyQuestionTypeSegments.includes(it.id));
            const surveyResponseTag = this.model.get("tags")?.find(it => surveyResponseTypeSegments.includes(it.id));
            const getSurveyType = () => {
                return surveyQuestionTag?.name ?? "Unknown Survey Type";
            };
            const getSurveyQuestion = () => {
                return isVerbatim ? getVerbatimQuestionPrompt() : this.model.get("postExtract") ?? "Verbatim maybe";
            };
            const getConsumerResponse = () => {
                return isVerbatim ? this.model.get("extract") ?? "Blank response" : surveyResponseTag?.name;
            };

            const isVerbatim = surveyQuestionTag?.id === VERBATIM_SURVEY_SEGMENT;

            const getVerbatimQuestionPrompt = () => {
                const t = this.model.get("tags").find(it => it.namespace === "survey-question" && it.name.toLowerCase().includes("verbatim"));
                return t ? verbatimQuestionTagsMap[t.id]?.description : "";
            }
            const surveyCombinedHtml = `
                <div>
                    <div>${getConsumerResponse()}</div>
                    <div class="be-post-extract">${getSurveyType()} with prompt: <i>${getSurveyQuestion()}</i></div>
                </div>
            `
            this.model.set("combinedHtml", surveyCombinedHtml)
        },

        closeRipcasePopup: function() {
            if (this._ripcasePopup) {
                this._ripcasePopup.close();
                this._ripcasePopup = null;
            }
        },

        onClose: function() {
            this.isClosed = true;
            this.hideAuthorPopup();
            this.closeMenu();
            this.closeRipcasePopup();
        },

        closeMenu: function() {
            if (this._currentMenu) {
                this._currentMenu.close();
                this._currentMenu = null;
                this.hideFullChrome();
            }
        },

        closePopups: function() {
            this.closeMenu();
            this.hideAuthorPopup();
            this.closeRipcasePopup();
            if (this._interactionPopup) {
                this._interactionPopup.close();
            }
        },

        showOriginalLang: function() {
            var $content = this.$('.content .mention-text');
            var fullText = this.options.usePublishFull ? this.model.get('publishFull') : this.model.get('combinedHtml');

            $content.html(fullText);
            this.fixContentLinks();
            delete this.currentLanguage;

            var button = this.$('.mention-translation')[0];
            var language = this.model.get('language');
            if (!language || !language.id) delete button.dataset.lastLanguage;
            else button.dataset.lastLanguage = language.id;

            button.classList.toggle('disabled', language && language.id === getDefaultTargetLanguage());
        },

        translate: function(code, target) {
            var $button = this.$('.mention-translation');
            var name = getLanguageName(code);
            var modelLanguage = this.model.get("language") && this.model.get("language").id;
            var currentLanguage = this.currentLanguage || modelLanguage;

            if (currentLanguage === code) {
                // noinspection JSIgnoredPromiseFromCall
                showInfoDialog("The mention is already in " + name, "Already translated");
                return;
            }

            var $content = this.$('.content .mention-text');
            var fullText = this.options.usePublishFull ? this.model.get('publishFull') : this.model.get('combinedHtml');

            var extract = $('.be-extract', $content).html();
            var to = $('.be-to', $content).html();
            var postExtract = $('.be-post-extract', $content).html();


            if (!extract && !postExtract) return;

            var update = function (text) {
                $content.html(text);
                this.fixContentLinks();
                this.currentLanguage = code;

                // Here, we want to enable / disable all translation buttons as appropriate.
                var buttons = document.querySelectorAll('.mention-item .mention-translation');
                buttons.forEach(function(button) {
                    button.title = "Translate this mention into " + name;
                    if (button === $button[0]) {
                        button.dataset.lastLanguage = code;
                        return;
                    }
                    button.classList.toggle('disabled', button.dataset.lastLanguage === code);
                });

            }.bind(this);

            if ($button.length) {
                $button.addClass('disabled');
            }

            if (modelLanguage === code) {
                saveDefaultTargetLanguage(code);
                update(toEmojiHtml(fullText));
                return;
            }

            var loading = null;
            if (target) {
                loading = showBusyNotification(
                    "Translating into " + name + "…",
                    null,
                    target
                );
            }

            saveDefaultTargetLanguage(code);

            var extractTranslation = extract
                ? translate(extract, code)
                    .then(function(result) {
                        return "<span class='be-extract'>" + result.targetText + "</span>";
                    })
                : null;
            var postExtractTranslation = postExtract
                ? translate(postExtract, code)
                      .then(function(result) {
                          return "<span class='be-post-extract'>" + result.targetText + "</span>";
                      })
                : null;

            var promise = extractTranslation && postExtractTranslation
                ? Promise.all([extractTranslation, postExtractTranslation])
                         .then(function(results) {
                             return results[0] + "<em class='be-to'>" + to + "</em>" + results[1];
                         })
                : extractTranslation || postExtractTranslation;

            promise
                .then(function(translation) { update(translation) }.bind(this))
                .catch(function(error) {
                    console.error("Error translating text", error);
                    showErrorDialog("We're having a problem communicating with Google Translate. Try again in a bit.", "Google Translate Problems");
                    if ($button.length) {
                        $button.toggleClass('disabled', false);
                    }
                })
                .finally(function() {
                    if (loading) loading.close();
                })
        },

        anonymise: function() {
            this.$el.toggleClass('anonymise', !!this.model.get("_anonymise"));
        }
    });

    /**
     * Fix up the DOM after rendering MentionItem.handlebars to e.
     */
    this.onRender = function(e, model, selected) {
        e.attr('data-id', model.get('id'));

        e.toggleClass("selected", selected);

        var relevancy = model.get('relevancy');
        e.toggleClass("irrelevant", relevancy == "IRRELEVANT");
        e.toggleClass("unsorted", relevancy == "UNSORTED");

        e.find(".content a").attr("target", "_blank");

        // Assumption:
        // Traditional broadcast media will never have actual
        // conversation ie. replies / shares / comments
        // This is why we're changing the width
        var uri = model.get('uri');
        e.toggleClass('emediamonitor-video-mention', uri
            && ((uri.toLowerCase().indexOf('emediamonitor.net') != -1)
                 || (uri.toLowerCase().indexOf('video-cache.brandseye.com') != -1)
                 || (uri.toLowerCase().indexOf('media-player.brandseye.com') != -1))
            && e.find('iframe.emediamonitor-net-video').length >= 1);
    };

    /**
     * Convert the mention to an image.
     * @param targetElement is the element the popup which says 'Creating Image...' is displayed relative to.
     * @param container is the JQuery element which is used to grab the html off of
     * @param attrs the attributes of the mention, we need its id
     */
    this.saveImage = function (targetElement, container, attrs) {
        if (this.exporting) return; // A save is already in progress.

        var markup = container.html();
        var classes = ["mention-item", "save-image", "rpcs"];
        if (attrs._anonymise) classes.push("anonymise");

        markup = '<div class="' + classes.join(" ") +'" style="position:relative;width:' + container.width() +
            'px">' + markup + "</div>";

        var popup = showBusyNotification(
            "Saving mention ...",
            null,
            targetElement
        );

        this.exporting = true;
        $.ajax({
            url: "/api/trotter/image",
            type: "POST",
            contentType: "text/plain",
            processData: false,
            data: markup,
            success: (data) => {
                popup.hide();
                this.exporting = false;
                if (account().dev) {
                    window.open(`/api/trotter/display/${data.id}`, "_blank")
                } else {
                    window.location = "/api/trotter/image/" + data.id + "?filename=" + currentAccountCode() +
                        "-" + attrs.id + ".png";
                }
            },
            error: () => {
                popup.hide();
                this.exporting = false;
                showErrorDialog("We're having a problem exporting this mention. Please try again in a moment.")
            }
        });
    }
});