<template>
    <div class="control-group" :class="{error: error}">
        <label v-if="label" @click="onLabelClick">{{label}}</label>
        <div class="controls">
            <popup-menu v-model="droppedDown" ref="popup" ignore-activator-click fixed :use-activator-width="useActivatorWidth">
                <template #activator>
                    <slot>
                        <span class="tag-input uneditable-input span12" :class="{focus: hasFocus, 'disabled': disabled}" ref="box"
                              :tabindex="search ? -1 : 0" @focus="onFocus" @blur="onBlur" @keydown="onKeydown"
                              @click="onClick">
                            <span v-if="placeholder && (!selection || !selection.length)" class="placeholder">{{placeholder}}</span>
                            <span class="tag" v-for="s in selection" :key="s.id" :class="{'not-optional': !optional}">
                                <span class="tag-text">
                                    <i v-if="s.icon" :class="'icon ' + s.icon"/> {{s.name || s.id}}
                                    <span class="muted" v-if="s.subLabel && showSubLabelInSelectedItem">({{ s.subLabel }})</span>
                                </span>
                                <button v-if="optional || multiselect" class="close" tabindex="-1" title="Remove this item"
                                        @click.stop="removeSelection(s.id)">×</button>
                            </span>
                            <input v-if="search" type="text" v-model="q" class="q" ref="search"
                                   @focus="hasFocus = true" @blur="hasFocus = false"/>
                        </span>
                    </slot>
                </template>

                <div class="menu-items dark-scrollbars dark-scrollbars--visible" ref="items">
                    <div v-for="(o,i) in items" :key="o.id" @mousedown="select($event, o)" :title="o.description"
                            :class="{item: o.item, title: !o.item, selected: o.id === value, active: i === pos, disabled: o.disabled, inactive: o.active === false}">
                        <i v-if="o.icon" :class="'icon ' + o.icon"/>
                        {{o.name}} <span class="muted" v-if="o.subLabel">({{ o.subLabel }})</span>
                    </div>
                </div>
            </popup-menu>
            <span v-if="error" class="help-block error-message">{{error}}</span>
        </div>
    </div>
</template>

<script>
    import PopupMenu from "../PopupMenu"

    /**
     * Choose options from a drop down list (from old DynamicPicker).
     */
    export default {
        name: "DropDownInput",

        components: { PopupMenu },

        props: {
            label: String,
            options: [Object, Array],
            value: [String, Array, Number],
            optional: Boolean,
            multiselect: Boolean,
            disabled: Boolean,
            search: Boolean,
            required: Boolean,
            placeholder: String,
            useActivatorWidth: { type: Boolean, default: true },
            showSubLabelInSelectedItem: Boolean,
            stopPropagation: {type: Boolean, default: false}
        },

        data() {
            return {
                droppedDown: false,
                q: "",
                hasFocus: false,
                pos: 0,
                error: null
            }
        },

        computed: {
            items() {
                let items = []
                let sections = { }
                let q = this.q ? this.q.toLowerCase() : null
                let a = Array.isArray(this.options) ? this.options.map(o => [o.id, o]) : Object.entries(this.options)
                a.forEach(row => {
                    let v = row[1]
                    if (v.visible !== undefined && !v.visible) return
                    if (v.subLabel) {
                        if (q && (v.name.toLowerCase().indexOf(q) < 0 && v.subLabel.toLowerCase().indexOf(q) < 0)) return
                    } else if (q && (v.name.toLowerCase().indexOf(q) < 0)) return
                    v.id = row[0]      // not so cool to modify options but its really nice to have an id
                    let o = {...v}
                    o.item = true
                    if (!o.name) o.name = o.id
                    if (o.section) {
                        let s = sections[o.section]
                        if (!s) sections[o.section] = s = { id: "section_" + o.section, name: o.section, items: [] }
                        s.items.push(o)
                    } else {
                        items.push(o)
                    }
                })
                Object.values(sections).forEach(s => {
                    items.push(s)
                    s.items.forEach(o => items.push(o))
                })
                return items
            },

            selection() {
                if (!this.value) return null
                let a = Array.isArray(this.value) ? this.value : [this.value]
                return a.map(v => {
                    let def = { id: v, name: this.required ? ' ' : v }
                    if (Array.isArray(this.options)) return this.options.find(o => o.id == v) || def
                    else return this.options[v] || def
                })
            }
        },

        watch: {
            droppedDown(v) {
                if (v) setTimeout(() => this.scrollTo(".selected"), 1)
            },

            q(v) {
                if (v && !this.droppedDown) this.droppedDown = true
            },

            items() {
                this.$nextTick(() => {
                    if (this.droppedDown) {
                        if (this.items.length) this.$refs.popup.reposition()
                        else this.droppedDown = false
                    }
                })
            },

            hasFocus(v) {
                this.droppedDown = v
            },

            error(v) {
                this.$emit('valid', !v)
            },

            value() {
                this.validate();
            },
        },

        mounted() {
            this.validate()
        },

        methods: {
            select(e, o) {
                if (this.stopPropagation) {
                    e.preventDefault()
                    e.stopPropagation()
                }
                if (o.item && !o.disabled) {
                    if (this.multiselect) {
                        let v
                        if (Array.isArray(this.value)) {
                            if (this.value.indexOf(o.id) >= 0) return
                            v = this.value.slice(0)
                            v.push(o.id)
                        } else {
                            v = [o.id]
                        }
                        this.$emit('input', v)
                    } else {
                        this.$emit('input', o.id)
                    }
                    this.droppedDown = false
                    this.q = ""
                }
            },

            scrollTo(sel) {
                let items = this.$refs.items
                if (!items) return
                let e = items.querySelector(sel)
                if (e) e.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
            },

            removeSelection(id) {
                if (Array.isArray(this.value)) this.$emit('input', this.value.filter(v => v != id))
                else this.$emit('input', null)
            },

            onFocus() {
                if (this.$refs.search) this.$refs.search.focus()
                else this.hasFocus = true
            },

            onBlur() {
                if (!this.$refs.search) this.hasFocus = false
            },

            onClick() {
                this.droppedDown = true
            },

            onLabelClick() {
                this.$refs.box.focus()
            },

            onKeydown(ev) {
                //console.log("onKeydown", ev)
                let kc = ev.keyCode
                if (kc == 27) {
                    if (this.droppedDown) {
                        this.droppedDown = false
                        ev.stopPropagation()
                    }
                    return
                }
                if (kc === 38 /* up */ || kc === 40 /* down */) {
                    ev.preventDefault()
                    ev.stopPropagation()
                    if (!this.droppedDown) {
                        this.droppedDown = true
                    } else {
                        let delta = kc === 38 ? -1 : +1
                        for (let i = Math.min(this.pos + delta, this.items.length - 1); this.items[i]; i += delta) {
                            if (this.items[i].item) {
                                this.pos = i
                                this.$nextTick(() => this.scrollTo(".active"))
                                break
                            }
                        }
                    }
                } else if (kc === 13 /* enter */) {
                    let o = this.items[this.pos]
                    if (o) {
                        this.select(o)
                        this.q = ""
                    }
                } else if (kc == 8 /* backspace */) {
                    if (Array.isArray(this.value)) {
                        if (this.value.length > 0) this.$emit('input', this.value.slice(0, this.value.length - 1))
                    } else if (this.value && this.q === "") {
                        this.$emit('input', null)
                    }
                }
            },

            isValid() {
                return !this.error
            },

            validate() {
                let err = null
                if (this.required) {
                    if (!this.value) {
                        err = "Required"
                    } else {
                        let a = Array.isArray(this.value) ? this.value : [this.value]
                        let o = a.find(v => {
                            return !(Array.isArray(this.options)? this.options.find(o => o.id == v) : this.options[v])
                        })
                        if (o) err = "Required"
                    }
                }
                this.error = err
                return this.isValid()
            }
        }
    }
</script>

<style>
.menu-items {
    max-height: 45vh;
    min-width: fit-content;
    overflow-y: auto;
    margin: 3px 0;
    overscroll-behavior: contain;
}

.menu-items .item {
    cursor: pointer;
    color: #ffffff;
    padding: 3px 20px 3px 10px;
}

.menu-items .item i.icon {
    padding-right: 5px;
}

.menu-items .item.active,
.menu-items .item:hover {
    color: #aee15d;
    background-color: #222;
}

.menu-items .item.disabled {
    color: #777777;
    cursor: default;
}

.menu-items .item.selected {
    background-color: #487329;
    color: #ffffff;
}

.menu-items .item.inactive {
    text-decoration: line-through;
}

.menu-items .title {
    cursor: default;
    font-size: 13px;
    background: black;
    height: 20px;
    padding: 0 20px 0 10px;
    text-transform: uppercase;
    font-family: var(--base-font-family);
    color: var(--be-colour-mid-grey)
}
</style>

<style scoped>
    .tag.not-optional {
        padding-right: 4px !important;
    }

    .q {
        width: 50px;
        border: none;
        background: transparent;
        padding: 0;
        margin: 0 !important;
    }

    .q:focus {
        outline: none;
        box-shadow: none;
    }

    .placeholder {
        color: #a0a0a0;
    }

    .disabled {
        background: #222;
        pointer-events: none;
    }
</style>