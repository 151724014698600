<template>

    <main class="deq-reset deq-crowd-verification-page">
        <h1 class="title head">Crowd Verification</h1>


        <CrowdSettingsPayPerBrandTable/>
        <MachineSetup/>
        <CrowdHistory/>
    </main>

</template>

<script>
import CrowdHistory from "@/setup/crowd/CrowdHistory.vue";
import MachineSetup from "@/setup/crowd/MachineSetup.vue";
import CrowdSettingsPayPerBrandTable from "@/setup/crowd/crowd-settings/CrowdSettingsPayPerBrandTable.vue";


export default {
    name: "SetupCrowd",
    components: {CrowdSettingsPayPerBrandTable, MachineSetup, CrowdHistory},
}
</script>

<style scoped lang="sass">

</style>