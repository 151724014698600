import {adjustedWald} from "@/app/utils/Util";
import {formatDuration, initialiseRow} from "@/dashboards/widgets/fantasticchart/FantasticUtilities";
import {formatNumber, formatPercentage} from "@/app/utils/Format";
import {getMetatagsInAccount} from "@/app/utils/Metatags";
import {appendSegmentRestrictions} from "@/app/utils/Segments";
import {currentAccountCode} from "@/app/utils/Account";
import {deprecatedTagsStore} from "@/store/deprecated/Stores";
import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
import VuexStore from "@/store/vuex/VuexStore";

import {encloseInSingleQuotes} from "@/app/utils/StringUtils";
import {features} from "@/app/Features";
import {
    calculateCes5PointScoreFrom,
    calculateCsatScoreFrom, calculateFcrScoreFrom,
    calculateNpsScoreFrom, getCes5PointScoreFilter, getCsatScoreFilter, getFcrScoreFilter,
    getNetPromoterScoreFilter,
    getNpsDetractorsFilter,
    getNpsPassivesFilter,
    getNpsPromotersFilter, NPS_DETRACTOR_DESCRIPTION, NPS_PASSIVE_DESCRIPTION, NPS_PROMOTER_DESCRIPTION
} from "@/app/utils/Surveys";

export function setColOrRowVisibility(ids, list, visible) {
    ids.forEach(id => {
        let colOrRow = list.find(v => v.id === id);
        if (colOrRow) colOrRow.visible = visible;
    });
}

export function percent(a,b) { return b ? a * 100 / b : null }

export const COLS = [
    {
        id: 'mentionCount',
        name: "Volume"
    },
    {
        id: 'sentiment',
        name: "Sentiment",
        negColour: "#ED2637",
        posColour: "#00b0b9",
        noPercentageOption: true,
        isPercentage: true,
        description: "Net sentiment % with negative and positive bars",
        select: ["mentionCount", "sentimentVerifiedCount", "totalVerifiedNegative", "totalVerifiedPositive", "totalVerifiedSentiment"],
        get: data => {
            return {
                number: percent(data.totalVerifiedSentiment, data.sentimentVerifiedCount),
                pos: percent(data.totalVerifiedPositive, data.sentimentVerifiedCount),
                neg: -percent(data.totalVerifiedNegative, data.sentimentVerifiedCount),
                sortNumber: data.mentionCount,
                count: data.totalVerifiedSentiment,
                total: data.sentimentVerifiedCount
            }
        },
    },
    {
        id: 'netSentiment',
        name: "Net Sentiment",
        negColour: "#ED2637",
        posColour: "#00b0b9",
        noPercentageOption: true,
        isPercentage: true,
        select: ["mentionCount", "sentimentVerifiedCount", "totalVerifiedSentiment"],
        get: data => {
            let n = percent(data.totalVerifiedSentiment, data.sentimentVerifiedCount)
            return {
                number: n,
                pos: n >= 0 ? n : 0,
                neg: n < 0 ? n : 0,
                sortNumber: data.mentionCount,
                count: data.totalVerifiedSentiment,
                total: data.sentimentVerifiedCount
            }
        },
    },
    {
        id: 'negative',
        name: "Negative",
        colour: "#ED2637",
        noPercentageOption: true,
        isPercentage: true,
        barScale: "sentiment",
        description: "Negative mention %",
        select: ["mentionCount", "sentimentVerifiedCount", "totalVerifiedNegative"],
        get: data => {
            return {
                number: percent(data.totalVerifiedNegative, data.sentimentVerifiedCount),
                sortNumber: data.mentionCount,
                count: data.totalVerifiedNegative,
                total: data.sentimentVerifiedCount
            }
        },
        filter: "Sentiment <= -1"
    },
    {
        id: 'positive',
        name: "Positive",
        colour: "#00b0b9",
        noPercentageOption: true,
        isPercentage: true,
        barScale: "sentiment",
        description: "Positive mention %",
        select: ["mentionCount", "sentimentVerifiedCount", "totalVerifiedPositive"],
        get: data => {
            return {
                number: percent(data.totalVerifiedPositive, data.sentimentVerifiedCount),
                sortNumber: data.mentionCount,
                count: data.totalVerifiedPositive,
                total: data.sentimentVerifiedCount
            }
        },
        filter: "Sentiment >= 2"
    },
    {
        id: 'neutral',
        name: "Neutral",
        colour: "#c0c0c0",
        noPercentageOption: true,
        isPercentage: true,
        barScale: "sentiment",
        description: "Neutral mention %",
        select: ["mentionCount", "sentimentVerifiedCount", "totalVerifiedNeutral"],
        get: data => {
            return {
                number: percent(data.totalVerifiedNeutral, data.sentimentVerifiedCount),
                sortNumber: data.mentionCount,
                count: data.totalVerifiedNeutral,
                total: data.sentimentVerifiedCount
            }
        },
        filter: "Sentiment = 1"
    },
    {
        id: 'sentimentVerifiedCount',
        name: "Sentiment Sample",
        noPercentageOption: true,
        description: "Number of mentions verified to estimate sentiment"
    },
    {
        id: 'sentimentMOE',
        name: "Sentiment MOE",
        noPercentageOption: true,
        isPercentage: true,
        description: "Margin of error on net sentiment",
        select: ["mentionCount", "sentimentVerifiedCount", "totalVerifiedNegative", "totalVerifiedPositive"],
        get: data => {
            let moe = adjustedWald(
                data.sentimentVerifiedCount,
                data.totalVerifiedNegative + data.totalVerifiedPositive,
                data.mentionCount
            )
            return {
                number: percent(moe.moe, data.sentimentVerifiedCount),
                sortNumber: data.mentionCount,
                count: moe.moe,
                total: data.sentimentVerifiedCount
            }
        },
    },
    {
        id: 'negativeMOE',
        name: "Negative MOE",
        noPercentageOption: true,
        isPercentage: true,
        description: "Margin of error on negative sentiment",
        select: ["mentionCount", "sentimentVerifiedCount", "totalVerifiedNegative"],
        get: data => {
            let negMOE = adjustedWald(data.sentimentVerifiedCount, data.totalVerifiedNegative, data.mentionCount)
            return {
                number: percent(negMOE.moe, data.sentimentVerifiedCount),
                sortNumber: data.mentionCount,
                count: negMOE.moe,
                total: data.sentimentVerifiedCount
            }
        },
        filter: "Sentiment <= -1"
    },
    {
        id: 'positiveMOE',
        name: "Positive MOE",
        noPercentageOption: true,
        isPercentage: true,
        description: "Margin of error on positive sentiment",
        select: ["mentionCount", "sentimentVerifiedCount", "totalVerifiedPositive"],
        get: data => {
            let posMOE = adjustedWald(data.sentimentVerifiedCount, data.totalVerifiedPositive, data.mentionCount)
            return {
                number: percent(posMOE.moe, data.sentimentVerifiedCount),
                sortNumber: data.mentionCount,
                count: posMOE.moe,
                total: data.sentimentVerifiedCount
            }
        },
        filter: "Sentiment >= 2"
    },
    {
        id: 'neutralMOE',
        name: "Neutral MOE",
        noPercentageOption: true,
        isPercentage: true,
        description: "Margin of error on neutral sentiment",
        select: ["mentionCount", "sentimentVerifiedCount", "totalVerifiedNeutral"],
        get: data => {
            let moe = adjustedWald(data.sentimentVerifiedCount, data.totalVerifiedNeutral, data.mentionCount)
            return {
                number: percent(moe.moe, data.sentimentVerifiedCount),
                sortNumber: data.mentionCount,
                count: moe.moe,
                total: data.sentimentVerifiedCount
            }
        },
        filter: "Sentiment = 1"
    },
    {
        id: 'interactionCount',
        name: "Unique interactions",
        filter: "interactionId isnt unknown"
    },
    {
        id: 'interactionHasResponseCount',
        name: "Interactions replied to",
        filter: "interactionId isnt unknown AND InteractionHasResponse IS true"
    },
    {
        id: 'interactionHasNoResponseCount',
        name: "Interactions not replied to",
        filter: "interactionId isnt unknown AND InteractionHasResponse IS false",
        select: ["interactionCount", "interactionHasResponseCount"],
        get: data => {
            return {
                number: data.interactionCount - data.interactionHasResponseCount
            }
        }
    },
    {
        id: 'averageInteractionHasResponse',
        name: "Interaction response rate",
        noPercentageOption: true,
        isPercentage: true,
        get: data => {
            return {
                number: data.averageInteractionHasResponse * 100
            }
        },
        filter: "interactionId isnt unknown AND InteractionHasResponse IS true"
    },
    {
        id: 'averageInteractionResponseTime',
        name: "Interaction response time",
        noPercentageOption: true,
        get: data => {
            return {
                number: data.averageInteractionResponseTime
            }
        },
        formatter: (v,r,c,a,forCsv) => forCsv ? `${v.number}` : formatDuration(v.number),
        filter: "interactionId isnt unknown AND InteractionHasResponse IS true"
    },
    {
        id: 'averageInteractionFirstResponseTime',
        name: "First response time",
        noPercentageOption: true,
        get: data => {
            return {
                number: data.averageInteractionFirstResponseTime
            }
        },
        formatter: (v,r,c,a,forCsv) => forCsv ? `${v.number}` : formatDuration(v.number),
        filter: "interactionId isnt unknown AND InteractionHasResponse IS true AND interactionFirstResponseTime > 0"
    },
    {
        id: 'averageInteractionFollowupResponseTime',
        name: "Follow-up response time",
        noPercentageOption: true,
        get: data => {
            return {
                number: data.averageInteractionFollowupResponseTime
            }
        },
        formatter: (v,r,c,a,forCsv) => forCsv ? `${v.number}` : formatDuration(v.number),
        filter: "interactionId isnt unknown AND InteractionHasResponse IS true AND InteractionFollowUpResponseTime > 0"
    },
    {
        id: 'averageInteractionWhResponseTime',
        name: "Interaction response time (working hours)",
        noPercentageOption: true,
        get: data => {
            return {
                number: data.averageInteractionWhResponseTime
            }
        },
        formatter: (v,r,c,a,forCsv) => forCsv ? `${v.number}` : formatDuration(v.number),
        filter: "interactionId isnt unknown AND InteractionHasResponse IS true AND InteractionWhResponseTime > 0"
    },
    {
        id: 'averageInteractionWhFirstResponseTime',
        name: "First response time (working hours)",
        noPercentageOption: true,
        get: data => {
            return {
                number: data.averageInteractionWhFirstResponseTime
            }
        },
        formatter: (v,r,c,a,forCsv) => forCsv ? `${v.number}` : formatDuration(v.number),
        filter: "interactionId isnt unknown AND InteractionHasResponse IS true AND interactionWhFirstResponseTime > 0"
    },
    {
        id: 'averageInteractionWhFollowupResponseTime',
        name: "Follow-up response time (working hours)",
        noPercentageOption: true,
        get: data => {
            return {
                number: data.averageInteractionWhFollowupResponseTime
            }
        },
        formatter: (v,r,c,a,forCsv) => forCsv ? `${v.number}` : formatDuration(v.number),
        filter: "interactionId isnt unknown AND InteractionHasResponse IS true AND InteractionWhFollowUpResponseTime > 0"
    },
    {
        id: 'note',
        name: "Note",
        description: "Explanatory text",
        select: [],
        formatter: (v, row, col, attrs) => {
            let note = attrs.notes ? attrs.notes[row.id + ":" + col.id] : null
            return note ? note.text : ''
        },
        quoteInCSV: true
    },
    {
        id: 'totalEngagement',
        name: "Engagement",
        description: "Total reshares and replies"
    },
    {
        id: 'totalReplyCount',
        name: "Replies",
        filter: "ReplyCount > 0"
    },
    {
        id: 'totalReshareCount',
        name: "Reshares",
        filter: "ReshareCount > 0"
    },
    {
        id: 'engagementRate',
        name: "Engagement Rate",
        description: "Engagement / mention count",
        select: ["mentionCount", "totalEngagement"],
        get: data => {
            return {
                number: data.mentionCount ? data.totalEngagement / data.mentionCount : null,
                sortNumber: data.mentionCount
            }
        },
        formatter: v => formatNumber(v.number, 1)
    },
    {
        id: 'maxFollowerCount',
        name: "Followers",
        description: "Max followers for any author in the dataset"
    },
    {
        id: 'engRatePerFollower',
        name: "Eng Rate / Follower",
        description: "Engagement / mention count / followers %",
        select: ["mentionCount", "totalEngagement", "maxFollowerCount"],
        get: data => {
            return {
                number: data.mentionCount && data.maxFollowerCount ? (data.totalEngagement / data.mentionCount) * 100 / data.maxFollowerCount : null,
                sortNumber: data.mentionCount
            }
        },
        formatter: v => formatPercentage(v.number, 3)
    },
    {
        id: 'totalOTS',
        name: "OTS"
    },
    {
        id: 'authorIdCount',
        name: "Authors"
    },
    {
        id: 'conversationIdCount',
        name: "Conversations"
    },
    {
        id: 'siteCount',
        name: "Sites"
    },
    {
        id: 'languageCount',
        name: "Languages"
    },
    {
        id: 'countryCount',
        name: "Countries"
    },
    {
        id: 'ticketIdCount',
        name: "Engage Tickets"
    },
    {
        id: 'averageFirstReplyTime',
        name: "First reply time",
        visible: false,
        formatter: v => formatDuration(v.number),
    },
    {
        id: 'averageHasReply',
        name: "Response rate",
        noPercentageOption: true,
        isPercentage: true,
        visible: false,
        get: data => {
            return {
                number: data.averageHasReply * 100
            }
        },
    },
    {
        id: 'totalHasReply', // todo only brand support profiles
        name: "Mentions replied to",
        visible: false
    },
    {
        id: 'npsScore',
        name: 'NPS Score',
        select: [],
        get: d => {
            return {number: d.npsScore}
        },
        filter: `${getNetPromoterScoreFilter()} OR ${getNpsDetractorsFilter()} OR ${getNpsPassivesFilter()}`,
        getData: async (params, fromGrouse, sortCol, row) => {
            const [netPromoterScoreData, npsPromoterData, npsDetractorData] = await Promise.all(
                [
                    fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", {...params, filter: appendFiltersReadably(params.filter, getNetPromoterScoreFilter())}),
                    fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", {...params, filter: appendFiltersReadably(params.filter, getNpsPromotersFilter())}),
                    fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", {...params, filter: appendFiltersReadably(params.filter, getNpsDetractorsFilter())}),
                ]
            )

            const result = [];
            const groupKey = params.groupBy;

            if (!groupKey) {
                const npsScore = Math.round(calculateNpsScoreFrom(netPromoterScoreData.mentionCount, npsDetractorData.mentionCount, npsPromoterData.mentionCount) * 100)
                return {npsScore: npsScore};
            }
            for (const entry of netPromoterScoreData) {

                const npsPromoterDataEntry = npsPromoterData.find(it =>
                    getGroupKey(it[groupKey]) === getGroupKey(entry[groupKey])
                )?.mentionCount ?? 0;

                const npsDetractorDataEntry = npsDetractorData.find(it =>
                    getGroupKey(it[groupKey]) === getGroupKey(entry[groupKey])
                )?.mentionCount ?? 0;

                const npsScore = Math.round(calculateNpsScoreFrom(entry.mentionCount, npsDetractorDataEntry, npsPromoterDataEntry) * 100)
                result.push({...entry, npsScore: npsScore})
            }
            return result;

        }
    },
    {
        id: 'csatScore',
        name: 'CSAT Score',
        select: [],
        get: d => {
            return {number: d.csatScore}
        },
        filter: `${getCsatScoreFilter()} OR segment is 167452 or segment is 167453`,
        getData: async (params, fromGrouse, sortCol, row) => {
            const [totalCsatData, totalFourAndFiveData] = await Promise.all(
                [
                    fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", {...params, filter: appendFiltersReadably(params.filter, getCsatScoreFilter())}),
                    fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", {...params, filter: appendFiltersReadably(params.filter, appendFiltersReadably("segment is 167452 or segment is 167453", getCsatScoreFilter()))})
                ]
            )

            const result = [];
            const groupKey = params.groupBy;

            if (!groupKey){
                const csatScore = Math.round(calculateCsatScoreFrom(totalCsatData.mentionCount, totalFourAndFiveData.mentionCount) * 100);
                return {csatScore: csatScore};
            }
            for (const entry of totalCsatData) {

                const totalFourAndFiveDataEntry = totalFourAndFiveData.find(it =>
                    getGroupKey(it[groupKey]) === getGroupKey(entry[groupKey])
                )?.mentionCount ?? 0;

                const csatScore = Math.round(calculateCsatScoreFrom(entry.mentionCount, totalFourAndFiveDataEntry) * 100);
                result.push({...entry, csatScore: csatScore})
            }
            return result;
        }
    },
    {
        id: 'cesScore',
        name: 'CES Score',
        select: [],
        get: d => {
            return {number: d.cesScore}
        },
        filter: `${getCes5PointScoreFilter()} OR segment is 196492 OR segment is 196493`,
        getData: async (params, fromGrouse, sortCol, row) => {
            const [totalCesData, stronglyAgreeData, agreeData] = await Promise.all(
                [
                    fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", {...params, filter: appendFiltersReadably(params.filter, getCes5PointScoreFilter())}),
                    fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", {...params, filter: appendFiltersReadably(params.filter, appendFiltersReadably(getCes5PointScoreFilter(), "segment is 196492"))}),
                    fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", {...params, filter: appendFiltersReadably(params.filter, appendFiltersReadably(getCes5PointScoreFilter(), "segment is 196493"))})
                ]
            )

            const result = [];
            const groupKey = params.groupBy;

            if (!groupKey) {
                const cesScore = Math.round(calculateCes5PointScoreFrom(totalCesData.mentionCount, agreeData.mentionCount, stronglyAgreeData.mentionCount) * 100);
                return {cesScore: cesScore};
            }
            for (const entry of totalCesData) {

                const stronglyAgreeDataEntry = stronglyAgreeData.find(it =>
                    getGroupKey(it[groupKey]) === getGroupKey(entry[groupKey])
                )?.mentionCount ?? 0;

                const agreeDataEntry = agreeData.find(it =>
                    getGroupKey(it[groupKey]) === getGroupKey(entry[groupKey])
                )?.mentionCount ?? 0;

                const cesScore = Math.round(calculateCes5PointScoreFrom(entry.mentionCount, agreeDataEntry, stronglyAgreeDataEntry) * 100);
                result.push({...entry, cesScore: cesScore});
            }

            return result;
        }
    },
    {
        id: 'fcrScore',
        name: 'FCR Score',
        select: [],
        get: d => {
            return {number: d.fcrScore}
        },
        filter: `${getCes5PointScoreFilter()} OR segment is 194053`,
        getData: async (params, fromGrouse, sortCol, row) => {
            const [totalFcrData, totalYesData] = await Promise.all(
                [
                    fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", {...params, filter: appendFiltersReadably(params.filter, getFcrScoreFilter())}),
                    fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", {...params, filter: appendFiltersReadably(params.filter, appendFiltersReadably("segment is 194053", getFcrScoreFilter()))}),
                ]
            )

            const result = [];
            const groupKey = params.groupBy;
            if (!groupKey) {
                const fcrScore = Math.round(calculateFcrScoreFrom(totalFcrData.mentionCount, totalYesData.mentionCount) * 100);
                return {fcrScore: fcrScore};
            }
            for (const entry of totalFcrData) {

                const totalYesDataEntry = totalYesData.find(it =>
                    getGroupKey(it[groupKey]) === getGroupKey(entry[groupKey])
                )?.mentionCount ?? 0;

                const fcrScore = Math.round(calculateFcrScoreFrom(entry.mentionCount, totalYesDataEntry) * 100);
                result.push({...entry, fcrScore: fcrScore})
            }
            return result;
        }
    }
]

export const COLS_BY_ID =  { }

COLS.forEach(c => {
    COLS_BY_ID[c.id] = c
    if (!c.get) c.get = data => { return {
        number: data[c.id],
        sortNumber: data[c.id]
    } }
    if (c.select === undefined) c.select = [c.id]
})

const SOCIAL_NETWORK_COLOURS = {
    TWITTER: "#000",
    FACEBOOK: "#3c5a99",
    YOUTUBE: "#FF0000",
    INSTAGRAM: "#C13584",
    LINKEDIN: "#0077B5",
    TELEGRAM: "#0088cc",
    VK: "#5181b8"
}

function getSocialNetworkFilter(value) {
    let filter = "";
    value = value.toLowerCase();

    switch (value) {
        case "twitter":
        case "facebook":
        case "youtube":
        case "instagram":
            filter = `Link CONTAINS '${value}.com'`
            break;
        case "un":
            filter = "(Link DOESNTCONTAIN 'twitter.com' AND Link DOESNTCONTAIN 'facebook.com' " +
                "AND Link DOESNTCONTAIN 'youtube.com' AND Link DOESNTCONTAIN 'instagram.com' AND Link DOESNTCONTAIN 't.me' " +
                "AND Link DOESNTCONTAIN 'http://media-cache.brandseye.com' AND Link DOESNTCONTAIN 'pressdisplay.com' " +
                "AND Link DOESNTCONTAIN 'video-cache.brandseye.com' AND Link DOESNTCONTAIN 'media-player.brandseye.com')";
            break;
        default:
            filter = `Link CONTAINS '${value}'`;
            break;
    }

    return filter;
}

export const SLA_RESPONSE_TIME_OPTIONS = [];
function populateSlaResponseTimeOptions() {
    for (let i = 1; i <= 24; i++) {
        SLA_RESPONSE_TIME_OPTIONS.push({
            id: i,
            name: `${i} ${i === 1 ? 'hour' : 'hours'}`
        });
    }
}
populateSlaResponseTimeOptions();

export const GROUPBY = [
    {
        id: "rpcs",
        groupBy: [],
        name: "Priority conversation",
        tagNamespace: ['segment', 'BrandsEye'],
        get: data => {
            return data.rpcs
        },
        getData: async (options, fromGrouse) => {
            let calls = [];
            let rowData = [];
            let metatags = getMetatagsInAccount();

            if (metatags.length === 0) {
                throw new Error("No meta tags were found for this account");
            }

            let filter = options.filter;
            filter = appendSegmentRestrictions(filter);

            metatags.forEach(metatag => {
                let params = {...options};
                params.filter = "(" + filter + ") and tag is " + metatag.id;

                let call = fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", params)
                    .then(response => {
                        let data = response;

                        if (data instanceof Array) {
                            data.forEach(d => {
                                d.rpcs = metatag;

                                rowData.push(d);
                            })
                        } else {
                            data.rpcs = metatag;
                            rowData.push(data);
                        }
                    });
                calls.push(call);
            });

            await Promise.all(calls)

            rowData.sort((a,b) => {
                return a.rpcs.id - b.rpcs.id;
            })

            return rowData;
        },
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.label,
                colour: v.colour,
                filter: appendFiltersReadably(filter, "tag is " + v.id)
            }
        }
    },
    {
        id: "interactionResponseTimeBreakdown",
        groupBy: [],
        noMax: true,
        name: "Interaction response time break-down",
        get: data => {
            return data.interactionResponseTimeBreakdown
        },
        getData: async (options, fromGrouse, sortCol, row, firstCol) => {
            let calls = [];
            let rowData = [];
            let slaResponseTimeHours = row.groupBy === "interactionResponseTimeBreakdown" ? row.slaResponseTime : firstCol.slaResponseTime;

            if (!slaResponseTimeHours) slaResponseTimeHours = 2;

            let timeFilters = [
                { label: "Response time: over 24h", filter: "InteractionResponseTime > 86400", order: 1 }, // over 24 hours
                { label: "Response time: 12h - 24h", filter: "InteractionResponseTime > 43200 AND InteractionResponseTime < 86400", order: 2 },
                { label: "Response time: 6h - 12h", filter: "InteractionResponseTime > 21600 AND InteractionResponseTime < 43200", order: 3 },
                { label: "Response time: 2h - 6h", filter: "InteractionResponseTime > 7200 AND InteractionResponseTime < 21600", order: 4 },
                { label: "Response time: 1h - 2h", filter: "InteractionResponseTime > 3600 AND InteractionResponseTime < 7200", order: 5 },
                { label: "Response time: 30m - 1h", filter: "InteractionResponseTime > 1800 AND InteractionResponseTime < 3600", order: 6 },
                { label: "Response time: under 30m", filter: "InteractionResponseTime < 1800", order: 7 }, // under 30 minutes
                { label: `SLA response time (under ${slaResponseTimeHours}h)`, filter: `InteractionResponseTime < ${slaResponseTimeHours * 60 * 60}`, ignoreForTotal: true, order: 8 }
            ]

            timeFilters.forEach(timeFilter => {
                let params = {...options};
                params.filter = appendFiltersReadably(options.filter, timeFilter.filter);

                let call = fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", params)
                    .then(response => {
                        let data = response;

                        // we can get back an array of data if we groupBy a particular field, cater for this
                        if (data instanceof Array) {
                            data.forEach(d => {
                                d.interactionResponseTimeBreakdown = {
                                    id: timeFilter.order,
                                    label: timeFilter.label,
                                    filter: timeFilter.filter,
                                    ignoreForTotal: timeFilter.ignoreForTotal
                                };
                                d.order = timeFilter.order;
                                rowData.push(d);
                            });
                        } else {
                            data.interactionResponseTimeBreakdown = {
                                id: timeFilter.order,
                                label: timeFilter.label,
                                filter: timeFilter.filter,
                                ignoreForTotal: timeFilter.ignoreForTotal
                            };
                            data.order = timeFilter.order;
                            rowData.push(data);
                        }
                    });
                calls.push(call);
            });

            await Promise.all(calls);

            rowData.sort((a,b) => {
                return a.order - b.order;
            });

            return rowData;
        },
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.label,
                filter: appendFiltersReadably(filter, v.filter),
                ignoreForTotal: v.ignoreForTotal
            }
        }
    },
    {
        id: "interactionResponseTimeWhBreakdown",
        groupBy: [],
        noMax: true,
        name: "Interaction response time break-down (working hours)",
        get: data => {
            return data.interactionResponseTimeWhBreakdown
        },
        getData: async (options, fromGrouse, sortCol, row, firstCol) => {
            let calls = [];
            let rowData = [];
            let slaResponseTimeHours = row.groupBy === "interactionResponseTimeWhBreakdown" ? row.slaResponseTime : firstCol.slaResponseTime;

            if (!slaResponseTimeHours) slaResponseTimeHours = 2;

            let timeFilters = [
                { label: "Response time (working hours): over 24h", filter: "InteractionWhResponseTime > 86400", order: 1 }, // over 24 hours
                { label: "Response time (working hours): 12h - 24h", filter: "InteractionWhResponseTime > 43200 AND InteractionWhResponseTime < 86400", order: 2 },
                { label: "Response time (working hours): 6h - 12h", filter: "InteractionWhResponseTime > 21600 AND InteractionWhResponseTime < 43200", order: 3 },
                { label: "Response time (working hours): 2h - 6h", filter: "InteractionWhResponseTime > 7200 AND InteractionWhResponseTime < 21600", order: 4 },
                { label: "Response time (working hours): 1h - 2h", filter: "InteractionWhResponseTime > 3600 AND InteractionWhResponseTime < 7200", order: 5 },
                { label: "Response time (working hours): 30m - 1h", filter: "InteractionWhResponseTime > 1800 AND InteractionWhResponseTime < 3600", order: 6 },
                { label: "Response time (working hours): under 30m", filter: "InteractionWhResponseTime < 1800", order: 7 }, // under 30 minutes
                { label: `SLA response time (working hours) (under ${slaResponseTimeHours}h)`, filter: `InteractionWhResponseTime < ${slaResponseTimeHours * 60 * 60}`, ignoreForTotal: true, order: 8 }
            ]

            timeFilters.forEach(timeFilter => {
                let params = {...options};
                params.filter = appendFiltersReadably(options.filter, timeFilter.filter);

                let call = fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", params)
                    .then(response => {
                        let data = response;

                        // we can get back an array of data if we groupBy a particular field, cater for this
                        if (data instanceof Array) {
                            data.forEach(d => {
                                d.interactionResponseTimeWhBreakdown = {
                                    id: timeFilter.order,
                                    label: timeFilter.label,
                                    filter: timeFilter.filter,
                                    ignoreForTotal: timeFilter.ignoreForTotal
                                };
                                d.order = timeFilter.order;
                                rowData.push(d);
                            });
                        } else {
                            data.interactionResponseTimeWhBreakdown = {
                                id: timeFilter.order,
                                label: timeFilter.label,
                                filter: timeFilter.filter,
                                ignoreForTotal: timeFilter.ignoreForTotal
                            };
                            data.order = timeFilter.order;
                            rowData.push(data);
                        }
                    });
                calls.push(call);
            });

            await Promise.all(calls);

            rowData.sort((a,b) => {
                return a.order - b.order;
            });

            return rowData;
        },
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.label,
                filter: appendFiltersReadably(filter, v.filter),
                ignoreForTotal: v.ignoreForTotal
            }
        }
    },
    {
        id: "firstResponseTimeBreakdown",
        groupBy: [],
        noMax: true,
        name: "First response time break-down",
        get: data => {
            return data.firstResponseTimeBreakdown
        },
        getData: async (options, fromGrouse, sortCol, row, firstCol) => {
            let calls = [];
            let rowData = [];
            let slaResponseTimeHours = row.groupBy === "firstResponseTimeBreakdown" ? row.slaResponseTime : firstCol.slaResponseTime;

            if (!slaResponseTimeHours) slaResponseTimeHours = 2;

            let timeFilters = [
                { label: "First response time: over 24h", filter: "InteractionFirstResponseTime > 86400", order: 1 }, // over 24 hours
                { label: "First response time: 12h - 24h", filter: "InteractionFirstResponseTime > 43200 AND InteractionFirstResponseTime < 86400", order: 2 },
                { label: "First response time: 6h - 12h", filter: "InteractionFirstResponseTime > 21600 AND InteractionFirstResponseTime < 43200", order: 3 },
                { label: "First response time: 2h - 6h", filter: "InteractionFirstResponseTime > 7200 AND InteractionFirstResponseTime < 21600", order: 4 },
                { label: "First response time: 1h - 2h", filter: "InteractionFirstResponseTime > 3600 AND InteractionFirstResponseTime < 7200", order: 5 },
                { label: "First response time: 30m - 1h", filter: "InteractionFirstResponseTime > 1800 AND InteractionFirstResponseTime < 3600", order: 6 },
                { label: "First response time: under 30m", filter: "InteractionFirstResponseTime < 1800", order: 7 }, // under 30 minutes
                { label: `SLA response time (under ${slaResponseTimeHours}h)`, filter: `InteractionFirstResponseTime < ${slaResponseTimeHours * 60 * 60}`, ignoreForTotal: true, order: 8 }
            ]

            timeFilters.forEach(timeFilter => {
                let params = {...options};
                params.filter = appendFiltersReadably(options.filter, timeFilter.filter);

                let call = fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", params)
                    .then(response => {
                        let data = response;

                        // we can get back an array of data if we groupBy a particular field, cater for this
                        if (data instanceof Array) {
                            data.forEach(d => {
                                d.firstResponseTimeBreakdown = {
                                    id: timeFilter.order,
                                    label: timeFilter.label,
                                    filter: timeFilter.filter,
                                    ignoreForTotal: timeFilter.ignoreForTotal
                                };
                                d.order = timeFilter.order;
                                rowData.push(d);
                            });
                        } else {
                            data.firstResponseTimeBreakdown = {
                                id: timeFilter.order,
                                label: timeFilter.label,
                                filter: timeFilter.filter,
                                ignoreForTotal: timeFilter.ignoreForTotal
                            };
                            data.order = timeFilter.order;
                            rowData.push(data);
                        }
                    });
                calls.push(call);
            });

            await Promise.all(calls);

            rowData.sort((a,b) => {
                return a.order - b.order;
            });

            return rowData;
        },
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.label,
                filter: appendFiltersReadably(filter, v.filter),
                ignoreForTotal: v.ignoreForTotal
            }
        }
    },
    {
        id: "firstResponseTimeWhBreakdown",
        groupBy: [],
        noMax: true,
        name: "First response time break-down (working hours)",
        get: data => {
            return data.firstResponseTimeWhBreakdown
        },
        getData: async (options, fromGrouse, sortCol, row, firstCol) => {
            let calls = [];
            let rowData = [];
            let slaResponseTimeHours = row.groupBy === "firstResponseTimeWhBreakdown" ? row.slaResponseTime : firstCol.slaResponseTime;

            if (!slaResponseTimeHours) slaResponseTimeHours = 2;

            let timeFilters = [
                { label: "First response time (working hours): over 24h", filter: "InteractionWhFirstResponseTime > 86400", order: 1 }, // over 24 hours
                { label: "First response time (working hours): 12h - 24h", filter: "InteractionWhFirstResponseTime > 43200 AND InteractionWhFirstResponseTime < 86400", order: 2 },
                { label: "First response time (working hours): 6h - 12h", filter: "InteractionWhFirstResponseTime > 21600 AND InteractionWhFirstResponseTime < 43200", order: 3 },
                { label: "First response time (working hours): 2h - 6h", filter: "InteractionWhFirstResponseTime > 7200 AND InteractionWhFirstResponseTime < 21600", order: 4 },
                { label: "First response time (working hours): 1h - 2h", filter: "InteractionWhFirstResponseTime > 3600 AND InteractionWhFirstResponseTime < 7200", order: 5 },
                { label: "First response time (working hours): 30m - 1h", filter: "InteractionWhFirstResponseTime > 1800 AND InteractionWhFirstResponseTime < 3600", order: 6 },
                { label: "First response time (working hours): under 30m", filter: "InteractionWhFirstResponseTime < 1800", order: 7 }, // under 30 minutes
                { label: `SLA response time (working hours) (under ${slaResponseTimeHours}h)`, filter: `InteractionWhFirstResponseTime < ${slaResponseTimeHours * 60 * 60}`, ignoreForTotal: true, order: 8 }
            ]

            timeFilters.forEach(timeFilter => {
                let params = {...options};
                params.filter = appendFiltersReadably(options.filter, timeFilter.filter);

                let call = fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", params)
                    .then(response => {
                        let data = response;

                        // we can get back an array of data if we groupBy a particular field, cater for this
                        if (data instanceof Array) {
                            data.forEach(d => {
                                d.firstResponseTimeWhBreakdown = {
                                    id: timeFilter.order,
                                    label: timeFilter.label,
                                    filter: timeFilter.filter,
                                    ignoreForTotal: timeFilter.ignoreForTotal
                                };
                                d.order = timeFilter.order;
                                rowData.push(d);
                            });
                        } else {
                            data.firstResponseTimeWhBreakdown = {
                                id: timeFilter.order,
                                label: timeFilter.label,
                                filter: timeFilter.filter,
                                ignoreForTotal: timeFilter.ignoreForTotal
                            };
                            data.order = timeFilter.order;
                            rowData.push(data);
                        }
                    });
                calls.push(call);
            });

            await Promise.all(calls);

            rowData.sort((a,b) => {
                return a.order - b.order;
            });

            return rowData;
        },
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.label,
                filter: appendFiltersReadably(filter, v.filter),
                ignoreForTotal: v.ignoreForTotal
            }
        }
    },
    {
        id: 'brand',
        name: "Brands",
        canSortByFirstCol: true,        // brands alpha sorted by default so allow the override option
        createLabel: (v, filter) => {   // create a row or column label from data from grouse
            return {
                id: v.id,
                name: v.shortName || v.fullName,
                title: v.fullName,
                colour: v.colour,
                filter: appendFiltersReadably(filter, "Brand isorchildof " + v.id)
            }
        }
    },
    {
        id: 'language',
        name: "Languages",
        canSortByName: true,
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.name,
                filter: appendFiltersReadably(filter, "Language is " + (v.id === "un" ? "unknown" : "'" + v.id + "'"))
            }
        }
    },
    {
        id: 'topic',
        name: "Topics",
        groupBy: ['tag'],
        tagNamespace: ['topic'],
        requiresTopicView: true,
        canSortByName: true,
        isTag: true,
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.name,
                labels: v.labels,
                filter: appendFiltersReadably(filter, "Topic is " + v.id),
                tagId: v.id
            }
        },
        valuePredicate: (v,topicViewId) => v.flag !== 'NONE_OF_THE_ABOVE' && // don't want 'No Topics'
            (v.leaf || v.namespace === 'topic_tree' || v.id === topicViewId) // don't want parent topics
    },
    {
        id: 'parentTopic',
        name: "Parent Topics",
        groupBy: ['tag'],
        tagNamespace: ['topic'],
        requiresTopicView: true,
        canSortByName: true,
        isTag: true,
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.name,
                labels: v.labels,
                filter: appendFiltersReadably(filter, "Topic is " + v.id),
                tagId: v.id
            }
        },
        valuePredicate: v => !v.leaf    // we don't want child topics
    },
    {
        id: 'segment',  // this get expanded into an option for each seg list in the a/c by getAvailableGroupByOptions
        name: "Segments",
        groupBy: ['tag'],
        tagNamespace: ['segment', 'segment_list'],
        isTag: true,
        canSortByName: true,
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.name,
                labels: v.labels,
                filter: appendFiltersReadably(filter, "Segment is " + v.id),
                tagId: v.id
            }
        },
        valuePredicate: (v, segmentListId) => {
            // only keep our seg list and its children .. this stops other random tags from showing up on the table
            // because they were included in the filter e.g. to do intersection of a segment with a tag
            if (segmentListId === v.id) return true
            let tag = deprecatedTagsStore.byId[segmentListId]
            return !tag || tag.children.indexOf(v.id) >= 0
        }
    },
    {
        id: 'engageusertag',
        name: "Engage user tags",
        groupBy: ['tag'],
        tagNamespace: ['engageusertags'],
        canSortByName: true,
        isTag: true,
        getData: async (options, fromGrouse, sortCol) => {
            await VuexStore.dispatch('refreshTags');
            let tags = VuexStore.state.tags;

            let engageUserTags = [];
            let engageUserTagPartitions = [];
            const PARTITION_SIZE = 50;

            for (const tag of tags) {
                if (tag.namespace === "engageusertags") {
                    engageUserTags.push(tag);
                }
            }

            for (let i = 0; i < engageUserTags.length; i += PARTITION_SIZE) {
                const curPartition = engageUserTags.slice(i, i + PARTITION_SIZE);
                engageUserTagPartitions.push(curPartition);
            }

            let data = [];
            let baseFilter = options.filter;
            let requests = [];

            for (const filterPartition of engageUserTagPartitions) {
                let engageTagsFilter = `Tag IS ${filterPartition.map(t => t.id).join(" OR TAG IS ")}`;
                let params = {...options};
                params.filter = `(${baseFilter}) and (${engageTagsFilter})`;

                requests.push(fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", params));
            }

            let results = await Promise.all(requests);
            results.forEach(result => {
                data = [...data, ...result];
            });

            if (sortCol) {
                let sortField = sortCol.type;
                data.sort((a,b) => {
                    return b[sortField] - a[sortField]
                });
            }

            return data;
        },
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.name,
                labels: v.labels,
                filter: appendFiltersReadably(filter, "Tag is " + v.id),
                tagId: v.id
            }
        }
    },
    {
        id: 'authorId',
        name: "Authors",
        groupBy: ['authorId','authorName','authorHandle','site'],
        limit: 3000,
        get: data => {
            let { authorId, authorHandle, authorName, site } = data
            let o = { authorId, authorHandle, authorName, site }
            o.id = authorId || (site + "-" + authorHandle + "-" + authorName)
            switch (site) {
                case "twitter.com":
                    o.name = "@" + authorHandle;
                    break
                case "www.facebook.com":
                    o.name = o.authorName || "Anon. FB authors"
                    break
                case "instagram.com":
                    o.name = o.authorName || "Anon. IG authors"
                    break
                default:
                    o.name = authorName || authorHandle || site
            }
            return o
        },
        createLabel: (v, filter) => {
            let f
            if (v.authorId) f = "AuthorId IS '" + v.authorId + "'"
            else if (v.authorName) f = "AuthorName IS " + encloseInSingleQuotes(v.authorName)
            return {
                id: v.id,
                name: v.name,
                filter: f ? appendFiltersReadably(filter, f) : null
            }
        }
    },
    {
        id: 'gender',
        name: "Gender",
        canSortByName: true,
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.label || v.name,
                filter: appendFiltersReadably(filter, "Gender is " + (v.id === "un" ? "unknown" : "'" + v.id + "'"))
            }
        }
    },
    {
        id: 'category',
        name: "Media Category",
        canSortByName: true,
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.label || v.name,
                filter: appendFiltersReadably(filter, "Media is " + (v.id === "un" ? "unknown" : v.id))
            }
        }
    },
    {
        id: 'tag',
        name: "Tags",
        canSortByName: true,
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.name,
                labels: v.labels,
                filter: appendFiltersReadably(filter, "Tag is " + v.id)
            }
        }
    },
    {
        id: 'country',
        name: "Countries",
        canSortByName: true,
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.name,
                filter: appendFiltersReadably(filter, "Location is '" + v.id + "'")
            }
        }
    },
    {
        id: 'region',
        name: "Regions",
        canSortByName: true,
        select: ['region[id,name,asciiName,country]'],
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.name,
                filter: appendFiltersReadably(filter, "Location is '" + (v.id === "un" ? "UN" : (v.country.id + "," + v.asciiName)) + "'")
            }
        }
    },
    {
        id: 'city',
        name: "Cities",
        canSortByName: true,
        createLabel: (v, filter) => {
            let region = v.region || { }
            return {
                id: v.id,
                name: v.name,
                filter: appendFiltersReadably(filter, "Location is '" +
                    (v.id === "un" ? "UN" : (v.country.id + "," + region.asciiName + "," + v.asciiName)) + "'")
            }
        }
    },
    {
        id: 'extractWord',
        name: "Words",
        limit: 3000,
        createLabel: (v, filter) => {
            return {
                id: v.id || v,
                name: v.name || v,
                filter: appendFiltersReadably(filter, "Content matches '\"" + (v.id || v) + "\"'")
            }
        }
    },
    {
        id: 'hashtags',
        name: "#hashtags",
        groupBy: ['extractWord'],
        limit: 3000,
        wordsLike: "#%",
        createLabel: (v, filter) => {
            return {
                id: v.id || v,
                name: v.name || v,
                filter: appendFiltersReadably(filter, "Content matches '\"" + (v.id || v) + "\"'")
            }
        }
    },
    {
        id: 'handles',
        name: "@handles",
        groupBy: ['extractWord'],
        limit: 3000,
        wordsLike: "@%",
        createLabel: (v, filter) => {
            return {
                id: v.id || v,
                name: v.name || v,
                filter: appendFiltersReadably(filter, "Content matches '\"" + (v.id || v) + "\"'")
            }
        }
    },
    {
        id: 'authorBioWord',
        name: "Author bio words",
        limit: 3000,
        createLabel: (v, filter) => {
            return {
                id: v.id || v,
                name: v.name || v,
                filter: appendFiltersReadably(filter, "AuthorBio matches '\"" + (v.id || v) + "\"'")
            }
        }
    },
    {
        id: 'site',
        name: "Sources",
        limit: 3000,
        createLabel: (v, filter) => {
            return {
                id: v.id || v,
                name: v.name || v,
                filter: appendFiltersReadably(filter, "Link contains '\"" + (v.id || v) + "\"'")
            }
        }
    },
    {
        id: 'socialNetwork',
        name: "Social networks",
        limit: 3000,
        unknown: { id: "un", name: "Websites" },
        createLabel: (v, filter) => {
            let name = v.label || v.name;
            return {
                id: v.id,
                name,
                colour: SOCIAL_NETWORK_COLOURS[v.id],
                filter: appendFiltersReadably(filter, getSocialNetworkFilter(v.id))
            }
        }
    },
    {
        id: 'fiveStarRating',
        name: "Five Star Rating",
        groupBy: ['tag'],
        tagNamespace: ['segment'],
        isTag: true,
        limit: 3000,
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.name || v,
                filter: appendFiltersReadably(filter, "Tag is " + v.id)
            }
        },
        getData: async (options, fromGrouse, sortCol) => {
            const fiveStarTagIds = [
                { id: 167449, rating: 1},
                {id: 167450, rating: 2},
                {id: 167451, rating: 3},
                {id: 167452, rating: 4},
                {id: 167453, rating: 5}
        ]

            let data = [];
            let baseFilter = options.filter;
            let requests = [];

            let fiveStarTagFilter = `SEGMENT IS ${fiveStarTagIds.map(t => t.id).join(" OR SEGMENT IS ")}`;
            let params = {...options};
            params.filter = `(${baseFilter}) and (${fiveStarTagFilter})`;

            requests.push(fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", params));

            let results = await Promise.all(requests);
            results.forEach(result => {
                data = [...data, ...result];
            });

            if (sortCol) {
                let sortField = sortCol.type;
                data.sort((a,b) => {
                    return b[sortField] - a[sortField]
                });
            } else {
                data.sort((a,b) => {
                   return a.tag.id - b.tag.id;
                })
            }
            return data;
        },
    },
    {
        id: 'zeroToTenStarRating',
        name: "Zero to Ten Star Rating",
        groupBy: ['tag'],
        tagNamespace: ['segment'],
        isTag: true,
        limit: 3000,
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.name || v,
                filter: appendFiltersReadably(filter, "Tag is " + v.id)
            }
        },
        getData: async (options, fromGrouse, sortCol) => {
            const zeroToTenStarTagIds = [
                { id: 194040, rating: 0 },
                { id: 194041, rating: 1 },
                { id: 194042, rating: 2 },
                { id: 194043, rating: 3 },
                { id: 194044, rating: 4 },
                { id: 194045, rating: 5 },
                { id: 194046, rating: 6 },
                { id: 194047, rating: 7 },
                { id: 194048, rating: 8 },
                { id: 194049, rating: 9 },
                { id: 194050, rating: 10 }
            ];

            let data = [];
            let baseFilter = options.filter;
            let requests = [];

            let fiveStarTagFilter = `SEGMENT IS ${zeroToTenStarTagIds.map(t => t.id).join(" OR SEGMENT IS ")}`;
            let params = {...options};
            params.filter = `(${baseFilter}) and (${fiveStarTagFilter})`;
            requests.push(fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", params));

            let results = await Promise.all(requests);
            results.forEach(result => {
                data = [...data, ...result];
            });

            if (sortCol) {
                let sortField = sortCol.type;
                data.sort((a,b) => {
                    return b[sortField] - a[sortField]
                });
            } else {
                data.sort((a,b) => {
                    return a.tag.id - b.tag.id;
                })
            }
            return data;
        },
    },
    {
        id: 'fiveOptionAgreementScale',
        name: "Five option agreement scale",
        groupBy: ['tag'],
        tagNamespace: ['segment'],
        isTag: true,
        limit: 3000,
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.name || v,
                filter: appendFiltersReadably(filter, "Tag is " + v.id)
            }
        },
        getData: async (options, fromGrouse, sortCol) => {
            const fiveOptionAgreementTagIds = [
                { id: 196492, rating: "STRONGLY_AGREE" },
                { id: 196493, rating: "AGREE" },
                { id: 196494, rating: "NEITHER_AGREE_NOR_DISAGREE" },
                { id: 196495, rating: "DISAGREE" },
                { id: 196496, rating: "STRONGLY_DISAGREE" }
            ]

            let data = [];
            let baseFilter = options.filter;
            let requests = [];

            let fiveStarTagFilter = `SEGMENT IS ${fiveOptionAgreementTagIds.map(t => t.id).join(" OR SEGMENT IS ")}`;
            let params = {...options};
            params.filter = `(${baseFilter}) and (${fiveStarTagFilter})`;
            requests.push(fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", params));

            let results = await Promise.all(requests);
            results.forEach(result => {
                data = [...data, ...result];
            });

            if (sortCol) {
                let sortField = sortCol.type;
                data.sort((a,b) => {
                    return b[sortField] - a[sortField]
                });
            }
            return data;
        },
    },
    {
        id: 'zeroToOneScoringScale',
        name: "Yes/No scale",
        groupBy: ['tag'],
        tagNamespace: ['segment'],
        isTag: true,
        limit: 3000,
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.name || v,
                filter: appendFiltersReadably(filter, "Tag is " + v.id)
            }
        },
        getData: async (options, fromGrouse, sortCol) => {
            const fiveOptionAgreementTagIds = [
                { id: 194054, rating: 0 },
                { id: 194053, rating: 1 }
            ]

            let data = [];
            let baseFilter = options.filter;
            let requests = [];

            let fiveStarTagFilter = `SEGMENT IS ${fiveOptionAgreementTagIds.map(t => t.id).join(" OR SEGMENT IS ")}`;
            let params = {...options};
            params.filter = `(${baseFilter}) and (${fiveStarTagFilter})`;
            requests.push(fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", params));

            let results = await Promise.all(requests);
            results.forEach(result => {
                data = [...data, ...result];
            });

            if (sortCol) {
                let sortField = sortCol.type;
                data.sort((a,b) => {
                    return b[sortField] - a[sortField]
                });
            }
            return data;
        },
    },
    {
        id: 'surveyQuestions',
        name: "Survey Questions",
        groupBy: ['tag'],
        tagNamespace: ['survey-question'],
        isTag: true,
        limit: 3000,
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.description || v,
                filter: appendFiltersReadably(filter, "Tag is " + v.id)
            }
        },
        getData: async (options, fromGrouse, sortCol) => {

            await VuexStore.dispatch('refreshTags');
            let tags = VuexStore.state.tags;

            let surveyQuestionTags = [];
            let surveyQuestionTagsPartition = [];
            const PARTITION_SIZE = 50;

            for (const tag of tags) {
                if (tag.namespace === "survey-question") {
                    surveyQuestionTags.push(tag);
                }
            }

            let surveyQuestionTagsMqp =  Object.fromEntries(
                surveyQuestionTags.map(obj => [obj.id, obj])
            );

            for (let i = 0; i < surveyQuestionTags.length; i += PARTITION_SIZE) {
                const curPartition = surveyQuestionTags.slice(i, i + PARTITION_SIZE);
                surveyQuestionTagsPartition.push(curPartition);
            }

            let data = [];
            let baseFilter = options.filter;
            let requests = [];

            for (const filterPartition of surveyQuestionTagsPartition) {
                let surveyQuestionFilter = `Tag IS ${filterPartition.map(t => t.id).join(" OR TAG IS ")}`;
                let params = {...options};
                params.filter = `(${baseFilter}) and (${surveyQuestionFilter})`;

                requests.push(fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", params));
            }

            let results = await Promise.all(requests);
            results.forEach(result => {
                data = [...data, ...result];
            });

            if (sortCol) {
                let sortField = sortCol.type;
                data.sort((a,b) => {
                    return b[sortField] - a[sortField]
                });
            }

            data = data.map(it => ({
                ...it,
                tag: surveyQuestionTagsMqp[it.tag.id],
            }))
            return data;
        },
    },
    {
        id: 'verbatimQuestions',
        name: "Verbatim Questions",
        groupBy: ['tag'],
        tagNamespace: ['survey-question'],
        isTag: true,
        limit: 3000,
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.description || v,
                filter: appendFiltersReadably(filter, "Tag is " + v.id)
            }
        },
        getData: async (options, fromGrouse, sortCol) => {

            await VuexStore.dispatch('refreshTags');
            let tags = VuexStore.state.tags;

            let verbatimQuestionTags = [];
            let verbatimQuestionTagsPartition = [];
            const PARTITION_SIZE = 50;

            for (const tag of tags) {
                if (tag.namespace === "survey-question" && tag.name.toLowerCase().includes("verbatim")) {
                    verbatimQuestionTags.push(tag);
                }
            }

            let verbatimQuestionTagsMap =  Object.fromEntries(
                verbatimQuestionTags.map(obj => [obj.id, obj])
            );


            for (let i = 0; i < verbatimQuestionTags.length; i += PARTITION_SIZE) {
                const curPartition = verbatimQuestionTags.slice(i, i + PARTITION_SIZE);
                verbatimQuestionTagsPartition.push(curPartition);
            }

            let data = [];
            let baseFilter = options.filter;
            let requests = [];

            for (const filterPartition of verbatimQuestionTagsPartition) {
                let verbatimQuestionFilter = `Tag IS ${filterPartition.map(t => t.id).join(" OR TAG IS ")}`;
                let params = {...options};
                params.filter = `(${baseFilter}) and (${verbatimQuestionFilter})`;

                requests.push(fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", params));
            }

            let results = await Promise.all(requests);
            results.forEach(result => {
                data = [...data, ...result];
            });

            if (sortCol) {
                let sortField = sortCol.type;
                data.sort((a,b) => {
                    return b[sortField] - a[sortField]
                });
            }

            data = data.map(it => ({
                ...it,
                tag: verbatimQuestionTagsMap[it.tag.id],
            }))
            return data;
        },
    },
    {
        id: 'npsBreakDown',
        name: "NPS Breakdown",
        groupBy: [],
        tagNamespace: ['survey-question'],
        limit: 3000,
        get: data => {
            return data
        },
        createLabel: (v, filter) => {
            return {
                id: v.id,
                name: v.id,
                filter: appendFiltersReadably(filter, v.filter)
            }
        },
        getData: async (options, fromGrouse, sortCol, row) => {

            const npsDetractorsFilter = getNpsDetractorsFilter();
            const npsPassivesFilter = getNpsPassivesFilter();
            const npsPromotersFilter = getNpsPromotersFilter();

            const filter = appendFiltersReadably(options.filter, getNetPromoterScoreFilter());

            const [npsDetractors, npsPassives, npsPromoters] = await Promise.all(
                [
                    { id: 'Detractors', filter: npsDetractorsFilter, ordinal: 3, label: "Detractors", description: NPS_DETRACTOR_DESCRIPTION },
                    { id: 'Passives', filter: npsPassivesFilter, ordinal: 2, label: "Passives", description: NPS_PASSIVE_DESCRIPTION },
                    { id: 'Promoters', filter: npsPromotersFilter, ordinal: 1, label: "Promoters", description: NPS_PROMOTER_DESCRIPTION },
                ].map(async (filterInfo) => {
                    let params = { ...options };
                    params.filter = `(${filter}) and (${filterInfo.filter})`;

                    const response = await fromGrouse("/v4/accounts/" + currentAccountCode() + "/mentions/count", params);
                    return {
                        id: filterInfo.id,
                        filter: filterInfo.filter,
                        description: filterInfo.description,
                        mentionCount: response.mentionCount,
                    };
                })
            );

            return [npsDetractors, npsPassives, npsPromoters];
        },
    }
]

export const GROUPBY_BY_ID = { }

GROUPBY.forEach(g => {
    GROUPBY_BY_ID[g.id] = g
    if (!g.groupBy) g.groupBy = [g.id]
    if (!g.unknown) g.unknown = { id: "un", name: "Unknown" }
    if (!g.get) g.get = data => data[g.groupBy] || g.unknown
    if (!g.set) g.set = (data,v) => data[g.groupBy] = v
    if (!g.valuePredicate) g.valuePredicate = true
})

/**
 * Get available group by options based on currently selected options
 *
 * @param usedOptions groupBy options that are already in use
 * @returns {*[]} list of available groupBy options
 */
export function getAvailableGroupByOptions(usedOptions) {
    // don't let user choose something that ends up with groupBy on the same column .. co-occurrence stuff needs
    // to be done with multiple rows with different filters for now
    let usedGroupBy = new Set()
    const account = VuexStore.state.account;

    let cxCount = 0;
    let channelCount = 0;
    let tcfCount = 0;

    for (const segment of account.segmentLists) {
        if (segment.segmentType?.id === "CX_LIST") cxCount++;
        if (segment.segmentType?.id === "CHANNEL_LIST") channelCount++;
        if (segment.segmentType?.id === "TCF_LIST") tcfCount++;
    }

    usedOptions.forEach(id => {     // segment-123, brand etc.
        let i = id.indexOf('-')
        if (i > 0) id = id.substring(0, i)
        let gb = GROUPBY_BY_ID[id]
        if (gb) {
            if (gb.groupBy?.length) gb.groupBy.forEach(g => usedGroupBy.add(g));
            else usedGroupBy.add(id);
        }
    })

    let availableOptions = []

    GROUPBY.forEach(g => {
        let disabled = !!g.groupBy.find(id => usedGroupBy.has(id)) || (!g.groupBy?.length && usedGroupBy.has(g.id))
        if (g.id === "segment") {
            account.segmentLists.forEach(s => {
                if (!s.segmentType) return

                const disambiguate = s.segmentType?.id === "CX_LIST" && cxCount > 1
                    || s.segmentType?.id === "CONDUCT_LIST" // Always disambiguate. Otherwise clashes with 'risk' RPCS tag.
                    || s.segmentType?.id === "CHANNEL_LIST" && channelCount > 1
                    || s.segmentType?.id === "TCF_LIST" && tcfCount > 2;

                const title = disambiguate
                    ? `${s.subtitle ?? ''} ${s.name.trim()}`.trim()
                    : s.name.trim();

                let o = {...g}
                o.id = o.id + "-" + s.id
                o.name = title
                o.disabled = disabled
                availableOptions.push(o)
            });
        } else {
            if (disabled) {
                g = {...g}
                g.disabled = true
            }
            availableOptions.push(g)
        }
    })
    return availableOptions
}

function getGroupKey(value) {
    if (!value) throw new Error()
    if (typeof value === 'object') {
        if ('id' in value) {
            return value.id;
        }
        return JSON.stringify(value);
    }
    return value;
}

export const DEFAULT_COLOUR = {
    'colour-palette': "be14",
    'colour-index': 6,          // light gray
    'colour-palette-custom': null
}