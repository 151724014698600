<template>

    <span>
        <span class="brand-segment-component">
            <TooltipComponent>
                <i :class="active ? 'icon-check-1' : 'icon-cancel-circle'"></i>
                {{ segmentName }} {{active ? 'active' : 'inactive'}} »
                <template #tooltip>{{`Active ${segmentName} segments`}}</template>
            </TooltipComponent>
        </span>
        <span v-for="(val, index) in segmentData" :key="index">
            <TooltipComponent>
                 <span>
                     {{ val.subtitle }}
                     <template v-if="isLast">|</template>
                 </span>
                <template #tooltip>{{val.description}}</template>
            </TooltipComponent>
        </span>

    </span>

</template>

<script>
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";

export default {
    name: "BrandSegmentComponent",
    components: {TooltipComponent},
    props: {
        segmentName: {
            type: String,
            required: true,
        },
        segmentData: {
            type: Array,
            required: true,
        },
        active: {
            type: Boolean,
            required: false,
            default: true
        },
        isLast: {
            type: Boolean,
            required: false,
            default: false
        },
    }
}
</script>

<style scoped lang="sass">

</style>