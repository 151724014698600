<template>
    <div class="deq-social-network-picker">
        <label for="" class="channel-picker-label">Only read these platforms (leave blank for all)</label>
        <div tabindex="0" class="deq-input-div selected-networks" @focusout="emitData">
            <div class="deq-placeholder placeholder" v-if="!selectedNetworks.length">Platforms</div>
            <SlottedTag ref="slotRef" v-for="network in selectedNetworks" :key="network" @close="unselectNetwork(network)">
                <span v-html="socialNetworks.find(sc => sc.name === network).markup.html" :class="network.toLowerCase()"></span>
            </SlottedTag>

            <div class="social-networks-list deq-picker-ul">
              <span v-for="network in socialNetworks.filter(platform => !['UNKNOWN', '𝕏 (TWITTER)'].includes(platform.name))"
                    :key="network.name" :class="'network ' + network.name.toLowerCase()">
                  <input type="checkbox" :id="network.displayName" :name="network.displayName"
                         :checked="selectedNetworks.includes(network.name)" @input="onSelect(network.name)">
                  <label :for="network.displayName" v-html="setHtml(network.markup)"></label>
              </span>
            </div>
        </div>
    </div>

</template>

<script>
import SlottedTag from "@/components/tags/SlottedTag.vue";

export default {
  name: "SocialNetworkPicker",
  components: {SlottedTag},
    model: {
        prop: 'originalSocialNetworks',
        event: 'networks-selected'
    },
  props: {
    originalSocialNetworks: {
      type: String,
      required: false,
      default: () => '',
    },

  },

  mounted() {
      this.emitData();
  },

  data() {
    return {
      width: '100%',
      searchInput: '',
      placeholder: 'Platforms',

      selectedNetworks: this.originalSocialNetworks ? this.originalSocialNetworks.split(' ') : [],
      isOpen: false,

      socialNetworks: [
        {
          name: 'TWITTER',
          displayName: '𝕏 (TWITTER)',
          markup: {
            html: '<span class="icon twitter"><i class="symbol-twitter"></i></span> X (Twitter)'
          }
        },
        {
          name: '𝕏 (TWITTER)',
          displayName: '𝕏 (TWITTER)',
          markup: {
            html: '<span class="icon twitter"><i class="symbol-twitter"></i></span> X (Twitter)'
          }
        },
        {
          name: 'FACEBOOK',
          displayName: 'Facebook',
          markup: {
            html: '<span class="icon facebook"><i class="symbol-facebook"></i></span> Facebook'
          }
        },
        {
          name: 'INSTAGRAM',
          displayName: 'Instagram',
          markup: {
            html: '<span class="icon"><i class="symbol-instagram"></i></span> Instagram'
          }
        },
        {
          name: 'YOUTUBE',
          displayName: 'YouTube',
          markup: {
            html: '<span class="icon"><i class="symbol-youtube"></i></span> YouTube'
          }
        },
        {
          name: 'TIKTOK',
          displayName: 'TikTok',
          markup: {
            html: '<span class="icon tiktok"><i class="symbol-tiktok"></i></span> TikTok'
          }
        },
        {
          name: 'TUMBLR',
          displayName: 'Tumblr',
          markup: {
            html: '<span class="icon"><i class="symbol-tumblr"></i></span> Tumblr'
          }
        },
        {
          name: 'VK',
          displayName: 'VK',
          markup: {
            html: '<span class="icon vk"><i class="symbol-vk"></i></span> VK',
            helpLine: '(Russian Facebook)'
          }
        },
        {
          name: 'TELEGRAM',
          displayName: 'Telegram',
          markup: {
            html: '<span class="icon telegram"><i class="symbol-telegram"></i></span> Telegram'
          }
        },
        {
          name: 'WHATSAPP',
          displayName: 'WhatsApp',
          markup: {
            html: '<span class="icon whatsapp"><i class="symbol-whatsapp"></i></span> WhatsApp'
          }
        },
        {
          name: 'LINKEDIN',
          displayName: 'LinkedIn',
          markup: {
            html: '<span class="icon linkedin"><i class="symbol-linkedin"></i></span> LinkedIn'
          }
        },
        {
          name: 'HELLOPETER',
          displayName: 'Hellopeter',
          markup: {
            html: '<span class="icon hellopeter"><i class="symbol-hellopeter"></i></span> Hellopeter'
          }
        },
        {
          name: 'GOOGLEBUSINESS',
          displayName: 'Google Business',
          markup: {
            html: '<span class="icon help"><i class="symbol-google-full"></i></span> Google Business'
          }
        },
        {
          name: 'TRUSTPILOT',
          displayName: 'Trustpilot',
          markup: {
            html: '<span class="icon trustpilot"><i class="symbol-trustpilot"></i></span> Trustpilot'
          }
        },
        {
          name: 'WEBSITES',
          displayName: 'Websites',
          markup: {
            html: '<span class="icon"><i class="symbol-website"></i></span> Websites, blogs, news sites, etc',
          }
        },
        {
          name: 'UNKNOWN',
          displayName: 'Unknown',
          markup: {
            html: '<span class="icon"><i class="symbol-website"></i></span> Websites, blogs, news sites, etc'
          }
        },
      ]
    }
  },

  methods: {

    setHtml(networkMarkup) {
      return networkMarkup.helpLine ? networkMarkup.html + (' ' + networkMarkup.helpLine) : networkMarkup.html;
    },

    onSelect(networkName) {
      if (this.selectedNetworks.includes(networkName)) {
        this.selectedNetworks.splice(this.selectedNetworks.indexOf(networkName), 1);
      } else {
        this.selectedNetworks.push(networkName);
      }
      this.emitData()
    },

      unselectNetwork(networkName) {
          this.selectedNetworks.splice(this.selectedNetworks.indexOf(networkName), 1);
          this.emitData();
      },

    emitData() {
      this.$emit('networks-selected', this.selectedNetworks.join(' '));
    }
  }
}
</script>

<style scoped lang="sass">
$network-hover: #AEE15D

.deq-social-network-picker
    position: relative

    label
        cursor: text

.controls
  &:hover
    background-color: unset

.social-networks-list
  position: absolute
  top: 100%
  grid-template-columns: 1fr 1fr
  padding: 8px
  gap: 5px
  width: fit-content
  display: none

  .network
    display: flex
    flex-direction: row
    gap: 7px
    transition: color var(--transition-duration)

    input, label
      margin: 0

    &:hover
      color: #AEE15D

.selected-networks
    position: relative
    gap: 7px
    display: flex
    flex-direction: row
    flex-wrap: wrap
    min-height: 28px

    &:focus-within
        border-color: var(--be-input-focus-border-color)
        box-shadow: var(--be-input-focus-box-shadow)

        .social-networks-list
            display: grid
        .placeholder
            display: none


::v-deep .icon
  display: inline-block
  width: 25px
  text-align: center
  border-radius: 3px
  transition: color var(--transition-duration)
  transform: scale(0.9)


.twitter
    &:hover
        color: #AEE15D
        ::v-deep .icon
            background-color: var(--colour-twitter)
            color: white
.facebook
    &:hover
        color: #AEE15D
        ::v-deep .icon
            color: white
            background-color: var(--colour-facebook)
.instagram
    &:hover
        color: #AEE15D
        ::v-deep .icon
            color: var(--colour-instagram)
.youtube
    &:hover
        color: #AEE15D
        ::v-deep .icon
            color: var(--colour-youtube)
.tiktok
    &:hover
        color: #AEE15D
        ::v-deep .icon
            color: #AEE15D
.linkedin
    &:hover
        color: #AEE15D
        ::v-deep .icon
            color: white
            background-color: var(--colour-linkedin)
.whatsapp
    &:hover
        color: #AEE15D
        ::v-deep .icon
            color: white
            background-color: var(--colour-whatsapp)
.telegram
    &:hover
        color: #AEE15D
        ::v-deep .icon
            color: white
            background-color: var(--colour-telegram)
.tumblr
    &:hover
        color: #AEE15D
        ::v-deep .icon
            color: #AEE15D
.vk
    &:hover
        color: #AEE15D
        ::v-deep .icon
            color: white
            background-color: var(--colour-vk)
.hellopeter
    &:hover
        color: #AEE15D
        ::v-deep .icon
            color: var(--colour-hellopeter)
            background-color: white
.googlebusiness
    &:hover
        color: #AEE15D
        ::v-deep .icon
            color: white
            background-color: var(--colour-googlebusiness)
.unknown
    &:hover
        color: #AEE15D
        ::v-deep .icon
            color: #AEE15D
.trustpilot
    &:hover
        color: #AEE15D
        ::v-deep .icon
            color: var(--colour-trustpilot)

.placeholder
    position: absolute
    margin-left: 5px

</style>