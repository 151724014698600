import { formatPlural } from "@/app/utils/Format";
import { getProfileAppId, profileTypes } from "@/setup/profiles/ProfileUtils";
import { beef, mash } from "@/store/Services";
import { mapActions, mapMutations, mapState } from "vuex";
import {phraseNeedsQuotes} from "@/app/utils/Phrases";

export const addProfileMixins = {
    data() {
        return {
            saving: false,
            saveComplete: false,
            saveError: false,
            profileTypes: profileTypes,
            saveMessage: "",
            saveCompleteMessage: "",
            saveErrorMessage: "There was an error saving your profile. Please refresh the page and try again or contact DataEQ support",
            addProfileTypes: {
                facebook: {
                    PUBLIC: 1,
                    MANAGED: 2,
                    INFLUENCER: 3
                },
                twitter: {
                    PUBLIC: 1,
                    MANAGED: 2
                },
                googleBusiness: {
                    PUBLIC: 1,
                    MANAGED: 2
                }
            },
            addProfileStages: {
                facebook: {
                    public: {
                        SEARCH_PAGE: 1,
                        EDIT_PAGE: 2,
                        SAVE: 3
                    },
                    managed: {
                        SELECT_PAGES: 1,
                        SAVE: 2
                    },
                    influencer: {
                        CREATE_LINKS: 1,
                        SAVE: 2
                    }
                },
                googleBusiness: {
                    public: {
                        SEARCH_PAGE: 1,
                        EDIT_PAGE: 2,
                        SAVE: 3
                    },
                    managed: {
                        SELECT_PROFILES: 1,
                        SAVE: 2
                    }
                },
                twitter: {
                    public: {
                        SEARCH_PROFILE: 1,
                        EDIT_PROFILE: 2,
                        SAVE: 3
                    },
                    managed: {
                        SELECT_PROFILES: 1,
                        SAVE: 2
                    }
                },
                trustpilot: {
                    public: {
                        SEARCH_PROFILE: 1,
                        EDIT_PROFILE: 2,
                        SAVE: 3
                    }
                },
                linkedin: {
                    managed: {
                        SELECT_PROFILES: 1,
                        SAVE: 2
                    }
                },
                youtube: {
                    public: {
                        SEARCH_CHANNEL: 1,
                        EDIT_CHANNEL: 2,
                        SAVE: 3
                    }
                },
                whatsapp: {
                    managed: {
                        SELECT_PROFILES: 1, // The same screen is used for clickatell profiles
                        SAVE: 2
                    }
                },
                helloPeter: {
                    managed: {
                        CONFIGURE: 1,
                        SAVE: 2
                    }
                },
                csv: {
                    public: {
                        INSTRUCTIONS: 1,
                        IMPORT_PROFILES: 2,
                        SAVE: 3
                    }
                },
                tiktok: {
                    managed: {
                        SELECT_PROFILES: 1,
                        SAVE: 2
                    }
                },
                tiktokAds: {
                    managed: {
                        SELECT_PROFILES: 1,
                        SAVE: 2
                    }
                },
            }
        }
    },

    computed: {
        ...mapState(['account']),
        ...mapState('profiles', ['newProfileData', 'profiles']),
        ...mapState('userPlatformAuth', ['facebookLoggedInAs', 'facebookUserToken'])
    },

    destroyed() {
        // reset new profile data
        this.clearNewProfiles();
    },

    methods: {
        formatPlural,

        ...mapMutations('profiles', ['clearNewProfiles', 'setInfluencerLinks']),
        ...mapActions('profiles', ['createProfile', 'refreshProfiles', 'updateProfile']),
        ...mapActions(['refreshBrands']),

        async savePublicProfile() {
            try {
                this.saving = true;
                this.saveError = false;
                this.saveMessage = "Saving your profile...";
                this.saveCompleteMessage = "Your profile has been saved and added to the account";
                let shouldRefreshBrands = false;

                // save phrases
                if (this.newProfileData.publicProfile.brands?.length) {
                    let phrases = [];
                    shouldRefreshBrands = true;

                    // prepare phrases
                    this.newProfileData.publicProfile.brands.forEach(brand => {
                        // only allow adding phrases to brands that aren't imported
                        if (!brand.importedFromAccount) {
                            let quoteHandle = phraseNeedsQuotes(this.newProfileData.publicProfile.handle);
                            let handle = this.newProfileData.publicProfile.handle;

                            // Modifying handle phrase based on platform
                            // We exclude enterprise mentions from root brand filters but only if their handle is tracked in the @name format
                            if (this.newProfileData.publicProfile.type === profileTypes.instagram_user || this.newProfileData.publicProfile.type === profileTypes.twitter) handle = `@${handle}`;

                            if (this.newProfileData.publicProfile.type !== profileTypes.trustpilot) {
                                let handlePhrase = {
                                    query: quoteHandle === true ? `"${handle}"` : handle,
                                    active: true, brandId: brand.id, deleted: false
                                };
                                phrases.push(handlePhrase);
                            }

                            // Youtube, and sometimes Facebook, handle and handleId are the same.
                            if (this.newProfileData.publicProfile.handle !== this.newProfileData.publicProfile.handleId) {
                                let quoteHandleId = phraseNeedsQuotes(this.newProfileData.publicProfile.handleId);
                                let handleIdPhrase = {
                                    query: quoteHandleId === true ? `"${this.newProfileData.publicProfile.handleId}"` : this.newProfileData.publicProfile.handleId,
                                    active: true, brandId: brand.id, deleted: false
                                };
                                phrases.push(handleIdPhrase);
                            }
                        }
                    });

                    // save phrases
                    await mash.post(`/rest/accounts/${this.account.code}/phrases`, phrases);
                }
                
                // save profile
                let profilePayload = {
                    type: this.newProfileData.publicProfile.type,
                    handle: this.newProfileData.publicProfile.handle,
                    handleId: this.newProfileData.publicProfile.handleId,
                    name: this.newProfileData.publicProfile.name,
                    media: this.newProfileData.publicProfile.media,
                    relevant: this.newProfileData.publicProfile.relevant,
                    pictureLink: this.newProfileData.publicProfile.pictureLink,
                    tagIds: this.newProfileData.publicProfile.tagIds,
                    description: this.newProfileData.publicProfile.description
                }

                // if profile exists but is deleted, undelete + update it
                let existingProfile = this.profiles.find(p => p.handleId === this.newProfileData.publicProfile.handleId);
                if (existingProfile) {
                    profilePayload.deleted = false;
                    profilePayload.id = existingProfile.id;

                    await this.updateProfile(profilePayload);
                } else {
                    await this.createProfile(profilePayload);
                }

                if (shouldRefreshBrands) await this.refreshBrands(true);

                this.saveComplete = true;

                this.refreshProfiles(true);
            } catch (e) {
                console.error("Error occurred while saving public profile: ", e);
                this.saveError = true;
            }
        },

        async saveManagedProfiles(profileType) {
            try {
                this.saving = true;
                this.saveError = false;
                this.saveMessage = "Adding your managed profiles...";
                this.saveCompleteMessage = "Your managed profiles have been added to the account";

                // create deep clone of list to avoid editing the table in the background when we delete fields
                let pageLookups = [];

                this.newProfileData.managedProfiles.profiles.forEach(profile => {
                   let pageLookup = {
                       handle: profile.handle,
                       handleId: profile.handleId,
                       name: profile.name,
                       pictureLink: profile.pictureLink,
                       type: profile.type
                   }

                   if(profile.description) {
                    pageLookup.description = profile.description;
                   }

                   if (profile.linkedProfile) pageLookup.linkedProfile = profile.linkedProfile;

                   pageLookups.push(pageLookup);
                });

                let payload = {
                    pageLookups: pageLookups,
                    appId: getProfileAppId(profileType),
                    accountCode: this.account.code,
                    directMessagesEnabled:  this.newProfileData.managedProfiles.dmsEnabled
                }

                if (profileType === profileTypes.facebook) {
                    payload.userToken = this.facebookUserToken;
                }

                await beef.post("/api/online-profile/add-managed-profiles", payload);

                this.refreshProfiles(true);

                this.saveComplete = true;
            } catch (error) {
                console.error(`An error occurred while trying to update the account ${this.account.code}'s managed Facebook pages`, error);
                this.saveError = true;
            }
        },

        async generateInfluencerLinks() {
            try {
                this.saving = true;
                this.saveError = false;
                let linkCount = this.newProfileData.influencerProfiles.generateLinkCount;
                this.saveMessage = `Generating ${linkCount} influencer ${this.formatPlural(linkCount, "link")}...`;
                this.saveCompleteMessage = `${linkCount} ${this.formatPlural(linkCount, "link")} ${linkCount > 1 ? `have` : `has`} been successfully generated`;

                let response = await beef.get(`/api/online-profile/generate-external-facebook-login-link?contentRestricted=false&accountCode=${this.account.code}&linkCount=${linkCount}`);
                let links = response.data.url;

                if(links && links.length) {
                    this.setInfluencerLinks(links);
                }

                this.saveComplete = true;
            } catch (error) {
                console.error(`An error occurred while trying to generate ${this.newProfileData.influencerProfiles.generateLinkCount} influencer links for account ${this.account.code}`, error);
                this.saveError = true;
            }
        }
    }
}