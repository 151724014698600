<template>

    <div class="deq-mention-filter-form">
        <template v-if="!isLoading">
            <!--conversationLocation-->
            <div class="edit-brand-row">
                <label>I am only interested in conversation from these locations</label>
                <LocationInput v-model="formData.conversationLocations"></LocationInput>
            </div>

            <LanguagePickerComponent class="edit-brand-row" :is-multiselect="true" :is-inside-dialog="true"
                                       :label-text="'I am only interested in conversation in these languages'"
                                       v-model="formData.conversationLanguages">
            </LanguagePickerComponent>

            <div class="edit-brand-row">
                <div @click="toggleAdvOps" class="adv-opt-toggle">
                    <i class="minus icon-down-dir" v-if="openAdvOps"></i>
                    <i class="plus icon-right-dir" v-if="!openAdvOps"></i>
                    <span>Advanced Options</span>
                </div>
                <div class="advanced-options" :class="{'adv-open': openAdvOps, 'adv-closed': !openAdvOps}">
                    <div class="author-bio-location">
                        <!--Author-bio location-->
                        <label>Author-bio location</label>
                        <BackboneStyleAuthorBioPicker v-model="formData.authorBioLocation"></BackboneStyleAuthorBioPicker>
                    </div>

                    <div v-if="!isPhrase" class="checkboxes">
                        <BackboneStyleSamplePicker v-model="formData.sampleRate" class="sample-picker"></BackboneStyleSamplePicker>

                        <div class="reshares">
                            <CheckboxInput v-model="formData.dissallowReshares" :label="'Do not collect reshares'"></CheckboxInput>
                        </div>
                    </div>
                </div>
            </div>

            <div class="edit-brand-row only-apply-to">
                <label>Only apply to: </label>

                <div class="apply-to-options">
                    <div class="option">
                        <CheckboxInput :label="'X Posts'" v-model="formData.twitter"></CheckboxInput>
                    </div>

                    <div class="option">
                        <CheckboxInput :label="'News sites & blogs'" v-model="formData.webhose"></CheckboxInput>
                    </div>
                </div>

                <p>
                    It is recommended that these boxes should be left ticked or "Unknown" should be added to the country
                    list. Mentions from Facebook and other sources often have no country and will be deleted otherwise.
                </p>
            </div>
        </template>


    </div>


</template>


<script>

import {PhraseFilter} from "@/app/utils/Phrases";
import BackboneStyleAuthorBioPicker
    from "@/components/pickers/BackboneStyleAuthorBioPicker/BackboneStyleAuthorBioPicker.vue";
import LocationInput from "@/components/inputs/LocationInput.vue";
import LanguagePickerComponent from "@/setup/brands/inputs/LanguagePickerComponent.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import BackboneStyleSamplePicker from "@/components/pickers/BackboneStyleSamplePicker/BackboneStyleSamplePicker.vue";

const mentionUpdated = 'mention-updated';
export default {
    name: "MentionForm",
    components: {
        BackboneStyleSamplePicker,
        CheckboxInput, LanguagePickerComponent, LocationInput, BackboneStyleAuthorBioPicker},
    model: {
        prop: 'initialMentionFilter',
        event: mentionUpdated
    },
    props: {
        initialMentionFilter: {
            type: String,
            required: true
        },
        isPhrase: {
            type: Boolean,
            required: false,
        }
    },

    data() {
        return {

            openAdvOps: false,
            isLoading: true,

            formData: {
                conversationLocations: '',
                conversationLanguages: '',
                authorBioLocation: '',
                sampleRate: 0,
                dissallowReshares: false,
                twitter: true,
                webhose: true,
            }
        }
    },

    watch: {
        formData:{
           handler() {
               this.emitData(this.constructMentionFilterString());
           },
            deep: true
        },

    },

    mounted() {
        this.isLoading = true;
        const splitMentionFilter = PhraseFilter.parse(this.initialMentionFilter?.length ? this.initialMentionFilter : '');

        this.formData.conversationLocations = splitMentionFilter.location?.split(' ').map(str => str.replace(/['"]+/g, ''));
        this.formData.authorBioLocation = splitMentionFilter.authorLocation ? splitMentionFilter.authorLocation : [];
        this.formData.sampleRate = splitMentionFilter.sampleRate || 0;
        this.formData.dissallowReshares = splitMentionFilter.dissallowReshares;
        this.formData.twitter = splitMentionFilter.twitter;
        this.formData.webhose = splitMentionFilter.webhose;
        this.formData.conversationLanguages = splitMentionFilter.language;
        this.isLoading = false;

    },

    methods: {

        toggleAdvOps() {
            this.openAdvOps = !this.openAdvOps;
        },

        emitData(data) {
            this.$emit(mentionUpdated, data)
        },

        constructMentionFilterString() {

            let mentionFilterData = {
                location: this.formData.conversationLocations?.map(location => `'${location.id || location}'`).join(' '),
                language: this.formData.conversationLanguages,
                authorLocation: this.formData.authorBioLocation,
                sampleRate: this.formData.sampleRate,
                dissallowReshares: this.formData.dissallowReshares,
                twitter: this.formData.twitter,
                webhose: this.formData.webhose
            };

            return PhraseFilter.from(mentionFilterData).toString();
        },
    }
}
</script>

<style scoped lang="sass">
@use "../tree/edit-brand-dialog"


</style>