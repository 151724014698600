import {
    getRiskSegmentList,
    getJourneySegmentList,
    isCxSolution,
    isRiskSolution,
    isTcfSolution
} from "@/app/utils/Segments"
import {substituteTagParamaters} from "@/app/utils/Tags"

import AxisOptions from "./AxisOptions"
import {features} from "@/app/Features";
import {isMashAdmin} from "@/app/Permissions";
import VuexStore from "@/store/vuex/VuexStore";
import {idIsRating, idIsSurvey} from "@/dashboards/widgets/fantasticchart/FantasticUtilities";

/**
 * These are all the options for the drop downs and so on in the FC settings dialog. Just to reduce the size of that
 * component.
 */
export default class FantasticChartSettingsOptions {

    constructor() {
        const account = VuexStore.state.account;

        this.rules = {
            lineWidth: [v => !v || v >= 1 && v <= 30 || "Invalid"],
            maxItems: [v => !v || v >= 1 && v <= 100 || "Invalid"],
            barOutline: [v => !v || v >= 0 && v <= 99 || "Invalid"],
            barPadding: [v => !v || v >= 0 && v <= 99 || "Invalid"],
            axisMaxValue: [v => !v || v >= -999999999 && v <= 999999999 || "Invalid"],
            commentWidth: [v => !v || v >= 0.5 && v <= 20 || "Invalid"]
        }

        this.height = HEIGHT
        this.width = WIDTH
        this.dateGroup = AxisOptions.dateGroup
        this.fontSize = FONT_SIZE
        this.numberFormat = NUMBER_FORMAT
        this.dateFormat = AxisOptions.dateFormats
        this.labelAngle = AxisOptions.labelAngles
        this.dateTicks = AxisOptions.dateTicks

        // yAxis items with the same scaleType can appear on the same y-axis on the chart
        let seeRawSentiment = features.seeRawSentimentOnCharts()
        this.yAxis = {
            mentionCount: { name: "Absolute Volume", scaleType: "mentions",
                description: "The total number of mentions received",
                section: "Volume"},
            mentionPercent: {name: "Percentage Volume", scaleType: "percentage",
                description: "The number of mentions received as a percentage of the total mentions",
                section: "Volume"},

            totalSentimentPercent: {name: "Percentage Net Sentiment", scaleType: "netSentimentPercentage", section: "Sentiment"},
            positivePercent: {name: "Percentage Positive", scaleType: "netSentimentPercentage", section: "Sentiment"},
            negativePercent: {name: "Percentage Negative", scaleType: "netSentimentPercentage", section: "Sentiment"},
            posAndNegPercent: {name: "Percentage Pos & Neg", scaleType: "netSentimentPercentage", section: "Sentiment", doubleValue: true },

            sentimentSamplePercent: {name: "Sentiment Sample %", scaleType: "netSentimentPercentage", section: "Sentiment", visible: seeRawSentiment},
            sentimentSampleSize: {name: "Sentiment Sample Size", scaleType: "mentions", section: "Sentiment", visible: seeRawSentiment},
            totalSentiment: {name: "Net Sentiment", scaleType: "netSentiment", section: "Sentiment", visible: seeRawSentiment},
            totalNegative: {name: "Negative Sentiment", scaleType: "mentions", section: "Sentiment", visible: seeRawSentiment},
            totalPositive: {name: "Positive Sentiment", scaleType: "mentions", section: "Sentiment", visible: seeRawSentiment},
            totalNeutral: {name: "Neutral Sentiment", scaleType: "mentions", section: "Sentiment", visible: seeRawSentiment},

            totalEngagement: {name: "Engagement", scaleType: "mentions", section: "Engagements"},
            totalReshareCount: {name: "Reshares", scaleType: "mentions", section: "Engagements"},
            averageConversationLength: {name: "Average Conversation Length", scaleType: "convLength", section: "Engagements"},
            totalOTS: {name: "Opportunity to see (OTS)", scaleType: "ots", section: "Engagements"},

            authorIdCount: {name: "Unique authors", scaleType: "mentions", section: "Counts"},
            conversationIdCount: {name: "Unique conversations", scaleType: "mentions", section: "Counts"},
            interactionCount: {name: "Unique interactions", scaleType: "mentions", section: "Counts"},
            interactionPercent: {name: "Percentage unique interactions", scaleType: "percentage", section: "Counts"},
            siteCount: {name: "Unique sites", scaleType: "sites", section: "Counts"},
            languageCount: {name: "Unique languages", scaleType: "languages", section: "Counts"},
            countryCount: {name: "Unique countries", scaleType: "countries", section: "Counts"},
            ticketIdCount: {name: "Active Engage tickets", scaleType: "mentions", section: "Counts", visible: isMashAdmin()},

            // new response time and interaction methodology
            averageInteractionResponseTime: { name: "Interaction response time", scaleType: "responseTime", section: "Response times" },
            averageInteractionFirstResponseTime: { name: "First response time", scaleType: "responseTime", section: "Response times" },
            averageInteractionFollowupResponseTime: { name: "Follow-up response time", scaleType: "responseTime", section: "Response times" },
            averageInteractionWhResponseTime: { name: "Interaction response time (working hours)", scaleType: "responseTime", section: "Response times" },
            averageInteractionWhFirstResponseTime: { name: "First response time (working hours)", scaleType: "responseTime", section: "Response times" },
            averageInteractionWhFollowupResponseTime: { name: "Follow-up response time (working hours)", scaleType: "responseTime", section: "Response times" },
            averageInteractionHasResponse: { name: "Interaction response rate", scaleType: "percentage", section: "Response times" },
            interactionHasResponseCount: { name: "Interactions replied to", scaleType: "mentions", section: "Response times" },
            totalHasReply: { name: "Mentions replied to", scaleType: "mentions", section: "Response times"},

            // legacy response time fields
            // maxFirstReplyTime: { name: "Maximum reply time", section: "Response times"},
            // minFirstReplyTime: { name: "Minimum reply time", section: "Response times"},
            // These should generally not be used. Kept here in case they
            // are on a chart, then they will at least appear in the menu if they had been selected.
            averageFirstReplyTime: { name: "Average reply time", scaleType: "responseTime", section: "Response times (legacy)", visible: false},
            averageHasReply: { name: "Response rate", scaleType: "percentage", section: "Response times (legacy)", visible: false},
            averageResponseTime: { name: "Average response time", scaleType: "responseTime", section: "Response times (legacy)", visible: false},
            maxResponseTime: { name: "Maximum response time", scaleType: "responseTime", section: "Response times (legacy)", visible: false},
            minResponseTime: { name: "Minimum response time", scaleType: "responseTime", section: "Response times (legacy)", visible: false},

            totalPlatformImpressions: { name: "Platform impressions", scaleType: "mentions", section: "Platform metrics", visible: features.publishingMetricsInCharts()},
            totalPlatformEngagement: { name: "Platform engagement", scaleType: "mentions", section: "Platform metrics", visible: features.publishingMetricsInCharts()},
            totalPlatformReactions: { name: "Platform reactions", scaleType: "mentions", section: "Platform metrics", visible: features.publishingMetricsInCharts()},
            totalPlatformReshares: { name: "Platform reshares", scaleType: "mentions", section: "Platform metrics", visible: features.publishingMetricsInCharts()},
            totalPlatformComments: { name: "Platform comments", scaleType: "mentions", section: "Platform metrics", visible: features.publishingMetricsInCharts()},

            totalAVE: {
                name: "Traditional AVE",
                scaleType: "ave",
                description: account.showAVE && account.showTraditionalAVE
                    ? "Shows AVE from traditional media sources, such as newspapers, television and radio"
                    : "AVE for traditional media sources is not supported in this account",
                disabled: !account.showAVE || !account.showTraditionalAVE,
                section: "Advanced"
            },
            totalOnlineAVE: {
                name: "Online AVE",
                scaleType: "ave",
                description: account.showAVE
                    ? "Shows estimated AVE values for social media sources, such as Twitter and Facebook"
                    : "AVE is not supported in this account",
                disabled: !account.showAVE,
                section: "Advanced"
            },
            npsScore: { name: "NPS Score", scaleType: "percentage", section: "Surveys", visible: features.surveyReportingEnhancements()},
            cesScore: { name: "CES Score", scaleType: "percentage", section: "Surveys", visible: features.surveyReportingEnhancements()},
            fcrScore: { name: "FCR Score", scaleType: "percentage", section: "Surveys", visible: features.surveyReportingEnhancements()},
            csatScore: { name: "CSAT Score", scaleType: "percentage", section: "Surveys", visible: features.surveyReportingEnhancements()},
        }

        this.yAxisByScaleType = { }
        Object.entries(this.yAxis).forEach(row => {
            let st = row[1].scaleType
            if (!st) return
            let m = this.yAxisByScaleType[st]
            if (!m) this.yAxisByScaleType[st] = m = { }
            m[row[0]] = row[1]
        })

        this.geometry = {
            columns: {name: "Columns", description: "Visualise your data as a column chart", icon: "icon-signal-1" },
            rows: {name: "Rows", description: "Visualise your data as a bar chart", icon: "icon-align-left"},
            lines: {
                name: "Lines",
                description: "Visualise your data as a line chart",
                icon: "icon-chart-line"
            },
            points: {
                name: "Scatterplot",
                description: "Visualise your data as a scatterplot of points",
                icon: "icon-dot"
            },
            pie: {
                name: "Pie",
                description: "Pie or donut chart",
                icon: "icon-chart-pie"
            }
        }

        this.geometryFirst = {
            columns: this.geometry.columns,
            lines: this.geometry.lines
        }

        this.geometryOthers = {
            lines: this.geometry.lines
        }

        // options allowed when there are 2 values for each point e.g. sentiment pos and neg
        this.geometryDoubleValue = {
            columns: this.geometry.columns,
            rows: this.geometry.rows
        }

        // geometries that support negative values
        this.geometryNegativeValues = {
            columns: this.geometry.columns,
            rows: this.geometry.rows,
            lines: this.geometry.lines,
            points: this.geometry.points
        }

        this.xAxis = {
            published: {name: "By published date", description: "See your data sub-divided by publication date",
                noCompareDataSets: true },
            dataSet: {name: "By data set", description: "Compare separate data sets", noCompareDataSets: true },
            brand: {name: "By brand", description: "Sub-divide your data by the brands in your filter"},
            topic: {name: "By topic", description: "Sub-divide your data by the topics that they relate to"},
            sentiment: {name: "By sentiment", description: "See how people are feeling towards your brands",
                noCompareDataSets: true },
            functionalSentiment: {name: "By operational vs reputational", description: "See how people relate to your Customer Experience",
                noCompareDataSets: true },
            rpcs: {
                name: "By priority categorisation",
                description: "Categorise your mentions by priority, such as whether they relate to a service issue, or cancellation and purchase requests",
                noCompareDataSets: true
            },
            priority: {
                name: "By high vs low priority",
                description: "See your high priority mentions vs your low priority mentions",
                noCompareDataSets: true
            },

            language: {name: "By language", description: "See the languages that your mentions are written in"},
            fullAuthor: {name: "By author", description: "See the authors and websites that created the mentions in your account"},
            gender: {name: "By gender", description: "See a breakdown of gender for the authors creating your conversations"},

            category: {name: "By media category", description: "See how your mentions are divided between the Enterprise, Consumer and Press categories"},

            tag: {name: "By tag", description: "Sub-divide your data by the tags that are on them"},

            risk: {
                name: "By overall risk",
                section: "Risk and Reputation",
                description: "See all mentions that speak to risk",
                visible: !!getRiskSegmentList()
            },

            service: {
                name: "By service",
                section: "Customer Experience",
                description: "See all mentions that speak to servicing customers",
                visible: !!getJourneySegmentList()
            },

            timeOfDay: {name: "By time of day", description: "See your data sub-divided by the hour of day in which it was published", section: "Date & Time"},
            dayOfWeek: {name: "By day of week", description: "See your data sub-divided by the day of the week in which it was published", section: "Date & Time"},

            country: {name: "By country", description: "See the countries that make up your data", section: "Location"},
            region: {name: "By region", description: "See the regions that make up your data", section: "Location"},
            city: {name: "By city", description: "See the cities that make up your data", section: "Location"},

            extractWord: {name: "By word", description: "See the words that frequently occur in your mentions", section: "Content"},
            authorBioWord: {name: "By author bio", description: "See the words that frequently occur in author bios", section: "Content"},

            site: {name: "By source", description: "See what platforms and websites your mentions originate from", section: "Source"},
            socialNetwork: {name: "By social network", description: "See the social media networks that your mentions originate from", section: "Source"},

            authorName: {name: "By author name", description: "See just the names of the people who created the mentions in your account", section: "Advanced"},
            authorHandle: {name: "By author handle", description: "See the social media handles of the authors who created the mentions in your account", section: "Advanced"},

            engageusertag: {name: "By engage user tag", description: "Sub-divide your data by the engage user tags that are on them",
                visible: isMashAdmin(),
                section: "Staff Only"
            },
            
            engageagenttag: {
                name: "By engage agent tag", description: "Sub-divide your data by the engage agent tags that are on them",
                visible: isMashAdmin(),
                section: "Staff Only"
            },

            surveytemplatetag: {
                name: "By survey template tag", description: "Sub-divide your data by the survey template tags that are on them",
                visible: isMashAdmin(),
                section: "Staff Only"
            },

            npsBreakdown: {
                name: "By NPS breakdown", description: "Sub-divide your data by Net Promoter Score groups",
                section: "Surveys"
            },

            surveyQuestion: {
                name: "Survey Question", description: "Sub-divide your data by survey questions",
                section: "Surveys",
                visible: features.surveyReportingEnhancements()
            },

            pickedUp: {name: "By pickedup date", description: "See your data sub-divided by when it was received",
                noCompareDataSets: true,
                visible: isMashAdmin(),
                section: "Staff Only"
            },
            ticketId: {
                name: "By Engage ticket",
                description: "See Engage ticket IDs related to mentions in your account",
                section: "Staff Only",
                visible: isMashAdmin()
            },
            interactionId: {
                name: "By interaction",
                description: "See interactions related to mentions in your account",
                section: "Staff Only",
                visible: isMashAdmin()
            }

        }

        this.compare = {
            brand: { name: "Brands", section: ""},
            dataSet: { name: "Data sets", section: "" },
            sentiment: { name: "Sentiment", section: ""},
            functionalSentiment: {name: "Operational vs reputational", description: "See how people relate to your Customer Experience", section: ""},
            topic: { name: "Topic", section: ""},
            tag: { name: "Tag", section: ""},
            rpcs: {
                name: "By priority categorisation",
                description: "Compare your mentions by priority, such as whether they relate to a service issue, or cancellation and purchase requests",
                section: ""
            },

            risk: {
                name: "Risk",
                section: "Risk and Reputation",
                description: "Compare by risk categories",
                visible: !!getRiskSegmentList()
            },

            service: {
                name: "Service",
                section: "Customer Experience",
                description: "Compare by service categories",
                visible: !!getJourneySegmentList()
            },

            country: { name: "Country", section: "Location"},
            region: { name: "Region", section: "Location"},
            city: { name: "City", section: "Location"},

            fullAuthor: { name: "Author", section: "Social"},
            gender: { name: "Gender", section: "Social"},
            language: { name: "Language", section: "Social"},
            category: { name: "Media category", section: "Social"},

            site: { name: "Source", section: "Source"},
            socialNetwork: { name: "Social network", section: "Source"},


            relevancy: { name: "Relevancy", section: "Advanced"},
            authorName: { name: "Author name", section: "Advanced"},
            authorHandle: { name: "Author handle", section: "Advanced"},

            npsBreakdown: {
                name: "NPS breakdown", description: "Sub-divide your data by Net Promoter Score groups",
                section: "Surveys"
            },

        };

        this.bubbleSize = {
            mentionCount: {name: "Absolute Volume", description: "The total number of mentions received", section: "Volume"},
            mentionPercent: {name: "Percentage Volume", description: "The number of mentions received as a percentage of the total mentions", section: "Volume"},

            totalSentiment: {name: "Net Sentiment", section: "Sentiment"},
            totalSentimentPercent: {name: "Percentage Net Sentiment", section: "Sentiment"},
            totalNegative: {name: "Negative Sentiment", section: "Sentiment"},
            totalPositive: {name: "Positive Sentiment", section: "Sentiment"},
            totalNeutral: {name: "Neutral Sentiment", section: "Sentiment"},

            totalEngagement: {name: "Engagement", section: "Interactions"},
            averageConversationLength: {name: "Average Conversation Length", section: "Interactions"},
            totalOTS: {name: "Opportunity to see (OTS)", section: "Interactions"},

            authorIdCount: {name: "Unique authors", section: "Counts"},
            conversationIdCount: {name: "Unique conversations", section: "Counts"},
            siteCount: {name: "Unique sites", section: "Counts"},
            languageCount: {name: "Unique languages", section: "Counts"},
            countryCount: {name: "Unique countries", section: "Counts"},

            totalAVE: {
                name: "Traditional AVE",
                description: account.showAVE && account.showTraditionalAVE
                    ? "Shows AVE from traditional media sources, such as newspapers, television and radio"
                    : "AVE for traditional media sources is not supported in this account",
                disabled: !account.showAVE || !account.showTraditionalAVE,
                section: "Advanced"
            },
            totalOnlineAVE: {
                name: "Online AVE",
                description: account.showAVE
                    ? "Shows estimated AVE values for social media sources, such as Twitter and Facebook"
                    : "AVE is not supported in this account",
                disabled: !account.showAVE,
                section: "Advanced"
            }
        }

        this.curve = {
            smooth: { name: "Smooth" },
            jagged: { name: "Jagged" },
        }

        this.labelPlacement = {
            outside: { name: "Outside"},
            inside: { name: "Inside"},
            hybrid: { name: "Hybrid"},
            legend: { name: "Legend"}
        }

        this.durationFormats = [
            {
                id: "default",
                name: "Hours & minutes",
                subLabel: "Default"
            },
            {
                id: "hours",
                name: "Hours"
            },
            {
                id: "minutes",
                name: "Minutes"
            }
        ];

        this.durationHoursThresholds = [{id: 1},{id: 2},{id: 3, subLabel: "Default"},{id: 4},{id: 5},{id: 6},{id: 7},{id: 8},{id: 9},{id: 10},{id: 11},{id: 12}];

        if (account.segmentLists) {
            let getSegmentSection = segment => {
                if (isCxSolution(segment)) return "Customer Experience";
                if (isRiskSolution(segment)) return "Risk and Reputation";
                if (isTcfSolution(segment)) return "Market Conduct";
                if (idIsRating(segment.id)) return "Rating Scales";
                if (idIsSurvey(segment.id)) return "Surveys";
                return "Segmentation";
            };

            // An account may have non-unique RCPS segments, such
            // as having the finance CX segment on some brands, and the insurance
            // one on another. We differentiate them in this case, otherwise
            // leave the names as they are for better usability.
            let cxCount = 0;
            let channelCount = 0;
            let tcfCount = 0;
            for (const segment of account.segmentLists) {
                if (segment.segmentType?.id === "CX_LIST") cxCount++;
                if (segment.segmentType?.id === "CHANNEL_LIST") channelCount++;
                if (segment.segmentType?.id === "TCF_LIST") tcfCount++;
            }
            account.segmentLists.forEach(s => {
                const disambiguate = s.segmentType?.id === "CX_LIST" && cxCount > 1
                    || s.segmentType?.id === "CONDUCT_LIST" // Always disambiguate. Otherwise clashes with 'risk' RPCS tag.
                    || s.segmentType?.id === "CHANNEL_LIST" && channelCount > 1
                    || s.segmentType?.id === "TCF_LIST" && tcfCount > 2;

                const title = disambiguate
                    ? `${s.subtitle ?? ''} ${s.name.trim()}`.trim()
                    : s.name.trim();
                this.xAxis[s.id] = {
                    name: "By " + title.toLowerCase(),
                    description: substituteTagParamaters(s.clientDescription),
                    section: getSegmentSection(s)
                };
                this.compare[s.id] = {
                    name: title,
                    description: substituteTagParamaters(s.clientDescription),
                    section: getSegmentSection(s)
                };
            })
        }
    }
}

const HEIGHT = [{id:1}, {id:2}, {id:3}, {id:4}, {id:5}, {id:6}]

const WIDTH = [{id:1}, {id:2}, {id:3}, {id:4}, {id:5}, {id:6}, {id:7}, {id:8},
    {id:9}, {id:10}, {id:11}, {id:12}, {id:13}, {id:14}, {id:15}, {id:16}]

const FONT_SIZE = [{id:10}, {id:12}, {id:14}, {id:16}, {id:18}]

const NUMBER_FORMAT = {
    si: { name: "9.2k" },
    space: { name: "9 200" }
}
