<template>

    <div>

        <p v-if="isLoading">
            <span class="spinner">
            </span>
            Searching for mentions ... please wait
        </p>

           <Transition>
               <p v-if="!isLoading && goodness.message">
                   Your phrase will bring in approximately <strong>{{formatNumber(goodness.mentionsPerDay)}} mentions(s) per day</strong>,
                   and <strong>{{formatNumber(goodness.mentionsTotal)}} total mention(s)</strong>.
               </p>
           </Transition>

           <Transition>
               <p v-if="!isLoading && goodness.mentions.length === 0">
                   This search phrase did not return any results at this time. If you would like to edit your search
                   phrase to make it less specific, please return to the previous screen or click Ok to save.
               </p>
           </Transition>

           <Transition>
               <div v-if="!isLoading && goodness.mentions.length > 0">
                   <p>
                       <template v-if="goodness.level === 'HIGH'">
                           Such a high volume of conversation will negatively impact the performance of your account.
                           Please return to the previous screen to refine your search phrase by including more terms or
                           adding words or phrases which you would like to exclude from the search.
                       </template>

                       <template v-if="goodness.level === 'MEDIUM'">
                           This is a high volume of mentions which will impact the relevancy of your overall conversation and
                           make it much harder to derive insights from the data. We suggest clicking Previous
                           and trying a more specific phrase.
                       </template>

                       <template v-if="goodness.level === 'NO'">
                           <i class="symbol-warning"></i> This phrase is expected to pull in far more data than the brand's volume limit in a month.
                           <strong>Note:</strong>
                           This phrase setup page cannot estimate any brand and phrase filters you have added will lower volumes.
                           Be careful when adding this phrase.
                       </template>
                   </p>

                   <p v-if="goodness.level !== 'NO' && goodness.level !== 'HIGH'">
                       Please review the sample of mentions below and select all that you don't feel are relevant. We'll use
                       the irrelevant mentions you select to help you fine tune your search phrase. When you've selected all
                       those that don't apply, click <strong>Next</strong> to proceed.
                   </p>

                   <ul class="mention-list dark-scrollbars dark-scrollbars--visible">
                       <li v-for="mention in goodness.mentions" :key="mention.uri + mention.published" class="goodness-mention" :class="selectedMentions.includes(mention) ? 'selected' : ''" @click="onToggleSelected(mention)">
                           <img class="profile" :src="mention.authorPictureLink" width="48" height="48" alt="mention-profile-image"/>
                           <div class="metadata">
                               <span>From: {{mention.authorName}}</span>
                               <a class="author" :href="mention.uri" target="_blank">@{{mention.authorHandle}}</a>
                               <div class="text" v-text="mention.content"></div>
                           </div>
                           <CheckboxInput :original-value="selectedMentions.includes(mention)"></CheckboxInput>
                       </li>
                   </ul>
               </div>
           </Transition>
    </div>

</template>

<script>
import {getGoodness} from "@/data/goodness";
import {notifyUser, notifyUserOfError} from "@/app/framework/notifications/Notifications";
import VuexStore from "@/store/vuex/VuexStore";
import {formatNumber} from "@/app/utils/Format";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import {QueryForm} from "@/setup/brands/shared/types";

export default {
    name: "PhraseGoodness",
    components: {CheckboxInput},
    store: VuexStore,
    props: {
        rootBrandId: {
            type: Number,
            required: true
        },
        queryFormData: {
            type: QueryForm,
            required: true,
        }
    },

    data() {
        return {
            isLoading: true,
            selectedMentions: [],
            goodness: {
                level: '',
                mentions: [],
                mentionsMaxPerDay: 0,
                mentionsMinPerDay: 0,
                mentionsPerDay: 0,
                mentionsTotal: 0,
                message: '',
                query: '',
                source: '',

            },
        }
    },

    computed: {
    },

    async mounted() {

        this.isLoading = true;
        try {
            const res = await getGoodness(this.rootBrandId, this.queryFormData.query);
            this.goodness = res[0];
            this.$emit('is-good-query-updated', this.goodness.level !== 'HIGH')
            this.$emit('goodness-results-retrieved', this.goodness);
        } catch(error) {
            console.warn('An error occurred while retrieving goodness check: ', error)
            notifyUserOfError('An error occurred while retrieving goodness check')
        } finally {
            this.isLoading = false;
        }
        this.$emit('button-update');
    },

    methods: {
        formatNumber,

        onToggleSelected(clickedMention) {
            this.$emit('is-good-query-updated', this.goodness.level !== 'HIGH')

            if (this.selectedMentions.includes(clickedMention)) {
                this.selectedMentions = this.selectedMentions.filter(mention => mention.published !== clickedMention.published)
                this.$emit('mentions-selected', [...this.selectedMentions]);
            } else {
                this.selectedMentions.push(clickedMention);
                this.$emit('mentions-selected', [...this.selectedMentions]);
            }
        },

    }
}
</script>

<style scoped lang="sass">


.v-enter-active
    transition: all 1s ease-in
.v-leave-active
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1)

.v-enter
    opacity: 0
    transform: translateY(20px)

.v-leave-to
    opacity: 0
    transform: translateY(20px)


.mention-list
    border: 1px solid black
    border-radius: 3px
    max-height: 450px
    overflow-y: auto
    margin: 0
    padding: 5px

    .goodness-mention
        display: flex
        flex-direction: row
        align-items: flex-start
        padding: 10px
        width: 100%
        min-height: 80px
        cursor: pointer
        background-color: rgba(255, 255, 255, 0.05)
        border: 1px solid rgba(255, 255, 255, 0.05)
        border-radius: 3px
        margin: 5px 0 5px 0

        &:hover
            background-color: rgba(255, 255, 255, 0.10)

        img
            width: 50px
            height: 50px
            margin: 0 5px 0 0

        .metadata
            width: 100%

    .selected
        background-color: rgba(255, 0, 0, 0.10)
        border: 1px solid rgba(218, 79, 73, 0.3)

        &:hover
            background-color: rgba(255, 0, 0, 0.10)



</style>