import Vue from "vue";
import Vuex from "vuex";
import MentionModule from "@/store/vuex/modules/MentionModule";
import ExploreModule from "@/store/vuex/modules/ExploreModule";
import UserPlatformAuthModule from "@/store/vuex/modules/UserPlatformAuthModule";
import {mash, beef} from "@/store/Services";
import DashboardModule from "@/store/vuex/modules/DashboardModule";
import DigestsModule from "@/store/vuex/modules/DigestsModule";
import LocationModule from "@/store/vuex/modules/LocationModule";
import SegmentModule from "@/store/vuex/modules/SegmentModule";
import TopicTreeModule from "@/store/vuex/modules/TopicTreeModule";
import StaffModule from "@/store/vuex/modules/StaffModule";
import AccountUserModule from "@/store/vuex/modules/AccountUserModule";
import {isDebugModeEnabled} from "@/app/Features";
import {TAG_PARENT, TagActions, TagGetters, TagMutations} from "@/store/vuex/modules/TagStore";
import {BrandSegmentMutations} from "@/store/vuex/modules/SegmentModule";
import {BrandTopicTreeMutations} from "@/store/vuex/modules/TopicTreeModule";
import HealthModule from "@/store/vuex/modules/HealthModule";
import ProfilesModule from "@/store/vuex/modules/ProfilesModule";
import {Account, Brand} from "@/app/utils/types";
import {logUserSeen} from "@/app/utils/UserAccessLog";
import {PhraseActions, PhraseGetters, PhraseMutations} from "@/store/vuex/modules/PhraseStore";
import {RuleActions, RuleMutations} from "@/store/vuex/modules/RuleStore";
import CrowdModule from "@/store/vuex/modules/CrowdModule";
import WorkingHoursModule from "@/store/vuex/modules/WorkingHoursModule";
import {FeedActions, FeedMutations} from "@/store/vuex/modules/FeedStore";
import {
    AccountTeamsActions,
    AccountTeamsGetters,
    AccountTeamsMutations
} from "@/store/vuex/modules/AccountTeamStore";

Vue.use(Vuex);

let userPromise = null;
let brandPromise = null;
let tagPromise = null;
let engageTeamsPromise = null;
let engageTicketTagsPromise = null

let accountPromise = null;

const BRAND_PARENT = new Brand();
const ACCOUNT_PARENT = new Account();


function setParent(brand) {
    if (!brand.children) return;
    for (const child of brand.children) {
        child.parent = brand;
        setParent(child);
    }
}

/**
 * A general, top level data store, for handling reactivity in our general data.
 * You likely want to be using vuex modules rather than adding anything directly to this.
 */
export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',

    modules: {
        mentionPanel: MentionModule,
        explorePanel: ExploreModule,
        userPlatformAuth: UserPlatformAuthModule,
        dashboards: DashboardModule,
        digests: DigestsModule,
        locations: LocationModule,
        segments: SegmentModule,
        topicTrees: TopicTreeModule,
        staff: StaffModule,
        accountUsers: AccountUserModule,
        health: HealthModule,
        profiles: ProfilesModule,
        crowds: CrowdModule,
        workingHours: WorkingHoursModule
    },

    state: {
        account: null,                      // Information for the current account.
        user: null,                         // The current user, fetched from mash.
        rootBrands: [],                     // The current root brands in the account.
        deletedBrands: [],                  // The deleted root brands in the account.
        tags: [],                           // The account's current list of tags.
        phrases: null,                      // the account's phrases
        rules: null,
        motd: null,                         // The message of the days
        engageTeams: [],                    // Engage teams linked to the account
        feeds: null,
        accountTeams: null,                 // teams for this account
        engageTicketTags: []                // ticket tags for account
    },

    getters: {
        ...PhraseGetters,
        ...TagGetters,
        ...AccountTeamsGetters,

        /** Useful for getting a tag from the given ID, or testing if a tag exists. */
        idToTag: state => {
            const map = new Map();
            for (const tag of state.tags) {
                map.set(tag.id, tag);
            }
            return map
        },
        /** Useful for getting a brand from a brand Id, or for testing if the brand exists */
        idToBrand: state => {
            const map = new Map();

            let brands = [...state.rootBrands, ...state.deletedBrands];

            while (brands.length) {
                const brand = brands.pop();
                map.set(brand.id, brand);
                if (brand.children?.length) brands = [...brands, ...brand.children];
            }

            return map;
        },
        ownedBrands: state => {
            if (!state.rootBrands?.length) return [];
            return state.rootBrands.filter(b => b.category === 'OWN');
        },
        segmentLists: state => {
            if (!state.tags) return null;
            return state.tags.filter(t => t.namespace === "segment_list");
        },
        cxLists: state => {
            if (!state.tags) return null;
            return state.tags.filter(t => t.namespace === "segment_list" && t.segmentType?.id === "CX_LIST");
        },
        /** This returns the list of accounts that the user has access to. Refresh the user to update. */
        userAccounts: state => {
            if (!state.user) return [];
            return state.user.accounts;
        },

        userName: state => `${state.user.firstName?.trim() ?? ''} ${state.user.lastName?.trim()}`.trim(),

        /**
         * @return {null|string}
         */
        userPreferredName: state => {
            const first = state.user?.firstName?.trim();
            if (first?.length) return first;
            const last = state.user?.lastName?.trim();
            if (last?.length) return last;
            return null;
        },
    },

    mutations: {
        setRootBrands: (state, value) => {
            if (value) {
                value.forEach(o => {
                    if (o.deleted) throw new Error(`brand id:${o.id} is deleted. Cannot have deleted brands in the rootBrands state`);
                    o.__proto__ = BRAND_PARENT
                });
            }
            state.rootBrands = value;
        },
        setDeletedBrands: (state, value) => {
            if (value) {
                value.forEach(o => {
                    if (!o.deleted) throw new Error(`brand id:${o.id} is NOT deleted. Cannot have deleted brands in the rootBrands state`);
                    o.__proto__ = BRAND_PARENT
                });
            }
            state.deletedBrands = value;
        },
        setUser: (state, value) => {
            if (value?.id) logUserSeen(value.id).catch(e => console.warn(e));
            state.user = value;
        },
        setTags: (state, value) => {
            if (value?.length) value.forEach(t => t.__proto__ = TAG_PARENT);
            state.tags = value
        },
        setEngageTeams: (state, value) => {
            state.engageTeams = value
        },
        setEngageTicketTags: (state, value) => {
            state.engageTicketTags = value
        },
        setAccount: (state, value) => {
            if (value) value.__proto__ = ACCOUNT_PARENT;
            state.account = value;
        },
        mergeAccountData: (state, updatedAccountData) => {
            if (!state.account) {
                state.account = updatedAccountData;
                state.account.__proto__ = ACCOUNT_PARENT;
                return;
            }

            state.account = Object.assign(state.account, updatedAccountData);
        },
        setUserAdmin: (state, value) => {
            if (state.user) state.user.admin = !!value;
        },
        setUserDebugMode: (state, value) => {
            if (state.user) state.user.debugMode = !!value;
        },
        setUserViewTips: (state, value) => {
            if (state.user) {
                state.user.settings ??= {};
                state.user.settings.viewTips = !!value;
            }
        },
        setUserName: (state, {firstName, lastName}) => {
            if (state.user) {
                state.user.firstName = firstName;
                state.user.lastName = lastName;
            }
        },
        setUserFacebookAuthorised: (state, value) => {
            if (state.user) {
                state.user.facebookAuthorised = value;
            }
        },
        setMotd: (state, value) => state.motd = value,

        ...TagMutations,
        ...BrandSegmentMutations,
        ...BrandTopicTreeMutations,
        ...PhraseMutations,
        ...RuleMutations,
        ...FeedMutations,
        ...AccountTeamsMutations
    },

    actions: {
        ...TagActions,
        ...PhraseActions,
        ...RuleActions,
        ...FeedActions,
        ...AccountTeamsActions,

        async refreshAccount({commit, state}, forceRefresh) {
            if (accountPromise) return accountPromise;
            if (!forceRefresh) return; // We always have account data from startup.
            if (!state.account?.code) return;

            async function impl() {
                try {
                    const response = await mash.get(`/rest/accounts/${state.account.code}?include=usage`);
                    const newAccount = response.data;
                    let account = Object.assign({}, state.account, newAccount);
                    await commit('setAccount', account);
                } finally {
                    accountPromise = null;
                }
            }

            return accountPromise = impl();
        },

        async refreshUser({commit, state}, forceRefresh) {
            forceRefresh ??= false;
            if (!forceRefresh && state.user) return;
            const previousAdmin = state.user?.admin;
            let response = null;
            if (userPromise) response = await userPromise;
            else {
                // We want multiple calls to fetch user data to
                userPromise = mash.get("/rest/users/me");
                response = await userPromise;
                userPromise = null;
            }
            const user = response.data;
            user.originalAdmin = !!user.admin; // Whether the user was originally a mashadmin or not.
            if (previousAdmin !== undefined) user.admin = previousAdmin; // Ensure we keep admin state for staff who have changed it.
            user.debugMode = isDebugModeEnabled();
            user.settings ??= {};
            commit('setUser', user);
        },
        async refreshBrands({state, commit}, forceRefresh = false) {
            if (state.rootBrands?.length && !forceRefresh) return;
            if (!state.account?.code) return;

            // Handle suspended accounts.
            if (state.account?.onlyAdminLogin && !state.user?.admin) return;

            if (brandPromise) return brandPromise;

            try {
                brandPromise = mash.get("/rest/accounts/" + state.account.code + "/brands?includeStats=true&includeDeleted=true&includeInactivePhrases=true");
                const res = await brandPromise;
                const brands = res.data.filter(b => !b.deleted);
                const deleted = res.data.filter(b => b.deleted);

                // Ensure that brands know what their parents are.
                // This does cause a cyclic structure that we cannot serialise for caching
                // via JSON.stringify.
                brands.forEach(b => setParent(b));
                deleted.forEach(b => setParent(b));

                await commit('setRootBrands', brands);
                await commit('setDeletedBrands', deleted);
                await commit('setPhrases', res.data);
            } finally {
                brandPromise = null;
            }
        },

        /**
         *
         * @param state
         * @param commit
         * @param {Brand} brand
         * @return {Promise<*>}
         */
        async updateBrand({state, dispatch}, brand) {
            if (!state.account?.code) return;
            if (!brand.id) throw new Error("brand object should have an ID");

            // Handle suspended accounts.
            if (state.account?.onlyAdminLogin && !state.user?.admin) return;

            await mash.put(`/rest/accounts/${state.account.code}/brands/${brand.id}`, brand);

            // This is very much not ideal. We should patch in the response from the mash put statement.
            // But mash does not currently return the same data as with the brands list endpoint,
            // and this will be tricky and potentially buggy to change in mash.
            await dispatch('refreshBrands', true);
        },

        /**
         * For adding a brand
         * @param state
         * @param commit
         * @param {Brand} brand
         * @return {Promise<*>}
         */
        async addBrand({state, dispatch}, brand) {
            if (!state.account?.code) return;

            // Handle suspended accounts.
            if (state.account?.onlyAdminLogin && !state.user?.admin) return;

            const res = await mash.post(`/rest/accounts/${state.account.code}/brands`, brand);

            // This is very much not ideal. We should patch in the response from the mash put statement.
            // But mash does not currently return the same data as with the brands list endpoint,
            // and this will be tricky and potentially buggy to change in mash.
            await dispatch('refreshBrands', true);
        },

        /**
         * For importing a brand
         * @param state
         * @param commit
         * @param {Number} brandId
         * @return {Promise<*>}
         */
        async importBrand({state, dispatch}, brandId) {
            if (!state.account?.code) return;

            // Handle suspended accounts.
            if (state.account?.onlyAdminLogin && !state.user?.admin) return;

            await mash.post(`/rest/accounts/${state.account.code}/brands`, {importId: brandId});
            await dispatch('refreshBrands', true);
        },

        /**
         * For deleting a brand
         * @param state
         * @param commit
         * @param {Number} brandId
         * @return {Promise<*>}
         */
        async deleteBrand({state, dispatch}, brandId) {
            if (!state.account?.code) return;

            // Handle suspended accounts.
            if (state.account?.onlyAdminLogin && !state.user?.admin) return;

            await  mash.delete(`/rest/accounts/${state.account.code}/brands/${brandId}`);
            await dispatch('refreshBrands', true);
        },


        async refreshTags({state, commit}, forceRefresh = false) {

            if (!state.account?.code) return;
            if (state.tags.length && !forceRefresh) return;
            if (tagPromise) return tagPromise;

            tagPromise = mash.get("/rest/accounts/" + state.account.code + "/tags");
            try {
                const res = await tagPromise;
                const tags = res.data;

                const idToTag = new Map();
                for (const t of tags) {
                    t.__proto__ = TAG_PARENT;
                    idToTag.set(t.id, t)
                }

                const setParent = tag => {
                    if (!tag.children) return;
                    for (const childId of tag.children) {
                        const child = idToTag.get(childId);
                        if (child) {
                            child.parent = tag;
                            setParent(childId);
                        }
                    }
                };

                tags.forEach(t => setParent(t));
                await commit('setTags', tags);
            } finally {
                tagPromise = null;
            }
        },
        async refreshEngageTeams({state, commit}, forceRefresh) {
            forceRefresh ??= false;

            if (!state.account?.code) return;
            if (state.engageTeams.length && !forceRefresh) return;
            if (engageTeamsPromise) return engageTeamsPromise;

            engageTeamsPromise = beef.get("/api/engage/teams/map?accountCode=" + state.account.code);
            try {
                const res = await engageTeamsPromise;
                const engageTeams = res.data;

                // data returned is a map structured as { id: teamName } -> convert to array
                let engageTeamsList = [];
                for (const teamKey in engageTeams) {
                    let engageTeam = {
                        id: teamKey,
                        name: engageTeams[teamKey]
                    }

                    engageTeamsList.push(engageTeam);
                }

                await commit('setEngageTeams', engageTeamsList);
            } finally {
                engageTeamsPromise = null;
            }
        },
        async refreshEngageTicketTags({state, commit}, forceRefresh) {
            forceRefresh ??= false;

            if (!state.account?.code) return;
            if (state.engageTicketTags.length && !forceRefresh) return;
            if (engageTicketTagsPromise) return engageTicketTagsPromise;

            engageTicketTagsPromise = beef.get(`/api/engage/accounts/${state.account.code}/ticketTags`);
            try {
                const res = await engageTicketTagsPromise;
                const engageTicketTags = res.data;
                await commit('setEngageTicketTags', engageTicketTags);
            } finally {
                engageTicketTagsPromise = null;
            }
        }
    }
});
