<template>
    <div class="move-brand-row">
        <div class="row-control" @click="toggleCheckboxValue">
            <CheckboxInput class="parent" ref="parentRow" v-if="!isChild" :label="branch.name" v-model="isChecked" :id="'move' + branch.id"
                            @change="onCheckboxChanged"></CheckboxInput>
            <CheckboxInput class="child" ref="childRow" v-if="isChild" :label="branch.name" v-model="isChecked" :id="'move' + branch.id"
                            @change="onCheckboxChanged"></CheckboxInput>
            <div v-if="branch.children" class="toggle-container" @click="toggleRowChildrenDisplay">
                <span class="caret"></span>
            </div>
        </div>
        <template v-if="showChildren">
            <div class="children" v-for="(leaf) in branch.children" :key="branch.id + '-' + leaf.id" :id="'move' + branch.id">
                <MoveBrandRow :current-parent-id="form.id" :branch="leaf" :isChild="true" @parent-updated="onChildUpdated"></MoveBrandRow>
            </div>
        </template>
    </div>
</template>

<script>
import MoveBrandRow from "./MoveBrandRow.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";

export default {
    name: "MoveBrandRow",
    props: ['currentParentId', 'branch', 'isChild'],
    components: {CheckboxInput},

    data() {
        return {
            isChecked: this.branch.id === this.currentParentId,
            form: {
                id: this.currentParentId
            },
            showChildren: false,
        }
    },

    mounted() {
        this.form.id = this.currentParentId;
        this.showChildren = this.branch.children?.find(b => {
            return b.id === this.currentParentId || b.children?.find(c => c.id === this.currentParentId)
        })
    },

    watch: {
        currentParentId(newValue) {
            this.form.id = newValue;
            this.isChecked = this.branch.id === newValue;
        },
    },

    methods: {

        toggleRowChildrenDisplay() {
            this.showChildren = !this.showChildren;
        },

        toggleCheckboxValue(event) {
            if (!event.target.classList.contains('caret') && !event.target.classList.contains('toggle-container')) {
                const checkbox = this.$refs.parentRow || this.$refs.childRow;
                checkbox.internalData = !checkbox.internalData;
            }
        },

        onCheckboxChanged(event) {
            if (event === true) {
                this.$emit('parent-updated', this.branch.id)
            }
        },

        onChildUpdated(event) {
            this.isChecked = this.branch.id === event
            this.form.id = event;
            this.$emit('parent-updated', event)

        },
    }
}
</script>


<style scoped lang="sass">

.move-brand-row
    .row-control
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        padding: 5px
        cursor: pointer

        &:hover
            color: var(--be-colour-text-dark__hover)
            background-color: rgba(85, 85, 85, 0.5)

        .deq-checkbox-input
            width: 100%
            pointer-events: unset

        .toggle-container
            width: 20px
            height: 20px
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer
            pointer-events: unset

            &:hover
                .caret
                    border-top-color: var(--be-colour-text-dark__hover)

.children
    margin-left: 23px
    .row-control
        border: none

.deq-checkbox-input
    gap: 8px

</style>