<template>

    <div class="deq-color-picker">

        <div class="input">
            <p>Colour:</p>
            <div class="color-picker deq-input-div" :style="{'background-color': selectedColor}"
                 @click="onShowInput">
            </div>
        </div>

        <input ref="colorInput" type="color" id="brandColor" name="brandColor" :value="selectedColor"
               @change="onColourChange">
    </div>

</template>

<script>
const eventName = 'color-selected';

export default {
    name: "ColorPicker",
    components: {},

    model: {
        prop: "originalColor",
        event: eventName
    },
    props: {
        originalColor: {
            type: String,
            required: false,
            default: ''
        }
    },

    data() {
        return {
            selectedColor: this.originalColor ? this.originalColor : '',
            showInput: false,
        }
    },

    watch: {
        originalColor(newVal) {
            this.selectedColor = newVal;
        }
    },

    methods: {
        onShowInput() {
            this.showInput = true;
            this.$refs.colorInput.click()
            this.$refs.colorInput.focus()
        },

        onColourChange(colorEvent) {
            this.selectedColor = colorEvent.target.value;
            this.$emit(eventName, this.selectedColor);
        },

    }
}
</script>

<style scoped lang="sass">

.deq-color-picker
    width: fit-content

    .input
        display: flex
        flex-direction: row
        gap: 5px
        align-items: flex-end
        margin-bottom: 0

    p
        position: relative
        top: 2px
        margin: 0
        margin-block-start: 0
        margin-block-end: 0

.color-picker
    height: 20px
    width: 35px
    cursor: pointer
    border-radius: 2px

#brandColor
    height: 0
    width: 0
    padding: 0
    border: none
    background-color: transparent
    transition: color linear 0.3s
    position: absolute
</style>