import { render, staticRenderFns } from "./SetupPanel.vue?vue&type=template&id=699c489a&scoped=true"
import script from "./SetupPanel.vue?vue&type=script&lang=js"
export * from "./SetupPanel.vue?vue&type=script&lang=js"
import style0 from "./SetupPanel.vue?vue&type=style&index=0&id=699c489a&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "699c489a",
  null
  
)

export default component.exports