import {readonly} from "@vue/runtime-dom";
import {notifyUserOfError} from "@/app/framework/notifications/Notifications";
import {biryani} from "@/store/Services";
import {currentAccountCode} from "@/app/utils/Account";
import {getUser} from "@/app/utils/User";
import {features} from "@/app/Features";

export class ImageBlock {

    constructor({api, data, readOnly}) {
        this.api = api;
        this.uniqueId = `image-block-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
        this.wrapper = document.createElement("div");
        this.wrapper.style.color = 'black';

        this.src = undefined;
        this.imageEl = document.createElement("div");
        this.img = document.createElement("img");
        this.img.alt = "Image";
        this.imageEl.appendChild(this.img);

        this.caption = document.createElement("input");
        this.caption.type = "text";
        this.caption.placeholder = "Caption";
        this.caption.setAttribute("class", "image-block-caption");

        let label = "Browse";
        let message = "No image selected";
        if (data.src) {
            this.initialize(data).catch(error => {
                console.error("Error initializing image:", error);
            });
            label = "Edit";
            message = this.fileName = data.fileName;
        } else {
            this.imageEl.style.opacity = '0';
            this.caption.style.opacity = '0';
        }

        const labelElement = document.createElement("label");
        labelElement.setAttribute("class", "image-block-upload-label");
        labelElement.setAttribute("for", `upload-image-${this.uniqueId}`);

        const uploadElement = document.createElement("span");
        uploadElement.setAttribute("class", 'btn upload-image-btn');
        uploadElement.textContent = label;

        const spanElement = document.createElement("span");
        spanElement.textContent = message;

        labelElement.appendChild(uploadElement);
        labelElement.appendChild(spanElement);

        this.addImage = document.createElement("div");
        this.addImage.innerHTML = `<input class="image-block-upload-el" type="file" name="image" accept="image/png, image/jpeg" id="upload-image-${this.uniqueId}" />`;
        this.addImage.addEventListener('change', () => {
            const file = this.addImage.querySelector('input').files[0];
            if (file.size > 2097152) {
                notifyUserOfError("File can not be larger than 2mb");
                return;
            }

            this.fileName = file.name;
            spanElement.textContent = file.name;
            const reader = new FileReader();
            let base64String;
            reader.onload = async (event) => {

                if (features.commentaryPlusImageToolEnhancement()) {
                    let buffer;
                    if (event.target.result instanceof ArrayBuffer) {
                        buffer = event.target.result;
                    } else {
                        buffer = Uint8Array.from([...event.target.result]).buffer;
                    }
                    if (!(buffer instanceof ArrayBuffer)) {
                        throw new Error('Failed to get valid ArrayBuffer from file');
                    }
                    const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
                    const hashArray = Array.from(new Uint8Array(hashBuffer));
                    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                    // create biryani asset
                    const res = await biryani.post(`/rest/v1/${currentAccountCode()}/assets`,
                        {
                            assetName: hashHex + Date.now().toString(),
                            actorId: getUser().id
                        });

                    const assetId = res.data;
                    //upload file to asset
                    const formData = new FormData();
                    formData.append('file', file);
                    await biryani.post(`/rest/v1/${currentAccountCode()}/assets/${assetId}/upload?fileType=${file.type}&actorId=${getUser().id}&tags=platformName;analyse`, formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            }
                        });

                    this.initialize({
                        src: `/rest/v1/${currentAccountCode()}/assets/${assetId}`,
                        version: 2
                    }).catch(error => {
                        console.error("Error initializing image:", error);
                    });
                } else {
                    base64String = btoa(reader.result);
                    this.img.src = this.src = `data:image/png;base64,${base64String}`;
                    this.imageEl.style.opacity = '1';
                    this.caption.style.opacity = '1';
                }
                label = 'Edit';
            };
            reader.readAsBinaryString(file);
        })

        this.wrapper.appendChild(this.imageEl);
        if (!readOnly) {
            this.wrapper.appendChild(this.caption);
            this.wrapper.appendChild(labelElement);
            this.wrapper.appendChild(this.addImage);

            this.imageEl.style.resize = 'inline';
            this.imageEl.style.overflow = 'auto';
        } else {
            const captionReadOnly = document.createElement("div");
            captionReadOnly.textContent = data.caption;
            this.wrapper.appendChild(captionReadOnly);
        }
    }

    static get toolbox(){
        return {
            title: 'Image',
            icon: '<i class="icon-picture-1"></i>',
        }
    }

    render(){
        return this.wrapper;
    }

    static get isReadOnlySupported() {
        return !0;
    }

    save(t){
        return {
            fileName: this.fileName,
            src: this.src,
            caption: this.caption.value,
            width: this.imageEl.style.width,
            version: features.commentaryPlusImageToolEnhancement() ? 2 : 1
        }
    }

    async initialize(data) {
        this.src = data.src;
        if (data.version === 2) {
            const response = await biryani.get(this.src + "?userId=" + getUser().id);
            const token = response.data.token;
            this.img.src = `${biryani.defaults.baseURL}${this.src}?userId=${getUser().id}&token=${token}&tag=platformName;analyse`;
        } else {
            this.img.src = this.src = data.src;
        }
        this.caption.value = data.caption ?? "";
        this.imageEl.style.width = data.width;
        this.imageEl.style.opacity = '1';
        this.caption.style.opacity = '1';

    }

}
