<template>

    <div class="phrase-count-cell">
        <template>
            <TooltipComponent>
                <span class="phrase-length">
                    {{brand.phrases ? brand.phrases.length : ''}}
                </span>
                <template #tooltip>
                    {{ brand.phrases ? `There are ${brand.phrases.length} search phrase(s) associated with this brand or sub-brand. Click to show the phrases` : '' }}
                </template>
            </TooltipComponent>
        </template>
        <i v-if="showPhrases" @click="emitToggle(false)" class="minus icon-up-open"></i>
        <i v-if="!showPhrases" @click="emitToggle(true)" class="plus icon-down-open"></i>
    </div>

</template>

<script>
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";

export default {
    name: "PhraseCountCell",
    components: {TooltipComponent},
    props: ['brand', 'showPhrases'],

    methods: {
        emitToggle(shouldShow) {
            this.$emit('toggle-phrases', shouldShow)
        }
    }
}
</script>



<style scoped lang="sass">

.phrase-count-cell
    display: flex
    justify-content: space-between

    .phrase-length, i
        width: 50%

</style>