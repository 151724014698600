<template>
  <DialogBox ref="dialog" modal title="Confirmation" width="500px" @close="onCancel">
    <div class="deq-reset confirmation">
      <p>{{message}}</p>
    </div>

    <template #buttons>
      <div class="deq-dialog-buttons">
        <BeButton :disabled="isSaving" @click="onCancel">No</BeButton>
        <BeButton :primary="true" :disabled="isSaving" @click="onConfirm">Yes</BeButton>
      </div>
    </template>
  </DialogBox>


</template>

<script>
import DialogBox from "@/components/DialogBox.vue";
import BeButton from "@/components/buttons/BeButton.vue";

export default {
  name: "ConfirmationDialog",
  components: {BeButton, DialogBox},
    props: {
        message: {
            type: String,
            required: false,
            default: 'Are you sure?'
        },
        onYesClick: {
            type: Function,
            required: true,
        },
        onYesArgs: {
            type: [],
            required: false,
        },
        onNoClick: {
            type: Function,
            required: false
        }
    },

  data() {
    return {
      isSaving: false
    }
  },

  mounted() {
  },

  methods: {

    async onConfirm() {
      this.isSaving = true;
      await this.onYesClick();
      this.isSaving = false;
      this.emitClose();
    },

    onCancel() {
        if (this.onNoClick) {
          this.onNoClick();
        }
      this.emitClose();
    },

    emitClose() {
      this.$emit('close');
    }
  },
}
</script>

<style scoped lang="sass">

.confirmation
  p
    text-align: center

.deq-dialog-buttons
  justify-content: center
  gap: 20px

</style>