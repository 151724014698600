<template>

    <DialogBox modal :title="isNew ? 'Add a sub-brand' : 'Edit the sub-brand'" class="edit-brand-dialog" width="550px" @close="emitClose()">
        <div class="deq-reset edit-brand-form">
            <div class="edit-brand-row">
                <label v-if="brand.level === 0">I am interested in conversation about {{brand.name}}'s</label>
                <label v-if="brand.level > 0">More specifically, I want to listen to conversation about</label>
                <TextInput :width="'100%'" :required="true" v-model="formData.name" class="text-input"></TextInput>
                <label v-if="brand.level === 0">which I believe impacts its reputation </label>
                <label v-if="brand.level > 0">as one of {{brand.parent.name}}'s {{brand.name}} and how it impacts the sentiment towards {{brand.parent.name}}</label>
            </div>

            <p class="text" v-if="brand.level === 0">Consider including conversation categories like employees, branches, products or sponsorships </p>

            <div class="edit-brand-row">
                <label>Client facing brand name</label>
                <TextInput :width="'100%'" v-model="formData.shortName" class="text-input" :placeholder="'Client facing brand name'"></TextInput>
            </div>

            <CheckboxInput v-model="formData.subPhraseMatching" :label="'Partial phrase matching'"></CheckboxInput>

            <p>Use this option to further classify mentions (e.g. to identify branches by name) without having to
                include the name of the brand in each search phrase. Phrases only match mentions that have already
                matched phrases for other brands. When enabled this option applies to this brand and all brands below
                it in the tree.
            </p>
        </div>


        <template #buttons>
            <div class="deq-dialog-buttons edit-brand-buttons">

                <div class="left-side">
                    <BeButton v-if="!isNew" link @click="onDelete" :disabled="isSaving">
                        <TooltipComponent>
                            <span>Delete</span>
                            <template #tooltip>Delete this brand</template>
                        </TooltipComponent>
                    </BeButton>

                    <BeButton v-if="!isNew" link @click="onMove" :disabled="isSaving">
                        <TooltipComponent>
                            <span>Move</span>
                            <template #tooltip>Move this brand to a different brand</template>
                        </TooltipComponent>
                    </BeButton>
                </div>

                <div class="saving-spinner">
                    <SpinnerComponent v-if="isSaving" :size="16"/>
                </div>

                <div class="right-side">
                    <BeButton @click="emitClose" :disabled="isSaving" link>
                        <TooltipComponent>
                            <span>Cancel</span>
                            <template #tooltip>Discard changes (Esc)</template>
                        </TooltipComponent>
                    </BeButton>

                    <BeButton @click="onSave" :disabled="!isFormValid || isSaving">Ok</BeButton>
                </div>
            </div>
        </template>
    </DialogBox>
</template>


<script>
import DialogBox from "@/components/DialogBox.vue";
import BeButton from "@/components/buttons/BeButton.vue";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import {mapActions, mapState} from "vuex";
import VuexStore from "@/store/vuex/VuexStore";
import {notifyUser} from "@/app/framework/notifications/Notifications";
import {mash} from "@/store/Services";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import MoveBrandDialog from "@/setup/brands/forms/edit-brand/move-brand/MoveBrandDialog.vue";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";

export default {
    name: "EditSubBrandDialog",
    props: {
        brand: {},
        isNew: {
            type: Boolean,
            required: false,
            default: false
        },
        searchToggles: {}
    },
    components: {TooltipComponent, CheckboxInput, TextInput, SpinnerComponent, BeButton, DialogBox},
    store: VuexStore,

    data() {
        return {
            isFormValid: !this.isNew && this.brand.name,
            isSaving: false,
            formData: {
                name: !this.isNew ? this.brand.name : '',
                shortName: !this.isNew ? this.brand.shortName : '',
                subPhraseMatching: !this.isNew ? this.brand.subPhraseMatching : false
            }
        }

    },

    computed: {
        ...mapState(['account']),
    },

    watch: {
      'formData.name'(newName) {
          this.isFormValid = !!this.formData.name.length
      }

    },

    methods: {
        ...mapActions(['updateBrand', 'addBrand', 'deleteBrand']),

        emitClose() {
            this.$emit('close');
        },

        async onDelete() {
            this.isSaving = true;
            try {
                await this.deleteBrand(this.brand.id);
                notifyUser(`Successfully deleted ${this.brand.name || this.brand.shortName}.`);
                this.emitClose();
            } catch (error) {
                console.warn(`An error occurred deleting brand. `, error)
            } finally {
                this.isSaving = false;
            }
        },

        onMove() {
            showDialogComponent(MoveBrandDialog, {brand: this.brand, searchToggles: this.searchToggles});
            this.emitClose();
        },

        async onSave() {
            this.isSaving = true;

            try {
                if (!this.formData.name) {
                    this.isFormValid = false;
                    throw new Error('name is required')
                }

                if (this.isNew) {
                    const request = {
                        parent: this.brand.id,
                        name: this.formData.name ? this.formData.name.trim() : '',
                        shortName: this.formData.shortName ? this.formData.shortName.trim() : '',
                        subPhraseMatching: this.formData.subPhraseMatching,
                        supportProfileIds: [],
                        otherProfileIds: []
                    };

                    await this.addBrand(request);
                    this.createUserMessage();
                    this.emitClose();
                } else {
                    const request = {
                        id: this.brand.id,
                        name: this.formData.name ? this.formData.name.trim() : '',
                        shortName: this.formData.shortName ? this.formData.shortName.trim() : '',
                        subPhraseMatching: this.formData.subPhraseMatching,
                        supportProfileIds: [],
                        otherProfileIds: []
                    };

                    await this.updateBrand(request);
                    this.createUserMessage();
                    this.emitClose();

                }

            } catch (e) {
                console.warn('An error occurred while saving: ', e);
            } finally {
                this.isSaving = false;
            }
        },

        createUserMessage() {
            if (this.isNew) {
                notifyUser(`Successfully created ${this.brand.name || this.brand.shortName}.`);
            } else {
                notifyUser(`Successfully updated ${this.brand.name || this.brand.shortName}.`);
            }
        }
    }
}
</script>

<style scoped lang="sass">

@use "../../tree/edit-brand-dialog"

.edit-brand-loader
    margin: auto

.text
    color: var(--deq-color-text-label)


</style>