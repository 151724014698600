<template>
    <div class="edit-brand-cell">
        <template v-if="canEdit">
            <div class="edit-btn">
                <TooltipComponent>
                    <i v-if="brand.deleted" class="icon-reply" @click="showEditBrandDialog"
                       :class="{'deq-can-edit': canEdit}">
                    </i>
                    <template #tooltip>Undelete</template>
                </TooltipComponent>
                <i v-if="!brand.deleted" class="icon-pencil" :class="{'deq-can-edit': canEdit}"
                   @click="showEditBrandDialog"></i>
            </div>
        </template>
    </div>
</template>

<script>
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import EditSubBrandDialog from "@/setup/brands/forms/edit-brand/EditSubBrandDialog.vue";
import EditRootBrand from "@/setup/brands/forms/edit-brand/EditRootBrand.vue";
import AddPhraseDialog from "@/setup/brands/forms/add-edit-phrase/AddPhraseDialog.vue";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";

export default {
    name: "EditBrandCell",
    components: {TooltipComponent},
    props: ['brand'],

    inject: ['rootBrandId', 'searchToggles'],

    computed: {
        canEdit() {
            return this.brand.parent ? this.brand.parent.canEdit : this.brand.canEdit;
        }
    },

    methods: {
        showEditBrandDialog() {
            if (!this.canEdit) return
            if (this.brand.level === 0) {
                showDialogComponent(EditRootBrand, {brand: this.brand})
            }

            if (this.brand.level > 0) {
                showDialogComponent(EditSubBrandDialog, {brand: this.brand, searchToggles: this.searchToggles})
            }

            if (this.brand.level === undefined) {
                // the brand is a phrase, show editPhrase dialog
                showDialogComponent(AddPhraseDialog, {phrase: {brandId: this.brand.parent.id, ...this.brand}, rootBrandId: this.rootBrandId().id, searchToggles: this.searchToggles})
            }
        },
    }
}
</script>

<style scoped lang="sass">
@use "../edit-brand-dialog"

.edit-brand-cell
    display: flex
    flex-direction: row
    justify-content: center

</style>