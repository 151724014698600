<template>

    <DialogBox modal :title="'Import a Brand'" class="edit-brand-dialog import-brand-dialog" width="600px" @close="emitClose()">

        <div class="ibd-body deq-reset dark-scrollbars dark-scrollbars--visible">
            <TextInput v-model="searchText" :width="'100%'" placeholder="Search by brand name" class="ibd-input"></TextInput>

            <div v-if="!isLoading" class="import-option-table">

                <div class="iot-header brand">Brand</div>
                <div class="iot-header account">Account</div>
                <div class="iot-header tier">Tier</div>
                <div class="iot-header sample">Sample %</div>
                <div class="iot-header topic">Topic %</div>

                <div v-for="brand in filteredOptions" :key="brand.id" class="import-option"
                      @click="onBrandSelect(brand)" :class="{selected: selectedBrand && selectedBrand.id === brand.id}">
                        <div class="brand">{{brand.name}}</div>
                        <div class="account">
                            <TooltipComponent>
                                <span class="code">{{brand.accountCode}}</span>
                                <template #tooltip>
                                    {{brand.accountName}}
                                </template>
                            </TooltipComponent>
                        </div>
                        <div class="tier">{{brand.tier}}</div>
                        <div class="sample">{{brand.crowdSamplePercentage}}</div>
                        <div class="topic">{{brand.crowdTopicPercentage}}</div>
                </div>
            </div>

            <LoadingMessage v-if="isLoading" :message="'Retrieving brands for import...'" class="edit-brand-loader import-brand-loader" />

            <ErrorMessage v-if="!isLoading && loadingError"></ErrorMessage>
        </div>


        <template #buttons>
            <div class="deq-dialog-buttons edit-brand-buttons">

                <div class="left-side">
                </div>

                <div class="saving-spinner">
                    <SpinnerComponent v-if="isSaving" :size="16"/>
                </div>

                <div class="right-side">

                    <BeButton v-if="!isSaving" @click="emitClose" :disabled="isSaving" link>
                        <TooltipComponent>
                            <span>Cancel</span>
                            <template #tooltip>Discard changes (Esc)</template>
                        </TooltipComponent>
                    </BeButton>

                    <BeButton @click="onImportClick" :disabled="!selectedBrand || isSaving">
                        Import Brand
                    </BeButton>
                </div>
            </div>
        </template>
    </DialogBox>
</template>

<script>
import DialogBox from "@/components/DialogBox.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";
import BeButton from "@/components/buttons/BeButton.vue";
import {mash} from "@/store/Services";
import {mapActions, mapState} from "vuex";
import VuexStore from "@/store/vuex/VuexStore";
import LoadingMessage from "@/components/LoadingMessage.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";

export default {
    name: "ImportBrandDialog" ,
    components: {ErrorMessage, LoadingMessage, BeButton, TooltipComponent, SpinnerComponent, TextInput, DialogBox},
    store: VuexStore,

    data() {
        return {
            searchText: '',
            isSaving: false,
            isLoading: true,
            loadingError: false,
            AllOptionsForImport: [],
            selectedBrand: undefined,
        }
    },

    computed: {
        ...mapState(['account']),

        filteredOptions() {
            return this.AllOptionsForImport.filter(brand => {
                return brand.name.toLowerCase().includes(this.searchText.toLowerCase());
            })

        }

    },

    async mounted() {
        this.isLoading = true;

        try {
            const promise = await mash.get(`/rest/accounts/${this.account.code}/brands/for-import`);
            this.AllOptionsForImport = promise.data;
        } catch (e) {
            console.warn(e)
            this.loadingError = true
        } finally {
            this.isLoading = false;
        }
    },

    methods: {
        ...mapActions(['importBrand']),

        emitClose() {
            this.$emit('close')
        },

        async onBrandSelect(brand) {
            this.selectedBrand = brand;
        },

        async onImportClick() {
            this.isSaving = true;

            try {
                await this.importBrand(this.selectedBrand.id)
                this.$emit('imported', this.selectedBrand.id)
            } catch (e) {
                console.warn(e)
                this.loadingError = true
            } finally {
                this.isSaving = false;
                this.emitClose()
            }


        }
    }
}
</script>


<style scoped lang="sass">
@use "../tree/edit-brand-dialog"

$border: 1px solid black

.ibd-body
    height: 60vh
    overflow-y: scroll

.ibd-input
    ::v-deep input
        padding: 15px

.import-brand-loader
    --loading-message-delay: 0ms
    margin: auto

.import-option-table
    height: fit-content
    display: grid
    grid-template-columns: repeat(13, 1fr)
    background-color:  var(--table-background-colour)
    border: $border

    .iot-header
        background-color:  var(--table-header-background-colour)

    .brand
        grid-column-end: span 5

    .brand, .account, .tier, .sample, .topic
        padding: 10px

    .account, .tier, .sample, .topic
        grid-column-end: span 2

    .selected
        background-color: #85b03d
        color: #fff

        .account
            .code
                color: #fff

        .brand, .account, .tier, .sample, .topic
            background-color: #85b03d

    .import-option
        display: contents

        .account
            color: #aee15d

        .brand, .account, .tier, .sample, .topic
            align-content: center

        .brand, .account, .tier, .sample, .topic
            border-bottom: $border
            height: 60px

        &:last-of-type
            .brand, .account, .tier, .sample, .topic
                border-bottom: none

        &:hover
            .brand, .account, .tier, .sample, .topic
                background-color: var(--table-row-hover-bg-colour)
                color: var(--table-row-hover-text-colour)
                cursor: pointer

        &:where(.selected)
            &:hover
                .brand, .account, .tier, .sample, .topic
                    background-color: #85b03d
                    color: unset
                    cursor: pointer
</style>