<template>

    <DialogBox modal title="Edit or add a brand description" class="deq-reset edit-brand-description-dialog" width="500px" @close="emitClose()">
        <div class="form-container">
            <LanguagePickerComponent :is-inside-dialog="true" :selected-language="formData.language.value"
                                       @selection="onLanguageSelection" @isValid="onLanguageValidity"
                                       :label-text="'Language'"/>

            <TextInput required label="Description" width="100%" name="description" v-model="formData.description.value"
                        placeholder="A description for the crowd here" class="description-input"/>
        </div>

        <template #buttons>
            <div class="edit-description-footer">
                <BeButton link @click="emitClose()" :disabled="saving" class="cancel-btn">Cancel</BeButton>
                <div class="form-submission-btns">
                    <BeButton v-if="operation === 'create'" :primary="true"
                               :disabled="disableSaveBtb" @click="onCreate">
                        Create
                    </BeButton>
                    <BeButton v-if="operation === 'update'" link :disabled="saving"
                               @click="onDelete">
                        Delete
                    </BeButton>
                    <BeButton v-if="operation === 'update'" :primary="true"
                               :disabled="disableSaveBtb" @click="onUpdate">
                        Update
                    </BeButton>
                </div>
            </div>
        </template>
    </DialogBox>
</template>

<script>
import DialogBox from "@/components/DialogBox.vue";
import LanguagePickerComponent from "@/setup/brands/inputs/LanguagePickerComponent.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import BeButton from "@/components/buttons/BeButton.vue";
import {mapActions} from "vuex";
import VuexStore from "@/store/vuex/VuexStore";
import {notifyUser} from "@/app/framework/notifications/Notifications";
import {LANGUAGES} from "@/app/utils/Language";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import ConfirmationDialog from "@/setup/brands/forms/ConfirmationDialog.vue";

export default {
    name: "EditBrandDescriptionDialog",
    props: ['operation', 'inputDescription', 'inputLanguage', 'brand'],
    components: {BeButton, TextInput, LanguagePickerComponent, DialogBox},
    store: VuexStore,

    data() {
        return {

            formData: {
                language: {
                    value: this.inputLanguage ? this.inputLanguage : '',
                    isValid: true,
                },
                description: {
                    value: this.inputDescription ? this.inputDescription : '',
                    isValid: true,
                },
            },
            saving: false,
            operationType: '',
            crudBrand: {
                id: this.brand.id,
                name: this.brand.name,
                description: {}
            }

        }
    },

    mounted() {
        this.formData.description.isValid = this.formData.description.value.length > 0;
        this.formData.language.isValid = this.formData.language.value.length > 0;
    },

    computed: {

        disableSaveBtb() {
            return !this.formData.language.isValid || !this.formData.description.isValid || this.saving;
        },
    },

    watch: {
        'formData.description.value'(newVal) {
            this.formData.description.isValid = newVal.length > 0;
        },

        'formData.language.value'(newVal) {
            this.formData.language.isValid = newVal.length > 0;
        }
    },

    methods: {
        ...mapActions(['updateBrand']),

        emitClose() {
            this.$emit('close');
        },

        onLanguageSelection(language) {
            this.formData.language.value = language
        },

        onLanguageValidity(event) {
            this.formData.language.isValid = event
        },

        async onDelete() {
            this.saving = true

            const description = {...this.brand.description};
            delete description[this.inputLanguage]
            this.crudBrand.description = description;

            this.operationType = 'deleted';
            await this.executeStoreUpdate();
        },

        onUpdate() {
            this.onCreate();
        },

        async onCreate() {

            this.saving = true

            this.crudBrand.description = {...this.brand.description, [this.formData.language.value]: this.formData.description.value};

            if (this.brand.description[this.formData.language.value]) {
                this.operationType = 'updated';
                showDialogComponent(ConfirmationDialog, {
                    message: `A '${LANGUAGES[this.formData.language.value]}' description already exists, are you sure you want to replace it?`,
                    onYesClick: this.executeStoreUpdate,
                    onNoClick: this.onCancel
                })
                return
            }

            this.operationType =  'created';
            await this.executeStoreUpdate();
        },

        async executeStoreUpdate() {
            try {
                await this.updateBrand(this.crudBrand)
                this.emitClose();
                notifyUser(`${LANGUAGES[this.formData.language.value]} description ${this.operation}.`);
            } catch (error) {
                console.warn("An error occurred creating description")
            } finally {
                this.saving = false;
            }
        },

        onCancel() {
            this.emitClose();
            this.saving = false
        }
    }
}
</script>

<style scoped lang="sass">

.form-container
    height: 150px
    display: flex
    flex-direction: column
    gap: 10px

    ::v-deep .control-group input
        height: 31px

.edit-description-footer
    display: flex
    flex-direction: row
    justify-content: space-between

    .form-submission-btns
        display: flex
        flex-direction: row
        gap: 10px

</style>