<template>


    <div class="phrase-tune-page">
        <LoadingMessage v-if="isLoading" :message="'Setting up your brand form...'" class="edit-brand-loader" />

        <div v-if="!isLoading">

            <template v-if="!words.length">
                <p v-if="hasIrrelevantMentions">
                    The mentions that you indicated as irrelevant didn't contain any words that
                    distinguish them from the other mentions. Click Ok to save this phrase or return to the previous
                    screen to make changes.
                </p>

                <p v-if="!hasIrrelevantMentions">
                    You did not indicate that any of the sample mentions were irrelevant.
                    Click Ok to save this search phrase or return to the previous screen to make changes.
                </p>
            </template>

            <template v-if="words.length">
                <p>
                    Here are some terms which only appeared in the mentions that you indicated were irrelevant. To improve
                    the quality of the conversation being picked up for this search phrase, use these suggestions to
                    indicate which terms you would like to add to the exclude list for this search phrase.
                </p>

                <p class="control-group control-group-spacer control-group-colour">
                    Find mentions that exclude all of these terms.
                </p>

                <div class="word-list">
                    <div v-for="word in words" :key="word" @click="toggleWordSelect(word)">
                        <CheckboxInput :label="word" :original-value="selectedWords.includes(word)"></CheckboxInput>
                    </div>
                </div>
            </template>
        </div>
    </div>

</template>

<script>
import LoadingMessage from "@/components/LoadingMessage.vue";
import {getExclusionSuggestions} from "@/app/utils/Phrases";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import VuexStore from "@/store/vuex/VuexStore";
import {Goodness} from "@/data/goodness";

export default {
    name: "PhraseTune",
    store: VuexStore,
    components: {CheckboxInput, LoadingMessage},

    props: {
        goodnessResults: {
            type: Goodness,
            required: true
        },
        selectedMentions: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            isLoading: true,
            words: [],
            selectedWords: [],
        }
    },

    computed: {

        hasIrrelevantMentions(){
            return this.selectedMentions.length > 0
        }
    },

    mounted() {
        this.isLoading = true;
        this.words = this.getExclusionSuggestions();
        this.isLoading = false;
        this.$emit('button-update');
    },

    methods: {

        getExclusionSuggestions: function() {
            let relevantMentions = [];
            let irrelevantMentions = [];

            this.goodnessResults.mentions.map(mention => {
                if (this.selectedMentions.includes(mention)) {
                    const relevantMention = {...mention, relevant: true};
                    irrelevantMentions.push(relevantMention);
                    return relevantMention;
                } else {
                    const irrelevantMention = {...mention, relevant: false};
                    relevantMentions.push(irrelevantMention);
                    return irrelevantMention;
                }
            })

            return getExclusionSuggestions(relevantMentions, irrelevantMentions);
        },

        toggleWordSelect(word){
            if (!this.selectedWords.includes(word)) {
                this.selectedWords.push(word);
            } else {
                this.selectedWords = this.selectedWords.filter(selectedWord => selectedWord !== word);
            }
        }
    }
}
</script>

<style scoped lang="sass">

.word-list
    display: flex
    flex-direction: row
    flex-wrap: wrap
    gap: 10px

    .deq-checkbox-input
        background-color: var(--be-input-background-color)
        border: 1px solid #333
        padding: 5px
        border-radius: 3px

</style>