import { teams } from "@/store/deprecated/Stores";
import {editDashboards, isOps, isMashAdmin} from "@/app/Permissions";
import VuexStore from "@/store/vuex/VuexStore";
import {isDevEnvironment} from "@/app/utils/Account";
import {intro} from "@/app/framework/markdown/MarkdownExamples";
import {features} from "@/app/Features";


/**
 * Takes a dashboard object and decides whether it is editable or not.
 * @return {boolean}
 */
export function dashboardIsEditable(dashboardObject) {
    return editDashboards()
        && !dashboardObject.deleted
        && !dashboardObject._readOnly
        && (isOps() && dashboardObject._unlocked || dashboardObject.type === 'NORMAL')
        && (!dashboardObject.purchased || isMashAdmin() || isOps())
        && (!dashboardObject.pinned || isMashAdmin() || isOps());
}

export function dashboardIsPrivate(dashboard) {
    let privacy = (dashboard.privacy || "PUBLIC");
    let read = dashboard.readTeamIds;
    let write = dashboard.writeTeamIds;

    return privacy !== "PUBLIC"
        || dashboard._private
        || read && read.length
        || write && write.length;
}



/**
 * Return a description of who can see/edit a dashboard. Returns a promise providing an object with icon and msg
 * fields.
 */
export async function getDashboardAccessMsg(attrs) {
    await Promise.all([teams.refresh(true), VuexStore.dispatch("accountUsers/refreshAccountUsers")]);
    const idToUser = VuexStore.getters["accountUsers/idToUser"];
    let s, icon = 'symbol-lock';
    if (attrs.privacy === 'MASH_ADMIN') {
        s = "This dashboard is only visible to DataEQ staff"
        if (attrs.hasPublicLink) s += " and anyone with the public link"
    } else if (attrs.privacy === 'OWNER') {
        s = "This dashboard is only visible to " + idToUser.get(attrs.ownerId)?.name
        if (attrs.hasPublicLink) s += " and anyone with the public link"
    } else {
        s = "This dashboard is visible to "
        let readers = lookupTeams(attrs.readTeamIds)
        if (readers.length) s += "the " + toTeamNameList(readers)
        else s += "all users"
        if (attrs.hasPublicLink) s += " and anyone with the public link"

        if (attrs.privacy === 'MASH_ADMIN_EDIT') {
            s += ", only DataEQ staff can edit"
        } else if (attrs.privacy === 'OWNER_EDIT') {
            s += ", only " + idToUser.get(attrs.ownerId)?.name + " can edit"
        } else {
            let writers = lookupTeams(attrs.writeTeamIds)
            if (writers.length) s += ", only the " + toTeamNameList(writers) + " can edit"
            if (!readers.length && !writers.length && !attrs.hasPublicLink) icon = 'symbol-lock-open'
        }
    }
    return { icon: icon, msg: s }
}

export function isCampaignDashboardLink() {
    if (!window.location.pathname.includes("dashboard")) return false;

    const params = new URLSearchParams(window.location.search)
    if (!params.has('campaign'))  return false;

    if (window.location.hash) {
        return window.location.hash.substring(1).includes("section-content");
    }

    return false;
}

export function getCampaignFromLink() {
    const params = new URLSearchParams(window.location.search)

    return params.get('campaign');
}

export function getAnchorSectionId() {
    let sectionId = ""

    if(window.location.hash) {
        sectionId = window.location.hash.substring(1)?.split("-").pop();
    }

    return sectionId;
}

function lookupTeams(ids) {
    let list = []
    if (ids) {
        ids.forEach(id => {
            let t = teams.byId[id]
            if (t) list.push(t)
        })
        list.sort((a,b) => a.name.localeCompare(b.name))
    }
    return list
}

function toTeamNameList(a) {
    let s = ""
    for (let i = 0; i < a.length; i++) {
        if (i > 0) {
            if (i === a.length - 1) s += " and "
            else s += ", "
        }
        s += a[i].name
    }
    s += a.length === 1 ? " team" : " teams"
    return s
}

/**
 * Called when dashboards are loaded. It updates old dashboards so that they can
 * continue to work in Analyse.
 */
export function updateOldDashboard(dashboard) {
    fixWidgetsMissingId(dashboard);
    upgradeGrid(dashboard);
    upgradeStyle(dashboard);
    fixInteractionIdFields(dashboard);
    if (features.upgradeDashBoardsToCommentPlus() && isDevEnvironment() && isMashAdmin()){
        upgradeToCommentaryPlus(dashboard);
    }
    return dashboard;
}

function fixWidgetsMissingId(data) {
    // somehow there are dashboards in the db with widgets with null id
    if (!data.sections) return
    data.sections.forEach(s => {
        if (!s.widgets) return
        s.widgets.forEach(w => {
            if (w.id) return
            let maxId = 0
            s.widgets.forEach(w => maxId = Math.max(maxId, w.id ? w.id : 0))
            w.id = maxId + 1
            console.warn("Fixing widget missing id (assigned " + w.id + ") in dashboard " + data.id)
        })
    })
}

// converts interactionIdCount to interactionCount for required fields
function fixInteractionIdFields(data) {
    data.sections?.forEach(s => {
        if (!s.widgets) return
        s.widgets.forEach(w => {
            if (w.type === "CompTable") {
                w.cols?.forEach(c => {
                    if (c.type === "interactionIdCount") {
                        c.type = "interactionCount";
                    }
                 });
            } else if (w.type === 'FantasticChart') {
                if (w.show) {
                    w.show.forEach(s => {
                        if (s.yAxis === "interactionIdCount") {
                            s.yAxis = "interactionCount";
                        }
                    });
                }
            }
        });
    });
}

function upgradeToCommentaryPlus(data) {
    data.sections?.forEach(section => {
        section.widgets.forEach(widget => {
            if (widget.type === "Text") {
                if (widget.caption === "Commentary") widget.caption = "Commentary Plus";
                widget.type = "CommentaryPlusWidget";
                widget.name = "CommentaryPlusWidget";
                const text = {
                    time: Date.now(),
                    blocks: [
                        {
                            id: "-3bye-e8OP",
                            type: "markdown",
                            data: {
                                text: widget.text ?? intro
                            },
                            tunes: {
                                alignmentTune: {
                                    alignment: "left"
                                }
                            }
                        }
                    ],
                    version: "2.29.0"
                };
                // will store this just in case. When we take it live we can then wait a few weeks, and if no complaints we can use the same method to remove it
                widget.legacyMarkdownCommentText = widget.text;
                widget.text = JSON.stringify(text);
                widget.width = widget.width ?? 4;
                widget.height = widget.height ?? 4;
                widget.upgradedToCommentPlus = Date.now();
            }

            if (widget.comment){
                const text = {
                    time: Date.now(),
                    blocks: [
                        {
                            id: "-3bye-e8OP",
                            type: "markdown",
                            data: {
                                text: widget.comment
                            },
                            tunes: {
                                alignmentTune: {
                                    alignment: "left"
                                }
                            }
                        }
                    ],
                    version: "2.29.0"
                };
                // will store this just in case. When we take it live we can then wait a few weeks, and if no complaints we can use the same method to remove it
                widget.legacyMarkdownWidgetCommentText = widget.comment;
                widget.commentPlus = JSON.stringify(text);
                widget.comment = null;
                widget.upgradedToCommentPlusComment = Date.now();
            }
        })
    })
}

function upgradeStyle(data) {
    if (data.styleVersion === 2 || !data.sections) return data

    // set outline, padding and number format on all fantastic charts to new defaults
    data.sections.forEach(s => {
        if (!s.widgets) return
        s.widgets.forEach(w => {
            if (w.type !== 'FantasticChart') return

            // fixes issue where some older fantastic charts might have their "show" field as "ldaTopics" (unsure what causes this).
            // "show" can only be "ldaTopics" for widgets of type "Conversation" (see ExampleMentions.js)
            if (w.show === "ldaTopics") {
                delete w.show;
            }

            let showingOTS = false
            if (w.show) {
                w.show.forEach(s => {
                    if (s.barOutline === 1) {
                        s.barOutline = 0
                        s.barPadding = 20
                    }
                    if (s.yAxis === "totalOTS") showingOTS = true
                })
            } else {
                w.barOutline = 0
                w.barPadding = 20
                if (w.yAxis === "totalOTS") showingOTS = true
            }

            delete w['no-si']
            w.noSi = !showingOTS
            w.numberFormat = showingOTS ? 'si' : 'space'
        })
    })

    data.styleVersion = 2
}

function upgradeGrid(data) {
    if (data.gridVersion === 2 || !data.sections) return data

    // double size of all widgets for the new smaller square grid
    data.sections.forEach(s => {
        if (!s.widgets) return
        s.widgets.forEach(w => {
            let type = w.type
            if (type) type = Beef.WidgetRegistry.typeMap[type]
            if (type) {
                if (!w.width && type.width) w.width = +type.width / 2
                if (!w.height && type.height) w.height = +type.height / 2
            }

            let nw = (+w.width || 2) * 2
            let nh = (+w.height || 2) * 2
            w.width = nw
            w.height = nh
        })
    })

    // old grid had weird thing with max-width "4" in UI being "1" in model, "5" -> "2" and so on
    // so now keep actual max-width in the model and convert to double columns
    data['max-width'] = (+(data['max-width'] || 1) + 3) * 2
    data.gridVersion = 2
}

/**
 * This provides a standard error helper to use in various dashboard widgets. It handles
 * appropriate error reporting and updating the model of the widget.
 *
 * @param model The model of the widget that is having an error handled.
 * @param xhrLikeOrError Either something that behaves like an xhr request, with a status code and possibly response
 *                       text, or an error object / message.
 * @return {void}
 */
export function errorHelper(model, xhrLikeOrError) {
    try {
        const account = VuexStore.state.account;
        if (xhrLikeOrError.isAxiosError) {
            xhrLikeOrError = xhrLikeOrError.response || {
                status: 0,
                statusText: "error"
            };
        }
        let name = "chart";
        if (model) {
            name = model.get("id") + ":[" + (model.get("caption") || model.get("title") || model.get("name") || "no caption") + "]";

            if (model.getSectionModel && model.getSectionModel()) {
                let s = model.getSectionModel();
                name = "Metric " + name + " on section " + s.get("id") + ":[" + (s.get("title") || "no title") + "]"
            }
        }

        let wasLogged = false;
        let responseText;
        if (xhrLikeOrError) {
            responseText = xhrLikeOrError.responseText
            if (!responseText && xhrLikeOrError.data) responseText = xhrLikeOrError.data.error
        }

        if (responseText) {
            if (xhrLikeOrError.status === 422) {
                if (responseText.indexOf("[BRAND-ERROR]") > 0) {
                    console.warn("Received BRAND-ERROR from API on " + name);
                    wasLogged = true;
                } else if (responseText.indexOf("[BRAND-ERROR-MULTIPLE]") > 0) {
                    console.warn("Received BRAND-ERROR-MULTIPLE from API on " + name);
                    wasLogged = true;
                }
            }

            if (!wasLogged) {
                try {
                    let json = JSON.parse(responseText);
                    if (json.error) {
                        console.error("Dashboard ajax error: " + xhrLikeOrError.statusText + " (" + xhrLikeOrError.status + ") - " + json.error, xhrLikeOrError);
                        wasLogged = true;
                    }
                } catch (e) {
                    console.error(e);
                    wasLogged = false;
                }
            }
        }

        if (!wasLogged) console.error("Error while rendering " + name, xhrLikeOrError);

        let msg;
        let code = xhrLikeOrError && xhrLikeOrError.status;
        const filterIssueMessage = "Unable to fetch data, there may be an issue with your filter";
        if (code === 422 && responseText && responseText.indexOf("[BRAND-ERROR]") > 0) {
            msg = "Please select a brand";
        } else if (code === 422 && responseText && responseText.indexOf("[BRAND-ERROR-MULTIPLE]") > 0) {
            msg = "Please select only one brand";
        } else if (code === 400 || code === 422) {
            msg = filterIssueMessage;
        } else if (code === 408) {
            msg = `The filter is taking too long to run
            <p class='info'>
            Try selecting fewer mentions. If that does not work, contact support@dataeq.com 
            </p>`;
        } else if (code === 0) {
            msg = "Unable to fetch data. Your filter may be too long.";
            if (account.dev) {
                msg += "<p class='error'> Please start grouse or your local proxy </p>";
            }
        } else if (code) {
            msg = "Something has gone wrong, please try again later. " +
                "<p class='info'>If this continues, please contact support (http error code: " + code + ").";
        } else if (!code) { // Probably just a regular error message
            msg = "There was an error drawing this metric." +
                "<p class='info'>" +
                "This is probably a problem with Analyse itself. Try reloading, " +
                "and if that does not work, contact support@dataeq.com" +
                "</p>";
        } else {
            msg = "Unable to reach DataEQ, please check your internet " +
                "connection and try again";
        }

        if (account.dev && xhrLikeOrError && responseText) msg += "<p class='error'>" + responseText + "</p>";
        else if (account.dev && xhrLikeOrError && xhrLikeOrError.fileName) {
            let path = xhrLikeOrError.fileName.split('/');
            msg += "<p class='error'> At " + path[path.length - 1] + ":" + xhrLikeOrError.lineNumber + ":" + xhrLikeOrError + "</p>";
        }
        else if (account.dev && xhrLikeOrError && xhrLikeOrError.statusText) {
            msg += "<p class='error'>" + xhrLikeOrError.statusText + ":" + xhrLikeOrError.status + "</p>";
        }
        else if (account.dev) {
            msg += "<p class='error'>" + xhrLikeOrError + "</p>";
        }

        setTimeout(function() { model.generalData.set('_message', msg) }, 500);
    } finally {
        if (model.generalData) {
            model.generalData.set({
                '_loading': false,
                '_completed': true,
                "_commentsComplete": true,
            });
            model.generalData.unset('_footnotes');
            model.generalData.unset('noSpaceForComments');
        }
    }
}