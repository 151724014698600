<template>

        <div class="deq-language-picker">
            <PopupMenu ref="popupMenu" fixed :value="showList"
                       ignore-activator-click :arrow-pointer="{show: true, left: true}" :never-hide="showList">
                <template #activator>
                    <label v-if="labelText.length" class="language-input-label" @click="onLocationInputBlur($event)">{{labelText}}</label>
                    <div ref="locationInput" class="deq-input-div language-input" @click="onLanguageInputFocus" @focusout="onLocationInputBlur">

                        <template v-if="languagePickerControl.value">
                            <SlottedTag ref="slotRef" v-for="language in languagePickerControl.value" :key="language"
                                         @close="unselectLanguage(language)" class="selected-tag">
                                {{ possibleLanguages[language] }}
                            </SlottedTag>
                        </template>

                        <input type="text" ref="searchInput" v-model="searchText" :placeholder="placeholder"
                               class="deq-invisible-input search-input"
                               style="">
                    </div>
                </template>

            <ul ref="languageList" class="language-options dark-scrollbars dark-scrollbars--visible">
                <li v-for="(lang, key) in filteredLanguages" :key="key" @click="onSelectLanguage(key)">{{ lang }}</li>
            </ul>
            </PopupMenu>
        </div>

</template>

<script>
import {LANGUAGES} from "@/app/utils/Language";
import SlottedTag from "@/components/tags/SlottedTag.vue";
import PopupMenu from "@/components/PopupMenu.vue";

const placeholderText = 'Search for language';
export default {
    name: "LanguagePickerComponent",
    components: {PopupMenu, SlottedTag},
    model: {
        prop: 'selectedLanguage',
        event: 'selection'
    },
    props: {
        selectedLanguage: {
            type: String,
            required: false,
            default: ''
        },
        isMultiselect: {
            type: Boolean,
            required: false,
            default: false
        },
        labelText: {
            type: String,
            required: false,
            default: ''
        }
    },

    data() {
        return {
            showList: false,
            searchText: '',
            possibleLanguages: LANGUAGES,
            placeholder: this.selectedLanguage ? '' : placeholderText,

            languagePickerControl: {
                value: this.selectedLanguage ? this.selectedLanguage.split(' ') : [],
                isValid: !!this.selectedLanguage,
            },

            width: '97%'
        }
    },


    computed: {
        filteredLanguages() {
            if (!this.searchText.length) {
                return this.possibleLanguages;
            } else {
                const searchText = this.searchText.toLowerCase();
                let filtered = {};

                for (const code in this.possibleLanguages) {
                    if (this.possibleLanguages[code].toLowerCase().includes(searchText)) {
                        filtered[code] = this.possibleLanguages[code];
                    }
                }

                return filtered;
            }

        },
    },

    mounted() {
        // no other way of closing the list: PopupMenu requires a boolean to show/hide
        document.querySelector('.dialog').addEventListener('click', this.onLocationInputBlur);
        this.emitData();
    },

    destroyed() {
        document.querySelector('.dialog').removeEventListener('click', this.onLocationInputBlur);
    },

    methods: {
        onSelectLanguage(key) {
            if (LANGUAGES[key] && !this.languagePickerControl.value.includes(key)) {
                if (!this.isMultiselect) {
                    this.languagePickerControl.value = [key];
                } else {
                    this.languagePickerControl.value.push(key);
                }
                this.languagePickerControl.isValid = true;
                this.emitData();
                this.$emit('isValid', true)
            } else {
                this.languagePickerControl.isValid = false;
                this.$emit('isValid', false)
            }
            this.searchText = '';
            this.$refs.searchInput.focus();

            if (!this.isMultiselect) {
                this.onLocationInputBlur();
            }
        },

        unselectLanguage(language) {
            this.languagePickerControl.value = this.languagePickerControl.value.filter(selectedLanguage => {
                return selectedLanguage !== language
            });

            this.showPlaceholder()
            this.emitData();
        },

        hidePlaceholder() {
            this.placeholder = '';
        },

        showPlaceholder() {
            this.placeholder = !this.languagePickerControl.value.length ? 'Search for language' : '';
        },

        onLanguageInputFocus() {
            this.$refs.searchInput.focus();
            this.showList = true;
            this.hidePlaceholder()
        },

        onLocationInputBlur(event) {
            if (event.target.classList.contains('language-input-label') || !this.$refs.popupMenu.$el.contains(event.target)) {
                this.showPlaceholder()
                this.$refs.searchInput.blur();
                this.showList = false
            }
        },

        emitData() {
            this.$emit('selection', this.languagePickerControl.value?.join(' '))
        }
    }
}
</script>

<style scoped lang="sass">

.language-options
    max-height: 150px
    overflow-y: auto
    width: 200px
    border: 1px solid black
    border-radius: 3px
    margin: 0
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2)
    background-color: #333
    position: absolute
    z-index: 1

    li
        border-radius: 3px
        padding: 3px

        &:hover
            background-color: #222222
            cursor: pointer


.deq-language-picker
    position: relative
    height: fit-content

    .language-input-label
        cursor: text

    .language-input
        gap: 7px
        display: flex
        flex-direction: row
        flex-wrap: wrap
        cursor: pointer

        &:focus-within
            border-color: var(--be-input-focus-border-color)
            box-shadow: var(--be-input-focus-box-shadow)

        .search-input
            width: fit-content
            margin: 2px 0 0 0

            &::placeholder
                position: relative
                top: 1px
                font-size: 10pt


.error
    color: #ff6663
    position: absolute

::v-deep .control-group
    width: 100%

</style>