import {mash} from "@/store/Services";
import {AccountUser} from "@/app/utils/types";

let refreshPromise = null;

export default {
    namespaced: true,

    state: () => ({
        users: null
    }),

    mutations: {
        setUsers: (state, value) => {

            value.forEach(user => user.__proto__ = new AccountUser())

            state.users = value
        },

        /**
         *
         * Updates a user's details
         * @param state
         * @param user {AccountUser}
         */
        updateUserObject: (state, user) => {
            if (!state.users.find(stateUser => stateUser.id === user.id)) {
                throw new Error('User not found');
            }

            state.users = state.users.map(stateUser => {
                if (stateUser.id === user.id) {
                    user.name = stateUser.name
                    return user;
                } else {
                    return stateUser;
                }
            });
        },

        /**
         * Adds a new user to the AccountUserModule store.
         *
         * @param state
         * @param user {AccountUser}
         */
        addUserObject: (state, user) => {
            if (state.users.find(stateUser => stateUser.id === user.id)) {
                throw new Error('User already exists');
            }

            user.name = `${user.firstName} ${user.lastName}`

            state.users.unshift(user);
        }
    },

    getters: {
        idToUser: (state) => {
            if (!state.users) return new Map();

            const map = new Map();
            for (const user of state.users) {
                map.set(user.id, user);
            }
            return map
        }
    },

    actions: {
        async refreshAccountUsers({state, commit, rootState}, forceRefresh) {
            forceRefresh ??= false;

            if (state.users && !forceRefresh) return;

            if (refreshPromise) {
                await refreshPromise;
                return;
            }

            try {
                refreshPromise = mash.get("/rest/accounts/" + rootState.account.code + "/users");
                const res = await refreshPromise;
                await commit('setUsers', res.data);
            } catch(e) {
                console.error(e);
            } finally {
                refreshPromise = null;
            }
        },

        /** Returns a user object if it exists. The user is looked up via their email address.
         * The address can be in the 'bob@email.com' or 'Bob Smith <bob@email.com>' format.
         */
        getUserByEmail({dispatch, state}, email) {
            let toMatch = email?.trim();
            if (!toMatch) return null;
            if (!state.users?.length) return null;

            if (toMatch.includes('<') && toMatch.includes('>')) {
                toMatch = toMatch.substring(toMatch.indexOf('<') + 1, toMatch.indexOf('>'));
            }

            return state.users.find(u => u.email?.trim().toLowerCase() === toMatch);
        },

        /**
         *
         * Updates and saves the details for the user
         * @param state
         * @param rootState
         * @param commit
         * @param user
         * @returns {Promise<AccountUser>}
         */
        async updateUser({state, rootState, commit}, user) {
            if (!rootState.account?.code) throw new Error("accountCode is required");
            if (!user.id) throw new Error("user object should have an ID");

            const res = await mash.put(`/rest/accounts/${rootState.account?.code}/users/${user.id}`, user);

            await commit('updateUserObject', res.data);
            return res.data
        },


        /**
         * creates and saves a new user
         * @param state
         * @param rootState
         * @param commit
         * @param user
         * @returns {Promise<any>}
         */
        async createUser({state, rootState, commit}, user) {
            if (!rootState.account?.code) throw new Error("accountCode is required");

            const res = await mash.post(`/rest/accounts/${rootState.account?.code}/users`, user);

            await commit('addUserObject', res.data);
            return res.data
        },


        /**
         * checks for an existing email
         * not sure if this is different to getUserByEmail
         * @param state
         * @param email
         * @returns {Promise<any>}
         */
        async checkForEmail({state}, email) {

          try {
              const res = await mash.get(`/rest/users/search/?email=${email}`);

              return res.data
          } catch (e) {
              console.warn("An error occurred while checking email: ", e)
          }
        },
    }
}