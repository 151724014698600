<template>
    <div class="help short-animated fadeIn">
        <ul>
            <li>
                <a @click="showTipsDialog">Help & tips…</a>
            </li>
            <li>
                <a @click="showNewInAnalyseDialog">New in Analyse…</a>
            </li>
            <li class="help__divider"></li>
            <lI>
                <a href="https://storage.googleapis.com/brandseye-public-files/dataeq-analyse-quickstart-guide-1.1.pdf"
                   title="Download a quickstart guide to getting up and running quickly" target="_blank">
                Quickstart</a>
            </lI>
            <lI>
                <a href="https://dataeq.com/knowledge/analyse"
                   title="Visit our knowledge base to find help with Analyse" target="_blank">
                    Knowledge base</a>
            </lI>
            <li>
                <a @click="createSupportTicket" title="Contact our support team via email">Contact support…</a>
            </li>
        </ul>
    </div>
</template>

<script>
import AllTipsDialog from "@/app/help/tips/AllTipsDialog";
import {showDialogComponent, showDialogComponent as showDialog} from "@/app/framework/dialogs/DialogUtilities";
import NewInAnalyseDialog from "@/app/framework/dialogs/motd/NewInAnalyseDialog";
import {showNewInAnalyseDialog} from "@/app/help/Help";

export default {
    name: "HelpMenu",

    data: function() {
        return {}
    },

    methods: {
        showTipsDialog() {
            showDialog(AllTipsDialog);
        },

        showNewInAnalyseDialog() {
            showNewInAnalyseDialog();
        },

        createSupportTicket() {
            Beef.SupportTicket.show();
        }
    }
}
</script>

<style scoped lang="sass">

.help
    margin: 3px 0

    ul
        list-style: none
        margin: 0

    li:not(.help__divider)
        line-height: 20px

    li > a
        font-weight: 400
        color: #ffffff
        cursor: pointer
        display: block
        padding: 3px 10px
        clear: both
        white-space: nowrap

        &:hover
            color: #aee15d
            text-decoration: none
            background-color: #222222

    &__divider
        margin: 5px 0
        overflow: hidden
        border-bottom: 1px solid #222222
        pointer-events: none
        padding: 0

</style>