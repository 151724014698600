import {AccountTeam} from "@/app/utils/types";
import {mash} from "@/store/Services";

export const AccountTeamsMutations = {

    /**
     * Sets the accountTeams array in the VuexStore
     *
     * @param state
     * @param teams {AccountTeam[]}
     */
    setAccountTeams: (state, teams) => {
        teams.forEach(team => team.__proto__ = new AccountTeam());
        state.accountTeams = teams;
    },

    /**
     * Sets a teams updated details
     * @param state
     * @param team
     */
    updateTeamMembers: (state, team) => {
        state.accountTeams = state.accountTeams?.map(accTeam => {
            if (accTeam.id === team.id) {
                return team;
            } else {
                return accTeam
            }
        })
    }
};

export const AccountTeamsGetters = {

    /**
     * Returns the appropriate team for the given teamId.
     * @param state
     * @returns {AccountTeam}
     */
    findAccountTeamById: (state) => (teamId) => {
        if (!state.accountTeams) return [];

        return state.accountTeams?.find(team => team.id === teamId);
    },

    /**
     * Returns all the teams the user is part of.
     * @param state
     * @returns {AccountTeam[]}
     */
    findAccountTeamsByUserId: (state) => (userId) => {
        if (!state.accountTeams) return [];

        return state.accountTeams?.filter(team => team.userIds.includes(userId));
    },

};

export const AccountTeamsActions = {

    async refreshAccountTeams({state, commit}, forceRefresh = false) {
        if (state.accountTeams && !forceRefresh) return;

        try {
            const res = await mash.get(`rest/accounts/${state.account.code}/teams`);

            await commit('setAccountTeams', res.data);
        } catch (e) {
            console.warn('An error occurred while fetching teams for this account: ', e);
            throw e;
        }
    },

    /**
     * Either adds or removes a user from a team
     * Commits the updated user to the store, no need to refresh
     * @param state
     * @param rootState
     * @param commit
     * @param dispatch
     * @param teamId
     * @param userId
     * @param isDelete
     * @param refreshTeams
     * @returns {Promise<void>}
     */
    async updateTeamUser({state, rootState, commit, dispatch}, {teamId, userId, isDelete = false, refreshTeams = false}) {
        try {

            let request = {};
            if (!isDelete) {
                request = {addUserIds: [userId]};
            } else {
                request = {removeUserIds: [userId]};
            }

            const res = await mash.put(`rest/accounts/${rootState.account.code}/teams/${teamId}`, request );
            await commit('updateTeamMembers', res.data);

            // not really needed, we get the updated team as response and commit('updateTeamMembers', res.data) with the updated team
            if (refreshTeams) {
                await dispatch('refreshAccountTeams', true);
            }
        } catch (e) {
            console.warn('An error occurred while fetching teams for this account: ', e);
            throw e;
        }
    },

}