<template>

    <main class="deq-reset feeds-container">
        <h1>Custom Data Sources</h1>

        <div class="intro">

            <p>DataEQ can read mentions from the <a href="https://en.wikipedia.org/wiki/RSS" target="blank">RSS feeds</a>
                provided by many websites and you can add those RSS feeds here. However If you do find a site that
                we don't appear to be searching please <a href="mailto:support@dataeq.com">let us know</a>.</p>

            <p>Note: you can add Facebook pages and Twitter users in the
                <a :href="`/${account.code}/setup/online`"><i class="icon-comment-empty"></i>Online Profiles</a> setup section.
            </p>
        </div>

        <template v-if="!isLoading">
            <search-input v-model="searchText" placeholder="Search for feed by name or url"></search-input>

            <div class="feeds-table dark-scrollbars dark-scrollbars--visible" :style="{'grid-template-columns': `repeat(${columns}, 1fr)`}">
                <div class="feeds-cell header name">Name</div>
                <div class="feeds-cell header link">Link</div>
                <div class="feeds-cell header activity">Activity</div>
                <div class="feeds-cell header vol-week">Vol/Week</div>
                <div class="feeds-cell header misses">Misses</div>
                <div v-if="isMashAdmin" class="feeds-cell header repoll">Repoll</div>

                <template v-if="!feeds">
                    <div class="feeds-cell no-feeds" :style="{'grid-column': `span ${columns}`}">No feeds found</div>
                </template>

                <div v-for="(feed) in filteredFeeds" :key="feed.id" class="body-container">
                    <div class="feeds-cell body name">
                        {{feed.name}}
                        <i v-if="editFeeds" @click="onEditClick(feed)" class="edit-icon icon-pencil" href="edit"></i>
                    </div>

                    <div class="feeds-cell body link">
                        {{feed.link}}<i v-if="feed.sendMentionsToAllAccounts" class="icon-users pull-right" title="Send mentions from feed to all accounts"></i>
                    </div>
                    <div class="feeds-cell body activity">
                        <template v-if="feed.status && feed.status.lastReadError">
                            <i class="icon-attention-1 warn"></i>
                            {{feed.status.readIOExceptionCount}}
                            {{feed.status.lastReadError}}, {{feed.status.lastRead}}
                        </template>
                        <template v-else-if="feed.status && feed.status.lastReadMentions">
                            Read {{feed.status.lastReadNewMentions}}/{{feed.status.lastReadMentions}} new
                            mention<span v-if="feed.status.lastReadMentions > 1">s</span>, {{feed.status.lastRead}}
                        </template>
                    </div>
                    <div class="feeds-cell body vol-week">
                        <template v-if="feed.status && feed.status.numNewMentions" >
                            {{feed.status.numNewMentions}}
                        </template>
                    </div>

                    <div class="feeds-cell body misses">
                        <template v-if="isMashAdmin && feed.status && feed.status.numMaybeMissedDataEvents">
                            <i class="icon-attention-1 warn"></i> {{feed.status.numMaybeMissedDataEvents}}
                        </template>
                    </div>

                    <div v-if="isMashAdmin" class="feeds-cell body repoll">
                        <template>
                            <i @click="onRepollClick(feed.id)" class="icon-ccw"></i>
                        </template>
                    </div>
                </div>

            </div>

            <be-button primary @click="onAddNewClick" class="add-new-btn" title="Create new feed"><i class="icon-plus"></i> Add a new custom data source</be-button>

            <br>
            <br>
            <edit-feed @close="onModalClose" v-if="showEditModal" :feed="crudFeed"></edit-feed>
        </template>

        <LoadingMessage v-if="isLoading" :message="'Fetching your custom data sources from DataEQ...'" class="feeds-loader" />
    </main>

</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {beef} from "@/store/Services";
import VuexStore from "@/store/vuex/VuexStore";
import {editFeeds, isMashAdmin} from "@/app/Permissions";
import EditFeed from "@/setup/feeds/EditFeed.vue";
import BeButton from "@/components/buttons/BeButton.vue";
import {currentAccountCode} from "@/app/utils/Account";
import {showErrorDialog, showInfoDialog} from "@/app/framework/dialogs/Dialog";
import SearchInput from "@/components/inputs/SearchInput.vue";
import LoadingMessage from "@/components/LoadingMessage.vue";
import {notifyUserOfError} from "@/app/framework/notifications/Notifications";


export default {
    name: "SetupFeeds",
    components: {LoadingMessage, SearchInput, BeButton, EditFeed},

    data() {
        return {
            isLoading: false,
            showEditModal: false,
            //  shows repoll for admin only
            columns: isMashAdmin() ? 19 : 18,

            crudFeed: {},
            searchText: '',

        }
    },

    computed: {
        ...mapState(['account', 'feeds']),

        filteredFeeds() {
            const searchText = this.searchText.toLowerCase();

            return this.feeds?.filter(feed => {
                return feed.name.toLowerCase().includes(searchText) || feed.link.toLowerCase().includes(searchText);
            })
        }
    },

    store: VuexStore,

    methods: {
        ...mapActions(['refreshFeeds']),

        editFeeds,
        isMashAdmin,

        onEditClick(feed) {
            this.showEditModal = true;
            this.crudFeed = feed;
        },

        onAddNewClick() {
            this.showEditModal = true;
        },

        onModalClose() {
            this.crudFeed = {};
            this.showEditModal = false;
        },

        async onRepollClick(feedId) {
            try {
                await beef.post(`/api/feeds/repoll?accountCode=${currentAccountCode()}&id=${feedId}`);
                await showInfoDialog("Feed will be re-polled shortly");
            } catch (error) {
                    console.warn(error);
                    showErrorDialog("Re-poll failed: " + error);
            }
        }
    },

    async mounted() {
        this.isLoading = true;
        try {
            await this.refreshFeeds();
        } catch (e) {
            notifyUserOfError("There has been a problem fetching your data sources from DataEQ")
        } finally {
            this.isLoading = false;
        }

    }

}
</script>

<style scoped lang="sass">

.feeds-loader
    --loading-message-delay: 100ms

.feeds-table
    display: grid
    grid-template-rows: auto
    gap: 1px
    background-color:  black // inner borders
    border: 1px solid black
    max-height: 70vh
    overflow: auto


    .feeds-cell
        background-color: #333
        padding: 5px

    .name
        grid-column: span 9
        display: flex
        flex-direction: row
        justify-content: space-between

        .edit-icon
            cursor: pointer
            height: fit-content

    .link
        grid-column: span 6
        word-break: break-all

    .header
        background-color: #222
        position: sticky
        top: 0
    .no-feeds
        text-align: center

    .add-new
        display: flex
        justify-content: center

        .btn
            color: #ffffff
            background-color: var(--dialog-title-background-colour)
            transition: color linear 0.1s

            &:hover
                border: none
                background-color: var(--dialog-title-background-colour) !important
                background: var(--dialog-title-background-colour)
                color: #85b03d
                transition: all .1s linear


    .body-container
        display: contents

        &:hover
            color: #aee15d

        .vol-week, .misses, .repoll
            display: flex
            flex-direction: row
            align-items: center
            justify-content: center

        .repoll > i
            &:hover
                cursor: pointer

        .activity
            text-align: center

        .activity, .misses
            color: #FFAAAA
.add-new-btn
    position: relative
    float: right
    margin-top: 20px

.search-input
    width: 40%
    margin-bottom: 10px
</style>