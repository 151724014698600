<template>


    <DialogBox modal title="Generate search phrases" class="edit-phrase-dialog" width="650px" @close="emitClose()">
        <div class="deq-reset generate-phrase-form edit-phrase-form dark-scrollbars dark-scrollbars--visible">
            <progress-bar :total-stages="3" :cur-stage="pages[activePage].id"/>
            <!-- data flow starts here and emits the termsList -->
            <KeepAlive>
                <PhraseGeneratorComponent v-if="activePage === 'PhraseGeneratorComponent'" @generated="onUpdatedTerms"></PhraseGeneratorComponent>
            </KeepAlive>
            <KeepAlive>
                <FeedsPicker v-if="activePage === 'PhraseGeneratorComponent'" v-model="feeds"></FeedsPicker>
            </KeepAlive>
            <KeepAlive>
                <MentionForm v-if="activePage === 'PhraseGeneratorComponent'" v-model="mentionFilter" :isPhrase="true"></MentionForm>
            </KeepAlive>
            <KeepAlive>
                <TermsList v-if="activePage === 'TermsList'" v-model="termsList"></TermsList>
            </KeepAlive>
            <GoodnessResultsComponent v-if="activePage === 'GoodnessResultsComponent'" :termsList="termsList" :parentId="parent.id"
                             :mention-filter="mentionFilter" @confirmation-changed="onGoodnessConfirmation">
            </GoodnessResultsComponent>

        </div>

        <template #buttons>
            <div class="deq-dialog-buttons gp-buttons">

                <div class="saving-spinner">
                    <SpinnerComponent v-if="isSaving" :size="16"/>
                </div>

                <div class="right-side">
                    <BeButton @click="emitClose" :disabled="isSaving" link>
                        <TooltipComponent>
                            <span>Cancel</span>
                            <template #tooltip>Discard changes (Esc)</template>
                        </TooltipComponent>
                    </BeButton>
                    <BeButton v-if="activePage === 'TermsList' || activePage === 'GoodnessResultsComponent'" @click="onPrevClick">
                        Previous
                    </BeButton>
                    <BeButton v-if="activePage === 'PhraseGeneratorComponent' || activePage === 'TermsList'" @click="onNextClick"
                              :disabled="!termsList.length">
                        Next
                    </BeButton>
                    <BeButton v-if="confirmationDetails.goodToSave && confirmationDetails.queryList" @click="onSave"
                               :disabled="!confirmationDetails.goodToSave || isSaving">Ok</BeButton>
                </div>
            </div>
        </template>
    </DialogBox>

</template>
<script>
import DialogBox from "@/components/DialogBox.vue";
import VuexStore from "@/store/vuex/VuexStore";
import MentionForm from "@/setup/brands/inputs/MentionForm.vue";
import BeButton from "@/components/buttons/BeButton.vue";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import TermsList from "@/setup/brands/forms/generate-phrase/TermsList.vue";
import PhraseGeneratorComponent from "@/dashboards/filter/PhraseGeneratorComponent.vue";
import FeedsPicker from "@/setup/brands/inputs/FeedsPicker.vue";
import GoodnessResultsComponent from "@/setup/brands/forms/generate-phrase/GoodnessResultsComponent.vue";
import {Brand} from "@/app/utils/types";
import {mapActions, mapGetters} from "vuex";
import {notifyUser, notifyUserOfError} from "@/app/framework/notifications/Notifications";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";
import ProgressBar from "@/components/ProgressBar.vue";

export default {
    name: "GeneratePhraseDialog",
    store: VuexStore,
    components: {
        ProgressBar,
        TooltipComponent,
        DialogBox, PhraseGeneratorComponent, TermsList, GoodnessResultsComponent,
        MentionForm, FeedsPicker,
        SpinnerComponent, BeButton
    },

    props: {
        parent: {
            type: Brand
        }
    },

    data() {
        return {

            /**
             * @type {'PhraseGeneratorComponent' | 'TermsList' | 'GoodnessResultsComponent'}
             */
            activePage: 'PhraseGeneratorComponent',
            pages: {
                PhraseGeneratorComponent: {
                    id: 1
                },
                TermsList: {
                    id: 2
                },
                GoodnessResultsComponent: {
                    id: 3
                },
            },
            isSaving: false,

            termsList: [],
            feeds: '',
            mentionFilter: '',
            /**
             * @type {{goodToSave: Boolean, queryList: String[]}}
             */
            confirmationDetails: {goodToSave: false, queryList: undefined},

        }
    },

    computed: {
        ...mapGetters(['idToBrand']),
    },

    watch: {
        activePage() {
            this.confirmationDetails.goodToSave = false;
            this.confirmationDetails.queryList = [];
            this.isSaving = false;
        }
    },

    methods: {
        ...mapActions(['createPhrase']),

        emitClose() {
            this.$emit('close');
        },

        onPrevClick() {
            switch (this.activePage) {
                case 'TermsList':
                    this.activePage = 'PhraseGeneratorComponent';
                    break;
                case 'GoodnessResultsComponent':
                    this.activePage = 'TermsList';
                    break;
            }
        },

        onNextClick() {
            switch (this.activePage) {
                case 'PhraseGeneratorComponent':
                    this.activePage = 'TermsList';
                    break;
                case 'TermsList':
                    this.activePage = 'GoodnessResultsComponent';
                    break;
            }
        },

        async onSave() {
            this.isSaving = true;

            if (!this.confirmationDetails.goodToSave) {
                notifyUserOfError('Confirm your phrases before saving');
                this.isSaving = false;
                return
            }

            if (!this.confirmationDetails.queryList || !this.confirmationDetails.queryList.length) {
                notifyUserOfError('Enter terms to generate a phrase');
                this.isSaving = false;
                return
            }

            const request = this.confirmationDetails.queryList.map(query => {
                return {
                    query: query,
                    brandId: this.parent.id,
                    active: true,
                    feeds: this.feeds,
                    mentionFilter: this.mentionFilter
                }
            })

            try {
                await this.createPhrase(request);
                const parent = this.idToBrand.get(this.parent.id);
                notifyUser(`${this.confirmationDetails.queryList > 1 ? 'Phrases' : 'Phrase'}
                successfully added to ${parent?.shortName || parent?.name}`);
                this.emitClose()
            } catch (err) {
                console.warn('An error occurred while saving: ', err)
                notifyUserOfError('An error occurred while saving');
            } finally {
                this.isSaving = true;
            }

        },

        onUpdatedTerms(updatedTerms) {
            this.termsList = updatedTerms;
        },

        onGoodnessConfirmation(confirmationDetails) {
            this.confirmationDetails = confirmationDetails;
        }
    }
}
</script>



<style scoped lang="sass">

.progress-bar
    margin: 20px 10px 10px 10px

.gp-buttons
    display: flex
    flex-direction: row
    justify-content: flex-end

    .right-side
        display: flex
        flex-direction: row
        justify-content: flex-end
        gap: 10px

</style>