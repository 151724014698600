
<template>

    <div class="phrases-container-placeholder">
        <div class="phrase-row" v-for="phrase in brand.phrases" :key="phrase.id">
            <div class="brand-name-cell phrase-cell pr-cell" :style="{'padding-left': getPadding}">
                <span class="pr-icon-container" v-if="updatePhraseId === phrase.id">
                    <SpinnerComponent :size="11"  />
                </span>
                <span class="pr-icon-container">
                    <span v-if="phrase.deleted" @click="onEditPhrase(phrase)" class="icon-reply" :class="{'deq-can-edit': brand.canEdit}"></span>
                </span>
                <CheckboxInput :original-value="!phrase.inactive" @change="onCheckboxChange($event, phrase)" :disable="updatePhraseId === phrase.id">
                    <span :class="{'strike' : phrase.deleted}" v-html="renderPhrase(phrase.q)"></span>
                </CheckboxInput>

                <template v-if="phrase.mentionFilter">
                    <TooltipComponent>
                        <span class="icon-filter"></span>
                        <template #tooltip>{{formatConversationFeedFilter(phrase.mentionFilter) + ': ' + formatConversationFilter(phrase.mentionFilter)}}</template>
                    </TooltipComponent>
                </template>

                <template v-if="phrase.socialNetworks">
                    <TooltipComponent>
                    <span class="icon-comment-empty"></span>
                        <template #tooltip>This phrase only matches for certain social networks</template>
                    </TooltipComponent>
                </template>

                <span v-if="phrase.socialNetworks" v-html="formatSocialNetworksIconList(phrase.socialNetworks)"></span>

                <template v-if="phrase.feeds">
                    <TooltipComponent>
                        <span class="icon-comment-1"></span>
                        <template #tooltip>Feeds {{formatFeedsList(phrase.feeds)}}</template>
                    </TooltipComponent>
                </template>

                <template v-if="!phrase.approved">
                    <TooltipComponent>
                        <span class="icon-clock approval-pending"></span>
                    <template #tooltip>Phrase is inactive pending approval</template>
                    </TooltipComponent>
                </template>

            </div>

            <EditBrandCell v-if="brand.canEdit" :brand="phrase" class="pr-cell"></EditBrandCell>

            <PhraseVolumeTrashCell :child="phrase" class="pr-cell"></PhraseVolumeTrashCell>
        </div>

        <div  v-show="(!brand.deleted && brand.canEdit)" class="btn-container">
            <BeButton link @click="onAddPhrase" class="phrase-btn add-phrase-btn" :style="{'padding-left': getPadding}">
                <i class="icon-plus"></i> Add a search phrase
            </BeButton>
            <BeButton link v-if="generatePhrases" @click="onGeneratePhrase" class="phrase-btn generate-phrases-btn">
                <i class="icon-plus"></i> Generate search phrases
            </BeButton>
        </div>
    </div>

</template>

<script>
import {generatePhrases} from "@/app/Permissions";
import renderPhrase from "@/helpers/renderPhrase";
import formatConversationFeedFilter from "@/helpers/formatConversationFeedFilter";
import formatConversationFilter from "@/helpers/formatConversationFilter";
import formatSocialNetworksIconList from "@/helpers/formatSocialNetworksIconList";
import formatFeedsList from "@/helpers/formatFeedsList";
import PhraseVolumeTrashCell from "@/setup/brands/tree/cells/PhraseVolumeTrashCell.vue";
import EditBrandCell from "@/setup/brands/tree/cells/EditBrandCell.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import AddPhraseDialog from "@/setup/brands/forms/add-edit-phrase/AddPhraseDialog.vue";
import GeneratePhraseDialog from "@/setup/brands/forms/generate-phrase/GeneratePhraseDialog.vue";
import {mapActions} from "vuex";
import {notifyUser, notifyUserOfError} from "@/app/framework/notifications/Notifications";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";
import BeButton from "@/components/buttons/BeButton.vue";

export default {
    name: "PhrasesRow",
    props: ['brand', 'searchToggles'],
    components: {EditBrandCell, BeButton, TooltipComponent, SpinnerComponent, CheckboxInput, PhraseVolumeTrashCell},

    inject: ['rootBrandId'],

    data() {
        return {
            updatePhraseId: '',
        }
    },

    computed: {
        getPadding() {
            return this.brand.phrasePadding === '0px' ? '30px' : this.brand.phrasePadding;
        }
    },

    methods: {
        ...mapActions(['togglePhraseActive']),
        formatFeedsList,
        formatSocialNetworksIconList,
        formatConversationFilter, formatConversationFeedFilter, renderPhrase, generatePhrases,

        async onCheckboxChange(isActive, phrase) {
            if (this.updatePhraseId.length) return;

            this.updatePhraseId = phrase.id;

            try {
                // in handlebars the brand did not refresh
                await this.togglePhraseActive({phraseId: phrase.id, isActive});
                notifyUser('Successfully updated phrase active state.')
            } catch (err) {
                console.warn(`An error occurred while updating phrase active state, error: `, err);
                notifyUserOfError('An error occurred while updating phrase active state');
            } finally {
                this.updatePhraseId = '';
            }

        },

        onEditPhrase(phrase) {
            showDialogComponent(AddPhraseDialog, {phrase: phrase, rootBrandId: this.rootBrandId().id, searchToggles: this.searchToggles})
        },

        onAddPhrase() {
            // this brand is the parent of the new phrase to be created
            showDialogComponent(AddPhraseDialog, {phrase: {parent: this.brand}, rootBrandId: this.rootBrandId().id, searchToggles: this.searchToggles})
        },

        onGeneratePhrase() {
            showDialogComponent(GeneratePhraseDialog, {parent: this.brand, rootBrandId: this.rootBrandId().id})
        }
    }
}
</script>

<style scoped lang="sass">

@use "../edit-brand-dialog"

@mixin indent
    padding-left: 30px

.phrases-container-placeholder
    display: contents

    .btn-container
        grid-column: span 12
        display: flex
        border-bottom: 1px solid black

        &:hover
            background-color: var(--table-row-hover-bg-colour)

    .phrase-row
        display: contents

        &:hover
            .pr-cell
                background-color: var(--table-row-hover-bg-colour)
                color: var(--table-row-hover-text-colour)
                cursor: pointer
            ::v-deep .tag
                color: var(--table-row-hover-text-colour)
                cursor: pointer

        .phrase-cell
            grid-column: span 10
            display: flex
            flex-direction: row
            gap: 6px

            .pr-icon-container
                width: 11px
                display: flex
                flex-direction: row
                align-items: center
                justify-content: center

                .css-spinner
                    margin: auto

.temp
    display: contents

.strike ::v-deep .tag
    position: relative

.strike ::v-deep .tag:before
    border-bottom: 1px solid    // uses color from tag class
    content: " "
    position: absolute
    z-index: 1
    top: 50%
    width: 100%


</style>