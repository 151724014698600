<template>

    <div class="query-form edit-brand-form">

        <LoadingMessage v-if="isLoading" :message="'Setting up your mention form...'" class="edit-brand-loader" />

       <template v-if="!isLoading">
           <p v-if="phrase.deleted" class="deleted-msg">
               <strong>
                   This phrase has been deleted. You can make changes here and click Ok to un-delete it.
               </strong>
           </p>

           <p class="find-msg">Find mentions that:</p>

           <PhraseTermPickerComponent v-model="formData.includeTerms" @terms-selected="rebuildQuery"
                                      :disabled="readOnly || !canEditIncTerms"
                                      :label="'Include all of these terms (case insensitive). Press enter after each phrase'">
           </PhraseTermPickerComponent>

           <p v-if="readOnly">
               This phrase is more than {{maxEditAge}} old so the include terms are read-only. If you
               need to make changes then add a new phrase and de-activate this one.
           </p>

           <p v-if="canEditIncTerms && readOnly">You are editing an existing phrase that may have already collected data. Don't
               make edits that change the meaning of the phrase, rather create a new phrase and
               de-activate or delete this one.</p>

           <PhraseTermPickerComponent v-model="formData.excludeTerms" @terms-selected="rebuildQuery" :is-include="false"
                                      :label="'Exclude all of these terms (case insensitive). Press enter after each phrase'"
                                      :placeholder-text="'Enter words or phrases to exclude here'">
           </PhraseTermPickerComponent>

           <p v-if="phrase.id && phrase.approved && phrase.approvedBy">
               <span class="icon-check"></span> Approved {{humanizeDate(phrase.approved)}} by {{phrase.approvedBy.firstName}} {{phrase.approvedBy.lastName}}
           </p>
           <p v-if="phrase.id && !phrase.approved">
               <span class="icon-clock"></span> Phrase is inactive pending approval
           </p>

           <CheckboxInput :label="phrase.id ? 'Collect mentions for this phrase' : 'Start collecting mentions for this phrase when I\'m done'"
                          v-model="formData.active"></CheckboxInput>

           <template v-if="phraseFilters">
               <SocialNetworkPicker class="edit-brand-row" v-model="formData.socialNetworks"></SocialNetworkPicker>
               <FeedsPicker class="edit-brand-row" v-model="formData.feeds"></FeedsPicker>
           </template>


           <MentionForm v-model="formData.mentionFilter" :is-phrase="true"></MentionForm>
       </template>
    </div>

</template>

<script>
import PhraseTermPickerComponent from "@/setup/brands/inputs/PhraseTermPickerComponent.vue";
import SocialNetworkPicker from "@/setup/brands/inputs/SocialNetworkPicker.vue";
import FeedsPicker from "@/setup/brands/inputs/FeedsPicker.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import MentionForm from "@/setup/brands/inputs/MentionForm.vue";
import {editOldPhrases, phraseFilters} from "@/app/Permissions";
import {BrandPhrase} from "@/app/utils/types";
import {normalisePhrase, parsePhraseString, phraseNeedsQuotes} from "@/app/utils/Phrases";
import moment from "moment";
import {humanizeDate} from "@/app/utils/Format";
import LoadingMessage from "@/components/LoadingMessage.vue";
import VuexStore from "@/store/vuex/VuexStore";
import {QueryForm} from "@/setup/brands/shared/types";

const MAX_EDIT_MINS = 60;
export default {
    name: "QueryPage",
    store: VuexStore,
    components: {
        LoadingMessage,
        MentionForm,
        CheckboxInput,
        FeedsPicker,
        SocialNetworkPicker,
        PhraseTermPickerComponent
    },

    props: {
        phrase: {
            type: BrandPhrase,
            required: true,
        },
        queryFormData: {
            type: QueryForm,
            required: true,
        }
    },

    data() {
        return {

            isLoading: true,

            formData: {
                query: '',
                includeTerms: '',
                excludeTerms: '',
                active: true,
                deleted: false,
                feeds: '',
                socialNetworks: '',
                mentionFilter: '',
                conversationLanguages: '',
                authorBioLocation: '',
                webhose: true,
                twitter: true,
            },

            lastGoodInc: [],
            lastGoodEx: [],
            goodQuery: false,
            maxEditAge: MAX_EDIT_MINS + " minutes",
            readOnly: false,
            canEditIncTerms: false,
            opsUserOverride: false,
            canEditOldPhrases: editOldPhrases(),
            canEditPhraseFilter: phraseFilters(),

        }
    },

    computed: {
    },

    watch: {

        formData: {
            handler() {
                this.$emit('query-form-updated', {...this.formData})
            },
            deep: true
        },

    },

    mounted() {
        this.isLoading = true;

        // for existing phrases
        if (Object.keys(this.queryFormData).length && this.queryFormData?.includeTerms.length) {
            this.formData = {...this.queryFormData};
            this.init(this.formData.query);
            this.emitUpdateButtons();
        } else {
            this.formData = {
                query: '',
                includeTerms: '',
                excludeTerms: '',
                active: this.phrase?.inactive ? !this.phrase.inactive : true,
                deleted: this.phrase?.deleted,
                feeds: this.phrase?.feeds ? this.phrase.feeds : '',
                socialNetworks: this.phrase?.socialNetworks ? this.phrase.socialNetworks : '',
                mentionFilter: this.phrase?.mentionFilter ? this.phrase.mentionFilter : '',
                conversationLanguages: '',
                authorBioLocation: '',
                webhose: true,
                twitter: true,
            }

            this.init(this.phrase.q || '');
            this.rebuildQuery();
        }

        this.isLoading = false;
    },

    methods: {
        phraseFilters,
        humanizeDate,

        init(query) {
            var inc = [], ex = [], qinc = [], qex = [];

            if (query) {
                const phraseArray = parsePhraseString(query);
                phraseArray.map(term => {
                    const needsQuotes = phraseNeedsQuotes(term);
                    if (term.charAt(0) === '-') {
                        term = term.substring(1);
                        ex.push(term);
                        qex.push(needsQuotes ? '"' + term + '"' : term);
                    } else {
                        inc.push(term);
                        qinc.push(needsQuotes ? '"' + term + '"' : term);
                    }

                })
            }

            this.formData.includeTerms =  qinc.join(" ");
            this.formData.excludeTerms = qex.join(" ");
            this.lastGoodInc = inc;
            this.lastGoodEx = ex;
            this.goodQuery = inc.length > 0 // assuming existing queries are good

            const readOnly = this.phrase.id && moment().diff(moment(this.phrase.created), 'minutes') >= MAX_EDIT_MINS;

            this.opsUserOverride = !readOnly;

            this.readOnly = readOnly;
            this.canEditIncTerms = !readOnly || editOldPhrases();

        },


        rebuildQuery() {
            // the include and exclude lists must contain at least all of the terms present at the last goodness
            // check or a new check is required
            const parsedInclTerms = this.formData.includeTerms ? parsePhraseString(this.formData.includeTerms) : [];
            const parsedExclTerms = this.formData.excludeTerms ? parsePhraseString(this.formData.excludeTerms) : [];
            this.$emit('is-goodness-check-required', this.lastGoodInc.length === 0 || !this.containsAll(parsedInclTerms, this.lastGoodInc)
                || !this.containsAll(parsedExclTerms, this.lastGoodEx) || this.phrase.deleted);

            const terms = parsedInclTerms.slice(0);
            for (let i = 0; i < parsedExclTerms.length; i++) terms.push("-" + parsedExclTerms[i]);

            this.$emit('is-query-valid-updated', parsedInclTerms.length > 0);
            this.$emit('is-good-query-updated', parsedInclTerms.length > 0);

            const newQuery = normalisePhrase(terms);
            this.formData.query = newQuery
            this.emitUpdateButtons();
            return newQuery;
        },

        emitUpdateButtons() {
            this.$emit('button-update');
        },

        /**
         * Does list contain all the strings in other? Not case sensitive.
         */
        containsAll(list, other) {
            for(let i = 0; i < other.length; i++) {
                if (list[i].toLowerCase() !== other[i].toLowerCase()) return false
            }
            return true;
        },

        onUnlock() {
            this.opsUserOverride = true;
        }
    }
}
</script>

<style scoped lang="sass">
@use "../../tree/edit-brand-dialog"

.query-form
    height: 100%
    display: flex
    flex-direction: column
    gap: 10px

.find-msg
    margin-bottom: 0
.deleted-msg
    strong
        color: unset

</style>